import { AnimationSegment } from 'lottie-web'

import bellImage from 'assets/images/bell-icon.png'
import carImage from 'assets/images/car-icon.png'
import locationImage from 'assets/images/location-icon-blue.png'
import lockImage from 'assets/images/lock-icon-blue.png'
import sosImage from 'assets/images/sos-icon.png'

export const BENEFITS = [
  { image: locationImage, text: 'onboarding.benefits.stayInformed' },
  { image: bellImage, text: 'onboarding.benefits.instantNotifications' },
  { image: carImage, text: 'onboarding.benefits.drivingMode' },
  { image: lockImage, text: 'onboarding.benefits.beConnected' },
  { image: sosImage, text: 'onboarding.benefits.sosAlerts' },
]

export const BENEFITS_V2 = [
  {
    title: 'onboarding.benefits.stayConnected',
    subtitle: 'onboarding.benefits.familyFriends',
  },
  {
    title: 'onboarding.benefits.trackLocation',
    subtitle: 'onboarding.benefits.lovedOnes',
  },
  {
    title: 'onboarding.benefits.onePlan',
    subtitle: 'onboarding.benefits.everyoneYouCare',
  },
]

export const ANIMATION_SEGMENTS: AnimationSegment[] = [
  [0, 186],
  [186, 418],
  [418, 600],
]
