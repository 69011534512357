import React from 'react'
import { useTranslation } from 'react-i18next'

import { usePurchaseStore } from 'hooks/usePurchaseStore'

import { PriceValue } from 'components/PriceValue'

import paymentServicesImage from 'assets/images/payment-services-grey-bg.png'

import {
  CURRENCY_SYMBOLS,
  TRIAL_IN_HOURS,
  TimeInterval,
} from 'root-constants/common'

import { StyledPaymentFlowsGoogleVariant4 as S } from './PaymentFlowsGoogleVariant4.styles'

export const PaymentFlowsGoogleVariant4: React.FC = () => {
  const { t } = useTranslation()

  const {
    selectedSubscriptionId,
    currentPrice,
    currency,
    trialPrice,
    trialPeriodDays,
  } = usePurchaseStore()

  return (
    <S.Wrapper>
      <S.PhoneNumberLabel />
      <S.PriceContainer>
        <S.PriceText gridArea="priceText">{t`payment.googlePayment`}</S.PriceText>
        <S.PriceValue gridArea="priceValue">
          <PriceValue value={trialPrice} currency={currency} />
        </S.PriceValue>
        <S.TrialDurationText>
          {t('payment.trial', {
            period: TRIAL_IN_HOURS[trialPeriodDays],
          })}
        </S.TrialDurationText>
      </S.PriceContainer>
      <S.PaymentRequestButton key={selectedSubscriptionId} />

      <S.StripePaymentForm />

      <S.Disclaimer>
        {t('payment.googleFlowDisclaimer', {
          period: TRIAL_IN_HOURS[trialPeriodDays],
          trialPeriodDays,
          currencySymbol: CURRENCY_SYMBOLS[currency],
          price: currentPrice,
          context: TimeInterval.DAY,
        })}
      </S.Disclaimer>
      <S.Image
        src={paymentServicesImage}
        decoding="async"
        alt="payments services"
      />
      <S.Guarantee>{t`payment.guarantee`}</S.Guarantee>
    </S.Wrapper>
  )
}
