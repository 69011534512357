import styled, { css } from 'styled-components'

import periodBgGreenImage from 'assets/images/price-bg-trial.svg'
import periodBgWhiteImage from 'assets/images/price-bg-white.svg'
import checkIcon from 'assets/images/success.svg'

import { Color, MediaBreakpoint } from 'root-constants/common'

type TPriceProps = {
  isMexicanPeso: boolean
}

const activePlanItemStyles = css`
  border-color: #7879f1;
  background-color: transparent;

  ::before {
    background: center url(${checkIcon}) no-repeat;
    background-size: 41px;
  }

  &[data-has-laptop-styles='true'] {
    @media (min-width: ${MediaBreakpoint.LAPTOP}px) {
      background-color: #fff;
    }
  }
`

const defaultPlanItemStyles = css`
  position: relative;

  &::after {
    content: attr(data-text);
    display: block;
    padding: 0 16px;
    position: absolute;
    top: -15px;
    left: 50%;
    transform: translateX(-50%);
    height: 26px;
    font-size: 10px;
    line-height: 26px;
    font-weight: 800;
    text-transform: uppercase;
    color: #ffffff;
    background-color: #4946d2;
    border-radius: 13px;
  }
`

const PlanItem = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 24px 16px 24px 56px;
  border-radius: 16px;
  border: 2px solid transparent;
  background-color: #eff5f7;
  transition: all 0.2s ease-out;

  &[data-is-default='true'] {
    ${defaultPlanItemStyles};
  }

  ::before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 16px;
    width: 24px;
    height: 24px;
    margin: auto;
    border: 2px solid #ccdce5;
    border-radius: 100%;
  }

  &[data-is-selected='true']::before {
    border-color: #7bc27e;
  }

  &[data-has-laptop-styles='true'] {
    @media (min-width: ${MediaBreakpoint.LAPTOP}px) {
      background-color: #fff;
    }
  }
`

export const StyledTrialPlanItemVariant1 = {
  PlanItem: styled(PlanItem)`
    &[data-is-selected='true'] {
      ${activePlanItemStyles};
      border-color: ${Color.ACCENT_VIOLET};
    }

    &[data-is-default='true'] {
      &::after {
        background-color: ${Color.ACCENT_VIOLET};
      }
    }

    &[data-has-smaller-padding='true'] {
      padding: 24px 10px 24px 48px;
    }
  `,
  PlanTitleWrapper: styled.div`
    display: flex;
    flex-direction: column;
  `,
  PlanTitle: styled.div`
    flex-grow: 1;
    font-size: 18px;
    font-weight: 800;
    line-height: 22px;

    &[data-is-es-lang='true'] {
      text-transform: none;
    }
  `,
  PlanPricesWrapper: styled.div`
    display: flex;
    align-items: center;
  `,
  OldPlanPrice: styled.p<TPriceProps>`
    margin-right: 6px;
    font-size: ${({ isMexicanPeso }) => (isMexicanPeso ? '12px' : '14px')};
    line-height: 22px;
    font-weight: 500;
    text-decoration: line-through;
    text-decoration-color: ${Color.RED};
    color: ${Color.LIGHT_TEXT};
  `,
  CurrentPrice: styled.p<TPriceProps>`
    margin-right: 6px;
    font-size: ${({ isMexicanPeso }) => (isMexicanPeso ? '12px' : '14px')};
    line-height: 22px;
    font-weight: 500;
    color: ${Color.LIGHT_TEXT};
  `,
  PlanPrice: styled.div<TPriceProps>`
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    gap: 4px;
    flex-shrink: 0;
    width: 87px;
    height: 48px;
    color: #0a324d;
    background: top right url(${periodBgWhiteImage}) no-repeat;

    &[data-is-selected='true'] {
      color: #ffffff;
      background: top right url(${periodBgGreenImage}) no-repeat;
    }

    > strong {
      font-size: ${({ isMexicanPeso }) => (isMexicanPeso ? '12px' : '18px')};
      font-weight: 800;
      line-height: ${({ isMexicanPeso }) => (isMexicanPeso ? '16px' : '20px')};
      margin-top: 7px;
      margin-left: 5px;
    }

    > sub {
      font-size: 10px;
      font-weight: 600;
      line-height: 10px;
      color: ${Color.LIGHT_TEXT};

      &[data-is-selected='true'] {
        color: #ffffff;
      }
    }
  `,
}
