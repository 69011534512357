import React from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'

import { useGetFlowInfo } from 'hooks/useGetFlowInfo'
import { useNextStep } from 'hooks/useNextStep'

import { TPageProps } from 'models/common.model'

import { Button } from 'components/Button'

import stars from 'assets/images/stars.svg'
import avatar from 'assets/images/user.jpg'

import { PageId } from 'root-constants/pages'

import { StyledFeedback as S } from './Feedback.styles'
import { PAGE_NAME } from './constants'

export const FeedbackVariant1: React.FC<TPageProps> = ({
  pageId,
  nextPagePath,
}) => {
  const { t } = useTranslation()
  const { search } = useLocation()

  const { isSafetyFlow } = useGetFlowInfo()

  const handleContinue = useNextStep({
    pageId,
    pageName: PAGE_NAME,
    question: '',
    nextPagePath: isSafetyFlow
      ? nextPagePath
      : `${PageId.PARTNERS_DATA_1}${search}`,
  })

  return (
    <S.Wrapper>
      <S.Column>
        <S.Title>{t`onboarding.feedback.title`}</S.Title>
        <S.Image
          marginBottom={8}
          aspectRatio={1}
          src={avatar}
          alt="Mitchell"
          width={56}
        />
        <S.UserName>{t`onboarding.feedback.userName`}</S.UserName>
        <S.Image
          marginBottom={0}
          aspectRatio={136 / 24}
          src={stars}
          alt="5 stars"
          width={136}
        />
        <S.Text>{t`onboarding.feedback.feedbackText`}</S.Text>
        <S.Note>
          <Trans i18nKey="onboarding.feedback.note" />
        </S.Note>
        <S.StickyButtonContainer>
          <Button onClick={() => handleContinue('')}>
            {t`actions.continue`}
          </Button>
        </S.StickyButtonContainer>
      </S.Column>
    </S.Wrapper>
  )
}
