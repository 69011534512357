import styled from 'styled-components'

import { baseAdaptiveColumnStyles, basePageStyles } from 'common-styles'
import { HEADER_HEIGHT, MediaBreakpoint } from 'root-constants/common'

export const StyledSocialProofVariant8 = {
  Wrapper: styled.div`
    ${basePageStyles};
    min-height: 100%;
    padding-top: ${HEADER_HEIGHT}px;
  `,
  Column: styled.div`
    ${baseAdaptiveColumnStyles};
    padding: 26px 0 40px;

    @media (min-height: 670px) {
      position: fixed;
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);
    }
  `,
  ImageContainer: styled.div`
    max-width: ${MediaBreakpoint.MAX_PHONE}px;
    margin: 0 auto;
    aspect-ratio: 375/309;

    @media (min-width: ${MediaBreakpoint.TABLET}px) {
      max-width: 100%;
    }
  `,
  Text: styled.p`
    font-size: 16px;
    line-height: 22px;
    text-align: center;
    margin-bottom: 16px;
  `,
  Note: styled.span`
    font-style: italic;
    font-size: 13px;
    line-height: 20px;
    text-align: center;
    color: #bcc9d0;
    margin-bottom: 16px;
  `,
}
