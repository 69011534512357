import React from 'react'
import { useTranslation } from 'react-i18next'

import { useDelayedContinue } from 'hooks/useDelayedContinue'
import { useNextStep } from 'hooks/useNextStep'

import { TPageProps } from 'models/common.model'

import { Option, OptionType } from 'components/Option'
import { StyledOption } from 'components/Option/Option.styles'
import { QuestionButton } from 'components/QuestionButton'

import { Confidence, Language } from 'root-constants/common'

import { StyledChildLocation as S } from './ChildLocation.styles'
import { PAGE_NAME } from './constants'

export const ChildLocationVariant1: React.FC<TPageProps> = ({
  pageId,
  nextPagePath,
}) => {
  const { t } = useTranslation()

  const { isAnswersDisabled, handleChange, setIsAnswersDisabled } =
    useDelayedContinue()

  const handleContinue = useNextStep({
    pageId,
    pageName: PAGE_NAME,
    question: t('onboarding.childLocation.title', { lng: Language.EN }),
    nextPagePath,
  })

  const optionProps = {
    name: pageId,
    type: OptionType.RADIO,
    onChange: (value: string) => {
      handleChange(value, handleContinue)
      setIsAnswersDisabled(true)
    },
  }

  return (
    <S.Wrapper>
      <S.Column>
        <S.Title>{t`onboarding.childLocation.title`}</S.Title>
        <StyledOption.OptionsContainer>
          <Option
            {...optionProps}
            value={Confidence.YES}
            disabled={isAnswersDisabled}
          >
            <QuestionButton>{t`commonComponents.yes`}</QuestionButton>
          </Option>

          <Option
            {...optionProps}
            value={Confidence.NO}
            disabled={isAnswersDisabled}
          >
            <QuestionButton>{t`commonComponents.no`}</QuestionButton>
          </Option>

          <Option
            {...optionProps}
            value={Confidence.NOT_SURE}
            disabled={isAnswersDisabled}
          >
            <QuestionButton>{t`commonComponents.notSure`}</QuestionButton>
          </Option>
        </StyledOption.OptionsContainer>
      </S.Column>
    </S.Wrapper>
  )
}
