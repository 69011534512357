import { getFormattedPrice } from 'helpers/getFormattedPrice'

import { TOldPrice, TRawPriceAmount } from 'models/subscriptions.model'

export const getOldPricesList = (oldPrice: TRawPriceAmount): TOldPrice => ({
  fullPrice: getFormattedPrice(oldPrice?.amount),
  amountOfDiscount: getFormattedPrice(oldPrice?.amount_of_discount),
  weekly: getFormattedPrice(oldPrice?.in_the_box.weekly),
  daily: getFormattedPrice(oldPrice?.in_the_box.daily),
  monthly: getFormattedPrice(oldPrice?.in_the_box.monthly),
  yearly: getFormattedPrice(oldPrice?.in_the_box.yearly),
  percentOfDiscount: oldPrice?.percent_of_discount,
})
