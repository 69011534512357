import { TrackingData } from 'root-constants/common'

export const PAGE_NAME = 'Preparing Personal Flow'
export const CHILD_FLOW_PROGRESS_DURATION = 5000

export const CHILD_FLOW_PROGRESS_TEXTS = {
  [TrackingData.MOVEMENT_HISTORY]: 'onboarding.data.answers.movementHistory',
  [TrackingData.LOCATION_NOTIFICATIONS]:
    'onboarding.data.answers.locationNotifications',
  [TrackingData.SOS_NOTIFICATIONS]: 'onboarding.data.answers.sosNotifications',
  [TrackingData.BATTERY_ALERTS]: 'onboarding.data.answers.batteryAlerts',
  [TrackingData.DRIVING_MODE]: 'onboarding.data.answers.drivingMode2',
  [TrackingData.PROXIMITY_ALERTS]:
    'onboarding.data.answers.proximityAlertsShort',
}
