import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { usePurchaseStore } from 'hooks/usePurchaseStore'

import {
  CURRENCY_SYMBOLS,
  TRIAL_IN_HOURS,
  TimeInterval,
  TrialPeriod,
} from 'root-constants/common'

import { StyledBenefitsVariant2 as S } from './BenefitsVariant2.styles'
import { GEOLOCATION_BENEFITS } from './constants'

type TProps = {
  benefits?: string[]
}

export const BenefitsVariant2: React.FC<TProps> = ({
  benefits = GEOLOCATION_BENEFITS,
}) => {
  const { t } = useTranslation()

  const { trialPrice, trialPeriodDays, currentPrice, currency } =
    usePurchaseStore()

  const textParameters = useMemo(() => {
    return trialPeriodDays >= TrialPeriod.THREE_DAYS
      ? {
          trialPrice,
          price: currentPrice,
          currencySymbol: CURRENCY_SYMBOLS[currency],
          period: trialPeriodDays,
          context: TimeInterval.DAY,
          minimumFractionDigits: 2,
        }
      : {
          trialPrice,
          price: currentPrice,
          currencySymbol: CURRENCY_SYMBOLS[currency],
          period: TRIAL_IN_HOURS[trialPeriodDays],
          minimumFractionDigits: 2,
        }
  }, [currency, currentPrice, trialPeriodDays, trialPrice])

  return (
    <S.Wrapper>
      <S.Title>
        {t`subscriptions.subscriptionsGoogle.variant1.geolocationBenefits.title`}
      </S.Title>
      <ul>
        {benefits.map((text) => (
          <S.Text key={text}>{t(text, textParameters)}</S.Text>
        ))}
      </ul>
    </S.Wrapper>
  )
}
