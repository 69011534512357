import { useSelector } from 'react-redux'

import {
  selectActionList,
  selectCurrentVariantCohort,
  selectCurrentVariantCohortToUse,
  selectDynamicDiscount,
  selectHasCancelOffer,
  selectIsFetching,
  selectLanguage,
  selectPayPalClientId,
  selectScreenName,
  selectStripeAccountId,
  selectStripeAccountName,
  selectSubscriptionList,
  selectTaxAmount,
} from 'root-redux/selects/common'
import {
  selectEmail,
  selectHasUserEmail,
  selectHasUserSubscription,
  selectUUID,
  selectUserCountryCode,
} from 'root-redux/selects/user'

import { compareSubscriptionList } from 'helpers/compareSubscriptionList'

import {
  select3DSecureIframeUrl,
  selectCurrency,
  selectIsPaymentFlowsShown,
  selectPayPalPlanId,
  selectPaymentMethod,
  selectPaymentRequestButtonType,
  selectSubscription,
  selectSubscriptionFullPrice,
  selectSubscriptionFullPriceTax,
  selectSubscriptionId,
  selectSubscriptionLookupKey,
  selectSubscriptionOldPriceBeforeCustomDiscount,
  selectSubscriptionOldTrialPriceBeforeCustomDiscount,
  selectSubscriptionPeriodName,
  selectSubscriptionPeriodQuantity,
  selectSubscriptionTrialLookupKey,
  selectSubscriptionTrialPeriodDays,
  selectSubscriptionTrialPeriodPrice,
  selectSubscriptionTrialPeriodPriceTax,
  selectUpgradeAmountToPay,
} from 'modules/payment/redux/selects'

export const usePurchaseStore = () => {
  const email = useSelector(selectEmail)
  const cohort = useSelector(selectCurrentVariantCohort)
  const cohortToUse = useSelector(selectCurrentVariantCohortToUse)
  const uuid = useSelector(selectUUID)
  const language = useSelector(selectLanguage)
  const subscriptions = useSelector(
    selectSubscriptionList,
    compareSubscriptionList,
  )
  const selectedSubscription = useSelector(selectSubscription)
  const selectedSubscriptionId = useSelector(selectSubscriptionId)
  const priceId = useSelector(selectSubscriptionLookupKey)
  const trialPriceId = useSelector(selectSubscriptionTrialLookupKey)
  const trialPrice = useSelector(selectSubscriptionTrialPeriodPrice)
  const oldTrialPrice = useSelector(
    selectSubscriptionOldTrialPriceBeforeCustomDiscount,
  )
  const trialPeriodDays = useSelector(selectSubscriptionTrialPeriodDays)
  const trialPriceTax = useSelector(selectSubscriptionTrialPeriodPriceTax)
  const fullPriceTax = useSelector(selectSubscriptionFullPriceTax)
  const currentPrice = useSelector(selectSubscriptionFullPrice)
  const oldPrice = useSelector(selectSubscriptionOldPriceBeforeCustomDiscount)
  const currency = useSelector(selectCurrency)
  const periodName = useSelector(selectSubscriptionPeriodName)
  const periodQuantity = useSelector(selectSubscriptionPeriodQuantity)
  const isPaymentFlowsShown = useSelector(selectIsPaymentFlowsShown)
  const paymentMethod = useSelector(selectPaymentMethod)
  const paymentRequestButtonType = useSelector(selectPaymentRequestButtonType)
  const hasUserEmail = useSelector(selectHasUserEmail)
  const hasSubscription = useSelector(selectHasUserSubscription)
  const hasCancelOffer = useSelector(selectHasCancelOffer)
  const stripeAccountName = useSelector(selectStripeAccountName)
  const stripeAccountId = useSelector(selectStripeAccountId)
  const paypalPlanId = useSelector(selectPayPalPlanId)
  const paypalClientId = useSelector(selectPayPalClientId)
  const screenName = useSelector(selectScreenName)
  const isFetching = useSelector(selectIsFetching)
  const dynamicDiscount = useSelector(selectDynamicDiscount)
  const taxAmount = useSelector(selectTaxAmount)
  const amountToPay = useSelector(selectUpgradeAmountToPay)
  const fetchingActionsList = useSelector(selectActionList)
  const threeDSecureIframeUrl = useSelector(select3DSecureIframeUrl)
  const countryCode = useSelector(selectUserCountryCode)

  return {
    email,
    cohort,
    cohortToUse,
    uuid,
    language,
    subscriptions,
    selectedSubscription,
    selectedSubscriptionId,
    priceId,
    trialPriceId,
    trialPrice,
    oldTrialPrice,
    trialPriceTax,
    fullPriceTax,
    trialPeriodDays,
    currentPrice,
    oldPrice,
    currency,
    periodName,
    periodQuantity,
    isPaymentFlowsShown,
    paymentMethod,
    paymentRequestButtonType,
    hasUserEmail,
    hasSubscription,
    hasCancelOffer,
    stripeAccountName,
    stripeAccountId,
    paypalPlanId,
    paypalClientId,
    screenName,
    isFetching,
    threeDSecureIframeUrl,
    dynamicDiscount,
    taxAmount,
    amountToPay,
    fetchingActionsList,
    countryCode,
  }
}
