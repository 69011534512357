import React from 'react'
import { useTranslation } from 'react-i18next'

import { StyledGoodHands as S } from './GoodHands.styles'

export const GoodHands: React.FC = () => {
  const { t } = useTranslation()

  return (
    <S.Wrapper>
      <S.Title>{t`subscriptions.subscriptionsGoogle.variant3.goodHands.title`}</S.Title>
      <S.UsersInfoContainer>
        <S.Percents>{t`subscriptions.subscriptionsGoogle.variant3.goodHands.percents`}</S.Percents>
        <S.Users>{t`subscriptions.subscriptionsGoogle.variant3.goodHands.users`}</S.Users>
      </S.UsersInfoContainer>
      <S.Statement>{t`subscriptions.subscriptionsGoogle.variant3.goodHands.statement`}</S.Statement>
    </S.Wrapper>
  )
}
