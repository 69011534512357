import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { useDelayedContinue } from 'hooks/useDelayedContinue'
import { useNextStep } from 'hooks/useNextStep'

import { TPageProps } from 'models/common.model'

import { Option, OptionType } from 'components/Option'
import { StyledOption } from 'components/Option/Option.styles'
import { QuestionButton } from 'components/QuestionButton'

import { Frequency, Language } from 'root-constants/common'

import { StyledQuickWay as S } from './QuickWay.styles'
import { PAGE_NAMES } from './constants'

export const QuickWayVariant1: React.FC<TPageProps> = ({
  pageId,
  nextPagePath,
}) => {
  const { t } = useTranslation()

  const { isAnswersDisabled, handleChange, setIsAnswersDisabled } =
    useDelayedContinue()

  const handleContinue = useNextStep({
    pageId,
    pageName: PAGE_NAMES[pageId],
    question: t('onboarding.childSchoolIssues.title', { lng: Language.EN }),
    nextPagePath,
  })
  const optionProps = useMemo(
    () => ({
      name: pageId,
      type: OptionType.RADIO,
      onChange: (value: string) => {
        handleChange(value, handleContinue)
        setIsAnswersDisabled(true)
      },
    }),
    [handleChange, handleContinue, pageId, setIsAnswersDisabled],
  )

  return (
    <S.Wrapper>
      <S.Column>
        <S.Title>{t`onboarding.quickWay.title`}</S.Title>
        <StyledOption.OptionsContainer>
          <Option
            {...optionProps}
            value={Frequency.QUITE_OFTEN}
            disabled={isAnswersDisabled}
          >
            <QuestionButton>{t`commonComponents.quiteOften`}</QuestionButton>
          </Option>
          <Option
            {...optionProps}
            value={Frequency.SOMETIMES}
            disabled={isAnswersDisabled}
          >
            <QuestionButton>{t`commonComponents.sometimes`}</QuestionButton>
          </Option>
          <Option
            {...optionProps}
            value={Frequency.RARELY}
            disabled={isAnswersDisabled}
          >
            <QuestionButton>{t`commonComponents.rarely`}</QuestionButton>
          </Option>
          <Option
            {...optionProps}
            value={Frequency.NEVER}
            disabled={isAnswersDisabled}
          >
            <QuestionButton>{t`commonComponents.never`}</QuestionButton>
          </Option>
        </StyledOption.OptionsContainer>
      </S.Column>
    </S.Wrapper>
  )
}
