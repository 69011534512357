import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { useNextStep } from 'hooks/useNextStep'
import { useUserData } from 'hooks/useUserData'

import { TPageProps } from 'models/common.model'

import { Button } from 'components/Button'
import { FixedContainer } from 'components/FixedContainer'
import { Option, OptionType } from 'components/Option'
import { StyledOption } from 'components/Option/Option.styles'
import { QuestionButton } from 'components/QuestionButton'

import { CheckboxCustomValue, ChildFlow, Language } from 'root-constants/common'

import { StyledConcernsVariant2 as S } from './ConcernsVariant2.styles'
import { OPTION_VALUES, PAGE_OPTION_VALUES } from './constants'

export const ConcernsVariant2: React.FC<TPageProps> = ({
  pageId,
  nextPagePath,
}) => {
  const { t } = useTranslation()
  const [answers, setAnswers] = useState<string[]>([])
  const { childFlow } = useUserData()

  const isTeenageFlow = childFlow === ChildFlow.TEENAGE

  const handleContinue = useNextStep({
    pageId,
    pageName: pageId,
    question: t('onboarding.concerns.question2', { lng: Language.EN }),
    nextPagePath,
  })

  const optionProps = {
    name: pageId,
    type: OptionType.CHECKBOX,
    onChange: (value: string, isChecked: boolean) => {
      if (isChecked) {
        value === CheckboxCustomValue.ALL_OF_THE_ABOVE
          ? setAnswers(PAGE_OPTION_VALUES[pageId])
          : setAnswers([...answers, value])
        return
      }

      value === CheckboxCustomValue.ALL_OF_THE_ABOVE
        ? setAnswers([])
        : setAnswers(answers.filter((item) => item !== value))
    },
  }

  return (
    <S.Wrapper>
      <S.Column>
        <S.Title>{t`onboarding.concerns.question2`}</S.Title>
        <StyledOption.OptionsContainer paddingBottom={4}>
          {isTeenageFlow && (
            <Option
              {...optionProps}
              value={OPTION_VALUES.DRUGS_ALCOHOL}
              checked={answers.includes(OPTION_VALUES.DRUGS_ALCOHOL)}
            >
              <QuestionButton hasCheckboxIcon>
                {t`onboarding.concerns.answers.drugsAlcohol`}
              </QuestionButton>
            </Option>
          )}
          <Option
            {...optionProps}
            value={OPTION_VALUES.HURT}
            checked={answers.includes(OPTION_VALUES.HURT)}
          >
            <QuestionButton hasCheckboxIcon>
              {t`onboarding.fear.answers.hurt`}
            </QuestionButton>
          </Option>
          <Option
            {...optionProps}
            value={OPTION_VALUES.DANGEROUS_PLACE}
            checked={answers.includes(OPTION_VALUES.DANGEROUS_PLACE)}
          >
            <QuestionButton hasCheckboxIcon>
              {t`onboarding.fear.answers.dangerousPlace`}
            </QuestionButton>
          </Option>
          {isTeenageFlow && (
            <Option
              {...optionProps}
              value={OPTION_VALUES.BAD_COMPANY}
              checked={answers.includes(OPTION_VALUES.BAD_COMPANY)}
            >
              <QuestionButton hasCheckboxIcon>
                {t`onboarding.concerns.answers.badCompany`}
              </QuestionButton>
            </Option>
          )}
          {!isTeenageFlow && (
            <Option
              {...optionProps}
              value={OPTION_VALUES.HIT_BY_CAR}
              checked={answers.includes(OPTION_VALUES.HIT_BY_CAR)}
            >
              <QuestionButton hasCheckboxIcon>
                {t`onboarding.fear.answers.hitByCar`}
              </QuestionButton>
            </Option>
          )}
          <Option
            {...optionProps}
            value={OPTION_VALUES.KIDNAPPED}
            checked={answers.includes(OPTION_VALUES.KIDNAPPED)}
          >
            <QuestionButton hasCheckboxIcon>
              {t`onboarding.fear.answers.kidnapped`}
            </QuestionButton>
          </Option>
          {!isTeenageFlow && (
            <Option
              {...optionProps}
              value={OPTION_VALUES.LOST}
              checked={answers.includes(OPTION_VALUES.LOST)}
            >
              <QuestionButton hasCheckboxIcon>
                {t`onboarding.concerns.answers.getLost`}
              </QuestionButton>
            </Option>
          )}
          <Option
            {...optionProps}
            value={OPTION_VALUES.OTHER}
            checked={answers.includes(OPTION_VALUES.OTHER)}
          >
            <QuestionButton hasCheckboxIcon>
              {t`commonComponents.other`}
            </QuestionButton>
          </Option>
          <Option
            {...optionProps}
            value={CheckboxCustomValue.ALL_OF_THE_ABOVE}
            checked={answers.length === PAGE_OPTION_VALUES[pageId].length}
          >
            <QuestionButton hasCheckboxIcon>
              {t`commonComponents.allOfTheAbove`}
            </QuestionButton>
          </Option>
        </StyledOption.OptionsContainer>
        <FixedContainer>
          <Button
            onClick={() => handleContinue(answers)}
            disabled={!answers.length}
          >
            {t`actions.continue`}
          </Button>
        </FixedContainer>
      </S.Column>
    </S.Wrapper>
  )
}
