import styled from 'styled-components'

import bgImage from 'assets/images/location-search-bg.jpg'
import desktopBgImage from 'assets/images/location-search-desktop-bg.jpg'

import { baseColumnStyles, basePageStyles } from 'common-styles'
import { Color, HEADER_HEIGHT, MediaBreakpoint } from 'root-constants/common'

type TSearchItemProps = {
  isVisible: boolean
}

type TSearchLocationProps = {
  isBlurred: boolean
}

type TSearchItemTextProps = {
  isFontOversized: boolean
}

export const StyledLocationSearchVariant1 = {
  Wrapper: styled.div`
    ${basePageStyles};
    padding-top: ${HEADER_HEIGHT}px;
  `,
  Content: styled.div`
    width: 100%;
    max-width: ${MediaBreakpoint.MAX_PHONE}px;
    height: calc(100dvh - ${HEADER_HEIGHT}px);
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    background-image: url(${bgImage});
    background-size: cover;
    background-repeat: no-repeat;

    @media (min-width: ${MediaBreakpoint.MAX_PHONE}px) {
      max-width: 375px;
    }

    @media (min-width: ${MediaBreakpoint.MAX_PHONE}px) {
      max-width: 100%;
      background-image: url(${desktopBgImage});
    }
  `,
  Column: styled.div`
    ${baseColumnStyles};
    padding: 8px 0 40px;

    @media (min-width: ${MediaBreakpoint.MAX_PHONE}px) {
      width: 400px;
    }
  `,
  TransmitterContainer: styled.div`
    margin-bottom: 24px;
  `,
  SearchTitle: styled.h3`
    margin-bottom: 6px;
    color: ${Color.LIGHT_TEXT};
    text-align: center;
    font-size: 20px;
    font-weight: 500;
    line-height: 27px;

    @media (min-width: ${MediaBreakpoint.LAPTOP}px) {
      font-size: 26px;
      line-height: 35px;
      margin-bottom: 8px;
    }
  `,
  PhoneNumber: styled.p`
    margin-bottom: 14px;
    text-align: center;
    font-size: 28px;
    font-weight: 700;
    line-height: 38px;

    @media (min-width: ${MediaBreakpoint.LAPTOP}px) {
      font-size: 38px;
      line-height: 55px;
      margin-bottom: 24px;
    }
  `,
  SearchItem: styled.div<TSearchItemProps>`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 57px;
    padding: 0 16px;
    margin-bottom: 12px;
    border-radius: 12px;
    border: 1px solid #97b8ff;
    background: ${Color.WHITE};
    opacity: ${({ isVisible }) => (isVisible ? 1 : 0)};
    transition-duration: 300ms;
    transition-property: opacity;
    transition-timing-function: ease-out;

    &:last-of-type {
      padding: 0 8px 0 16px;
    }
  `,
  SearchItemText: styled.p<TSearchItemTextProps>`
    font-size: 18px;
    font-weight: 700;
    line-height: 25px;

    @media (min-width: ${MediaBreakpoint.LAPTOP}px) {
      line-height: 27px;
      font-size: ${({ isFontOversized }) => (isFontOversized ? 18 : 20)}px;
    }
  `,
  SearchItemLocation: styled.p<TSearchLocationProps>`
    font-size: 18px;
    font-weight: 700;
    line-height: 25px;
    color: ${Color.BLUE};
    filter: ${({ isBlurred }) => (isBlurred ? 'blur(4px)' : 'none')};

    @media (min-width: ${MediaBreakpoint.LAPTOP}px) {
      font-size: 20px;
      line-height: 27px;
    }
  `,
  LoaderAnimationContainer: styled.div`
    height: 24px;
  `,
  DefinedLocationLabel: styled.p`
    display: flex;
    place-content: center;
    padding: 8px 12px 8px 8px;
    border-radius: 9px;
    background: ${Color.ACCENT_VIOLET};
    color: ${Color.WHITE};
    text-align: right;
    font-size: 18px;
    font-weight: 700;
    line-height: 25px;
  `,
}
