import React from 'react'

import { useDynamicPage } from 'hooks/useDynamicPage'

import { TPageProps } from 'models/common.model'

import { BurgerMenu } from 'components/BurgerMenu'
import { Option } from 'components/Option'
import { PoliciesGroup } from 'components/PoliciesGroup'

import { StyledDynamicIntroMultipleChoice as S } from './DynamicIntroMultipleChoice.styles'

export const DynamicIntroMultipleChoice: React.FC<TPageProps> = ({
  pageId,
  nextPagePath,
}) => {
  const {
    title,
    subtitle,
    buttonText,
    optionData,
    optionProps,
    imageUrls: [firstImage],
    pageTexts: [firstText],
    answers,
    handleContinue,
  } = useDynamicPage(pageId, nextPagePath)

  return (
    <S.Wrapper>
      <BurgerMenu />
      <S.ImageContainer imageSrc={firstImage}>
        <S.Title>{title}</S.Title>
      </S.ImageContainer>
      <S.FixedContainer>
        <S.Column>
          {!!subtitle && <S.Question>{subtitle}</S.Question>}
          {!!firstText && <S.Note>{firstText}</S.Note>}

          <S.OptionsContainer>
            {optionData?.map(({ id, value, text }) => (
              <Option
                {...optionProps}
                key={id}
                value={value}
                checked={answers.includes(value)}
              >
                <S.QuestionButton>{text}</S.QuestionButton>
              </Option>
            ))}
          </S.OptionsContainer>

          <S.Button
            onClick={() => handleContinue(answers)}
            disabled={!answers.length}
          >
            {buttonText}
          </S.Button>
          <PoliciesGroup />
        </S.Column>
      </S.FixedContainer>
    </S.Wrapper>
  )
}
