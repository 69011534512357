import avatar1 from 'assets/images/review-avatar-4.webp'
import avatar2 from 'assets/images/review-avatar-5.png'
import avatar3 from 'assets/images/review-avatar-15.png'
import avatar4 from 'assets/images/review-avatar-16.png'
import borderImage1 from 'assets/images/reviews-border-1.svg'
import borderImage2 from 'assets/images/reviews-border-2.svg'
import borderImage3 from 'assets/images/reviews-border-3.svg'

export const REVIEWS_DATA = [
  {
    borderImage: borderImage1,
    avatar: avatar1,
    labelText: 'onboarding.reviews.partner',
    mainText: 'onboarding.reviews.firstUser.mainText',
    additionalText: 'onboarding.reviews.firstUser.additionalText',
    name: 'onboarding.reviews.firstUser.name',
  },
  {
    borderImage: borderImage2,
    avatar: avatar2,
    labelText: 'onboarding.reviews.kid',
    mainText: 'onboarding.reviews.secondUser.mainText',
    additionalText: 'onboarding.reviews.secondUser.additionalText',
    name: 'onboarding.reviews.secondUser.name',
  },
  {
    borderImage: borderImage3,
    avatar: avatar3,
    labelText: 'onboarding.reviews.friend',
    mainText: 'onboarding.reviews.thirdUser.mainText',
    additionalText: 'onboarding.reviews.thirdUser.additionalText',
    name: 'onboarding.reviews.thirdUser.name',
  },
  {
    borderImage: borderImage2,
    avatar: avatar4,
    labelText: 'onboarding.reviews.parent',
    mainText: 'onboarding.reviews.fourthUser.mainText',
    additionalText: 'onboarding.reviews.fourthUser.additionalText',
    name: 'onboarding.reviews.fourthUser.name',
  },
]
