export const TITLE_PATH = 'onboarding.kidOutdoorActivities.title'

export const OPTION_DATA = [
  {
    value: 'daily',
    text: 'onboarding.kidOutdoorActivities.daily',
  },
  {
    value: 'weekly',
    text: 'onboarding.kidOutdoorActivities.weekly',
  },
  {
    value: 'monthly',
    text: 'onboarding.kidOutdoorActivities.monthly',
  },
  {
    value: 'rarely',
    text: 'onboarding.kidOutdoorActivities.rarely',
  },
]
