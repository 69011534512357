import female29 from 'assets/images/female-29.png'
import female44 from 'assets/images/female-44.png'
import female59 from 'assets/images/female-59.png'
import female60 from 'assets/images/female-60.png'
import fifteenYears from 'assets/images/fifteen-years.png'
import fiveYears from 'assets/images/five-years.png'
import male29 from 'assets/images/male-29.png'
import male44 from 'assets/images/male-44.png'
import male59 from 'assets/images/male-59.png'
import male60 from 'assets/images/male-60.png'
import sixteenYears from 'assets/images/sixteen-years.png'
import tenYears from 'assets/images/ten-years.png'

import { Gender } from 'root-constants/common'

export const AGE_RANGE_DATA = [
  {
    text: '0-5',
    value: '5',
    image: fiveYears,
  },
  {
    text: '6-10',
    value: '10',
    image: tenYears,
  },
  {
    text: '11-15',
    value: '15',
    image: fifteenYears,
  },
  {
    text: '16+',
    value: '16',
    image: sixteenYears,
  },
]
export const AGE_RANGE_DATA_2 = [
  {
    text: '18-29',
    value: '18-29',
    imgSrc: {
      [Gender.MALE]: male29,
      [Gender.FEMALE]: female29,
    },
  },
  {
    text: '30-44',
    value: '30-44',
    imgSrc: {
      [Gender.MALE]: male44,
      [Gender.FEMALE]: female44,
    },
  },
  {
    text: '45-59',
    value: '45-59',
    imgSrc: {
      [Gender.MALE]: male59,
      [Gender.FEMALE]: female59,
    },
  },
  {
    text: '60+',
    value: '60+',
    imgSrc: {
      [Gender.MALE]: male60,
      [Gender.FEMALE]: female60,
    },
  },
]
