import styled from 'styled-components'

import { BurgerMenu } from 'components/BurgerMenu'

import checkboxViolet from 'assets/images/checkbox-violet.svg'

import { basePageStyles } from 'common-styles'
import { Color, HEADER_HEIGHT, MediaBreakpoint } from 'root-constants/common'

export const StyledIntroGoogleVariant3 = {
  Wrapper: styled.div`
    ${basePageStyles};
    padding-top: ${HEADER_HEIGHT}px;
    padding-bottom: 36px;

    img {
      max-width: ${MediaBreakpoint.MAX_PHONE}px;

      @media (min-width: ${MediaBreakpoint.MAX_PHONE}px) {
        max-width: 375px;
      }
    }
  `,
  BurgerMenu: styled(BurgerMenu)`
    button {
      top: 6px;
      right: 6px;

      @media (min-width: ${MediaBreakpoint.LAPTOP}px) {
        top: 3px;
        right: 3px;
      }
    }
  `,
  Title: styled.h1`
    color: #0a324d;
    font-size: 32px;
    font-weight: 800;
    line-height: 38px;
    text-align: center;
    margin-bottom: 12px;
    margin-top: 24px;

    span {
      color: ${Color.BLUE};
    }
  `,
  Image: styled.img`
    width: 100%;
    aspect-ratio: 375/247;
  `,
  PhoneForm: styled.form`
    width: 343px;
    padding: 16px 0;
    border-radius: 16px;
    background: #eff5f7;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: -40px;
    justify-content: center;
    gap: 16px;
  `,
  PhoneText: styled.h3`
    color: #0a324d;
    text-align: center;
    font-size: 20px;
    font-weight: 700;
    line-height: 28px;
    width: 296px;
  `,
  PhoneErrorText: styled.p`
    color: #ff0000;
    text-align: center;
    font-size: 18px;
    font-weight: 600;
    line-height: 20px;
  `,
  ReviewsSubtitle: styled.h3`
    color: #0a324d;
    text-align: center;
    font-size: 24px;
    font-weight: 800;
    line-height: 28px;
    margin-top: 52px;
    margin-bottom: 24px;
  `,
  BenefitsWrapper: styled.div`
    display: flex;
    width: 311px;
    padding: 24px 16px;
    flex-direction: column;
    gap: 16px;
    border-radius: 16px;
    background: linear-gradient(
      266deg,
      rgba(212, 178, 255, 0.15) 44.65%,
      rgba(254, 182, 224, 0.15) 100%
    );
    margin-top: 64px;
    margin-bottom: 24px;
  `,
  BenefitsSubtitle: styled.h3`
    color: #0a324d;
    text-align: center;
    font-size: 24px;
    font-weight: 800;
    line-height: 28px;
  `,
  ListItem: styled.li`
    position: relative;
    margin-bottom: 18px;
    padding-left: 35px;
    color: #0a324d;
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
    margin-left: 10px;

    ::before {
      content: '';
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
      display: block;
      width: 23px;
      height: 23px;
      background-image: url(${checkboxViolet});
      background-size: 23px;
    }
  `,
  SecurityWrapper: styled.div`
    width: 311px;
    margin-top: 40px;
  `,
}
