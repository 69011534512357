import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { setUpUUIDAction } from 'root-redux/actions/user'
import {
  selectCurrentVariantCohort,
  selectGiaApiKeys,
  selectLanguage,
} from 'root-redux/selects/common'

import { getQueryParamsFromSearchString } from 'helpers/getQueryParamsFromSearchString'

import { TOU_PP_COOKIE_LINKS } from 'root-constants/common'

export const useSetUuid = () => {
  const dispatch = useDispatch()
  const cohort = useSelector(selectCurrentVariantCohort)
  const giaApiKeys = useSelector(selectGiaApiKeys)
  const language = useSelector(selectLanguage)
  const isExternalPage = TOU_PP_COOKIE_LINKS.includes(window.location.pathname)

  useEffect(() => {
    const searchParams = new URLSearchParams(document.location.search)
    const uuidFromUrl = searchParams.get('uuid')
    const utmTags = getQueryParamsFromSearchString(document.location.search)

    if (cohort && giaApiKeys && language && !isExternalPage) {
      dispatch(
        setUpUUIDAction({
          cohort,
          giaApiKey: giaApiKeys[language],
          uuid: uuidFromUrl,
          language,
          utmTags,
        }),
      )
    }
  }, [cohort, dispatch, giaApiKeys, language, isExternalPage])
}
