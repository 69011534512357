import styled from 'styled-components'

import { baseTitleStyles } from 'common-styles'
import { MediaBreakpoint } from 'root-constants/common'

type TWrapperProps = {
  hasLaptopStyles?: boolean
}
type TListItemProps = {
  checkboxIcon?: string
}
export const StyledBenefits = {
  Wrapper: styled.div<TWrapperProps>`
    margin: 41px 0 28px;

    ${({ hasLaptopStyles }) =>
      hasLaptopStyles &&
      `
        @media (min-width: ${MediaBreakpoint.LAPTOP}px) {
          width: 1200px;
          margin: 70px auto 105px;
    
          h2 {
            font-weight: 800;
            font-size: 38px;
            line-height: 52px;
            margin-bottom: 31px;
          }
    
          ul {
            display: flex;
            flex-wrap: wrap;
            justify-content: flex-start;
            gap: 0 36px;
    
            &[data-is-partner-goal='true'] {
              gap: 0 190px;
              justify-content: center;
            }
          }
    
          li {
            width: 376px;
            font-weight: 500;
            font-size: 20px;
            line-height: 28px;
            align-self: center;
            padding-left: 47px;
    
            ::before {
              width: 35px;
              height: 35px;
              background-size: 35px;
            }
          }
        }
    `}
  `,
  Title: styled.h2`
    ${baseTitleStyles};
    color: ${({ theme }) => theme.colors.primaryText};
    margin-bottom: 24px;
  `,
  List: styled.ul``,
  ListItem: styled.li<TListItemProps>`
    position: relative;
    margin-bottom: 18px;
    padding-left: 35px;
    font-size: 16px;
    line-height: 24px;
    font-weight: 700;
    color: ${({ theme }) => theme.colors.primaryText};

    ::before {
      content: '';
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
      display: block;
      width: 22px;
      height: 22px;
      background-image: ${({ theme, checkboxIcon }) =>
        checkboxIcon
          ? `url(${checkboxIcon})`
          : `url(${theme.images.checkboxIcon})`};
      background-size: 22px;
      background-repeat: no-repeat;
    }
  `,
}
