import styled from 'styled-components'

import { BurgerMenu } from 'components/BurgerMenu'
import { Button } from 'components/Button'

import desktopBg from 'assets/images/desktop-phone-number-bg.jpg'

import { baseColumnStyles, basePageStyles } from 'common-styles'
import { HEADER_HEIGHT, MediaBreakpoint } from 'root-constants/common'

export const StyledSubscriptionsGoogleVariant2 = {
  Wrapper: styled.div`
    ${basePageStyles};
    min-height: calc(100dvh - ${HEADER_HEIGHT}px);
    padding-top: ${HEADER_HEIGHT}px;
    padding-bottom: 25px;

    @media (min-width: ${MediaBreakpoint.LAPTOP}px) {
      button {
        font-size: 24px;
        line-height: 33px;
        width: 358px;

        img {
          width: 32px;
          height: 32px;
        }
      }
      .react-international-phone-input-container {
        width: 358px;
      }

      .react-international-phone-input {
        font-size: 20px;
        line-height: 27px;
      }

      .react-international-phone-country-selector-button {
        width: 62px;
      }
    }
  `,
  BurgerMenu: styled(BurgerMenu)`
    button {
      top: 6px;
      right: 6px;
      width: 40px;

      @media (min-width: ${MediaBreakpoint.LAPTOP}px) {
        top: 3px;
        right: 3px;
      }
    }
  `,
  PhoneNumberWrapper: styled.div`
    align-items: center;
    display: flex;
    flex-direction: column;
    width: 100%;

    @media (min-width: ${MediaBreakpoint.LAPTOP}px) {
      padding: 100px 0 0 180px;
      background: url(${desktopBg}) no-repeat center;
      background-size: cover;
      flex-direction: row;
      gap: 172px;
      justify-content: start;
      align-items: end;
      min-height: 684px;
    }
  `,
  WideColumn: styled.div`
    ${baseColumnStyles};
    max-width: 375px;
    width: 100%;

    @media (min-width: ${MediaBreakpoint.LAPTOP}px) {
      max-width: 583px;
      align-items: start;
    }
  `,
  Title: styled.h1`
    color: #0a324d;
    font-size: 32px;
    font-weight: 800;
    line-height: 38px;
    text-align: center;
    margin-top: 24px;
    margin-bottom: 12px;
    max-width: 331px;

    strong {
      font-size: 32px;
      font-weight: 800;
      line-height: 38px;
      color: #5c90ff;

      @media (min-width: ${MediaBreakpoint.LAPTOP}px) {
        font-size: 56px;
        font-weight: 800;
        line-height: 76px;
      }
    }

    @media (min-width: ${MediaBreakpoint.LAPTOP}px) {
      text-align: start;
      font-size: 56px;
      font-weight: 800;
      line-height: 76px;
      max-width: 583px;
      margin-bottom: 24px;
      margin-top: 0;
    }
  `,
  Text: styled.p`
    text-align: center;
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
    margin-bottom: 24px;
    max-width: 323px;

    @media (min-width: ${MediaBreakpoint.LAPTOP}px) {
      text-align: start;
      font-size: 20px;
      line-height: 27px;
      max-width: 583px;
      margin-bottom: 32px;
    }
  `,
  PhoneImageContainer: styled.div`
    width: 100%;
    aspect-ratio: 375/415;
    max-width: ${MediaBreakpoint.MAX_PHONE}px;

    @media (min-width: ${MediaBreakpoint.MAX_PHONE}px) {
      max-width: 375px;
    }

    @media (min-width: ${MediaBreakpoint.LAPTOP}px) {
      display: none;
    }
  `,
  DesktopImageContainer: styled.div`
    display: none;
    max-width: 325px;
    width: 100%;
    aspect-ratio: 325/559;
    box-sizing: border-box;

    img {
      border-top-left-radius: 55px;
      border-top-right-radius: 55px;
    }

    @media (min-width: ${MediaBreakpoint.LAPTOP}px) {
      display: block;
    }
  `,
  Column: styled.div`
    ${baseColumnStyles};

    @media (min-width: ${MediaBreakpoint.MAX_PHONE}px) {
      width: ${MediaBreakpoint.LAPTOP}px;
    }
  `,
  Button: styled(Button)`
    line-height: 27px;
  `,
  CommonTitle: styled.h2`
    font-size: 24px;
    font-weight: 800;
    line-height: 30px;
    margin-top: 48px;

    @media (min-width: ${MediaBreakpoint.LAPTOP}px) {
      font-size: 40px;
      line-height: 55px;
    }
  `,
  EmailSubtitle: styled.p`
    font-size: 20px;
    font-weight: 600;
    line-height: 27px;
    margin: 48px 0 16px;
    text-align: center;

    @media (min-width: ${MediaBreakpoint.LAPTOP}px) {
      margin: 0;
      max-width: 297px;
      font-size: 26px;
      line-height: 35px;
      text-align: start;
    }
  `,
  VideoContainer: styled.div`
    position: relative;
    padding-bottom: 56.25%;
    z-index: 1;
    max-width: ${MediaBreakpoint.MAX_PHONE}px;
    width: 100%;
    margin-top: 20px;

    iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border: 0;
    }

    @media (min-width: ${MediaBreakpoint.MAX_PHONE}px) {
      max-width: 375px;
    }

    @media (min-width: ${MediaBreakpoint.LAPTOP}px) {
      margin: 100px 0 52px;
      max-width: 100%;
    }
  `,
  PhoneFormWrapper: styled.div`
    ${baseColumnStyles};

    @media (min-width: ${MediaBreakpoint.LAPTOP}px) {
      width: ${MediaBreakpoint.LAPTOP}px;
      margin: 100px 0 52px;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      gap: 24px;

      form {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 24px;

        p {
          position: absolute;
          top: 80px;
          left: 5px;
        }
      }
    }
  `,
  SecurityColumn: styled.div`
    ${baseColumnStyles};

    @media (min-width: ${MediaBreakpoint.MAX_PHONE}px) {
      width: 1300px;
    }
  `,
}
