import styled, { css } from 'styled-components'

import { Color } from 'root-constants/common'

type TPaymentText = {
  color?: string
  fontSize?: number
  fontWeight?: number
}

const commonPriceTextStyles = css`
  color: ${Color.BLUE};
  text-align: center;
  font-size: 13px;
  font-style: normal;
  font-weight: 700;
  line-height: 18px;
`
export const StyledTotalAmountWithDiscount = {
  Wrapper: styled.div`
    min-width: 312px;
    width: 100%;
  `,
  PriceContainer: styled.div`
    margin: 8px 0 16px;
    display: flex;
    flex-direction: column;
    gap: 8px;
  `,
  PaymentContainer: styled.div`
    display: flex;
    justify-content: space-between;
  `,
  PaymentText: styled.p<TPaymentText>`
    ${commonPriceTextStyles};
    text-align: start;
    color: ${({ color }) => color || Color.BLUE};
    font-size: ${({ fontSize }) => fontSize || 13}px;
    font-weight: ${({ fontWeight }) => fontWeight || 700};
  `,
  PaymentDiscountText: styled.p`
    ${commonPriceTextStyles};
    color: ${Color.RED};
    text-align: start;
    max-width: 230px;
  `,
}
