/* eslint-disable max-lines */
import {
  PaymentMethodResult,
  PaymentRequestPaymentMethodEvent,
  Stripe,
  StripeCardNumberElement,
  StripeError,
} from '@stripe/stripe-js'
import { paymentApi, userApi } from 'api'
import i18n from 'i18next'

import {
  setErrorAction,
  startFetching,
  stopFetching,
} from 'root-redux/actions/common'
import {
  sendPlanAdditionsAction,
  sendUserConfigAction,
} from 'root-redux/actions/user'
import {
  selectCurrentVariantCohort,
  selectScreenName,
} from 'root-redux/selects/common'
import {
  selectDeliveryInfo,
  selectEmail,
  selectIsUpgradePassed,
  selectUUID,
  selectUserPaymentMethod,
  selectUserPaymentSystem,
} from 'root-redux/selects/user'

import { IAction, IAppState, TAppDispatchThunk } from 'models/store.model'
import { ISubscription } from 'models/subscriptions.model'

import { Source, eventLogger } from 'services/eventLogger.service'
import { googleAnalyticsLogger } from 'services/googleAnalytics.service'

import {
  DEFAULT_CARDHOLDER_NAME,
  PaymentMethod,
  PaymentSystem,
  StripeDeclineReason,
  StripeSoftDeclineReason,
} from 'modules/payment/constants'
import { getPurchaseFailedEventParams } from 'modules/payment/helpers/getPurchaseFailedEventParams'
import { getPurchaseStartedEventParams } from 'modules/payment/helpers/getPurchaseStartedEventParams'
import { getPurchaseSuccessEventParams } from 'modules/payment/helpers/getPurchaseSuccessEventParams'
import { getRedirectUrl } from 'modules/payment/helpers/getRedirectUrl'

import { CENTS_IN_DOLLAR, Cohort, PlanAddition } from 'root-constants/common'

import { checkIsRetryAllowed } from '../../helpers/checkIsRetryAllowed'
import { logFailedPayment } from '../../helpers/logFailedPayment'
import { logSuccessfulPayment } from '../../helpers/logSuccessfulPayment'
import {
  selectCreatedSubscriptionId,
  selectCurrency,
  selectPaymentClientSecret,
  selectPlanId,
  selectProductId,
  selectSubscription,
  selectSubscriptionFullPrice,
  selectSubscriptionLookupKey,
  selectSubscriptionPeriodName,
  selectSubscriptionPeriodQuantity,
  selectSubscriptionTrialLookupKey,
  selectSubscriptionTrialPeriodDays,
  selectSubscriptionTrialPeriodPrice,
  selectUpgradeAmountToPay,
} from '../selects'

const MODULE_NAME = 'PAYMENT'

export const SET_SUBSCRIPTION = `${MODULE_NAME}/SET_SUBSCRIPTION`
export const PURCHASE = `${MODULE_NAME}/PURCHASE`
export const CHECK_3D_SECURE = `${MODULE_NAME}/CHECK_3D_SECURE`
export const SET_3D_SECURE_IFRAME_URL = `${MODULE_NAME}/SET_3D_SECURE_IFRAME_URL`
export const RESET_3D_SECURE_IFRAME_URL = `${MODULE_NAME}/RESET_3D_SECURE_IFRAME_URL`
export const SET_PAYMENT_CLIENT_SECRET = `${MODULE_NAME}/SET_PAYMENT_CLIENT_SECRET`
export const SET_TRIAL_PERIOD_DAYS = `${MODULE_NAME}/SET_TRIAL_PERIOD_DAYS`
export const SET_SUBSCRIPTION_ID = `${MODULE_NAME}/SET_SUBSCRIPTION_ID`
export const SET_IS_PAYMENT_FLOWS_SHOWN = `${MODULE_NAME}/SET_IS_PAYMENT_FLOWS_SHOWN`
export const SET_PLAN_ADDITIONS = `${MODULE_NAME}/SET_PLAN_ADDITIONS`
export const SET_PAYMENT_METHOD = `${MODULE_NAME}/SET_PAYMENT_METHOD`
export const SET_PAYMENT_REQUEST_BUTTON_TYPE = `${MODULE_NAME}/SET_PAYMENT_REQUEST_BUTTON_TYPE`
export const MAKE_UPSELL = `${MODULE_NAME}/MAKE_UPSELL`

const getErrorActionPayload = ({ type, message }: StripeError): string =>
  message || type

const set3DSecureIframeUrlAction = (payload: string): IAction<string> => ({
  type: SET_3D_SECURE_IFRAME_URL,
  payload,
})

const reset3DSecureIframeUrlAction = (): IAction<never> => ({
  type: RESET_3D_SECURE_IFRAME_URL,
})

const setPaymentClientSecretAction = (payload: string): IAction<string> => ({
  type: SET_PAYMENT_CLIENT_SECRET,
  payload,
})

const setSubscriptionIdAction = (payload: string): IAction<string> => ({
  type: SET_SUBSCRIPTION_ID,
  payload,
})

export const setIsPaymentFlowsShownAction = (
  payload: boolean,
): IAction<boolean> => ({
  type: SET_IS_PAYMENT_FLOWS_SHOWN,
  payload,
})

export const setSelectedSubscriptionAction = (
  payload: ISubscription,
): IAction<ISubscription> => ({
  type: SET_SUBSCRIPTION,
  payload,
})

export const setPlanAdditionsAction = (
  payload: PlanAddition[],
): IAction<PlanAddition[]> => ({
  type: SET_PLAN_ADDITIONS,
  payload,
})

export const setPaymentMethodAction = (
  payload: PaymentMethod,
): IAction<PaymentMethod> => ({
  type: SET_PAYMENT_METHOD,
  payload,
})

export const setPaymentRequestButtonTypeAction = (
  payload: PaymentMethod,
): IAction<PaymentMethod> => ({
  type: SET_PAYMENT_REQUEST_BUTTON_TYPE,
  payload,
})

export const purchaseAction =
  ({
    stripe,
    paymentPageId,
    card,
    name,
    createPaymentResFromDigitalWallet,
  }: {
    stripe: Stripe
    paymentPageId: string
    card?: StripeCardNumberElement
    name?: string
    createPaymentResFromDigitalWallet?: PaymentRequestPaymentMethodEvent
  }): any =>
  async (
    dispatch: TAppDispatchThunk<any>,
    getState: () => IAppState,
  ): Promise<void> => {
    const state = getState()
    const planId = selectPlanId(state)
    const priceId = selectSubscriptionLookupKey(state)
    const trialPriceId = selectSubscriptionTrialLookupKey(state)
    const trialPrice = selectSubscriptionTrialPeriodPrice(state)
    const uuid = selectUUID(state)
    const currentPrice = selectSubscriptionFullPrice(state)
    const trialPeriodDays = selectSubscriptionTrialPeriodDays(state)
    const periodName = selectSubscriptionPeriodName(state)
    const periodQuantity = selectSubscriptionPeriodQuantity(state)
    const currency = selectCurrency(state)
    const cohort = selectCurrentVariantCohort(state)
    const selectedSubscription = selectSubscription(state)

    if (!priceId || !currentPrice) {
      console.error('Error: no subscriptions plan selected')
      return
    }

    const commonPurchaseStartedParams = getPurchaseStartedEventParams(state)
    const commonPurchaseSuccessParams = getPurchaseSuccessEventParams(state)
    const commonPurchaseFailedParams = getPurchaseFailedEventParams(state)

    dispatch(startFetching(PURCHASE))

    const paymentMethod =
      createPaymentResFromDigitalWallet?.paymentMethod?.card?.wallet?.type

    if (cohort !== Cohort.MAIN_8) {
      googleAnalyticsLogger.logAddingToCart(
        selectedSubscription as ISubscription,
      )
      window.fbq('track', 'AddToCart', {}, { eventID: uuid })
    }

    eventLogger.logPurchaseStarted({
      ...commonPurchaseStartedParams,
      paymentMethod,
      paymentSystem: PaymentSystem.STRIPE,
    })

    try {
      const createPaymentResponse =
        card && !createPaymentResFromDigitalWallet
          ? await stripe.createPaymentMethod({
              type: 'card',
              card,
              billing_details: { name: name || DEFAULT_CARDHOLDER_NAME },
            })
          : (createPaymentResFromDigitalWallet as PaymentMethodResult)

      if (
        !createPaymentResponse?.paymentMethod &&
        createPaymentResponse?.error
      ) {
        const {
          error: { type, message },
        } = createPaymentResponse

        eventLogger.logPurchaseFailed({
          ...commonPurchaseFailedParams,
          error: createPaymentResponse.error,
          paymentMethod,
          paymentSystem: PaymentSystem.STRIPE,
        })

        dispatch(setErrorAction(message || type))
        dispatch(stopFetching(PURCHASE))
        createPaymentResFromDigitalWallet?.complete('fail')
        return
      }

      const isFreeTrial = !!trialPeriodDays && !trialPriceId
      const createSubscriptionResponse = await paymentApi.createSubscription({
        cohort,
        uuid,
        planId,
        trialPeriodDays,
      })

      if (
        !createSubscriptionResponse.success ||
        !createSubscriptionResponse.data
      ) {
        // It's a hack for fixing behavior after changing response
        // from WS BE after getting payment_method
        if (
          createSubscriptionResponse.data?.error?.startsWith(
            'creating new customer',
          )
        ) {
          let error = { type: createSubscriptionResponse.data?.error }

          try {
            error = JSON.parse(
              createSubscriptionResponse.data.error.split(
                'creating new customer: ',
              )[1],
            )
          } finally {
            logFailedPayment({
              ...commonPurchaseFailedParams,
              paymentResponse: error,
              paymentMethod,
              paymentSystem: PaymentSystem.STRIPE,
            })

            dispatch(
              setErrorAction({ type: createSubscriptionResponse.data?.error }),
            )
            dispatch(stopFetching(PURCHASE))
            createPaymentResFromDigitalWallet?.complete('fail')
          }
          return
        }

        if (isFreeTrial) {
          logFailedPayment({
            ...commonPurchaseFailedParams,
            paymentResponse: { type: createSubscriptionResponse.data?.error },
            paymentMethod,
            paymentSystem: PaymentSystem.STRIPE,
          })

          dispatch(
            setErrorAction({ type: createSubscriptionResponse.data?.error }),
          )
          dispatch(stopFetching(PURCHASE))
          createPaymentResFromDigitalWallet?.complete('fail')
          return
        }
        switch (createSubscriptionResponse.status) {
          case 409:
            dispatch(setErrorAction(i18n.t('payment.haveSubscription')))
            break
          default:
            dispatch(setErrorAction(i18n.t('commonComponents.commonError')))
        }

        logFailedPayment({
          ...commonPurchaseFailedParams,
          paymentResponse: { type: createSubscriptionResponse.data?.error },
          paymentMethod,
          paymentSystem: PaymentSystem.STRIPE,
        })

        dispatch(stopFetching(PURCHASE))
        createPaymentResFromDigitalWallet?.complete('fail')
        return
      }

      if (isFreeTrial) {
        const predictedLtvResponse = await userApi.getUserPredictedLtv(uuid)

        logSuccessfulPayment({
          ...commonPurchaseSuccessParams,
          predictedLtv: predictedLtvResponse?.data?.predicted_ltv,
          productId: createSubscriptionResponse.data.purchase.product_id,
          trialPeriodDays:
            createSubscriptionResponse.data.purchase.trial_period_days,
          subscriptionId:
            createSubscriptionResponse.data.purchase.subscription_id,
          paymentMethod,
          paymentSystem: PaymentSystem.STRIPE,
        })

        dispatch(
          sendUserConfigAction({
            payment_currency: currency,
            payment_method: paymentMethod || PaymentMethod.CREDIT_CARD,
            subscription_price: currentPrice,
            subscription_duration: `${periodQuantity}${periodName}`,
            price_id: priceId,
            trial_price: trialPrice,
            trial_period: trialPeriodDays,
          }),
        )
        dispatch(sendPlanAdditionsAction())
        dispatch(stopFetching(PURCHASE))
        createPaymentResFromDigitalWallet?.complete('success')
        return
      }

      dispatch(
        setPaymentClientSecretAction(
          createSubscriptionResponse.data.purchase.client_secret,
        ),
      )
      dispatch(
        setSubscriptionIdAction(
          createSubscriptionResponse.data.purchase.subscription_id,
        ),
      )

      const cardPaymentResponseFirst = await stripe.confirmCardPayment(
        createSubscriptionResponse.data.purchase.client_secret,
        {
          payment_method: createPaymentResponse.paymentMethod.id,
          save_payment_method: true,
          return_url: getRedirectUrl(paymentPageId),
        },
      )

      if (
        !cardPaymentResponseFirst?.paymentIntent &&
        cardPaymentResponseFirst?.error
      ) {
        logFailedPayment({
          ...commonPurchaseFailedParams,
          paymentResponse: cardPaymentResponseFirst.error,
          paymentMethod,
          paymentSystem: PaymentSystem.STRIPE,
        })
        if (!checkIsRetryAllowed(cardPaymentResponseFirst)) {
          dispatch(
            setErrorAction(
              getErrorActionPayload(cardPaymentResponseFirst.error),
            ),
          )
          dispatch(stopFetching(PURCHASE))
          createPaymentResFromDigitalWallet?.complete('fail')
          return
        }

        const retryResponseFirst = await paymentApi.retryPayment({
          uuid,
          stripeError: cardPaymentResponseFirst.error as StripeError,
        })

        if (!retryResponseFirst.data.should_retry) {
          dispatch(
            setErrorAction(
              getErrorActionPayload(cardPaymentResponseFirst.error),
            ),
          )
          dispatch(stopFetching(PURCHASE))
          createPaymentResFromDigitalWallet?.complete('fail')
          return
        }

        dispatch(
          setPaymentClientSecretAction(
            createSubscriptionResponse.data.purchase.client_secret,
          ),
        )
        dispatch(
          setSubscriptionIdAction(
            createSubscriptionResponse.data.purchase.subscription_id,
          ),
        )

        const cardPaymentResponseSecond = await stripe.confirmCardPayment(
          retryResponseFirst.data.subscription.client_secret,
          {
            payment_method: createPaymentResponse.paymentMethod.id,
            save_payment_method: true,
            return_url: getRedirectUrl(paymentPageId),
          },
        )

        if (
          cardPaymentResponseSecond?.paymentIntent &&
          !cardPaymentResponseSecond?.error
        ) {
          const predictedLtvResponse = await userApi.getUserPredictedLtv(uuid)

          logSuccessfulPayment({
            ...commonPurchaseSuccessParams,
            predictedLtv: predictedLtvResponse?.data?.predicted_ltv,
            productId: createSubscriptionResponse.data.purchase.product_id,
            trialPrice:
              cardPaymentResponseSecond.paymentIntent.amount / CENTS_IN_DOLLAR,
            trialPeriodDays:
              retryResponseFirst.data.subscription.trial_period_days,
            subscriptionId:
              retryResponseFirst.data.subscription.subscription_id,
            discountApplied:
              retryResponseFirst.data.subscription.discount_applied,
            paymentMethod,
            paymentSystem: PaymentSystem.STRIPE,
          })

          dispatch(
            sendUserConfigAction({
              payment_currency: currency,
              payment_method: paymentMethod || PaymentMethod.CREDIT_CARD,
              discount_applied:
                !!retryResponseFirst.data.subscription.discount_applied,
              subscription_price: currentPrice,
              subscription_duration: `${periodQuantity}${periodName}`,
              price_id: priceId,
              trial_price: trialPrice,
              trial_period: trialPeriodDays,
            }),
          )
          dispatch(sendPlanAdditionsAction())
          dispatch(stopFetching(PURCHASE))
          createPaymentResFromDigitalWallet?.complete('success')
          return
        }

        logFailedPayment({
          ...commonPurchaseFailedParams,
          paymentResponse: cardPaymentResponseSecond.error,
          paymentMethod,
          paymentSystem: PaymentSystem.STRIPE,
        })

        if (!checkIsRetryAllowed(cardPaymentResponseSecond)) {
          dispatch(
            setErrorAction(
              getErrorActionPayload(cardPaymentResponseSecond.error),
            ),
          )
          dispatch(stopFetching(PURCHASE))
          createPaymentResFromDigitalWallet?.complete('fail')
          return
        }

        const retryResponseSecond = await paymentApi.retryPayment({
          uuid,
          stripeError: cardPaymentResponseSecond.error as StripeError,
        })

        if (!retryResponseSecond.data.should_retry) {
          dispatch(
            setErrorAction(
              getErrorActionPayload(cardPaymentResponseSecond.error),
            ),
          )
          dispatch(stopFetching(PURCHASE))
          createPaymentResFromDigitalWallet?.complete('fail')
          return
        }

        dispatch(
          setPaymentClientSecretAction(
            createSubscriptionResponse.data.purchase.client_secret,
          ),
        )
        dispatch(
          setSubscriptionIdAction(
            createSubscriptionResponse.data.purchase.subscription_id,
          ),
        )

        const cardPaymentResponseThird = await stripe.confirmCardPayment(
          retryResponseSecond.data.subscription.client_secret,
          {
            payment_method: createPaymentResponse.paymentMethod.id,
            save_payment_method: true,
            return_url: getRedirectUrl(paymentPageId),
          },
        )

        if (
          cardPaymentResponseThird?.paymentIntent &&
          !cardPaymentResponseThird?.error
        ) {
          const predictedLtvResponse = await userApi.getUserPredictedLtv(uuid)

          logSuccessfulPayment({
            ...commonPurchaseSuccessParams,
            predictedLtv: predictedLtvResponse?.data?.predicted_ltv,
            productId: createSubscriptionResponse.data.purchase.product_id,
            trialPrice:
              cardPaymentResponseThird.paymentIntent.amount / CENTS_IN_DOLLAR,
            trialPeriodDays:
              retryResponseSecond.data.subscription.trial_period_days,
            subscriptionId:
              retryResponseSecond.data.subscription.subscription_id,
            discountApplied:
              retryResponseSecond.data.subscription.discount_applied,
            paymentMethod,
            paymentSystem: PaymentSystem.STRIPE,
          })

          dispatch(
            sendUserConfigAction({
              payment_currency: currency,
              payment_method: paymentMethod || PaymentMethod.CREDIT_CARD,
              discount_applied:
                !!retryResponseSecond.data.subscription.discount_applied,
              subscription_price: currentPrice,
              subscription_duration: `${periodQuantity}${periodName}`,
              price_id: priceId,
              trial_price: trialPrice,
              trial_period: trialPeriodDays,
            }),
          )
          dispatch(sendPlanAdditionsAction())
          dispatch(stopFetching(PURCHASE))
          createPaymentResFromDigitalWallet?.complete('success')
          return
        }

        logFailedPayment({
          ...commonPurchaseFailedParams,
          paymentResponse: cardPaymentResponseThird.error,
          paymentMethod,
          paymentSystem: PaymentSystem.STRIPE,
        })

        if (!checkIsRetryAllowed(cardPaymentResponseThird)) {
          dispatch(
            setErrorAction(
              getErrorActionPayload(cardPaymentResponseThird.error),
            ),
          )
          dispatch(stopFetching(PURCHASE))
          createPaymentResFromDigitalWallet?.complete('fail')
          return
        }

        const retryResponseThird = await paymentApi.retryPayment({
          uuid,
          stripeError: cardPaymentResponseThird.error as StripeError,
        })

        if (!retryResponseThird.data.should_retry) {
          dispatch(
            setErrorAction(
              getErrorActionPayload(cardPaymentResponseThird.error),
            ),
          )
          dispatch(stopFetching(PURCHASE))
          createPaymentResFromDigitalWallet?.complete('fail')
          return
        }

        dispatch(
          setPaymentClientSecretAction(
            createSubscriptionResponse.data.purchase.client_secret,
          ),
        )
        dispatch(
          setSubscriptionIdAction(
            createSubscriptionResponse.data.purchase.subscription_id,
          ),
        )

        const cardPaymentResponseFourth = await stripe.confirmCardPayment(
          retryResponseThird.data.subscription.client_secret,
          {
            payment_method: createPaymentResponse.paymentMethod.id,
            save_payment_method: true,
            return_url: getRedirectUrl(paymentPageId),
          },
        )

        if (
          cardPaymentResponseFourth?.paymentIntent &&
          !cardPaymentResponseFourth?.error
        ) {
          const predictedLtvResponse = await userApi.getUserPredictedLtv(uuid)

          logSuccessfulPayment({
            ...commonPurchaseSuccessParams,
            predictedLtv: predictedLtvResponse?.data?.predicted_ltv,
            productId: createSubscriptionResponse.data.purchase.product_id,
            trialPrice:
              cardPaymentResponseFourth.paymentIntent.amount / CENTS_IN_DOLLAR,
            trialPeriodDays:
              retryResponseThird.data.subscription.trial_period_days,
            subscriptionId:
              retryResponseThird.data.subscription.subscription_id,
            discountApplied:
              retryResponseThird.data.subscription.discount_applied,
            paymentMethod,
            paymentSystem: PaymentSystem.STRIPE,
          })

          dispatch(
            sendUserConfigAction({
              payment_currency: currency,
              payment_method: paymentMethod || PaymentMethod.CREDIT_CARD,
              discount_applied:
                !!retryResponseThird.data.subscription.discount_applied,
              subscription_price: currentPrice,
              subscription_duration: `${periodQuantity}${periodName}`,
              price_id: priceId,
              trial_price: trialPrice,
              trial_period: trialPeriodDays,
            }),
          )
          dispatch(sendPlanAdditionsAction())
          dispatch(stopFetching(PURCHASE))
          createPaymentResFromDigitalWallet?.complete('success')
          return
        }

        logFailedPayment({
          ...commonPurchaseFailedParams,
          paymentResponse: cardPaymentResponseFourth.error,
          paymentMethod,
          paymentSystem: PaymentSystem.STRIPE,
        })

        dispatch(
          setErrorAction(
            getErrorActionPayload(cardPaymentResponseFourth.error),
          ),
        )

        // Needed for reset invoice on BE
        await paymentApi.retryPayment({
          uuid,
          stripeError: cardPaymentResponseFourth.error as StripeError,
        })

        dispatch(stopFetching(PURCHASE))
        createPaymentResFromDigitalWallet?.complete('fail')
        return
      }

      const { paymentIntent } = cardPaymentResponseFirst

      if (paymentIntent.status === 'requires_payment_method') {
        eventLogger.logPurchaseFailed({
          ...commonPurchaseFailedParams,
          error: {
            type: 'requires_payment_method',
          },
          paymentMethod,
          paymentSystem: PaymentSystem.STRIPE,
        })
        dispatch(setErrorAction('Payment failed'))
        dispatch(stopFetching(PURCHASE))
        createPaymentResFromDigitalWallet?.complete('fail')
        return
      }

      const threeDSesureURL = paymentIntent.next_action?.redirect_to_url?.url

      if (paymentIntent.status === 'requires_action' && threeDSesureURL) {
        dispatch(set3DSecureIframeUrlAction(threeDSesureURL))
        dispatch(stopFetching(PURCHASE))
        return
      }

      const predictedLtvResponse = await userApi.getUserPredictedLtv(uuid)

      logSuccessfulPayment({
        ...commonPurchaseSuccessParams,
        predictedLtv: predictedLtvResponse?.data?.predicted_ltv,
        productId: createSubscriptionResponse.data.purchase.product_id,
        trialPeriodDays:
          createSubscriptionResponse.data.purchase.trial_period_days,
        subscriptionId:
          createSubscriptionResponse.data.purchase.subscription_id,
        paymentMethod,
        paymentSystem: PaymentSystem.STRIPE,
      })

      dispatch(
        sendUserConfigAction({
          payment_currency: currency,
          payment_method: paymentMethod || PaymentMethod.CREDIT_CARD,
          subscription_price: currentPrice,
          subscription_duration: `${periodQuantity}${periodName}`,
          price_id: priceId,
          trial_price: trialPrice,
          trial_period: trialPeriodDays,
        }),
      )
      dispatch(sendPlanAdditionsAction())
      dispatch(stopFetching(PURCHASE))
      createPaymentResFromDigitalWallet?.complete('success')
    } catch (error: any) {
      dispatch(setErrorAction(error.toString()))
      dispatch(stopFetching(PURCHASE))
      createPaymentResFromDigitalWallet?.complete('fail')
    }
  }

export const check3DSecure =
  (stripe: Stripe): any =>
  async (
    dispatch: TAppDispatchThunk<any>,
    getState: () => IAppState,
  ): Promise<void> => {
    const state = getState()
    const uuid = selectUUID(state)
    const priceId = selectSubscriptionLookupKey(state)
    const currentPrice = selectSubscriptionFullPrice(state)
    const trialPeriodPrice = selectSubscriptionTrialPeriodPrice(state)
    const clientSecret = selectPaymentClientSecret(state)
    const trialPeriodDays = selectSubscriptionTrialPeriodDays(state)
    const subscriptionId = selectCreatedSubscriptionId(state)
    const periodName = selectSubscriptionPeriodName(state)
    const periodQuantity = selectSubscriptionPeriodQuantity(state)
    const currency = selectCurrency(state)

    if (!priceId || !currentPrice) {
      console.error('Error: no subscriptions plan selected')
      return
    }

    if (!clientSecret) {
      console.error('Error: client secret is needed')
      return
    }

    dispatch(startFetching(CHECK_3D_SECURE))

    const commonPurchaseSuccessParams = getPurchaseSuccessEventParams(state)
    const commonPurchaseFailedParams = getPurchaseFailedEventParams(state)

    const response = await stripe.retrievePaymentIntent(clientSecret)

    if (response.paymentIntent?.status === 'succeeded') {
      const predictedLtvResponse = await userApi.getUserPredictedLtv(uuid)

      logSuccessfulPayment({
        ...commonPurchaseSuccessParams,
        predictedLtv: predictedLtvResponse?.data?.predicted_ltv,
        trialPeriodDays,
        subscriptionId,
        paymentSystem: PaymentSystem.STRIPE,
      })
      dispatch(reset3DSecureIframeUrlAction())
      dispatch(
        sendUserConfigAction({
          payment_currency: currency,
          payment_method: PaymentMethod.CREDIT_CARD,
          subscription_price: currentPrice,
          subscription_duration: `${periodQuantity}${periodName}`,
          price_id: priceId,
          trial_price: trialPeriodPrice,
          trial_period: trialPeriodDays,
        }),
      )
      return
    }

    if (response.paymentIntent?.status === 'requires_payment_method') {
      eventLogger.logPurchaseFailed({
        ...commonPurchaseFailedParams,
        error: {
          type: 'requires_payment_method',
        },
        paymentSystem: PaymentSystem.STRIPE,
      })
      dispatch(reset3DSecureIframeUrlAction())
      dispatch(setErrorAction('Payment failed'))
      dispatch(stopFetching(CHECK_3D_SECURE))
      return
    }

    if (response.error) {
      const {
        error: { type, message },
      } = response

      eventLogger.logPurchaseFailed({
        ...commonPurchaseFailedParams,
        error: response.error,
        paymentSystem: PaymentSystem.STRIPE,
      })
      dispatch(reset3DSecureIframeUrlAction())
      dispatch(setErrorAction(message || type))
      dispatch(stopFetching(CHECK_3D_SECURE))
      return
    }

    dispatch(reset3DSecureIframeUrlAction())
    dispatch(setErrorAction('Error: unhandled checking 3D Secure error'))
    dispatch(stopFetching(CHECK_3D_SECURE))
  }

export const purchaseUpgrade =
  (chipoloColor?: string): any =>
  async (
    dispatch: TAppDispatchThunk<any>,
    getState: () => IAppState,
  ): Promise<void> => {
    const state = getState()
    const email = selectEmail(state)
    const cohort = selectCurrentVariantCohort(state)
    const uuid = selectUUID(state)
    const planId = selectPlanId(state)
    const currency = selectCurrency(state)
    const fullPrice = selectSubscriptionFullPrice(state)
    const amountToPay = selectUpgradeAmountToPay(state)
    const priceId = selectSubscriptionLookupKey(state)
    const paymentMethod = selectUserPaymentMethod(state)
    const deliveryInfo = selectDeliveryInfo(state)
    const paymentSystem = selectUserPaymentSystem(state)
    const isUpgradePassed = selectIsUpgradePassed(state)

    dispatch(startFetching(PURCHASE))

    const commonPurchaseStartedParams = getPurchaseStartedEventParams(state)
    const commonPurchaseSuccessParams = getPurchaseSuccessEventParams(state)
    const commonPurchaseFailedParams = getPurchaseFailedEventParams(state)

    if (isUpgradePassed) return

    eventLogger.logPurchaseStarted({
      ...commonPurchaseStartedParams,
      paymentMethod,
      priceDetails: {
        currency,
        price: fullPrice,
      },
      paymentSystem,
      isUpgraded: true,
      amountToPay,
      productId: priceId,
    })

    try {
      const createSubscriptionResponse =
        paymentSystem === PaymentSystem.PRIMER
          ? await paymentApi.upgradePrimerSubscription({
              uuid,
              planId,
              cohort,
            })
          : await paymentApi.upgradeSubscription({
              uuid,
              planId,
              cohort,
            })

      if (
        !createSubscriptionResponse.success ||
        !createSubscriptionResponse.data
      ) {
        let errorText: string

        switch (createSubscriptionResponse.data?.decline_code) {
          case StripeSoftDeclineReason.INSUFFICIENT_FUNDS:
            errorText = i18n.t('payment.insufficientFunds')
            dispatch(setErrorAction(errorText))
            break
          case StripeDeclineReason.STOLEN_CARD:
          case StripeDeclineReason.LOST_CARD:
          case StripeDeclineReason.STRIPE_GENERIC_DECLINE_ERROR:
            errorText = i18n.t('payment.genericDecline')
            dispatch(setErrorAction(errorText))
            break
          default:
            errorText = i18n.t('commonComponents.commonError')
            dispatch(setErrorAction(errorText))
        }

        eventLogger.logPurchaseFailed({
          ...commonPurchaseFailedParams,
          error: {
            type: createSubscriptionResponse.data?.error,
            code: createSubscriptionResponse.data?.decline_code,
            description: errorText,
          },
          paymentMethod,
          isUpgraded: true,
          amountToPay,
          productId: priceId,
          paymentSystem,
        })

        if (deliveryInfo) {
          eventLogger.logUpsellChipoloPurchaseFailed({
            deliveryInfo,
            email,
            error: createSubscriptionResponse.data.error,
            isPersonalDataAllowed:
              commonPurchaseFailedParams.isPersonalDataAllowed,
          })
        }

        dispatch(stopFetching(PURCHASE))
        return
      }

      eventLogger.logPurchaseCompleted({
        ...commonPurchaseSuccessParams,
        priceDetails: {
          price: fullPrice,
          currency,
        },
        paymentMethod,
        utmSource: Source.UPGRADE_OFFER,
        isUpgraded: true,
        amountToPay,
        productId: createSubscriptionResponse.data.upgrade.product_id,
        paymentSystem,
      })

      if (deliveryInfo && chipoloColor) {
        eventLogger.logOfflineProductPurchaseCompleted({
          isTrial: false,
          chipoloColor,
          userEmail: email,
          deliveryInfo,
        })
        eventLogger.logUpsellChipoloPurchaseCompleted({
          deliveryInfo,
          email,
          transactionId:
            createSubscriptionResponse.data.upgrade.subscription_id,
          isPersonalDataAllowed:
            commonPurchaseSuccessParams.isPersonalDataAllowed,
        })

        await userApi.saveDeliveryUserInfo({
          uuid,
          chipoloColor,
          deliveryInfo,
        })
      }
      dispatch(sendPlanAdditionsAction())
      dispatch(stopFetching(PURCHASE))
    } catch (error: any) {
      dispatch(setErrorAction(error.toString()))
      dispatch(stopFetching(PURCHASE))
    }
  }

export const makeUpsellAction =
  (): any =>
  async (
    dispatch: TAppDispatchThunk<any>,
    getState: () => IAppState,
  ): Promise<void> => {
    const state = getState()
    const uuid = selectUUID(state)
    const planId = selectPlanId(state)
    const trialPeriodDays = selectSubscriptionTrialPeriodDays(state)
    const paymentMethod = selectUserPaymentMethod(state)
    const productId = selectProductId(state)
    const paymentSystem = selectUserPaymentSystem(state)
    const screenName = selectScreenName(state)

    dispatch(startFetching(MAKE_UPSELL))

    const commonPurchaseStartedParams = getPurchaseStartedEventParams(state)
    const commonPurchaseSuccessParams = getPurchaseSuccessEventParams(state)
    const commonPurchaseFailedParams = getPurchaseFailedEventParams(state)

    eventLogger.logPurchaseStarted({
      ...commonPurchaseStartedParams,
      paymentMethod,
      paymentSystem,
      productKey: productId,
    })

    const upsellResponse =
      paymentSystem === PaymentSystem.PRIMER
        ? await paymentApi.makePrimerUpsell({
            uuid,
            planId,
            productId,
          })
        : await paymentApi.makeUpsell({
            uuid,
            planId,
            productId,
          })

    if (upsellResponse.success && upsellResponse.data) {
      logSuccessfulPayment({
        ...commonPurchaseSuccessParams,
        trialPeriodDays,
        subscriptionId: upsellResponse.data.subscription.subscription_id,
        paymentMethod,
        paymentSystem,
        productId: upsellResponse.data.subscription.product_id,
        productKey: productId,
        screenName,
      })

      dispatch(sendPlanAdditionsAction())
      dispatch(stopFetching(MAKE_UPSELL))
      return
    }

    let errorText: string

    switch (upsellResponse.data?.decline_code) {
      case StripeSoftDeclineReason.INSUFFICIENT_FUNDS:
        errorText = i18n.t('payment.insufficientFunds')
        dispatch(setErrorAction(errorText))
        break
      case StripeSoftDeclineReason.CARD_VELOCITY_EXCEEDED:
        errorText = i18n.t('payment.velocityExceeded')
        dispatch(setErrorAction(errorText))
        break
      case StripeDeclineReason.STOLEN_CARD:
      case StripeDeclineReason.LOST_CARD:
      case StripeDeclineReason.STRIPE_GENERIC_DECLINE_ERROR:
        errorText = i18n.t('payment.genericDecline')
        dispatch(setErrorAction(errorText))
        break
      default:
        errorText = i18n.t('commonComponents.commonError')
        dispatch(setErrorAction(errorText))
    }

    eventLogger.logPurchaseFailed({
      ...commonPurchaseFailedParams,
      paymentMethod,
      paymentSystem,
      productKey: productId,
      error: {
        type: upsellResponse.data?.error,
        code: upsellResponse.data?.decline_code,
        description: errorText,
      },
    })
    dispatch(stopFetching(MAKE_UPSELL))
  }
