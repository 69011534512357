import React, { useMemo } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { selectAnswers } from 'root-redux/selects/common'

import { useNextStep } from 'hooks/useNextStep'

import { TPageProps } from 'models/common.model'

import { OPTION_VALUES as CONCERN_OPTION_VALUES } from 'pages/concerns/constants'
import { CHILD_FLOW_TEXTS_DATA } from 'pages/social-proof/constants'

import { Button } from 'components/Button'
import { FixedContainer } from 'components/FixedContainer'

import socialProof from 'assets/images/social-proof-20.jpg'

import { PageId } from 'root-constants/pages'

import { StyledSocialProofVariant20 as S } from './SocialProofVariant20.styles'

export const SocialProofVariant20: React.FC<TPageProps> = ({
  pageId,
  nextPagePath,
}) => {
  const { t } = useTranslation()
  const answers = useSelector(selectAnswers)

  const handleContinue = useNextStep({
    pageId,
    pageName: pageId,
    question: '',
    nextPagePath,
  })

  const textsData = useMemo(() => {
    const concerns = (answers?.[PageId.CONCERNS_2] as string[]) || []

    const isFirstFlow =
      concerns.length &&
      concerns.every(
        (item) =>
          item === CONCERN_OPTION_VALUES.HURT ||
          item === CONCERN_OPTION_VALUES.KIDNAPPED,
      )

    const isSecondFlow =
      concerns.length &&
      concerns.every((item) => item === CONCERN_OPTION_VALUES.HIT_BY_CAR)

    if (isFirstFlow) return CHILD_FLOW_TEXTS_DATA.FIRST_FLOW
    if (isSecondFlow) return CHILD_FLOW_TEXTS_DATA.SECOND_FLOW
    return CHILD_FLOW_TEXTS_DATA.THIRD_FLOW
  }, [answers])

  return (
    <S.Wrapper>
      <S.ImageContainer>
        <img src={socialProof} alt="child" />
      </S.ImageContainer>
      <S.Column>
        <S.Title>{t`onboarding.socialProof.variant19.title`}</S.Title>
        <S.Text marginBottom={16}>
          <Trans i18nKey={textsData.firstText} />
        </S.Text>
        <S.Text marginBottom={textsData.marginBottom}>
          <Trans i18nKey={textsData.secondText} />
        </S.Text>
        <FixedContainer>
          <Button type="button" onClick={() => handleContinue('')}>
            {t`actions.continue`}
          </Button>
        </FixedContainer>
      </S.Column>
    </S.Wrapper>
  )
}
