import React, { HTMLAttributes } from 'react'
import { useTranslation } from 'react-i18next'

import { PrivacyPolicyLink } from '../PrivacyPolicyLink'
import { TermsOfUseLink } from '../TermsOfUseLink'
import { StyledPoliciesGroup as S } from './PoliciesGroup.styles'

export const PoliciesGroup: React.FC<HTMLAttributes<HTMLDivElement>> = ({
  ...props
}) => {
  const { t } = useTranslation()

  return (
    <S.Wrapper {...props}>
      <div>{t`commonComponents.byContinue`}</div>
      <S.Links>
        <PrivacyPolicyLink />
        <span>|</span>
        <TermsOfUseLink />
      </S.Links>
    </S.Wrapper>
  )
}
