import React from 'react'
import { Helmet } from 'react-helmet'

import { getIsFamylocateDomain } from 'helpers/getIsFamylocateDomain'

import famioAppLogo from 'assets/images/famio-logo.png'
import famyLocateAppLogo from 'assets/images/famylocate-logo.png'

export const MetaInfo: React.FC = () => {
  const isFamylocateDomain = getIsFamylocateDomain()

  return isFamylocateDomain ? (
    <Helmet>
      <title>Famylocate</title>
      <link rel="icon" href={famyLocateAppLogo} />
    </Helmet>
  ) : (
    <Helmet>
      <title>Famio</title>
      <link rel="icon" href={famioAppLogo} />
    </Helmet>
  )
}
