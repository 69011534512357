import styled from 'styled-components'

import { Button } from 'components/Button'

import { Color, MediaBreakpoint } from 'root-constants/common'

type TReviewProps = {
  isFontOversized: boolean
}

export const StyledTrustpilotVariant1 = {
  Wrapper: styled.div`
    width: 100%;
    max-width: ${MediaBreakpoint.MAX_PHONE}px;
    border-radius: 40px;
    background: linear-gradient(180deg, #574fcf 2.34%, #3734a6 100%);
    position: relative;
    margin-top: 40px;
    padding-bottom: 24px;
    display: block;

    @media (min-width: ${MediaBreakpoint.MAX_PHONE}px) {
      max-width: 375px;
    }

    @media (min-width: ${MediaBreakpoint.LAPTOP}px) {
      display: none;
    }
  `,
  TrustpilotLogoWrapper: styled.div`
    display: flex;
    flex-direction: column;
    gap: 16px;
    width: 100%;
    position: relative;
    top: 67px;
    left: 24px;
  `,
  TrustpilotLogo: styled.img`
    width: 140px;
  `,
  Review: styled.p<TReviewProps>`
    font-size: ${({ isFontOversized }) => (isFontOversized ? 13 : 15)}px;
    font-weight: 600;
    line-height: 20px;
    color: #dfecff;
  `,
  TrustpilotPhone: styled.img`
    width: 270px;
    position: absolute;
    right: 0;
    top: 0;
  `,
  Button: styled(Button)`
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 12px;
    color: #35319f;
    font-size: 17px;
    font-weight: 700;
    line-height: 24px;
    margin-top: 150px;

    img {
      width: 20px;
      height: 20px;
    }
  `,
  Title: styled.h2`
    margin-top: 40px;
    font-size: 24px;
    font-weight: 700;
    line-height: 32px;
    text-align: center;
    color: ${Color.WHITE};
    margin-bottom: 24px;
  `,
  BenefitsContainer: styled.div`
    display: flex;
    flex-direction: column;
    gap: 16px;
    align-items: center;
  `,
  BenefitsWrapper: styled.div`
    max-width: 327px;
    width: 100%;
    padding: 12px 16px 12px 12px;
    display: flex;
    align-items: center;
    gap: 12px;
    border-radius: 30px;
    background: rgba(201, 200, 255, 0.2);
    box-shadow: 0 8px 20px 0 rgba(27, 25, 88, 0.08);

    img {
      width: 117px;
      height: 100px;
    }
  `,
  Text: styled.p`
    font-size: 15px;
    font-weight: 600;
    line-height: 20px;
    letter-spacing: 1px;
    text-transform: uppercase;
    color: ${Color.WHITE};
  `,
}
