import { TrialPeriod } from 'root-constants/common'

export const TRIAL_DURATION_CONTEXTS = {
  [TrialPeriod.ONE_DAY]: 'oneDay',
  [TrialPeriod.TWO_DAYS]: 'twoDays',
  [TrialPeriod.THREE_DAYS]: 'threeDays',
  [TrialPeriod.ONE_WEEK]: 'sevenDays',
}

export const BENEFITS = [
  'subscriptions.subscriptionsGoogle.variant1.subscriptionsBlock.compatible',
  'subscriptions.subscriptionsGoogle.variant1.subscriptionsBlock.exactLocation',
  'subscriptions.subscriptionsGoogle.variant1.subscriptionsBlock.fastGPS',
  'subscriptions.subscriptionsGoogle.variant1.subscriptionsBlock.unlimitedRequest',
  'subscriptions.subscriptionsGoogle.variant1.subscriptionsBlock.trackStolen',
  'subscriptions.subscriptionsGoogle.variant1.subscriptionsBlock.motionHistory',
  'subscriptions.subscriptionsGoogle.variant1.subscriptionsBlock.sosNotifications',
  'subscriptions.subscriptionsGoogle.variant1.subscriptionsBlock.drivingReports',
  'subscriptions.subscriptionsGoogle.variant1.subscriptionsBlock.batteryAlerts',
  'subscriptions.subscriptionsGoogle.variant1.subscriptionsBlock.privateChat',
]

export const BEENEFITS_V2 = [
  'subscriptions.subscriptionsGoogle.variant1.subscriptionsBlock.compatible',
  'subscriptions.subscriptionsGoogle.variant1.subscriptionsBlock.realTime',
  'subscriptions.subscriptionsGoogle.variant1.subscriptionsBlock.unlimitedRequest',
  'subscriptions.subscriptionsGoogle.variant1.subscriptionsBlock.trackStolen',
  'subscriptions.subscriptionsGoogle.variant1.subscriptionsBlock.motionHistory',
  'subscriptions.subscriptionsGoogle.variant1.subscriptionsBlock.sosNotifications',
  'subscriptions.subscriptionsGoogle.variant1.subscriptionsBlock.drivingReports',
  'subscriptions.subscriptionsGoogle.variant1.subscriptionsBlock.batteryAlerts',
  'subscriptions.subscriptionsGoogle.variant1.subscriptionsBlock.privateChat',
]
