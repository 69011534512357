import iosIcon from 'assets/images/ios-icon.png'
import locationIcon from 'assets/images/location-icon-blue.png'
import lockIcon from 'assets/images/lock-icon-blue.png'

export const CANCEL_OFFER_BENEFITS_DATA = [
  {
    image: iosIcon,
    title: 'subscriptions.subscriptionsGoogle.variant1.benefits.compatible',
    subtitle: 'subscriptions.subscriptionsGoogle.variant1.benefits.phoneTypes',
  },
  {
    image: lockIcon,
    title: 'subscriptions.subscriptionsGoogle.variant1.benefits.secure',
    subtitle: 'subscriptions.subscriptionsGoogle.variant1.benefits.privacy',
  },
  {
    image: locationIcon,
    title:
      'subscriptions.subscriptionsGoogle.variant1.benefits.realTimeLocation',
    subtitle: 'subscriptions.cancelOffer.benefits.location',
  },
]
