import React from 'react'
import { Trans, useTranslation } from 'react-i18next'

import { usePurchaseStore } from 'hooks/usePurchaseStore'

import {
  CURRENCY_SYMBOLS,
  TRIAL_IN_HOURS,
  TimeInterval,
  TrialPeriod,
} from 'root-constants/common'

import { StyledBenefitsVariant1 as S } from './BenefitsVariant1.styles'
import { GOOGLE_BENEFITS_DATA } from './constants'

type TProps = {
  benefits?: {
    image: string
    title: string
    subtitle: string
  }[]
  marginBottom?: number
}
export const BenefitsVariant1: React.FC<TProps> = ({
  benefits = GOOGLE_BENEFITS_DATA,
  marginBottom,
}) => {
  const { t } = useTranslation()

  const { trialPrice, trialPeriodDays, currentPrice, currency } =
    usePurchaseStore()

  const textParameters =
    trialPeriodDays >= TrialPeriod.THREE_DAYS
      ? {
          trialPrice,
          price: currentPrice,
          currencySymbol: CURRENCY_SYMBOLS[currency],
          period: trialPeriodDays,
          context: TimeInterval.DAY,
          minimumFractionDigits: 2,
        }
      : {
          trialPrice,
          price: currentPrice,
          currencySymbol: CURRENCY_SYMBOLS[currency],
          period: TRIAL_IN_HOURS[trialPeriodDays],
          minimumFractionDigits: 2,
        }

  return (
    <S.Wrapper marginBottom={marginBottom}>
      <S.Title>{t`subscriptions.subscriptionsGoogle.variant1.ultimatePeace`}</S.Title>
      {benefits.map(({ image, title, subtitle }) => (
        <S.Content key={title}>
          <img src={image} alt="icon" />
          <S.TextWrapper>
            <S.BenefitsTitle>{t(title)}</S.BenefitsTitle>
            <S.Subtitle>
              <Trans i18nKey={subtitle} values={textParameters} />
            </S.Subtitle>
          </S.TextWrapper>
        </S.Content>
      ))}
    </S.Wrapper>
  )
}
