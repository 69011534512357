import styled from 'styled-components'

import { Button } from 'components/Button'
import { Option } from 'components/Option'
import { QuestionButton } from 'components/QuestionButton'

import { baseColumnStyles, basePageStyles } from 'common-styles'
import { Color, MediaBreakpoint } from 'root-constants/common'

export const StyledIntroVariant4 = {
  Wrapper: styled.div`
    ${basePageStyles};
  `,
  ImageContainer: styled.div`
    width: 100%;
    max-width: ${MediaBreakpoint.MAX_PHONE}px;
    aspect-ratio: 375/165;

    @media (min-width: ${MediaBreakpoint.MAX_PHONE}px) {
      max-width: 375px;
    }
  `,
  Column: styled.div`
    ${baseColumnStyles};
    padding-bottom: 16px;
  `,
  Title: styled.h2`
    margin-bottom: 40px;
    font-size: 24px;
    font-weight: 700;
    line-height: 33px;
    text-align: center;
  `,
  Subtitle: styled.h3`
    margin-bottom: 4px;
    text-align: center;
    font-size: 18px;
    font-weight: 600;
    line-height: 25px;
  `,
  Disclaimer: styled.p`
    margin-bottom: 16px;
    color: #bcc9d0;
    text-align: center;
    font-size: 13px;
    font-weight: 400;
    line-height: 18px;
  `,
  OptionContainer: styled.div`
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto;
    grid-gap: 18px;
    margin-bottom: 16px;
  `,
  Option: styled(Option)`
    margin-bottom: 0;
  `,
  QuestionButton: styled(QuestionButton)`
    height: 54px;
    justify-content: center;
    border-radius: 30px;
    border: 3px solid transparent;

    input:checked + & {
      border: 3px solid ${Color.ACCENT_VIOLET};
      background-color: ${Color.GREY};
      color: ${Color.DEFAULT_TEXT};
    }
  `,
  Button: styled(Button)`
    margin-bottom: 17px;
  `,
}
