import styled from 'styled-components'

import { FixedContainer } from 'components/FixedContainer'
import { QuestionButton } from 'components/QuestionButton'

import { baseAdaptiveTitleStyles, basePageStyles } from 'common-styles'
import { HEADER_HEIGHT, MediaBreakpoint } from 'root-constants/common'

export const StyledFearVariant1 = {
  Wrapper: styled.div`
    ${basePageStyles};
    padding-top: ${HEADER_HEIGHT}px;
  `,
  Column: styled.div`
    padding: 40px 0 114px;

    @media (min-width: ${MediaBreakpoint.LAPTOP}px) {
      padding: 40px 25px;
    }
  `,
  OptionsContainer: styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    max-width: 311px;
    margin: 0 auto;
    padding-bottom: 40px;
    z-index: 1;
    gap: 0 32px;

    label {
      width: 100%;
    }

    @media (min-width: ${MediaBreakpoint.TABLET}px) {
      max-width: 460px;
    }

    @media (min-width: 1350px) {
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: center;
      max-width: 654px;

      label {
        width: calc(50% - 16px);
      }
    }
  `,
  QuestionButton: styled(QuestionButton)`
    max-height: 76px;
  `,
  Title: styled.h1`
    ${baseAdaptiveTitleStyles};
    max-width: 310px;
    margin: 0 auto 18px;

    @media (min-width: ${MediaBreakpoint.TABLET}px) {
      max-width: 540px;
    }

    @media (min-width: ${MediaBreakpoint.LAPTOP}px) {
      margin: 0 auto 48px;
    }
  `,
  FixedContainer: styled(FixedContainer)`
    left: 50%;
    transform: translateX(-50%);

    @media (min-width: ${MediaBreakpoint.LAPTOP}px) {
      background-image: none;
      padding: 0;
    }
  `,
}
