import { useEffect, useLayoutEffect } from 'react'

import {
  selectFacebookPixelId,
  selectLanguage,
} from 'root-redux/selects/common'
import { selectUUID, selectUserCountryCode } from 'root-redux/selects/user'

import { useBulkSelector } from 'hooks/common/useBulkSelector'
import { useCookieConsentAnswer } from 'hooks/common/useCookieConsentAnswer'

import { hasAnalyticsByLocale } from 'helpers/hasAnalyticsByLocale'

import { FBConsent } from 'root-constants/common'

const useSelectors = () =>
  useBulkSelector({
    uuid: selectUUID,
    facebookPixelId: selectFacebookPixelId,
    userCountryCode: selectUserCountryCode,
    language: selectLanguage,
  })

export const useFacebookPixelInitialization = (): void => {
  const { uuid, facebookPixelId, userCountryCode, language } = useSelectors()

  const { isEUUser, hasAdvertisingAndTargetingCookie } =
    useCookieConsentAnswer()

  useLayoutEffect(() => {
    if (!window.fbq || !facebookPixelId || !uuid || !userCountryCode) return

    window.fbq('init', facebookPixelId, { external_id: uuid })
    window.fbq('track', 'PageView')
  }, [facebookPixelId, userCountryCode, uuid])

  useEffect(() => {
    if (!window.fbq || !isEUUser || hasAnalyticsByLocale(language)) return

    window.fbq(
      'consent',
      hasAdvertisingAndTargetingCookie ? FBConsent.GRANT : FBConsent.REVOKE,
    )
  }, [hasAdvertisingAndTargetingCookie, isEUUser, language])
}
