import { TAnswers } from 'models/common.model'

export const getDynamicPersonalization = (
  value: string | undefined,
  userAnswers: Partial<TAnswers> | null,
  personalization: Record<string, Record<string, string | number>> | undefined,
) => {
  let transformedText = value
  const storedUserAnswers = userAnswers || []
  const personalizedPageIds = value
    ?.match(/{{(.*?)}}/g)
    ?.map((match) => match.slice(2, -2))

  if (personalizedPageIds) {
    for (let i = 0; i < personalizedPageIds.length; i++) {
      const personalizedAnswers = storedUserAnswers[personalizedPageIds[i]]
      const dynamicPersonalization = personalization || {}
      transformedText = transformedText?.replace(
        `{{${personalizedPageIds[i]}}}`,
        dynamicPersonalization[personalizedPageIds[i]]?.[
          personalizedAnswers
        ] !== undefined
          ? dynamicPersonalization[personalizedPageIds[i]]?.[
              personalizedAnswers
            ]
          : personalizedAnswers,
      )
    }
  }
  return transformedText
}
