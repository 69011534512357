import { useCounter } from 'hooks/useCounter'

const FOUR_SECONDS = 4000

export const useProgressBarList = (
  isShown: boolean,
  duration: number = FOUR_SECONDS,
) => {
  const {
    counter: firstCounterPercent,
    isFinished: isFirstProcessingFinished,
  } = useCounter(isShown, {
    duration,
  })

  const {
    counter: secondCounterPercent,
    isFinished: isSecondProcessingFinished,
  } = useCounter(isFirstProcessingFinished, {
    duration,
  })

  const {
    counter: thirdCounterPercent,
    isFinished: isThirdProcessingFinished,
  } = useCounter(isSecondProcessingFinished, {
    duration,
  })

  const {
    counter: fourthCounterPercent,
    isFinished: isFourthProcessingFinished,
  } = useCounter(isThirdProcessingFinished, {
    duration,
  })

  return {
    firstCounterPercent,
    secondCounterPercent,
    thirdCounterPercent,
    isThirdProcessingFinished,
    fourthCounterPercent,
    isFourthProcessingFinished,
  }
}
