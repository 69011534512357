import { useSelector } from 'react-redux'

import {
  selectCurrentVariantCohortToUse,
  selectHasUpsellOffer,
  selectLanguage,
} from 'root-redux/selects/common'
import {
  selectGeneralSubscriptionTrialPeriod,
  selectIsUpgradePassed,
  selectUserStatus,
} from 'root-redux/selects/user'

import {
  GOOGLE_UPSELL_COHORTS,
  Language,
  TrialPeriod,
} from 'root-constants/common'

export const useUpsellStatus = () => {
  const cohortToUse = useSelector(selectCurrentVariantCohortToUse)
  const userStatus = useSelector(selectUserStatus)
  const isUpgradePassed = useSelector(selectIsUpgradePassed)
  const language = useSelector(selectLanguage)
  const hasUpsellOffer = useSelector(selectHasUpsellOffer)
  const userTrialPeriod = useSelector(selectGeneralSubscriptionTrialPeriod)

  return (
    userStatus?.hasSubscription &&
    hasUpsellOffer &&
    userStatus.upsell.isUpsellAvailable &&
    !userStatus.upsell.isUpsellPurchased &&
    !isUpgradePassed &&
    language === Language.EN &&
    userTrialPeriod !== TrialPeriod.ONE_YEAR &&
    (userTrialPeriod !== TrialPeriod.ONE_WEEK ||
      GOOGLE_UPSELL_COHORTS.includes(cohortToUse))
  )
}
