import styled from 'styled-components'

import { Button } from 'components/Button'

import {
  StyledPhoneInput,
  baseColumnStyles,
  basePageStyles,
  baseTitleStyles,
} from 'common-styles'
import { HEADER_HEIGHT } from 'root-constants/common'

export const StyledPhoneNumber = {
  Wrapper: styled.div`
    ${basePageStyles};
    padding-top: ${HEADER_HEIGHT}px;
  `,
  Column: styled.div`
    ${baseColumnStyles};
    padding: 40px 0;
  `,
  Title: styled.h1`
    ${baseTitleStyles};
    margin: 0 0 56px;
  `,
  PhoneForm: styled.form`
    width: 343px;
    padding: 16px 0;
    border-radius: 16px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 16px;
  `,
  PhoneText: styled.h3`
    color: #0a324d;
    text-align: center;
    font-size: 20px;
    font-weight: 700;
    line-height: 28px;
    width: 296px;
  `,
  PhoneErrorText: styled.p`
    color: #ff0000;
    text-align: center;
    font-size: 18px;
    font-weight: 600;
    line-height: 20px;
  `,
  PhoneNumberInput: styled(StyledPhoneInput)`
    border: 1px solid #cbc3e7;
    background: #fff;
  `,
  LaterButton: styled(Button)`
    background: #eff5f7;
    color: #0a324d;
  `,
}
