import { PaymentIntentResult } from '@stripe/stripe-js'

import {
  STRIPE_SOFT_DECLINE_REASONS,
  StripeSoftDeclineReason,
} from '../constants'

export const checkIsRetryAllowed = (
  confirmCardPaymentResponse: PaymentIntentResult,
): boolean => {
  const declineCode =
    confirmCardPaymentResponse?.error?.decline_code ||
    confirmCardPaymentResponse?.error?.code

  return STRIPE_SOFT_DECLINE_REASONS.includes(
    declineCode as StripeSoftDeclineReason,
  )
}
