import React from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { selectDynamicDiscount } from 'root-redux/selects/common'

import { usePurchaseStore } from 'hooks/usePurchaseStore'
import { useVatInfo } from 'hooks/useVatInfo'

import { getPriceBeforeDiscount } from 'helpers/getPriceBeforeDiscount'

import { PriceValue } from 'components/PriceValue'

import {
  Color,
  DEFAULT_DYNAMIC_DISCOUNT_AMOUNT,
  INTRO_OFFER_PERIODS,
  INTRO_OFFER_PERIOD_NAMES,
} from 'root-constants/common'

import { StyledTotalAmountWithDiscount as S } from './TotalAmountWithDiscount.styles'

export const TotalAmountWithDiscount: React.FC = () => {
  const { t } = useTranslation()
  const dynamicDiscount = useSelector(selectDynamicDiscount)
  const hasIncludedVAT = useVatInfo()

  const { trialPrice, currency, trialPeriodDays } = usePurchaseStore()

  const period = INTRO_OFFER_PERIODS[trialPeriodDays]

  const oldPrice = getPriceBeforeDiscount(
    dynamicDiscount?.amount || DEFAULT_DYNAMIC_DISCOUNT_AMOUNT,
    trialPrice,
  )

  const discountAmount = Number(oldPrice) - trialPrice

  return (
    <S.Wrapper>
      <S.PriceContainer>
        <S.PaymentContainer>
          <S.PaymentText>
            <Trans
              i18nKey="payment.totalForPeriod"
              values={{
                count: period,
                trialPeriod: period,
                trialPeriodName: t(INTRO_OFFER_PERIOD_NAMES[trialPeriodDays], {
                  count: period,
                }),
              }}
            />
          </S.PaymentText>
          <S.PaymentText>
            <PriceValue value={oldPrice} currency={currency} />
          </S.PaymentText>
        </S.PaymentContainer>
        <S.PaymentContainer>
          <S.PaymentDiscountText>
            {t('payment.variant5.discountBlock.discountText', {
              discount: dynamicDiscount?.amount,
              text: dynamicDiscount?.checkout,
            })}
          </S.PaymentDiscountText>
          <S.PaymentText color={Color.RED}>
            -
            <PriceValue value={discountAmount} currency={currency} />
          </S.PaymentText>
        </S.PaymentContainer>
        <S.PaymentContainer>
          <S.PaymentText fontSize={16} fontWeight={800}>
            {hasIncludedVAT ? t`payment.totalVatV2` : t`payment.totalV2`}
          </S.PaymentText>
          <S.PaymentText fontSize={16} fontWeight={800}>
            <PriceValue value={trialPrice} currency={currency} />
          </S.PaymentText>
        </S.PaymentContainer>
      </S.PriceContainer>
    </S.Wrapper>
  )
}
