import { PageId } from 'root-constants/pages'

export const PAGE_NAME = 'Biggest fear kids'
export const OPTION_VALUES = {
  HURT: 'will_be_hurt',
  DANGEROUS_PLACE: 'dangerous_place',
  HIT_BY_CAR: 'hit_by_car',
  KIDNAPPED: 'will_be_kidnapped',
  LOST: 'get_lost',
  OTHER: 'other',
}

export const PAGE_OPTION_VALUES = {
  [PageId.FEAR_1]: [
    OPTION_VALUES.HURT,
    OPTION_VALUES.DANGEROUS_PLACE,
    OPTION_VALUES.HIT_BY_CAR,
    OPTION_VALUES.KIDNAPPED,
    OPTION_VALUES.LOST,
    OPTION_VALUES.OTHER,
  ],
  [PageId.FEAR_2]: [
    OPTION_VALUES.HURT,
    OPTION_VALUES.DANGEROUS_PLACE,
    OPTION_VALUES.HIT_BY_CAR,
    OPTION_VALUES.OTHER,
  ],
}
