import { useLayoutEffect } from 'react'

import { selectLanguage, selectTiktokPixelId } from 'root-redux/selects/common'
import { selectEmail, selectUserCountryCode } from 'root-redux/selects/user'

import { useBulkSelector } from 'hooks/common/useBulkSelector'
import { useCookieConsentAnswer } from 'hooks/common/useCookieConsentAnswer'

import { hasAnalyticsByLocale } from 'helpers/hasAnalyticsByLocale'

const useSelectors = () =>
  useBulkSelector({
    tiktokPixelId: selectTiktokPixelId,
    email: selectEmail,
    userCountryCode: selectUserCountryCode,
    language: selectLanguage,
  })

export const useTiktokPixelInitialization = (): void => {
  const { tiktokPixelId, email, userCountryCode, language } = useSelectors()

  const { isEUUser, isPersonalDataAllowed, hasAdvertisingAndTargetingCookie } =
    useCookieConsentAnswer()

  const shouldTiktokPixelBePaused =
    isEUUser &&
    !hasAdvertisingAndTargetingCookie &&
    !hasAnalyticsByLocale(language)

  useLayoutEffect(() => {
    if (!tiktokPixelId || !userCountryCode || shouldTiktokPixelBePaused) return

    window.ttq.load(tiktokPixelId)
    window.ttq.identify({ email: isPersonalDataAllowed ? email : '' })
    window.ttq.page()
  }, [
    email,
    isPersonalDataAllowed,
    shouldTiktokPixelBePaused,
    tiktokPixelId,
    userCountryCode,
  ])
}
