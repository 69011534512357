import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router'

import {
  selectCurrentVariantCohort,
  selectGoogleAdsConversions,
} from 'root-redux/selects/common'

import { googleAnalyticsLogger } from 'services/googleAnalytics.service'

import { PagePath } from 'root-constants/common'

export const useGAPageTracker = (): void => {
  const { pathname } = useLocation()
  const cohort = useSelector(selectCurrentVariantCohort)
  const googleAdsConversions = useSelector(selectGoogleAdsConversions)

  useEffect(() => {
    googleAnalyticsLogger.configure(googleAdsConversions)
  }, [googleAdsConversions])

  useEffect(() => {
    const currentOnboardingVariant = cohort ? `/${cohort}` : ``

    if (window.location.href.includes(PagePath.LOCALHOST)) return

    const currentLocation = `${pathname}${currentOnboardingVariant}`
    googleAnalyticsLogger.logPageView(currentLocation)
  }, [pathname, cohort])
}
