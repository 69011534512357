import { useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { GET_SUBSCRIPTION_LIST } from 'root-redux/actions/common'
import { GET_STATUS } from 'root-redux/actions/user'
import {
  selectActionList,
  selectCurrentVariantCohort,
  selectSubscriptionList,
} from 'root-redux/selects/common'
import { selectUUID } from 'root-redux/selects/user'

import { compareSubscriptionList } from 'helpers/compareSubscriptionList'

import { ISubscription } from 'models/subscriptions.model'

import { googleAnalyticsLogger } from 'services/googleAnalytics.service'

import { setSelectedSubscriptionAction } from 'modules/payment/redux/actions/common'

import { goTo } from 'browser-history'
import { PagePath } from 'root-constants/common'

export const usePaywall = (
  nextPagePath: string,
): {
  hasPrices: boolean
  handleShowPayment: () => void
  handleSelectSubscription: () => void
} => {
  const dispatch = useDispatch()
  const cohort = useSelector(selectCurrentVariantCohort)
  const uuid = useSelector(selectUUID)
  const subscriptionsList = useSelector(selectSubscriptionList)
  const fetchingActionsList = useSelector(selectActionList)
  const subscriptions = useSelector(
    selectSubscriptionList,
    compareSubscriptionList,
  )

  const hasPrices = useMemo(
    () =>
      !fetchingActionsList?.includes(GET_SUBSCRIPTION_LIST) &&
      !fetchingActionsList?.includes(GET_STATUS) &&
      !!subscriptionsList.length,
    [fetchingActionsList, subscriptionsList],
  )

  const handleShowPayment = () => {
    window.fbq('track', 'InitiateCheckout', {}, { eventID: uuid })
    googleAnalyticsLogger.logPageView(`${PagePath.SUBSCRIPTIONS}/${cohort}`)
    googleAnalyticsLogger.logCheckoutStarted(subscriptions)

    goTo(nextPagePath)
  }

  const handleSelectSubscription = (subscription?: ISubscription): void => {
    subscription && dispatch(setSelectedSubscriptionAction(subscription))
  }

  return {
    hasPrices,
    handleShowPayment,
    handleSelectSubscription,
  }
}
