import styled from 'styled-components'

import { Button } from 'components/Button'

import bgImage from 'assets/images/benefits-bg-2.png'

import { Color, MediaBreakpoint } from 'root-constants/common'

export const StyledBenefitsVariant2 = {
  Wrapper: styled.div`
    max-width: ${MediaBreakpoint.MAX_PHONE}px;
    min-height: 100dvh;
    margin: 0 auto;
    padding-bottom: 32px;
    background-image: url(${bgImage});
    background-size: 100%;
    background-repeat: no-repeat;
    background-position: top center;

    @media (min-width: ${MediaBreakpoint.MAX_PHONE}px) {
      max-width: 375px;
    }
  `,
  BlackBackground: styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #000;
    z-index: -2;
  `,
  Content: styled.div`
    position: fixed;
    left: 50%;
    transform: translateX(-50%);
    bottom: 32px;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
  `,
  AnimationContainer: styled.div`
    position: relative;
    width: 100%;
    aspect-ratio: 375/500;
    z-index: -1;
  `,
  SwiperContainer: styled.div`
    width: 100%;
    margin-bottom: 10px;

    .swiper-wrapper {
      padding-bottom: 50px;
    }

    .swiper-pagination-bullet {
      width: 8px;
      height: 8px;
      background: ${Color.WHITE};
      opacity: 0.35;
    }

    .swiper-pagination-bullet-active {
      opacity: 1;
    }

    @media (min-width: ${MediaBreakpoint.MAX_PHONE}px) {
      max-width: 375px;
    }
  `,
  Title: styled.h2`
    margin-bottom: 4px;
    color: ${Color.WHITE};
    text-align: center;
    font-size: 34px;
    font-weight: 800;
    line-height: 46px;
  `,
  Subtitle: styled.h3`
    color: ${Color.WHITE};
    text-align: center;
    font-size: 22px;
    font-weight: 600;
    line-height: 30px;
  `,
  Button: styled(Button)`
    position: relative;
    width: 324px;

    svg {
      position: absolute;
      top: 19px;
      right: 19px;
    }
  `,
}
