import confidentialIcon from 'assets/images/confidential-icon-white.svg'
import geoIcon from 'assets/images/geo-icon.png'
import mapBgFirst from 'assets/images/intro-google1-map-first.jpg'
import mapBgSecond from 'assets/images/intro-google1-map-second.jpg'
import mapBgThird from 'assets/images/intro-google1-map-third.jpg'
import locationIcon from 'assets/images/location-icon.png'
import lockIcon from 'assets/images/lock-icon.png'
import secureIcon from 'assets/images/secure-icon-white.svg'

export const BENEFITS_LIST_TEXTS = [
  'onboarding.introGoogle3.benefits.movementHistory',
  'onboarding.introGoogle3.benefits.realTime',
  'onboarding.introGoogle3.benefits.sos',
  'onboarding.introGoogle3.benefits.battery',
]

export const PAGE_NAME = 'intro_google'

export const BENEFITS_DATA = [
  {
    title: 'onboarding.introGoogle1.benefits.first.title',
    subtitle: 'onboarding.introGoogle1.benefits.first.subtitle',
    bgImage: mapBgFirst,
  },
  {
    title: 'onboarding.introGoogle1.benefits.second.title',
    subtitle: 'onboarding.introGoogle1.benefits.second.subtitle',
    bgImage: mapBgSecond,
  },
  {
    title: 'onboarding.introGoogle1.benefits.third.title',
    subtitle: 'onboarding.introGoogle1.benefits.third.subtitle',
    bgImage: mapBgThird,
  },
]

export const BENEFITS_DATA_GOOGLE_4 = [
  {
    title: 'onboarding.introGoogle4.benefits.allPhones',
    subtitle: 'onboarding.introGoogle4.benefits.compatibleDevices',
  },
  {
    title: 'onboarding.introGoogle4.benefits.anonymous',
    subtitle: 'onboarding.introGoogle4.benefits.protectPrivacy',
  },
  {
    title: 'onboarding.introGoogle4.benefits.networks',
    subtitle: 'onboarding.introGoogle4.benefits.geolocationNetwork',
  },
]

export const POSSIBILITIES_DATA = [
  {
    image: geoIcon,
    title: 'onboarding.introGoogle4.possibilities.sendRequests',
    subtitle: 'onboarding.introGoogle4.possibilities.trackFriends',
  },
  {
    image: locationIcon,
    title: 'onboarding.introGoogle4.possibilities.getLocation',
    subtitle: 'onboarding.introGoogle4.possibilities.pinpointLocation',
  },
  {
    image: lockIcon,
    title: 'onboarding.introGoogle4.possibilities.securePayments',
    subtitle: 'onboarding.introGoogle4.possibilities.payByCard',
  },
]

export const STEPS_DATA = [
  {
    title: 'onboarding.introGoogle4.steps.stepOne',
    subtitle: 'onboarding.introGoogle4.steps.enterThePhone',
  },
  {
    title: 'onboarding.introGoogle4.steps.stepTwo',
    subtitle: 'onboarding.introGoogle4.steps.provideEmail',
  },
  {
    title: 'onboarding.introGoogle4.steps.stepThree',
    subtitle: 'onboarding.introGoogle4.steps.proceedPayment',
  },
  {
    title: 'onboarding.introGoogle4.steps.stepFour',
    subtitle: 'onboarding.introGoogle4.steps.receiveLocation',
  },
]
export const SECURE_DATA = [
  {
    img: confidentialIcon,
    title: '100%',
    text: 'onboarding.introGoogle5.confidential',
  },
  {
    img: secureIcon,
    title: 'onboarding.introGoogle5.secure',
    text: 'onboarding.introGoogle5.ssl',
  },
]
