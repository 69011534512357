import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import 'react-international-phone/style.css'
import { useSelector } from 'react-redux'

import { selectUserCountryCode } from 'root-redux/selects/user'

import { checkIsPhoneValid } from 'helpers/phone'

import { TPageProps } from 'models/common.model'

import { eventLogger } from 'services/eventLogger.service'

import { Button } from 'components/Button'
import { ProgressBarListModal } from 'components/ProgressBarList'

import { goTo } from 'browser-history'
import { CustomPageId } from 'root-constants/pages'

import { StyledPhoneNumber as S } from './PhoneNumberVariant1.styles'
import { PAGE_ANSWER, PAGE_NAME } from './constants'

export const PhoneNumberVariant1: React.FC<TPageProps> = ({ nextPagePath }) => {
  const { t } = useTranslation()
  const countryCode = useSelector(selectUserCountryCode)
  const [phoneNumber, setPhoneNumber] = useState('')
  const [isFocused, setIsFocused] = useState(false)
  const [isSubmitted, setIsSubmitted] = useState(false)
  const isValid = checkIsPhoneValid(phoneNumber)

  const handleContinue = () => {
    eventLogger.logQuestion({
      question: t(`onboarding.introGoogle3.phoneText2`, { lng: 'en' }),
      answers: PAGE_ANSWER,
      pageName: PAGE_NAME,
    })

    goTo(nextPagePath)
  }

  const handleChange = (value: string) => {
    setPhoneNumber(value)
  }

  const handleFocus = () => {
    setIsFocused(true)
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    setIsSubmitted(true)
    eventLogger.logCheckNumberClicked()
  }

  return (
    <S.Wrapper>
      <S.Column>
        <S.Title>{t`onboarding.introGoogle3.phoneText2`}</S.Title>
        <S.PhoneForm onSubmit={handleSubmit}>
          <S.PhoneNumberInput
            defaultCountry={countryCode.toLowerCase()}
            value={phoneNumber}
            onChange={handleChange}
            onFocus={handleFocus}
          />
          {isFocused && !isValid && (
            <S.PhoneErrorText>
              {t`onboarding.introGoogle3.phoneError`}
            </S.PhoneErrorText>
          )}
          <S.LaterButton onClick={handleContinue} type="button">
            {t`actions.maybeLater`}
          </S.LaterButton>
          <Button type="submit">
            {t`onboarding.introGoogle3.phoneButton`}
          </Button>
        </S.PhoneForm>
        <ProgressBarListModal
          isShown={isSubmitted}
          nextPagePath={nextPagePath}
          pageId={CustomPageId.PHONE_NUMBER}
          phoneNumber={phoneNumber}
          pageName={PAGE_NAME}
        />
      </S.Column>
    </S.Wrapper>
  )
}
