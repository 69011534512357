import React, { useEffect } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import { getUserStatusAction } from 'root-redux/actions/user'
import { selectLanguage, selectScreenName } from 'root-redux/selects/common'
import { selectUUID, selectUserPaymentSystem } from 'root-redux/selects/user'

import { ScreenName } from 'services/eventLogger.service'

import { PaymentMethod, PaymentSystem } from 'modules/payment/constants'
import { selectPaymentMethod } from 'modules/payment/redux/selects'

import successIcon from 'assets/images/success.svg'

import { StyledPaymentStatus as S } from 'common-styles'
import { Language } from 'root-constants/common'

type TProps = {
  onNextStep?: () => void
  hasChipolo?: boolean
}

export const PaymentSuccess: React.FC<TProps> = ({
  onNextStep,
  hasChipolo,
  ...props
}) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const uuid = useSelector(selectUUID)
  const paymentMethod = useSelector(selectPaymentMethod)
  const screenName = useSelector(selectScreenName)
  const language = useSelector(selectLanguage)
  const paymentSystem = useSelector(selectUserPaymentSystem)

  const hasPayPalDisclaimer =
    paymentMethod === PaymentMethod.PAYPAL &&
    paymentSystem !== PaymentSystem.PRIMER

  useEffect(() => {
    return () => {
      dispatch(getUserStatusAction(uuid))
    }
  }, [dispatch, uuid])

  const handleSuccessButtonClick = () => {
    if (onNextStep) {
      onNextStep()
      return
    }
    dispatch(getUserStatusAction(uuid))
  }

  return (
    <S.Wrapper {...props}>
      <S.Container data-testid="purchase-outcome-container">
        <S.Icon src={successIcon} alt="Success" />
        <S.Title>{t`payment.successful`}</S.Title>
        <S.Subtitle>
          <Trans
            i18nKey={
              screenName === ScreenName.UPGRADE && hasChipolo
                ? 'payment.orderAccepted'
                : 'payment.thankYou'
            }
          />
        </S.Subtitle>
        <S.Button
          type="button"
          onClick={handleSuccessButtonClick}
          isPtLanguage={language === Language.PT}
        >
          {t`payment.proceedToMyPlan`}
        </S.Button>
        {hasPayPalDisclaimer && (
          <S.Disclaimer>{t`payment.successDisclaimer`}</S.Disclaimer>
        )}
      </S.Container>
    </S.Wrapper>
  )
}
