import styled from 'styled-components'

import {
  BASE_COLUMN_WIDTH,
  Color,
  MediaBreakpoint,
} from 'root-constants/common'

export const StyledDesktopProvider = {
  Root: styled.div`
    position: relative;
    display: flex;
    justify-content: space-between;
    min-height: var(--full-height, 100vh);
  `,
  LeftContainer: styled.div`
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    min-height: var(--full-height, 100vh);
    aspect-ratio: 705/1024;
    overflow: hidden;

    @media (min-width: ${MediaBreakpoint.LAPTOP}px) {
      display: block;
    }
  `,
  MiddleContainer: styled.div`
    display: none;
    position: relative;
    min-height: var(--full-height, 100vh);
    aspect-ratio: 705/1024;

    @media (min-width: ${MediaBreakpoint.LAPTOP}px) {
      display: block;
    }
  `,
  RightContainer: styled.div`
    position: relative;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-grow: 1;
    background-color: ${Color.WHITE};

    @media (min-width: ${MediaBreakpoint.LAPTOP}px) {
      align-items: center;
    }
  `,
  Image: styled.img`
    position: absolute;
    top: 50%;
    left: 0;
    display: block;
    max-width: 100%;
    max-height: 100%;
    aspect-ratio: 705/1024;
    transform: translateY(-50%);
  `,
  Gradient: styled.div`
    position: absolute;
    top: 0;
    right: 0;
    width: 40%;
    height: 100%;
    background: linear-gradient(
      270deg,
      ${Color.WHITE} 0%,
      rgba(255, 255, 255, 0) 95.88%
    );
  `,
  HeaderWrapper: styled.div`
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    border-bottom: 1px solid #ccdce5;
  `,
  Header: styled.header`
    width: ${BASE_COLUMN_WIDTH}px;
    padding: 13px 0 12px;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
  `,
}
