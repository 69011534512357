import { useEffect, useLayoutEffect, useState } from 'react'
import { useLocation } from 'react-router'

import { usePageInfo } from 'providers/PageInfoProvider'

import {
  selectAmplitudeApiKey,
  selectCurrentVariantCohort,
  selectLanguage,
  selectStripeAccountId,
  selectStripeAccountName,
} from 'root-redux/selects/common'
import {
  selectUUID,
  selectUserStatus,
  selectUtmTags,
} from 'root-redux/selects/user'

import { useBulkSelector } from 'hooks/common/useBulkSelector'
import { useCookieConsentAnswer } from 'hooks/common/useCookieConsentAnswer'

import { getEnvironment } from 'helpers/getEnvironment'
import { hasAnalyticsByLocale } from 'helpers/hasAnalyticsByLocale'

import { Amplitude } from 'services/amplitude.service'
import { eventLogger } from 'services/eventLogger.service'

import { EventLoggerInstanceName } from 'root-constants/common'

const useSelectors = () =>
  useBulkSelector({
    cohort: selectCurrentVariantCohort,
    uuid: selectUUID,
    amplitudeApiKey: selectAmplitudeApiKey,
    stripeAccountId: selectStripeAccountId,
    stripeAccountName: selectStripeAccountName,
    utmTags: selectUtmTags,
    userStatus: selectUserStatus,
    language: selectLanguage,
  })

export const useAmplitudeInitialization = () => {
  const { search } = useLocation()
  const {
    cohort,
    uuid,
    amplitudeApiKey,
    stripeAccountId,
    stripeAccountName,
    utmTags,
    userStatus,
    language,
  } = useSelectors()

  const [amplitudeInstance, setAmplitudeInstance] = useState<Amplitude | null>(
    null,
  )

  const URLParams = new URLSearchParams(search)
  const utmTagsFromUrl = Object.fromEntries(URLParams.entries())
  const { isProdEnvironment, isTestEnvironment } = getEnvironment()

  const { isPaymentPage } = usePageInfo()
  const { hasAnalyticsAndPerformanceCookie, isEUUser } =
    useCookieConsentAnswer()

  const shouldAmplitudeBePaused =
    isEUUser &&
    !hasAnalyticsAndPerformanceCookie &&
    !hasAnalyticsByLocale(language)

  const hasAmplitude = isPaymentPage || userStatus?.hasSubscription

  useLayoutEffect(() => {
    if (eventLogger.isAmplitudeActive || shouldAmplitudeBePaused) return

    if (uuid && amplitudeApiKey && hasAmplitude) {
      const amplitudeService = new Amplitude({
        apiKey: amplitudeApiKey,
        instanceName: EventLoggerInstanceName.AMPLITUDE,
        userId: uuid,
        utmTags: utmTagsFromUrl.utm_source ? utmTagsFromUrl : utmTags,
        isTestEnvironment,
        isDevEnvironment: !isProdEnvironment,
      })

      amplitudeService.configure({ cohort, language })
      eventLogger.init(amplitudeService)
      setAmplitudeInstance(amplitudeService)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    amplitudeApiKey,
    cohort,
    isProdEnvironment,
    isPaymentPage,
    isTestEnvironment,
    search,
    utmTags,
    uuid,
    shouldAmplitudeBePaused,
    userStatus?.hasSubscription,
  ])

  useEffect(() => {
    if (!stripeAccountId || !stripeAccountName) return

    amplitudeInstance?.updateUserProperties(stripeAccountId, stripeAccountName)
  }, [amplitudeInstance, stripeAccountId, stripeAccountName])
}
