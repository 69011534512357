import { useMemo } from 'react'
import { useSelector } from 'react-redux'

import { selectAnswers } from 'root-redux/selects/common'

import { COUNTRIES_DATA } from 'components/Map/constants'

export const useGetUserPosition = () => {
  const answers = useSelector(selectAnswers)

  const [position] = useMemo(() => {
    return COUNTRIES_DATA.filter(
      (item) => item.country === answers?.userPhoneCountry,
    )
  }, [answers?.userPhoneCountry])

  return {
    lat: position.lat,
    lng: position.lng,
    timeZone: position.timeZone,
  }
}
