import React from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'

import { usePageInfo } from 'providers/PageInfoProvider'

import { useNextStep } from 'hooks/useNextStep'

import { TPageProps } from 'models/common.model'

import { BENEFITS } from 'pages/benefits/constants'

import {
  BackgroundGlobalStyles,
  StyledBenefitsVariant1 as S,
} from './BenefitsVariant1.styles'

export const BenefitsVariant1: React.FC<TPageProps> = ({ pageId }) => {
  const { search } = useLocation()
  const { t } = useTranslation()
  const { currentSubscriptionPageId } = usePageInfo()

  const handleContinue = useNextStep({
    pageId,
    pageName: pageId,
    question: '',
    nextPagePath: `${currentSubscriptionPageId}${search}`,
  })

  return (
    <S.Wrapper>
      <BackgroundGlobalStyles />
      <S.BurgerMenu />
      <S.Content>
        {BENEFITS.map(({ image, text }) => (
          <S.BenefitContainer key={text}>
            <S.Image src={image} alt="icon" />
            <S.Text>
              <Trans i18nKey={text} />
            </S.Text>
          </S.BenefitContainer>
        ))}
      </S.Content>

      <S.Button type="button" onClick={() => handleContinue('')}>
        {t`actions.continue`}
      </S.Button>
    </S.Wrapper>
  )
}
