import styled from 'styled-components'

import { Option } from 'components/Option'

import { baseColumnStyles, basePageStyles } from 'common-styles'
import { Color, HEADER_HEIGHT } from 'root-constants/common'

export const StyledAgeRangeVariant2 = {
  Wrapper: styled.div`
    ${basePageStyles};
    min-height: 100%;
    padding-top: ${HEADER_HEIGHT}px;
  `,
  Column: styled.div`
    ${baseColumnStyles};
    text-align: center;
    margin: 0 auto;
    padding: 24px 0 10px;
    width: 327px;
  `,
  Title: styled.h1`
    font-weight: 700;
    font-size: 24px;
    line-height: 33px;
    text-align: center;
    margin: 0 0 12px 0;
  `,
  Subtitle: styled.p`
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
    margin: 0 0 26px 0;
    text-align: center;
  `,
  OptionsContainer: styled.div`
    width: 100%;
    display: grid;
    grid-gap: 8px 11px;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, 1fr);
    margin-bottom: 24px;
  `,
  Option: styled(Option)`
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    margin: 0;
    position: relative;
    width: 100%;
  `,

  RangeImage: styled.div`
    aspect-ratio: 161 / 168;
    width: 100%;
    z-index: 1;
    background-color: #e6e9f6;
    border: 2px solid #e6e9f6;
    border-radius: 18px;
    padding: 2px;

    input:checked + & {
      border-color: ${Color.ACCENT_VIOLET};
    }

    img {
      aspect-ratio: 153 / 124;
      max-width: 153px;
      width: 153px;
    }
  `,

  Range: styled.span`
    align-items: center;
    background-color: ${Color.WHITE};
    border-radius: 16px;
    color: ${({ theme }) => theme.colors.defaultTextColor};
    display: flex;
    font-size: 16px;
    font-weight: 700;
    justify-content: space-between;
    padding: 12px;
    width: 100%;
  `,
  ArrowIcon: styled.span`
    background-color: ${({ theme }) => theme.colors.activeBackground};
    border-radius: 50%;
    height: 24px;
    position: relative;
    width: 24px;

    :after {
      border-left: 2px solid ${Color.WHITE};
      border-radius: 1px;
      border-top: 2px solid ${Color.WHITE};
      content: '';
      display: block;
      height: 8px;
      left: 50%;
      position: absolute;
      top: 50%;
      transform: translate(-75%, -50%) rotate(135deg);
      width: 8px;
    }
  `,
}
