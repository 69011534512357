import React from 'react'

import { TPageProps } from 'models/common.model'

import { RadioOptionPage } from 'pages/options-page/RadioOptionPage'

import { LOW_CONFIDENCE_OPTION_DATA } from 'root-constants/common'

import { TITLE_PATH_CHILD_LOCATION_4 } from './constants'

export const ChildLocationVariant4: React.FC<TPageProps> = (props) => (
  <RadioOptionPage
    {...props}
    options={LOW_CONFIDENCE_OPTION_DATA}
    titlePath={TITLE_PATH_CHILD_LOCATION_4}
    marginBottom={87}
  />
)
