import React from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

import { eventLogger } from 'services/eventLogger.service'

import { AppLinks } from 'root-constants/common'

export const PrivacyPolicyLink: React.FC = () => {
  const { t } = useTranslation()

  return (
    <Link
      to={AppLinks.PP_LINK}
      onClick={() => eventLogger.logPrivacyPolicyClicked()}
      target="_blank"
      rel="noopener noreferrer"
    >
      {t`commonComponents.privacyPolicy`}
    </Link>
  )
}
