import React, { useMemo } from 'react'
import { Trans, useTranslation } from 'react-i18next'

import { useDynamicPaywallConfig } from 'hooks/useDynamicPaywallConfig'
import { useUserData } from 'hooks/useUserData'

import { UserGoal } from 'root-constants/common'

import { StyledBenefits as S } from './Benefits.styles'
import { USER_GOAL_BENEFITS, USER_MULTI_GOAL_BENEFITS } from './constants'

type TProps = {
  hasLaptopStyles?: boolean
}

export const Benefits: React.FC<TProps> = ({ hasLaptopStyles = false }) => {
  const { t } = useTranslation()
  const { singleUserGoal } = useUserData()
  const { benefitsBlockTitle, benefits } = useDynamicPaywallConfig()

  const benefitsList = useMemo(() => {
    if (benefits?.length) return benefits

    return singleUserGoal
      ? USER_GOAL_BENEFITS[singleUserGoal]
      : USER_MULTI_GOAL_BENEFITS
  }, [benefits, singleUserGoal])

  return (
    <S.Wrapper hasLaptopStyles={hasLaptopStyles}>
      <S.Title>
        {benefitsBlockTitle ||
          (singleUserGoal === UserGoal.PARTNER
            ? t`subscriptions.benefits.alternativeTitle`
            : t`subscriptions.benefits.title`)}
      </S.Title>
      <S.List data-is-partner-goal={singleUserGoal === UserGoal.PARTNER}>
        {benefitsList.map(({ id, text }) => (
          <S.ListItem key={id}>
            <Trans i18nKey={text} />
          </S.ListItem>
        ))}
      </S.List>
    </S.Wrapper>
  )
}
