import styled from 'styled-components'

import { MediaBreakpoint } from 'root-constants/common'

export const StyledPlanBlockVariant1 = {
  Wrapper: styled.div`
    margin-bottom: 8px;
    width: 100%;

    @media (min-width: ${MediaBreakpoint.LAPTOP}px) {
      display: flex;
      gap: 21px;
      margin-bottom: 56px;
    }
  `,
}
