import styled from 'styled-components'

import { baseColumnStyles, basePageStyles } from 'common-styles'
import { HEADER_HEIGHT, MediaBreakpoint } from 'root-constants/common'

export const StyledOnePlanForEveryone = {
  Wrapper: styled.div`
    ${basePageStyles};
    min-height: var(--full-height, 100vh);
    justify-content: space-between;
    padding-top: ${HEADER_HEIGHT}px;
  `,
  Column: styled.div`
    ${baseColumnStyles};
    padding-bottom: 40px;
  `,
  ImageContainer: styled.div`
    max-width: ${MediaBreakpoint.MAX_PHONE}px;
    aspect-ratio: 375/350;

    @media (min-width: ${MediaBreakpoint.MAX_PHONE}px) {
      max-width: 375px;
    }
  `,
  Text: styled.p`
    margin-bottom: 50px;
    font-weight: 700;
    font-size: 24px;
    line-height: 33px;
    text-align: center;
  `,
}
