import styled from 'styled-components'

import { MediaBreakpoint } from 'root-constants/common'

export const StyledWrapper = styled.div`
  position: fixed;
  width: 100%;
  max-width: 460px;
  bottom: 0;
  padding: 77px 0 32px;
  background-image: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0) 0%,
    #ffffff 41%
  );
  background-size: cover;
  background-repeat: no-repeat;
  pointer-events: none;
  z-index: 1;

  button {
    pointer-events: auto;
  }

  @media (min-width: ${MediaBreakpoint.TABLET}px) {
    button {
      width: 100%;
      font-size: 24px;
      height: 76px;
      border-radius: 100px;
    }
  }

  @media (min-width: ${MediaBreakpoint.LAPTOP}px) {
    position: relative;
  }
`
