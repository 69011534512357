import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { ISubscription } from 'models/subscriptions.model'

import { selectCurrency } from 'modules/payment/redux/selects'

import { PriceValue } from 'components/PriceValue'

import { CURRENCY_SYMBOLS, ProductKey } from 'root-constants/common'

import { StyledPlanItemVariant4 as S } from './PlanItemVariant4.styles'
import { INTRO_OFFER_PERIODS } from './constants'

type TProps = {
  subscription: ISubscription
  isSelected: boolean
}

export const PlanItemVariant5: React.FC<TProps> = ({
  subscription: {
    isDefault,
    product,
    trialPrices: { fullPrice: trialPrice, durationDays, daily, oldPrices },
  },
  isSelected,
}) => {
  const { t } = useTranslation()
  const currency = useSelector(selectCurrency)

  const [integerCustomDailyPrice, fractionalCustomDailyPrice = '00'] = daily
    .toFixed(2)
    .split('.')

  return (
    <S.Wrapper
      isDefault={isDefault}
      isSelected={isSelected}
      labelText={t`subscriptions.planBlock.mostPopular`}
    >
      <S.Checkbox isSelected={isSelected} />
      <S.MainPricesContainer>
        <S.Period>
          {t('payment.variant5.planPeriodName', {
            periodQuantity: INTRO_OFFER_PERIODS[durationDays],
            periodName:
              product === ProductKey.ONE_WEEK
                ? t('payment.variant5.planPeriods.week')
                : t('payment.variant5.planPeriods.month', {
                    count: INTRO_OFFER_PERIODS[durationDays],
                  }),
          })}
        </S.Period>

        <S.TotalPrice>
          <PriceValue
            i18nKey="payment.variant5.total"
            value={trialPrice}
            currency={currency}
            context={product}
          />
        </S.TotalPrice>
        {isSelected && (
          <S.Discount>
            <PriceValue
              i18nKey="payment.variant5.discount"
              value={oldPrices.beforeCustomDiscount.amountOfDiscount}
              currency={currency}
            />
          </S.Discount>
        )}
      </S.MainPricesContainer>

      <S.CustomPriceContainer isSelected={isSelected}>
        <S.Currency>{CURRENCY_SYMBOLS[currency]}</S.Currency>
        <S.CustomPriceInteger>{integerCustomDailyPrice}</S.CustomPriceInteger>
        <S.CustomPriceFractional>
          {fractionalCustomDailyPrice}{' '}
        </S.CustomPriceFractional>
        <S.CustomPricePeriod>
          {t('subscriptions.planBlock.perPeriod', {
            interval: t('subscriptions.planBlock.perDay'),
          })}
        </S.CustomPricePeriod>
      </S.CustomPriceContainer>
    </S.Wrapper>
  )
}
