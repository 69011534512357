import React from 'react'
import { useSelector } from 'react-redux'

import { usePageInfo } from 'providers/PageInfoProvider'

import { selectCurrentVariantSteps } from 'root-redux/selects/common'

import { StyledProgressBar as S } from './ProgressBar.styles'

export const ProgressBar: React.FC = () => {
  const steps = useSelector(selectCurrentVariantSteps)
  const { currentPageId, hasProgressbar } = usePageInfo()

  const pagesWithProgressBar = steps.filter(
    ({ hasProgressBar }) => hasProgressBar,
  )

  const currentPageIndex = pagesWithProgressBar.findIndex(
    ({ id }) => id === currentPageId,
  )

  return !hasProgressbar ? null : (
    <S.Wrapper>
      <S.Progress
        max={pagesWithProgressBar.length + 1}
        value={currentPageIndex + 1}
      />
    </S.Wrapper>
  )
}
