import styled, { css } from 'styled-components'

import { PaymentRequestButton } from 'modules/payment/components/PaymentRequestButton'
import { StripePaymentForm } from 'modules/payment/components/StripePaymentForm'

import { PhoneNumberLabel } from 'components/PhoneNumberLabel'

import desktopBg from 'assets/images/personal-discount-bg-desktop.jpg'
import bg from 'assets/images/personal-discount-bg.jpg'

import { Color, MediaBreakpoint } from 'root-constants/common'

type TTextProps = {
  gridArea: string
}

type TPhoneNumberLabelProps = {
  hasPersonalDiscount?: boolean
}

type TPriceProps = {
  isFontOversized?: boolean
}

const commonPriceTextStyles = css`
  color: #91bfff;
  text-align: center;
  font-size: 24px;
  font-weight: 700;
  line-height: 32px;
`

export const StyledPaymentFlowsGoogleVariant4 = {
  Wrapper: styled.div``,
  PhoneNumberLabel: styled(PhoneNumberLabel)<TPhoneNumberLabelProps>`
    padding: 8px 16px;
    border-radius: 0 0 20px 20px;
    background: ${({ hasPersonalDiscount }) =>
      hasPersonalDiscount
        ? 'none'
        : 'linear-gradient(90deg, #fc8e57 0.61%, #ffd651 59.15%)'};
    box-shadow: ${({ hasPersonalDiscount }) =>
      hasPersonalDiscount ? 'none' : '0 4px 14px 0 rgba(45, 67, 82, 0.3)'};

    p:first-of-type {
      font-size: 15px;
      font-weight: 600;
      line-height: 20px;
      color: ${({ hasPersonalDiscount }) =>
        hasPersonalDiscount ? `${Color.WHITE}` : '#35319f'};

      @media (min-width: ${MediaBreakpoint.LAPTOP}px) {
        font-size: 17px;
        line-height: 24px;
      }
    }

    p:last-of-type {
      font-size: ${({ hasPersonalDiscount }) =>
        hasPersonalDiscount ? 17 : 16}px;
      font-weight: 700;
      line-height: 24px;
      color: ${({ hasPersonalDiscount }) =>
        hasPersonalDiscount ? `${Color.WHITE}` : '#35319f'};

      strong {
        font-weight: 700;
      }

      @media (min-width: ${MediaBreakpoint.LAPTOP}px) {
        font-size: 20px;
        line-height: 26px;
      }
    }
  `,
  PriceContainer: styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto;
    grid-template-areas:
      'priceText priceValue'
      'trialDuration trialDuration';
    margin: 24px 0 16px;

    @media (min-width: ${MediaBreakpoint.LAPTOP}px) {
      margin: 32px 0 24px;
    }
  `,
  PaymentRequestButton: styled(PaymentRequestButton)`
    margin-bottom: 24px;
  `,
  PriceText: styled.p<TTextProps>`
    ${commonPriceTextStyles};
    grid-area: ${({ gridArea }) => gridArea};
    justify-self: flex-start;

    @media (min-width: ${MediaBreakpoint.LAPTOP}px) {
      font-size: 32px;
      line-height: 40px;
    }
  `,
  PriceValue: styled.p<TTextProps>`
    ${commonPriceTextStyles};
    grid-area: ${({ gridArea }) => gridArea};
    justify-self: flex-end;

    @media (min-width: ${MediaBreakpoint.LAPTOP}px) {
      font-size: 32px;
      line-height: 40px;
    }
  `,
  TrialDurationText: styled.p`
    grid-area: trialDuration;
    justify-self: flex-end;
    font-size: 14px;
    font-weight: 600;
    line-height: 18px;
    color: ${Color.WHITE};

    @media (min-width: ${MediaBreakpoint.LAPTOP}px) {
      font-size: 15px;
      line-height: 20px;
    }
  `,
  StripePaymentForm: styled(StripePaymentForm)`
    input {
      margin-bottom: 24px;
      font-size: 17px;
      color: #616a77;
    }

    p {
      font-size: 13px;
      font-weight: 600;
      line-height: 16px;
      letter-spacing: 0.5px;
    }

    @media (min-width: ${MediaBreakpoint.LAPTOP}px) {
      width: initial;
      margin-bottom: 25px;
    }
  `,
  Image: styled.img`
    max-width: 293px;
    margin: 32px auto 16px;
  `,
  Guarantee: styled.p`
    color: ${Color.WHITE};
    text-align: center;
    font-size: 12px;
    font-weight: 600;
    line-height: 16px;
    padding-bottom: 50px;

    @media (min-width: ${MediaBreakpoint.LAPTOP}px) {
      font-size: 14px;
      line-height: 18px;
      font-weight: 400;
    }
  `,
  Disclaimer: styled.p`
    margin-bottom: 32px;
    font-size: 11px;
    font-weight: 400;
    line-height: 14px;
    color: #dfecff;

    @media (min-width: ${MediaBreakpoint.LAPTOP}px) {
      font-size: 13px;
      line-height: 16px;
    }
  `,
  DiscountWrapper: styled.div`
    width: 100%;
    background-image: url(${bg});
    display: flex;
    align-items: center;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    border-radius: 100px;
    position: relative;
    height: 66px;
    margin-top: 24px;

    img {
      position: absolute;
      height: 90px;
      top: -9px;
      left: -6px;

      @media (min-width: ${MediaBreakpoint.LAPTOP}px) {
        height: 112px;
        left: -10px;
      }
    }

    @media (min-width: ${MediaBreakpoint.LAPTOP}px) {
      margin-top: 41px;
      height: 84px;
      background-image: url(${desktopBg});
      background-position: initial;
    }
  `,
  DiscountText: styled.p`
    font-size: 14px;
    font-weight: 600;
    line-height: 18px;
    color: ${Color.WHITE};
    padding-left: 68px;

    strong {
      font-size: 15px;
      font-weight: 600;
      line-height: 20px;
      color: #ffa0d6;
    }

    @media (min-width: ${MediaBreakpoint.LAPTOP}px) {
      font-size: 20px;
      line-height: 24px;
      padding-left: 95px;

      strong {
        font-size: 20px;
        line-height: 24px;
      }
    }
  `,
  TimerContainer: styled.div`
    max-width: 94px;
    width: 100%;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 100px;
    border: 1px solid rgba(255, 255, 255, 0.2);
    background: rgba(255, 255, 255, 0.2);
    font-size: 24px;
    font-weight: 700;
    line-height: 32px;
    color: ${Color.WHITE};
    margin-left: 10px;

    @media (min-width: ${MediaBreakpoint.LAPTOP}px) {
      font-size: 32px;
      max-width: 143px;
      margin-left: 25px;
    }
  `,
  Separator: styled.div`
    width: 100%;
    height: 1px;
    background: ${Color.WHITE};
    opacity: 0.2;
    margin: 16px 0;

    @media (min-width: ${MediaBreakpoint.LAPTOP}px) {
      margin: 32px 0 24px;
    }
  `,
  PriceContainerV2: styled.div`
    width: 100%;
    margin-bottom: 30px;
  `,
  PriceBlock: styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
  `,
  Payment: styled.p`
    font-size: 18px;
    font-weight: 700;
    line-height: 24px;
    color: ${Color.WHITE};

    @media (min-width: ${MediaBreakpoint.LAPTOP}px) {
      font-size: 20px;
      line-height: 26px;
    }
  `,
  PricesWrapper: styled.div`
    display: flex;
    gap: 8px;
  `,
  OldPrice: styled.p<TPriceProps>`
    ${commonPriceTextStyles};
    color: rgba(255, 255, 255, 0.3);
    font-size: ${({ isFontOversized }) => (isFontOversized ? 19 : 24)}px;
    text-decoration: line-through;
    text-decoration-color: ${Color.WHITE};

    @media (min-width: ${MediaBreakpoint.LAPTOP}px) {
      font-size: 32px;
      line-height: 40px;
    }
  `,
  CurrentPrice: styled.p<TPriceProps>`
    ${commonPriceTextStyles};
    color: ${Color.WHITE};
    font-size: ${({ isFontOversized }) => (isFontOversized ? 19 : 24)}px;

    @media (min-width: ${MediaBreakpoint.LAPTOP}px) {
      font-size: 32px;
      line-height: 40px;
    }
  `,
  TextBlock: styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 2px;
  `,
  Gift: styled.p`
    font-size: 14px;
    font-weight: 600;
    line-height: 18px;
    color: #ffffff80;
  `,
  Duration: styled.p`
    font-size: 14px;
    font-weight: 600;
    line-height: 18px;
    color: ${Color.WHITE};

    @media (min-width: ${MediaBreakpoint.LAPTOP}px) {
      font-size: 15px;
      line-height: 20px;
    }
  `,
}
