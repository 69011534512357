import {
  CardCvcElement,
  CardExpiryElement,
  CardNumberElement,
} from '@stripe/react-stripe-js'
import { StripeElementStyle } from '@stripe/stripe-js'
import styled, { css } from 'styled-components'

import { Button } from 'components/Button'

import cardIcon from 'assets/images/card.svg'
import warningIcon from 'assets/images/warning.svg'

import { Color } from 'root-constants/common'

type TLabelProps = {
  gridArea: string
}

type TInputProps = {
  borderColor: string
}

export const stripeElementStyle: StripeElementStyle = {
  base: {
    color: Color.DEFAULT_TEXT,
    fontFamily: 'Nunito, sans-serif',
    fontSize: '16px',
    lineHeight: '56px',
    fontWeight: 700,
    fontSmoothing: 'antialiased',
    '::placeholder': {
      color: '#bcc9d0',
      fontWeight: 500,
    },
  },
  invalid: {
    color: Color.ERROR,
  },
}

const commonInputStyles = css<TInputProps>`
  position: relative;
  margin-bottom: 12px;
  padding-left: 16px;
  height: 56px;
  background-color: ${Color.WHITE};
  color: ${Color.DEFAULT_TEXT};
  font-size: 16px;
  font-weight: 700;
  border-radius: 12px;
  border: ${({ borderColor }) => `1px solid ${borderColor}`};
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);

  &[data-invalid='true'],
  &.StripeElement--invalid {
    border: 1px solid ${Color.ERROR};

    &::before {
      content: url(${warningIcon});
      position: absolute;
      top: 50%;
      right: 16px;
      transform: translateY(-50%);
      width: 18px;
      height: 18px;
    }
  }
`

export const StyledStripePaymentFormVariant2 = {
  Form: styled.form`
    position: relative;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto;
    grid-template-areas:
      'cardNumberLabel cardNumberLabel'
      'cardNumber cardNumber'
      'cardExpiryLabel cardCvcLabel'
      'cardExpiry cardCvc'
      'cardholderLabel cardholderLabel'
      'cardholder cardholder'
      'button button';
    grid-gap: 0 16px;
    width: 100%;
    margin-bottom: 16px;
  `,
  Label: styled.p<TLabelProps>`
    grid-area: ${({ gridArea }) => gridArea};
    padding-bottom: 4px;
    font-size: 13px;
    line-height: 20px;
    color: ${Color.DEFAULT_TEXT};
  `,
  CardNumberElement: styled(CardNumberElement)`
    ${commonInputStyles};
    grid-area: cardNumber;
  `,
  CardExpiryElement: styled(CardExpiryElement)`
    ${commonInputStyles};
    grid-area: cardExpiry;
  `,
  CardCvcElement: styled(CardCvcElement)`
    ${commonInputStyles};
    grid-area: cardCvc;

    &.StripeElement--invalid {
      &::before {
        right: 48px;
      }
    }

    ::after {
      content: url(${cardIcon});
      position: absolute;
      right: 16px;
      top: 18px;
    }
  `,
  CardholderInput: styled.input`
    ${commonInputStyles};
    grid-area: cardholder;
    width: 100%;
    margin-bottom: 16px;
    outline: none;
    box-shadow: none;

    ::placeholder {
      color: #bcc9d0;
      font-weight: 500;
    }
  `,
  SubmitButton: styled(Button)`
    grid-area: button;
    height: 62px;
    line-height: 62px;
  `,
}
