import React, { useMemo } from 'react'
import { Trans, useTranslation } from 'react-i18next'

import { usePurchaseStore } from 'hooks/usePurchaseStore'
import { useVatInfo } from 'hooks/useVatInfo'

import { getPriceWithoutTaxes } from 'helpers/getPriceWithoutTaxes'

import { ISelectPlanItemProps } from 'models/common.model'

import { PriceValue } from 'components/PriceValue'

import arrow from 'assets/images/right-arrow.svg'

import {
  Currency,
  INTRO_OFFER_PERIODS_WEEKS,
  INTRO_OFFER_WEEKLY_PERIOD_NAMES,
  Language,
  ProductKey,
} from 'root-constants/common'

import { OVERSIZE_CURRENCIES } from '../../../constants'
import { StyledTrialPlanItemVariant2 as S } from './TrialPlanItemVariant2.styles'

export const TrialPlanItemVariant2: React.FC<ISelectPlanItemProps> = ({
  subscription: {
    isDefault,
    product,
    mainPrices: { fullPrice },
    trialPrices: { fullPrice: trialPrice, durationDays, daily, oldPrices },
  },
  isSelected,
  hasLaptopStyles = false,
}) => {
  const { t } = useTranslation()

  const { language, currency, taxAmount } = usePurchaseStore()
  const hasIncludedVat = useVatInfo()

  const oldPrice = useMemo(() => {
    const actualOldPrice =
      product === ProductKey.ONE_WEEK
        ? oldPrices.beforeCustomDiscount.fullPrice
        : fullPrice

    const actualOldPriceWithoutTaxes = getPriceWithoutTaxes({
      price: actualOldPrice,
      taxAmount,
    })

    return hasIncludedVat
      ? actualOldPriceWithoutTaxes
      : actualOldPrice.toFixed(2)
  }, [
    product,
    oldPrices.beforeCustomDiscount.fullPrice,
    fullPrice,
    taxAmount,
    hasIncludedVat,
  ])

  const priceWithoutTaxes = getPriceWithoutTaxes({
    price: trialPrice,
    taxAmount,
  })

  const dailyPriceWithoutTaxes = getPriceWithoutTaxes({
    price: daily,
    taxAmount,
  })

  const oldDailyPriceWithoutTaxes = getPriceWithoutTaxes({
    price: oldPrices.beforeCustomDiscount.daily,
    taxAmount,
  })

  const hasSmallerFontSize = OVERSIZE_CURRENCIES.includes(currency)
  const isMexicanPeso = currency === Currency.MXN

  return (
    <S.PlanItem
      data-has-laptop-styles={hasLaptopStyles}
      data-is-selected={isSelected}
      data-is-default={isDefault}
      data-text={t`subscriptions.planBlock.mostPopular`}
      data-has-smaller-padding={language === Language.ES || isMexicanPeso}
    >
      <S.PlanTitleWrapper>
        <S.PlanTitle hasSmallerFontSize={language === Language.PT}>
          <Trans
            i18nKey="subscriptions.planBlock.weeklyPlanTitle"
            values={{
              periodQuantity: INTRO_OFFER_PERIODS_WEEKS[durationDays],
              periodName: t(INTRO_OFFER_WEEKLY_PERIOD_NAMES[durationDays]),
            }}
          />
        </S.PlanTitle>
        <S.PlanPricesWrapper>
          {!!oldPrices.beforeCustomDiscount.fullPrice && (
            <S.OldPlanPrice isMexicanPeso={isMexicanPeso}>
              <PriceValue value={oldPrice} currency={currency} />
            </S.OldPlanPrice>
          )}
          <img src={arrow} alt="icon" />
          <S.CurrentPrice isMexicanPeso={isMexicanPeso}>
            <PriceValue
              value={hasIncludedVat ? priceWithoutTaxes : trialPrice}
              currency={currency}
            />
          </S.CurrentPrice>
        </S.PlanPricesWrapper>
      </S.PlanTitleWrapper>
      <S.PlanPrice
        marginBottom={language === Language.EN ? 10 : 0}
        hasSmallerFontSize={hasSmallerFontSize}
      >
        <p>
          <PriceValue
            value={
              hasIncludedVat
                ? oldDailyPriceWithoutTaxes
                : oldPrices.beforeCustomDiscount.daily
            }
            currency={currency}
          />
        </p>
        <strong>
          <PriceValue
            value={hasIncludedVat ? dailyPriceWithoutTaxes : daily}
            currency={currency}
          />
        </strong>
        <sub>
          {t('subscriptions.planBlock.perPeriod', {
            interval: t('subscriptions.planBlock.perDay'),
          })}
        </sub>
      </S.PlanPrice>
    </S.PlanItem>
  )
}
