import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'

export const useScrollToAnchor = (isLoaded: boolean) => {
  const { hash } = useLocation()

  useEffect(() => {
    if (!isLoaded) return
    const targetElement = document.getElementById(hash.slice(1))

    targetElement?.scrollIntoView({
      block: 'start',
      behavior: 'instant',
    })
  }, [hash, isLoaded])
}
