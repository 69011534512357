import React, { useLayoutEffect } from 'react'
import { useDispatch } from 'react-redux'

import { usePrimerContext } from 'providers/PrimerProvider'

import { startFetching } from 'root-redux/actions/common'

import { PRIMER_PAY_PAL_ID } from 'modules/payment/constants'
import { SET_PRIMER_PAYMENT_FORM_IS_LOADING } from 'modules/payment/redux/actions/primer'

import { StyledPrimerPayPalButton as S } from './PrimerPayPalButton.styles'

export const PrimerPayPalButton: React.FC = () => {
  const dispatch = useDispatch()
  const { paypalManagerRef } = usePrimerContext()

  useLayoutEffect(() => {
    if (!paypalManagerRef.current) {
      dispatch(startFetching(SET_PRIMER_PAYMENT_FORM_IS_LOADING))
    }
  }, [dispatch, paypalManagerRef])

  return <S.PayPalButton id={PRIMER_PAY_PAL_ID} />
}
