import { useCallback, useEffect, useRef, useState } from 'react'

import { easeInOutQuad } from 'helpers/animationFunctions'

const frameDuration = 1000 / 60

export const useCounter = (
  hasStarted: boolean,
  animation: {
    duration: number
    from?: number
    to?: number
    animationFunction?: (t: number) => number
    callback?: (data: string) => void
  },
): {
  counter: number
  linearCounterValue: number
  isFinished: boolean
  handlePause: () => void
  handleResume: () => void
} => {
  const {
    from: countFrom = 0,
    to: countTo = 100,
    duration: animationDuration,
    animationFunction = easeInOutQuad,
    callback,
  } = animation

  const [counterValue, setCounterValue] = useState(countFrom)
  const [linearCounterValue, setLinearCounterValue] = useState(countFrom)
  const isStoppedRef = useRef(false)

  const setComputedValues = useCallback(
    (frame: number, totalFrames: number) => {
      setCounterValue(
        Math.round(countTo * animationFunction(frame / totalFrames)),
      )
      setLinearCounterValue(Math.round(countTo * (frame / totalFrames)))
    },
    [animationFunction, countTo],
  )

  useEffect(() => {
    if (!hasStarted) return
    if (countFrom >= countTo && callback) {
      callback('')
    }

    let frame = 0
    const totalFrames = Math.round(animationDuration / frameDuration)

    const counterIntervalId = setInterval(() => {
      if (isStoppedRef.current) {
        setComputedValues(frame + 1, totalFrames)
        return
      }

      frame += 1

      setComputedValues(frame, totalFrames)

      if (frame !== totalFrames) return

      clearInterval(counterIntervalId)
      callback && callback('')
    }, frameDuration)

    // eslint-disable-next-line consistent-return
    return () => {
      clearInterval(counterIntervalId)
    }
  }, [
    animationDuration,
    animationFunction,
    callback,
    countFrom,
    countTo,
    hasStarted,
    setComputedValues,
  ])

  return {
    counter: counterValue,
    linearCounterValue,
    isFinished: counterValue === countTo,
    handlePause: () => {
      isStoppedRef.current = true
    },
    handleResume: () => {
      isStoppedRef.current = false
    },
  }
}
