import React from 'react'
import { Trans, useTranslation } from 'react-i18next'

import { usePurchaseStore } from 'hooks/usePurchaseStore'

import { ISelectPlanItemProps } from 'models/common.model'

import {
  DESCRIPTION_DATA,
  PLAN_ITEM_TITLES,
} from 'modules/subscriptions/components/upsell/constants'

import checkIconGrey from 'assets/images/check-icon-grey-v2.svg'
import checkIconViolet from 'assets/images/check-icon-violet-filled.svg'

import { CURRENCY_SYMBOLS, Cohort, Currency } from 'root-constants/common'

import { StyledUpsellPlanItemVariant1 as S } from './UpsellPlanItemVariant1.styles'

export const UpsellPlanItemVariant1: React.FC<ISelectPlanItemProps> = ({
  subscription: {
    isDefault,
    discountPercent,
    product,
    mainPrices: { fullPrice },
  },
  isSelected,
}) => {
  const { t } = useTranslation()

  const { cohortToUse, currency } = usePurchaseStore()

  const oldPrice = (fullPrice / (discountPercent / 100)).toFixed(2)
  const isOceaniaCurrency =
    currency === Currency.NZD || currency === Currency.AUD

  return (
    <S.Wrapper
      data-is-selected={isSelected}
      data-is-default={isDefault}
      isDifferentBG={isSelected && isDefault}
    >
      <S.TitleWrapper>
        <S.RadioButton
          checkIcon={isSelected ? checkIconViolet : checkIconGrey}
        />
        <S.Title>
          <Trans i18nKey={t(PLAN_ITEM_TITLES[product])} />
        </S.Title>
        <S.PriceWrapper>
          {isDefault && (
            <S.DiscountBadge
              isFamyLocate={cohortToUse === Cohort.FAMYLOCATE_V1}
            >
              <Trans i18nKey="subscriptions.upsell2.discount" />
            </S.DiscountBadge>
          )}
          <S.Price isOceaniaCurrency={isOceaniaCurrency}>
            {t('subscriptions.planBlock.price', {
              price: fullPrice,
              currencySymbol: CURRENCY_SYMBOLS[currency],
              minimumFractionDigits: 2,
            })}
          </S.Price>
          {!!discountPercent && (
            <S.OldPrice isOceaniaCurrency={isOceaniaCurrency}>
              {t('subscriptions.planBlock.price', {
                price: oldPrice,
                currencySymbol: CURRENCY_SYMBOLS[currency],
                minimumFractionDigits: 2,
              })}
            </S.OldPrice>
          )}
        </S.PriceWrapper>
      </S.TitleWrapper>
      {!!DESCRIPTION_DATA[product] && (
        <S.DescriptionWrapper>
          <img src={DESCRIPTION_DATA[product].img} alt="description" />
          <S.DescriptionText>
            {t(DESCRIPTION_DATA[product].text)}
          </S.DescriptionText>
        </S.DescriptionWrapper>
      )}
    </S.Wrapper>
  )
}
