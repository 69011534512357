import { CustomerData } from '@livechat/widget-react'
import { AnyAction } from 'redux'

import { IDeliveryInfo, IUserStatus } from 'models/user.model'

import {
  SET_COOKIE_CONSENT,
  SET_DELIVERY_INFO,
  SET_LIVECHAT_PROPS,
  SET_SHORTENED_ONE_LINK,
  SET_STATUS,
  SET_UTM_TAGS,
  SET_UUID,
  UPDATE_USER_CONFIG,
} from '../actions/user'

export interface IUserState {
  uuid: string
  shortenedOneLink: string
  status: IUserStatus | null
  liveChatProps?: CustomerData
  utmTags: Record<string, string> | null
  deliveryInfo: IDeliveryInfo | null
  cookieConsent: string[] | null
}

const initState: IUserState = {
  uuid: '',
  shortenedOneLink: '',
  status: null,
  utmTags: null,
  deliveryInfo: null,
  cookieConsent: null,
}

export function userReducer(
  state: IUserState = initState,
  { type, payload = null }: AnyAction,
): IUserState {
  switch (type) {
    case SET_UUID: {
      return {
        ...state,
        uuid: payload,
      }
    }
    case SET_STATUS: {
      return {
        ...state,
        status: payload,
      }
    }
    case SET_SHORTENED_ONE_LINK: {
      return {
        ...state,
        shortenedOneLink: payload,
      }
    }
    case SET_LIVECHAT_PROPS: {
      return {
        ...state,
        liveChatProps: payload,
      }
    }
    case SET_UTM_TAGS: {
      return {
        ...state,
        utmTags: payload,
      }
    }
    case SET_DELIVERY_INFO: {
      return {
        ...state,
        deliveryInfo: payload,
      }
    }
    case SET_COOKIE_CONSENT: {
      return {
        ...state,
        cookieConsent: payload,
      }
    }
    case UPDATE_USER_CONFIG: {
      return {
        ...state,
        ...(state.status && {
          status: {
            ...state.status,
            config: {
              ...state.status.config,
              ...payload,
            },
          },
        }),
      }
    }
    default:
      return state
  }
}
