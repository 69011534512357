import { ApiService } from 'services/api.service'

import { AmazonShortenerApi } from './AmazonShortenerApi'
import { ConfigApi } from './ConfigApi'
import { EventsApi } from './EventsApi'
import { PaymentApi } from './PaymentApi'
import { SubscriptionsApi } from './SubscriptionsApi'
import { UserApi } from './UserApi'
import { VariantsApi } from './VariantsApi'

const API_PREFIX = '/landing'

export const baseApiService = new ApiService(API_PREFIX)
export const hooksApiService = new ApiService('/hooks')
export const amazonShortenerApiService = new ApiService('/shorten')

export const paymentApi = new PaymentApi(baseApiService)
export const userApi = new UserApi(baseApiService)
export const eventsApi = new EventsApi(hooksApiService)
export const variantsApi = new VariantsApi(baseApiService)
export const configApi = new ConfigApi(baseApiService)
export const subscriptionsApi = new SubscriptionsApi(baseApiService)
export const amazonShortenerApi = new AmazonShortenerApi(
  amazonShortenerApiService,
)
