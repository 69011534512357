import styled from 'styled-components'

import { MediaBreakpoint } from 'root-constants/common'

export const StyledTotalAmount = {
  Container: styled.div`
    display: flex;
    margin-bottom: 16px;
    font-size: 13px;
    line-height: 20px;
    color: ${({ theme }) => theme.colors.secondaryText};

    @media (min-width: ${MediaBreakpoint.LAPTOP}px) {
      font-weight: 600;
      font-size: 18px;
      line-height: 25px;
      margin-bottom: 28px;
    }
  `,
  TitleContainer: styled.span`
    margin-right: 3px;
  `,
  SubscriptionDescription: styled.span``,
  PriceContainer: styled.span`
    font-weight: ${({ theme }) => theme.common.priceFontWeight};
    color: ${({ theme }) => theme.colors.priceColor};
  `,
}
