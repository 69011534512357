import React from 'react'
import { Trans, useTranslation } from 'react-i18next'

import { useNextStep } from 'hooks/common/useNextStep'

import { TPageProps } from 'models/common.model'

import { Button } from 'components/Button'

import img from 'assets/images/social-proof-24.webp'

import { StyledSocialProofVariant24 as S } from './SocialProofVariant24.styles'

export const SocialProofVariant24: React.FC<TPageProps> = ({
  pageId,
  nextPagePath,
}) => {
  const { t } = useTranslation()

  const handleContinue = useNextStep({
    pageId,
    pageName: pageId,
    question: '',
    nextPagePath,
  })

  return (
    <S.Wrapper>
      <S.ImageContainer>
        <img src={img} alt="social_proof" />
      </S.ImageContainer>
      <S.Column>
        <S.Title>{t`onboarding.socialProof.variant1.title`}</S.Title>
        <S.Text>
          <Trans i18nKey="onboarding.socialProof.variant24.monogamousRelationship" />
        </S.Text>
      </S.Column>
      <S.FixedContainer>
        <S.Note>{t`onboarding.socialProof.variant24.note`}</S.Note>
        <Button type="button" onClick={() => handleContinue('')}>
          {t`actions.continue`}
        </Button>
      </S.FixedContainer>
    </S.Wrapper>
  )
}
