import styled from 'styled-components'

import { SelectPlanBlockVariant1 } from 'modules/subscriptions/components/common/SelectPlanBlockVariant1'

import { Button } from 'components/Button'

import { baseColumnStyles, basePageStyles } from 'common-styles'
import { Color, HEADER_HEIGHT } from 'root-constants/common'

type TTitleButtonProps = {
  isFamyLocate: boolean
}
export const StyledUpsellVariant2 = {
  Wrapper: styled.div`
    ${basePageStyles};
    padding-top: ${HEADER_HEIGHT}px;
  `,
  Column: styled.div`
    ${baseColumnStyles};
    padding: 16px 0 32px;
  `,
  Title: styled.h1<TTitleButtonProps>`
    font-size: ${({ isFamyLocate }) => (isFamyLocate ? 17 : 18)}px;
    font-weight: ${({ isFamyLocate }) => (isFamyLocate ? 700 : 800)};
    line-height: ${({ isFamyLocate }) => (isFamyLocate ? 24 : 25)}px;
    text-align: center;
    margin-bottom: 16px;
  `,
  SelectPlanBlock: styled(SelectPlanBlockVariant1)`
    margin-bottom: 16px;
  `,
  Button: styled(Button)<TTitleButtonProps>`
    line-height: 22px;
    font-size: ${({ isFamyLocate }) => (isFamyLocate ? 17 : 20)}px;
  `,
  SkipButton: styled(Button)<TTitleButtonProps>`
    color: ${Color.LIGHT_TEXT};
    text-align: center;
    font-size: 16px;
    font-weight: ${({ isFamyLocate }) => (isFamyLocate ? 400 : 500)};
    line-height: 22px;
    text-decoration: underline;
    margin-top: 16px;
    background-color: transparent;
    cursor: pointer;
    max-height: 22px;
  `,
  Disclaimer: styled.div`
    font-size: 11px;
    font-weight: 400;
    line-height: 18px;
    color: ${Color.LIGHT_TEXT};
    margin-top: 16px;

    a {
      font-size: 11px;
      font-weight: 400;
      line-height: 18px;
      color: ${Color.LIGHT_TEXT};
      text-decoration: underline;
    }
  `,
}
