import { IRequestOptions, IResponseResult } from 'models/api.model'
import { IShortenerResponse } from 'models/common.model'

import { ApiService } from 'services/api.service'

import { AWS_SHORTENER_API_KEY, RequestMethod } from 'root-constants/common'

export class AmazonShortenerApi {
  private api: ApiService

  constructor(api: ApiService) {
    this.api = api
  }

  getShortenedLink(url: string): Promise<IResponseResult<IShortenerResponse>> {
    const options: IRequestOptions = {
      method: RequestMethod.POST,
      headers: new Headers({
        'Content-Type': 'application/json',
        'x-api-key': AWS_SHORTENER_API_KEY,
      }),
      body: {
        longUrl: url,
        expireHours: 1,
      },
    }

    return this.api.makeRequest<IShortenerResponse>('', options)
  }
}
