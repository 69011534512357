import { useSelector } from 'react-redux'

import { selectCookieConsentCountries } from 'root-redux/selects/common'
import {
  selectCookieConsent,
  selectUserCountryCode,
} from 'root-redux/selects/user'

import { CookieConsentOption } from 'root-constants/common'

export const useCookieConsentAnswer = () => {
  const userCountryCode = useSelector(selectUserCountryCode)
  const cookieConsent = useSelector(selectCookieConsent)
  const cookieConsentCountries = useSelector(selectCookieConsentCountries)

  const isEUUser = cookieConsentCountries.includes(
    userCountryCode.toUpperCase(),
  )

  const {
    hasAdvertisingAndTargetingCookie,
    hasFunctionalCookie,
    hasAnalyticsAndPerformanceCookie,
  } = {
    hasAdvertisingAndTargetingCookie: (cookieConsent || []).includes(
      CookieConsentOption.ADVERTISING_AND_TARGETING,
    ),
    hasFunctionalCookie: (cookieConsent || []).includes(
      CookieConsentOption.FUNCTIONAL_COOKIES,
    ),
    hasAnalyticsAndPerformanceCookie: (cookieConsent || []).includes(
      CookieConsentOption.ANALYTICS_AND_PERFORMANCE,
    ),
  }

  const isPersonalDataAllowed = (isEUUser && hasFunctionalCookie) || !isEUUser

  return {
    isEUUser,
    hasAdvertisingAndTargetingCookie,
    hasFunctionalCookie,
    hasAnalyticsAndPerformanceCookie,
    cookieConsent,
    isPersonalDataAllowed,
  }
}
