import React, { useMemo } from 'react'
import { Trans, useTranslation } from 'react-i18next'

import { usePurchaseStore } from 'hooks/usePurchaseStore'
import { useVatInfo } from 'hooks/useVatInfo'

import { getPriceWithoutTaxes } from 'helpers/getPriceWithoutTaxes'

import { ISelectPlanItemProps } from 'models/common.model'

import { PriceValue } from 'components/PriceValue'

import {
  Currency,
  INTRO_OFFER_PERIODS,
  INTRO_OFFER_PERIOD_NAMES,
  Language,
  ProductKey,
} from 'root-constants/common'

import { StyledTrialPlanItemVariant1 as S } from './TrialPlanItemVariant1.styles'

export const TrialPlanItemVariant1: React.FC<ISelectPlanItemProps> = ({
  subscription: {
    isDefault,
    product,
    mainPrices: { fullPrice },
    trialPrices: { fullPrice: trialPrice, durationDays, daily, oldPrices },
  },
  isSelected,
  hasLaptopStyles = false,
}) => {
  const { t } = useTranslation()

  const { language, currency, taxAmount } = usePurchaseStore()
  const hasIncludedVat = useVatInfo()

  const oldPrice = useMemo(() => {
    const actualOldPrice =
      product === ProductKey.ONE_WEEK
        ? oldPrices.beforeCustomDiscount.fullPrice
        : fullPrice

    const actualOldPriceWithoutTaxes = getPriceWithoutTaxes({
      price: actualOldPrice,
      taxAmount,
    })

    return hasIncludedVat
      ? actualOldPriceWithoutTaxes
      : actualOldPrice.toFixed(2)
  }, [
    product,
    oldPrices.beforeCustomDiscount.fullPrice,
    fullPrice,
    taxAmount,
    hasIncludedVat,
  ])

  const priceWithoutTaxes = getPriceWithoutTaxes({
    price: trialPrice,
    taxAmount,
  })

  const dailyPriceWithoutTaxes = getPriceWithoutTaxes({
    price: daily,
    taxAmount,
  })

  const isMexicanPeso = currency === Currency.MXN

  return (
    <S.PlanItem
      data-has-laptop-styles={hasLaptopStyles}
      data-is-selected={isSelected}
      data-is-default={isDefault}
      data-text={t`subscriptions.planBlock.mostPopular`}
      data-has-smaller-padding={language === Language.ES || isMexicanPeso}
    >
      <S.PlanTitleWrapper>
        <S.PlanTitle data-is-es-lang={language === Language.ES}>
          <Trans
            i18nKey="subscriptions.planBlock.planTitle2"
            values={{
              periodQuantity: INTRO_OFFER_PERIODS[durationDays],
              periodName: t(INTRO_OFFER_PERIOD_NAMES[durationDays], {
                count: INTRO_OFFER_PERIODS[durationDays],
              }),
            }}
          />
        </S.PlanTitle>
        <S.PlanPricesWrapper>
          {!!oldPrices.beforeCustomDiscount.fullPrice && (
            <S.OldPlanPrice isMexicanPeso={isMexicanPeso}>
              <PriceValue value={oldPrice} currency={currency} />
            </S.OldPlanPrice>
          )}
          <S.CurrentPrice isMexicanPeso={isMexicanPeso}>
            <PriceValue
              value={hasIncludedVat ? priceWithoutTaxes : trialPrice}
              currency={currency}
            />
          </S.CurrentPrice>
        </S.PlanPricesWrapper>
      </S.PlanTitleWrapper>
      <S.PlanPrice data-is-selected={isSelected} isMexicanPeso={isMexicanPeso}>
        <strong>
          <PriceValue
            value={hasIncludedVat ? dailyPriceWithoutTaxes : daily}
            currency={currency}
          />
        </strong>
        <sub data-is-selected={isSelected}>
          {t('subscriptions.planBlock.perPeriod', {
            interval: t('subscriptions.planBlock.perDay'),
          })}
        </sub>
      </S.PlanPrice>
    </S.PlanItem>
  )
}
