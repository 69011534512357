import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { resetErrorAction } from 'root-redux/actions/common'
import { bindUserAction } from 'root-redux/actions/user'
import { selectError, selectIsFetching } from 'root-redux/selects/common'

import { useAuthObserver } from 'hooks/useAuthObserver'
import { useGetRedirectResult } from 'hooks/useGetRedirectResult'
import { useInitFirebase } from 'hooks/useInitFirebase'

import { eventLogger } from 'services/eventLogger.service'

import { DesktopProvider } from 'components/DesktopProvider'
import { Modal } from 'components/Modal'
import { Spinner } from 'components/Spinner'

import laptopImage from 'assets/images/post-payment-page-img.jpg'

import { StyledAccount as S } from './Account.styles'
import { EmailAccount } from './components/EmailAccount'

export const Account: React.FC = () => {
  const dispatch = useDispatch()
  const error = useSelector(selectError)
  const isFetching = useSelector(selectIsFetching)

  const [isModalShown, setIsModalShown] = useState(false)
  const [isFirebaseDataLoading, setIsFirebaseDataLoading] = useState(false)

  useEffect(() => {
    eventLogger.logCreateAccountShown()
  }, [])

  useEffect(() => {
    error && setIsModalShown(true)
  }, [error])

  const authStateChangeHandler = useCallback(
    (token: string) => {
      dispatch(bindUserAction(token))
    },
    [dispatch],
  )

  useInitFirebase()
  useGetRedirectResult(authStateChangeHandler, setIsFirebaseDataLoading)
  useAuthObserver(authStateChangeHandler)

  return (
    <DesktopProvider imageSrc={laptopImage}>
      <S.Wrapper>
        {(isFetching || isFirebaseDataLoading) && <Spinner />}
        <EmailAccount />

        <Modal
          onClose={() => {
            setIsModalShown(false)
            dispatch(resetErrorAction())
          }}
          isShown={isModalShown}
        >
          {error}
        </Modal>
      </S.Wrapper>
    </DesktopProvider>
  )
}
