import { useEffect } from 'react'
import { useDispatch } from 'react-redux'

import { getAppConfigAction } from 'root-redux/actions/common'

export const useInitAppConfig = (): void => {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(getAppConfigAction())
  }, [dispatch])
}
