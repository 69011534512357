import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { getIsFamylocateDomain } from 'helpers/getIsFamylocateDomain'

import { PrivacyPolicyLink } from 'components/PrivacyPolicyLink'
import { SupportLink } from 'components/SupportLink'
import { TermsOfUseLink } from 'components/TermsOfUseLink'

import menuIcon from 'assets/images/burger-menu.svg'

import { StyledBurgerMenu as S } from './BurgerMenu.styles'

type TProps = {
  menuIconSrc?: string
  className?: string
}

export const BurgerMenu: React.FC<TProps> = ({
  menuIconSrc = menuIcon,
  className,
}) => {
  const { t } = useTranslation()
  const [isMenuVisible, setIsMenuVisible] = useState<boolean>(false)

  const isFamylocateDomain = getIsFamylocateDomain()

  const toggleMenuVisibility = () => {
    setIsMenuVisible(!isMenuVisible)
  }

  return (
    <S.Wrapper className={className}>
      <S.Burger
        type="button"
        onClick={toggleMenuVisibility}
        menuIconSrc={menuIconSrc}
      />
      <S.Backdrop
        isMenuVisible={isMenuVisible}
        onClick={toggleMenuVisibility}
      />
      <S.Menu isMenuVisible={isMenuVisible}>
        <S.MenuCloseButton type="button" onClick={toggleMenuVisibility} />
        <TermsOfUseLink />
        <PrivacyPolicyLink />
        {!isFamylocateDomain && (
          <SupportLink>{t`actions.needHelp`}</SupportLink>
        )}
      </S.Menu>
    </S.Wrapper>
  )
}
