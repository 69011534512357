import React from 'react'

import { TPageProps } from 'models/common.model'

import { RadioOptionPage } from 'pages/options-page/RadioOptionPage'

import { FREQUENCY_OPTION_DATA } from 'root-constants/common'
import { PageId } from 'root-constants/pages'

import { PAGE_NAMES, TITLE_PATHS } from './constants'

export const QuickWayVariant4: React.FC<TPageProps> = (props) => {
  return (
    <RadioOptionPage
      {...props}
      pageName={PAGE_NAMES[PageId.QUICK_WAY_4]}
      options={FREQUENCY_OPTION_DATA}
      titlePath={TITLE_PATHS[PageId.QUICK_WAY_4]}
    />
  )
}
