import React, { useEffect } from 'react'

import { useFeatureIsOn } from '@growthbook/growthbook-react'

import { useCookieConsentAnswer } from 'hooks/common/useCookieConsentAnswer'
import { useGetPrices } from 'hooks/common/useGetPrices'
import { usePaywall } from 'hooks/common/usePaywall'
import { useProductId } from 'hooks/common/useProductId'
import { usePurchaseStore } from 'hooks/common/usePurchaseStore'

import { TPageProps } from 'models/common.model'

import { eventLogger } from 'services/eventLogger.service'
import { googleAnalyticsLogger } from 'services/googleAnalytics.service'

import { PaymentFlowsGoogleVariant4 } from 'modules/payment/components/PaymentFlowsGoogle'

import { Spinner } from 'components/Spinner'

import {
  GROWTH_BOOK_FEATURE_KEYS,
  Language,
  SubscriptionTag,
} from 'root-constants/common'

import { StyledPaymentVariant6 as S } from './PaymentVariant6.styles'

export const PaymentVariant6: React.FC<TPageProps> = () => {
  const {
    email,
    uuid,
    hasSubscription,
    subscriptions,
    selectedSubscription,
    isPaymentFlowsShown,
    stripeAccountId,
    stripeAccountName,
    screenName,
    threeDSecureIframeUrl,
    language,
  } = usePurchaseStore()

  const productId = useProductId()
  const { hasPrices } = usePaywall('')
  const { isPersonalDataAllowed } = useCookieConsentAnswer()
  const hasAlternativePrice = useFeatureIsOn(
    GROWTH_BOOK_FEATURE_KEYS.car_843_newprice,
  )

  const isFontOversized = language === Language.PT

  useGetPrices({
    tags: hasAlternativePrice
      ? SubscriptionTag.NO_TAX_NEW_PRICE
      : SubscriptionTag.NO_TAX,
  })

  useEffect(() => {
    if (!selectedSubscription) return

    window.fbq('track', 'InitiateCheckout', {}, { eventID: uuid })
    googleAnalyticsLogger.logCheckoutStarted(subscriptions)
  }, [selectedSubscription, uuid, subscriptions])

  useEffect(() => {
    if (hasSubscription || !selectedSubscription) return

    eventLogger.logPurchaseShown({
      screenName,
      productId,
      stripeAccountName,
      stripeAccountId,
    })
    window.ttq.identify({ email: isPersonalDataAllowed ? email : '' })
  }, [
    email,
    hasSubscription,
    isPersonalDataAllowed,
    productId,
    screenName,
    selectedSubscription,
    stripeAccountId,
    stripeAccountName,
  ])

  return (
    <S.Wrapper>
      {threeDSecureIframeUrl ? (
        <S.ThreeDSecureIframe title="3DSecure" src={threeDSecureIframeUrl} />
      ) : (
        <>
          <S.StripePaymentProcessing isFontOversized={isFontOversized} />
          <S.Content>
            <PaymentFlowsGoogleVariant4 />
          </S.Content>
        </>
      )}
      {(!isPaymentFlowsShown || !hasPrices) && <Spinner />}
    </S.Wrapper>
  )
}
