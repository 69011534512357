import styled from 'styled-components'

import { Button } from 'components/Button'

import { StyledPhoneInput } from 'common-styles'

export const StyledPhoneForm = {
  PhoneForm: styled.form`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 16px;
    position: relative;
  `,
  PhoneText: styled.h3`
    text-align: center;
    font-size: 20px;
    font-weight: 600;
    line-height: 27px;
    width: 296px;
  `,
  PhoneNumberInput: styled(StyledPhoneInput)`
    border: 1px solid #cbc3e7;
  `,
  PhoneErrorText: styled.p`
    color: ${({ theme }) => theme.colors.errorTextColor};
    text-align: center;
    font-size: 18px;
    font-weight: 600;
    line-height: 20px;
  `,
  Button: styled(Button)`
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 12px;
    margin-top: 20px;
  `,
}
