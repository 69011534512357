import React from 'react'
import { Trans, useTranslation } from 'react-i18next'

import { ICancelOfferPlanItemProps } from 'modules/subscriptions/types'

import { PriceValue } from 'components/PriceValue'

import {
  CURRENCY_SYMBOLS,
  PERIOD_NAME_TO_MARKUP_MAP,
} from 'root-constants/common'

import { StyledPlanItemCancelOffer as S } from './PlanItemCancelOffer.styles'

export const PlanItemCancelOffer: React.FC<ICancelOfferPlanItemProps> = ({
  currentPrice,
  periodQuantity,
  periodName,
  oldPrice,
  currency,
  isSoldOut = false,
}) => {
  const { t } = useTranslation()

  return (
    <S.PlanItem
      data-is-sold-out={isSoldOut}
      data-text={
        isSoldOut
          ? t`subscriptions.planBlock.soldOut`
          : t`subscriptions.planBlock.mostPopular`
      }
    >
      <S.PlanTitle>
        <Trans
          i18nKey="subscriptions.planBlock.planTitle"
          values={{
            periodQuantity,
            periodName: t(PERIOD_NAME_TO_MARKUP_MAP[periodName], {
              count: periodQuantity,
            }),
          }}
        />
      </S.PlanTitle>

      <S.Container>
        <S.OldPrice data-currency={CURRENCY_SYMBOLS[currency]}>
          <PriceValue value={oldPrice} currency={currency} />
        </S.OldPrice>
        <S.CurrentPrice
          data-is-sold-out={isSoldOut}
          data-currency={CURRENCY_SYMBOLS[currency]}
        >
          <PriceValue value={currentPrice} currency={currency} />
        </S.CurrentPrice>
      </S.Container>
    </S.PlanItem>
  )
}
