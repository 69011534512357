import React, { useEffect, useMemo, useRef } from 'react'
import { Trans, useTranslation } from 'react-i18next'

import { FeaturesReady } from '@growthbook/growthbook-react'

import { useDynamicPaywallConfig } from 'hooks/useDynamicPaywallConfig'
import { useGetPrices } from 'hooks/useGetPrices'
import { usePaywall } from 'hooks/usePaywall'
import { usePurchaseStore } from 'hooks/usePurchaseStore'
import { useUserData } from 'hooks/useUserData'

import { createProductId } from 'helpers/createProductId'

import { TPageProps } from 'models/common.model'

import { ScreenName, eventLogger } from 'services/eventLogger.service'

import { BenefitsVariant2 } from 'modules/subscriptions/components/benefits'
import { SubheaderWithTimer } from 'modules/subscriptions/components/common/SubheaderWithTimer'
import { Timer } from 'modules/subscriptions/components/common/Timer'
import {
  FEW_GOALS_IMG_ASPECT_RATIO,
  GOAL_TO_MARKUP_MAP,
  PLAN_ITEMS,
} from 'modules/subscriptions/constants'

import { Guarantee } from 'components/Guarantee'
import { ReviewsSlider } from 'components/ReviewsSlider'
import { Security } from 'components/Security'
import { Spinner } from 'components/Spinner'

import {
  CDN_FOLDER_LINK,
  Images,
  SubscriptionTag,
  UserGoal,
} from 'root-constants/common'

import { Benefits } from '../../components/benefits'
import { StyledSubscriptionsVariant8 as S } from './SubscriptionsVariant8.styles'

export const SubscriptionsVariant8: React.FC<TPageProps> = ({
  nextPagePath,
}) => {
  const { t } = useTranslation()
  const scrollToElement = useRef<HTMLDivElement>(null)
  const timerTriggerElementRef = useRef(null)

  const { subscriptions, language } = usePurchaseStore()
  const { hasPrices, handleSelectSubscription, handleShowPayment } =
    usePaywall(nextPagePath)
  const { singleUserGoal, isTeenPath } = useUserData()
  const { subscriptionBlockType, subscriptionBlockTitle } =
    useDynamicPaywallConfig()

  useGetPrices({ tags: SubscriptionTag.NO_TAX })

  const { goalImageLink, aspectRatio } = useMemo(() => {
    if (isTeenPath) {
      return {
        goalImageLink: `${CDN_FOLDER_LINK}${
          GOAL_TO_MARKUP_MAP[UserGoal.TEEN].img
        }.webp`,
        aspectRatio: GOAL_TO_MARKUP_MAP[UserGoal.TEEN].aspectRatio,
      }
    }
    if (singleUserGoal) {
      return {
        goalImageLink: `${CDN_FOLDER_LINK}${GOAL_TO_MARKUP_MAP[singleUserGoal].img}_${language}.webp`,
        aspectRatio: GOAL_TO_MARKUP_MAP[singleUserGoal].aspectRatio,
      }
    }
    return {
      goalImageLink: `${CDN_FOLDER_LINK}${Images.FEW_GOALS_IMG}_${language}.webp`,
      aspectRatio: FEW_GOALS_IMG_ASPECT_RATIO,
    }
  }, [language, singleUserGoal, isTeenPath])

  useEffect(() => {
    if (!subscriptions.length) return

    eventLogger.logSalePageShown({
      productIds: subscriptions.map(
        ({ mainPrices: { periodName, periodQuantity, fullPrice } }) =>
          createProductId({
            periodName,
            periodQuantity,
            price: fullPrice,
          }),
      ),
      screenName: ScreenName.ONBOARDING,
      onboardingId: singleUserGoal || 'multiple_choise',
    })
  }, [singleUserGoal, subscriptions])

  const handleTimerButtonClick = () => {
    scrollToElement.current?.scrollIntoView({
      block: 'center',
      behavior: 'smooth',
    })
  }

  return !hasPrices ? (
    <Spinner />
  ) : (
    <FeaturesReady timeout={0} fallback={<div />}>
      <SubheaderWithTimer
        elemForComparisonRef={timerTriggerElementRef}
        onClick={handleTimerButtonClick}
      />
      <S.Wrapper>
        <S.Column marginBottom={40}>
          <S.Title>
            {singleUserGoal
              ? t(GOAL_TO_MARKUP_MAP[singleUserGoal].title)
              : t`subscriptions.variant2.titleMulti`}
          </S.Title>
          <S.Text>
            <Trans
              i18nKey={
                singleUserGoal
                  ? GOAL_TO_MARKUP_MAP[singleUserGoal].subtitle
                  : 'subscriptions.variant2.subtitleMulti'
              }
            />
          </S.Text>
          <Timer />
        </S.Column>
        <S.ImageWrapper aspectRatio={aspectRatio}>
          <img
            ref={timerTriggerElementRef}
            src={goalImageLink}
            alt="map with locations"
          />
        </S.ImageWrapper>
        <S.Column data-has-bg>
          {isTeenPath ? <BenefitsVariant2 /> : <Benefits />}
          {!isTeenPath && (
            <S.Sharing>
              <Trans i18nKey="subscriptions.variant2.shareBlock" />
            </S.Sharing>
          )}
          <S.Subtitle>
            {subscriptionBlockTitle ||
              t`subscriptions.variant2.titlePlanBlock2`}
          </S.Subtitle>
          <S.SelectPlanBlock
            ref={scrollToElement}
            onSelect={handleSelectSubscription}
            SelectPlanItem={PLAN_ITEMS[subscriptionBlockType]}
          />
          <S.Button
            onClick={handleShowPayment}
            marginBottom={12}
            laptopMarginBottom={20}
          >
            {t`actions.start`}
          </S.Button>
          <S.SubscriptionDescription />
        </S.Column>

        <S.Column>
          <Guarantee />
          <ReviewsSlider />
          <S.Button
            onClick={handleShowPayment}
            marginBottom={24}
            laptopMarginBottom={54}
          >
            {t`actions.start`}
          </S.Button>
          <Security />
        </S.Column>
      </S.Wrapper>
    </FeaturesReady>
  )
}
