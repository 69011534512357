import React, { useMemo, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useLocation } from 'react-router'

import { useNextStep } from 'hooks/useNextStep'

import { TPageProps } from 'models/common.model'

import {
  INTRO_4_OPTION_DATA,
  PAGE_NAME,
  QUESTIONS,
} from 'pages/intro/constants'

import { BurgerMenu } from 'components/BurgerMenu'
import { OptionType } from 'components/Option'
import { PoliciesGroup } from 'components/PoliciesGroup'

import menuIcon from 'assets/images/burger-menu-black.svg'
import introImage from 'assets/images/intro-4.webp'

import { PageId } from 'root-constants/pages'

import { StyledIntroVariant4 as S } from './IntroVariant4.styles'

export const IntroVariant4: React.FC<TPageProps> = ({ pageId }) => {
  const { search } = useLocation()
  const { t } = useTranslation()
  const [answers, setAnswers] = useState<string[]>([])

  const nextPagePath = useMemo(() => {
    const [priorityOption] = INTRO_4_OPTION_DATA.filter((item) =>
      answers.includes(item.optionValue),
    )
    return priorityOption?.nextPagePath || PageId.PARTNERS_SAFETY_1
  }, [answers])

  const handleContinue = useNextStep({
    pageId,
    pageName: PAGE_NAME,
    question: QUESTIONS[pageId],
    nextPagePath: `${nextPagePath}${search}`,
  })

  const optionProps = {
    name: pageId,
    type: OptionType.CHECKBOX,
    onChange: (value: string, isChecked: boolean) => {
      if (isChecked) {
        setAnswers([...answers, value])
      } else {
        setAnswers(answers.filter((item) => item !== value))
      }
    },
  }

  return (
    <S.Wrapper>
      <BurgerMenu menuIconSrc={menuIcon} />
      <S.ImageContainer>
        <img src={introImage} alt="family" />
      </S.ImageContainer>
      <S.Column>
        <S.Title>
          <Trans i18nKey="onboarding.intro4.title" />
        </S.Title>
        <S.Subtitle>{t`onboarding.intro4.subtitle`}</S.Subtitle>
        <S.Disclaimer>{t`onboarding.intro.note`}</S.Disclaimer>

        <S.OptionContainer>
          {INTRO_4_OPTION_DATA.map(({ optionValue, answerText }) => (
            <S.Option key={optionValue} {...optionProps} value={optionValue}>
              <S.QuestionButton>{t(answerText)}</S.QuestionButton>
            </S.Option>
          ))}
        </S.OptionContainer>

        <S.Button
          onClick={() => handleContinue(answers)}
          disabled={!answers.length}
        >
          {t`actions.continue`}
        </S.Button>
        <PoliciesGroup />
      </S.Column>
    </S.Wrapper>
  )
}
