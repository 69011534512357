import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import {
  selectDynamicDiscount,
  selectLanguage,
} from 'root-redux/selects/common'

import { getPriceBeforeDiscount } from 'helpers/getPriceBeforeDiscount'

import { ISubscription } from 'models/subscriptions.model'

import { selectCurrency } from 'modules/payment/redux/selects'

import {
  CURRENCY_SYMBOLS,
  Currency,
  Language,
  ProductKey,
} from 'root-constants/common'

import { StyledPlanItemVariant2 as S } from './PlanItemVariant2.styles'
import { INTRO_OFFER_PERIODS } from './constants'

type TProps = {
  subscription: ISubscription
  isSelected: boolean
}

export const PlanItemVariant3: React.FC<TProps> = ({
  subscription: {
    isDefault,
    product,
    trialPrices: { fullPrice: trialPrice, durationDays, daily },
  },
  isSelected,
}) => {
  const { t } = useTranslation()
  const currency = useSelector(selectCurrency)
  const language = useSelector(selectLanguage)
  const dynamicDiscount = useSelector(selectDynamicDiscount)

  const [integerCustomDailyPrice, fractionalCustomDailyPrice = '00'] = useMemo(
    () => daily.toFixed(2).split('.'),
    [daily],
  )

  const oldPrice = useMemo(
    () =>
      Number(
        getPriceBeforeDiscount(Number(dynamicDiscount?.amount), trialPrice),
      ),
    [dynamicDiscount?.amount, trialPrice],
  )

  const oldCustomPrice = useMemo(
    () =>
      Number(getPriceBeforeDiscount(Number(dynamicDiscount?.amount), daily)),
    [dynamicDiscount?.amount, daily],
  )

  const isSmallerFontSize =
    currency === Currency.CAD ||
    currency === Currency.AUD ||
    currency === Currency.NZD

  return (
    <S.Wrapper
      isDefault={isDefault}
      isSelected={isSelected}
      labelText={t`subscriptions.planBlock.mostPopular`}
    >
      <S.Checkbox isSelected={isSelected} />
      <S.MainPricesContainer>
        <S.Period>
          {t('payment.variant5.planPeriodName', {
            periodQuantity: INTRO_OFFER_PERIODS[durationDays],
            periodName:
              product === ProductKey.ONE_WEEK
                ? t('payment.variant5.planPeriods.week')
                : t('payment.variant5.planPeriods.month', {
                    count: INTRO_OFFER_PERIODS[durationDays],
                  }),
          })}
        </S.Period>
        <S.OldPrice fontSize={isSmallerFontSize ? 9 : 12}>
          {t('subscriptions.planBlock.price', {
            price: oldPrice,
            currencySymbol: CURRENCY_SYMBOLS[currency],
            minimumFractionDigits: 2,
          })}
        </S.OldPrice>
        <S.CurrentPrice fontSize={isSmallerFontSize ? 9 : 12}>
          {t('subscriptions.planBlock.price', {
            price: trialPrice,
            currencySymbol: CURRENCY_SYMBOLS[currency],
            minimumFractionDigits: 2,
          })}
        </S.CurrentPrice>
        <S.OldCustomPrice fontSize={isSmallerFontSize ? 9 : 12}>
          {t('subscriptions.planBlock.price', {
            price: oldCustomPrice,
            currencySymbol: CURRENCY_SYMBOLS[currency],
            minimumFractionDigits: 2,
          })}
        </S.OldCustomPrice>
      </S.MainPricesContainer>

      <S.CustomPriceContainer isSelected={isSelected}>
        {language === Language.EN && (
          <S.Currency>{CURRENCY_SYMBOLS[currency]}</S.Currency>
        )}
        <S.CustomPriceInteger>{integerCustomDailyPrice}</S.CustomPriceInteger>
        <S.CustomPriceFractional>
          {fractionalCustomDailyPrice}{' '}
          {language === Language.ES && CURRENCY_SYMBOLS[currency]}
        </S.CustomPriceFractional>
        <S.CustomPricePeriod>
          {t('subscriptions.planBlock.perPeriod', {
            interval: t('subscriptions.planBlock.perDay'),
          })}
        </S.CustomPricePeriod>
      </S.CustomPriceContainer>
    </S.Wrapper>
  )
}
