import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { useNextStep } from 'hooks/useNextStep'

import { TPageProps } from 'models/common.model'

import { Button } from 'components/Button'
import { DesktopProvider } from 'components/DesktopProvider'
import { Option, OptionType } from 'components/Option'

import fearImage from 'assets/images/biggest-fear-kid.jpg'

import { CheckboxCustomValue, Language } from 'root-constants/common'

import { StyledFearVariant1 as S } from './FearVariant1.styles'
import { OPTION_VALUES, PAGE_NAME, PAGE_OPTION_VALUES } from './constants'

export const FearVariant1: React.FC<TPageProps> = ({
  pageId,
  nextPagePath,
}) => {
  const { t } = useTranslation()
  const [answers, setAnswers] = useState<string[]>([])

  const handleContinue = useNextStep({
    pageId,
    pageName: PAGE_NAME,
    question: t('onboarding.fear.question', { lng: Language.EN }),
    nextPagePath,
  })

  const optionProps = {
    name: pageId,
    type: OptionType.CHECKBOX,
    onChange: (value: string, isChecked: boolean) => {
      if (isChecked) {
        value === CheckboxCustomValue.ALL_OF_THE_ABOVE
          ? setAnswers(PAGE_OPTION_VALUES[pageId])
          : setAnswers([...answers, value])
        return
      }

      value === CheckboxCustomValue.ALL_OF_THE_ABOVE
        ? setAnswers([])
        : setAnswers(answers.filter((item) => item !== value))
    },
  }

  return (
    <DesktopProvider imageSrc={fearImage}>
      <S.Wrapper>
        <S.Column>
          <S.Title>{t`onboarding.fear.question`}</S.Title>
          <S.OptionsContainer>
            <Option
              {...optionProps}
              value={OPTION_VALUES.HURT}
              checked={answers.includes(OPTION_VALUES.HURT)}
            >
              <S.QuestionButton hasCheckboxIcon>
                {t`onboarding.fear.answers.hurt`}
              </S.QuestionButton>
            </Option>
            <Option
              {...optionProps}
              value={OPTION_VALUES.DANGEROUS_PLACE}
              checked={answers.includes(OPTION_VALUES.DANGEROUS_PLACE)}
            >
              <S.QuestionButton hasCheckboxIcon>
                {t`onboarding.fear.answers.dangerousPlace`}
              </S.QuestionButton>
            </Option>
            <Option
              {...optionProps}
              value={OPTION_VALUES.HIT_BY_CAR}
              checked={answers.includes(OPTION_VALUES.HIT_BY_CAR)}
            >
              <S.QuestionButton hasCheckboxIcon>
                {t`onboarding.fear.answers.hitByCar`}
              </S.QuestionButton>
            </Option>
            <Option
              {...optionProps}
              value={OPTION_VALUES.KIDNAPPED}
              checked={answers.includes(OPTION_VALUES.KIDNAPPED)}
            >
              <S.QuestionButton hasCheckboxIcon>
                {t`onboarding.fear.answers.kidnapped`}
              </S.QuestionButton>
            </Option>

            <Option
              {...optionProps}
              value={OPTION_VALUES.LOST}
              checked={answers.includes(OPTION_VALUES.LOST)}
            >
              <S.QuestionButton hasCheckboxIcon>
                {t`onboarding.fear.answers.lost`}
              </S.QuestionButton>
            </Option>
            <Option
              {...optionProps}
              value={OPTION_VALUES.OTHER}
              checked={answers.includes(OPTION_VALUES.OTHER)}
            >
              <S.QuestionButton hasCheckboxIcon>
                {t`commonComponents.other`}
              </S.QuestionButton>
            </Option>
            <Option
              {...optionProps}
              value={CheckboxCustomValue.ALL_OF_THE_ABOVE}
              checked={answers.length === PAGE_OPTION_VALUES[pageId].length}
            >
              <S.QuestionButton hasCheckboxIcon>
                {t`commonComponents.allOfTheAbove`}
              </S.QuestionButton>
            </Option>
          </S.OptionsContainer>
          <S.FixedContainer>
            <Button
              onClick={() => handleContinue(answers)}
              disabled={!answers.length}
            >
              {t`actions.next`}
            </Button>
          </S.FixedContainer>
        </S.Column>
      </S.Wrapper>
    </DesktopProvider>
  )
}
