import styled from 'styled-components'

import { MediaBreakpoint } from 'root-constants/common'

export const StyledBenefitsVariant4 = {
  Wrapper: styled.div`
    width: 100%;
    max-width: ${MediaBreakpoint.MAX_PHONE}px;
    display: flex;
    flex-direction: column;
    place-content: center;
    margin-bottom: 15px;
    padding: 24px;
    background: linear-gradient(
      179deg,
      rgba(246, 249, 255, 0.8) 26.53%,
      rgba(249, 244, 255, 0.8) 99.42%
    );

    @media (min-width: ${MediaBreakpoint.MAX_PHONE}px) {
      max-width: 375px;
    }
  `,
  Title: styled.h2`
    margin-bottom: 24px;
    text-align: center;
    font-size: 24px;
    font-weight: 800;
    line-height: 28px;
  `,
  BenefitContainer: styled.div`
    display: grid;
    grid-template-columns: 75px 1fr;
    grid-template-rows: auto;
    grid-template-areas: 'image title' 'image text';
    grid-gap: 8px;
    margin-bottom: 32px;

    &:last-of-type {
      margin-bottom: 0;
    }
  `,
  Image: styled.img`
    grid-area: image;
  `,
  BenefitTitle: styled.h3`
    grid-area: title;
    align-self: flex-end;
    font-size: 20px;
    font-weight: 700;
    line-height: 27px;
  `,
  Text: styled.p`
    grid-area: text;
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
  `,
}
