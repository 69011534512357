import { combineReducers } from 'redux'

import { commonReducer } from 'root-redux/reducers/common'
import { userReducer } from 'root-redux/reducers/user'

import { IAppState } from 'models/store.model'

import { accountReducer } from 'modules/account/redux/reducer'
import { paymentReducer } from 'modules/payment/redux/reducer'

export const rootReducer = combineReducers<IAppState>({
  common: commonReducer,
  user: userReducer,
  payment: paymentReducer,
  account: accountReducer,
})
