import React from 'react'
import { Trans, useTranslation } from 'react-i18next'

import leftLeaf from 'assets/images/left-golden-leaf.png'
import rightLeaf from 'assets/images/right-golden-leaf.png'

import { StyledAppStatistics as S } from './AppStatistics.styles'
import { STATISTICS_DATA } from './constants'

export const AppStatistics: React.FC = () => {
  const { t } = useTranslation()

  return (
    <S.Wrapper>
      <S.Tracker>
        <S.TrackerImage src={leftLeaf} alt="icon" gridArea="leftLeaf" />
        <S.TrackerTitle>
          <Trans i18nKey="subscriptions.subscriptionsGoogle.variant4.trackerInfo" />
        </S.TrackerTitle>
        <S.TrackerSubtitle>{t`subscriptions.subscriptionsGoogle.variant4.since`}</S.TrackerSubtitle>
        <S.TrackerImage src={rightLeaf} alt="icon" gridArea="rightLeaf" />
      </S.Tracker>

      <S.StatisticsContainer>
        {STATISTICS_DATA.map(({ image, amount, text }) => (
          <S.StatisticsCard key={amount}>
            <S.StatisticsImage src={image} alt="icon" />
            <S.StatisticsTitle>{t(amount)}</S.StatisticsTitle>
            <S.StatisticsSubtitle>{t(text)}</S.StatisticsSubtitle>
          </S.StatisticsCard>
        ))}
      </S.StatisticsContainer>
    </S.Wrapper>
  )
}
