import styled from 'styled-components'

import { Button } from 'components/Button'
import { SvgImage } from 'components/SvgImage'

import { baseColumnStyles, basePageStyles } from 'common-styles'
import {
  Color,
  FAMY_LOCATE_HEADER_HEIGHT,
  MediaBreakpoint,
} from 'root-constants/common'

export const StyledEmailVariant4 = {
  Wrapper: styled.div`
    ${basePageStyles};
    padding-top: ${FAMY_LOCATE_HEADER_HEIGHT}px;
    padding-bottom: 20px;
  `,
  Content: styled.div`
    ${baseColumnStyles};
    width: 328px;
    padding-top: 24px;
  `,
  Title: styled.h2`
    margin-bottom: 16px;
    text-align: center;
    font-size: 28px;
    font-weight: 800;
    line-height: 38px;
    background: linear-gradient(90deg, #fc8e57 0.61%, #ffd651 59.15%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  `,
  Subtitle: styled.h3`
    margin-bottom: 16px;
    color: ${Color.WHITE};
    text-align: center;
    font-size: 17px;
    font-weight: 400;
    line-height: 24px;
  `,
  Date: styled.p`
    margin-bottom: 32px;
    color: #91bfff;
    text-align: center;
    font-size: 13px;
    font-weight: 600;
    line-height: 16px;
    letter-spacing: 0.5px;
    text-transform: uppercase;
  `,
  Form: styled.form`
    width: 100%;
    margin-bottom: 32px;
    padding: 24px 16px;
    border-radius: 20px;
    background: rgba(255, 255, 255, 0.15);
  `,
  InputWrapper: styled.div`
    width: 100%;
    position: relative;
  `,
  InputTitle: styled.h3`
    margin-bottom: 16px;
    color: ${Color.WHITE};
    text-align: center;
    font-size: 18px;
    font-weight: 700;
    line-height: 24px;

    strong {
      color: #ffd651;
    }
  `,
  AutoCompleteWrapper: styled.div`
    width: 100%;
    max-height: 160px;
    padding: 0 15px;
    position: absolute;
    top: 55px;
    left: 0;
    border-radius: 12px;
    overflow-y: auto;
    background-color: #fff;
    filter: drop-shadow(0px 7px 20px #c8d4d8);
    z-index: 2;
    transform: translateZ(0);
  `,
  AutoCompleteButton: styled.button`
    height: 50px;
    width: 100%;
    padding: 0;
    display: block;
    cursor: pointer;
    border: none;
    outline: none;
    font-size: 16px;
    font-weight: 500;
    line-height: 50px;
    text-decoration: none;
    text-align: left;
    color: ${Color.DEFAULT_TEXT};
    background-color: transparent;
    -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    direction: rtl;

    :not(:last-child) {
      border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    }
  `,
  AutoCompleteButtonTextWrapper: styled.span`
    unicode-bidi: plaintext;
  `,
  Note: styled.div`
    display: flex;
    flex: 0 0 auto;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 8px;
    margin-bottom: 16px;
  `,
  LockImg: styled(SvgImage)`
    width: 100%;
    max-width: 16px;
    margin-top: 3px;
    fill: #dfecff;
  `,
  Description: styled.p`
    color: #dfecff;
    font-size: 13px;
    font-weight: 400;
    line-height: 16px;
  `,
  Button: styled(Button)`
    width: 100%;
    font-size: 17px;
    font-weight: 700;
    line-height: 24px;
  `,
  DisclaimerContainer: styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 12px;
    padding: 9px;
    background-color: rgba(255, 255, 255, 0.1);
    max-width: ${MediaBreakpoint.MAX_PHONE}px;
    margin-bottom: 32px;

    @media (min-width: ${MediaBreakpoint.MAX_PHONE}px) {
      max-width: 328px;
    }
  `,
  Disclaimer: styled.p`
    color: #91bfff;
    font-size: 13px;
    font-weight: 600;
    line-height: 16px;
    letter-spacing: 0.5px;
    text-transform: uppercase;
  `,
}
