import styled from 'styled-components'

import {
  baseAdaptiveTitleStyles,
  baseColumnStyles,
  basePageStyles,
} from 'common-styles'
import { HEADER_HEIGHT, MediaBreakpoint } from 'root-constants/common'

export const StyledRoutinesChanges = {
  Wrapper: styled.div`
    ${basePageStyles};
    padding-top: ${HEADER_HEIGHT}px;
  `,
  Column: styled.div`
    ${baseColumnStyles};
    padding: 40px 0;

    @media (min-width: ${MediaBreakpoint.TABLET}px) {
      width: 460px;
    }
  `,
  Title: styled.h1`
    ${baseAdaptiveTitleStyles};
    margin-bottom: 87px;

    @media (min-width: ${MediaBreakpoint.LAPTOP}px) {
      margin-bottom: 48px;
    }
  `,
}
