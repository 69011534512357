import { useEffect } from 'react'
import { useDispatch } from 'react-redux'

import { useGetPageInfo } from 'hooks/useGetPageInfo'

import { setPlanAdditionsAction } from 'modules/payment/redux/actions/common'

import { PLAN_ADDITIONS_MAP } from 'root-constants/common'

export const useSetPlanAdditions = (): void => {
  const { currentPageId } = useGetPageInfo()
  const dispatch = useDispatch()

  useEffect(() => {
    const planAdditions = PLAN_ADDITIONS_MAP[currentPageId]

    if (planAdditions) {
      dispatch(setPlanAdditionsAction(planAdditions))
    }
  }, [dispatch, currentPageId])
}
