import React, { lazy, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { Navigate, Outlet, useLocation, useRoutes } from 'react-router-dom'

import {
  selectCurrentVariantCohort,
  selectCurrentVariantSteps,
  selectLanguage,
} from 'root-redux/selects/common'
import { selectUUID } from 'root-redux/selects/user'

import { useGetPageInfo } from 'hooks/useGetPageInfo'
import { useUserStatus } from 'hooks/useUserStatus'

import { getDynamicPageId } from 'helpers/getDynamicPageId'
import { getPathFromPageId } from 'helpers/getPathFromPageId'

import { IStep } from 'models/variant.model'

import { Account } from 'modules/account/Account'
import { ChipoloCheckout } from 'modules/payment/pages'
import { ChipoloUpgradeVariant1 } from 'modules/subscriptions/pages'

import { DownloadApp } from 'pages/download-app'
import { ProxyToStart } from 'pages/proxy-to-start'

import { LiveChat } from 'components/LiveChat'
import { ProtectedRoute } from 'components/ProtectedRoute'
import { SubscribeFlow } from 'components/SubscribeFlow'

import { DYNAMIC_PAGE_ID_PREFIX, PUBLIC_PAGES } from 'root-constants/common'
import { ID_TO_PAGE_MAP, PageId } from 'root-constants/pages'

const PaymentProvider = lazy(() => import('components/PaymentProvider'))

export const RouteList: React.FC = () => {
  const { search, hash } = useLocation()
  const steps = useSelector(selectCurrentVariantSteps)
  const cohort = useSelector(selectCurrentVariantCohort)
  const uuid = useSelector(selectUUID)
  const language = useSelector(selectLanguage)

  const availableRoute = useUserStatus()

  const { currentSubscriptionPageId, currentUpsellPageId } = useGetPageInfo()

  const firstPagePath = useMemo(
    () =>
      getPathFromPageId({
        pageId: steps[0].id,
        cohort,
        uuid,
        language,
        currentSearch: search,
        hash,
      }),
    [cohort, language, search, steps, uuid, hash],
  )

  const { onboardingPages, subscriptionPages, upsellPages } = useMemo(() => {
    return steps.reduce<{
      onboardingPages: IStep[]
      subscriptionPages: IStep[]
      upsellPages: IStep[]
    }>(
      (accum, { isSubscriptions, isPayment, isUpsell }, ind, arr) => {
        if (isSubscriptions || isPayment) {
          accum.subscriptionPages.push(arr[ind])
          return accum
        }

        if (isUpsell) {
          accum.upsellPages.push(arr[ind])
          return accum
        }

        accum.onboardingPages.push(arr[ind])
        return accum
      },
      {
        onboardingPages: [],
        subscriptionPages: [],
        upsellPages: [],
      },
    )
  }, [steps])

  return useRoutes([
    { index: true, element: <Navigate to={firstPagePath} /> },
    {
      path: PageId.PROXY_TO_START,
      element: (
        <ProtectedRoute isAvailable={availableRoute === PageId.PROXY_TO_START}>
          <ProxyToStart />
        </ProtectedRoute>
      ),
    },
    {
      element: <SubscribeFlow />,
      children: onboardingPages.map(({ id: pageId }, index, arr) => {
        const currentPageId = pageId.includes(DYNAMIC_PAGE_ID_PREFIX)
          ? getDynamicPageId(pageId)
          : pageId
        const CurrentPage = ID_TO_PAGE_MAP[currentPageId]

        const nextPagePath = getPathFromPageId({
          pageId: arr[index + 1]?.id,
          cohort,
          uuid,
          language,
          currentSearch: search,
          hash,
        })

        const alternativePageId = arr.find(
          (dynamicPage, dynamicPageIndex) =>
            !dynamicPage.isSkippable && dynamicPageIndex > index,
        )?.id

        const alternativePagePath = getPathFromPageId({
          pageId: alternativePageId || arr[index + 1]?.id,
          cohort,
          uuid,
          language,
          currentSearch: search,
          hash,
        })

        if (PUBLIC_PAGES.includes(pageId)) {
          return {
            path: pageId,
            element: (
              <CurrentPage
                pageId={pageId}
                nextPagePath={nextPagePath}
                alternativePagePath="/"
              />
            ),
          }
        }

        return {
          path: pageId,
          element: (
            <ProtectedRoute isAvailable={!availableRoute}>
              <CurrentPage
                pageId={pageId}
                nextPagePath={nextPagePath}
                alternativePagePath={alternativePagePath}
              />
            </ProtectedRoute>
          ),
        }
      }),
    },
    {
      element: (
        <PaymentProvider>
          <Outlet />
        </PaymentProvider>
      ),
      children: [
        {
          element: <SubscribeFlow />,
          children: subscriptionPages.map(({ id: pageId }, index, arr) => {
            const PurchasePage = ID_TO_PAGE_MAP[pageId]
            const nextPagePath = getPathFromPageId({
              pageId: arr[index + 1]?.id,
              cohort,
              uuid,
              language,
              currentSearch: search,
              hash,
            })

            if (PUBLIC_PAGES.includes(pageId)) {
              return {
                path: pageId,
                element: (
                  <PurchasePage
                    pageId={pageId}
                    nextPagePath={nextPagePath}
                    alternativePagePath="/"
                  />
                ),
              }
            }

            return {
              path: pageId,
              element: (
                <ProtectedRoute
                  isAvailable={availableRoute === currentSubscriptionPageId}
                >
                  <PurchasePage
                    pageId={pageId}
                    nextPagePath={nextPagePath}
                    alternativePagePath="/"
                  />
                </ProtectedRoute>
              ),
            }
          }),
        },
        {
          element: <SubscribeFlow />,
          children: upsellPages.map(({ id: pageId }, index, arr) => {
            const UpsellPage = ID_TO_PAGE_MAP[pageId]
            const nextPagePath = getPathFromPageId({
              pageId: arr[index + 1]?.id,
              cohort,
              uuid,
              language,
              currentSearch: search,
              hash,
            })

            return {
              path: pageId,
              element: (
                <ProtectedRoute
                  isAvailable={availableRoute === currentUpsellPageId}
                >
                  <UpsellPage
                    pageId={pageId}
                    nextPagePath={nextPagePath}
                    alternativePagePath="/"
                  />
                </ProtectedRoute>
              ),
            }
          }),
        },
        {
          path: PageId.CHIPOLO_UPGRADE_1,
          element: (
            <ProtectedRoute
              isAvailable={
                availableRoute === PageId.CHIPOLO_UPGRADE_1 ||
                availableRoute === currentUpsellPageId
              }
            >
              <ChipoloUpgradeVariant1 />
            </ProtectedRoute>
          ),
        },
        {
          path: PageId.CHIPOLO_CHECKOUT,
          element: (
            <ProtectedRoute
              isAvailable={
                availableRoute === PageId.CHIPOLO_UPGRADE_1 ||
                availableRoute === currentUpsellPageId
              }
            >
              <ChipoloCheckout />
            </ProtectedRoute>
          ),
        },
      ],
    },
    {
      element: (
        <>
          <LiveChat />
          <Outlet />
        </>
      ),
      children: [
        {
          path: PageId.ACCOUNT,
          element: (
            <ProtectedRoute
              isAvailable={
                availableRoute === currentUpsellPageId ||
                availableRoute === PageId.CHIPOLO_UPGRADE_1 ||
                availableRoute === PageId.ACCOUNT
              }
            >
              <Account />
            </ProtectedRoute>
          ),
        },
        {
          path: PageId.DOWNLOAD,
          element: (
            <ProtectedRoute isAvailable={availableRoute === PageId.DOWNLOAD}>
              <DownloadApp />
            </ProtectedRoute>
          ),
        },
      ],
    },
    { path: '*', element: <Navigate to={firstPagePath} /> },
  ])
}
