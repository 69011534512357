import React, { useMemo } from 'react'
import { Trans, useTranslation } from 'react-i18next'

import { usePurchaseStore } from 'hooks/usePurchaseStore'
import { useVatInfo } from 'hooks/useVatInfo'

import { getPriceBeforeDiscount } from 'helpers/getPriceBeforeDiscount'
import { getPriceWithoutTaxes } from 'helpers/getPriceWithoutTaxes'

import { ISelectPlanItemProps } from 'models/common.model'

import { OVERSIZE_CURRENCIES } from 'modules/subscriptions/constants'

import { PriceValue } from 'components/PriceValue'

import arrow from 'assets/images/right-arrow.svg'

import {
  Currency,
  INTRO_OFFER_PERIODS_WEEKS,
  INTRO_OFFER_WEEKLY_PERIOD_NAMES,
  Language,
} from 'root-constants/common'

import { StyledTrialPlanItemVariant4 as S } from './TrialPlanItemVariant4.styles'

export const TrialPlanItemVariant4: React.FC<ISelectPlanItemProps> = ({
  subscription: {
    isDefault,
    trialPrices: { fullPrice: trialPrice, durationDays, daily, oldPrices },
  },
  isSelected,
  hasLaptopStyles = false,
}) => {
  const { t } = useTranslation()

  const { language, currency, dynamicDiscount, taxAmount } = usePurchaseStore()
  const hasIncludedVat = useVatInfo()

  const priceWithoutTaxes = getPriceWithoutTaxes({
    price: trialPrice,
    taxAmount,
  })

  const oldPrice = useMemo(() => {
    const actualOldPrice = Number(
      getPriceBeforeDiscount(Number(dynamicDiscount?.amount), trialPrice),
    )

    const actualOldPriceWithoutTaxes = Number(
      getPriceBeforeDiscount(
        Number(dynamicDiscount?.amount),
        Number(priceWithoutTaxes),
      ),
    )

    return hasIncludedVat
      ? actualOldPriceWithoutTaxes
      : actualOldPrice.toFixed(2)
  }, [hasIncludedVat, dynamicDiscount?.amount, trialPrice, priceWithoutTaxes])

  const dailyPriceWithoutTaxes = getPriceWithoutTaxes({
    price: daily,
    taxAmount,
  })

  const oldDailyPriceWithoutTaxes = Number(
    getPriceBeforeDiscount(
      Number(dynamicDiscount?.amount),
      Number(dailyPriceWithoutTaxes),
    ),
  )

  const oldDailyPriceWithoutDiscount = getPriceBeforeDiscount(
    Number(dynamicDiscount?.amount),
    daily,
  )

  const hasSmallerFontSize = OVERSIZE_CURRENCIES.includes(currency)
  const isMexicanPeso = currency === Currency.MXN

  return (
    <S.PlanItem
      data-has-laptop-styles={hasLaptopStyles}
      data-is-selected={isSelected}
      data-is-default={isDefault}
      data-text={t`subscriptions.planBlock.mostPopular`}
      data-is-es-lang={language === Language.ES}
    >
      <S.PlanTitleWrapper>
        <S.PlanTitle>
          <Trans
            i18nKey="subscriptions.planBlock.weeklyPlanTitle"
            values={{
              periodQuantity: INTRO_OFFER_PERIODS_WEEKS[durationDays],
              periodName: t(INTRO_OFFER_WEEKLY_PERIOD_NAMES[durationDays]),
            }}
          />
        </S.PlanTitle>
        <S.PlanPricesWrapper>
          {!!oldPrices.beforeCustomDiscount.fullPrice && (
            <S.OldPlanPrice isMexicanPeso={isMexicanPeso}>
              <PriceValue value={oldPrice} currency={currency} />
            </S.OldPlanPrice>
          )}
          <img src={arrow} alt="icon" />
          <S.CurrentPrice isMexicanPeso={isMexicanPeso}>
            <PriceValue
              value={hasIncludedVat ? priceWithoutTaxes : trialPrice}
              currency={currency}
            />
          </S.CurrentPrice>
        </S.PlanPricesWrapper>
      </S.PlanTitleWrapper>
      <S.PlanPrice
        marginBottom={language === Language.EN ? 10 : 0}
        hasSmallerFontSize={hasSmallerFontSize}
      >
        <p>
          <PriceValue
            value={
              hasIncludedVat
                ? oldDailyPriceWithoutTaxes
                : oldDailyPriceWithoutDiscount
            }
            currency={currency}
          />
        </p>
        <strong>
          <PriceValue
            value={hasIncludedVat ? dailyPriceWithoutTaxes : daily}
            currency={currency}
          />
        </strong>
        <sub>
          {t('subscriptions.planBlock.perPeriod', {
            interval: t('subscriptions.planBlock.perDay'),
          })}
        </sub>
      </S.PlanPrice>
    </S.PlanItem>
  )
}
