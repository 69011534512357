import styled from 'styled-components'

import { Button } from 'components/Button'
import { SvgImage } from 'components/SvgImage'

import { Color } from 'root-constants/common'

export const StyledDeliveryForm = {
  Wrapper: styled.div`
    width: 100%;
  `,
  Title: styled.h2`
    margin-bottom: 24px;
    text-align: center;
    font-size: 20px;
    font-weight: 700;
    line-height: 22px;
  `,
  Form: styled.form``,
  OptionalAddressButton: styled.button`
    margin-bottom: 20px;
    color: ${Color.ACCENT_VIOLET};
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
    background: transparent;
    border: none;
    outline: none;
    cursor: pointer;
  `,
  ShippingInfoTitle: styled.h2`
    margin-bottom: 12px;
    font-size: 18px;
    font-weight: 700;
    line-height: 24px;
  `,
  ShippingInfoContainer: styled.div`
    display: grid;
    grid-template-columns: 24px 1fr;
    grid-template-rows: auto;
    grid-column-gap: 12px;
    grid-template-areas:
      'icon text'
      'icon shippingDays';
    align-items: center;
    height: 56px;
    margin-bottom: 24px;
    padding: 8px 16px;
    border-radius: 12px;
    background-color: ${Color.GREY};
  `,
  ShippingInfoIcon: styled(SvgImage)`
    grid-area: icon;
    width: 24px;
  `,
  ShippingInfoText: styled.p`
    grid-area: text;
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
  `,
  ShippingInfoDays: styled.p`
    grid-area: shippingDays;
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
  `,
  Button: styled(Button)`
    height: 56px;
    font-size: 20px;
    font-weight: 700;
    line-height: 22px;
  `,
}
