import { createGlobalStyle } from 'styled-components'

import { Color } from 'root-constants/common'

type TProps = {
  isSecondUpsellPage: boolean
}
export const FamylocateGlobalStyles = createGlobalStyle<TProps>`
  * {
    font-family: 'Open Sans', sans-serif;
  }
  
  :root {
    --base-background: ${({ isSecondUpsellPage }) =>
      isSecondUpsellPage ? '#fafaff' : Color.DEEP_BLUE};
  }
`
export const MainSafetyGlobalStyles = createGlobalStyle`
  :root {
    --base-background: #f5f5ff;
  }
`
