import React from 'react'
import { Trans, useTranslation } from 'react-i18next'

import { usePurchaseStore } from 'hooks/usePurchaseStore'

import { PaymentRequestButton } from 'modules/payment/components/PaymentRequestButton'

import { TermsOfUseLink } from 'components/TermsOfUseLink'

import paymentServicesImage from 'assets/images/payment-services.png'

import {
  CURRENCY_SYMBOLS,
  TRIAL_IN_HOURS,
  TimeInterval,
  TrialPeriod,
} from 'root-constants/common'

import { StyledPaymentFlowsGoogle as S } from './PaymentFlowsGoogle.styles'

export const PaymentFlowsGoogle: React.FC = () => {
  const { t } = useTranslation()

  const {
    selectedSubscriptionId,
    currentPrice,
    currency,
    trialPrice,
    trialPeriodDays,
  } = usePurchaseStore()

  return (
    <S.Wrapper>
      <S.Title>{t`payment.checkout`}</S.Title>
      <S.Description>
        <S.DescriptionText gridArea="periodText">{t`payment.subscriptionPeriod`}</S.DescriptionText>
        <S.DescriptionValue gridArea="periodValue">
          {t('payment.pricePeriod', {
            trialPeriodDays,
            count: trialPeriodDays,
          })}
        </S.DescriptionValue>

        <S.DescriptionText gridArea="priceText">{t`payment.price`}</S.DescriptionText>
        <S.DescriptionValue gridArea="priceValue">
          <Trans
            i18nKey="payment.trialPrice"
            values={{
              currencySymbol: CURRENCY_SYMBOLS[currency],
              trialPrice,
            }}
            components={{ span: <span /> }}
          />
        </S.DescriptionValue>
      </S.Description>
      <S.Disclaimer>
        {t('payment.googleFlowDisclaimer', {
          period: TRIAL_IN_HOURS[trialPeriodDays],
          trialPeriodDays,
          currencySymbol: CURRENCY_SYMBOLS[currency],
          price: currentPrice,
          context:
            trialPeriodDays > TrialPeriod.THREE_DAYS
              ? TimeInterval.WEEK
              : TimeInterval.DAY,
        })}
      </S.Disclaimer>

      <S.StripePaymentForm />
      <PaymentRequestButton isDividerVisible key={selectedSubscriptionId} />
      <S.Image
        src={paymentServicesImage}
        decoding="async"
        alt="payments services"
      />
      <S.Guarantee>{t`payment.guarantee`}</S.Guarantee>
      <S.BottomDisclaimer>
        <Trans
          i18nKey="payment.descriptionWithLink"
          components={{ a: <TermsOfUseLink /> }}
        />
      </S.BottomDisclaimer>
    </S.Wrapper>
  )
}
