import React, { useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next'

import lottie from 'lottie-web/build/player/lottie_light'

import { BENEFITS_DATA } from 'pages/intro-google/components/SearchNumberAnimation/constants'

import searchNumberAnimation from 'assets/animations/search-number-animation.json'

import { StyledSearchNumberAnimationVariant1 as S } from './SearchNumberAnimationVariant1.styles'

export const SearchNumberAnimationVariant1: React.FC = () => {
  const { t } = useTranslation()
  const phoneAnimationRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (phoneAnimationRef.current) {
      lottie.loadAnimation({
        name: 'phoneAnimation',
        container: phoneAnimationRef.current,
        animationData: searchNumberAnimation,
        loop: true,
      })
    }

    return () => lottie.destroy('phoneAnimation')
  }, [])

  return (
    <S.Wrapper>
      <S.BenefitsColumn>
        {BENEFITS_DATA.map(({ text, img }) => (
          <S.Benefits key={text}>
            <img src={img} alt="text" />
            <S.Text>{t(text)}</S.Text>
          </S.Benefits>
        ))}
      </S.BenefitsColumn>
      <S.AnimationContainer ref={phoneAnimationRef} />
    </S.Wrapper>
  )
}
