import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'

import { sendFacebookParamsAction } from 'root-redux/actions/user'
import {
  selectCurrentVariantCohortToUse,
  selectLanguage,
} from 'root-redux/selects/common'
import { selectUUID, selectUserCountryCode } from 'root-redux/selects/user'

import { useAmplitudeInitialization } from 'hooks/useAmplitudeInitialization'
import { useCookieConsentAnswer } from 'hooks/useCookieConsentAnswer'
import { useDynamicDiscount } from 'hooks/useDynamicDiscount'
import { useGAPageTracker } from 'hooks/useGAPageTracker'
import { useGetPageInfo } from 'hooks/useGetPageInfo'
import { useGiaFlowTrackerInitialization } from 'hooks/useGiaFlowTrackerInitialization'
import { growthbook } from 'hooks/useGrowthBook'
import { useScrollToTop } from 'hooks/useScrollToTop'
import { useSetPlanAdditions } from 'hooks/useSetPlanAdditions'
import { useStartSession } from 'hooks/useStartSession'
import { useUtmTags } from 'hooks/useUtmTags'

import { getEnvironment } from 'helpers/getEnvironment'

import { googleAnalyticsLogger } from 'services/googleAnalytics.service'

import { CookieConsent } from 'components/CookieConsent'
import { Header } from 'components/Header'
import { InContextControlPanel } from 'components/InContextControlPanel'
import { LanguageSelector } from 'components/LanguageSelector'
import { FamylocateGlobalStyles } from 'components/LayoutProvider/LayoutProvider.styles'
import { Theme } from 'components/Theme'

import famyLocateLogoV2 from 'assets/images/famylocate-header-logo-v2.png'

import {
  DEFAULT_HEADER_BORDER,
  FAMYLOCATE_COHORTS,
  Language,
} from 'root-constants/common'

import { RouteList } from '../../RouteList'

export const LayoutProvider: React.FC = () => {
  const dispatch = useDispatch()
  const { search, pathname } = useLocation()
  const { i18n } = useTranslation()
  const cohortToUse = useSelector(selectCurrentVariantCohortToUse)
  const language = useSelector(selectLanguage)
  const uuid = useSelector(selectUUID)
  const userCountryCode = useSelector(selectUserCountryCode)

  const { isDevEnvironment, isStageEnvironment, isTestEnvironment } =
    getEnvironment()
  const isLanguageSelectorShown = isStageEnvironment || isTestEnvironment

  const { isSecondPage, isSecondUpsellPage } = useGetPageInfo()

  useUtmTags()
  useGAPageTracker()
  useGiaFlowTrackerInitialization()
  useAmplitudeInitialization()
  useStartSession()
  useSetPlanAdditions()
  useScrollToTop()
  useDynamicDiscount()

  const { isEUUser, cookieConsent } = useCookieConsentAnswer()

  const hasAlternativeStyles = FAMYLOCATE_COHORTS.includes(cohortToUse)
  const hasAlternativeFamyLocateHeader =
    FAMYLOCATE_COHORTS.includes(cohortToUse) && isSecondUpsellPage

  const isCookieConsentAvailable =
    !cookieConsent && isEUUser && language === Language.EN

  useEffect(() => {
    i18n.changeLanguage(language)
  }, [i18n, language])

  useEffect(() => {
    if (isSecondPage) {
      dispatch(sendFacebookParamsAction())
      window.fbq('track', 'CustomizeProduct', {}, { eventID: uuid })
      googleAnalyticsLogger.logFirstPageCompleted()
    }
  }, [dispatch, isSecondPage, uuid])

  useEffect(() => {
    growthbook.setAttributes({
      ...growthbook.getAttributes(),
      url: `${pathname}${search}`,
      country: userCountryCode,
      language,
    })
  }, [language, pathname, search, userCountryCode])

  return (
    <Theme>
      {hasAlternativeStyles && (
        <FamylocateGlobalStyles isSecondUpsellPage={isSecondUpsellPage} />
      )}

      {isDevEnvironment && <InContextControlPanel />}
      {isLanguageSelectorShown && <LanguageSelector />}

      <Header
        headerImg={hasAlternativeFamyLocateHeader ? famyLocateLogoV2 : ''}
        headerBorder={
          hasAlternativeFamyLocateHeader ? DEFAULT_HEADER_BORDER : ''
        }
      />
      {isCookieConsentAvailable && <CookieConsent />}
      <RouteList />
    </Theme>
  )
}
