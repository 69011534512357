import { Gender } from 'root-constants/common'

export const OPTIONS = [
  {
    value: Gender.FEMALE,
    text: 'onboarding.gender.female',
  },
  {
    value: Gender.MALE,
    text: 'onboarding.gender.male',
  },
  {
    value: Gender.OTHER,
    text: 'onboarding.gender.other',
  },
]
