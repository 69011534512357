import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import 'react-international-phone/style.css'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router'

import { selectUserCountryCode } from 'root-redux/selects/user'

import { checkIsPhoneValid } from 'helpers/phone'

import { TPageProps } from 'models/common.model'

import { eventLogger } from 'services/eventLogger.service'

import { DesktopProvider } from 'components/DesktopProvider'
import { ProgressBarListModal } from 'components/ProgressBarList'

import phoneIcon from 'assets/images/phone-icon.png'
import trackPhoneDesktop from 'assets/images/track-phone-desktop.jpg'
import trackPhone from 'assets/images/track-phone-number.png'

import { goTo } from 'browser-history'
import { CustomPageId, PageId } from 'root-constants/pages'

import { StyledPhoneNumberVariant2 as S } from './PhoneNumberVariant2.styles'
import { PAGE_ANSWER, PAGE_NAME } from './constants'

export const PhoneNumberVariant2: React.FC<TPageProps> = () => {
  const { t } = useTranslation()
  const countryCode = useSelector(selectUserCountryCode)
  const { search } = useLocation()
  const [phoneNumber, setPhoneNumber] = useState('')
  const [isFocused, setIsFocused] = useState(false)
  const [isSubmitted, setIsSubmitted] = useState(false)
  const isValid = checkIsPhoneValid(phoneNumber)

  const handleContinue = () => {
    eventLogger.logQuestion({
      question: t(`onboarding.introGoogle3.phoneText2`, { lng: 'en' }),
      answers: PAGE_ANSWER,
      pageName: PAGE_NAME,
    })

    goTo(`${PageId.EMAIL_1}${search}`)
  }

  const handleChange = (value: string) => {
    setPhoneNumber(value)
  }

  const handleFocus = () => {
    setIsFocused(true)
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    setIsSubmitted(true)
    eventLogger.logCheckNumberClicked()
  }

  return (
    <DesktopProvider imageSrc={trackPhoneDesktop}>
      <S.Wrapper>
        <S.Column>
          <S.ImageContainer>
            <img src={trackPhone} alt="phone_track" />
            <S.PhoneIcon src={phoneIcon} alt="phone_icon" />
          </S.ImageContainer>
          <S.Title>{t`onboarding.introGoogle3.phoneText2`}</S.Title>
          <S.PhoneForm onSubmit={handleSubmit}>
            <S.PhoneNumberInput
              defaultCountry={countryCode.toLowerCase()}
              value={phoneNumber}
              onChange={handleChange}
              onFocus={handleFocus}
            />
            {isFocused && !isValid && (
              <S.PhoneErrorText>
                {t`onboarding.introGoogle3.phoneError`}
              </S.PhoneErrorText>
            )}
            <S.LaterButton onClick={handleContinue} type="button">
              {t`actions.maybeLater`}
            </S.LaterButton>
            <S.Button type="submit">
              {t`onboarding.introGoogle3.phoneButton`}
            </S.Button>
          </S.PhoneForm>
          <ProgressBarListModal
            isShown={isSubmitted}
            nextPagePath={`${PageId.EMAIL_1}${search}`}
            pageId={CustomPageId.PHONE_NUMBER}
            phoneNumber={phoneNumber}
            pageName={PAGE_NAME}
            isDesktop
          />
        </S.Column>
      </S.Wrapper>
    </DesktopProvider>
  )
}
