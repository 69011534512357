import React, { useCallback, useLayoutEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'

import { setAnswersAction } from 'root-redux/actions/common'
import { sendUserAnswersAction } from 'root-redux/actions/user'

import { useDelayedContinue } from 'hooks/useDelayedContinue'
import { useUserData } from 'hooks/useUserData'

import { TPageProps } from 'models/common.model'

import { eventLogger } from 'services/eventLogger.service'

import { DesktopProvider } from 'components/DesktopProvider'
import { Option, OptionType } from 'components/Option'
import { StyledOption } from 'components/Option/Option.styles'
import { QuestionButton } from 'components/QuestionButton'

import concernsKidImage from 'assets/images/concerns-kid.jpg'
import concernsImage from 'assets/images/concerns.jpg'

import { goTo, replaceHistory } from 'browser-history'
import { Frequency, Language, UserGoal } from 'root-constants/common'

import { StyledConcerns as S } from './Conserns.styles'
import { PAGE_NAME } from './constants'

export const ConcernsVariant1: React.FC<TPageProps> = ({
  pageId,
  nextPagePath,
  alternativePagePath,
}) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const { isAnswersDisabled, handleChange, setIsAnswersDisabled } =
    useDelayedContinue()
  const { userGoalsFormatted, singleUserGoal } = useUserData()

  const isPartnerGoal = singleUserGoal === UserGoal.PARTNER

  const isFriendGoal = singleUserGoal === UserGoal.FRIEND

  const hasKidGoal = userGoalsFormatted.includes(UserGoal.KID)

  const handleContinue = useCallback(
    (answer): void => {
      dispatch(
        setAnswersAction({
          pageId,
          answers: answer,
        }),
      )
      dispatch(sendUserAnswersAction())

      eventLogger.logQuestion({
        question: t('onboarding.concerns.question', { lng: Language.EN }),
        answers: answer,
        pageName: PAGE_NAME,
      })

      const isNegativeAnswer =
        answer === Frequency.NEVER || answer === Frequency.RARELY
      goTo(
        isPartnerGoal && isNegativeAnswer ? alternativePagePath : nextPagePath,
      )
    },
    [alternativePagePath, dispatch, isPartnerGoal, nextPagePath, pageId, t],
  )

  const optionProps = {
    name: pageId,
    type: OptionType.RADIO,
    onChange: (value: string) => {
      setIsAnswersDisabled(true)
      handleChange(value, handleContinue)
    },
  }

  useLayoutEffect(() => {
    if (isFriendGoal) {
      replaceHistory(nextPagePath)
    }
  }, [isFriendGoal, nextPagePath])

  return (
    <DesktopProvider imageSrc={hasKidGoal ? concernsKidImage : concernsImage}>
      <S.Wrapper>
        <S.Column>
          <S.Title>{t`onboarding.concerns.question`}</S.Title>
          <StyledOption.OptionsContainer>
            <Option
              {...optionProps}
              value={Frequency.QUITE_OFTEN}
              disabled={isAnswersDisabled}
            >
              <QuestionButton>{t`commonComponents.quiteOften`}</QuestionButton>
            </Option>
            <Option
              {...optionProps}
              value={Frequency.SOMETIMES}
              disabled={isAnswersDisabled}
            >
              <QuestionButton>{t`commonComponents.sometimes`}</QuestionButton>
            </Option>
            <Option
              {...optionProps}
              value={Frequency.RARELY}
              disabled={isAnswersDisabled}
            >
              <QuestionButton>{t`commonComponents.rarely`}</QuestionButton>
            </Option>
            <Option
              {...optionProps}
              value={Frequency.NEVER}
              disabled={isAnswersDisabled}
            >
              <QuestionButton>{t`commonComponents.never`}</QuestionButton>
            </Option>
          </StyledOption.OptionsContainer>
        </S.Column>
      </S.Wrapper>
    </DesktopProvider>
  )
}
