import styled from 'styled-components'

import { Button } from 'components/Button'

import { Color, MediaBreakpoint } from 'root-constants/common'

export const StyledGetPlanButton = {
  Button: styled(Button)<{ isVisible: boolean }>`
    position: fixed;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    border-radius: unset;
    line-height: unset;
    height: unset;
    width: 100%;
    max-width: ${MediaBreakpoint.MAX_PHONE}px;
    padding: 13px 16px;
    background-color: ${Color.DEFAULT_TEXT};
    visibility: ${({ isVisible }) => (isVisible ? 'visible' : 'hidden')};

    @media (min-width: ${MediaBreakpoint.MAX_PHONE}px) {
      max-width: 375px;
    }
  `,
  ButtonContent: styled.div`
    width: inherit;
    display: flex;
    align-items: center;

    > :not(:last-child) {
      margin: 0 16px 0 0;
    }
  `,
  TimerBlock: styled.div`
    display: flex;
    align-items: center;
    flex-grow: 1;
  `,
  TimerValue: styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #23475f;
    width: 40px;
    height: 48px;
    border-radius: 16px;
  `,
  Value: styled.span`
    font-size: 18px;
    font-weight: 600;
    line-height: 18px;
    color: ${Color.WHITE};
  `,
  Text: styled.span`
    font-size: 8px;
    font-weight: 700;
    line-height: 12px;
    text-transform: uppercase;
    color: #547a93;
  `,
  Divider: styled.span`
    margin: 0 4px;
    color: #547a93;
  `,
  PromoSlogan: styled.p`
    flex-grow: 2;
    font-size: 17px;
    font-weight: 600;
    color: ${Color.WHITE};
    text-align: left;
  `,
  Label: styled.div`
    flex-grow: 1;
    height: 40px;
    border-radius: 30px;
    background-color: ${Color.WHITE};
    color: ${Color.DEFAULT_TEXT};
    padding: 8px 20px;
    font-size: 17px;
    text-align: center;
  `,
}
