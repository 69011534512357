import styled from 'styled-components'

import { baseColumnStyles, basePageStyles } from 'common-styles'
import { HEADER_HEIGHT, MediaBreakpoint } from 'root-constants/common'

type TTextProps = {
  marginBottom: number
}

export const StyledSocialProofVariant9 = {
  Wrapper: styled.div`
    ${basePageStyles};
    padding-top: ${HEADER_HEIGHT}px;
  `,
  ImageContainer: styled.div`
    width: 100%;
    max-width: ${MediaBreakpoint.MAX_PHONE}px;
    margin-bottom: 33px;
    aspect-ratio: 375/300;

    @media (min-width: ${MediaBreakpoint.MAX_PHONE}px) {
      max-width: 375px;
    }
  `,
  Column: styled.div`
    ${baseColumnStyles};
    padding-bottom: 32px;

    @media (min-height: 550px) {
      position: fixed;
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);
    }
  `,
  Text: styled.p<TTextProps>`
    margin-bottom: ${({ marginBottom }) => `${marginBottom}px`};
    font-size: 16px;
    line-height: 22px;
    text-align: center;
  `,
  Note: styled.span`
    margin-bottom: 16px;
    font-style: italic;
    font-size: 13px;
    line-height: 20px;
    text-align: center;
    color: #bcc9d0;
  `,
}
