import React from 'react'
import { useTranslation } from 'react-i18next'

import { useUserData } from 'hooks/useUserData'

import { formatPhoneNumber } from 'helpers/phone'

import { StyledPhoneNumberLabel as S } from './PhoneNumberLabel.styles'

export const PhoneNumberLabel: React.FC = (props) => {
  const { t } = useTranslation()
  const { userPhoneNumber } = useUserData()

  const formattedPhoneNumber = formatPhoneNumber(userPhoneNumber)

  return (
    <S.Wrapper {...props}>
      <S.Text>{t`commonComponents.locating`}</S.Text>
      <S.Text>
        <strong>{formattedPhoneNumber}</strong>
      </S.Text>
    </S.Wrapper>
  )
}
