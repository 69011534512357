export const setViewportFullHeight = (): void => {
  function calculateInnerHeightAndSetStyle() {
    document.documentElement.style.setProperty(
      '--full-height',
      `${window.innerHeight}px`,
    )
  }

  calculateInnerHeightAndSetStyle()

  window.addEventListener('load', calculateInnerHeightAndSetStyle)
  window.addEventListener('resize', calculateInnerHeightAndSetStyle)
}
