import styled, { css } from 'styled-components'

import { SelectPlanBlockVariant1 } from 'modules/subscriptions/components/common/SelectPlanBlockVariant1'

import { Button } from 'components/Button'
import { ReviewsSlider } from 'components/ReviewsSlider'

import gradient from 'assets/images/benefits-bg.svg'

import { baseColumnStyles, basePageStyles } from 'common-styles'
import { Color, MediaBreakpoint } from 'root-constants/common'

type TProps = {
  marginBottom?: number
  laptopMarginBottom?: number
}

const baseTitleStyles = css<TProps>`
  font-size: 24px;
  line-height: 33px;
  text-align: center;
`

export const StyledCancelOfferSubscriptions = {
  Wrapper: styled.div`
    ${basePageStyles};
    min-height: 100%;
    padding: 85px 0 20px;

    @media (min-width: ${MediaBreakpoint.LAPTOP}px) {
      padding: 136px 0 80px;
    }
  `,
  Content: styled.div`
    ${baseColumnStyles};

    @media (min-width: ${MediaBreakpoint.LAPTOP}px) {
      width: 1023px;
    }
  `,
  Title: styled.h1`
    ${baseTitleStyles};
    font-weight: 800;
    margin-bottom: 12px;

    @media (min-width: ${MediaBreakpoint.LAPTOP}px) {
      font-size: 72px;
      line-height: 98px;
      margin-bottom: 24px;
    }
  `,
  Subtitle: styled.h2`
    ${baseTitleStyles};
    font-weight: 700;
    margin: ${({ marginBottom }) => `0 -5px ${marginBottom}px`};

    @media (min-width: ${MediaBreakpoint.LAPTOP}px) {
      font-weight: 800;
      font-size: 38px;
      line-height: 52px;
      margin: ${({ laptopMarginBottom }) => `0 0 ${laptopMarginBottom}px`};
    }
  `,
  Text: styled.p`
    font-size: 16px;
    line-height: 22px;
    text-align: center;
    margin-bottom: 27px;

    @media (min-width: ${MediaBreakpoint.LAPTOP}px) {
      width: 608px;
      font-weight: 500;
      font-size: 24px;
      line-height: 33px;
      margin-bottom: 80px;
    }
  `,
  BenefitsList: styled.ul`
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 18px 0;
    width: 100%;
    font-size: 16px;
    line-height: 23px;
    margin-bottom: 48px;

    li {
      display: flex;
      align-items: center;
      font-weight: 700;
    }

    img {
      width: 23px;
      height: 23px;
      margin-right: 12px;
    }

    ::before {
      content: '';
      top: -100px;
      left: 50%;
      transform: translateX(-50%);
      display: block;
      width: 375px;
      aspect-ratio: 1/1;
      background-image: url(${gradient});
      background-size: contain;
      background-repeat: no-repeat;
      position: absolute;
    }

    @media (min-width: ${MediaBreakpoint.LAPTOP}px) {
      flex-direction: row;
      flex-wrap: wrap;
      gap: 35px 56px;
      font-size: 20px;
      line-height: 27px;
      margin-bottom: 160px;
      padding: 0 160px;

      li {
        width: 320px;
      }

      img {
        width: 35px;
        height: 35px;
      }

      ::before {
        top: -300px;
        width: 602px;
      }
    }
  `,
  InnerWrapper: styled.div`
    display: flex;
    flex-direction: column;
    gap: 18px;

    p {
      font-weight: 400;
    }

    @media (min-width: ${MediaBreakpoint.LAPTOP}px) {
      gap: 35px;
    }
  `,
  PromoBlock: styled.div`
    font-weight: 500;
    font-size: 13px;
    line-height: 18px;
    text-align: center;
    background-image: linear-gradient(
      262.56deg,
      rgba(209, 224, 255, 0.5) 10%,
      rgba(224, 200, 255, 0.5) 99%
    );
    border-radius: 16px;
    padding: 15px 16px 18px;
    margin-bottom: 48px;

    b,
    strong,
    span {
      font-weight: 900;
    }

    strong {
      color: ${Color.ACCENT_VIOLET};
    }

    span {
      font-size: 28px;
      line-height: 42px;
      text-align: center;
      color: ${Color.ACCENT_VIOLET};
    }

    @media (min-width: ${MediaBreakpoint.LAPTOP}px) {
      font-size: 15px;
      border-radius: 24px;
      padding: 15px 50px;
      margin-bottom: 70px;

      span {
        font-size: 40px;
        line-height: 55px;
      }
    }
  `,
  Slider: styled(ReviewsSlider)`
    margin-bottom: 57px;
  `,
  GuaranteeCard: styled.div`
    font-size: 13px;
    line-height: 20px;
    text-align: center;
    color: #565976;

    @media (min-width: ${MediaBreakpoint.LAPTOP}px) {
      font-size: 15px;
    }
  `,
  GuaranteeImage: styled.img`
    height: 180px;
    width: auto;
    margin: 0 auto 48px;
  `,
  Logo: styled.img`
    margin: 0 auto 16px;

    @media (min-width: ${MediaBreakpoint.LAPTOP}px) {
      margin-bottom: 46px;
    }
  `,
  LinksWrapper: styled.div`
    display: flex;
    gap: 24px;
    justify-content: center;

    a {
      color: inherit;
      text-decoration: underline;
    }

    @media (min-width: ${MediaBreakpoint.LAPTOP}px) {
      gap: 40px;
    }
  `,
  Button: styled(Button)<TProps>`
    font-weight: 800;
    margin-bottom: 48px;
    height: 56px;
    line-height: 56px;

    &[data-is-es-language='true'] {
      font-size: 16px;
    }

    @media (min-width: ${MediaBreakpoint.LAPTOP}px) {
      width: 391px;
      height: 76px;
      font-weight: 700;
      font-size: 24px;
      line-height: 76px;
      margin-bottom: ${({ laptopMarginBottom }) => laptopMarginBottom}px;

      &[data-is-es-language='true'] {
        font-size: 22px;
      }
    }
  `,
  SelectPlanBlock: styled(SelectPlanBlockVariant1)`
    margin-bottom: 8px;
  `,
}
