import styled from 'styled-components'

import {
  baseColumnStyles,
  basePageStyles,
  baseTitleStyles,
} from 'common-styles'
import { HEADER_HEIGHT, MediaBreakpoint } from 'root-constants/common'

type TTextProps = {
  marginTop?: number
}
export const StyledSocialProofVariant19 = {
  Wrapper: styled.div`
    ${basePageStyles};
    padding-top: ${HEADER_HEIGHT}px;
  `,
  ImageContainer: styled.div`
    width: 100%;
    max-width: ${MediaBreakpoint.MAX_PHONE}px;
    aspect-ratio: 375/238;

    @media (min-width: ${MediaBreakpoint.MAX_PHONE}px) {
      max-width: 375px;
    }
  `,
  Column: styled.div`
    ${baseColumnStyles};
    margin-top: 32px;

    @media (min-width: 390px) {
      margin-top: 5px;
    }
  `,
  FixedContainer: styled.div`
    ${baseColumnStyles};
    position: fixed;
    bottom: 0;
    padding-bottom: 40px;

    @media (min-width: 430px) {
      padding-bottom: 75px;
    }
  `,
  Title: styled.h1`
    ${baseTitleStyles};
    margin-bottom: 16px;
  `,
  Text: styled.p<TTextProps>`
    font-size: 16px;
    line-height: 22px;
    font-weight: 400;
    text-align: center;
    margin-top: ${({ marginTop }) => marginTop || 0}px;

    strong {
      font-weight: 700;
      font-size: 16px;
      line-height: 22px;
    }
  `,
  Note: styled.p`
    font-size: 13px;
    font-weight: 400;
    line-height: 20px;
    font-style: italic;
    text-align: center;
    color: #bcc9d0;
    margin-bottom: 16px;
  `,
}
