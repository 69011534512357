import React, { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'

import { setAnswersAction } from 'root-redux/actions/common'
import { sendUserAnswersAction } from 'root-redux/actions/user'

import { useDelayedContinue } from 'hooks/useDelayedContinue'

import { TPageProps } from 'models/common.model'

import { eventLogger } from 'services/eventLogger.service'

import { Option, OptionType } from 'components/Option'
import { StyledOption } from 'components/Option/Option.styles'
import { QuestionButton } from 'components/QuestionButton'

import { goTo } from 'browser-history'
import { Confidence, Language } from 'root-constants/common'

import { StyledWish as S } from './Wish.styles'
import { PAGE_NAME } from './constants'

export const WishVariant1: React.FC<TPageProps> = ({
  pageId,
  nextPagePath,
  alternativePagePath,
}) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const { isAnswersDisabled, handleChange, setIsAnswersDisabled } =
    useDelayedContinue()

  const handleContinue = useCallback(
    (answer) => {
      dispatch(
        setAnswersAction({
          answers: answer,
          pageId,
        }),
      )

      dispatch(sendUserAnswersAction())

      eventLogger.logQuestion({
        question: t('onboarding.wish.question', { lng: Language.EN }),
        answers: answer,
        pageName: PAGE_NAME,
      })

      answer === Confidence.NO ? goTo(alternativePagePath) : goTo(nextPagePath)
    },
    [alternativePagePath, dispatch, nextPagePath, pageId, t],
  )

  const optionProps = {
    name: pageId,
    type: OptionType.RADIO,
    onChange: (value: string) => {
      handleChange(value, handleContinue)
      setIsAnswersDisabled(true)
    },
  }

  return (
    <S.Wrapper>
      <S.Column>
        <S.Title>{t`onboarding.wish.question`}</S.Title>
        <StyledOption.OptionsContainer>
          <Option
            {...optionProps}
            value={Confidence.YES}
            disabled={isAnswersDisabled}
          >
            <QuestionButton>{t`commonComponents.yes`}</QuestionButton>
          </Option>
          <Option
            {...optionProps}
            value={Confidence.NO}
            disabled={isAnswersDisabled}
          >
            <QuestionButton>{t`commonComponents.no`}</QuestionButton>
          </Option>
          <Option
            {...optionProps}
            value={Confidence.SOMETIMES}
            disabled={isAnswersDisabled}
          >
            <QuestionButton>{t`commonComponents.sometimes`}</QuestionButton>
          </Option>
        </StyledOption.OptionsContainer>
      </S.Column>
    </S.Wrapper>
  )
}
