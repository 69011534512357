import React, { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'

import { setAnswersAction } from 'root-redux/actions/common'
import { sendUserAnswersAction } from 'root-redux/actions/user'

import { useDelayedContinue } from 'hooks/useDelayedContinue'

import { TPageProps } from 'models/common.model'

import { eventLogger } from 'services/eventLogger.service'

import { Option, OptionType } from 'components/Option'
import { StyledOption } from 'components/Option/Option.styles'
import { QuestionButton } from 'components/QuestionButton'

import { goTo } from 'browser-history'
import { Language } from 'root-constants/common'

import { StyledWhatToDo as S } from './WhatToDo.styles'
import { OPTION_VALUES, PAGE_NAME } from './constants'

export const WhatToDoVariant1: React.FC<TPageProps> = ({
  pageId,
  nextPagePath,
  alternativePagePath,
}) => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const { isAnswersDisabled, handleChange, setIsAnswersDisabled } =
    useDelayedContinue()

  const handleContinue = useCallback(
    (answer) => {
      dispatch(
        setAnswersAction({
          answers: answer,
          pageId,
        }),
      )

      dispatch(sendUserAnswersAction())

      eventLogger.logQuestion({
        question: t('onboarding.whatToDo.question', { lng: Language.EN }),
        answers: answer,
        pageName: PAGE_NAME,
      })

      answer === OPTION_VALUES.STAY_CALM
        ? goTo(alternativePagePath)
        : goTo(nextPagePath)
    },
    [alternativePagePath, dispatch, nextPagePath, pageId, t],
  )

  const optionProps = {
    name: pageId,
    type: OptionType.RADIO,
    onChange: (value: string) => {
      handleChange(value, handleContinue)
      setIsAnswersDisabled(true)
    },
  }

  return (
    <S.Wrapper>
      <S.Column>
        <S.Title>{t`onboarding.whatToDo.question`}</S.Title>
        <StyledOption.OptionsContainer>
          <Option
            {...optionProps}
            value={OPTION_VALUES.GET_THROUGH}
            disabled={isAnswersDisabled}
          >
            <QuestionButton>{t`onboarding.whatToDo.answers.getThrough`}</QuestionButton>
          </Option>
          <Option
            {...optionProps}
            value={OPTION_VALUES.STAY_CALM}
            disabled={isAnswersDisabled}
          >
            <QuestionButton>{t`onboarding.whatToDo.answers.stayCalm`}</QuestionButton>
          </Option>
          <Option
            {...optionProps}
            value={OPTION_VALUES.FEEL_ANXIOUS}
            disabled={isAnswersDisabled}
          >
            <QuestionButton>{t`onboarding.whatToDo.answers.feelAnxious`}</QuestionButton>
          </Option>
          <Option
            {...optionProps}
            value={OPTION_VALUES.OTHER}
            disabled={isAnswersDisabled}
          >
            <QuestionButton>{t`onboarding.whatToDo.answers.other`}</QuestionButton>
          </Option>
        </StyledOption.OptionsContainer>
      </S.Column>
    </S.Wrapper>
  )
}
