import styled, { css } from 'styled-components'

import { Button } from 'components/Button'
import { Switch } from 'components/Switch'

import minusIcon from 'assets/images/minus-accordion-icon.svg'
import plusIcon from 'assets/images/plus-accordion-icon.svg'

import { Color } from 'root-constants/common'

type TTitleProps = {
  marginBottom?: number
}

export const StyledCookieConsent = {
  Wrapper: styled.div<{ fullHeight: boolean }>`
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    box-shadow: 0 8px 20px 0 #cccfde;
    background-color: ${Color.WHITE};
    z-index: 3;
    ${({ fullHeight }) => {
      return (
        fullHeight &&
        css`
          height: 100dvh;
          overflow-y: auto;
        `
      )
    }};
  `,
  Header: styled.header`
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 24px;
    align-items: center;
    justify-content: flex-end;
    border-bottom: 1px solid #ccdce5;
    padding: 10px 12px;

    img {
      justify-self: center;
      margin-left: 24px;
    }
  `,
  CloseButton: styled.button`
    all: unset;
    display: flex;
    align-items: center;
    cursor: pointer;
  `,
  Container: styled.div`
    max-width: 375px;
    margin: 0 auto;
    padding: 16px;
  `,
  Title: styled.h2<TTitleProps>`
    margin-bottom: ${({ marginBottom }) => `${marginBottom || 8}px`};
    font-size: 16px;
    font-weight: 700;
    line-height: 22px;
  `,
  Description: styled.p`
    margin-bottom: 16px;
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
    color: #536f82;

    a {
      text-decoration: underline;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 18px;
      color: #536f82;
  `,
  ButtonsContainer: styled.div`
    padding-top: 16px;
    border-top: 1px solid #ccdce5;
  `,
  PrimaryButton: styled(Button)`
    width: 343px;
    height: 38px;
    margin-bottom: 8px;
    font-size: 14px;
    font-weight: 700;
    line-height: 18px;
    border-radius: 100px;
  `,
  SecondaryButton: styled(Button)`
    width: 343px;
    height: 38px;
    margin-bottom: 8px;
    background: ${Color.GREY};
    color: ${Color.ACCENT_VIOLET};
    font-size: 14px;
    font-weight: 700;
    line-height: 18px;
    border-radius: 100px;

    &:last-child {
      margin-bottom: 0;
    }
  `,
  Settings: styled.ul`
    border: 1px solid #ccdce5;
    border-bottom: none;
  `,
  SettingItem: styled.li`
    position: relative;

    & label {
      position: absolute;
      right: 16px;
      top: 18px;
    }
  `,
  SettingsTitle: styled.h2`
    margin: 32px auto 20px;
    font-size: 18px;
    font-weight: 700;
    line-height: 25px;
  `,
  Setting: styled.details`
    &[open] summary::after {
      background-image: url(${minusIcon});
    }

    &:last-child span {
      flex-basis: 164px;
    }
  `,
  SettingName: styled.summary`
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    min-height: 60px;
    padding: 12px 16px 12px 41px;
    font-size: 14px;
    font-weight: 700;
    line-height: 18px;
    border-bottom: 1px solid #ccdce5;

    &::-webkit-details-marker {
      display: none;
    }

    & span {
      flex-basis: 195px;
    }

    &::after {
      content: '';
      width: 20px;
      height: 20px;
      background-image: url(${plusIcon});
      background-size: contain;
      position: absolute;
      left: 16px;
      top: 50%;
      transform: translateY(-50%);
    }
  `,
  SettingDetails: styled.p`
    padding: 16px;
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
    background-color: #f9fafb;
    color: #536f82;
    border-bottom: 1px solid #ccdce5;
  `,
  ActiveLabel: styled.strong`
    font-size: 14px;
    font-weight: 700;
    line-height: 18px;
    color: ${Color.ACCENT_VIOLET};
  `,
  Divider: styled.div`
    width: 100%;
    height: 1px;
    margin-top: 8px;
    background-color: #ccdce5;
  `,
  Switch: styled(Switch)`
    width: 44px;
    height: 24px;

    div {
      background: #e1e8ec;
    }

    input {
      &:checked ~ div {
        background: ${Color.ACCENT_VIOLET};
        border: 1px solid transparent;
      }
    }

    span {
      top: 2px;
      left: 2px;
      width: 20px;
      height: 20px;
    }
  `,
}
