import React from 'react'
import { Trans } from 'react-i18next'

import { CURRENCY_SYMBOLS } from 'root-constants/common'

type TProps = {
  value: string | number
  currency: string
  i18nKey?: string
  context?: string
}

export const PriceValue: React.FC<TProps> = ({
  value,
  currency,
  i18nKey = 'subscriptions.planBlock.price',
  context,
}) => (
  <Trans
    i18nKey={i18nKey}
    values={{
      price: value,
      currencySymbol: CURRENCY_SYMBOLS[currency],
      minimumFractionDigits: 2,
      ...(context && { context }),
    }}
  />
)
