import reviewAvatar1 from 'assets/images/review-avatar-4.webp'
import reviewAvatar3 from 'assets/images/review-avatar-6.webp'
import reviewAvatar2 from 'assets/images/review-avatar-8.webp'

export const SLIDER_DATA = [
  {
    avatar: reviewAvatar1,
    userName: 'subscriptions.reviewsSlider.suzie',
    reviewText: 'subscriptions.reviewsSlider.text4',
    borderColor: '#eed0e4',
  },
  {
    avatar: reviewAvatar2,
    userName: 'subscriptions.reviewsSlider.maria',
    reviewText: 'subscriptions.reviewsSlider.text5',
    borderColor: '#d9d8f6',
  },
  {
    avatar: reviewAvatar3,
    userName: 'subscriptions.reviewsSlider.david',
    reviewText: 'subscriptions.reviewsSlider.text17',
    borderColor: '#f0dace',
  },
]

export const STEPS_DATA = [
  {
    title: 'onboarding.introGoogle4.steps.stepOne',
    subtitle: 'subscriptions.variant6.steps.download',
    text: 'subscriptions.variant10.steps.note',
  },
  {
    title: 'onboarding.introGoogle4.steps.stepTwo',
    subtitle: 'subscriptions.variant10.steps.createGroups',
    text: 'subscriptions.variant10.steps.realTime',
  },
  {
    title: 'onboarding.introGoogle4.steps.stepThree',
    subtitle: 'subscriptions.variant10.steps.enjoyPeace',
    text: 'subscriptions.variant10.steps.beInTouch',
  },
]
