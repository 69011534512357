import { useFeatureIsOn, useFeatureValue } from '@growthbook/growthbook-react'

import {
  GOOGLE_9_TEST_SEGMENTS,
  GROWTH_BOOK_FEATURE_KEYS,
  MAIN_9_CHECKOUT_TEST_SEGMENTS,
} from 'root-constants/common'

export const useExperimentalFeatures = () => {
  const hasAlternativePrices = useFeatureIsOn(
    GROWTH_BOOK_FEATURE_KEYS.car_931_month,
  )

  const hasBrazilianPrices = useFeatureIsOn(
    GROWTH_BOOK_FEATURE_KEYS.car_1143_brl,
  )

  const hasCanadianPrice = useFeatureIsOn(GROWTH_BOOK_FEATURE_KEYS.car_1067_can)

  const isPrimerCard = useFeatureIsOn(
    GROWTH_BOOK_FEATURE_KEYS.car_1134_primer_card,
  )

  const alternativePlanItem = useFeatureValue(
    GROWTH_BOOK_FEATURE_KEYS.car_1007_checkout,
    GOOGLE_9_TEST_SEGMENTS.A_SEGMENT,
  )

  const hasCheckoutPinnedButton = useFeatureValue(
    GROWTH_BOOK_FEATURE_KEYS.car_1193_checkout,
    MAIN_9_CHECKOUT_TEST_SEGMENTS.A_SEGMENT,
  )

  return {
    isPrimerCard,
    hasAlternativePrices,
    hasBrazilianPrices,
    hasCanadianPrice,
    alternativePlanItem,
    hasCheckoutPinnedButton,
  }
}
