import styled from 'styled-components'

import { MediaBreakpoint } from 'root-constants/common'

export const StyledChipoloDescription = {
  Wrapper: styled.div`
    width: 100%;
    max-width: ${MediaBreakpoint.MAX_PHONE}px;
    margin-bottom: 48px;

    @media (min-width: ${MediaBreakpoint.MAX_PHONE}px) {
      max-width: 375px;
    }
  `,
  Title: styled.h2`
    margin-bottom: 24px;
    text-align: center;
    font-size: 32px;
    font-weight: 800;
    line-height: 40px;
  `,
  Subtitle: styled.h3`
    margin-bottom: 24px;
    text-align: center;
    font-size: 20px;
    font-weight: 600;

    strong {
      color: #71da75;
      font-weight: 800;
    }
  `,
  BenefitsBlock: styled.div`
    padding: 32px;
    background: linear-gradient(179deg, #f6f9ff 26.53%, #f9f4ff 99.42%);
  `,
  BenefitsBlockTitle: styled.h2`
    margin-bottom: 16px;
    text-align: center;
    font-size: 24px;
    font-weight: 800;
    line-height: 30px;
  `,
  BenefitsBlockSubtitle: styled.h3`
    margin-bottom: 24px;
    text-align: center;
    font-size: 18px;
    font-weight: 400;
    line-height: 25px;
  `,
  BenefitsWrapper: styled.div`
    margin-bottom: 32px;

    &:last-of-type {
      margin-bottom: 0;
    }
  `,
  Icon: styled.img`
    max-width: 85px;
    margin: 0 auto;
  `,
  Benefit: styled.p`
    margin-bottom: 8px;
    text-align: center;
    font-size: 20px;
    font-weight: 700;
    line-height: 27px;
  `,
  BenefitDescription: styled.p`
    text-align: center;
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
  `,
}
