import { useEffect } from 'react'
import { useSelector } from 'react-redux'

import firebase from 'firebase/app'
import 'firebase/auth'

import { selectFirebaseConfig } from 'root-redux/selects/common'

export const useInitFirebase = (): void => {
  const firebaseConfig = useSelector(selectFirebaseConfig)

  useEffect(() => {
    if (firebaseConfig && !firebase.apps.length) {
      firebase.initializeApp({
        ...firebaseConfig,
        authDomain: window.location.hostname,
      })
      firebase.auth().signOut()
    }
  }, [firebaseConfig])
}
