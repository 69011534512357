/* eslint-disable react/no-unescaped-entities */
import React, { useMemo } from 'react'
import { Helmet } from 'react-helmet'

import { getIsFamylocateDomain } from 'helpers/getIsFamylocateDomain'

import famylocateLogo from 'assets/images/app-logo-famylocate.webp'
import logo from 'assets/images/app-logo.webp'

import { GlobalStyleForCookiePolicy } from './CookiePolicy.styles'

export const CookiePolicy: React.FC = () => {
  const { appLogo, appTitle, appLink, supportLink, supportLinkText } =
    useMemo(() => {
      const isFamylocateDomain = getIsFamylocateDomain()

      if (isFamylocateDomain) {
        return {
          appLogo: famylocateLogo,
          appTitle: 'Famylocate',
          appLink: 'https://famylocate.app',
          supportLink: 'mailto:support@gismart.com',
          supportLinkText: 'support@gismart.com',
        }
      }

      return {
        appLogo: logo,
        appTitle: 'Famio',
        appLink: 'https://myfamio.com/',
        supportLink: 'https://account.myfamio.com/contact-form',
        supportLinkText: 'https://account.myfamio.com/contact-form',
      }
    }, [])

  return (
    <>
      <Helmet>
        <title>{appTitle} | Cookie Policy</title>
      </Helmet>
      <GlobalStyleForCookiePolicy />
      <header className="header">
        <section className="header__container container">
          <div className="logo">
            <img className="logo__img" src={appLogo} alt={appTitle} />
          </div>
          <h3 className="header-title">{appTitle}</h3>
        </section>
      </header>
      <main>
        <article className="article container">
          <h1 className="article__title">Cookie Policy</h1>
          <h4 className="article__date">LAST UPDATED: August 28, 2024</h4>
          <section>
            <p className="mb-10">
              Our Platforms mean the website(s), including but not limited to:{' '}
              <a href={appLink}>{appLink}</a>, (the “<strong>Site</strong>”);
              mobile applications (means applications and games (each an “
              <strong>App</strong>”, collectively “<strong>Apps</strong>”), all
              together is referring to the “<strong>Platforms</strong>”. Any
              related services; any images, logos, music, photographs and video
              content, software, designs, graphics, photos, images,
              illustrations, animations, videos, scripts, texts, music, sounds,
              voiceover, interactive features, wellness plans, and all other
              materials and content accessible within the Apps or Site that are
              incorporated into and form part of our Apps etc. are managed, and
              operated by Us (“Company”, “We”, “Our”, or “Us”).
            </p>
          </section>
          <section>
            <h2>WHAT ARE COOKIES?</h2>
            <p>
              Cookies are tiny text files stored in your web browser. They help
              us or third parties recognize you. These files save and retrieve
              identifiers and other important details on computers, phones, and
              other devices. Cookies can collect, store, and share bits of
              information, like IP addresses, about what you do online,
              including on our Platforms.
            </p>
          </section>
          <section>
            <h2>WHY DO WE USE COOKIES?</h2>
            <p className="mb-10">
              We use cookies to enhance your experience on our Site and improve
              its functionality. While cookies aren't absolutely necessary, they
              make using our Site much easier. They do a few different things:
            </p>
            <ul>
              <li className="mb-10">
                remember things you've chosen, like if you want to see a certain
                message or how many times you want to see it;
              </li>
              <li className="mb-10">
                keep track of what you do on the Site so it can work better for
                you;
              </li>
              <li className="mb-10">
                gather general information without identifying individuals to
                help make the Site work better overall.
              </li>
            </ul>
            <p>
              When using our Platforms, we ask for your consent to the use of
              cookies (as defined) in accordance with the Privacy Policy. The
              consent provided is voluntary and can be withdrawn at any time.
            </p>
          </section>
          <section>
            <h2>TYPES OF COOKIES WE USE</h2>
            <p className="mb-10">
              We make use of both session cookies and persistent cookies on our
              Platforms:
            </p>
            <p className="mb-10">
              <strong>Session cookies</strong>: these are used to identify a
              specific visit to our Platforms and expire after a short period or
              when you close your web browser. They help recognize you during a
              single browsing session and are automatically deleted when you
              close your browser.
            </p>
            <p className="mb-10">
              <strong>Persistent cookies</strong>: these remain on your devices
              for a predetermined period, even after you close your browser,
              e.g., we employ persistent cookies to keep you logged in to our
              Platforms.
            </p>
          </section>
          <section>
            <h2>WHAT KINDS OF COOKIES ARE ON OUR PLATFORMS?</h2>
            <p className="mb-10">
              Here's a detailed description of the specific types of first and
              third-party cookies used on our Platforms and their respective
              purposes:
            </p>
            <p className="mb-10">
              <strong>Strictly necessary cookies:</strong>
            </p>
            <ul>
              <li className="mb-10">
                essential for the Platforms to operate and cannot be disabled in
                our systems;
              </li>
              <li className="mb-10">
                typically activated in response to actions you take, such as
                setting privacy preferences, logging in, or completing forms;
              </li>
              <li className="mb-10">
                while you can configure your browser to block or notify you
                about these cookies, certain parts of the Platforms may not
                function properly if these cookies are disabled;
              </li>
              <li className="mb-10">
                do not store any personally identifiable information.
              </li>
            </ul>
            <p className="mb-10">
              <strong>Functional cookies:</strong>
            </p>
            <ul>
              <li className="mb-10">
                enhance the functionality and personalization of the Platforms;
              </li>
              <li className="mb-10">
                may be set by us or third-party providers whose services we
                integrate into our Platforms;
              </li>
              <li className="mb-10">
                disabling them may result in some or all of these services not
                working correctly.
              </li>
            </ul>
            <p className="mb-10">
              <strong>Analytical and performance cookies:</strong>
            </p>
            <ul>
              <li className="mb-10">
                enable us to track visits and traffic sources to measure and
                enhance the performance of our Platforms;
              </li>
              <li className="mb-10">
                may be set by us or third-party vendors to help us identify the
                most and least popular pages and understand how users navigate
                the Platforms;
              </li>
              <li className="mb-10">
                all data collected by these cookies is aggregated and anonymous;
              </li>
              <li className="mb-10">
                if you opt out of these cookies, we will not be able to track
                your visits to our Platforms or monitor your performance.
              </li>
            </ul>
            <p className="mb-10">
              <strong>Advertising and targeting cookies:</strong>
            </p>
            <ul>
              <li className="mb-10">
                may be set by our Platforms' advertising partners;
              </li>
              <li className="mb-10">
                enable our advertising partners to create a profile of your
                interests and display relevant advertisements on other websites;
              </li>
              <li className="mb-10">
                while these cookies do not store personal data directly, they
                use unique identifiers to identify your browser and internet
                device;
              </li>
              <li className="mb-10">
                disabling these cookies may result in less personalized
                advertising being displayed to you.
              </li>
            </ul>
          </section>
          <section>
            <h2>YOUR COOKIE PREFERENCES MANAGEMENT</h2>
            <p className="mb-10">
              You have the option to modify your cookie preferences at any time,
              including withdrawing consent for non-essential cookies, by
              clicking on the cookie settings banner.
            </p>
            <p className="mb-10">
              If you have previously consented to third-party cookies, you won't
              be able to disable them using our cookie settings. To change your
              preferences regarding optional cookies, including those from third
              parties, or if you wish to decline certain types of cookies,
              you'll need to delete your cookies via your internet browser
              settings. Many internet browsers allow you to perform this action
              while browsing.
            </p>
            <p className="mb-10">
              For more detailed instructions on how to delete or block cookies,
              please click <a href="https://www.aboutcookies.org/">here</a>.
            </p>
            <p className="mb-10">
              You can also find helpful information on managing cookies from
              browser manufacturers like:
            </p>
            <p className="mb-10">
              <strong>Firefox</strong>: To learn more about "Enhanced Tracking
              Protection in Firefox for desktop" and managing cookie settings,
              click{' '}
              <a href="https://support.mozilla.org/en-US/kb/enhanced-tracking-protection-firefox-desktop?redirectslug=enable-and-disable-cookies-website-preferences&redirectlocale=en-US">
                here
              </a>
              .
            </p>
            <p className="mb-10">
              <strong>Chrome</strong>: For information on "Delete, allow and
              manage cookies in Chrome " mode and managing cookie settings,
              click{' '}
              <a href="https://support.google.com/chrome/answer/95647">here</a>.
            </p>
            <p className="mb-10">
              <strong>Internet Explorer</strong>: To find out more about "Delete
              and manage cookies" browsing and managing cookie settings, click{' '}
              <a href="https://support.microsoft.com/en-gb/windows/manage-cookies-in-microsoft-edge-view-allow-block-delete-and-use-168dab11-0753-043d-7c16-ede5947fc64d">
                here
              </a>
              .
            </p>
            <p className="mb-10">
              <strong>Safari</strong>: Click{' '}
              <a href="https://support.apple.com/en-is/guide/safari/sfri11471/17.0/mac/14.0">
                here
              </a>{' '}
              to discover more about "Cookies in Safari on Mac" and managing
              cookie settings. For mobile version, please click{' '}
              <a href="https://support.apple.com/en-us/105082">here</a>.
            </p>
            <p className="mb-10">
              <strong>Google Analytics</strong>: To opt out of being tracked by
              Google Analytics across all websites, click{' '}
              <a href="https://tools.google.com/dlpage/gaoptout?hl=en">here</a>.
            </p>
            <p>
              Please note, that if you delete or reject cookies, you might not
              be able to access all our features, save your preferences, or view
              certain pages properly.
            </p>
          </section>
          <section>
            <h2>HOW CAN I LEARN MORE ABOUT COOKIES?</h2>
            <p className="mb-10">
              You can learn more about cookies and the following third-party
              websites:
            </p>
            <ul>
              <li className="mb-10">
                <strong>AllAboutCookies:</strong> click{' '}
                <a href="https://allaboutcookies.org/">here</a> to discover more
                about
              </li>
              <li className="mb-10">
                <strong>Network Advertising Initiative:</strong> to find out
                more click <a href="https://thenai.org/">here</a>
              </li>
            </ul>
          </section>
          <section>
            <h2>CHANGES TO THIS POLICY</h2>
            <p className="mb-10">
              We reserve the right to amend this Cookie Policy from time to time
              to reflect changes in the law, our cookie collection, the features
              of our Platforms, or advances in technology.
            </p>
            <p>
              Please check this page periodically for changes and refer to the
              “last updated” date at the top of the page to know if it has been
              revised since your last visit. If we make any changes to this
              Cookie Policy that we consider to be material to your consent, we
              will notify you of them.
            </p>
          </section>
          <section>
            <h2>CONTACT US</h2>
            <p>
              If you have any questions or comments about this Cookie Policy,
              please email <a href={supportLink}>{supportLinkText}</a>.
            </p>
          </section>
        </article>
      </main>
      <footer className="footer">
        <p className="footer__content">© 2024 All Rights Reserved.</p>
      </footer>
    </>
  )
}
