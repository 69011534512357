import { useLayoutEffect } from 'react'
import { useDispatch } from 'react-redux'

import {
  getSubscriptionListAction,
  setScreenNameAction,
} from 'root-redux/actions/common'

import { ScreenName } from 'services/eventLogger.service'

import { SubscriptionListType } from 'root-constants/common'

export const useGetPrices = ({
  tags,
  screenName = ScreenName.ONBOARDING,
  subscriptionType = SubscriptionListType.PURCHASE,
}: {
  tags: string
  screenName?: ScreenName
  subscriptionType?: SubscriptionListType
}): void => {
  const dispatch = useDispatch()

  useLayoutEffect(() => {
    dispatch(setScreenNameAction(screenName))
    dispatch(
      getSubscriptionListAction({
        tags,
        subscriptionType,
      }),
    )
  }, [dispatch, screenName, subscriptionType, tags])
}
