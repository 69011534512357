import React from 'react'
import { Trans, useTranslation } from 'react-i18next'

import { useNextStep } from 'hooks/useNextStep'

import { TPageProps } from 'models/common.model'

import { Button } from 'components/Button'
import { Security } from 'components/Security'

import pairImage from 'assets/images/intro-google1-pair.jpg'

import { StyledIntroGoogleVariant1 as S } from './IntroGoogleVariant1.styles'
import { BENEFITS_DATA, PAGE_NAME } from './constants'

export const IntroGoogleVariant1: React.FC<TPageProps> = ({
  pageId,
  nextPagePath,
}) => {
  const { t } = useTranslation()

  const handleContinue = useNextStep({
    pageId,
    pageName: PAGE_NAME,
    question: '',
    nextPagePath,
  })

  return (
    <S.Wrapper>
      <S.BurgerMenu />
      <S.Column>
        <S.Title>
          <Trans i18nKey="onboarding.introGoogle1.title" />
        </S.Title>
      </S.Column>
      <S.Image src={pairImage} alt="pair" />

      <S.ButtonContainer>
        <Button type="button" onClick={() => handleContinue('')}>
          {t`actions.tryNow`}
        </Button>
      </S.ButtonContainer>

      {BENEFITS_DATA.map(({ title, subtitle, bgImage }) => (
        <S.Card key={title}>
          <S.CardContent bgImage={bgImage}>
            <S.CardTitle>
              <Trans i18nKey={title} />
            </S.CardTitle>
            <S.CardSubtitle>{t(subtitle)}</S.CardSubtitle>
          </S.CardContent>
          <Button type="button" onClick={() => handleContinue('')}>
            {t`actions.tryNow`}
          </Button>
        </S.Card>
      ))}

      <S.Column>
        <Security />
      </S.Column>
    </S.Wrapper>
  )
}
