import styled from 'styled-components'

import { BASE_COLUMN_WIDTH } from 'root-constants/common'

type TProps = {
  headerBorder?: string
}
export const StyledHeader = {
  HeaderWrapper: styled.div<TProps>`
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    border-bottom: ${({ headerBorder, theme }) =>
      headerBorder || theme.common.headerBorder};
  `,
  Header: styled.header`
    width: ${BASE_COLUMN_WIDTH}px;
    padding: ${({ theme }) => theme.common.headerPadding};
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
  `,
}
