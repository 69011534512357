import React from 'react'

import { useTheme } from 'styled-components'

import { useGetFlowInfo } from 'hooks/useGetFlowInfo'
import { useGetPageInfo } from 'hooks/useGetPageInfo'

import { getIsMobileDevice } from 'helpers/getIsMobileDevice'

import { ProgressBar } from '../ProgressBar'
import { StyledHeader as S } from './Header.styles'

type TProps = {
  headerImg?: string
  headerBorder?: string
}
export const Header: React.FC<TProps> = ({ headerImg, headerBorder }) => {
  const theme = useTheme()

  const isMobile = getIsMobileDevice()
  const { hasHeader } = useGetPageInfo()
  const { hasDesktopVersion } = useGetFlowInfo()

  if (!hasHeader || (hasDesktopVersion && !isMobile)) return null

  return (
    <S.HeaderWrapper headerBorder={headerBorder}>
      <S.Header>
        <img
          height={theme.dimensions.headerLogoHeight}
          src={headerImg || theme.images.headerLogo}
          alt="logo"
        />
      </S.Header>
      <ProgressBar />
    </S.HeaderWrapper>
  )
}
