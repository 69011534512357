import React, { useEffect, useLayoutEffect } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'

import { useGetPageInfo } from 'hooks/useGetPageInfo'
import { useGetPrices } from 'hooks/useGetPrices'
import { usePaywall } from 'hooks/usePaywall'
import { usePurchaseStore } from 'hooks/usePurchaseStore'
import { useViewportValue } from 'hooks/useViewportValue'

import { createProductId } from 'helpers/createProductId'

import { TPageProps } from 'models/common.model'

import { ScreenName, eventLogger } from 'services/eventLogger.service'

import { HowItWorksVariant1 } from 'modules/subscriptions/components/common/HowItWorks'
import {
  BenefitsVariant1,
  BenefitsVariant5,
} from 'modules/subscriptions/components/google/Benefits'
import { GOOGLE_6_BENEFITS_PEACE_DATA } from 'modules/subscriptions/components/google/Benefits/constants'
import { GoodHands } from 'modules/subscriptions/components/google/GoodHands'
import { SubscriptionsBlockVariant1 } from 'modules/subscriptions/components/google/SubscriptionsBlockVariant1'
import { BEENEFITS_V2 } from 'modules/subscriptions/components/google/SubscriptionsBlockVariant1/constants'

import { ReviewsSlider3 } from 'components/ReviewsSlider'
import { Security } from 'components/Security'
import { Spinner } from 'components/Spinner'
import { SwiperSlideVariant1 } from 'components/SwiperSlide'

import leftLeaf from 'assets/images/left-golden-leaf.png'
import rightLeaf from 'assets/images/right-golden-leaf.png'

import { goTo } from 'browser-history'
import { CDN_FOLDER_LINK, Images, SubscriptionTag } from 'root-constants/common'
import { PageId } from 'root-constants/pages'

import { StyledSubscriptionsGoogleVariant6 as S } from './SubscriptionsGoogleVariant6.styles'

export const SubscriptionsGoogleVariant6: React.FC<TPageProps> = ({
  nextPagePath,
}) => {
  const { t } = useTranslation()
  const { search } = useLocation()

  const { hasUserEmail, subscriptions } = usePurchaseStore()
  const { hasPrices } = usePaywall(nextPagePath)
  const { currentPaymentPageId } = useGetPageInfo()
  useGetPrices({ tags: SubscriptionTag.NO_TAX })
  useViewportValue()

  useLayoutEffect(() => {
    if (hasUserEmail) {
      goTo({ pathname: currentPaymentPageId, search })
    }
  }, [search, hasUserEmail, currentPaymentPageId])

  useEffect(() => {
    if (!subscriptions.length) return

    eventLogger.logSalePageShown({
      productIds: subscriptions.map(
        ({ mainPrices: { periodName, periodQuantity, fullPrice } }) =>
          createProductId({
            periodName,
            periodQuantity,
            price: fullPrice,
          }),
      ),
      screenName: ScreenName.ONBOARDING,
    })
  }, [subscriptions])

  const handleContinue = () => {
    goTo({ pathname: PageId.EMAIL_1, search })
  }

  return !hasPrices ? (
    <Spinner />
  ) : (
    <S.Wrapper>
      <S.BurgerMenu />
      <S.Content>
        <S.Column>
          <S.Title>
            {t`subscriptions.subscriptionsGoogle.variant6.title`}
          </S.Title>
          <S.Tracker>
            <img src={leftLeaf} alt="icon" />
            <p>
              <Trans i18nKey="subscriptions.subscriptionsGoogle.variant6.numberOne" />
            </p>
            <img src={rightLeaf} alt="icon" />
          </S.Tracker>
          <BenefitsVariant5 />
        </S.Column>
        <S.Button onClick={handleContinue} type="button" marginBottom={48}>
          {t`actions.continue`}
        </S.Button>
        <S.PhoneImageContainer>
          <img
            src={`${CDN_FOLDER_LINK}${Images.GOOGLE_SUBS_PHONE_ALEX_FAMILY}.jpg`}
            alt="phone"
          />
        </S.PhoneImageContainer>
        <S.Column>
          <GoodHands />
        </S.Column>
        <BenefitsVariant1
          benefits={GOOGLE_6_BENEFITS_PEACE_DATA}
          marginBottom={48}
        />
        <S.Column>
          <S.Subtitle>{t`subscriptions.subscriptionsGoogle.variant6.benefits.allInOne`}</S.Subtitle>
        </S.Column>
        <SwiperSlideVariant1 />
        <S.Column>
          <S.Button type="button" marginBottom={48} onClick={handleContinue}>
            {t`subscriptions.subscriptionsGoogle.variant1.subscribe`}
          </S.Button>
          <S.Subtitle lineHeight={30}>
            {t`subscriptions.subscriptionsGoogle.variant6.hotItWorks`}
          </S.Subtitle>
        </S.Column>
        <HowItWorksVariant1 />
        <S.Column>
          <S.Button type="button" marginBottom={15} onClick={handleContinue}>
            {t`subscriptions.subscriptionsGoogle.variant6.getMyPlan`}
          </S.Button>
        </S.Column>
        <ReviewsSlider3 marginBottom={0} />
        <SubscriptionsBlockVariant1 benefits={BEENEFITS_V2} />
        <S.Column>
          <Security />
        </S.Column>
      </S.Content>
    </S.Wrapper>
  )
}
