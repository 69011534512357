import { PageId } from 'root-constants/pages'

export const PAGE_NAMES = {
  [PageId.LOW_BATTERY_1]: 'Low Battery of partner',
  [PageId.LOW_BATTERY_3]: 'Low Battery of friend',
}

export const TITLE_PATHS = {
  [PageId.LOW_BATTERY_2]: 'onboarding.lowBattery.question',
  [PageId.LOW_BATTERY_3]: 'onboarding.lowBattery.question2',
}
