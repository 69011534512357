import styled from 'styled-components'

import { baseColumnStyles, basePageStyles } from 'common-styles'
import { HEADER_HEIGHT, MediaBreakpoint } from 'root-constants/common'

export const StyledSocialProofVariant10 = {
  Wrapper: styled.div`
    ${basePageStyles};
    padding-top: ${HEADER_HEIGHT}px;
  `,
  ImageContainer: styled.div`
    width: 100%;
    max-width: ${MediaBreakpoint.MAX_PHONE}px;
    aspect-ratio: 375/300;

    @media (min-width: ${MediaBreakpoint.MAX_PHONE}px) {
      max-width: 375px;
    }
  `,
  Column: styled.div`
    ${baseColumnStyles};
    padding-bottom: 32px;

    @media (min-height: 550px) {
      position: fixed;
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);
    }
  `,
  Percents: styled.p`
    margin-bottom: 10px;
    text-align: center;
    font-size: 52px;
    font-weight: 800;
    line-height: 71px;
  `,
  Text: styled.p`
    margin-bottom: 60px;
    text-align: center;
    font-size: 18px;
    font-weight: 600;
    line-height: 25px;
  `,
}
