import React, { useRef, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { selectLanguage } from 'root-redux/selects/common'

import { useViewportValue } from 'hooks/useViewportValue'

import { TPageProps } from 'models/common.model'

import {
  HOW_IT_WORKS_DATA,
  POSSIBILITIES_DATA,
  RATING_DATA,
  SECURE_DATA,
  TECHNOLOGIES_DATA,
  WRITE_ABOUT_US_DATA,
} from 'pages/intro/constants'

import { Button } from 'components/Button'
import { DynamicDiscountBanner } from 'components/DynamicDiscountBanner'
import { PrivacyPolicyLink } from 'components/PrivacyPolicyLink'
import { ReviewsSlider2 } from 'components/ReviewsSlider'
import { REVIEWS_DATA_SLIDER_VARIANT_4 } from 'components/ReviewsSlider/constants'
import { TermsOfUseLink } from 'components/TermsOfUseLink'

import leftLeaf from 'assets/images/left-golden-leaf.png'
import quotes from 'assets/images/quotes.png'
import rightLeaf from 'assets/images/right-golden-leaf.png'
import theVergeLogo from 'assets/images/the-verge-logo.png'

import { CDN_FOLDER_LINK, Images, Language } from 'root-constants/common'
import { CustomPageId, PageId } from 'root-constants/pages'

import { StyledIntroVariant8 as S } from './IntroVariant8.styles'

export const IntroVariant8: React.FC<TPageProps> = () => {
  const { t } = useTranslation()
  const language = useSelector(selectLanguage)
  const [phoneNumber, setPhoneNumber] = useState('')
  const scrollToElement = useRef<HTMLParagraphElement>(null)

  useViewportValue()

  const handleScroll = () => {
    scrollToElement.current?.scrollIntoView({
      block: 'start',
      behavior: 'smooth',
    })
  }

  return (
    <S.Wrapper>
      <S.BurgerMenu />
      <DynamicDiscountBanner />
      <S.ImageContainer
        image={`${CDN_FOLDER_LINK}${Images.GOOGLE_SUBS_PHONE_LOCATION}.png`}
      >
        <S.ContentContainer>
          <img src={leftLeaf} alt="leaf" />
          <S.TextWrapper>
            <S.UsersCount>{t`onboarding.intro8.users`}</S.UsersCount>
            <S.Text ref={scrollToElement}>{t`onboarding.intro8.trust`}</S.Text>
          </S.TextWrapper>
          <img src={rightLeaf} alt="leaf" />
        </S.ContentContainer>
      </S.ImageContainer>
      <S.Content>
        <S.Column>
          <S.Title>
            <Trans i18nKey="onboarding.intro8.title" />
          </S.Title>
          <S.Subtitle>{t`onboarding.intro8.subtitle`}</S.Subtitle>
          <S.PhoneForm
            buttonText="onboarding.introGoogle4.phoneButton"
            pageId={CustomPageId.PHONE_NUMBER}
            phoneNumber={phoneNumber}
            setPhoneNumber={setPhoneNumber}
            pathname={PageId.LOCATION_SEARCH_2}
          />
        </S.Column>
        <S.SecureContainer>
          {SECURE_DATA.map(({ img, text }) => (
            <S.SecureWrapper key={text}>
              <img src={img} alt="icon" />
              <S.SecureText>{t(text)}</S.SecureText>
            </S.SecureWrapper>
          ))}
        </S.SecureContainer>
        <S.Column>
          <S.BlockTitle>{t`onboarding.intro8.youGet`}</S.BlockTitle>
          <S.PossibilitiesContainer isEnLanguage={language === Language.EN}>
            {POSSIBILITIES_DATA.map(({ img, text }) => (
              <S.PossibilitiesWrapper
                key={text}
                isEnLanguage={language === Language.EN}
              >
                <img
                  src={
                    language === Language.DE
                      ? `${img}_${Language.EN}.png`
                      : `${img}_${language}.png`
                  }
                  alt="icon"
                />
                <S.PossibilitiesText>
                  <Trans i18nKey={text} />
                </S.PossibilitiesText>
              </S.PossibilitiesWrapper>
            ))}
          </S.PossibilitiesContainer>
          <S.BlockTitle>{t`onboarding.intro8.technologies.title`}</S.BlockTitle>
          <S.TechnologiesContainer>
            {TECHNOLOGIES_DATA.map(({ img, text }) => (
              <S.TechnologiesWrapper key={text}>
                <img src={img} alt="icon" />
                <S.TechnologiesText>{t(text)}</S.TechnologiesText>
              </S.TechnologiesWrapper>
            ))}
          </S.TechnologiesContainer>
          <Button type="button" onClick={handleScroll}>
            {t`onboarding.intro8.tryFamio`}
          </Button>
          <S.BlockTitle marginTop={48}>
            {t`onboarding.intro8.theyWrite`}
          </S.BlockTitle>
          <S.QuotesContainer>
            <img src={quotes} alt="quotes" />
            <S.QuotesText>
              <Trans i18nKey="onboarding.intro8.theVerge" />
            </S.QuotesText>
            <img src={theVergeLogo} alt="logo" />
          </S.QuotesContainer>
          <S.LogosWrapper>
            {WRITE_ABOUT_US_DATA.map((logo) => (
              <img src={logo} alt="logo" key={logo} />
            ))}
          </S.LogosWrapper>
          <S.BlockTitle>{t`onboarding.intro8.howItWorks.title`}</S.BlockTitle>
          <S.HowItWorksContainer isEnLanguage={language === Language.EN}>
            <img
              src={`${CDN_FOLDER_LINK}${Images.STEPS}_${language}.png`}
              alt="steps"
            />
            <S.HowItWorksData>
              {HOW_IT_WORKS_DATA.map(({ title, text }) => (
                <div key={title}>
                  <S.HowItWorksTitle>{t(title)}</S.HowItWorksTitle>
                  <S.HowItWorksText>{t(text)}</S.HowItWorksText>
                </div>
              ))}
            </S.HowItWorksData>
          </S.HowItWorksContainer>
          <Button type="button" onClick={handleScroll}>
            {t`onboarding.intro8.tryFamio`}
          </Button>
          <S.ContentContainer paddingTop={48}>
            <img src={leftLeaf} alt="leaf" />
            <S.TextWrapper>
              <S.UsersCount>{t`onboarding.intro8.users`}</S.UsersCount>
              <S.Text>{t`onboarding.intro8.trust`}</S.Text>
            </S.TextWrapper>
            <img src={rightLeaf} alt="leaf" />
          </S.ContentContainer>
          <S.RatingContainer>
            {RATING_DATA.map(({ logo, name, score, ratingImg, rating }) => (
              <S.RatingWrapper key={name}>
                <S.LogoWrapper>
                  <img src={logo} alt="logo" />
                  <p>{t(name)}</p>
                </S.LogoWrapper>
                <S.Rating>{t(score)}</S.Rating>
                <S.Stars src={ratingImg} alt="rating" />
                <S.RatingQuantity>{t(rating)}</S.RatingQuantity>
              </S.RatingWrapper>
            ))}
          </S.RatingContainer>
          <ReviewsSlider2
            marginBottom={48}
            reviews={REVIEWS_DATA_SLIDER_VARIANT_4}
          />
          <S.Title>
            <Trans i18nKey="onboarding.intro8.title" />
          </S.Title>
          <S.Subtitle>{t`onboarding.intro8.subtitle`}</S.Subtitle>
          <S.PhoneForm
            buttonText="onboarding.introGoogle4.phoneButton"
            pageId={CustomPageId.PHONE_NUMBER}
            phoneNumber={phoneNumber}
            setPhoneNumber={setPhoneNumber}
            pathname={PageId.LOCATION_SEARCH_2}
          />
        </S.Column>
        <S.SecureContainer>
          {SECURE_DATA.map(({ img, text }) => (
            <S.SecureWrapper key={text}>
              <img src={img} alt="icon" />
              <S.SecureText>{t(text)}</S.SecureText>
            </S.SecureWrapper>
          ))}
        </S.SecureContainer>
      </S.Content>
      <S.Footer>
        <S.Copyright>
          <Trans i18nKey="onboarding.intro8.copyright" />
        </S.Copyright>
        <S.LinkWrapper>
          <PrivacyPolicyLink />
          <TermsOfUseLink />
        </S.LinkWrapper>
      </S.Footer>
    </S.Wrapper>
  )
}
