import React, { useMemo } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { selectDynamicDiscount } from 'root-redux/selects/common'

import { usePurchaseStore } from 'hooks/usePurchaseStore'

import { getPriceBeforeDiscount } from 'helpers/getPriceBeforeDiscount'

import { PaymentRequestButton } from 'modules/payment/components/PaymentRequestButton'
import { COHORTS_WITH_DISCLAIMER } from 'modules/payment/pages/PaymentVariant3/constants'

import { PhoneNumberLabel } from 'components/PhoneNumberLabel'
import { TermsOfUseLink } from 'components/TermsOfUseLink'

import paymentServicesImage from 'assets/images/payment-services.png'

import {
  CURRENCY_SYMBOLS,
  Cohort,
  DEFAULT_DYNAMIC_DISCOUNT_AMOUNT,
  TRIAL_IN_HOURS,
  TimeInterval,
  TrialPeriod,
} from 'root-constants/common'

import { StyledPaymentFlowsGoogleVariant5 as S } from './PaymentFlowsGoogleVariant5.styles'

export const PaymentFlowsGoogleVariant5: React.FC = () => {
  const { t } = useTranslation()
  const dynamicDiscount = useSelector(selectDynamicDiscount)

  const {
    cohortToUse,
    selectedSubscriptionId,
    currentPrice,
    currency,
    trialPrice,
    trialPeriodDays,
  } = usePurchaseStore()

  const hasDisclaimer = useMemo(
    () => COHORTS_WITH_DISCLAIMER.includes(cohortToUse),
    [cohortToUse],
  )

  const textParameters = useMemo(() => {
    return trialPeriodDays >= TrialPeriod.THREE_DAYS
      ? {
          period: trialPeriodDays,
          context: TimeInterval.DAY,
        }
      : {
          period: TRIAL_IN_HOURS[trialPeriodDays],
        }
  }, [trialPeriodDays])

  const isSixteenCohort = useMemo(
    () => cohortToUse === Cohort.GOOGLE_16,
    [cohortToUse],
  )

  const oldPrice = useMemo(() => {
    return getPriceBeforeDiscount(
      dynamicDiscount?.amount || DEFAULT_DYNAMIC_DISCOUNT_AMOUNT,
      trialPrice,
    )
  }, [dynamicDiscount?.amount, trialPrice])

  const discountAmount = useMemo(
    () => Number(oldPrice) - trialPrice,
    [oldPrice, trialPrice],
  )

  return (
    <S.Wrapper>
      {!isSixteenCohort && <PhoneNumberLabel />}
      {dynamicDiscount ? (
        <S.PriceContainerV2>
          <S.PaymentContainer>
            <S.PaymentText>{t`payment.googlePayment`}</S.PaymentText>
            <S.PaymentText>
              {t('payment.totalPrice', {
                price: oldPrice,
                currencySymbol: CURRENCY_SYMBOLS[currency],
              })}
            </S.PaymentText>
          </S.PaymentContainer>
          <S.PaymentContainer>
            <S.PaymentDiscountText>
              {t('payment.variant5.discountBlock.discountText', {
                discount: dynamicDiscount?.amount,
                text: dynamicDiscount?.checkout,
              })}
            </S.PaymentDiscountText>
            <S.PaymentText color="#fc5774">
              -
              {t('payment.totalPrice', {
                price: discountAmount,
                currencySymbol: CURRENCY_SYMBOLS[currency],
              })}
            </S.PaymentText>
          </S.PaymentContainer>
          <S.PaymentContainer>
            <S.PaymentText>{t`payment.totalV2`}</S.PaymentText>
            <S.PaymentText>
              {t('payment.totalPrice', {
                price: trialPrice,
                currencySymbol: CURRENCY_SYMBOLS[currency],
              })}
            </S.PaymentText>
          </S.PaymentContainer>
          <S.TrialDurationTextV2>
            {t('payment.trial', textParameters)}
          </S.TrialDurationTextV2>
        </S.PriceContainerV2>
      ) : (
        <S.PriceContainer>
          <S.PriceText gridArea="priceText">{t`payment.googlePayment`}</S.PriceText>
          <S.PriceValue gridArea="priceValue">
            {t('payment.totalPrice', {
              price: trialPrice,
              currencySymbol: CURRENCY_SYMBOLS[currency],
            })}
          </S.PriceValue>
          <S.TrialDurationText>
            {t('payment.trial', textParameters)}
          </S.TrialDurationText>
        </S.PriceContainer>
      )}
      {hasDisclaimer && (
        <S.Disclaimer>
          {t('payment.googleFlowDisclaimer', {
            period: TRIAL_IN_HOURS[trialPeriodDays],
            trialPeriodDays,
            currencySymbol: CURRENCY_SYMBOLS[currency],
            price: currentPrice,
            context:
              trialPeriodDays > TrialPeriod.THREE_DAYS
                ? TimeInterval.WEEK
                : TimeInterval.DAY,
          })}
        </S.Disclaimer>
      )}

      <S.StripePaymentForm />
      <PaymentRequestButton isDividerVisible key={selectedSubscriptionId} />
      <S.Image
        src={paymentServicesImage}
        decoding="async"
        alt="payments services"
      />
      <S.Guarantee>{t`subscriptions.guarantee.title`}</S.Guarantee>
      <S.BottomDisclaimer>
        <Trans
          i18nKey="payment.descriptionWithLink"
          components={{ a: <TermsOfUseLink /> }}
        />
      </S.BottomDisclaimer>
    </S.Wrapper>
  )
}
