import styled from 'styled-components'

import { Button } from 'components/Button'
import { StyledOption } from 'components/Option/Option.styles'
import { QuestionButton } from 'components/QuestionButton'

import { baseColumnStyles, basePageStyles } from 'common-styles'
import { Color } from 'root-constants/common'

type TImageContainerProps = {
  imageSrc: string
}

export const StyledDynamicIntroMultipleChoice = {
  Wrapper: styled.div`
    ${basePageStyles};
    width: 100%;
  `,
  ImageContainer: styled.div<TImageContainerProps>`
    width: 100%;
    min-height: 700px;
    padding-top: 15px;
    background-image: url(${({ imageSrc }) => imageSrc});
    background-size: 100% auto;
    background-repeat: no-repeat;
  `,
  FixedContainer: styled.div`
    position: fixed;
    bottom: 0;
    width: 100%;
    padding: 60px 0 0;
    background-image: linear-gradient(
      180deg,
      rgba(255, 255, 255, 0.1) 10%,
      #ffffff 16%
    );
    background-size: cover;
    background-repeat: no-repeat;
    background-position: bottom center;

    @media (min-height: 550px) {
      padding: 80px 0 18px;
    }
  `,
  Column: styled.div`
    ${baseColumnStyles};
    text-align: center;
    margin: 0 auto;
  `,
  Title: styled.h1`
    margin: 0 auto;
    font-weight: 800;
    font-size: 28px;
    line-height: 38px;
    text-align: center;
    max-width: 350px;
  `,
  Question: styled.h2`
    font-weight: 700;
    font-size: 20px;
    line-height: 27px;
    margin-bottom: 8px;
  `,
  Note: styled.span`
    font-size: 13px;
    line-height: 18px;
    margin-bottom: 7px;
    color: #bcc9d0;

    @media (min-height: 670px) {
      margin-bottom: 23px;
    }
  `,
  OptionsContainer: styled(StyledOption.OptionsContainer)`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    gap: 0 16px;
    padding-bottom: 0;

    div {
      width: 147px;
      flex-shrink: 0;
    }

    @media (min-height: 670px) {
      gap: 11px 16px;
      padding-bottom: 16px;
    }
  `,
  QuestionButton: styled(QuestionButton)`
    height: 54px;
    justify-content: center;
    border-radius: 30px;
    border: 3px solid transparent;

    input:checked + & {
      border: 3px solid ${Color.ACCENT_VIOLET};
      background-color: ${Color.GREY};
      color: ${Color.DEFAULT_TEXT};
    }
  `,
  Button: styled(Button)`
    margin-bottom: 17px;
  `,
}
