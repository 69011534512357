import React from 'react'
import { useTranslation } from 'react-i18next'

import { useDynamicPaywallConfig } from 'hooks/useDynamicPaywallConfig'
import { useGetPageInfo } from 'hooks/useGetPageInfo'

import { COMMON_BENEFITS } from 'modules/subscriptions/components/benefits/constants'

import { StyledBenefits as S } from './Benefits.styles'

type TProps = {
  title?: string
  checkboxIcon?: string
}

export const BenefitsVariant2: React.FC<TProps> = ({ title, checkboxIcon }) => {
  const { t } = useTranslation()
  const { currentPageId } = useGetPageInfo()
  const { benefitsBlockTitle, benefits } = useDynamicPaywallConfig()

  return (
    <S.Wrapper>
      <S.Title>
        {benefitsBlockTitle || title || t`subscriptions.benefits.title`}
      </S.Title>
      <ul>
        {(benefits?.length ? benefits : COMMON_BENEFITS[currentPageId]).map(
          ({ id, text }) => (
            <S.ListItem key={id} checkboxIcon={checkboxIcon}>
              {benefits?.length ? text : t(text)}
            </S.ListItem>
          ),
        )}
      </ul>
    </S.Wrapper>
  )
}
