import styled from 'styled-components'

import { FixedContainer } from 'components/FixedContainer'

import { basePageStyles } from 'common-styles'
import { HEADER_HEIGHT } from 'root-constants/common'

export const StyledReasonToJoin = {
  Wrapper: styled.div`
    ${basePageStyles};
    padding-top: ${HEADER_HEIGHT}px;
  `,
  Image: styled.img`
    @media (min-width: 460px) {
      width: 460px;
    }
  `,
  Text: styled.p`
    text-align: center;
    font-size: 18px;
    margin-bottom: 24px;

    strong {
      font-weight: 700;
    }
  `,
  Note: styled.p`
    color: #94aab6;
    text-align: center;
    font-size: 13px;
    line-height: 20px;
    margin-bottom: 24px;
  `,
  FixedContainer: styled(FixedContainer)`
    max-width: 375px;
    padding: 77px 14px 32px;
  `,
}
