import { useEffect } from 'react'
import { useSelector } from 'react-redux'

import { selectGoogleAdsConversions } from 'root-redux/selects/common'

import { googleAnalyticsLogger } from 'services/googleAnalytics.service'

export const useGAPageTracker = (): void => {
  const googleAdsConversions = useSelector(selectGoogleAdsConversions)

  useEffect(() => {
    googleAnalyticsLogger.configure(googleAdsConversions)
  }, [googleAdsConversions])
}
