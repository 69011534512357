import { useSelector } from 'react-redux'

import { selectHasInAppOffer, selectLanguage } from 'root-redux/selects/common'
import {
  selectIsDiscountApplied,
  selectIsUpgradeAvailable,
  selectIsUpgradePassed,
  selectUserCountryCode,
  selectUserStatus,
} from 'root-redux/selects/user'

import { CountryCode, Language } from 'root-constants/common'

export const useChipoloUpgradeStatus = () => {
  const userStatus = useSelector(selectUserStatus)
  const isUpgradePassed = useSelector(selectIsUpgradePassed)
  const isUpgradeAvailable = useSelector(selectIsUpgradeAvailable)
  const hasInAppOffer = useSelector(selectHasInAppOffer)
  const countryCode = useSelector(selectUserCountryCode)
  const language = useSelector(selectLanguage)
  const isDiscountApplied = useSelector(selectIsDiscountApplied)

  return (
    userStatus?.hasSubscription &&
    hasInAppOffer &&
    isUpgradeAvailable &&
    !isUpgradePassed &&
    language === Language.EN &&
    !isDiscountApplied &&
    countryCode.toLowerCase() === CountryCode.UNITED_STATES
  )
}
