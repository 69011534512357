import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { useNextStep } from 'hooks/useNextStep'
import { useUserData } from 'hooks/useUserData'

import { TPageProps } from 'models/common.model'

import { Button } from 'components/Button'
import { DesktopProvider } from 'components/DesktopProvider'
import { Option, OptionType } from 'components/Option'
import { StyledOption } from 'components/Option/Option.styles'
import { QuestionButton } from 'components/QuestionButton'

import reasonsMultiple from 'assets/images/reasons-multiple.jpg'

import {
  CheckboxCustomValue,
  SINGLE_GOAL_LENGTH,
  UserGoal,
} from 'root-constants/common'

import { StyledReasons as S } from './Reasons.styles'
import {
  OPTION_VALUES,
  PAGE_NAME_MULTIPLE,
  PAGE_OPTION_VALUES,
  QUESTION,
  QUESTION_MULTIPLE,
  REASON_IMAGES,
  REASON_PAGE_NAMES,
} from './constants'

export const ReasonsVariant1: React.FC<TPageProps> = ({
  pageId,
  nextPagePath,
  alternativePagePath,
}) => {
  const { t } = useTranslation()
  const [answers, setAnswers] = useState<string[]>([])

  const { userGoalsFormatted, singleUserGoal } = useUserData()

  const isMultipleGoal = userGoalsFormatted.length > SINGLE_GOAL_LENGTH

  const { question, imageSrc, pageName } = isMultipleGoal
    ? {
        question: t`onboarding.reasons.questionMulti`,
        imageSrc: reasonsMultiple,
        pageName: PAGE_NAME_MULTIPLE,
      }
    : {
        question: t`onboarding.reasons.question`,
        imageSrc: REASON_IMAGES[singleUserGoal],
        pageName: REASON_PAGE_NAMES[singleUserGoal],
      }

  const allOptionValues = PAGE_OPTION_VALUES[pageId].filter((reason) => {
    if (isMultipleGoal) {
      return (
        reason !== OPTION_VALUES.NOTIFICATION && reason !== OPTION_VALUES.HONEST
      )
    }

    if (singleUserGoal === UserGoal.PARTNER) {
      return (
        reason !== OPTION_VALUES.NOTIFICATION &&
        reason !== OPTION_VALUES.LOST &&
        reason !== OPTION_VALUES.LOCATION
      )
    }

    if (singleUserGoal === UserGoal.KID) {
      return (
        reason !== OPTION_VALUES.HONEST &&
        reason !== OPTION_VALUES.EMERGENCY &&
        reason !== OPTION_VALUES.LOCATION
      )
    }

    return (
      reason !== OPTION_VALUES.HONEST &&
      reason !== OPTION_VALUES.NOTIFICATION &&
      reason !== OPTION_VALUES.LOCATION
    )
  })

  const optionProps = {
    name: pageId,
    type: OptionType.CHECKBOX,
    onChange: (value: string, isChecked: boolean) => {
      if (isChecked) {
        value === CheckboxCustomValue.ALL_OF_THE_ABOVE
          ? setAnswers(allOptionValues)
          : setAnswers([...answers, value])
        return
      }

      value === CheckboxCustomValue.ALL_OF_THE_ABOVE
        ? setAnswers([])
        : setAnswers(answers.filter((item) => item !== value))
    },
  }

  const handleContinue = useNextStep({
    pageId,
    pageName,
    question: isMultipleGoal ? QUESTION_MULTIPLE : QUESTION,
    nextPagePath:
      singleUserGoal === UserGoal.FRIEND ? alternativePagePath : nextPagePath,
  })

  return (
    <DesktopProvider imageSrc={imageSrc}>
      <S.Wrapper>
        <S.Column>
          <S.Title>{question}</S.Title>
          <StyledOption.OptionsContainer paddingBottom={4}>
            <Option
              {...optionProps}
              value={OPTION_VALUES.PEACE}
              checked={answers.includes(OPTION_VALUES.PEACE)}
            >
              <QuestionButton hasCheckboxIcon>
                {t`onboarding.reasons.answers.peace`}
              </QuestionButton>
            </Option>
            <Option
              {...optionProps}
              value={OPTION_VALUES.SAFE}
              checked={answers.includes(OPTION_VALUES.SAFE)}
            >
              <QuestionButton hasCheckboxIcon>
                {t`onboarding.reasons.answers.safe`}
              </QuestionButton>
            </Option>
            {singleUserGoal === UserGoal.PARTNER ? (
              <Option
                {...optionProps}
                value={OPTION_VALUES.HONEST}
                checked={answers.includes(OPTION_VALUES.HONEST)}
              >
                <QuestionButton hasCheckboxIcon>
                  {t`onboarding.reasons.answers.honest`}
                </QuestionButton>
              </Option>
            ) : (
              <Option
                {...optionProps}
                value={OPTION_VALUES.LOST}
                checked={answers.includes(OPTION_VALUES.LOST)}
              >
                <QuestionButton hasCheckboxIcon>
                  {t`onboarding.reasons.answers.lost`}
                </QuestionButton>
              </Option>
            )}
            {singleUserGoal === UserGoal.KID ? (
              <Option
                {...optionProps}
                value={OPTION_VALUES.NOTIFICATION}
                checked={answers.includes(OPTION_VALUES.NOTIFICATION)}
              >
                <QuestionButton hasCheckboxIcon>
                  {t`onboarding.reasons.answers.notification`}
                </QuestionButton>
              </Option>
            ) : (
              <Option
                {...optionProps}
                value={OPTION_VALUES.EMERGENCY}
                checked={answers.includes(OPTION_VALUES.EMERGENCY)}
              >
                <QuestionButton hasCheckboxIcon>
                  {t`onboarding.reasons.answers.emergency`}
                </QuestionButton>
              </Option>
            )}
            {isMultipleGoal && (
              <Option
                {...optionProps}
                value={OPTION_VALUES.LOCATION}
                checked={answers.includes(OPTION_VALUES.LOCATION)}
              >
                <QuestionButton hasCheckboxIcon>
                  {t`onboarding.reasons.answers.location`}
                </QuestionButton>
              </Option>
            )}
            <Option
              {...optionProps}
              value={CheckboxCustomValue.ALL_OF_THE_ABOVE}
              checked={answers.length === allOptionValues.length}
            >
              <QuestionButton hasCheckboxIcon>
                {t`commonComponents.allOfTheAbove`}
              </QuestionButton>
            </Option>
          </StyledOption.OptionsContainer>
          <S.FixedContainer>
            <Button
              onClick={() => handleContinue(answers)}
              disabled={!answers.length}
            >
              {t`actions.next`}
            </Button>
          </S.FixedContainer>
        </S.Column>
      </S.Wrapper>
    </DesktopProvider>
  )
}
