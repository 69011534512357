import styled, { createGlobalStyle } from 'styled-components'

import { PaymentRequestButton } from 'modules/payment/components/PaymentRequestButton'

import { BurgerMenu } from 'components/BurgerMenu'
import { Button } from 'components/Button'

import checkIcon from 'assets/images/check-icon-white-rounded.svg'

import { basePageStyles } from 'common-styles'
import { BASE_COLUMN_WIDTH, Color, HEADER_HEIGHT } from 'root-constants/common'

export const BackgroundGlobalStyles = createGlobalStyle`
  :root {
    --base-background: linear-gradient(172deg, rgba(222, 211, 255, 0.43) -2.32%, rgba(246, 235, 254, 0.47) 25.9%, rgba(219, 241, 253, 0.35) 93.76%), #FFF;
  }
`

type TContainerProps = {
  isVisible: boolean
}

export const StyledPaymentVariant4 = {
  Wrapper: styled.div`
    ${basePageStyles};
    min-height: 100dvh;
    padding-top: ${HEADER_HEIGHT}px;
  `,
  BurgerMenu: styled(BurgerMenu)`
    button {
      top: 6px;
      right: 16px;
    }
  `,
  Content: styled.div`
    width: 100%;
    max-width: 375px;
    padding: 16px 24px 24px;
  `,
  Title: styled.h1`
    margin-bottom: 34px;
    text-align: center;
    font-size: 22px;
    font-weight: 800;
    line-height: 28px;
  `,
  BenefitsContainer: styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    margin-bottom: 24px;
  `,
  BenefitsText: styled.p`
    position: relative;
    max-width: 155px;
    margin-bottom: 16px;
    padding-left: 24px;
    font-size: 15px;
    font-weight: 700;
    line-height: 20px;

    &::before {
      content: '';
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
      width: 16px;
      height: 16px;
      background-color: #7bc27e;
      background-image: url(${checkIcon});
      background-repeat: no-repeat;
      background-position: center;
      background-size: 10px;
      border-radius: 50%;
    }
  `,
  BenefitsImage: styled.img`
    max-width: 201px;
    position: absolute;
    bottom: 0;
    right: -12px;
  `,
  PaymentMethod: styled.div`
    display: flex;
    justify-content: space-between;
    margin-bottom: 16px;
    min-height: 24px;
  `,
  PaymentMethodName: styled.span`
    display: inline-flex;
    align-items: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 18px;

    & svg {
      margin-right: 8px;
    }
  `,
  SwitchPaymentMethod: styled.button`
    position: relative;
    padding-right: 24px;
    all: unset;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 18px;
    cursor: pointer;
    color: ${Color.ACCENT_VIOLET};
    -webkit-tap-highlight-color: rgba(255, 255, 255, 0);

    & svg {
      margin-left: 6px;
    }
  `,
  PaymentRequestButtonContainer: styled.div<TContainerProps>`
    position: relative;
    display: ${({ isVisible }) => (isVisible ? 'block' : 'none')};
    margin-bottom: 16px;
  `,
  Button: styled(Button)`
    pointer-events: none;
  `,
  PaymentRequestButton: styled(PaymentRequestButton)`
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    max-width: ${BASE_COLUMN_WIDTH}px;
    margin-top: 0;
    opacity: 0.000000001;
  `,
  Disclaimer: styled.p`
    font-size: 11px;
    line-height: 18px;
    text-align: center;
    color: ${Color.LIGHT_TEXT};

    a {
      color: inherit;
      font-weight: inherit;
      text-decoration: underline;
      white-space: pre;
    }
  `,
  ThreeDSecureIframe: styled.iframe`
    height: calc(var(--full-height, 100vh) - 50px) !important;
    padding: 15px;
  `,
}
