import React, { useLayoutEffect } from 'react'
import { useTranslation } from 'react-i18next'

import { useNextStep } from 'hooks/common/useNextStep'
import { useDelayedContinue } from 'hooks/ui/useDelayedContinue'
import { useUserData } from 'hooks/user/useUserData'

import { TPageProps } from 'models/common.model'

import { AGE_RANGE_DATA_2 } from 'pages/age-range/constants'

import { OptionType } from 'components/Option'

import { replaceHistory } from 'browser-history'
import { Gender, Language } from 'root-constants/common'
import { CustomPageId } from 'root-constants/pages'

import { StyledAgeRangeVariant3 as S } from './AgeRangeVariant3.styles'

export const AgeRangeVariant3: React.FC<TPageProps> = ({
  pageId,
  nextPagePath,
}) => {
  const { t } = useTranslation()
  const { userGender } = useUserData()
  const { isAnswersDisabled, handleChange, setIsAnswersDisabled } =
    useDelayedContinue()

  const handleContinue = useNextStep({
    pageId: CustomPageId.AGE_RANGE,
    pageName: pageId,
    question: t('onboarding.ageRange.title2', { lng: Language.EN }),
    nextPagePath,
  })

  const optionProps = {
    name: pageId,
    type: OptionType.RADIO,
    onChange: (value: string) => {
      handleChange(value, handleContinue)
      setIsAnswersDisabled(true)
    },
  }

  const shouldBeRedirect = userGender !== Gender.OTHER

  useLayoutEffect(() => {
    if (shouldBeRedirect) {
      replaceHistory(nextPagePath)
    }
  }, [nextPagePath, shouldBeRedirect])

  return (
    <S.Wrapper>
      <S.Column>
        <S.Title>{t`onboarding.ageRange.title2`}</S.Title>
        <S.Subtitle>{t`onboarding.ageRange.subtitle`}</S.Subtitle>
        <S.OptionContainer>
          {AGE_RANGE_DATA_2.map(({ text, value }) => (
            <S.Option
              key={text}
              {...optionProps}
              value={value}
              disabled={isAnswersDisabled}
            >
              <S.QuestionButton>{t(text)}</S.QuestionButton>
            </S.Option>
          ))}
        </S.OptionContainer>
      </S.Column>
    </S.Wrapper>
  )
}
