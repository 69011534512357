import {
  EMAIL_DOMAINS,
  EMAIL_DOMAIN_REGEXP,
  EMAIL_USERNAME_REGEXP,
} from './constants'

export const getFilteredEmailDomains = (emailValue: string): string[] => {
  const [, emailDomain] = EMAIL_DOMAIN_REGEXP.exec(emailValue) || []
  const [userName] = EMAIL_USERNAME_REGEXP.exec(emailValue) || []
  return EMAIL_DOMAINS.filter((domain) => domain.includes(emailDomain)).map(
    (filteredDomain) => `${userName}${filteredDomain}`,
  )
}
