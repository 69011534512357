import React from 'react'
import { useTranslation } from 'react-i18next'

import { STEPS_DATA } from 'modules/subscriptions/constants'

import lineIcon from 'assets/images/long-line.png'

import { StyledStepsVariant1 as S } from './StepsVariant1.styles'

type TProps = {
  stepsData?: {
    title: string
    subtitle: string
    text: string
  }[]
  marginBottom?: number
  height?: number
  image?: string
}
export const StepsVariant1: React.FC<TProps> = ({
  stepsData = STEPS_DATA,
  marginBottom,
  height,
  image,
}) => {
  const { t } = useTranslation()

  return (
    <S.StepsWrapper marginBottom={marginBottom} height={height}>
      <img src={image || lineIcon} alt="icon" />
      <S.StepsContent>
        {stepsData.map(({ title, subtitle, text }) => (
          <div key={title}>
            <S.StepsTitle>{t(title)}</S.StepsTitle>
            <S.StepsSubtitle>{t(subtitle)}</S.StepsSubtitle>
            <S.StepsText>{t(text)}</S.StepsText>
          </div>
        ))}
      </S.StepsContent>
    </S.StepsWrapper>
  )
}
