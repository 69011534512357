import {
  BillingCycle,
  Currency,
  Language,
  TrialPeriod,
} from 'root-constants/common'

export const PERIOD_NAMES = {
  [BillingCycle.ONE_MONTH]: 'subscriptions.planBlock.planPeriods.month',
  [BillingCycle.YEAR]: 'subscriptions.planBlock.planPeriods.year',
}

export const PERIOD_QUANTITIES = {
  [BillingCycle.ONE_MONTH]: 1,
  [BillingCycle.YEAR]: 1,
}

export const INTRO_OFFER_PERIODS = {
  [TrialPeriod.ONE_WEEK]: 1,
  [TrialPeriod.ONE_MONTH]: 1,
  [TrialPeriod.THREE_MONTH]: 3,
}

export const OVERSIZE_CURRENCIES: string[] = [
  Currency.CAD,
  Currency.AUD,
  Currency.NZD,
  Currency.MXN,
  Currency.BRL,
]
export const LEFT_SIDE_CURRENCY_LANGUAGE: string[] = [Language.EN, Language.PT]
