import { composeWithDevTools } from '@redux-devtools/extension'
import {
  Store,
  applyMiddleware,
  legacy_createStore as createStore,
} from 'redux'
import thunk from 'redux-thunk'

import { IAction, IAppState } from 'models/store.model'

import { rootReducer } from './rootReducer'

export type TAppStore = Store<IAppState>

export const store: TAppStore = createStore<IAppState, IAction<any>, any, any>(
  rootReducer,
  composeWithDevTools(applyMiddleware(thunk)),
)
