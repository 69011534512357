import styled from 'styled-components'

import { baseColumnStyles, basePageStyles } from 'common-styles'
import { HEADER_HEIGHT, MediaBreakpoint } from 'root-constants/common'

export const StyledPaymentVariant1 = {
  Wrapper: styled.div`
    ${basePageStyles};
    padding-top: ${HEADER_HEIGHT}px;
    min-height: 100%;
  `,
  Content: styled.div`
    ${baseColumnStyles};

    @media (min-width: ${MediaBreakpoint.LAPTOP}px) {
      width: 100vw;
    }
  `,
  ThreeDSecureIframe: styled.iframe`
    height: calc(var(--full-height, 100vh) - 50px) !important;
    padding: 15px;
  `,
}
