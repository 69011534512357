import locationIcon from 'assets/images/location-icon.png'
import lockIcon from 'assets/images/lock-icon.png'
import presentIcon from 'assets/images/present-icon.png'

export const CHIPOLO_BENEFITS = [
  {
    icon: locationIcon,
    title: 'subscriptions.chipolo.paywall.description.peaceOfMind',
    benefit: 'subscriptions.chipolo.paywall.description.checkLocation',
  },
  {
    icon: lockIcon,
    title: 'subscriptions.chipolo.paywall.description.nothingLost',
    benefit: 'subscriptions.chipolo.paywall.description.findYourThings',
  },
  {
    icon: presentIcon,
    title: 'subscriptions.chipolo.paywall.description.tinyGift',
    benefit: 'subscriptions.chipolo.paywall.description.chipoloFinders',
  },
]
