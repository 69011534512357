import styled from 'styled-components'

import { CloseButton } from 'common-styles'
import { MediaBreakpoint } from 'root-constants/common'

export const StyledPaymentFlows = {
  TitleWrapper: styled.div`
    width: 100%;
    margin: 48px 0 8px;
    position: relative;

    @media (min-width: ${MediaBreakpoint.LAPTOP}px) {
      margin: 134px 0 12px;
    }
  `,
  CloseButton: styled(CloseButton)`
    position: absolute;
    top: -32px;
    right: -16px;

    @media (min-width: ${MediaBreakpoint.LAPTOP}px) {
      top: -75px;
      right: 43px;
    }
  `,
  Title: styled.h3`
    font-size: 24px;
    line-height: 28px;
    font-weight: 800;
    text-align: center;
    color: ${({ theme }) => theme.colors.primaryText};

    @media (min-width: ${MediaBreakpoint.LAPTOP}px) {
      font-size: 32px;
      line-height: 44px;
    }
  `,
  PaymentButtonsContainer: styled.div`
    width: 100%;

    @media (min-width: ${MediaBreakpoint.LAPTOP}px) {
      width: initial;
      display: flex;
      gap: 16px;
    }
  `,
  PaymentServiceImage: styled.img`
    margin: 32px 0 16px;
  `,
  Guarantee: styled.span`
    margin-bottom: 48px;
    font-size: 13px;
    line-height: 20px;
    text-align: center;
    color: ${({ theme }) => theme.colors.guaranteeText};

    @media (min-width: ${MediaBreakpoint.LAPTOP}px) {
      margin-bottom: 13px;
    }
  `,
  Description: styled.span`
    font-size: 11px;
    line-height: 18px;
    text-align: center;
    color: ${({ theme }) => theme.colors.secondaryText};
    margin-bottom: 44px;

    @media (min-width: ${MediaBreakpoint.LAPTOP}px) {
      width: 718px;
      margin: 0 auto 31px;
      font-size: 10px;
      line-height: 14px;
    }
  `,
}
