import React, { useMemo } from 'react'
import { Trans, useTranslation } from 'react-i18next'

import { usePurchaseStore } from 'hooks/usePurchaseStore'
import { useVatInfo } from 'hooks/useVatInfo'

import { getPriceBeforeDiscount } from 'helpers/getPriceBeforeDiscount'
import { getPriceWithoutTaxes } from 'helpers/getPriceWithoutTaxes'

import { ISelectPlanItemProps } from 'models/common.model'

import arrow from 'assets/images/right-arrow.svg'

import {
  CURRENCY_SYMBOLS,
  Currency,
  INTRO_OFFER_PERIODS_WEEKS,
  INTRO_OFFER_WEEKLY_PERIOD_NAMES,
  Language,
} from 'root-constants/common'

import { StyledTrialPlanItemVariant4 as S } from './TrialPlanItemVariant4.styles'

export const TrialPlanItemVariant4: React.FC<ISelectPlanItemProps> = ({
  subscription: {
    isDefault,
    trialPrices: { fullPrice: trialPrice, durationDays, daily, oldPrices },
  },
  isSelected,
  hasLaptopStyles = false,
}) => {
  const { t } = useTranslation()

  const { language, currency, dynamicDiscount, taxAmount } = usePurchaseStore()
  const hasIncludedVat = useVatInfo()
  const priceWithoutTaxes = useMemo(
    () =>
      getPriceWithoutTaxes({
        price: trialPrice,
        taxAmount,
      }),
    [taxAmount, trialPrice],
  )

  const oldPrice = useMemo(() => {
    const actualOldPrice = Number(
      getPriceBeforeDiscount(Number(dynamicDiscount?.amount), trialPrice),
    )

    const actualOldPriceWithoutTaxes = Number(
      getPriceBeforeDiscount(
        Number(dynamicDiscount?.amount),
        Number(priceWithoutTaxes),
      ),
    )

    return hasIncludedVat
      ? actualOldPriceWithoutTaxes
      : actualOldPrice.toFixed(2)
  }, [hasIncludedVat, dynamicDiscount?.amount, trialPrice, priceWithoutTaxes])

  const dailyPriceWithoutTaxes = useMemo(
    () =>
      getPriceWithoutTaxes({
        price: daily,
        taxAmount,
      }),
    [taxAmount, daily],
  )

  const oldDailyPriceWithoutTaxes = useMemo(
    () =>
      Number(
        getPriceBeforeDiscount(
          Number(dynamicDiscount?.amount),
          Number(dailyPriceWithoutTaxes),
        ),
      ),
    [dynamicDiscount?.amount, dailyPriceWithoutTaxes],
  )

  const oldDailyPriceWithoutDiscount = useMemo(
    () =>
      getPriceBeforeDiscount(Number(dynamicDiscount?.amount), Number(daily)),
    [dynamicDiscount?.amount, daily],
  )

  return (
    <S.PlanItem
      data-has-laptop-styles={hasLaptopStyles}
      data-is-selected={isSelected}
      data-is-default={isDefault}
      data-text={t`subscriptions.planBlock.mostPopular`}
      data-is-es-lang={language === Language.ES}
    >
      <S.PlanTitleWrapper>
        <S.PlanTitle>
          <Trans
            i18nKey="subscriptions.planBlock.weeklyPlanTitle"
            values={{
              periodQuantity: INTRO_OFFER_PERIODS_WEEKS[durationDays],
              periodName: t(INTRO_OFFER_WEEKLY_PERIOD_NAMES[durationDays]),
            }}
          />
        </S.PlanTitle>
        <S.PlanPricesWrapper>
          {!!oldPrices.beforeCustomDiscount.fullPrice && (
            <S.OldPlanPrice>
              {t('subscriptions.planBlock.price', {
                price: oldPrice,
                currencySymbol: CURRENCY_SYMBOLS[currency],
                minimumFractionDigits: 2,
              })}
            </S.OldPlanPrice>
          )}
          <img src={arrow} alt="icon" />
          <S.CurrentPrice>
            {t('subscriptions.planBlock.price', {
              price: hasIncludedVat ? priceWithoutTaxes : trialPrice.toFixed(2),
              currencySymbol: CURRENCY_SYMBOLS[currency],
              minimumFractionDigits: 2,
            })}
          </S.CurrentPrice>
        </S.PlanPricesWrapper>
      </S.PlanTitleWrapper>
      <S.PlanPrice
        marginBottom={language === Language.EN ? 10 : 0}
        fontSize={
          currency === Currency.NZD || currency === Currency.AUD ? 18 : 24
        }
      >
        <p>
          {t('subscriptions.planBlock.price', {
            price: hasIncludedVat
              ? oldDailyPriceWithoutTaxes
              : oldDailyPriceWithoutDiscount,
            currencySymbol: CURRENCY_SYMBOLS[currency],
            minimumFractionDigits: 2,
          })}
        </p>
        <strong>
          {t('subscriptions.planBlock.price', {
            price: hasIncludedVat ? dailyPriceWithoutTaxes : daily,
            currencySymbol: CURRENCY_SYMBOLS[currency],
            minimumFractionDigits: 2,
          })}
        </strong>
        <sub>
          {t('subscriptions.planBlock.perPeriod', {
            interval: t('subscriptions.planBlock.perDay'),
          })}
        </sub>
      </S.PlanPrice>
    </S.PlanItem>
  )
}
