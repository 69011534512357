import React from 'react'
import { useTranslation } from 'react-i18next'

import { usePurchaseStore } from 'hooks/usePurchaseStore'

import {
  CURRENCY_SYMBOLS,
  Language,
  PERIOD_NAME_TO_MARKUP_MAP,
} from 'root-constants/common'

import { StyledPlanItemVariant1 as S } from './PlanItem.styles'

export const PlanItemVariant1: React.FC = () => {
  const { t } = useTranslation()

  const {
    currentPrice,
    oldPrice,
    currency,
    periodQuantity,
    periodName,
    language,
  } = usePurchaseStore()

  return (
    <S.PlanItem>
      <S.Column>
        <S.PlanTitle>
          {t('subscriptions.purchaseUpgrade.plan.period', {
            quantity: periodQuantity,
            period: t(PERIOD_NAME_TO_MARKUP_MAP[periodName], {
              count: periodQuantity,
            }),
          })}
        </S.PlanTitle>
        <S.Discount
          maxWidth={language === Language.EN ? 75 : 100}
          lineHeight={language === Language.EN ? 22 : 16}
        >
          {t('subscriptions.purchaseUpgrade.plan.discount', { discount: 50 })}
        </S.Discount>
      </S.Column>
      <S.Column leftAlignment>
        <S.CurrentPrice>
          {t('subscriptions.planBlock.price', {
            price: currentPrice,
            currencySymbol: CURRENCY_SYMBOLS[currency],
            minimumFractionDigits: 2,
          })}
        </S.CurrentPrice>
        <S.OldPrice>
          {t('subscriptions.planBlock.price', {
            price: oldPrice.fullPrice,
            currencySymbol: CURRENCY_SYMBOLS[currency],
            minimumFractionDigits: 2,
          })}
        </S.OldPrice>
      </S.Column>
    </S.PlanItem>
  )
}
