import React, { HTMLAttributes } from 'react'
import { Trans, useTranslation } from 'react-i18next'

import { Swiper, SwiperSlide } from 'swiper/react'

import { StyledReviewsSlider as S } from './ReviewsSlider.styles'
import { REVIEWS_DATA_SLIDER_VARIANT_2 as REVIEWS_DATA } from './constants'

export interface ISliderProps extends HTMLAttributes<HTMLDivElement> {
  marginBottom?: number
  reviews?: {
    avatar: string
    userName: string
    reviewText: string
    borderColor: string
  }[]
}

export const ReviewsSlider2: React.FC<ISliderProps> = ({
  marginBottom = 24,
  reviews = REVIEWS_DATA,
  ...props
}) => {
  const { t } = useTranslation()

  return (
    <S.Wrapper marginBottom={marginBottom} {...props}>
      <Swiper
        slidesPerView="auto"
        spaceBetween={20}
        centeredSlides
        breakpoints={{
          1200: {
            spaceBetween: 12,
            initialSlide: 1,
          },
        }}
      >
        {reviews?.map(({ userName, reviewText, avatar, borderColor }) => (
          <SwiperSlide key={userName}>
            <S.Card borderColor={borderColor}>
              <S.Avatar width="66" height="66" src={avatar} loading="lazy" />
              <S.CardTitle>
                <Trans i18nKey={userName} />
              </S.CardTitle>
              <S.Recommendation
                text={t`subscriptions.reviewsSlider.recommendation`}
              />
              <S.CardText>
                <Trans i18nKey={reviewText} />
              </S.CardText>
            </S.Card>
          </SwiperSlide>
        ))}
      </Swiper>
    </S.Wrapper>
  )
}
