import styled, { css } from 'styled-components'

import { PhoneForm } from 'modules/subscriptions/components/google/PhoneForm'

import { BurgerMenu } from 'components/BurgerMenu'

import { baseColumnStyles, basePageStyles } from 'common-styles'
import { Color, HEADER_HEIGHT, MediaBreakpoint } from 'root-constants/common'

type TImageContainer = {
  image: string
}
type TBlockTitle = {
  marginTop?: number
}
type TContentContainer = {
  paddingTop?: number
}
type TPossibilitiesContainer = {
  isEnLanguage: boolean
}
type THowItWorksContainer = {
  isEnLanguage: boolean
}

const baseTextStyles = css`
  font-size: 16px;
  font-weight: 600;
  line-height: 22px;
  text-align: center;
`
export const StyledIntroVariant8 = {
  Wrapper: styled.div`
    ${basePageStyles};
    padding-top: ${HEADER_HEIGHT}px;
  `,
  BurgerMenu: styled(BurgerMenu)`
    button {
      top: 6px;
      right: 6px;

      @media (min-width: ${MediaBreakpoint.LAPTOP}px) {
        top: 3px;
        right: 3px;
      }
    }
  `,
  Content: styled.div`
    width: 100%;
    max-width: ${MediaBreakpoint.MAX_PHONE}px;
    min-height: calc(100dvh - ${HEADER_HEIGHT}px);
    display: flex;
    flex-direction: column;
    align-items: center;
    background: #f5f5ff;

    @media (min-width: ${MediaBreakpoint.MAX_PHONE}px) {
      max-width: 375px;
    }
  `,
  Column: styled.div`
    ${baseColumnStyles};
  `,
  ImageContainer: styled.div<TImageContainer>`
    width: 100%;
    aspect-ratio: 375/359;
    max-width: ${MediaBreakpoint.MAX_PHONE}px;
    background-image: url(${({ image }) => image});
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;

    @media (min-width: ${MediaBreakpoint.MAX_PHONE}px) {
      max-width: 375px;
    }
  `,
  ContentContainer: styled.div<TContentContainer>`
    display: flex;
    align-items: center;
    max-width: 279px;
    width: 100%;
    gap: 4px;
    margin: 0 auto;
    padding-top: ${({ paddingTop }) => paddingTop || 24}px;

    img {
      width: 33px;
      height: 64px;
    }
  `,
  TextWrapper: styled.div`
    display: flex;
    flex-direction: column;
    gap: 8px;
    align-items: center;
  `,
  UsersCount: styled.h3`
    font-size: 20px;
    font-weight: 800;
    line-height: 22px;
    text-transform: uppercase;
    text-align: center;
  `,
  Text: styled.p`
    ${baseTextStyles};
    color: ${Color.LIGHT_TEXT};
  `,
  Title: styled.h1`
    font-size: 30px;
    font-weight: 800;
    line-height: 38px;
    text-align: center;
    margin-bottom: 12px;
  `,
  Subtitle: styled.p`
    ${baseTextStyles};
    font-weight: 400;
    margin-bottom: 24px;
  `,
  PhoneForm: styled(PhoneForm)`
    button {
      margin-top: 0;
    }
  `,
  SecureContainer: styled.div`
    margin: 24px 0 48px;
    display: flex;
    align-items: center;
    gap: 12px;
  `,
  SecureWrapper: styled.div`
    display: flex;
    padding: 4px 8px 4px 4px;
    align-items: center;
    gap: 4px;
    border-radius: 8px;
    background-color: #e7e6ff;

    img {
      width: 24px;
      height: 24px;
    }
  `,
  SecureText: styled.p`
    font-size: 13px;
    font-weight: 400;
    line-height: 20px;
  `,
  BlockTitle: styled.p<TBlockTitle>`
    font-size: 24px;
    font-weight: 800;
    line-height: 28px;
    text-align: center;
    margin-top: ${({ marginTop }) => marginTop || 0}px;
  `,
  PossibilitiesContainer: styled.div<TPossibilitiesContainer>`
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 15px;
    margin: 24px 0 48px;

    & div:nth-child(n + 7) {
      aspect-ratio: ${({ isEnLanguage }) =>
        isEnLanguage ? '148/178' : '148/188'};
      min-height: ${({ isEnLanguage }) => (isEnLanguage ? 178 : 188)}px;
    }
  `,
  PossibilitiesWrapper: styled.div<TPossibilitiesContainer>`
    max-width: 148px;
    width: 100%;
    padding: 16px 8px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 8px;
    border-radius: 16px;
    background: ${Color.WHITE};
    aspect-ratio: ${({ isEnLanguage }) =>
      isEnLanguage ? '148/159' : '148/178'};

    img {
      width: 132px;
      height: 100px;
    }
  `,
  PossibilitiesText: styled.p`
    font-size: 14px;
    font-weight: 700;
    line-height: 19px;
    text-align: center;
  `,
  TechnologiesContainer: styled.div`
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 12px;
    margin: 24px 0;
  `,
  TechnologiesWrapper: styled.div`
    display: flex;
    align-items: center;
    gap: 16px;
    border-radius: 16px;
    background-color: ${Color.WHITE};
    padding: 12px 16px;
    max-width: 311px;
    width: 100%;

    img {
      width: 40px;
      height: 40px;
    }
  `,
  TechnologiesText: styled.p`
    font-size: 18px;
    font-weight: 700;
    line-height: 25px;
  `,
  QuotesContainer: styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 16px;
    padding: 16px 16px;
    border-radius: 16px;
    background-color: ${Color.WHITE};
    margin: 24px 0;

    img:first-of-type {
      width: 20px;
      height: 20px;
    }

    img {
      width: 60px;
      height: 40px;
    }
  `,
  QuotesText: styled.p`
    ${baseTextStyles};
    font-weight: 400;
    text-align: center;
  `,
  LogosWrapper: styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 48px;
    max-width: 311px;
    width: 100%;

    img {
      max-height: 64px;
    }
  `,
  HowItWorksContainer: styled.div<THowItWorksContainer>`
    display: flex;
    gap: 16px;
    margin: 24px 0;

    img {
      width: 40px;
      height: ${({ isEnLanguage }) => (isEnLanguage ? 279 : 340)}px;
    }
  `,
  HowItWorksData: styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 24px;
  `,
  HowItWorksTitle: styled.p`
    font-size: 18px;
    font-weight: 700;
    line-height: 25px;
    margin-bottom: 4px;
  `,
  HowItWorksText: styled.p`
    font-size: 14px;
    font-weight: 400;
    line-height: 19px;
  `,
  RatingContainer: styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 16px;
    margin: 48px 0;
  `,
  RatingWrapper: styled.div`
    display: flex;
    flex-direction: column;
    gap: 12px;
    align-items: center;
    padding: 16px;
    border-radius: 16px;
    background-color: ${Color.WHITE};
    max-width: 150px;
    min-height: 178px;
  `,
  LogoWrapper: styled.div`
    display: flex;
    align-items: center;
    gap: 6px;
    font-size: 14px;
    font-weight: 700;
    line-height: 20px;

    img {
      width: 24px;
      height: 24px;
    }
  `,
  Rating: styled.p`
    ${baseTextStyles};
    font-weight: 500;
  `,
  Stars: styled.img`
    width: 116px;
    height: 20px;
  `,
  RatingQuantity: styled.p`
    ${baseTextStyles};
    font-weight: 700;
  `,
  Footer: styled.footer`
    display: flex;
    flex-direction: column;
    gap: 16px;
    align-items: center;
    padding: 24px 0;
    width: 100%;
    max-width: ${MediaBreakpoint.MAX_PHONE}px;
    background: #e3e3f1;

    @media (min-width: ${MediaBreakpoint.MAX_PHONE}px) {
      max-width: 375px;
    }
  `,
  Copyright: styled.p`
    font-size: 13px;
    font-weight: 400;
    line-height: 20px;
    text-align: center;
    color: ${Color.LIGHT_TEXT};
  `,
  LinkWrapper: styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 64px;
    font-size: 13px;
    font-weight: 400;
    line-height: 20px;

    a {
      text-decoration: underline;
      color: ${Color.DEFAULT_TEXT};
    }
  `,
}
