import React, { useRef } from 'react'
import { useTranslation } from 'react-i18next'

import { useTimerForTarget } from 'modules/subscriptions/hooks/useTimerForTarget'

import { SvgImage } from 'components/SvgImage'

import clock from 'assets/images/sprite/clock.svg'

import { StyledTimer as S } from './Timer.styles'

const FIVE_MINUTES_IN_SECONDS = 300
const TIMER_START_MINUTES = '05'
const TIMER_START_SECONDS = '00'

export const Timer: React.FC = () => {
  const { t } = useTranslation()
  const timerElementRef = useRef<HTMLDivElement>(null)

  const { minutes, seconds } = useTimerForTarget(
    timerElementRef,
    FIVE_MINUTES_IN_SECONDS,
  )

  return (
    <S.Wrapper ref={timerElementRef}>
      <SvgImage svg={clock} width={24} />
      <S.Text>
        {t('payment.variant5.offerEnds', {
          minutes: minutes || TIMER_START_MINUTES,
          seconds: seconds || TIMER_START_SECONDS,
        })}
      </S.Text>
    </S.Wrapper>
  )
}
