import styled from 'styled-components'

import { StripePaymentForm } from 'modules/payment/components/StripePaymentForm'

import { Color, MediaBreakpoint } from 'root-constants/common'

type TTextProps = {
  gridArea: string
}

export const StyledPaymentFlowsGoogle = {
  Wrapper: styled.div``,
  Title: styled.h3`
    margin: 32px 0 24px;
    font-size: 24px;
    line-height: 28px;
    font-weight: 800;
    text-align: center;
  `,
  Description: styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto;
    grid-template-areas:
      'periodText periodValue'
      'priceText priceValue';
    grid-gap: 17px 0;
    margin-bottom: 16px;
  `,
  DescriptionText: styled.p<TTextProps>`
    grid-area: ${({ gridArea }) => gridArea};
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
  `,
  DescriptionValue: styled.p<TTextProps>`
    grid-area: ${({ gridArea }) => gridArea};
    justify-self: flex-end;
    font-size: 16px;
    font-weight: 700;
    line-height: 22px;

    span {
      color: ${Color.LIGHT_TEXT};
      font-weight: 400;
    }
  `,
  Disclaimer: styled.p`
    margin-bottom: 32px;
    font-size: 11px;
    font-weight: 400;
    line-height: 18px;
    color: ${Color.LIGHT_TEXT};
  `,
  StripePaymentForm: styled(StripePaymentForm)`
    @media (min-width: ${MediaBreakpoint.LAPTOP}px) {
      width: initial;
      margin-bottom: 25px;
    }
  `,
  Image: styled.img`
    max-width: 256px;
    margin: 40px auto 16px;
  `,
  Guarantee: styled.p`
    font-size: 13px;
    line-height: 20px;
    text-align: center;
    opacity: 0.7;
    margin-bottom: 48px;
  `,
  BottomDisclaimer: styled.p`
    font-size: 11px;
    line-height: 18px;
    text-align: center;
    color: ${Color.LIGHT_TEXT};
    margin-bottom: 44px;

    a {
      color: inherit;
      text-decoration: underline;
    }
  `,
}
