import React from 'react'

import { useUserData } from 'hooks/useUserData'

import { TPageProps } from 'models/common.model'

import { RadioOptionPage } from 'pages/options-page'

import { OPTION_DATA, TITLES } from './constants'

export const KidLostVariant1: React.FC<TPageProps> = (props) => {
  const { childFlow } = useUserData()

  return (
    <RadioOptionPage
      {...props}
      options={OPTION_DATA}
      titlePath={TITLES[childFlow]}
      marginBottom={54}
    />
  )
}
