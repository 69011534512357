import React, { useEffect } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { useLocation } from 'react-router-dom'

import { useChipoloUpgradeStatus } from 'hooks/useChipoloUpgradeStatus'
import { useGetPrices } from 'hooks/useGetPrices'
import { usePaywall } from 'hooks/usePaywall'
import { usePurchaseStore } from 'hooks/usePurchaseStore'
import { useVatInfo } from 'hooks/useVatInfo'

import { ScreenName, eventLogger } from 'services/eventLogger.service'

import { makeUpsellAction } from 'modules/payment/redux/actions/common'

import { Spinner } from 'components/Spinner'
import { StripePaymentProcessing } from 'components/StripePaymentProcessing'
import { SupportLink } from 'components/SupportLink'
import { TermsOfUseLink } from 'components/TermsOfUseLink'

import { goTo } from 'browser-history'
import {
  CURRENCY_SYMBOLS,
  SubscriptionListType,
  SubscriptionTag,
} from 'root-constants/common'
import { PageId } from 'root-constants/pages'

import { StyledUpsellVariant1 as S } from './UpsellVariant1.styles'
import { BENEFITS_DATA, PRODUCTS_DATA } from './constants'

export const UpsellVariant1: React.FC = () => {
  const { t } = useTranslation()
  const { search } = useLocation()
  const dispatch = useDispatch()

  const isChipoloAvailable = useChipoloUpgradeStatus()

  const hasIncludedVAT = useVatInfo()
  const { currentPrice, currency } = usePurchaseStore()
  const { hasPrices } = usePaywall('')

  useGetPrices({
    screenName: ScreenName.UPSELL_OFFER,
    subscriptionType: SubscriptionListType.UPSELL,
    tags: hasIncludedVAT ? SubscriptionTag.TAX : SubscriptionTag.NO_TAX,
  })

  useEffect(() => {
    eventLogger.logUpsellPurchaseShown()
  }, [])

  const makeUpsell = () => {
    dispatch(makeUpsellAction())
  }

  const handleSkip = () => {
    eventLogger.logUpsellPurchaseClose()
    goTo({
      pathname: isChipoloAvailable ? PageId.CHIPOLO_UPGRADE_1 : PageId.ACCOUNT,
      search,
    })
  }

  return !hasPrices ? (
    <Spinner />
  ) : (
    <S.Wrapper>
      <S.Column>
        <S.Title>
          <Trans i18nKey="subscriptions.upsell.title" />
        </S.Title>
        <S.BenefitsContainer>
          {BENEFITS_DATA.map(({ text, image }) => (
            <S.BenefitsData key={text}>
              <img src={image} alt="icon" />
              <S.BenefitsText>{t(text)}</S.BenefitsText>
            </S.BenefitsData>
          ))}
          <S.BenefitsPrice>
            {t('subscriptions.upsell.benefitsData.price', {
              price: currentPrice,
              currencySymbol: CURRENCY_SYMBOLS[currency],
              minimumFractionDigits: 2,
            })}
          </S.BenefitsPrice>
        </S.BenefitsContainer>
        <S.Button type="button" onClick={makeUpsell}>
          {t`actions.continue`}
        </S.Button>
        <S.SkipButton type="button" onClick={handleSkip}>
          {t`actions.skipOffer`}
        </S.SkipButton>
        <S.Disclaimer>
          <Trans
            i18nKey="subscriptions.upsell.disclaimer"
            values={{
              price: currentPrice,
              minimumFractionDigits: 2,
              vatInfo: hasIncludedVAT ? t('commonComponents.inclVat') : '',
              currencySymbol: CURRENCY_SYMBOLS[currency],
              currency: currency.toUpperCase(),
            }}
            components={{
              termsOfUse: <TermsOfUseLink />,
              supportLink: <SupportLink />,
            }}
          />
        </S.Disclaimer>
        <S.Subtitle>{t`subscriptions.upsell.subtitle`}</S.Subtitle>
        <S.Text>{t`subscriptions.upsell.youGet`}</S.Text>
        <S.ProductsContainer>
          {PRODUCTS_DATA.map(({ image, name, text }) => (
            <S.ProductDescriptionWrapper key={name}>
              <S.ProductImage src={image} alt="product_image" />
              <S.ProductDescription>
                <S.ProductName>{t(name)}</S.ProductName>
                <S.Description>{t(text)}</S.Description>
              </S.ProductDescription>
            </S.ProductDescriptionWrapper>
          ))}
        </S.ProductsContainer>
        <S.Button type="button" onClick={makeUpsell}>
          {t`actions.continue`}
        </S.Button>
        <S.SkipButton type="button" onClick={handleSkip}>
          {t`actions.skipOffer`}
        </S.SkipButton>
      </S.Column>
      <StripePaymentProcessing />
    </S.Wrapper>
  )
}
