import styled from 'styled-components'

import { BurgerMenu } from 'components/BurgerMenu'

import { baseColumnStyles, basePageStyles } from 'common-styles'
import { Color, HEADER_HEIGHT, MediaBreakpoint } from 'root-constants/common'

type TCardContentProps = {
  bgImage: string
}

export const StyledIntroGoogleVariant1 = {
  Wrapper: styled.div`
    ${basePageStyles};
    padding-top: ${HEADER_HEIGHT}px;
  `,
  Column: styled.div`
    ${baseColumnStyles};
  `,
  BurgerMenu: styled(BurgerMenu)`
    button {
      top: 6px;
      right: 6px;

      @media (min-width: ${MediaBreakpoint.LAPTOP}px) {
        top: 3px;
        right: 3px;
      }
    }
  `,
  Title: styled.h2`
    padding-top: 24px;
    font-size: 32px;
    font-weight: 800;
    line-height: 38px;
    text-align: center;

    strong {
      font-weight: 800;
      color: ${Color.BLUE};
    }
  `,
  Image: styled.img`
    width: 100%;
    max-width: ${MediaBreakpoint.MAX_PHONE}px;
    aspect-ratio: 375/302;

    @media (min-width: ${MediaBreakpoint.MAX_PHONE}px) {
      max-width: 375px;
    }
  `,
  ButtonContainer: styled.div`
    margin: 3px 0 48px;
  `,
  Card: styled.div`
    width: 100%;
    max-width: ${MediaBreakpoint.MAX_PHONE}px;
    margin-bottom: 50px;

    @media (min-width: ${MediaBreakpoint.MAX_PHONE}px) {
      max-width: 375px;
    }
  `,
  CardContent: styled.div<TCardContentProps>`
    aspect-ratio: 375/359;
    margin-bottom: 8px;
    background-image: url(${({ bgImage }) => bgImage});
    background-size: 100%;
    background-repeat: no-repeat;
  `,
  CardTitle: styled.h2`
    margin-bottom: 8px;
    text-align: center;
    font-size: 24px;
    font-weight: 800;
    line-height: 28px;
  `,
  CardSubtitle: styled.h3`
    max-width: 246px;
    margin: 0 auto;
    text-align: center;
    font-size: 16px;
    font-weight: 600;
    line-height: normal;
    color: #0a324d;
  `,
}
