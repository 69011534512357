export const PAGE_NAME = 'Not get in touch with your partner'
export const OPTION_VALUES = {
  GET_THROUGH: 'get_through',
  STAY_CALM: 'stay_calm',
  FEEL_ANXIOUS: 'feel_anxious',
  OTHER: 'other',
}
export const TITLE_PATH = 'onboarding.whatToDo.question'
export const OPTION_DATA = [
  {
    value: OPTION_VALUES.GET_THROUGH,
    text: 'onboarding.whatToDo.answers.getThrough',
  },
  {
    value: OPTION_VALUES.STAY_CALM,
    text: 'onboarding.whatToDo.answers.stayCalm',
  },
  {
    value: OPTION_VALUES.FEEL_ANXIOUS,
    text: 'onboarding.whatToDo.answers.feelAnxious',
  },
  { value: OPTION_VALUES.OTHER, text: 'onboarding.whatToDo.answers.other' },
]
