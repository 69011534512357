import React from 'react'
import { Trans, useTranslation } from 'react-i18next'

import { useNextStep } from 'hooks/useNextStep'

import { TPageProps } from 'models/common.model'

import { Button } from 'components/Button'

import earthImg from 'assets/images/earth.jpg'

import { StyledStayConnectedVariant2 as S } from './StayConnectedVariant2.styles'
import { PAGE_NAME } from './constants'

export const StayConnectedVariant2: React.FC<TPageProps> = ({
  pageId,
  nextPagePath,
}) => {
  const { t } = useTranslation()

  const handleContinue = useNextStep({
    pageId,
    pageName: PAGE_NAME,
    question: '',
    nextPagePath,
  })

  return (
    <S.Wrapper>
      <S.ImageContainer>
        <img src={earthImg} alt="Earth" />
      </S.ImageContainer>
      <S.Column>
        <S.Title>{t`onboarding.stayConnected.title`}</S.Title>
        <S.Note>
          <Trans i18nKey="onboarding.stayConnected.note" />
        </S.Note>
        <Button onClick={() => handleContinue('')}>
          {t`actions.continue`}
        </Button>
      </S.Column>
    </S.Wrapper>
  )
}
