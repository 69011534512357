import styled from 'styled-components'

import { Button } from 'components/Button'

import { Color, MediaBreakpoint } from 'root-constants/common'

export const StyledBenefits = {
  Wrapper: styled.div`
    display: flex;
    flex-direction: column;
    gap: 24px;
    padding: 24px 32px;
    align-items: center;
    background: linear-gradient(174deg, #f0f5ff 13.11%, #f9f4ff 96.5%);
    max-width: ${MediaBreakpoint.MAX_PHONE}px;
    margin-bottom: 48px;

    @media (min-width: ${MediaBreakpoint.MAX_PHONE}px) {
      max-width: 375px;
    }
  `,
  Title: styled.h2`
    font-size: 24px;
    font-style: normal;
    font-weight: 800;
    line-height: 28px;
    text-align: center;
  `,
  Content: styled.div`
    display: flex;
    justify-content: center;
    gap: 16px;

    img {
      width: 56px;
      height: 56px;
    }

    &:last-of-type {
      margin-bottom: 8px;
    }
  `,
  TextWrapper: styled.div``,
  BenefitsTitle: styled.p`
    color: ${Color.BLUE};
    font-size: 18px;
    font-weight: 700;
    line-height: 25px;
    margin-bottom: 4px;
  `,
  Subtitle: styled.p`
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
  `,
  Button: styled(Button)`
    height: 56px;
    line-height: 56px;
    font-weight: 800;

    &[data-is-es-language='true'] {
      font-size: 16px;
    }
  `,
}
