import React from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

import { eventLogger } from 'services/eventLogger.service'

import { AppLinks } from 'root-constants/common'

type TProps = {
  title?: string
}
export const CookiePolicyLink: React.FC<TProps> = ({
  title = 'commonComponents.cookiePolicy',
}) => {
  const { t } = useTranslation()

  return (
    <Link
      to={AppLinks.COOKIE_LINK}
      onClick={() => eventLogger.logCookiePolicyClicked()}
      target="_blank"
      rel="noopener noreferrer"
    >
      {t(title)}
    </Link>
  )
}
