import React, { useEffect } from 'react'
import { useMap } from 'react-leaflet'

type TProps = {
  position: [number, number]
}
export const CurrentLocation: React.FC<TProps> = ({ position }) => {
  const map = useMap()

  useEffect(() => {
    if (position) {
      map.flyTo(position, 7, {
        animate: true,
        duration: 2,
      })
    }
  }, [map, position])

  return null
}
