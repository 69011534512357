import styled, { css } from 'styled-components'

import { baseColumnStyles, basePageStyles } from 'common-styles'
import {
  Color,
  FAMY_LOCATE_HEADER_HEIGHT,
  MediaBreakpoint,
} from 'root-constants/common'

const progressValueStyles = css`
  border-radius: 10px;
  transition: all 0.2s ease-out;
`
export const StyledPersonalGiftVariant1 = {
  Wrapper: styled.div`
    ${basePageStyles};
    min-height: 100dvh;
    padding-top: ${FAMY_LOCATE_HEADER_HEIGHT}px;
  `,
  Content: styled.div`
    ${baseColumnStyles};
    width: 328px;
    padding-top: 24px;
    padding-bottom: 56px;
    flex-grow: 1;

    @media (min-width: ${MediaBreakpoint.LAPTOP}px) {
      width: 100%;
      padding-top: 64px;
    }
  `,
  Title: styled.h1`
    font-size: 28px;
    font-weight: 800;
    line-height: 38px;
    text-align: center;
    background: linear-gradient(90deg, #adaaff 0.61%, #ffa0d6 100%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    max-width: 188px;
    flex-grow: 1;

    @media (min-width: ${MediaBreakpoint.LAPTOP}px) {
      max-width: 100%;
      font-size: 46px;
    }
  `,
  AnimationContainer: styled.div`
    margin: 70px 0;
    display: flex;
    flex-direction: column;
    flex-grow: 1;

    @media (min-width: ${MediaBreakpoint.LAPTOP}px) {
      width: 315px;
    }
  `,
  Subtitle: styled.p`
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: 0.5px;
    text-transform: uppercase;
    text-align: center;
    color: ${Color.WHITE};
    max-width: 287px;

    strong {
      font-size: 16px;
      font-weight: 700;
      line-height: 24px;
      letter-spacing: 0.5px;
      text-transform: uppercase;
      color: #ffa0d6;
    }

    @media (min-width: ${MediaBreakpoint.LAPTOP}px) {
      max-width: 469px;
      font-size: 26px;
      line-height: 35px;

      strong {
        font-size: 26px;
        line-height: 35px;
      }
    }
  `,
  ProgressBar: styled.progress`
    width: 100%;
    max-width: 175px;
    height: 4px;
    margin-top: 24px;
    appearance: none;
    border: none;
    display: block;
    border-radius: 3px;
    overflow: hidden;
    background-color: rgba(255, 255, 255, 0.1);

    &::-webkit-progress-bar {
      background-color: rgba(255, 255, 255, 0.1);
    }
    &::-webkit-progress-value {
      ${progressValueStyles};
      background-color: #fff;
    }
    &::-moz-progress-bar {
      ${progressValueStyles};
      background-color: #fff;
    }
    &::-ms-fill {
      ${progressValueStyles};
      background-color: #fff;
    }

    @media (min-width: ${MediaBreakpoint.LAPTOP}px) {
      max-width: 351px;
      margin-top: 65px;
    }
  `,
}
