import styled from 'styled-components'

import {
  baseAdaptiveColumnStyles,
  baseAdaptiveTitleStyles,
  basePageStyles,
} from 'common-styles'
import {
  BASE_COLUMN_WIDTH,
  HEADER_HEIGHT,
  MediaBreakpoint,
} from 'root-constants/common'

export const StyledGender = {
  Wrapper: styled.div`
    ${basePageStyles};
    padding-top: ${HEADER_HEIGHT}px;
  `,
  Column: styled.div`
    ${baseAdaptiveColumnStyles};
    width: ${BASE_COLUMN_WIDTH}px;
    padding: 40px 0;

    @media (min-width: ${MediaBreakpoint.LAPTOP}px) {
      width: 460px;
    }
  `,
  Title: styled.h1`
    ${baseAdaptiveTitleStyles};
    margin-bottom: 140px;

    @media (min-width: ${MediaBreakpoint.LAPTOP}px) {
      margin-bottom: 48px;
    }
  `,
}
