import styled, { css } from 'styled-components'

import { PaymentRequestButton } from 'modules/payment/components/PaymentRequestButton/PaymentRequestButton'

import { Button } from 'components/Button'
import { Guarantee } from 'components/Guarantee'

import checkIcon from 'assets/images/check-violet.svg'

import { baseColumnStyles, basePageStyles } from 'common-styles'
import { Color, HEADER_HEIGHT, MediaBreakpoint } from 'root-constants/common'

type TContentProps = {
  hasLighterBg: boolean
  hasPinnedElement?: boolean
}

const commonWidthStyles = css`
  width: 100%;
  max-width: ${MediaBreakpoint.MAX_PHONE}px;

  @media (min-width: ${MediaBreakpoint.MAX_PHONE}px) {
    max-width: 375px;
  }
`

export const StyledPaymentVariant5 = {
  Wrapper: styled.div`
    ${basePageStyles};
    padding-top: ${HEADER_HEIGHT}px;
  `,
  Content: styled.div<TContentProps>`
    ${commonWidthStyles};
    min-height: calc(100dvh - ${HEADER_HEIGHT}px);
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding: ${({ hasPinnedElement }) =>
      hasPinnedElement ? '32px 32px 88px' : `32px`};
    background-color: ${({ hasLighterBg }) =>
      hasLighterBg ? '#f8f8ff' : '#f5f5ff'};
  `,
  Column: styled.div`
    ${baseColumnStyles};
  `,
  Title: styled.h1<{ hasPinnedTimer: boolean }>`
    margin-bottom: ${({ hasPinnedTimer }) =>
      hasPinnedTimer ? '32px' : '48px'};
    text-align: center;
    font-size: 24px;
    font-weight: 800;
    line-height: 28px;
  `,
  BenefitsContainer: styled.div`
    display: flex;
    flex-wrap: wrap;
    border-radius: 16px;
    border: 1px solid ${Color.ACCENT_VIOLET};
    background-color: #e7e6ff;
    margin: 10px 0 32px;
    padding: 16px;
  `,
  BenefitsTitle: styled.h3`
    flex-basis: 100%;
    margin-bottom: 16px;
    text-align: center;
    font-size: 20px;
    font-weight: 800;
    line-height: 27px;
  `,
  BenefitsText: styled.p`
    position: relative;
    flex-basis: 50%;
    margin-bottom: 16px;
    padding-left: 20px;
    font-size: 12px;
    font-weight: 600;
    line-height: 16px;

    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 16px;
      height: 16px;
      background: url(${checkIcon}) center no-repeat;
      background-size: contain;
    }
  `,
  PaymentServiceImage: styled.img`
    margin-bottom: 16px;
  `,
  GuaranteeText: styled.span`
    margin-bottom: 32px;
    font-size: 13px;
    line-height: 20px;
    text-align: center;
    color: ${({ theme }) => theme.colors.guaranteeText};
  `,
  PaymentRequestButton: styled(PaymentRequestButton)`
    margin: 0 0 16px;
  `,
  Guarantee: styled(Guarantee)`
    margin-top: 16px;
    background-color: ${Color.WHITE};
  `,
  Disclaimer: styled.p`
    font-size: 11px;
    line-height: 18px;
    text-align: center;
    color: ${Color.LIGHT_TEXT};

    a {
      color: inherit;
      font-weight: inherit;
      text-decoration: underline;
      white-space: pre;
    }
  `,
  ThreeDSecureIframe: styled.iframe`
    height: calc(var(--full-height, 100vh) - 50px) !important;
    padding: 15px;
  `,
  ButtonContainer: styled.div<{ isVisible: boolean }>`
    ${commonWidthStyles};
    position: fixed;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    z-index: 2;
    height: 88px;
    display: ${({ isVisible }) => (isVisible ? 'flex' : 'none')};
    align-items: center;
    justify-content: center;
    background: linear-gradient(
      180deg,
      rgba(245, 245, 255, 0) 0%,
      #f5f5ff 19.89%
    );
    overflow: hidden;
  `,
  Button: styled(Button)`
    text-transform: capitalize;
    line-height: 22px;
    height: 56px;
  `,
}
