import React from 'react'

import { usePurchaseStore } from 'hooks/usePurchaseStore'

import { ISelectPlanItemProps } from 'models/common.model'
import { ISubscription } from 'models/subscriptions.model'

import { OptionType } from 'components/Option'

import { StyledSelectPlanVariant1 as S } from './SelectPlanBlockVariant1.styles'

type TProps = Omit<React.HTMLAttributes<HTMLDivElement>, 'onSelect'> & {
  onSelect: (subscription?: ISubscription) => void
  SelectPlanItem: React.FC<ISelectPlanItemProps>
}

export const SelectPlanBlockVariant1 = React.forwardRef<HTMLDivElement, TProps>(
  ({ onSelect, SelectPlanItem, ...props }, ref) => {
    const { subscriptions, selectedSubscriptionId } = usePurchaseStore()

    const handleChange = (value) => {
      const checkedSubscription = subscriptions.find(
        (subscription) => subscription.id === value,
      )
      onSelect(checkedSubscription)
    }

    return (
      <S.Wrapper ref={ref} {...props}>
        {subscriptions.map((subscription) => (
          <S.Option
            type={OptionType.RADIO}
            name="subscription"
            value={subscription.id}
            key={subscription.id}
            onChange={handleChange}
          >
            <SelectPlanItem
              hasLaptopStyles
              subscription={subscription}
              isSelected={subscription.id === selectedSubscriptionId}
            />
          </S.Option>
        ))}
      </S.Wrapper>
    )
  },
)
