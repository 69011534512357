import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { useNextStep } from 'hooks/useNextStep'

import { TPageProps } from 'models/common.model'

import { AGE_RANGE_DATA } from 'pages/age-range/constants'

import { OptionType } from 'components/Option'
import { PoliciesGroup } from 'components/PoliciesGroup'

import vectorIcon from 'assets/images/vector.svg'

import { Language } from 'root-constants/common'

import { StyledAgeRangeVariant1 as S } from './AgeRangeVariant1.styles'

export const AgeRangeVariant1: React.FC<TPageProps> = ({
  pageId,
  nextPagePath,
}) => {
  const { t } = useTranslation()
  const [answers, setAnswers] = useState<string[]>([])

  const handleContinue = useNextStep({
    pageId,
    pageName: pageId,
    question: t('onboarding.ageRange.title', { lng: Language.EN }),
    nextPagePath,
  })

  const optionProps = {
    name: pageId,
    type: OptionType.CHECKBOX,
    onChange: (value, isChecked) => {
      if (isChecked) {
        setAnswers([...answers, value])
      } else {
        const newValues = answers.filter((item) => item !== value)
        setAnswers(newValues)
      }
    },
  }

  return (
    <>
      <S.BurgerMenu />
      <S.Wrapper>
        <S.Column>
          <S.Title>{t`onboarding.ageRange.title`}</S.Title>
          <S.Subtitle>{t`onboarding.intro.note`}</S.Subtitle>
          <S.OptionContainer>
            {AGE_RANGE_DATA.map(({ text, value, image }) => (
              <S.Option key={text} {...optionProps} value={value}>
                <S.QuestionButton backgroundImage={image} hasCheckboxIcon>
                  <S.TextWrapper>
                    {t(text)}
                    <img src={vectorIcon} alt="icon" />
                  </S.TextWrapper>
                </S.QuestionButton>
              </S.Option>
            ))}
          </S.OptionContainer>
          <S.Button
            onClick={() => handleContinue(answers)}
            disabled={!answers.length}
          >
            {t`actions.continue`}
          </S.Button>
          <PoliciesGroup />
        </S.Column>
      </S.Wrapper>
    </>
  )
}
