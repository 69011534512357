import { EMPTY_FIELD_ERROR } from 'modules/payment/constants'
import { TPaymentErrorState } from 'modules/payment/types'

export const getPaymentErrorState = (
  errors: TPaymentErrorState,
): TPaymentErrorState =>
  Object.entries(errors).reduce(
    (result, error) => ({
      ...result,
      [error[0]]: {
        ...error[1],
        ...(!error[1].isTouched && {
          isTouched: true,
          error: EMPTY_FIELD_ERROR,
        }),
      },
    }),
    {} as TPaymentErrorState,
  )
