import styled from 'styled-components'

import {
  baseAdaptiveColumnStyles,
  baseAdaptiveTitleStyles,
  basePageStyles,
} from 'common-styles'
import { HEADER_HEIGHT, MediaBreakpoint } from 'root-constants/common'

type TColumnProps = {
  isMainSafetyCohort: boolean
}
export const StyledLocation = {
  Wrapper: styled.div`
    ${basePageStyles};
    padding-top: ${HEADER_HEIGHT}px;
  `,
  Column: styled.div<TColumnProps>`
    ${baseAdaptiveColumnStyles};
    padding: ${({ isMainSafetyCohort }) =>
      isMainSafetyCohort ? '24px 25px' : '40px 25px'};
  `,
  Title: styled.h1`
    ${baseAdaptiveTitleStyles};
    margin: 0 0 87px;

    @media (min-width: ${MediaBreakpoint.LAPTOP}px) {
      margin: 0 -25px 48px;
    }
  `,
}
