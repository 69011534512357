import React from 'react'
import { Trans, useTranslation } from 'react-i18next'

import { useNextStep } from 'hooks/useNextStep'

import { TPageProps } from 'models/common.model'

import { Button } from 'components/Button'

import kidImg from 'assets/images/proof-kid.webp'

import { StyledSocialProofVariant9 as S } from './SocialProofVariant9.styles'
import { PAGE_NAMES } from './constants'

export const SocialProofVariant9: React.FC<TPageProps> = ({
  pageId,
  nextPagePath,
}) => {
  const { t } = useTranslation()

  const handleContinue = useNextStep({
    pageId,
    pageName: PAGE_NAMES[pageId],
    question: '',
    nextPagePath,
  })

  return (
    <S.Wrapper>
      <S.ImageContainer>
        <img src={kidImg} alt="kid" />
      </S.ImageContainer>
      <S.Column>
        <S.Text marginBottom={24}>
          <Trans i18nKey="onboarding.socialProof.variant9.paragraph1" />
        </S.Text>
        <S.Text marginBottom={16}>
          <Trans i18nKey="onboarding.socialProof.variant9.paragraph2" />
        </S.Text>
        <S.Note>{t`onboarding.socialProof.variant9.note`}</S.Note>
        <Button type="button" onClick={() => handleContinue('')}>
          {t`actions.continue`}
        </Button>
      </S.Column>
    </S.Wrapper>
  )
}
