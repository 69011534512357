import React from 'react'
import { useSelector } from 'react-redux'

import { selectDynamicDiscount } from 'root-redux/selects/common'

import { SALE_COUNT } from 'components/DynamicDiscountBanner/constants'
import { SvgImage } from 'components/SvgImage'

import highVoltage from 'assets/images/sprite/high-voltage.svg'

import { DYNAMIC_DISCOUNT_THEME } from 'root-constants/common'

import { StyledDynamicDiscountBanner as S } from './DynamicDiscountBanner.styles'

export const DynamicDiscountBanner: React.FC = () => {
  const dynamicDiscount = useSelector(selectDynamicDiscount)

  return dynamicDiscount ? (
    <S.Wrapper
      backgroundColor={
        DYNAMIC_DISCOUNT_THEME[dynamicDiscount?.theme] ||
        DYNAMIC_DISCOUNT_THEME.orange
      }
    >
      <S.Container>
        {Array.from({ length: SALE_COUNT })
          .map((_, i) => i + 1)
          .map((number) => (
            <S.Sale key={number}>
              <SvgImage svg={highVoltage} />
              {dynamicDiscount?.name}
            </S.Sale>
          ))}
      </S.Container>

      <S.Container aria-hidden>
        {Array.from({ length: SALE_COUNT })
          .map((_, i) => i + 1)
          .map((number) => (
            <S.Sale key={number}>
              <SvgImage svg={highVoltage} />
              {dynamicDiscount?.name}
            </S.Sale>
          ))}
      </S.Container>
    </S.Wrapper>
  ) : null
}
