import { RefObject, useEffect } from 'react'

export const useClickOutside = ({
  ref,
  callback,
  excludedRef,
}: {
  ref: RefObject<HTMLElement>
  callback: () => void
  excludedRef?: RefObject<HTMLButtonElement>
}) => {
  useEffect(() => {
    const handleClick = ({ target }) => {
      if (excludedRef?.current && excludedRef.current.contains(target)) return

      if (ref.current && !ref.current.contains(target)) {
        callback()
      }
    }

    document.addEventListener('click', handleClick, true)
    return () => {
      document.removeEventListener('click', handleClick, true)
    }
  }, [callback, excludedRef, ref])
}
