import { getCurrentEnv } from 'helpers/getCurrentEnv'

import { CurrentEnvironment, TEST_ENV_QUERY_PARAM } from 'root-constants/common'

export type TCurrentEnvKeys = keyof typeof CurrentEnvironment
export type TCurrentEnvValues = typeof CurrentEnvironment[TCurrentEnvKeys]

export const getEnvironment = () => {
  const currentEnv = getCurrentEnv()
  const searchParams = new URLSearchParams(document.location.search)

  return {
    isProdEnvironment: currentEnv === CurrentEnvironment.PROD,
    isDevEnvironment: currentEnv === CurrentEnvironment.DEV,
    isStageEnvironment: currentEnv === CurrentEnvironment.STAGE,
    isTestEnvironment: searchParams.has(TEST_ENV_QUERY_PARAM),
  }
}
