import styled from 'styled-components'

import { Button } from 'components/Button'
import { Input } from 'components/Input'

import emailIcon from 'assets/images/email-icon.svg'

import { StyledPhoneInput } from 'common-styles'
import { Color } from 'root-constants/common'

type TProps = {
  isShown: boolean
}

export const StyledEmailPhoneForm = {
  PhoneForm: styled.form`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 16px;
  `,
  PhoneText: styled.h3`
    text-align: center;
    font-size: 20px;
    font-weight: 600;
    line-height: 27px;
    width: 296px;
  `,
  PhoneNumberInput: styled(StyledPhoneInput)`
    border: 1px solid #cbc3e7;
  `,
  PhoneErrorText: styled.p`
    color: #ff0000;
    text-align: center;
    font-size: 18px;
    font-weight: 600;
    line-height: 20px;
  `,
  Button: styled(Button)`
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 12px;
    margin-top: 20px;
  `,
  EmailErrorWrapper: styled.div<TProps>`
    width: 311px;
    display: ${({ isShown }) => (isShown ? 'flex' : 'none')};
    align-items: center;
    justify-content: center;
    gap: 8px;
    margin: 16px 0;
  `,
  EmailForm: styled.form<TProps>`
    width: 311px;
    display: ${({ isShown }) => (isShown ? 'flex' : 'none')};
    align-items: center;
    justify-content: center;
    gap: 16px;
  `,
  InputWrapper: styled.div`
    width: 100%;
    position: relative;
  `,
  EmailInput: styled(Input)`
    border: 1px solid #cbc3e7;
    background-image: url(${emailIcon});
    background-size: 24px 24px;
    background-position: 16px center;
    background-repeat: no-repeat;
    background-color: #fff;
    color: #000000;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    padding-left: 45px;
  `,
  AutoCompleteWrapper: styled.div`
    width: 100%;
    max-height: 160px;
    padding: 0 15px;
    position: absolute;
    top: 80px;
    left: 0;
    border-radius: 12px;
    overflow-y: auto;
    background-color: #fff;
    filter: drop-shadow(0px 7px 20px #c8d4d8);
    z-index: 2;
    transform: translateZ(0);
  `,
  AutoCompleteButton: styled.button`
    height: 50px;
    width: 100%;
    padding: 0;
    display: block;
    cursor: pointer;
    border: none;
    outline: none;
    font-size: 16px;
    font-weight: 500;
    line-height: 50px;
    text-decoration: none;
    text-align: left;
    color: ${Color.DEFAULT_TEXT};
    background-color: transparent;
    -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    direction: rtl;

    :not(:last-child) {
      border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    }
  `,
  AutoCompleteButtonTextWrapper: styled.span`
    unicode-bidi: plaintext;
  `,
  Text: styled.p`
    font-size: 13px;
    font-weight: 400;
    line-height: 18px;
  `,
}
