import React, { RefObject, createContext, useContext, useMemo } from 'react'

import {
  ICardPaymentMethodManager,
  INativePaymentMethodManager,
} from '@primer-io/checkout-web'

import { usePrimer } from 'hooks/common/usePrimer'

type TContext = {
  primerFormRef: RefObject<HTMLFormElement>
  cardManagerRef: RefObject<ICardPaymentMethodManager | null>
  paypalManagerRef: RefObject<INativePaymentMethodManager | null>
}

const PrimerContext = createContext<TContext>({} as TContext)

export const PrimerProvider: React.FC<React.PropsWithChildren> = ({
  children,
}) => {
  const { primerFormRef, cardManagerRef, paypalManagerRef } = usePrimer()

  const values = useMemo(
    () => ({
      primerFormRef,
      cardManagerRef,
      paypalManagerRef,
    }),
    [cardManagerRef, paypalManagerRef, primerFormRef],
  )

  return (
    <PrimerContext.Provider value={values}>{children}</PrimerContext.Provider>
  )
}

export const usePrimerContext = () => {
  const context = useContext(PrimerContext)

  if (!context) {
    throw new Error('usePrimerContext must be used within a PrimerProvider')
  }

  return context
}
