import styled from 'styled-components'

type TProps = {
  imageLink: string
}

export const GuaranteeCard = styled.div<TProps>`
  margin-bottom: 38px;
  padding: 32px 24px;
  background-color: ${({ theme }) => theme.colors.guaranteeBackground};
  border-radius: 16px;
  background-image: url(${({ imageLink }) => imageLink});
  background-size: 75px;
  background-repeat: no-repeat;
  background-position: calc(100% - 10px) calc(100% - 10px);
  font-size: 13px;
  line-height: 20px;

  h4 {
    margin-bottom: 10px;
    font-size: 20px;
    line-height: 22px;
    font-weight: 700;
    text-align: center;
    color: ${({ theme }) => theme.colors.primaryText};
  }

  span {
    color: ${({ theme }) => theme.colors.tertiaryText};
  }
`
