import React, { HTMLAttributes, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { selectLanguage } from 'root-redux/selects/common'

import { useTimerForTarget } from 'modules/subscriptions/hooks/useTimerForTarget'

import { Language } from 'root-constants/common'

import { StyledTimer as S } from './Timer.styles'

const FIFTEEN_MINUTES_IN_SECONDS = 900

export const Timer: React.FC<HTMLAttributes<HTMLDivElement>> = ({
  ...props
}) => {
  const { t } = useTranslation()
  const timerElementRef = useRef<HTMLDivElement>(null)
  const language = useSelector(selectLanguage)

  const { minutes, seconds } = useTimerForTarget(
    timerElementRef,
    FIFTEEN_MINUTES_IN_SECONDS,
  )

  return (
    <S.TimerContainer {...props}>
      <S.TimerContent
        data-is-de-lang={language === Language.DE}
        ref={timerElementRef}
      >
        <span>{t`subscriptions.timer.reservedPrice`}:</span>
        {minutes && seconds && <strong>{`${minutes}:${seconds}`}</strong>}
      </S.TimerContent>
    </S.TimerContainer>
  )
}
