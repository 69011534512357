import React from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

import { eventLogger } from 'services/eventLogger.service'

import { AppLinks } from 'root-constants/common'

type TProps = {
  title?: string
}

export const TermsOfUseLink: React.FC<TProps> = ({ title }) => {
  const { t } = useTranslation()

  return (
    <Link
      to={AppLinks.TOU_LINK}
      onClick={() => eventLogger.logTermsOfUseClicked()}
      target="_blank"
      rel="noopener noreferrer"
    >
      {title || t`commonComponents.termsOfUse`}
    </Link>
  )
}
