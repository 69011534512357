/* eslint-disable max-lines */
import { getCurrentExperimentId } from 'helpers/getCurrentExperimentId'
import { getCurrentOnboardingVariant } from 'helpers/getCurrentOnboardingVariant'
import { getMobileOperatingSystem } from 'helpers/getMobileOperatingSystem'

import { IEvent, IEventLogger, TQuestionPageParams } from 'models/events.model'
import { TProductId } from 'models/subscriptions.model'
import { IDeliveryInfo } from 'models/user.model'

import { PaymentMethod, PaymentSystem } from 'modules/payment/constants'
import { UpgradeItem } from 'modules/subscriptions/constants'

import {
  EventLoggerInstanceName,
  LoginMethod,
  NO_NAME_AB_SEGMENT,
} from 'root-constants/common'

export const enum ScreenName {
  ONBOARDING = 'onboarding',
  CANCEL_OFFER = 'cancel_offer_fm1',
  UPSALE_SCREEN = 'upsale_screen',
  LIVECHAT_UPGRADE = 'livechat_upgrade',
  UPGRADE = 'upgrade',
  CHIPOLO_UPSELL = 'chipolo_upsell',
  UPSELL_OFFER = 'upsell_offer',
}

export const enum Source {
  INITIAL = 'initial',
  UPSALE_OFFER = 'upsale_offer',
  CANCEL_OFFER = 'cancel_offer',
  UPGRADE_OFFER = 'upgrade_offer',
  EMAIL = 'email',
  LIVE_CHAT = 'livechat',
}

export const enum Events {
  SESSION_STARTED = 'session_start',
  AB_SEGMENT = 'ab_segment',
  QUESTION_COMPLETED = 'question_page_completed',
  PRESALE_PAGE_COMPLETED = 'presale_page_completed',
  PRESALE_PAGE_SHOW = 'presale_page_show',
  EMAIL_PAGE_SHOW = 'email_page_show',
  EMAIL_PAGE_COMPLETED = 'email_page_completed',
  EMAIL_PAGE_ERROR = 'email_page_error',
  PAYMENT_METHOD_SELECTED = 'payment_method_selected',
  SALE_SCREEN_SHOW = 'plans_page_show',
  CANCEL_OFFER_SCREEN_SHOW = 'cancel_offer_page_show',
  TERMS_OF_USE = 'terms_of_use_tap',
  PRIVACY_POLICY = 'privacy_policy_tap',
  NEED_HELP = 'need_help_tap',
  PURCHASE_SHOW = 'subs_purchase_show',
  PURCHASE_STARTED = 'subs_purchase_started',
  PURCHASE_STARTED_PAYPAL = 'subs_purchase_started_paypal',
  PURCHASE_COMPLETED = 'subs_purchase_completed',
  PURCHASE_FAILED = 'subs_purchase_failed',
  PURCHASE_SCREEN_CLOSE = 'subs_purchase_screen_close',
  PAYMENT_METHOD_POPUP_CLOSE = 'payment_method_popup_close',
  PAYMENT_METHOD_TAP = 'change_payment_method_tap',
  UPSELL_CHIPOLO_PAGE_SHOW = 'upsell_chipolo_page_show',
  UPSELL_CHIPOLO_PAGE_CLOSE = 'upsell_chipolo_page_close',
  UPSELL_CHIPOLO_TOGGLE_ENABLE = 'upsell_chipolo_toggle_enable',
  UPSELL_CHIPOLO_TOGGLE_DISABLE = 'upsell_chipolo_toggle_disable',
  UPSELL_CHIPOLO_PAGE_SCROLLED = 'upsell_chipolo_page_scrolled',
  UPSELL_CHIPOLO_BUTTON_TAP = 'upsell_chipolo_button_tap',
  UPSELL_CHIPOLO_OFFER_SKIP = 'upsell_chipolo_offer_skip',
  UPSELL_CHIPOLO_CHECKOUT_SHOW = 'upsell_chipolo_checkout_show',
  UPSELL_CHIPOLO_CHECKOUT_CLOSE = 'upsell_chipolo_checkout_close',
  UPSELL_CHIPOLO_PURCHASE_COMPLETED = 'upsell_chipolo_purchase_completed',
  UPSELL_CHIPOLO_PURCHASE_FAILED = 'upsell_chipolo_purchase_failed',
  UPSELL_PURCHASE_SHOW = 'upsell_purchase_show',
  UPSELL_PURCHASE_CLOSE = 'upsell_purchase_close',
  OFFLINE_PRODUCT_PURCHASE_COMPLETED = 'offline_product_purchase_completed',
  CREATE_ACCOUNT_SHOW = 'create_account_page',
  ACCOUNT_CREATED = 'account_created',
  ACCOUNT_CREATION_FAILED = 'account_creation_failed',
  FINISH_ACCOUNT_SCREEN_VIEW = 'finish_account_screen_view',
  DOWNLOAD_BTN_PRESSED = 'download_button_press',
  LIVE_CHAT_OPENED = 'live_chat_opened',
  LIVE_CHAT_CLOSED = 'live_chat_closed',
  CHECK_NUMBER = 'check_number',
  PAGE_SCROLLED = 'page_scrolled',
  VIDEO_PLAY_STARTED = 'video_play_started',
  COOKIES_BANNER_SHOW = 'cookies_banner_show',
  COOKIES_BANNER_SETTINGS_TAP = 'cookies_banner_settings_tap',
  COOKIES_ACCEPT_ALL_TAP = 'cookies_accept_all_tap',
  COOKIES_REJECT_ALL_TAP = 'cookies_reject_all_tap',
  COOKIES_SETTINGS_TOGGLE_ENABLE = 'cookies_settings_toggle_enable',
  COOKIES_SETTINGS_TOGGLE_DISABLE = 'cookies_settings_toggle_disable',
  COOKIES_SETTING_CONFIRM_CHOICES_TAP = 'cookies_setting_confirm_choices_tap',
  COOKIES_SETTINGS_SCREEN_CLOSE = 'cookies_settings_screen_close',
  COOKIES_SETTINGS_EXPAND_OPTION_TAP = 'cookies_settings_expand_option_tap',
  COOKIE_POLICY_TAP = 'cookies_policy_button_tap',
}

class EventLoggerService {
  private loggers?: Map<EventLoggerInstanceName, IEventLogger>
  private eventsQueue: IEvent[] = []
  private eventsCache: Events[] = []

  get isAmplitudeActive() {
    return this.loggers?.has(EventLoggerInstanceName.AMPLITUDE)
  }

  get getIsGiaActive() {
    return this.loggers?.has(EventLoggerInstanceName.GIA)
  }

  init(...loggers: IEventLogger[]): void {
    const entriesArr = loggers.map(
      (logger) =>
        [logger.name, logger] as [EventLoggerInstanceName, IEventLogger],
    )

    if (!this.loggers) {
      this.loggers = new Map(entriesArr)
      this.notifyInitFinished()
      return
    }

    if (this.loggers) {
      loggers.map((logger) =>
        this.loggers?.set(
          logger.name as EventLoggerInstanceName,
          logger as IEventLogger,
        ),
      )
    }

    this.notifyInitFinished()
  }

  logSessionStarted = (): void => {
    this.logEventOrPushToQueue({
      event: Events.SESSION_STARTED,
      eventProperty: {
        device_type: getMobileOperatingSystem(),
      },
      isAmplitudeEvent: false,
    })
  }

  logGrowthbookAbSegmentName({
    variantId,
    experimentKey,
    variantName,
  }: {
    experimentKey: string
    variantId: string
    variantName: string
  }): void {
    this.logEventOrPushToQueue({
      event: Events.AB_SEGMENT,
      eventProperty: {
        growthbook_experiment_key: getCurrentExperimentId(experimentKey),
        ab_variant: getCurrentOnboardingVariant(variantId),
        ab_segment_name: variantName || NO_NAME_AB_SEGMENT,
      },
      isAmplitudeEvent: false,
    })
  }

  logQuestion({ question, answers, pageName }: TQuestionPageParams): void {
    this.logEventOrPushToQueue({
      event: Events.QUESTION_COMPLETED,
      eventProperty: {
        question,
        answer: Array.isArray(answers) ? answers.join(',') : answers,
        screen_name: pageName,
      },
    })
  }

  // EmailWrapper Page Events
  logEmailPageShown(): void {
    if (this.eventsCache.includes(Events.EMAIL_PAGE_SHOW)) {
      return
    }

    this.eventsCache.push(Events.EMAIL_PAGE_SHOW)
    this.logEventOrPushToQueue({
      event: Events.EMAIL_PAGE_SHOW,
      isAmplitudeEvent: false,
    })
  }

  logEmailPageCompleted(eventProperty: { email: string }): void {
    this.logEventOrPushToQueue({
      event: Events.EMAIL_PAGE_COMPLETED,
      eventProperty,
      isAmplitudeEvent: false,
    })
  }

  logEmailPageError(eventProperty: { error: string }): void {
    this.logEventOrPushToQueue({
      event: Events.EMAIL_PAGE_ERROR,
      eventProperty,
    })
  }

  // Presale Page Events
  logPresalePageShown({ pageName }: { pageName: string }): void {
    this.logEventOrPushToQueue({
      event: Events.PRESALE_PAGE_SHOW,
      eventProperty: {
        screen_name: pageName,
      },
      isAmplitudeEvent: false,
    })
  }

  logPresalePageCompleted({ pageName }: { pageName: string }): void {
    this.logEventOrPushToQueue({
      event: Events.PRESALE_PAGE_COMPLETED,
      eventProperty: {
        screen_name: pageName,
      },
      isAmplitudeEvent: false,
    })
  }

  // Sale Page Events
  logSalePageShown({
    productIds,
    screenName,
    onboardingId,
  }: {
    productIds: string[]
    screenName: ScreenName
    onboardingId?: string
  }): void {
    this.logEventOrPushToQueue({
      event:
        screenName === ScreenName.CANCEL_OFFER
          ? Events.CANCEL_OFFER_SCREEN_SHOW
          : Events.SALE_SCREEN_SHOW,
      eventProperty: {
        product_id: productIds.join(','),
        screen_name: screenName,
        onboarding_id: onboardingId || '',
      },
      isAmplitudeEvent: screenName === ScreenName.CANCEL_OFFER,
    })
  }

  logTermsOfUseClicked(): void {
    this.logEventOrPushToQueue({ event: Events.TERMS_OF_USE })
  }

  logPrivacyPolicyClicked(): void {
    this.logEventOrPushToQueue({ event: Events.PRIVACY_POLICY })
  }

  logNeedHelpClicked(): void {
    this.logEventOrPushToQueue({ event: Events.NEED_HELP })
  }

  // Account Page events
  logCreateAccountShown(): void {
    this.logEventOrPushToQueue({ event: Events.CREATE_ACCOUNT_SHOW })
  }

  logAccountCreated(eventProperty: { method: LoginMethod | null }): void {
    this.logEventOrPushToQueue({
      event: Events.ACCOUNT_CREATED,
      eventProperty,
    })
  }

  logAccountCreationFailed({ error }: { error: string }): void {
    this.logEventOrPushToQueue({
      event: Events.ACCOUNT_CREATION_FAILED,
      eventProperty: {
        error_reason: error,
      },
    })
  }

  // Getting App Page Events
  logGettingAppShown(): void {
    this.logEventOrPushToQueue({ event: Events.FINISH_ACCOUNT_SCREEN_VIEW })
  }

  logDownloadClicked(callback?: () => void): void {
    this.logEventOrPushToQueue({
      event: Events.DOWNLOAD_BTN_PRESSED,
      callback,
    })
  }

  // Payment
  logPaymentMethodSelected({
    paymentMethod,
  }: {
    paymentMethod: PaymentMethod
  }): void {
    this.logEventOrPushToQueue({
      event: Events.PAYMENT_METHOD_SELECTED,
      eventProperty: {
        payment_method: paymentMethod,
      },
    })
  }

  // SubscriptionsWrapper Page events
  logPurchaseShown({
    screenName,
    productId,
    stripeAccountId,
    stripeAccountName,
  }: {
    screenName: ScreenName
    productId?: TProductId
    stripeAccountId?: string
    stripeAccountName?: string
  }): void {
    this.logEventOrPushToQueue({
      event: Events.PURCHASE_SHOW,
      eventProperty: {
        onboarding_id: '',
        screen_name: screenName,
        ...(productId && { product_id: productId }),
        ...(stripeAccountName && { stripe_account_name: stripeAccountName }),
        ...(stripeAccountId && { stripe_account_id: stripeAccountId }),
      },
    })
  }

  logPurchaseStarted({
    eventName = Events.PURCHASE_STARTED,
    productId,
    productKey,
    priceDetails: { price, trial = false, currency = 'USD' },
    paymentMethod,
    email,
    screenName,
    paymentSystem,
    stripeAccountId,
    stripeAccountName,
    isUpgraded = false,
    amountToPay,
    isPersonalDataAllowed,
  }: {
    eventName?: Events
    productId: TProductId
    productKey?: string
    priceDetails: { price: number; trial?: boolean; currency?: string }
    paymentMethod: PaymentMethod
    email: string
    screenName: ScreenName
    paymentSystem: PaymentSystem
    stripeAccountId?: string
    stripeAccountName?: string
    isUpgraded?: boolean
    amountToPay?: number
    isPersonalDataAllowed: boolean
  }): void {
    this.logEventOrPushToQueue({
      event: eventName,
      eventProperty: {
        email,
        onboarding_id: '',
        trial,
        price,
        currency,
        product_id: productId,
        ...(productKey && { product_key: productKey }),
        screen_name: screenName,
        payment_system: paymentSystem,
        ...(stripeAccountName && { stripe_account_name: stripeAccountName }),
        ...(stripeAccountId && { stripe_account_id: stripeAccountId }),
        payment_method: paymentMethod || PaymentMethod.CREDIT_CARD,
        ...(isUpgraded && {
          is_upgraded: isUpgraded,
          amount_to_pay: amountToPay,
        }),
      },
      personalDataProperties: { email: isPersonalDataAllowed ? email : '' },
    })
  }

  logPurchaseCompleted({
    productId,
    productKey,
    priceDetails: { price, trial = false, currency = 'USD' },
    email,
    screenName,
    paymentSystem,
    stripeAccountId,
    stripeAccountName,
    paymentMethod,
    discountApplied,
    transactionId,
    utmSource = Source.INITIAL,
    isUpgraded,
    amountToPay,
    isPersonalDataAllowed,
  }: {
    productId: TProductId
    productKey?: string
    priceDetails: { price: number; trial?: boolean; currency?: string }
    email: string
    screenName: ScreenName
    paymentSystem: PaymentSystem
    stripeAccountId?: string
    stripeAccountName?: string
    paymentMethod?: PaymentMethod
    discountApplied?: string
    transactionId?: string
    utmSource?: Source
    isUpgraded?: boolean
    amountToPay?: number
    isPersonalDataAllowed: boolean
  }): void {
    this.logEventOrPushToQueue({
      event: Events.PURCHASE_COMPLETED,
      eventProperty: {
        email,
        onboarding_id: '',
        utm_source: utmSource,
        trial,
        price,
        currency,
        product_id: productId,
        ...(productKey && { product_key: productKey }),
        screen_name: screenName,
        payment_method: paymentMethod || PaymentMethod.CREDIT_CARD,
        payment_system: paymentSystem,
        ...(stripeAccountName && { stripe_account_name: stripeAccountName }),
        ...(stripeAccountId && { stripe_account_id: stripeAccountId }),
        ...(transactionId && { transaction_id: transactionId }),
        ...(discountApplied && { discount_applied: discountApplied }),
        ...(isUpgraded && {
          is_upgraded: isUpgraded,
          amount_to_pay: amountToPay,
        }),
      },
      personalDataProperties: { email: isPersonalDataAllowed ? email : '' },
    })
  }

  logPurchaseFailed({
    productId,
    productKey,
    priceDetails: { price, trial = false, currency = 'USD' },
    error: {
      description,
      type,
      code,
      errorData,
      isFromDeveloper,
      diagnosticsId,
    },
    screenName,
    email,
    paymentSystem,
    stripeAccountId,
    stripeAccountName,
    paymentMethod,
    isUpgraded,
    amountToPay,
    isPersonalDataAllowed,
  }: {
    productId: TProductId
    productKey?: string
    priceDetails: { price: number; trial?: boolean; currency?: string }
    error: {
      type: string
      description?: string
      code?: string
      errorData?: any
      isFromDeveloper?: boolean
      diagnosticsId?: string | null
    }
    screenName: ScreenName
    email: string
    paymentSystem: PaymentSystem
    stripeAccountId?: string
    stripeAccountName?: string
    paymentMethod?: PaymentMethod
    isUpgraded?: boolean
    amountToPay?: number
    isPersonalDataAllowed: boolean
  }): void {
    this.logEventOrPushToQueue({
      event: Events.PURCHASE_FAILED,
      eventProperty: {
        email,
        onboarding_id: '',
        trial,
        price,
        currency,
        error_type: type,
        payment_system: paymentSystem,
        product_id: productId,
        ...(productKey && { product_key: productKey }),
        screen_name: screenName,
        payment_method: paymentMethod || PaymentMethod.CREDIT_CARD,
        error_data: errorData,
        is_from_developer: isFromDeveloper,
        diagnostics_id: diagnosticsId,
        ...(stripeAccountName && { stripe_account_name: stripeAccountName }),
        ...(stripeAccountId && { stripe_account_id: stripeAccountId }),
        ...(description && { error_description: description }),
        ...(code && { error_code: code }),
        ...(isUpgraded && {
          is_upgraded: isUpgraded,
          amount_to_pay: amountToPay,
        }),
      },
      personalDataProperties: { email: isPersonalDataAllowed ? email : '' },
    })
  }

  logPurchaseScreenClosed({
    productId,
    screenName,
  }: {
    productId: TProductId
    screenName: ScreenName
  }): void {
    this.logEventOrPushToQueue({
      event: Events.PURCHASE_SCREEN_CLOSE,
      eventProperty: {
        product_id: productId,
        screen_name: screenName,
      },
    })
  }

  logUpsellPurchaseShown(): void {
    this.logEventOrPushToQueue({
      event: Events.UPSELL_PURCHASE_SHOW,
      eventProperty: {
        screen_name: ScreenName.UPSELL_OFFER,
      },
    })
  }

  logUpsellPurchaseClose(): void {
    this.logEventOrPushToQueue({
      event: Events.UPSELL_PURCHASE_CLOSE,
      eventProperty: {
        screen_name: ScreenName.UPSELL_OFFER,
      },
    })
  }

  logPaymentMethodPopupClose(): void {
    this.logEventOrPushToQueue({ event: Events.PAYMENT_METHOD_POPUP_CLOSE })
  }

  logPaymentMethodTap(): void {
    this.logEventOrPushToQueue({ event: Events.PAYMENT_METHOD_TAP })
  }

  // Chipolo upgrade
  logUpsellChipoloPageShown(): void {
    this.logEventOrPushToQueue({ event: Events.UPSELL_CHIPOLO_PAGE_SHOW })
  }

  logUpsellChipoloPageClosed(): void {
    this.logEventOrPushToQueue({ event: Events.UPSELL_CHIPOLO_PAGE_CLOSE })
  }

  logUpsellChipoloToggleEnabled(value: UpgradeItem): void {
    this.logEventOrPushToQueue({
      event: Events.UPSELL_CHIPOLO_TOGGLE_ENABLE,
      eventProperty: {
        toggle: value,
      },
    })
  }

  logUpsellChipoloToggleDisabled(value: UpgradeItem): void {
    this.logEventOrPushToQueue({
      event: Events.UPSELL_CHIPOLO_TOGGLE_DISABLE,
      eventProperty: {
        toggle: value,
      },
    })
  }

  logUpsellChipoloButtonTapped({
    buttonNumber,
    upgradeItems,
  }: {
    buttonNumber: string
    upgradeItems: UpgradeItem[]
  }): void {
    this.logEventOrPushToQueue({
      event: Events.UPSELL_CHIPOLO_BUTTON_TAP,
      eventProperty: {
        button_number: buttonNumber,
        toggle: upgradeItems.join(','),
      },
    })
  }

  logUpsellChipoloOfferSkipped(): void {
    this.logEventOrPushToQueue({ event: Events.UPSELL_CHIPOLO_OFFER_SKIP })
  }

  logUpsellChipoloCheckoutShown(): void {
    this.logEventOrPushToQueue({ event: Events.UPSELL_CHIPOLO_CHECKOUT_SHOW })
  }

  logUpsellChipoloCheckoutClosed(): void {
    this.logEventOrPushToQueue({ event: Events.UPSELL_CHIPOLO_CHECKOUT_CLOSE })
  }

  logUpsellChipoloPurchaseCompleted({
    email,
    transactionId,
    deliveryInfo: {
      firstName,
      lastName,
      address,
      optionalAddress,
      city,
      zipCode,
      state,
      country,
      phone,
    },
    isPersonalDataAllowed,
  }: {
    email: string
    transactionId: string
    deliveryInfo: IDeliveryInfo
    isPersonalDataAllowed: boolean
  }): void {
    const personalData = {
      email,
      first_name: firstName,
      last_name: lastName,
      address,
      optional_address: optionalAddress,
      city,
      zip_code: zipCode,
      state,
      country,
      phone,
    }

    this.logEventOrPushToQueue({
      event: Events.UPSELL_CHIPOLO_PURCHASE_COMPLETED,
      eventProperty: {
        transaction_id: transactionId,
        ...personalData,
      },
      personalDataProperties: isPersonalDataAllowed
        ? personalData
        : {
            email: '',
            first_name: '',
            last_name: '',
            address: '',
            optional_address: '',
            city: '',
            zip_code: '',
            state: '',
            country: '',
            phone: '',
          },
    })
  }

  logUpsellChipoloPurchaseFailed({
    error,
    email,
    deliveryInfo: {
      firstName,
      lastName,
      address,
      optionalAddress,
      city,
      zipCode,
      state,
      country,
      phone,
    },
    isPersonalDataAllowed,
  }: {
    error: string
    email: string
    deliveryInfo: IDeliveryInfo
    isPersonalDataAllowed: boolean
  }): void {
    const personalData = {
      email,
      first_name: firstName,
      last_name: lastName,
      address,
      optional_address: optionalAddress,
      city,
      zip_code: zipCode,
      state,
      country,
      phone,
    }

    this.logEventOrPushToQueue({
      event: Events.UPSELL_CHIPOLO_PURCHASE_FAILED,
      eventProperty: {
        error_type: error,
        ...personalData,
      },
      personalDataProperties: isPersonalDataAllowed
        ? personalData
        : {
            email: '',
            first_name: '',
            last_name: '',
            address: '',
            optional_address: '',
            city: '',
            zip_code: '',
            state: '',
            country: '',
            phone: '',
          },
    })
  }

  logOfflineProductPurchaseCompleted({
    isTrial,
    chipoloColor,
    userEmail,
    deliveryInfo: {
      firstName,
      lastName,
      address,
      optionalAddress,
      city,
      zipCode,
      state,
      country,
      phone,
    },
  }: {
    isTrial: boolean
    chipoloColor: string
    userEmail: string
    deliveryInfo: IDeliveryInfo
  }): void {
    this.logEventOrPushToQueue({
      event: Events.OFFLINE_PRODUCT_PURCHASE_COMPLETED,
      eventProperty: {
        is_trial: isTrial,
        device_color: chipoloColor,
        device_quantity: 1,
        user_email: userEmail,
        first_name: firstName,
        last_name: lastName,
        address,
        optional_address: optionalAddress,
        city,
        zip_code: zipCode,
        state,
        country,
        phone,
        order: 'Chipolo One',
      },
      isAmplitudeEvent: false,
    })
  }

  logLiveChatOpened(): void {
    this.logEventOrPushToQueue({ event: Events.LIVE_CHAT_OPENED })
  }

  logLiveChatClosed(): void {
    this.logEventOrPushToQueue({ event: Events.LIVE_CHAT_CLOSED })
  }

  logCheckNumberClicked(): void {
    this.logEventOrPushToQueue({
      event: Events.CHECK_NUMBER,
      isAmplitudeEvent: false,
    })
  }

  logPageScrolled({
    event,
    value,
    screenName,
  }: {
    event: Events
    value: number
    screenName: ScreenName
  }) {
    this.logEventOrPushToQueue({
      event,
      eventProperty: { screen_name: screenName, scrolled_part: `${value}%` },
    })
  }

  logVideoPlayStarted(): void {
    this.logEventOrPushToQueue({ event: Events.VIDEO_PLAY_STARTED })
  }

  logCookiesConsentShown(): void {
    this.logEventOrPushToQueue({
      event: Events.COOKIES_BANNER_SHOW,
      isAmplitudeEvent: false,
    })
  }

  logCookiesConsentSettingsTap(): void {
    this.logEventOrPushToQueue({
      event: Events.COOKIES_BANNER_SETTINGS_TAP,
      isAmplitudeEvent: false,
    })
  }

  logCookiesConsentAcceptAllTap(source: string): void {
    this.logEventOrPushToQueue({
      event: Events.COOKIES_ACCEPT_ALL_TAP,
      eventProperty: { source },
      isAmplitudeEvent: false,
    })
  }

  logCookiesConsentRejectAllTap(source: string): void {
    this.logEventOrPushToQueue({
      event: Events.COOKIES_REJECT_ALL_TAP,
      eventProperty: { source },
      isAmplitudeEvent: false,
    })
  }

  logCookiesConsentToggleEnable(option: string): void {
    this.logEventOrPushToQueue({
      event: Events.COOKIES_SETTINGS_TOGGLE_ENABLE,
      eventProperty: { option },
      isAmplitudeEvent: false,
    })
  }

  logCookiesConsentToggleDisable(option: string): void {
    this.logEventOrPushToQueue({
      event: Events.COOKIES_SETTINGS_TOGGLE_DISABLE,
      eventProperty: { option },
      isAmplitudeEvent: false,
    })
  }

  logCookiesConsentSettingsConfirmChoice(options: string): void {
    this.logEventOrPushToQueue({
      event: Events.COOKIES_SETTING_CONFIRM_CHOICES_TAP,
      eventProperty: { allowed_options: options },
      isAmplitudeEvent: false,
    })
  }

  logCookiesConsentSettingsScreenClose(): void {
    this.logEventOrPushToQueue({
      event: Events.COOKIES_SETTINGS_SCREEN_CLOSE,
      isAmplitudeEvent: false,
    })
  }

  logCookiesConsentExpandOptionTap(option: string): void {
    this.logEventOrPushToQueue({
      event: Events.COOKIES_SETTINGS_EXPAND_OPTION_TAP,
      eventProperty: { option },
      isAmplitudeEvent: false,
    })
  }

  logCookiePolicyClicked(): void {
    this.logEventOrPushToQueue({ event: Events.COOKIE_POLICY_TAP })
  }

  private logEventOrPushToQueue({
    event,
    eventProperty,
    personalDataProperties,
    isAmplitudeEvent = true,
    callback,
  }: {
    event: Events
    eventProperty?: Record<string, any>
    personalDataProperties?: Record<string, any>
    isAmplitudeEvent?: boolean
    callback?: () => void
  }): void {
    if (this.getIsGiaActive) {
      this.logEvent({
        event,
        eventProperty,
        personalDataProperties,
        isAmplitudeEvent,
        callback,
      })
    } else {
      this.eventsQueue.push({
        event,
        eventProperty,
        personalDataProperties,
        isAmplitudeEvent,
        callback,
      })
    }
  }

  private notifyInitFinished() {
    if (this.eventsQueue.length) {
      this.eventsQueue.forEach(
        ({
          event,
          eventProperty,
          personalDataProperties,
          isAmplitudeEvent,
          callback,
        }) =>
          this.logEvent({
            event,
            eventProperty,
            personalDataProperties,
            isAmplitudeEvent,
            callback,
          }),
      )
      this.eventsQueue = []
    }
  }

  private logEvent({
    event,
    eventProperty,
    personalDataProperties = {},
    isAmplitudeEvent = true,
    callback,
  }: {
    event: Events
    eventProperty?: Record<string, any>
    personalDataProperties?: Record<string, any>
    isAmplitudeEvent?: boolean
    callback?: () => void
  }): void {
    if (!isAmplitudeEvent) {
      this.loggers
        ?.get(EventLoggerInstanceName.GIA)
        ?.log(event, eventProperty, callback)

      this.loggers
        ?.get(EventLoggerInstanceName.USER_FLOW_TRACKER)
        ?.log(event, eventProperty, callback)
      return
    }

    if (this.loggers) {
      const eventLoggers = [...this.loggers.values()]
      eventLoggers.forEach((logger, index, array) => {
        const personalData =
          logger.name === EventLoggerInstanceName.AMPLITUDE
            ? personalDataProperties
            : {}

        index === array.length - 1
          ? logger.log(event, { ...eventProperty, ...personalData }, callback)
          : logger.log(event, { ...eventProperty, ...personalData })
      })
    }
  }
}

export const eventLogger = new EventLoggerService()
