import deviceIcon from 'assets/images/device-icon-red.png'
import gpsIcon from 'assets/images/gps-icon-yellow.png'
import geoIcon from 'assets/images/location-icon-green.png'

export const GEOLOCATION_BENEFITS = [
  {
    img: geoIcon,
    title: 'onboarding.introGoogle5.geolocationBenefits.data.easilyInput',
    text: 'onboarding.introGoogle5.geolocationBenefits.data.familySafety',
    borderColor: '#00B67A',
  },
  {
    img: deviceIcon,
    title: 'onboarding.introGoogle5.geolocationBenefits.data.suitable',
    text: 'onboarding.introGoogle5.geolocationBenefits.data.locationGeolocations',
    borderColor: '#FF91A4',
  },
  {
    img: gpsIcon,
    title: 'onboarding.introGoogle5.geolocationBenefits.data.assistant',
    text: 'onboarding.introGoogle5.geolocationBenefits.data.rescue',
    borderColor: '#FECF35',
  },
]
