import React, {
  useDeferredValue,
  useEffect,
  useLayoutEffect,
  useState,
} from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'

import { useFeatureValue } from '@growthbook/growthbook-react'
import { usePageInfo } from 'providers/PageInfoProvider'

import { resetErrorAction } from 'root-redux/actions/common'
import {
  GET_STATUS,
  SEND_USER_EMAIL,
  sendUserEmailAction,
} from 'root-redux/actions/user'
import {
  selectActionList,
  selectCurrentVariantCohortToUse,
  selectError,
} from 'root-redux/selects/common'
import { selectHasUserEmail } from 'root-redux/selects/user'

import { useCookieConsentAnswer } from 'hooks/useCookieConsentAnswer'
import { useDynamicOBConfig } from 'hooks/useDynamicOBConfig'
import { useEmailInputField } from 'hooks/useEmailInputField'
import { useUserData } from 'hooks/useUserData'

import { formatPhoneNumber } from 'helpers/phone'

import { TPageProps } from 'models/common.model'

import { eventLogger } from 'services/eventLogger.service'

import { Input } from 'components/Input'
import { Modal } from 'components/Modal'
import { PoliciesGroup } from 'components/PoliciesGroup'
import { Spinner } from 'components/Spinner'

import lockImg from 'assets/images/sprite/lock.svg'

import { goTo } from 'browser-history'
import { Cohort, GOOGLE_9_TEST_SEGMENTS } from 'root-constants/common'
import { PageId } from 'root-constants/pages'

import { getFilteredEmailDomains } from '../../helpers'
import { StyledEmailVariant2 as S } from './EmailVariant2.styles'

export const EmailVariant2: React.FC<TPageProps> = () => {
  const { t } = useTranslation()
  const { search } = useLocation()
  const dispatch = useDispatch()
  const fetchingActionsList = useSelector(selectActionList)
  const error = useSelector(selectError)
  const hasUserEmail = useSelector(selectHasUserEmail)
  const cohort = useSelector(selectCurrentVariantCohortToUse)

  const [areEmailTipsVisible, setAreEmailTipsVisible] = useState(false)
  const [isErrorModalShown, setIsErrorModalShown] = useState(false)

  const { title, subtitle, buttonText } = useDynamicOBConfig()
  const { isPersonalDataAllowed } = useCookieConsentAnswer()
  const [email, , validateEmail] = useEmailInputField()
  const deferredEmail = useDeferredValue(email.value)
  const { userPhoneNumber } = useUserData()
  const { currentPaymentPageId } = usePageInfo()

  const alternativePlanItem = useFeatureValue(
    'abtest_car_1007_checkout',
    GOOGLE_9_TEST_SEGMENTS.A_SEGMENT,
  )

  const formattedPhoneNumber = formatPhoneNumber(userPhoneNumber)

  const isStatusFetching =
    fetchingActionsList?.includes(SEND_USER_EMAIL) ||
    fetchingActionsList?.includes(GET_STATUS)

  const isButtonDisabled = !email.value || !email.isValid || isStatusFetching

  const filteredEmailDomains = getFilteredEmailDomains(deferredEmail)

  const isGoogleMainTenCohort = cohort === Cohort.GOOGLE_MAIN_10

  useLayoutEffect(() => {
    if (hasUserEmail) {
      goTo({
        pathname:
          alternativePlanItem !== GOOGLE_9_TEST_SEGMENTS.A_SEGMENT
            ? PageId.PAYMENT_5
            : currentPaymentPageId,
        search,
      })
    }
  }, [search, hasUserEmail, currentPaymentPageId, alternativePlanItem])

  useEffect(() => {
    if (hasUserEmail) return
    eventLogger.logEmailPageShown()
  }, [hasUserEmail])

  const handleSubmit = (e) => {
    e.preventDefault()

    if (!email.isValid || isStatusFetching) return

    dispatch(
      sendUserEmailAction({
        email: email.value,
        isPersonalDataAllowed,
        unsuccessCallback: () => setIsErrorModalShown(true),
      }),
    )
  }

  const handleChange = ({ target: { value } }) => {
    validateEmail(value)
    setAreEmailTipsVisible(true)
  }

  const handlePrefilledEmail = ({ currentTarget: { value } }) => {
    validateEmail(value)
    setAreEmailTipsVisible(false)
  }

  return isStatusFetching ? (
    <Spinner />
  ) : (
    <>
      <S.Wrapper>
        {!isGoogleMainTenCohort && (
          <S.Text>
            {t`onboarding.email.numberLocated`}
            <strong>{formattedPhoneNumber}</strong>
          </S.Text>
        )}
        <form onSubmit={handleSubmit}>
          <S.Content>
            <S.Title>{title || t`onboarding.email.title`}</S.Title>
            <S.Subtitle>
              {subtitle || (
                <Trans
                  i18nKey={
                    isGoogleMainTenCohort
                      ? 'onboarding.email.subtitle8'
                      : 'onboarding.email.subtitle5'
                  }
                />
              )}
            </S.Subtitle>

            <S.InputWrapper>
              <Input
                value={email.value}
                isValid={email.isValid}
                validationText={email.validationText}
                placeholder={t`onboarding.email.placeholder`}
                autoComplete="off"
                onChange={handleChange}
              />
              {areEmailTipsVisible && (
                <S.AutoCompleteWrapper>
                  {filteredEmailDomains.map((value) => (
                    <S.AutoCompleteButton
                      type="button"
                      key={value}
                      value={value}
                      onMouseDown={handlePrefilledEmail}
                    >
                      <S.AutoCompleteButtonTextWrapper>
                        {value}
                      </S.AutoCompleteButtonTextWrapper>
                    </S.AutoCompleteButton>
                  ))}
                </S.AutoCompleteWrapper>
              )}
            </S.InputWrapper>
            <S.Note>
              <S.LockImg svg={lockImg} />
              <S.Description>{t`onboarding.email.note`}</S.Description>
            </S.Note>
            <S.Footer data-is-auto-complete-shown={areEmailTipsVisible}>
              <S.Button type="submit" disabled={isButtonDisabled}>
                {buttonText || t`actions.continue`}
              </S.Button>
              <PoliciesGroup />
            </S.Footer>
          </S.Content>
        </form>
      </S.Wrapper>
      <Modal
        onClose={() => {
          dispatch(resetErrorAction())
          setIsErrorModalShown(false)
        }}
        isShown={isErrorModalShown}
      >
        {error}
      </Modal>
    </>
  )
}
