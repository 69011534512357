import React from 'react'
import { Trans, useTranslation } from 'react-i18next'

import chipoloExample from 'assets/images/chipolo-example.jpg'

import { StyledChipoloDescription as S } from './ChipoloDescription.styles'
import { CHIPOLO_BENEFITS } from './constants'

export const ChipoloDescription: React.FC = () => {
  const { t } = useTranslation()

  return (
    <S.Wrapper>
      <S.Title>
        <Trans i18nKey="subscriptions.chipolo.paywall.description.exclusive" />
      </S.Title>

      <S.Subtitle>
        <Trans i18nKey="subscriptions.chipolo.paywall.description.getFreeChipolo" />
      </S.Subtitle>
      <img src={chipoloExample} alt="chipolo-example" />

      <S.BenefitsBlock>
        <S.BenefitsBlockTitle>
          {t`subscriptions.chipolo.paywall.description.title`}
        </S.BenefitsBlockTitle>
        <S.BenefitsBlockSubtitle>
          {t`subscriptions.chipolo.paywall.description.subtitle`}
        </S.BenefitsBlockSubtitle>

        {CHIPOLO_BENEFITS.map(({ icon, title, benefit }) => (
          <S.BenefitsWrapper key={title}>
            <S.Icon src={icon} alt="benefit" />
            <S.Benefit>{t(title)}</S.Benefit>
            <S.BenefitDescription>{t(benefit)}</S.BenefitDescription>
          </S.BenefitsWrapper>
        ))}
      </S.BenefitsBlock>
    </S.Wrapper>
  )
}
