import styled, { css } from 'styled-components'

import periodBgGreen from 'assets/images/price-bg-light-green.svg'
import periodBgViolet from 'assets/images/price-bg-violet.svg'
import successIcon from 'assets/images/success-2.svg'

import { Color } from 'root-constants/common'

const activePlanItemStyles = css`
  border-color: #81e786;
  background-color: transparent;

  ::before {
    background: center url(${successIcon}) no-repeat;
  }
`

const defaultPlanItemStyles = css`
  position: relative;

  &::after {
    content: attr(data-text);
    display: block;
    padding: 4px 16px;
    position: absolute;
    top: -15px;
    left: 50%;
    transform: translateX(-50%);
    font-size: 10px;
    line-height: 18px;
    font-weight: 800;
    text-transform: uppercase;
    color: #15042a;
    background-color: #81e786;
    border-radius: 13px;
  }
`

const PlanItem = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 24px 24px 24px 64px;
  border-radius: 16px;
  border: 2px solid transparent;
  background-color: #312b4b;
  transition: all 0.2s ease-out;

  &[data-is-default='true'] {
    ${defaultPlanItemStyles};
  }

  ::before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 24px;
    width: 24px;
    height: 24px;
    margin: auto;
    border: 2px solid #969ea7;
    border-radius: 50%;
  }

  &[data-is-selected='true']::before {
    border-color: transparent;
  }
`

export const StyledTrialPlanItemVariant3 = {
  PlanItem: styled(PlanItem)`
    &[data-is-selected='true'] {
      ${activePlanItemStyles};
    }
  `,
  PlanTitleWrapper: styled.div`
    display: flex;
    flex-direction: column;
  `,
  PlanTitle: styled.div`
    flex-grow: 1;
    font-size: 18px;
    font-weight: 800;
    line-height: 22px;
    color: ${Color.WHITE};
  `,
  PlanPricesWrapper: styled.div`
    display: flex;
    align-items: center;
  `,
  OldPlanPrice: styled.div`
    margin-right: 6px;
    font-size: 14px;
    line-height: 22px;
    font-weight: 500;
    text-decoration: line-through;
    text-decoration-color: #d02d67;
    color: #969ea7;
  `,
  CurrentPrice: styled.div`
    margin-right: 6px;
    font-size: 14px;
    line-height: 22px;
    font-weight: 500;
    color: #969ea7;
  `,
  PlanPrice: styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 87px;
    height: 48px;
    color: ${Color.WHITE};
    background: top right url(${periodBgViolet}) no-repeat;

    strong {
      margin-left: 10px;
      font-size: 18px;
      font-weight: 800;
      line-height: 20px;
    }

    span {
      font-size: 10px;
      font-weight: 600;
      line-height: 10px;
    }

    &[data-is-selected='true'] {
      color: #15042a;
      background: top right url(${periodBgGreen}) no-repeat;
    }
  `,
}
