import fifteenYears from 'assets/images/fifteen-years.png'
import fiveYears from 'assets/images/five-years.png'
import sixteenYears from 'assets/images/sixteen-years.png'
import tenYears from 'assets/images/ten-years.png'

export const AGE_RANGE_DATA = [
  {
    text: '0-5',
    value: '5',
    image: fiveYears,
  },
  {
    text: '6-10',
    value: '10',
    image: tenYears,
  },
  {
    text: '11-15',
    value: '15',
    image: fifteenYears,
  },
  {
    text: '16+',
    value: '16',
    image: sixteenYears,
  },
]
