import styled from 'styled-components'

import { StripePaymentProcessing } from 'components/StripePaymentProcessing'

import { baseColumnStyles, basePageStyles } from 'common-styles'

export const StyledPaymentVariant6 = {
  Wrapper: styled.div`
    ${basePageStyles};
    padding-top: 53px;
    min-height: 100%;
  `,
  Content: styled.div`
    ${baseColumnStyles};
  `,
  ThreeDSecureIframe: styled.iframe`
    height: calc(var(--full-height, 100vh) - 50px) !important;
    padding: 15px;
  `,
  StripePaymentProcessing: styled(StripePaymentProcessing)`
    h2 {
      font-size: 18px;
      font-weight: 700;
      line-height: 24px;
      color: #17202a;
    }
    h3 {
      font-size: 13px;
      font-weight: 400;
      line-height: 16px;
      color: #626262;
    }
    button {
      font-size: 17px;
      font-weight: 700;
      line-height: 24px;
    }
  `,
}
