import { useMemo } from 'react'
import { useSelector } from 'react-redux'

import {
  selectCurrentVariantCohortToUse,
  selectCurrentVariantSteps,
} from 'root-redux/selects/common'
import {
  selectIsUpgradeAvailable,
  selectIsUpgradePassed,
  selectUserStatus,
} from 'root-redux/selects/user'

import { useChipoloUpgradeStatus } from 'hooks/useChipoloUpgradeStatus'
import { useGetPageInfo } from 'hooks/useGetPageInfo'
import { useUpsellStatus } from 'hooks/useUpsellStatus'

import { EMAIL_COHORTS, UPGRADE_COHORTS } from 'root-constants/common'
import { PageId } from 'root-constants/pages'

export const useUserStatus = (): string => {
  const steps = useSelector(selectCurrentVariantSteps)
  const userStatus = useSelector(selectUserStatus)
  const cohortToUse = useSelector(selectCurrentVariantCohortToUse)
  const isUpgradePassed = useSelector(selectIsUpgradePassed)
  const isUpgradeAvailable = useSelector(selectIsUpgradeAvailable)

  const { currentSubscriptionPageId, currentUpsellPageId } = useGetPageInfo()
  const isChipoAvailable = useChipoloUpgradeStatus()
  const isUpsellAvailable = useUpsellStatus()

  const isUpgradeCohort = UPGRADE_COHORTS.includes(cohortToUse)
  const isEmailCohort = EMAIL_COHORTS.includes(cohortToUse)

  return useMemo(() => {
    if (
      userStatus?.account.hasAccount &&
      isUpgradeCohort &&
      !isUpgradePassed &&
      isUpgradeAvailable
    ) {
      return PageId.PURCHASE_UPGRADE_1
    }

    if (
      (isUpgradeCohort &&
        (!userStatus?.account.hasAccount || !isUpgradeAvailable)) ||
      (isEmailCohort && !userStatus?.email.hasEmail)
    ) {
      return PageId.PROXY_TO_START
    }

    if (userStatus?.account.hasAccount) {
      return PageId.DOWNLOAD
    }

    if (isUpsellAvailable) {
      return currentUpsellPageId
    }

    if (isChipoAvailable) {
      return PageId.CHIPOLO_UPGRADE_1
    }

    if (userStatus?.hasSubscription) {
      return PageId.ACCOUNT
    }

    if (userStatus?.email.hasEmail) {
      return currentSubscriptionPageId || steps[0].id
    }

    return ''
  }, [
    userStatus?.account.hasAccount,
    userStatus?.email.hasEmail,
    userStatus?.hasSubscription,
    isUpgradeCohort,
    isUpgradePassed,
    isUpgradeAvailable,
    isEmailCohort,
    isUpsellAvailable,
    isChipoAvailable,
    currentUpsellPageId,
    currentSubscriptionPageId,
    steps,
  ])
}
