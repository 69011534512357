import styled from 'styled-components'

import { baseColumnStyles, basePageStyles } from 'common-styles'

type TTitleProps = {
  titleFontSize?: number
  titleLineHeight?: number
  marginBottom?: number
}

type TWrapperProps = {
  wrapperPaddingTop?: number
}

type TColumnProps = {
  columnPaddingTop?: number
}

export const StyledRadioOptionPage = {
  Wrapper: styled.div<TWrapperProps>`
    ${basePageStyles};
    padding-top: ${({ wrapperPaddingTop }) => wrapperPaddingTop || 0}px;
  `,
  Column: styled.div<TColumnProps>`
    ${baseColumnStyles};
    padding-bottom: 13px;
    padding-top: ${({ columnPaddingTop }) => columnPaddingTop || 40}px;
    justify-content: ${({ theme }) =>
      theme.arrangements.columnVerticalAlignment};
    height: ${({ theme }) => theme.dimensions.columnHeight};
  `,
  Title: styled.h2<TTitleProps>`
    margin-bottom: ${({ marginBottom }) => marginBottom || 16}px;
    text-align: center;
    font-size: ${({ titleFontSize }) => `${titleFontSize || 24}px`};
    font-weight: 700;
    line-height: ${({ titleLineHeight }) => `${titleLineHeight || 33}px`};
    color: ${({ theme }) => theme.colors.primaryText};
  `,
}
