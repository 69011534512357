import { amazonShortenerApi, userApi } from 'api'
import i18n from 'i18next'

import {
  setAnswersFromBackendAction,
  setErrorAction,
  startFetching,
  stopFetching,
} from 'root-redux/actions/common'
import { selectAnswers } from 'root-redux/selects/common'
import { selectUUID, selectUtmTags } from 'root-redux/selects/user'

import { getCookie } from 'helpers/getCookie'
import { getUserStatusFromRawUserStatus } from 'helpers/getUserStatusFromRawUserStatus'

import { TAnswers } from 'models/common.model'
import { IAction, IAppState, TAppDispatchThunk } from 'models/store.model'
import {
  IDeliveryInfo,
  IGetUUIDResponseRaw,
  IUserStatus,
  TUserConfig,
} from 'models/user.model'

import { eventLogger } from 'services/eventLogger.service'

import { selectPlanAdditions } from 'modules/payment/redux/selects'

import { STORE_ONE_LINK } from 'root-constants/common'

const MODULE_NAME = 'USER'

export const SET_UUID = `${MODULE_NAME}/SET_UUID`
export const GET_STATUS = `${MODULE_NAME}/GET_STATUS`
export const SET_STATUS = `${MODULE_NAME}/SET_STATUS`
export const SEND_FACEBOOK_PARAMS = `${MODULE_NAME}/SEND_FACEBOOK_PARAMS`
export const SEND_USER_ANSWERS = `${MODULE_NAME}/SEND_USER_ANSWERS`
export const SEND_USER_EMAIL = `${MODULE_NAME}/SEND_USER_EMAIL`
export const SEND_USER_CONFIG = `${MODULE_NAME}/SEND_USER_CONFIG`
export const UPDATE_USER_CONFIG = `${MODULE_NAME}/UPDATE_USER_CONFIG`
export const GET_SHORTENED_ONE_LINK = `${MODULE_NAME}/GET_SHORTENED_ONE_LINK`
export const SET_SHORTENED_ONE_LINK = `${MODULE_NAME}/SET_SHORTENED_ONE_LINK`
export const SET_UTM_TAGS = `${MODULE_NAME}/SET_UTM_TAGS`
export const SET_DELIVERY_INFO = `${MODULE_NAME}/SET_DELIVERY_INFO`
export const SET_COOKIE_CONSENT = `${MODULE_NAME}/SET_COOKIE_CONSENT`
const BIND_USER = `${MODULE_NAME}/BIND_USER`
const SEND_PLAN_ADDITIONS = `${MODULE_NAME}/SEND_PLAN_ADDITIONS`

export function setUUIDAction(uuid: string): IAction<string> {
  return {
    type: SET_UUID,
    payload: uuid,
  }
}

export function setUserStatusAction(
  userStatus: IUserStatus,
): IAction<IUserStatus> {
  return {
    type: SET_STATUS,
    payload: userStatus,
  }
}

export const setUpUUIDAction =
  ({
    cohort,
    uuid: uuidFromUrl,
    utmTags: utmTagsFromUrl,
    giaApiKey,
    language,
  }: {
    cohort: string
    uuid: string | null
    utmTags: Record<string, string> | null
    giaApiKey: string
    language: string
  }): any =>
  async (
    dispatch: TAppDispatchThunk<any>,
    getState: () => IAppState,
  ): Promise<void> => {
    const state = getState()
    const uuidFromStore = selectUUID(state)
    const uuid = uuidFromUrl || uuidFromStore
    const utmTagsFromStore = selectUtmTags(state)
    const utmTags = utmTagsFromUrl || utmTagsFromStore

    if (uuid) {
      dispatch(setUUIDAction(uuid))
      return
    }

    const response = await userApi.getUUID({
      cohort,
      appId: giaApiKey,
      locale: language,
      utm: utmTags,
    })

    if (response.success) {
      const responseUUID = (response.data as IGetUUIDResponseRaw).value
      dispatch(setUUIDAction(responseUUID))
    } else {
      console.error('error')
    }
  }

export const sendFacebookParamsAction =
  (): any =>
  async (
    dispatch: TAppDispatchThunk<any>,
    getState: () => IAppState,
  ): Promise<void> => {
    const state = getState()
    const uuid = selectUUID(state)
    const fbp = getCookie('_fbp')
    const fbc = getCookie('_fbc')

    dispatch(startFetching(SEND_FACEBOOK_PARAMS))

    const response = await userApi.saveFacebookParams({
      uuid,
      fbp,
      fbc,
    })

    if (!response.success) {
      dispatch(setErrorAction(i18n.t('commonComponents.commonError')))
    }

    dispatch(stopFetching(SEND_FACEBOOK_PARAMS))
  }

export function bindUserAction(token: string): any {
  return async (
    dispatch: TAppDispatchThunk<any>,
    getState: () => IAppState,
  ) => {
    const state = getState()
    const uuid = selectUUID(state)

    dispatch(startFetching(BIND_USER))

    const response = await userApi.bindUser({ token, uuid })

    if (response.success && response.data) {
      const userStatusResponse = await userApi.getUserStatus(uuid)
      const userStatus = getUserStatusFromRawUserStatus(
        userStatusResponse.data.state,
      )

      dispatch(setUserStatusAction(userStatus))
      eventLogger.logAccountCreated({
        method: userStatus.account.loginMethod,
      })
    } else {
      eventLogger.logAccountCreationFailed({ error: response?.data?.error })

      if (response.status === 409) {
        const errorMessage = response?.data?.error || ''
        const message = errorMessage.toLowerCase().includes('subscription')
          ? i18n.t('login.refunded')
          : i18n.t('login.emailExists')

        dispatch(setErrorAction(message))
        dispatch(stopFetching(BIND_USER))
        return
      }

      dispatch(setErrorAction('Error: Something went wrong.'))
    }

    dispatch(stopFetching(BIND_USER))
  }
}

export function getUserStatusAction(uuid: string): any {
  return async (dispatch: TAppDispatchThunk<any>) => {
    dispatch(startFetching(GET_STATUS))

    const response = await userApi.getUserStatus(uuid)

    if (response.success && response.data) {
      const { state } = response.data

      const userStatus = getUserStatusFromRawUserStatus(state)
      dispatch(setUserStatusAction(userStatus))
      dispatch(setAnswersFromBackendAction(userStatus.onboarding))
    }

    if (!response.success && response.status === 404) {
      const { search } = window.location
      const urlParams = new URLSearchParams(search)
      urlParams.delete('uuid')

      // use window.location.search to reload the page with updated query params
      window.location.search = `?${urlParams}`
    }

    dispatch(stopFetching(GET_STATUS))
  }
}

export const sendUserAnswersAction =
  (isFinished = false, successCallback?: () => void): any =>
  async (
    dispatch: TAppDispatchThunk<any>,
    getState: () => IAppState,
  ): Promise<void> => {
    const state = getState()
    const answers = selectAnswers(state) as TAnswers
    const uuid = selectUUID(state)

    dispatch(startFetching(SEND_USER_ANSWERS))

    const response = await userApi.saveUserAnswers({
      uuid,
      answers,
      isFinished,
    })

    if (!response.success) {
      dispatch(setErrorAction(i18n.t('commonComponents.commonError')))
    }

    successCallback && successCallback()
    dispatch(stopFetching(SEND_USER_ANSWERS))
  }

export const sendUserConfigAction =
  (config: TUserConfig, unsuccessCallback?: (() => void) | null): any =>
  async (
    dispatch: TAppDispatchThunk<any>,
    getState: () => IAppState,
  ): Promise<void> => {
    const state = getState()
    const uuid = selectUUID(state)

    dispatch(startFetching(SEND_USER_CONFIG))

    const response = await userApi.saveUserConfig({
      uuid,
      config,
    })

    if (!response.success) {
      dispatch(setErrorAction(i18n.t('commonComponents.commonError')))
      unsuccessCallback && unsuccessCallback()
    }

    dispatch(stopFetching(SEND_USER_CONFIG))
  }

export function updateUserConfigAction(
  value: TUserConfig,
): IAction<TUserConfig> {
  return {
    type: UPDATE_USER_CONFIG,
    payload: value,
  }
}

export const sendUserEmailAction =
  ({
    email,
    isPersonalDataAllowed,
    unsuccessCallback,
  }: {
    email: string
    isPersonalDataAllowed: boolean
    unsuccessCallback?: (() => void) | null
  }): any =>
  async (
    dispatch: TAppDispatchThunk<any>,
    getState: () => IAppState,
  ): Promise<void> => {
    const state = getState()
    const uuid = selectUUID(state)

    dispatch(startFetching(SEND_USER_EMAIL))

    const response = await userApi.saveUserEmail({
      uuid,
      email,
    })

    if (!response.success) {
      if (response.status === 409) {
        dispatch(setErrorAction(i18n.t('login.accountExists')))
      } else {
        dispatch(setErrorAction(i18n.t('commonComponents.commonError')))
      }

      eventLogger.logEmailPageError({ error: JSON.stringify(response.data) })
      unsuccessCallback && unsuccessCallback()
      dispatch(stopFetching(SEND_USER_EMAIL))
      return
    }

    eventLogger.logEmailPageCompleted({ email })
    window.fbq('track', 'Lead', {}, { eventID: uuid })
    window.ttq.identify({ email: isPersonalDataAllowed ? email : '' })
    window.ttq.track('CompleteRegistration')

    dispatch(getUserStatusAction(uuid))
    dispatch(stopFetching(SEND_USER_EMAIL))
  }

export const sendPlanAdditionsAction =
  (): any =>
  async (
    dispatch: TAppDispatchThunk<any>,
    getState: () => IAppState,
  ): Promise<void> => {
    const state = getState()
    const uuid = selectUUID(state)
    const planAdditions = selectPlanAdditions(state)

    if (!planAdditions.length) return

    dispatch(startFetching(SEND_PLAN_ADDITIONS))

    const response = await userApi.savePlanAdditions({
      uuid,
      planAdditions,
    })

    if (!response.success) {
      const errorMessage =
        response?.data?.error || i18n.t('commonComponents.commonError')

      dispatch(setErrorAction(errorMessage))
      dispatch(stopFetching(SEND_PLAN_ADDITIONS))
      return
    }

    dispatch(stopFetching(SEND_PLAN_ADDITIONS))
  }

export function setShortenedOneLinkAction(payload: string): IAction<string> {
  return {
    type: SET_SHORTENED_ONE_LINK,
    payload,
  }
}

export function getShortenedOneLinkAction(unsuccessCallback: () => void): any {
  return async (
    dispatch: TAppDispatchThunk<any>,
    getState: () => IAppState,
  ) => {
    const state = getState()
    const uuid = selectUUID(state)

    dispatch(startFetching(GET_SHORTENED_ONE_LINK))

    const autologinTokenResponse = await userApi.getAutologinToken(uuid)

    if (!autologinTokenResponse.success || !autologinTokenResponse.data) {
      dispatch(setErrorAction(i18n.t('commonComponents.commonError')))
      dispatch(stopFetching(GET_SHORTENED_ONE_LINK))
      unsuccessCallback()
      return
    }

    const shortenedLinkResponse = await amazonShortenerApi.getShortenedLink(
      `${STORE_ONE_LINK}&deep_link_value=${autologinTokenResponse.data.custom_token}`,
    )

    if (!shortenedLinkResponse.success || !shortenedLinkResponse.data) {
      dispatch(setErrorAction(i18n.t('commonComponents.commonError')))
      dispatch(stopFetching(GET_SHORTENED_ONE_LINK))
      unsuccessCallback()
      return
    }

    dispatch(
      setShortenedOneLinkAction(
        `https://${shortenedLinkResponse.data.shortUrl}`,
      ),
    )
    dispatch(stopFetching(GET_SHORTENED_ONE_LINK))
  }
}

export function setUtmTagsAction(
  payload: Record<string, string>,
): IAction<Record<string, string>> {
  return {
    type: SET_UTM_TAGS,
    payload,
  }
}

export function setDeliveryInfoAction(
  payload: IDeliveryInfo,
): IAction<IDeliveryInfo> {
  return {
    type: SET_DELIVERY_INFO,
    payload,
  }
}

export function setCookieConsentAction(payload: string[]): IAction<string[]> {
  return {
    type: SET_COOKIE_CONSENT,
    payload,
  }
}
