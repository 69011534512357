import styled from 'styled-components'

import { PhoneForm } from 'modules/subscriptions/components/google/PhoneForm'

import { BurgerMenu } from 'components/BurgerMenu'
import { Button } from 'components/Button'

import { baseColumnStyles, basePageStyles } from 'common-styles'
import { Color, HEADER_HEIGHT, MediaBreakpoint } from 'root-constants/common'

export const StyledSubscriptionsGoogleVariant3 = {
  Wrapper: styled.div`
    ${basePageStyles};
    padding-top: ${HEADER_HEIGHT}px;
    padding-bottom: 25px;
  `,
  BurgerMenu: styled(BurgerMenu)`
    button {
      top: 6px;
      right: 6px;

      @media (min-width: ${MediaBreakpoint.LAPTOP}px) {
        top: 3px;
        right: 3px;
      }
    }
  `,
  Content: styled.div`
    width: 100%;
    max-width: ${MediaBreakpoint.MAX_PHONE}px;
    height: calc(100dvh - ${HEADER_HEIGHT}px);
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    background: radial-gradient(
        77.02% 49.98% at 123.2% 68.28%,
        rgba(255, 255, 255, 0.2) 0%,
        rgba(232, 214, 255, 0.2) 42.71%,
        rgba(255, 255, 255, 0.2) 83.25%
      ),
      radial-gradient(
        72.83% 45.25% at -34.27% 25.76%,
        rgba(255, 255, 255, 0.2) 0%,
        rgba(232, 214, 255, 0.2) 62.55%,
        rgba(255, 255, 255, 0.2) 96.24%
      ),
      #fff;

    @media (min-width: ${MediaBreakpoint.MAX_PHONE}px) {
      max-width: 375px;
    }
  `,
  Column: styled.div`
    ${baseColumnStyles};
    width: 331px;
  `,
  Title: styled.h1`
    margin-bottom: 12px;
    font-size: 32px;
    font-weight: 800;
    line-height: 38px;
    text-align: center;
    margin-top: 24px;

    strong {
      font-size: 32px;
      font-weight: 800;
      line-height: 38px;
      color: ${Color.BLUE};
    }
  `,
  Text: styled.p`
    margin-bottom: 32px;
    text-align: center;
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
  `,
  Tracker: styled.div`
    display: flex;
    margin-bottom: 32px;
    text-align: center;

    p {
      margin: 0 10px;
      font-size: 20px;
      font-weight: 800;
      line-height: 22px;
    }

    strong {
      font-size: 32px;
    }

    img {
      width: 46px;
      height: 87px;
    }
  `,
  PhoneForm: styled(PhoneForm)`
    button {
      margin-top: 0;
    }
  `,
  PhoneImageContainer: styled.div`
    width: 100%;
    aspect-ratio: 375/415;
    max-width: ${MediaBreakpoint.MAX_PHONE}px;
    margin-bottom: 24px;

    @media (min-width: ${MediaBreakpoint.MAX_PHONE}px) {
      max-width: 375px;
    }
  `,
  FeaturedTitle: styled.h3`
    margin-bottom: 24px;
    text-align: center;
    font-size: 24px;
    font-weight: 800;
    line-height: 28px;
  `,
  FeaturedImage: styled.img`
    max-width: 327px;
    margin-bottom: 48px;
  `,
  Button: styled(Button)`
    margin-bottom: 32px;
  `,
  VideoContainer: styled.div`
    position: relative;
    padding-bottom: 56.25%;
    z-index: 1;
    max-width: ${MediaBreakpoint.MAX_PHONE}px;
    width: 100%;
    margin-bottom: 48px;

    @media (min-width: ${MediaBreakpoint.MAX_PHONE}px) {
      max-width: 375px;
    }

    iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border: 0;
    }
  `,
  PhoneFormTitle: styled.h3`
    margin-bottom: 16px;
    padding: 0 10px;
    text-align: center;
    font-size: 20px;
    font-weight: 600;
    line-height: 27px;
  `,
  BenefitsTitle: styled.h3`
    text-align: center;
    font-size: 24px;
    font-weight: 800;
    line-height: 30px;
  `,
}
