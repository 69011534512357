import React, { useEffect, useMemo, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router'

import { selectGeneralSubscriptionTrialPeriod } from 'root-redux/selects/user'

import { useGetPrices } from 'hooks/useGetPrices'
import { usePaywall } from 'hooks/usePaywall'
import { useViewportValue } from 'hooks/useViewportValue'

import { Events, ScreenName, eventLogger } from 'services/eventLogger.service'

import { purchaseUpgradeV2 } from 'modules/payment/redux/actions/common'
import { ChipoloDescription } from 'modules/subscriptions/components/chipolo/ChipoloDescription'
import { ChipoloOffer } from 'modules/subscriptions/components/chipolo/ChipoloOffer'
import { BenefitsVariant3 } from 'modules/subscriptions/components/google/Benefits'
import { CHIPOLO_BENEFITS } from 'modules/subscriptions/components/google/Benefits/constants'
import { StepsVariant1 } from 'modules/subscriptions/components/google/Steps'
import {
  CHIPOLO_STEPS_DATA,
  UPGRADE_ITEMS,
  UpgradeItem,
} from 'modules/subscriptions/constants'

import { DesktopProvider } from 'components/DesktopProvider'
import { Security } from 'components/Security'
import { Spinner } from 'components/Spinner'
import { StripePaymentProcessing } from 'components/StripePaymentProcessing'

import { goTo } from 'browser-history'
import {
  DISCLAIMER_CONTEXTS,
  SubscriptionListType,
  SubscriptionTag,
} from 'root-constants/common'
import { PageId } from 'root-constants/pages'

import { StyledChipoloUpgradeVariant1 as S } from './ChipoloUpgradeVariant1.styles'

export const ChipoloUpgradeVariant1: React.FC = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const { search } = useLocation()
  const trialPeriodDays = useSelector(selectGeneralSubscriptionTrialPeriod)

  const [upgradeItems, setUpgradeItems] = useState(UPGRADE_ITEMS.BOTH)

  const { hasPrices } = usePaywall('')

  useViewportValue(Events.UPSELL_CHIPOLO_PAGE_SCROLLED, ScreenName.UPGRADE)

  useGetPrices({
    subscriptionType: SubscriptionListType.UPGRADE,
    tags: SubscriptionTag.TAX,
    screenName: ScreenName.UPGRADE,
  })

  const areBothSelected = useMemo(
    () => upgradeItems.length === UPGRADE_ITEMS.BOTH.length,
    [upgradeItems.length],
  )

  const hasUpgradeOnly = useMemo(
    () =>
      !!upgradeItems.length &&
      upgradeItems.every((plan) => plan === UpgradeItem.SUBSCRIPTION),
    [upgradeItems],
  )

  const buttonText = useMemo(
    () =>
      !upgradeItems.length
        ? t`subscriptions.chipolo.paywall.skipTheOffer`
        : t`subscriptions.chipolo.paywall.claimTheOffer`,
    [t, upgradeItems.length],
  )

  const disclaimerText = useMemo(() => {
    if (hasUpgradeOnly) {
      return t('subscriptions.chipolo.paywall.noChipoloDisclaimer', {
        context: DISCLAIMER_CONTEXTS[trialPeriodDays],
      })
    }

    return ''
  }, [hasUpgradeOnly, t, trialPeriodDays])

  useEffect(() => {
    eventLogger.logUpsellChipoloPageShown()

    return () => {
      eventLogger.logUpsellChipoloPageClosed()
    }
  }, [])

  const upgradePlan = () => {
    dispatch(purchaseUpgradeV2())
  }

  const handleChange = ({ target: { value, checked } }) => {
    if (checked) {
      const newUpgradeItems =
        value === UpgradeItem.SUBSCRIPTION
          ? UPGRADE_ITEMS.UPGRADE
          : UPGRADE_ITEMS.BOTH

      setUpgradeItems(newUpgradeItems)
      eventLogger.logUpsellChipoloToggleEnabled(value)
      return
    }

    const newUpgradeItems =
      value === UpgradeItem.CHIPOLO ? UPGRADE_ITEMS.UPGRADE : UPGRADE_ITEMS.NONE
    setUpgradeItems(newUpgradeItems)
    eventLogger.logUpsellChipoloToggleDisabled(value)
  }

  const handleContinue = ({ target }) => {
    if (areBothSelected) {
      eventLogger.logUpsellChipoloButtonTapped({
        buttonNumber: target.dataset.buttonNumber,
        upgradeItems,
      })
      goTo({ pathname: PageId.CHIPOLO_CHECKOUT, search })
      return
    }

    if (hasUpgradeOnly) {
      eventLogger.logUpsellChipoloButtonTapped({
        buttonNumber: target.dataset.buttonNumber,
        upgradeItems,
      })
      upgradePlan()
      return
    }

    goTo({ pathname: PageId.ACCOUNT, search })
    eventLogger.logUpsellChipoloOfferSkipped()
  }

  return !hasPrices ? (
    <Spinner />
  ) : (
    <DesktopProvider>
      <S.Wrapper>
        <S.Column>
          <S.Title>{t`subscriptions.chipolo.paywall.ultimatePeace`}</S.Title>
          <S.Offer>{t`subscriptions.chipolo.paywall.exclusiveOffer`}</S.Offer>
          <S.Text>
            <Trans i18nKey="subscriptions.chipolo.paywall.upgradeTo" />
          </S.Text>
          <ChipoloOffer
            upgradeValue={UpgradeItem.SUBSCRIPTION}
            chipoloValue={UpgradeItem.CHIPOLO}
            isUpgradeSelected={upgradeItems.includes(UpgradeItem.SUBSCRIPTION)}
            isChipoloSelected={upgradeItems.includes(UpgradeItem.CHIPOLO)}
            onChange={handleChange}
          />
          <S.Button
            onClick={handleContinue}
            hasDisclaimer={!!disclaimerText}
            data-button-number={1}
          >
            {buttonText}
          </S.Button>
          {!!disclaimerText && <S.Disclaimer>{disclaimerText}</S.Disclaimer>}

          <BenefitsVariant3 benefits={CHIPOLO_BENEFITS} />
        </S.Column>
        <ChipoloDescription />

        <S.Column>
          <ChipoloOffer
            upgradeValue={UpgradeItem.SUBSCRIPTION}
            chipoloValue={UpgradeItem.CHIPOLO}
            isUpgradeSelected={upgradeItems.includes(UpgradeItem.SUBSCRIPTION)}
            isChipoloSelected={upgradeItems.includes(UpgradeItem.CHIPOLO)}
            onChange={handleChange}
          />
          <S.Button
            onClick={handleContinue}
            hasDisclaimer={!!disclaimerText}
            data-button-number={2}
          >
            {buttonText}
          </S.Button>
          {!!disclaimerText && <S.Disclaimer>{disclaimerText}</S.Disclaimer>}

          <S.Subtitle>
            <Trans i18nKey="onboarding.introGoogle4.howItWorks" />
          </S.Subtitle>
          <StepsVariant1 stepsData={CHIPOLO_STEPS_DATA} marginBottom={48} />
          <ChipoloOffer
            upgradeValue={UpgradeItem.SUBSCRIPTION}
            chipoloValue={UpgradeItem.CHIPOLO}
            isUpgradeSelected={upgradeItems.includes(UpgradeItem.SUBSCRIPTION)}
            isChipoloSelected={upgradeItems.includes(UpgradeItem.CHIPOLO)}
            onChange={handleChange}
          />
          <S.Button
            onClick={handleContinue}
            hasDisclaimer={!!disclaimerText}
            data-button-number={3}
          >
            {buttonText}
          </S.Button>
          {!!disclaimerText && <S.Disclaimer>{disclaimerText}</S.Disclaimer>}

          <Security />
        </S.Column>
        <StripePaymentProcessing
          onTryAgain={upgradePlan}
          hasChipolo={areBothSelected}
        />
      </S.Wrapper>
    </DesktopProvider>
  )
}
