import styled from 'styled-components'

import { Color } from 'root-constants/common'

const getBorderStyles = (isValid: boolean, isOpen: boolean) => {
  if (!isValid) return `1px solid ${Color.ERROR}`
  if (isOpen) return `1px solid ${Color.ACCENT_VIOLET}`
  return '1px solid transparent'
}

export const customSelectStyles = (isValid: boolean) => ({
  menu: () => ({
    borderRadius: '12px',
    boxShadow: '0px 7px 20px 0px #c8d4d8',
  }),
  control: (_, state) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    height: '56px',
    padding: '24px 16px 8px',
    backgroundColor: Color.GREY,
    border: getBorderStyles(isValid, state.selectProps.menuIsOpen),
    borderRadius: '12px',
  }),
  valueContainer: (provided) => ({
    ...provided,
    margin: 0,
    padding: 0,
    overflow: 'visible',
    position: 'relative',
    alignSelf: 'flex-end',
    fontWeight: 700,
  }),
  dropdownIndicator: (provided, state) => ({
    ...provided,
    position: 'absolute',
    top: '16px',
    right: '16px',
    padding: 0,
    transform: state.selectProps.menuIsOpen ? 'rotate(180deg)' : 'rotate(0deg)',
  }),
  indicatorSeparator: () => ({
    display: 'none',
  }),
  input: (provided) => ({
    ...provided,
    margin: 0,
    padding: 0,
  }),
  singleValue: (provided) => ({
    ...provided,
    margin: 0,
    padding: 0,
  }),
})

export const StyledSelectWithFloatPlaceholder = {
  Root: styled.div`
    position: relative;
    margin-bottom: 24px;
  `,
  Option: styled.div`
    display: flex;
    align-items: center;
    padding: 16px;
    font-size: 15px;
    font-weight: 400;
    line-height: 20px;
    color: ${Color.DEFAULT_TEXT};
    border-bottom: 1px solid #e6e6e6;
    cursor: pointer;

    &:last-child {
      border-bottom: 0;
    }
  `,
  Label: styled.label<{ isFloating?: boolean }>`
    position: absolute;
    top: ${({ isFloating }) => (isFloating ? '15px' : '50%')};
    transform: translateY(-50%);
    left: 16px;
    color: ${Color.LIGHT_TEXT};
    font-size: ${({ isFloating }) => (isFloating ? '12px' : '16px')};
    line-height: ${({ isFloating }) => (isFloating ? '16px' : '24px')};
    transition-property: top, font-size, line-height;
    transition-duration: 0.2s;
    transition-timing-function: ease;
    pointer-events: none;
    cursor: text;
  `,
}
