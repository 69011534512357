import { useLayoutEffect, useMemo } from 'react'
import { useSelector } from 'react-redux'

import { selectLanguage, selectTiktokPixelId } from 'root-redux/selects/common'
import { selectEmail, selectUserCountryCode } from 'root-redux/selects/user'

import { useCookieConsentAnswer } from 'hooks/useCookieConsentAnswer'

import { Language } from 'root-constants/common'

export const useTiktokPixelInitialization = (): void => {
  const tiktokPixelId = useSelector(selectTiktokPixelId)
  const email = useSelector(selectEmail)
  const userCountryCode = useSelector(selectUserCountryCode)
  const language = useSelector(selectLanguage)

  const { isEUUser, isPersonalDataAllowed, hasAdvertisingAndTargetingCookie } =
    useCookieConsentAnswer()

  const shouldTiktokPixelBePaused = useMemo(
    () =>
      isEUUser && !hasAdvertisingAndTargetingCookie && language === Language.EN,
    [hasAdvertisingAndTargetingCookie, isEUUser, language],
  )

  useLayoutEffect(() => {
    if (!tiktokPixelId || !userCountryCode || shouldTiktokPixelBePaused) return

    window.ttq.load(tiktokPixelId)
    window.ttq.identify({ email: isPersonalDataAllowed ? email : '' })
    window.ttq.page()
  }, [
    email,
    isPersonalDataAllowed,
    shouldTiktokPixelBePaused,
    tiktokPixelId,
    userCountryCode,
  ])
}
