import React from 'react'

import { StyledSwitch as S } from './Switch.styles'

type TSwitchTypes = {
  value: string
  isChecked: boolean
  disabled?: boolean
  onChange: (event) => void
}
export const Switch: React.FC<TSwitchTypes> = ({
  value,
  isChecked = true,
  disabled = false,
  onChange,
  ...props
}) => (
  <S.Root {...props}>
    <S.Input
      value={value}
      checked={isChecked}
      disabled={disabled}
      onChange={onChange}
      type="checkbox"
    />
    <S.Button />
    <S.Track />
  </S.Root>
)
