import styled from 'styled-components'

import { PaymentRequestButton } from 'modules/payment/components/PaymentRequestButton'

import { Guarantee } from 'components/Guarantee'

import checkIcon from 'assets/images/check-violet.svg'

import { baseColumnStyles, basePageStyles } from 'common-styles'
import { Color, HEADER_HEIGHT, MediaBreakpoint } from 'root-constants/common'

type TContentProps = {
  hasLighterBg: boolean
}

export const StyledPaymentVariant5 = {
  Wrapper: styled.div`
    ${basePageStyles};
    padding-top: ${HEADER_HEIGHT}px;
  `,
  Content: styled.div<TContentProps>`
    width: 100%;
    max-width: ${MediaBreakpoint.MAX_PHONE}px;
    min-height: calc(100dvh - ${HEADER_HEIGHT}px);
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding: 32px;
    background-color: ${({ hasLighterBg }) =>
      hasLighterBg ? '#f8f8ff' : '#f5f5ff'};

    @media (min-width: ${MediaBreakpoint.MAX_PHONE}px) {
      max-width: 375px;
    }
  `,
  Column: styled.div`
    ${baseColumnStyles};
  `,
  Title: styled.h1`
    margin-bottom: 32px;
    text-align: center;
    font-size: 24px;
    font-weight: 800;
    line-height: 28px;
  `,
  BenefitsContainer: styled.div`
    display: flex;
    flex-wrap: wrap;
    border-radius: 16px;
    border: 1px solid ${Color.ACCENT_VIOLET};
    background-color: #e7e6ff;
    margin: 10px 0 32px;
    padding: 16px;
  `,
  BenefitsTitle: styled.h3`
    flex-basis: 100%;
    margin-bottom: 16px;
    text-align: center;
    font-size: 20px;
    font-weight: 800;
    line-height: 27px;
  `,
  BenefitsText: styled.p`
    position: relative;
    flex-basis: 50%;
    margin-bottom: 16px;
    padding-left: 20px;
    font-size: 12px;
    font-weight: 600;
    line-height: 16px;

    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 16px;
      height: 16px;
      background: url(${checkIcon}) center no-repeat;
      background-size: contain;
    }
  `,
  PaymentServiceImage: styled.img`
    margin-bottom: 16px;
  `,
  GuaranteeText: styled.span`
    margin-bottom: 32px;
    font-size: 13px;
    line-height: 20px;
    text-align: center;
    color: ${({ theme }) => theme.colors.guaranteeText};
  `,
  PaymentRequestButton: styled(PaymentRequestButton)`
    margin: 0 0 16px;
  `,
  Guarantee: styled(Guarantee)`
    margin-top: 16px;
    background-color: ${Color.WHITE};
  `,
  Disclaimer: styled.p`
    font-size: 11px;
    line-height: 18px;
    text-align: center;
    color: ${Color.LIGHT_TEXT};

    a {
      color: inherit;
      font-weight: inherit;
      text-decoration: underline;
      white-space: pre;
    }
  `,
  ThreeDSecureIframe: styled.iframe`
    height: calc(var(--full-height, 100vh) - 50px) !important;
    padding: 15px;
  `,
}
