import styled from 'styled-components'

import { Button } from 'components/Button'

import image from 'assets/images/intro-city.webp'

import {
  StyledPhoneInput,
  baseColumnStyles,
  basePageStyles,
} from 'common-styles'
import { Color, MediaBreakpoint } from 'root-constants/common'

export const StyledPhoneNumberVariant3 = {
  Wrapper: styled.div`
    ${basePageStyles};
    background-image: url(${image});
    background-size: cover;
    background-repeat: no-repeat;
    min-height: 100%;
    max-width: ${MediaBreakpoint.MAX_PHONE}px;
    margin: 0 auto;
    justify-content: flex-end;
  `,
  Column: styled.div`
    ${baseColumnStyles};
    padding: 24px 20px 32px;
    max-width: ${MediaBreakpoint.MAX_PHONE}px;
    width: 100%;
    border-radius: 24px 24px 0 0;
    background: #1c1c20;
    align-items: center;

    @media (min-width: 430px) {
      padding-bottom: 80px;
    }
  `,
  Title: styled.h1`
    font-size: 34px;
    font-weight: 800;
    line-height: 46px;
    color: ${Color.WHITE};
    margin-bottom: 32px;
  `,
  Button: styled(Button)`
    position: relative;
    width: 324px;

    svg {
      position: absolute;
      top: 19px;
      right: 19px;
    }
  `,
  PhoneForm: styled.form`
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 32px;
    position: relative;
    align-items: center;
  `,
  PhoneText: styled.h3`
    text-align: center;
    font-size: 20px;
    font-weight: 600;
    line-height: 27px;
    width: 296px;
  `,
  PhoneNumberInput: styled(StyledPhoneInput)`
    border: transparent;
    background: #1c1c20;
    padding: 0;

    .react-international-phone-input {
      background-color: transparent;
      border: none;
      color: #6b6b6e;
      font-size: 22px;
      font-weight: 600;
      line-height: 30px;
      margin-left: 10px;
    }

    .react-international-phone-country-selector-button {
      border-radius: 12px;
      padding: 10px 8px;
    }

    .react-international-phone-country-selector-button:hover {
      background-color: ${Color.WHITE};
    }

    .react-international-phone-country-selector-dropdown {
      max-height: 100px;
    }
  `,
  PhoneErrorText: styled.p`
    color: ${({ theme }) => theme.colors.errorTextColor};
    text-align: center;
    font-size: 18px;
    font-weight: 600;
    line-height: 20px;
  `,
}
