import { RefObject, useEffect } from 'react'

// Fixes safari bug with overscroll behaviour
export const useScrollFix = (refObject?: RefObject<HTMLElement>): void => {
  useEffect(() => {
    let startTopScroll
    const elem = refObject?.current || document.getElementById('root')

    if (!elem) return

    const updateScrollPosition = () => {
      startTopScroll = elem.scrollTop

      if (startTopScroll <= 0) {
        elem.scrollTop = 1
      }

      if (startTopScroll + elem.offsetHeight >= elem.scrollHeight) {
        elem.scrollTop = elem.scrollHeight - elem.offsetHeight - 1
      }
    }

    elem.addEventListener('touchstart', updateScrollPosition, false)

    // eslint-disable-next-line consistent-return
    return () =>
      elem.removeEventListener('touchstart', updateScrollPosition, false)
  }, [refObject])
}
