import React, { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { useNextStep } from 'hooks/useNextStep'

import { TPageProps } from 'models/common.model'

import { Button } from 'components/Button'
import { FixedContainer } from 'components/FixedContainer'
import { Option, OptionType } from 'components/Option'
import { StyledOption } from 'components/Option/Option.styles'
import { QuestionButton } from 'components/QuestionButton'

import { CheckboxCustomValue, Language } from 'root-constants/common'

import { StyledFeelingsAboutLackOfContact as S } from './FeelingsAboutLackOfContact.styles'
import { OPTION_VALUES, PAGE_NAME } from './constants'

export const FeelingsAboutLackOfContactVariant1: React.FC<TPageProps> = ({
  pageId,
  nextPagePath,
}) => {
  const { t } = useTranslation()
  const [answers, setAnswers] = useState<string[]>([])
  const allOptionValues = Object.values(OPTION_VALUES)

  const handleContinue = useNextStep({
    pageId,
    pageName: PAGE_NAME,
    question: t('onboarding.feelingsAboutLackOfContact.question', {
      lng: Language.EN,
    }),
    nextPagePath,
  })

  const optionProps = useMemo(
    () => ({
      name: pageId,
      type: OptionType.CHECKBOX,
      onChange: (value: string, isChecked: boolean) => {
        if (isChecked) {
          value === CheckboxCustomValue.ALL_OF_THE_ABOVE
            ? setAnswers(allOptionValues)
            : setAnswers([...answers, value])
          return
        }

        value === CheckboxCustomValue.ALL_OF_THE_ABOVE
          ? setAnswers([])
          : setAnswers(answers.filter((item) => item !== value))
      },
    }),
    [allOptionValues, answers, pageId],
  )

  return (
    <S.Wrapper>
      <S.Column>
        <S.Title>{t`onboarding.feelingsAboutLackOfContact.question`}</S.Title>
        <StyledOption.OptionsContainer paddingBottom={4}>
          <Option
            {...optionProps}
            value={OPTION_VALUES.ANXIOUS}
            checked={answers.includes(OPTION_VALUES.ANXIOUS)}
          >
            <QuestionButton hasCheckboxIcon>
              {t`onboarding.feelingsAboutLackOfContact.answers.anxious`}
            </QuestionButton>
          </Option>
          <Option
            {...optionProps}
            value={OPTION_VALUES.NOT_ABLE_TO_SUPPORT}
            checked={answers.includes(OPTION_VALUES.NOT_ABLE_TO_SUPPORT)}
          >
            <QuestionButton hasCheckboxIcon>
              {t`onboarding.feelingsAboutLackOfContact.answers.notAbleToSupport`}
            </QuestionButton>
          </Option>
          <Option
            {...optionProps}
            value={OPTION_VALUES.NOT_CLOSE}
            checked={answers.includes(OPTION_VALUES.NOT_CLOSE)}
          >
            <QuestionButton hasCheckboxIcon>
              {t`onboarding.feelingsAboutLackOfContact.answers.notClose`}
            </QuestionButton>
          </Option>
          <Option
            {...optionProps}
            value={CheckboxCustomValue.ALL_OF_THE_ABOVE}
            checked={answers.length === allOptionValues.length}
          >
            <QuestionButton hasCheckboxIcon>
              {t`commonComponents.allOfTheAbove`}
            </QuestionButton>
          </Option>
        </StyledOption.OptionsContainer>
        <FixedContainer>
          <Button
            onClick={() => handleContinue(answers)}
            disabled={!answers.length}
          >
            {t`actions.next`}
          </Button>
        </FixedContainer>
      </S.Column>
    </S.Wrapper>
  )
}
