import { PageId } from 'root-constants/pages'

import { OPTION_VALUES } from '../lib/constants'

export const INTRO_4_OPTION_DATA = [
  {
    optionValue: OPTION_VALUES.PARTNER,
    answerText: 'onboarding.intro.answers.partner',
    nextPagePath: PageId.PARTNERS_SAFETY_1,
  },
  {
    optionValue: OPTION_VALUES.KID,
    answerText: 'onboarding.intro.answers.kid',
    nextPagePath: PageId.CHILD_LOCATION_2,
  },
  {
    optionValue: OPTION_VALUES.FRIEND,
    answerText: 'onboarding.intro.answers.friend',
    nextPagePath: PageId.FRIENDS_SAFETY_1,
  },
  {
    optionValue: OPTION_VALUES.OTHER,
    answerText: 'onboarding.intro.answers.other',
    nextPagePath: PageId.FRIENDS_SAFETY_1,
  },
]
