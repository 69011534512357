import { ISubscription } from 'models/subscriptions.model'

interface IAxonItem {
  item_id: string
  item_name: string
  price: number
  quantity: number
  item_category_id: number
}

const APPLOVIN_CATEGORY_ID = 3283

export const getAxonItem = ({
  id,
  periodQuantity,
  periodName,
  trialPrice,
  price,
}: {
  id: string
  periodQuantity: number
  periodName: string
  trialPrice?: number
  price: number
}): IAxonItem => ({
  item_id: id,
  item_category_id: APPLOVIN_CATEGORY_ID,
  item_name: `${periodQuantity} ${periodName} plan`,
  price: trialPrice || price,
  quantity: 1,
})

export const getAxonItems = (subscriptions: ISubscription[]): IAxonItem[] =>
  subscriptions.map(({ id, mainPrices, trialPrices }) =>
    getAxonItem({
      id,
      periodQuantity: mainPrices.periodQuantity,
      periodName: mainPrices.periodName,
      trialPrice: trialPrices.fullPrice,
      price: mainPrices.fullPrice,
    }),
  )
