import styled from 'styled-components'

import { Option } from 'components/Option'
import { QuestionButton } from 'components/QuestionButton'

import { baseColumnStyles, basePageStyles } from 'common-styles'
import { Color, HEADER_HEIGHT } from 'root-constants/common'

export const StyledAgeRangeVariant3 = {
  Wrapper: styled.div`
    ${basePageStyles};
    min-height: 100%;
    padding-top: ${HEADER_HEIGHT}px;
  `,
  Column: styled.div`
    ${baseColumnStyles};
    text-align: center;
    margin: 0 auto;
    padding: 24px 0 10px;
    width: 327px;
  `,
  Title: styled.h1`
    font-weight: 700;
    font-size: 24px;
    line-height: 33px;
    text-align: center;
  `,
  Subtitle: styled.p`
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
    margin-top: 12px;
    text-align: center;
  `,
  OptionContainer: styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 13px;
    flex-wrap: wrap;
    width: 100%;
    margin-top: 66px;
  `,
  Option: styled(Option)`
    background-color: ${Color.WHITE};
    max-width: 157px;
    width: 100%;
    border-radius: 16px;
    margin-bottom: 0;
  `,
  QuestionButton: styled(QuestionButton)`
    background-color: ${Color.WHITE};
    padding: 12px;
    font-size: 16px;
    font-weight: 600;
    line-height: 22px;
  `,
}
