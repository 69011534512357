import React from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'

import { useNextStep } from 'hooks/useNextStep'
import { useUserData } from 'hooks/useUserData'

import { TPageProps } from 'models/common.model'

import { DesktopProvider } from 'components/DesktopProvider'

import socialProofImageMobile from 'assets/images/social-proof-5-mobile.webp'
import socialProofImageDesktop from 'assets/images/social-proof-5.jpg'

import { UserGoal } from 'root-constants/common'
import { PageId } from 'root-constants/pages'

import { StyledSocialProofVariant5 as S } from './SocialProofVariant5.styles'
import { PAGE_NAMES } from './constants'

export const SocialProofVariant5: React.FC<TPageProps> = ({
  pageId,
  nextPagePath,
}) => {
  const { search } = useLocation()
  const { t } = useTranslation()

  const { singleUserGoal } = useUserData()

  const isPartnerGoal = singleUserGoal === UserGoal.PARTNER

  const handleContinue = useNextStep({
    pageId,
    pageName: PAGE_NAMES[pageId],
    question: '',
    nextPagePath: isPartnerGoal ? nextPagePath : `${PageId.DATA_1}${search}`,
  })

  return (
    <DesktopProvider imageSrc={socialProofImageDesktop}>
      <S.Wrapper>
        <S.ImageContainer>
          <img src={socialProofImageMobile} alt="people" />
        </S.ImageContainer>
        <S.Column>
          <S.Description>
            <Trans
              i18nKey="onboarding.socialProof.variant5.paragraph"
              components={[<strong />, <br />, <i />]}
            />
          </S.Description>
          <S.Button type="button" onClick={() => handleContinue('')}>
            {t`actions.continue`}
          </S.Button>
        </S.Column>
      </S.Wrapper>
    </DesktopProvider>
  )
}
