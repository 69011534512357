import React from 'react'
import { useTranslation } from 'react-i18next'

import { useDelayedContinue } from 'hooks/useDelayedContinue'
import { useNextStep } from 'hooks/useNextStep'

import { TPageProps } from 'models/common.model'

import { DesktopProvider } from 'components/DesktopProvider'
import { Option, OptionType } from 'components/Option'
import { StyledOption } from 'components/Option/Option.styles'
import { QuestionButton } from 'components/QuestionButton'

import connectionImage from 'assets/images/connection.jpg'

import { Confidence, Language } from 'root-constants/common'

import { StyledConnection as S } from './Connection.styles'
import { PAGE_NAME } from './constants'

export const ConnectionVariant1: React.FC<TPageProps> = ({
  pageId,
  nextPagePath,
}) => {
  const { t } = useTranslation()

  const { isAnswersDisabled, handleChange, setIsAnswersDisabled } =
    useDelayedContinue()

  const handleContinue = useNextStep({
    pageId,
    pageName: PAGE_NAME,
    question: t('onboarding.connection.question', { lng: Language.EN }),
    nextPagePath,
  })

  const optionProps = {
    name: pageId,
    type: OptionType.RADIO,
    onChange: (value: string) => {
      handleChange(value, handleContinue)
      setIsAnswersDisabled(true)
    },
  }

  return (
    <DesktopProvider imageSrc={connectionImage}>
      <S.Wrapper>
        <S.Column>
          <S.Title>{t`onboarding.connection.question`}</S.Title>
          <StyledOption.OptionsContainer>
            <Option
              {...optionProps}
              value={Confidence.YES}
              disabled={isAnswersDisabled}
            >
              <QuestionButton>{t`commonComponents.yes`}</QuestionButton>
            </Option>
            <Option
              {...optionProps}
              value={Confidence.NO}
              disabled={isAnswersDisabled}
            >
              <QuestionButton>{t`commonComponents.no`}</QuestionButton>
            </Option>
            <Option
              {...optionProps}
              value={Confidence.CANT_REMEMBER}
              disabled={isAnswersDisabled}
            >
              <QuestionButton>{t`commonComponents.cantRemember`}</QuestionButton>
            </Option>
          </StyledOption.OptionsContainer>
        </S.Column>
      </S.Wrapper>
    </DesktopProvider>
  )
}
