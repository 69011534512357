import styled, { css } from 'styled-components'

import checkIcon from 'assets/images/check-icon-violet.svg'

import { Color, MediaBreakpoint } from 'root-constants/common'

type TPlanPriceProps = {
  marginBottom?: number
  fontSize?: number
}
const activePlanItemStyles = css`
  background-color: transparent;

  ::before {
    background: center url(${checkIcon}) no-repeat;
  }

  &[data-has-laptop-styles='true'] {
    @media (min-width: ${MediaBreakpoint.LAPTOP}px) {
      background-color: #fff;
    }
  }
`

const defaultPlanItemStyles = css`
  position: relative;

  &::after {
    content: attr(data-text);
    display: block;
    padding: 0 16px;
    position: absolute;
    top: -15px;
    left: 50%;
    transform: translateX(-50%);
    height: 26px;
    font-size: 14px;
    line-height: 14px;
    font-weight: 600;
    letter-spacing: 0.5px;
    text-transform: uppercase;
    color: #ffffff;
  }
`

const PlanItem = styled.div`
  position: relative;
  width: 100%;
  max-height: 80px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 30px 16px 16px 56px;
  border-radius: 16px;
  border: 2px solid #cfd3da;
  background-color: #eff5f7;
  transition: all 0.2s ease-out;

  &[data-is-default='true'] {
    ${defaultPlanItemStyles};
    max-height: 91px;
  }

  ::before {
    content: '';
    display: block;
    position: absolute;
    top: 5px;
    bottom: 0;
    left: 16px;
    width: 24px;
    height: 24px;
    margin: auto;
    border-radius: 100%;
  }

  &[data-has-laptop-styles='true'] {
    @media (min-width: ${MediaBreakpoint.LAPTOP}px) {
      background-color: #fff;
    }
  }
`

export const StyledTrialPlanItemVariant4 = {
  PlanItem: styled(PlanItem)`
    &[data-is-selected='true'] {
      ${activePlanItemStyles};
      border: 2px solid ${Color.ACCENT_VIOLET};
    }

    &[data-is-selected='false'] {
      ::before {
        border: 2px solid #ccdce5;
      }
    }

    &[data-is-default='true'] {
      &::after {
        width: 310px;
        border-radius: 16px 16px 0 0;
        padding: 4px 0;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: ${Color.ACCENT_VIOLET};
      }
    }

    &[data-is-es-lang='true'] {
      padding: 35px 10px 24px 48px;
    }
  `,
  PlanTitleWrapper: styled.div`
    display: flex;
    flex-direction: column;
  `,
  PlanTitle: styled.div`
    flex-grow: 1;
    font-size: 18px;
    font-weight: 700;
    line-height: 22px;
    text-transform: capitalize;
  `,
  PlanPricesWrapper: styled.div`
    display: flex;
    align-items: center;

    img {
      width: 16px;
      height: 16px;
      margin: 0 3px;
    }
  `,
  OldPlanPrice: styled.div`
    font-size: 14px;
    line-height: 22px;
    font-weight: 500;
    text-decoration: line-through;
    text-decoration-color: #fc5774;
    color: ${Color.LIGHT_TEXT};
  `,
  CurrentPrice: styled.div`
    margin-right: 6px;
    font-size: 14px;
    line-height: 22px;
    font-weight: 400;
    color: #292d3d;
  `,
  PlanPrice: styled.div<TPlanPriceProps>`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 4px;
    text-align: center;
    max-height: 24px;
    margin-bottom: ${({ marginBottom }) => marginBottom || 0}px;

    p {
      font-size: 12px;
      font-weight: 500;
      line-height: 12px;
      text-decoration-line: line-through;
      color: ${Color.LIGHT_TEXT};
    }

    strong {
      font-size: ${({ fontSize }) => fontSize || 24}px;
      font-weight: 600;
      line-height: 24px;
    }

    sub {
      font-size: 10px;
      font-weight: 600;
      line-height: 10px;
      color: ${Color.LIGHT_TEXT};
    }
  `,
}
