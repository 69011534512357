import styled from 'styled-components'

import { Color, MediaBreakpoint } from 'root-constants/common'

export const StyledSwiperSlideVariant1 = {
  SwiperContainer: styled.div`
    flex-grow: 1;
    max-width: ${MediaBreakpoint.MAX_PHONE}px;
    aspect-ratio: 430/350;
    width: 100%;

    .swiper-wrapper {
      display: flex;
      align-items: center;
      padding-bottom: 38px;
    }

    .swiper-slide {
      max-width: 154px;

      img {
        width: 100%;
        max-width: 154px;
      }
    }

    .swiper-pagination-bullet {
      width: 6px;
      height: 6px;
      background-color: #b2b8cd;
      opacity: 1;
    }

    .swiper-pagination-bullet-active {
      background-color: ${Color.ACCENT_VIOLET};
    }

    @media (min-width: ${MediaBreakpoint.MAX_PHONE}px) {
      max-width: 375px;
    }
  `,
}
