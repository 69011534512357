import React, { useRef } from 'react'
import ReactSelect, { ControlProps, components } from 'react-select'

import { SvgImage } from 'components/SvgImage'

import arrow from 'assets/images/sprite/arrow-bottom.svg'

import {
  StyledSelectWithFloatPlaceholder as S,
  customSelectStyles,
} from './SelectWithFloatPlaceholder.styles'

type TOption = {
  value: string
  label: string
}

type TSelectProps = {
  placeholder: string
  options: TOption[]
  defaultValue?: TOption | undefined
  isValid?: boolean
  onBlur?: (event: any) => void
  onChange?: (event: any) => void
}

export const SelectWithFloatPlaceholder = React.forwardRef<
  HTMLDivElement,
  TSelectProps
>(
  (
    { placeholder, options, defaultValue, isValid = true, onBlur, onChange },
    ref,
  ) => {
    const styles = customSelectStyles(isValid)
    const rootRef = useRef<HTMLDivElement>(null)
    const handleBlur = (event: React.FocusEvent<HTMLInputElement>) => {
      onBlur && onBlur(event)
    }

    const handleChange = (event) => {
      onChange && onChange(event)
    }

    const handleMenuOpen = () => {
      setTimeout(() => {
        if (rootRef.current) {
          rootRef.current.scrollIntoView({
            behavior: 'smooth',
            block: 'start',
            inline: 'end',
          })
        }
      }, 0)
    }

    const Control = (props: ControlProps<TOption>) => {
      const { isFocused, hasValue } = props

      return (
        <>
          <S.Label isFloating={isFocused || hasValue}>{placeholder}</S.Label>
          <components.Control {...props} {...(ref && { innerRef: ref })} />
        </>
      )
    }

    const CustomOption = ({ innerProps, isDisabled, innerRef, children }) =>
      !isDisabled ? (
        <S.Option {...innerProps} ref={innerRef}>
          {children}
        </S.Option>
      ) : null

    const DropdownIndicator = (props) => (
      <components.DropdownIndicator {...props}>
        <SvgImage svg={arrow} width={24} />
      </components.DropdownIndicator>
    )

    return (
      <S.Root ref={rootRef}>
        <ReactSelect
          defaultValue={defaultValue}
          options={options}
          hideSelectedOptions={false}
          styles={styles}
          isClearable={false}
          isSearchable
          onBlur={handleBlur}
          closeMenuOnSelect
          blurInputOnSelect
          onChange={handleChange}
          placeholder=""
          onMenuOpen={handleMenuOpen}
          components={{
            Option: CustomOption,
            Control,
            DropdownIndicator,
          }}
        />
      </S.Root>
    )
  },
)
