import React from 'react'

import { TPageProps } from 'models/common.model'

import { RadioOptionPage } from 'pages/options-page/RadioOptionPage'

import { OPTION_DATA, TITLE_PATH } from './constants'

export const KidsSafetyVariant1: React.FC<TPageProps> = (props) => (
  <RadioOptionPage
    {...props}
    options={OPTION_DATA}
    titlePath={TITLE_PATH}
    marginBottom={54}
  />
)
