import React, { useEffect, useState } from 'react'

import { INITIAL_FORMATTED_TIME } from 'modules/subscriptions/constants'
import { getDecoratedTimerValue } from 'modules/subscriptions/helpers'
import { IFormattedTime } from 'modules/subscriptions/types'

export const useTimerForTarget = (
  timerElementRef: React.RefObject<HTMLElement>,
  startTimeValueInSeconds: number,
): IFormattedTime => {
  const [formattedTime, setFormattedTime] = useState<IFormattedTime>(
    INITIAL_FORMATTED_TIME,
  )

  useEffect(() => {
    let intervalID: number

    const setIntervalCallback = () => {
      if (!timerElementRef?.current) return

      const timerElement = timerElementRef.current
      const currentTimerValue = +(
        timerElement.dataset.value || startTimeValueInSeconds
      )

      if (currentTimerValue <= 0) {
        clearInterval(intervalID)
        return
      }

      const nextTimerValue = currentTimerValue - 1

      timerElement.setAttribute('data-value', `${nextTimerValue}`)
      setFormattedTime(getDecoratedTimerValue(nextTimerValue))
    }

    intervalID = setInterval(setIntervalCallback, 1000) as unknown as number

    const leavePageCallback = () => {
      if (document.hidden) {
        clearInterval(intervalID)
        return
      }

      intervalID = setInterval(setIntervalCallback, 1000) as unknown as number
    }

    window.addEventListener('visibilitychange', leavePageCallback)

    return () => {
      clearInterval(intervalID)
      window.removeEventListener('visibilitychange', leavePageCallback)
    }
  }, [startTimeValueInSeconds, timerElementRef])

  return formattedTime
}
