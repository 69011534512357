import React, { useEffect, useMemo, useRef } from 'react'
import { Trans, useTranslation } from 'react-i18next'

import { useDynamicPaywallConfig } from 'hooks/useDynamicPaywallConfig'
import { useGetPrices } from 'hooks/useGetPrices'
import { usePaywall } from 'hooks/usePaywall'
import { usePurchaseStore } from 'hooks/usePurchaseStore'
import { useUserData } from 'hooks/useUserData'

import { createProductId } from 'helpers/createProductId'

import { TPageProps } from 'models/common.model'

import { ScreenName, eventLogger } from 'services/eventLogger.service'

import { BenefitsVariant3 } from 'modules/subscriptions/components/benefits/BenefitsVariant3'
import { SubheaderWithTimer } from 'modules/subscriptions/components/common/SubheaderWithTimer'
import { Timer } from 'modules/subscriptions/components/common/Timer'
import {
  PLAN_ITEMS,
  SAFETY3_COHORT_DATA,
} from 'modules/subscriptions/constants'

import { Guarantee } from 'components/Guarantee'
import { ReviewsSlider2 } from 'components/ReviewsSlider'
import { Spinner } from 'components/Spinner'

import { SubscriptionTag, TRIAL_COHORTS } from 'root-constants/common'

import { StyledSubscriptionsVariant9 as S } from './SubscriptionsVariant9.styles'

export const SubscriptionsVariant9: React.FC<TPageProps> = ({
  nextPagePath,
}) => {
  const { t } = useTranslation()
  const scrollToElement = useRef<HTMLDivElement>(null)
  const timerTriggerElementRef = useRef(null)

  const { cohortToUse, subscriptions } = usePurchaseStore()
  const { hasPrices, handleSelectSubscription, handleShowPayment } =
    usePaywall(nextPagePath)
  const { userGoals } = useUserData()
  const { subscriptionBlockType, subscriptionBlockTitle } =
    useDynamicPaywallConfig()

  const prioritizedUsersGoal = useMemo(() => {
    const [topPriorityData] = SAFETY3_COHORT_DATA.filter(({ userGoal }) =>
      userGoals.includes(userGoal),
    )
    return topPriorityData || SAFETY3_COHORT_DATA[0]
  }, [userGoals])

  const isTrialCohort = useMemo(
    () => TRIAL_COHORTS.includes(cohortToUse),
    [cohortToUse],
  )

  useGetPrices({ tags: SubscriptionTag.NO_TAX })

  useEffect(() => {
    if (!subscriptions.length) return

    eventLogger.logSalePageShown({
      productIds: subscriptions.map(
        ({ mainPrices: { periodName, periodQuantity, fullPrice } }) =>
          createProductId({
            periodName,
            periodQuantity,
            price: fullPrice,
          }),
      ),
      screenName: ScreenName.ONBOARDING,
      onboardingId: prioritizedUsersGoal.userGoal || 'partner',
    })
  }, [subscriptions, prioritizedUsersGoal.userGoal])

  const handleTimerButtonClick = () => {
    scrollToElement.current?.scrollIntoView({
      block: 'center',
      behavior: 'smooth',
    })
  }

  return !hasPrices ? (
    <Spinner />
  ) : (
    <>
      <SubheaderWithTimer
        elemForComparisonRef={timerTriggerElementRef}
        onClick={handleTimerButtonClick}
      />
      <S.Wrapper>
        <S.Column>
          <S.Title>{t(prioritizedUsersGoal.data.title)}</S.Title>
          <S.Subtitle>
            <Trans i18nKey={prioritizedUsersGoal.data.subtitle} />
          </S.Subtitle>
          <Timer />
        </S.Column>
        <S.ImageWrapper aspectRatio={prioritizedUsersGoal.data.aspectRatio}>
          <img
            ref={timerTriggerElementRef}
            src={prioritizedUsersGoal.data.img}
            alt={prioritizedUsersGoal.userGoal}
          />
        </S.ImageWrapper>
        <S.Column data-has-bg>
          <BenefitsVariant3 userGoal={prioritizedUsersGoal.userGoal} />
          <S.SubscriptionsTitle>
            {subscriptionBlockTitle ||
              t`subscriptions.variant5.subscriptionsTitle`}
          </S.SubscriptionsTitle>
          <S.SelectPlanBlock
            ref={scrollToElement}
            onSelect={handleSelectSubscription}
            SelectPlanItem={PLAN_ITEMS[subscriptionBlockType]}
          />
          <S.Button onClick={handleShowPayment}>{t`actions.start`}</S.Button>
          {isTrialCohort ? (
            <S.SubscriptionDescriptionTrial />
          ) : (
            <S.SubscriptionDescription />
          )}
          <Guarantee />
          <ReviewsSlider2 reviews={prioritizedUsersGoal.data.reviews} />
          <S.Button onClick={handleShowPayment}>{t`actions.start`}</S.Button>
          <S.Security />
        </S.Column>
      </S.Wrapper>
    </>
  )
}
