import React, { useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import 'react-international-phone/style.css'
import { useSelector } from 'react-redux'

import { selectUserCountryCode } from 'root-redux/selects/user'

import { useNextStep } from 'hooks/useNextStep'

import { checkIsPhoneValid } from 'helpers/phone'

import { TPageProps } from 'models/common.model'

import { eventLogger } from 'services/eventLogger.service'

import { BENEFITS_LIST_TEXTS, PAGE_NAME } from 'pages/intro-google/constants'

import { Button } from 'components/Button'
import { ProgressBarListModal } from 'components/ProgressBarList'
import { ReviewsSlider2 } from 'components/ReviewsSlider'
import { REVIEWS_DATA_SLIDER_VARIANT_3 } from 'components/ReviewsSlider/constants'
import { Security } from 'components/Security'

import stayConnected from 'assets/images/stay-connected.png'

import { StyledPhoneInput } from 'common-styles'
import { CustomPageId } from 'root-constants/pages'

import { StyledIntroGoogleVariant3 as S } from './IntroGoogleVariant3.styles'

export const IntroGoogleVariant3: React.FC<TPageProps> = ({
  pageId,
  nextPagePath,
}) => {
  const { t } = useTranslation()
  const countryCode = useSelector(selectUserCountryCode)
  const [phoneNumber, setPhoneNumber] = useState('')
  const [isFocused, setIsFocused] = useState(false)
  const [isSubmitted, setIsSubmitted] = useState(false)
  const isValid = checkIsPhoneValid(phoneNumber)

  const handleContinue = useNextStep({
    pageId,
    pageName: PAGE_NAME,
    question: '',
    nextPagePath,
  })

  const handleChange = (value: string) => {
    setPhoneNumber(value)
  }

  const handleFocus = () => {
    setIsFocused(true)
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    setIsSubmitted(true)
    eventLogger.logCheckNumberClicked()
  }

  return (
    <S.Wrapper>
      <S.BurgerMenu />
      <S.Title>
        <Trans
          i18nKey="onboarding.introGoogle3.title"
          components={{ span: <span /> }}
        />
      </S.Title>
      <S.Image src={stayConnected} alt="stay_connected" />
      <S.PhoneForm onSubmit={handleSubmit}>
        <S.PhoneText>{t`onboarding.introGoogle3.phoneText`}</S.PhoneText>
        <StyledPhoneInput
          defaultCountry={countryCode.toLowerCase()}
          value={phoneNumber}
          onChange={handleChange}
          onFocus={handleFocus}
        />
        {isFocused && !isValid && (
          <S.PhoneErrorText>
            {t`onboarding.introGoogle3.phoneError`}
          </S.PhoneErrorText>
        )}
        <Button type="submit">{t`onboarding.introGoogle3.phoneButton`}</Button>
      </S.PhoneForm>
      <S.ReviewsSubtitle>{t`onboarding.introGoogle3.reviewsSubtitle`}</S.ReviewsSubtitle>
      <ReviewsSlider2 reviews={REVIEWS_DATA_SLIDER_VARIANT_3} />
      <Button onClick={() => handleContinue('')}>{t`actions.tryNow`}</Button>
      <S.BenefitsWrapper>
        <S.BenefitsSubtitle>{t`onboarding.introGoogle3.benefitsSubtitle`}</S.BenefitsSubtitle>
        <ul>
          {BENEFITS_LIST_TEXTS.map((text) => (
            <S.ListItem key={text}>
              <Trans i18nKey={text} />
            </S.ListItem>
          ))}
        </ul>
      </S.BenefitsWrapper>
      <Button onClick={() => handleContinue('')}>{t`actions.tryNow`}</Button>
      <S.SecurityWrapper>
        <Security />
      </S.SecurityWrapper>
      <ProgressBarListModal
        isShown={isSubmitted}
        nextPagePath={nextPagePath}
        pageId={CustomPageId.PHONE_NUMBER}
        pageName={PAGE_NAME}
      />
    </S.Wrapper>
  )
}
