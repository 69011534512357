import { ChipoloColor } from 'modules/payment/constants'

import chipoloLogoBlack from 'assets/images/chipolo-logo-black.png'
import chipoloLogoBlue from 'assets/images/chipolo-logo-blue.png'
import chipoloLogoGreen from 'assets/images/chipolo-logo-green.png'
import chipoloLogoRed from 'assets/images/chipolo-logo-red.png'
import chipoloLogoWhite from 'assets/images/chipolo-logo-white.png'
import chipoloLogoYellow from 'assets/images/chipolo-logo-yellow.png'

export const CHIPOLO_IMAGES = {
  [ChipoloColor.BLUE]: chipoloLogoBlue,
  [ChipoloColor.YELLOW]: chipoloLogoYellow,
  [ChipoloColor.RED]: chipoloLogoRed,
  [ChipoloColor.WHITE]: chipoloLogoWhite,
  [ChipoloColor.GREEN]: chipoloLogoGreen,
  [ChipoloColor.BLACK]: chipoloLogoBlack,
}

export const COLORS_DATA = [
  {
    value: ChipoloColor.BLUE,
    borderColor: '#3689d5',
    backgroundColor: '#3689d5',
  },
  {
    value: ChipoloColor.YELLOW,
    borderColor: '#f7d649',
    backgroundColor: '#f7d649',
  },
  {
    value: ChipoloColor.RED,
    borderColor: '#fc4c36',
    backgroundColor: '#fc4c36',
  },
  {
    value: ChipoloColor.WHITE,
    borderColor: '#bcc9d0',
    backgroundColor: '#fff',
  },
  {
    value: ChipoloColor.GREEN,
    borderColor: '#0ac75a',
    backgroundColor: '#0ac75a',
  },
  { value: ChipoloColor.BLACK, borderColor: '#000', backgroundColor: '#000' },
]
