import styled, { css } from 'styled-components'

import { MediaBreakpoint } from 'root-constants/common'

const dividerTextStyles = css`
  position: relative;
  padding: 0 25px;
  font-size: 13px;
  line-height: 20px;
  font-weight: 400;

  ::before,
  ::after {
    content: '';
    position: absolute;
    display: block;
    height: 1px;
    width: 500px;
    top: 10px;
  }

  ::before {
    right: 100%;
  }

  ::after {
    left: 100%;
  }
`

export const StyledDivider = {
  Divider: styled.div`
    margin-bottom: 25px;
    width: 100%;
    display: flex;
    justify-content: center;
    overflow: hidden;

    @media (min-width: ${MediaBreakpoint.LAPTOP}px) {
      margin-bottom: 50px;
    }
  `,
  DividerText: styled.div`
    ${dividerTextStyles};
    color: ${({ theme }) => theme.colors.secondaryText};

    ::before,
    ::after {
      background-color: #bcc9d0;
    }

    @media (min-width: ${MediaBreakpoint.LAPTOP}px) {
      ::before,
      ::after {
        width: 100vw;
      }
    }
  `,
}
