import styled from 'styled-components'

import { ReviewsSlider2 } from 'components/ReviewsSlider'

import { basePageStyles, baseTitleStyles } from 'common-styles'
import {
  BASE_COLUMN_WIDTH,
  HEADER_HEIGHT,
  MediaBreakpoint,
} from 'root-constants/common'

export const StyledParentReviewsVariant1 = {
  Wrapper: styled.div`
    ${basePageStyles};
    padding-top: ${HEADER_HEIGHT}px;
  `,
  Content: styled.div`
    width: 100%;
    max-width: ${MediaBreakpoint.MAX_PHONE}px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding-bottom: 114px;

    @media (min-width: ${MediaBreakpoint.MAX_PHONE}px) {
      max-width: 375px;
    }
  `,
  ImageContainer: styled.div`
    width: 100%;
    margin-bottom: 32px;
    aspect-ratio: 375/238;
  `,
  Title: styled.h1`
    ${baseTitleStyles};
    max-width: ${BASE_COLUMN_WIDTH}px;
    margin-bottom: 16px;
  `,
  ReviewSlider: styled(ReviewsSlider2)`
    .swiper-slide > div {
      min-height: 190px;
    }
  `,
}
