import { useMemo } from 'react'
import { useSelector } from 'react-redux'

import { usePageInfo } from 'providers/PageInfoProvider'

import { selectAnswers, selectLanguage } from 'root-redux/selects/common'

import { getDynamicPersonalization } from 'helpers/getDynamicPersonalization'

export const useDynamicPaywallConfig = () => {
  const language = useSelector(selectLanguage)
  const userAnswers = useSelector(selectAnswers)
  const { currentPageIndex, steps } = usePageInfo()

  return useMemo(() => {
    const dynamicPaywallConfig = steps[currentPageIndex].paywallDynamicConfig
    return {
      title: getDynamicPersonalization(
        dynamicPaywallConfig?.[language]?.title,
        userAnswers,
        dynamicPaywallConfig?.[language]?.personalization,
      ),
      subtitle: getDynamicPersonalization(
        dynamicPaywallConfig?.[language]?.subtitle,
        userAnswers,
        dynamicPaywallConfig?.[language]?.personalization,
      ),
      buttonText: dynamicPaywallConfig?.[language]?.buttonText,
      subscriptionBlockTitle: getDynamicPersonalization(
        dynamicPaywallConfig?.[language]?.subscriptionBlockTitle,
        userAnswers,
        dynamicPaywallConfig?.[language]?.personalization,
      ),
      subscriptionBlockType:
        dynamicPaywallConfig?.[language]?.subscriptionBlockType ||
        'plan_item_variant_1',
      benefitsBlockTitle: getDynamicPersonalization(
        dynamicPaywallConfig?.[language]?.benefitsBlockTitle,
        userAnswers,
        dynamicPaywallConfig?.[language]?.personalization,
      ),
      benefits: dynamicPaywallConfig?.[language]?.benefits,
      customerReviewsTitle: getDynamicPersonalization(
        dynamicPaywallConfig?.[language]?.customerReviewsTitle,
        userAnswers,
        dynamicPaywallConfig?.[language]?.personalization,
      ),
      customerReviews: dynamicPaywallConfig?.[language]?.customerReviews,
    }
  }, [currentPageIndex, language, steps, userAnswers])
}
