import { useSelector } from 'react-redux'

import { selectCurrentVariantCohortToUse } from 'root-redux/selects/common'

import { Cohort, DESKTOP_COHORTS } from 'root-constants/common'

export const useGetFlowInfo = () => {
  const cohortToUse = useSelector(selectCurrentVariantCohortToUse)

  const hasDesktopVersion = DESKTOP_COHORTS.includes(cohortToUse)

  const isSafetyFlow = cohortToUse === Cohort.SAFETY_4

  return {
    hasDesktopVersion,
    isSafetyFlow,
  }
}
