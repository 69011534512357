import { useLayoutEffect } from 'react'
import { useDispatch } from 'react-redux'

import { sendUserSCCID } from 'root-redux/actions/user'
import { selectLanguage, selectSnapPixelId } from 'root-redux/selects/common'
import {
  selectEmail,
  selectUUID,
  selectUserCountryCode,
} from 'root-redux/selects/user'

import { useBulkSelector } from 'hooks/common/useBulkSelector'
import { useCookieConsentAnswer } from 'hooks/common/useCookieConsentAnswer'

import { getCookie } from 'helpers/getCookie'
import { hasAnalyticsByLocale } from 'helpers/hasAnalyticsByLocale'

const useSelectors = () =>
  useBulkSelector({
    snapPixelId: selectSnapPixelId,
    email: selectEmail,
    uuid: selectUUID,
    userCountryCode: selectUserCountryCode,
    language: selectLanguage,
  })

export const useSnapPixelInitialization = (): void => {
  const dispatch = useDispatch()
  const { snapPixelId, email, uuid, userCountryCode, language } = useSelectors()

  const { isEUUser, hasAdvertisingAndTargetingCookie, isPersonalDataAllowed } =
    useCookieConsentAnswer()

  const shouldSNAPTRBePaused =
    isEUUser &&
    !hasAdvertisingAndTargetingCookie &&
    !hasAnalyticsByLocale(language)

  useLayoutEffect(() => {
    if (!snapPixelId || !uuid || !userCountryCode || shouldSNAPTRBePaused) {
      return
    }

    window.snaptr('init', snapPixelId, {
      user_email: isPersonalDataAllowed ? email : '',
    })
    window.snaptr('track', 'PAGE_VIEW', {
      user_email: isPersonalDataAllowed ? email : '',
    })

    const searchParams = new URLSearchParams(document.location.search)
    const sccid = searchParams.get('sccid')
    const scid = getCookie('_scid')

    if (sccid) {
      dispatch(sendUserSCCID(sccid, scid))
    }
  }, [
    snapPixelId,
    email,
    uuid,
    dispatch,
    userCountryCode,
    shouldSNAPTRBePaused,
    isPersonalDataAllowed,
  ])
}
