import React, { useEffect, useMemo, useRef } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { selectDynamicDiscount } from 'root-redux/selects/common'

import { useDynamicPaywallConfig } from 'hooks/useDynamicPaywallConfig'
import { useGetPrices } from 'hooks/useGetPrices'
import { usePaywall } from 'hooks/usePaywall'
import { usePurchaseStore } from 'hooks/usePurchaseStore'
import { useUserData } from 'hooks/useUserData'
import { useVatInfo } from 'hooks/useVatInfo'

import { createProductId } from 'helpers/createProductId'

import { TPageProps } from 'models/common.model'

import { ScreenName, eventLogger } from 'services/eventLogger.service'

import { Discount } from 'modules/subscriptions/components/discount'
import { TrialPlanItemVariant2 } from 'modules/subscriptions/components/trial'
import { TrialPlanItemVariant4 } from 'modules/subscriptions/components/trial/TrialPlanItemVariant4'

import { DesktopProvider } from 'components/DesktopProvider'
import { DynamicDiscountBanner } from 'components/DynamicDiscountBanner'
import { ReviewsSlider, ReviewsSliderDynamic } from 'components/ReviewsSlider'
import { Security } from 'components/Security'
import { Spinner } from 'components/Spinner'

import {
  CDN_FOLDER_LINK,
  Images,
  SubscriptionTag,
  TRIAL_COHORTS,
} from 'root-constants/common'

import { VatPlanItemVariant1 } from '../../components/common/PlanItemVariant1'
import { PLAN_ITEMS } from '../../constants'
import { StyledSubscriptions as S } from './Subscriptions.styles'

export const SubscriptionsVariant1: React.FC<TPageProps> = ({
  nextPagePath,
}) => {
  const { t } = useTranslation()
  const scrollToElement = useRef<HTMLDivElement>(null)
  const timerTriggerElementRef = useRef(null)
  const dynamicDiscount = useSelector(selectDynamicDiscount)

  const { cohortToUse, subscriptions, language } = usePurchaseStore()
  const { hasPrices, handleSelectSubscription, handleShowPayment } =
    usePaywall(nextPagePath)
  const { userGender, isFemale } = useUserData()
  const {
    title,
    subtitle,
    buttonText,
    benefitsBlockTitle,
    benefits,
    subscriptionBlockType,
    subscriptionBlockTitle,
    customerReviews,
  } = useDynamicPaywallConfig()
  const hasIncludedVAT = useVatInfo()

  useGetPrices({
    tags: hasIncludedVAT ? SubscriptionTag.TAX : SubscriptionTag.NO_TAX,
  })

  const isTrialCohort = TRIAL_COHORTS.includes(cohortToUse)

  const laptopBgImgLink = isFemale
    ? `${CDN_FOLDER_LINK}${Images.WIFE_CALL_LAPTOP}_${language}.webp`
    : `${CDN_FOLDER_LINK}${Images.HUSBAND_CALL_LAPTOP}_${language}.webp`

  const planItem = useMemo(() => {
    if (isTrialCohort) {
      return TrialPlanItemVariant2
    }
    if (hasIncludedVAT) {
      return VatPlanItemVariant1
    }
    return PLAN_ITEMS[subscriptionBlockType]
  }, [hasIncludedVAT, subscriptionBlockType, isTrialCohort])

  useEffect(() => {
    if (!subscriptions.length) return

    eventLogger.logSalePageShown({
      productIds: subscriptions.map(
        ({ mainPrices: { periodName, periodQuantity, fullPrice } }) =>
          createProductId({
            periodName,
            periodQuantity,
            price: fullPrice,
          }),
      ),
      screenName: ScreenName.ONBOARDING,
    })
  }, [subscriptions])

  const handleTimerButtonClick = () => {
    scrollToElement.current?.scrollIntoView({
      block: 'center',
      behavior: 'smooth',
    })
  }

  return !hasPrices ? (
    <Spinner />
  ) : (
    <DesktopProvider>
      <S.SubheaderWithTimer
        elemForComparisonRef={timerTriggerElementRef}
        onClick={handleTimerButtonClick}
      />
      <S.Wrapper
        bgImgLink={laptopBgImgLink}
        paddingTop={dynamicDiscount ? 56 : 96}
      >
        <DynamicDiscountBanner />

        <S.Column marginBottom={40} data-has-full-width>
          <S.Title marginTop={dynamicDiscount ? 20 : 0}>
            {title || (
              <Trans
                i18nKey="subscriptions.variant1.title"
                context={userGender}
              />
            )}
          </S.Title>

          <S.Text>{subtitle || t`subscriptions.variant1.subtitle`}</S.Text>
          <S.Timer />
        </S.Column>

        <S.Image
          ref={timerTriggerElementRef}
          src={
            isFemale
              ? `${CDN_FOLDER_LINK}${Images.WIFE_CALL}_${language}.jpg`
              : `${CDN_FOLDER_LINK}${Images.HUSBAND_CALL}_${language}.jpg`
          }
          alt="calling partner"
        />

        <S.Column data-has-bg data-has-full-width>
          <S.Subtitle margin="56px 0 16px">
            {benefitsBlockTitle ||
              t('subscriptions.variant1.benefitsTitle', {
                context: userGender,
              })}
          </S.Subtitle>
          <S.List data-gender={userGender}>
            {benefits?.length ? (
              benefits.map(({ id, text }) => (
                <S.ContentItem key={id}>{text}</S.ContentItem>
              ))
            ) : (
              <>
                <S.ContentItem>
                  <Trans
                    i18nKey="subscriptions.variant1.benefitsList.firstItem"
                    context={userGender}
                  />
                </S.ContentItem>
                <S.ContentItem>
                  <Trans
                    i18nKey="subscriptions.variant1.benefitsList.secondItem"
                    context={userGender}
                  />
                </S.ContentItem>
                <S.ContentItem>
                  <Trans i18nKey="subscriptions.variant1.benefitsList.thirdItem" />
                </S.ContentItem>
                <S.ContentItem>
                  <Trans
                    i18nKey="subscriptions.variant1.benefitsList.fourthItem"
                    context={userGender}
                  />
                </S.ContentItem>
                {!isFemale && (
                  <S.ContentItem>
                    <Trans i18nKey="subscriptions.variant1.benefitsList.fifthItem" />
                  </S.ContentItem>
                )}
              </>
            )}
          </S.List>
          <S.StyleContainer>
            <S.Subtitle>
              {subscriptionBlockTitle ||
                t`subscriptions.variant5.subscriptionsTitle`}
            </S.Subtitle>
            <Discount />
            <S.SelectPlanBlock
              ref={scrollToElement}
              onSelect={handleSelectSubscription}
              SelectPlanItem={
                dynamicDiscount ? TrialPlanItemVariant4 : planItem
              }
            />
            <S.Button
              onClick={handleShowPayment}
              marginBottom={12}
              laptopMarginBottom={33}
            >
              {buttonText || t`actions.start`}
            </S.Button>
            {isTrialCohort ? (
              <S.SubscriptionDescriptionTrial />
            ) : (
              <S.SubscriptionDescription />
            )}
          </S.StyleContainer>
        </S.Column>
        <S.Column>
          <S.Guarantee />
          {customerReviews?.length ? (
            <ReviewsSliderDynamic hasLaptopStyles />
          ) : (
            <ReviewsSlider hasLaptopStyles />
          )}
          <S.Button
            onClick={handleShowPayment}
            marginBottom={24}
            laptopMarginBottom={54}
          >
            {buttonText || t`actions.start`}
          </S.Button>
          <Security />
        </S.Column>
      </S.Wrapper>
    </DesktopProvider>
  )
}
