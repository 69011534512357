import styled from 'styled-components'

import {
  baseColumnStyles,
  basePageStyles,
  baseTitleStyles,
} from 'common-styles'
import { HEADER_HEIGHT, MediaBreakpoint } from 'root-constants/common'

type TTextProps = {
  marginBottom: number
}
export const StyledSocialProofVariant20 = {
  Wrapper: styled.div`
    ${basePageStyles};
    padding-top: ${HEADER_HEIGHT}px;
  `,
  ImageContainer: styled.div`
    width: 100%;
    max-width: ${MediaBreakpoint.MAX_PHONE}px;
    margin-bottom: 24px;
    aspect-ratio: 375/238;

    @media (min-width: ${MediaBreakpoint.MAX_PHONE}px) {
      max-width: 375px;
    }
  `,
  Title: styled.h1`
    ${baseTitleStyles};
    margin-bottom: 16px;
  `,
  Column: styled.div`
    ${baseColumnStyles};
    padding-bottom: 80px;
  `,
  Text: styled.p<TTextProps>`
    text-align: center;
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
    margin-bottom: ${({ marginBottom }) => marginBottom}px;

    strong {
      font-size: 16px;
      line-height: 22px;
      font-weight: 700;
    }
  `,
}
