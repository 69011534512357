import { useCallback, useEffect } from 'react'

import 'root-redux/actions/user'
import {
  selectCookieConsentCountries,
  selectGoogleAnalyticsId,
  selectLanguage,
} from 'root-redux/selects/common'
import { selectUserCountryCode } from 'root-redux/selects/user'

import { useBulkSelector } from 'hooks/common/useBulkSelector'

import { hasAnalyticsByLocale } from 'helpers/hasAnalyticsByLocale'

import { GAConsent } from 'root-constants/common'

import { useCookieConsentAnswer } from '../common/useCookieConsentAnswer'

const useSelectors = () =>
  useBulkSelector({
    googleAnalyticsId: selectGoogleAnalyticsId,
    userCountryCode: selectUserCountryCode,
    cookieConsentCountries: selectCookieConsentCountries,
    language: selectLanguage,
  })

export const useGoogleAnalytics = (): void => {
  const {
    googleAnalyticsId,
    userCountryCode,
    cookieConsentCountries,
    language,
  } = useSelectors()

  const {
    isEUUser,
    cookieConsent,
    hasAdvertisingAndTargetingCookie,
    hasFunctionalCookie,
    hasAnalyticsAndPerformanceCookie,
  } = useCookieConsentAnswer()

  const shouldGABePaused =
    isEUUser &&
    !hasAnalyticsAndPerformanceCookie &&
    !hasAnalyticsByLocale(language)

  const setDefaultConsentOptions = useCallback(() => {
    if (!isEUUser || hasAnalyticsByLocale(language)) return

    window.gtag('consent', 'default', {
      ad_storage: 'denied',
      ad_user_data: 'denied',
      ad_personalization: 'denied',
      analytics_storage: 'granted',
      region: cookieConsentCountries,
    })
  }, [cookieConsentCountries, isEUUser, language])

  useEffect(() => {
    if (!googleAnalyticsId || !userCountryCode || shouldGABePaused) return

    const initGtag = () => {
      const script = document.createElement('script')
      script.text = `window.dataLayer = window.dataLayer || [];
        gtag = function () { dataLayer.push(arguments); }
        gtag('js', new Date());`
      script.async = true
      document.head.appendChild(script)
    }

    const setUpGTM = () => {
      const script = document.createElement('script')
      script.src = `https://www.googletagmanager.com/gtag/js?id=${googleAnalyticsId}`
      script.async = true
      document.head.appendChild(script)
    }

    initGtag()
    setUpGTM()
    setDefaultConsentOptions()

    window.gtag('config', googleAnalyticsId, {
      send_page_view: false,
    })
  }, [
    googleAnalyticsId,
    setDefaultConsentOptions,
    shouldGABePaused,
    userCountryCode,
  ])

  useEffect(() => {
    if (!isEUUser || !cookieConsent?.length) return

    !!window.gtag &&
      window.gtag('consent', 'update', {
        ad_user_data: hasAdvertisingAndTargetingCookie
          ? GAConsent.GRANTED
          : GAConsent.DENIED,
        ad_personalization:
          hasAdvertisingAndTargetingCookie && hasFunctionalCookie
            ? GAConsent.GRANTED
            : GAConsent.DENIED,
        ad_storage:
          hasAdvertisingAndTargetingCookie && hasFunctionalCookie
            ? GAConsent.GRANTED
            : GAConsent.DENIED,
        region: cookieConsentCountries,
      })
  }, [
    cookieConsent?.length,
    cookieConsentCountries,
    hasAdvertisingAndTargetingCookie,
    hasFunctionalCookie,
    isEUUser,
  ])
}
