import styled from 'styled-components'

import {
  baseAdaptiveColumnStyles,
  baseAdaptiveTitleStyles,
  basePageStyles,
} from 'common-styles'
import { HEADER_HEIGHT } from 'root-constants/common'

export const StyledFeelingsAboutLackOfContact = {
  Wrapper: styled.div`
    ${basePageStyles};
    padding-top: ${HEADER_HEIGHT}px;
  `,
  Column: styled.div`
    ${baseAdaptiveColumnStyles};
    padding: 40px 25px 114px;
  `,
  Title: styled.h1`
    ${baseAdaptiveTitleStyles};
    margin: 0 0 18px;
  `,
}
