import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import { setMultipleAnswerAction } from 'root-redux/actions/common'
import { sendUserAnswersAction } from 'root-redux/actions/user'
import { selectUserCountryCode } from 'root-redux/selects/user'

import { checkIsPhoneValid } from 'helpers/phone'

import { TPageProps } from 'models/common.model'

import { eventLogger } from 'services/eventLogger.service'

import { SvgImage } from 'components/SvgImage'

import grayArrow from 'assets/images/sprite/right-arrow-gray.svg'
import arrow from 'assets/images/sprite/right-arrow.svg'

import { goTo } from 'browser-history'
import { CustomPageId } from 'root-constants/pages'

import { StyledPhoneNumberVariant3 as S } from './PhoneNumberVariant3.styles'

export const PhoneNumberVariant3: React.FC<TPageProps> = ({ nextPagePath }) => {
  const { t } = useTranslation()
  const [phoneNumber, setPhoneNumber] = useState('')
  const dispatch = useDispatch()
  const countryCode = useSelector(selectUserCountryCode)
  const [isPhoneFocused, setIsPhoneFocused] = useState(false)
  const [userPhoneCountry, setUserPhoneCountry] = useState('')
  const isValid = checkIsPhoneValid(phoneNumber)

  const handleChangeNumber = (value: string, { country }) => {
    setPhoneNumber(value)
    setUserPhoneCountry(country.name)
  }

  const handlePhoneFocus = () => {
    setIsPhoneFocused(true)
  }

  const handleSubmit = (e) => {
    e.preventDefault()

    dispatch(
      setMultipleAnswerAction({
        answers: {
          [CustomPageId.PHONE_NUMBER]: phoneNumber,
          userPhoneCountry,
        },
      }),
    )
    dispatch(sendUserAnswersAction())
    eventLogger.logCheckNumberClicked()
    goTo(nextPagePath)
  }

  return (
    <S.Wrapper>
      <S.Column>
        <S.Title>{t`onboarding.phoneNumber.title`}</S.Title>
        <S.PhoneForm onSubmit={handleSubmit}>
          <S.PhoneNumberInput
            defaultCountry={countryCode.toLowerCase()}
            value={phoneNumber}
            onChange={handleChangeNumber}
            onFocus={handlePhoneFocus}
          />
          {isPhoneFocused && !isValid && (
            <S.PhoneErrorText>
              {t`onboarding.introGoogle3.phoneError`}
            </S.PhoneErrorText>
          )}
          <S.Button type="submit" disabled={!isValid}>
            {t`onboarding.phoneNumber.startTracking`}
            <SvgImage svg={isValid ? arrow : grayArrow} width={24} />
          </S.Button>
        </S.PhoneForm>
      </S.Column>
    </S.Wrapper>
  )
}
