import React from 'react'
import { useTranslation } from 'react-i18next'

import { StyledBenefits as S } from 'modules/subscriptions/components/benefits/Benefits.styles'
import { SAFETY3_COHORT_BENEFITS } from 'modules/subscriptions/components/benefits/constants'

type TProps = {
  userGoal: string
}
export const BenefitsVariant3: React.FC<TProps> = ({ userGoal }) => {
  const { t } = useTranslation()

  return (
    <S.Wrapper>
      <S.Title>{t(SAFETY3_COHORT_BENEFITS[userGoal].title)}</S.Title>
      <ul>
        {SAFETY3_COHORT_BENEFITS[userGoal].benefits.map((text: string) => (
          <S.ListItem key={text}>{t(text)}</S.ListItem>
        ))}
      </ul>
    </S.Wrapper>
  )
}
