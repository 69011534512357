import styled from 'styled-components'

import { BASE_COLUMN_WIDTH, MediaBreakpoint } from 'root-constants/common'

type TProps = {
  headerBorder?: string
}
export const StyledHeader = {
  HeaderWrapper: styled.div<TProps>`
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    border-bottom: ${({ headerBorder, theme }) =>
      headerBorder || theme.common.headerBorder};
    background: ${({ theme }) => theme.colors.headerBackground};
  `,
  Header: styled.header`
    width: ${BASE_COLUMN_WIDTH}px;
    padding: ${({ theme }) => theme.common.headerPadding};
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;

    @media (min-width: ${MediaBreakpoint.MAX_PHONE}px) {
      margin: 0 0 0 180px;
      justify-content: start;
    }
  `,
}
