import styled from 'styled-components'

import { Button } from 'components/Button'
import { SvgImage } from 'components/SvgImage'

import {
  baseColumnStyles,
  basePageStyles,
  baseTitleStyles,
} from 'common-styles'
import { Color, HEADER_HEIGHT, MediaBreakpoint } from 'root-constants/common'

type TTitleProps = {
  isFontOversized: boolean
}

export const StyledEmailVariant2 = {
  Wrapper: styled.div`
    ${basePageStyles};
    padding-top: ${HEADER_HEIGHT}px;

    @media (min-width: ${MediaBreakpoint.LAPTOP}px) {
      padding-top: 120px;
    }
  `,
  Content: styled.div`
    ${baseColumnStyles};
    min-height: calc(100dvh - 130px);
    padding: 23px 0 17px;

    @media (min-width: ${MediaBreakpoint.LAPTOP}px) {
      width: 460px;
    }
  `,
  Title: styled.h2<TTitleProps>`
    ${baseTitleStyles};
    margin-bottom: 4px;

    @media (min-width: ${MediaBreakpoint.LAPTOP}px) {
      font-size: ${({ isFontOversized }) => (isFontOversized ? 38 : 40)}px;
      line-height: 55px;
      margin-bottom: 16px;
    }
  `,
  Subtitle: styled.h3`
    margin-bottom: 17px;
    text-align: center;
    font-size: 16px;
    font-weight: 600;
    line-height: normal;
    color: ${Color.LIGHT_TEXT};

    @media (min-width: ${MediaBreakpoint.LAPTOP}px) {
      font-size: 20px;
      line-height: 27px;
      margin-bottom: 24px;
    }
  `,
  InputWrapper: styled.div`
    width: 100%;
    position: relative;

    @media (min-width: ${MediaBreakpoint.LAPTOP}px) {
      input {
        font-size: 20px;
        line-height: 27px;
      }
    }
  `,
  AutoCompleteWrapper: styled.div`
    width: 100%;
    max-height: 160px;
    padding: 0 15px;
    position: absolute;
    top: 56px;
    left: 0;
    border-radius: 12px;
    overflow-y: auto;
    background-color: #fff;
    filter: drop-shadow(0px 7px 20px #c8d4d8);
    z-index: 2;
    transform: translateZ(0);
  `,
  AutoCompleteButton: styled.button`
    height: 50px;
    width: 100%;
    padding: 0;
    display: block;
    cursor: pointer;
    border: none;
    outline: none;
    font-size: 16px;
    font-weight: 500;
    line-height: 50px;
    text-decoration: none;
    text-align: left;
    color: ${Color.DEFAULT_TEXT};
    background-color: transparent;
    -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    direction: rtl;

    :not(:last-child) {
      border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    }
  `,
  AutoCompleteButtonTextWrapper: styled.span`
    unicode-bidi: plaintext;
  `,
  Note: styled.div`
    display: flex;
    flex: 0 0 auto;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 4px;
    margin-bottom: 20px;
  `,
  LockImg: styled(SvgImage)`
    width: 100%;
    max-width: 15px;
    margin-left: 3px;
    fill: ${Color.LIGHT_TEXT};
  `,
  Description: styled.p`
    font-weight: 500;
    font-size: 13px;
    line-height: 18px;
    color: #7b8898;

    @media (min-width: ${MediaBreakpoint.LAPTOP}px) {
      font-size: 14px;
      line-height: 19px;
      font-weight: 400;
    }
  `,
  Button: styled(Button)`
    margin: 0 0 22px;

    @media (min-width: ${MediaBreakpoint.LAPTOP}px) {
      width: 460px;
      font-size: 24px;
      line-height: 33px;
    }
  `,
  Footer: styled.footer`
    @media (min-width: ${MediaBreakpoint.LAPTOP}px) {
      margin-top: 112px;
    }
  `,
  Text: styled.p`
    color: #5c90ff;
    text-align: center;
    font-size: 20px;
    font-weight: 700;
    line-height: 27px;
    margin-bottom: 4px;

    strong {
      display: block;
      color: #0a324c;
      text-align: center;
      font-size: 20px;
      font-weight: 700;
      line-height: 27px;
    }

    @media (min-width: ${MediaBreakpoint.LAPTOP}px) {
      font-size: 24px;
      line-height: 33px;

      strong {
        font-size: 24px;
        line-height: 33px;
      }
    }
  `,
}
