import React from 'react'
import { Trans, useTranslation } from 'react-i18next'

import { useNextStep } from 'hooks/useNextStep'

import { TPageProps } from 'models/common.model'

import { PrivacyPolicyLink } from 'components/PrivacyPolicyLink'
import { TermsOfUseLink } from 'components/TermsOfUseLink'

import famioLogo from 'assets/images/famio-logo.png'
import leftLeaf from 'assets/images/left-silver-leaf.png'
import rightLeaf from 'assets/images/right-silver-leaf.png'
import rating from 'assets/images/stars-3.png'

import { StyledIntroVariant7 as S } from './IntroVariant7.styles'

export const IntroVariant7: React.FC<TPageProps> = ({
  pageId,
  nextPagePath,
}) => {
  const { t } = useTranslation()

  const handleContinue = useNextStep({
    pageId,
    pageName: pageId,
    question: '',
    nextPagePath,
  })

  return (
    <S.Wrapper>
      <S.Column>
        <S.LogoContainer>
          <img src={famioLogo} alt="famio" />
        </S.LogoContainer>

        <S.Title>{t`onboarding.intro7.title`}</S.Title>
        <S.Subtitle>{t`onboarding.intro7.subtitle`}</S.Subtitle>
        <S.Text>
          <Trans i18nKey="onboarding.intro7.text" />
        </S.Text>

        <S.UserInfoContainer>
          <S.UserInfoImage src={leftLeaf} alt="icon" gridArea="leftLeaf" />
          <S.UserInfoTitle>{t`onboarding.intro7.trustedBy`}</S.UserInfoTitle>
          <S.UserInfoSubtitle>{t`onboarding.intro7.millionUsers`}</S.UserInfoSubtitle>
          <S.UserInfoImage src={rightLeaf} alt="icon" gridArea="rightLeaf" />
          <S.Rating src={rating} alt="rating" />
        </S.UserInfoContainer>

        <S.Button type="button" onClick={() => handleContinue('')}>
          {t`actions.continue`}
        </S.Button>
        <S.Disclaimer>
          <Trans
            i18nKey="onboarding.intro7.disclaimer"
            components={{
              termsOfUse: <TermsOfUseLink />,
              privacyPolicy: <PrivacyPolicyLink />,
            }}
          />
        </S.Disclaimer>
      </S.Column>
    </S.Wrapper>
  )
}
