import addMemberImage from 'assets/images/add-member-phone.png'
import alexFamilyImage from 'assets/images/alex-family-phone.png'
import needHelpImage from 'assets/images/need-help-phone.png'

export const HOW_IT_WORKS_DATA = [
  {
    step: 'onboarding.introGoogle4.steps.stepOne',
    title: 'subscriptions.subscriptionsGoogle.variant6.steps.getPlan',
    text: 'subscriptions.subscriptionsGoogle.variant6.steps.firstGroup',
    image: addMemberImage,
    background: '#eff5f7',
  },
  {
    step: 'onboarding.introGoogle4.steps.stepTwo',
    title: 'subscriptions.subscriptionsGoogle.variant6.steps.customizeApp',
    text: 'subscriptions.subscriptionsGoogle.variant6.steps.realTime',
    image: needHelpImage,
    background: '#faF5ff',
  },
  {
    step: 'onboarding.introGoogle4.steps.stepThree',
    title: 'subscriptions.subscriptionsGoogle.variant6.steps.ultimatePeace',
    text: 'subscriptions.subscriptionsGoogle.variant6.steps.beSure',
    image: alexFamilyImage,
    background: '#f1f6ff',
  },
]
