import React, { useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { selectLanguage } from 'root-redux/selects/common'

import { useViewportValue } from 'hooks/ui/useViewportValue'

import { TPageProps } from 'models/common.model'

import { Disclaimer } from 'pages/intro-google/components/Disclaimer'

import { PrivacyPolicyLink } from 'components/PrivacyPolicyLink'
import { TermsOfUseLink } from 'components/TermsOfUseLink'

import burgerMenu from 'assets/images/burger-menu-v2.svg'
import gpsIcon from 'assets/images/gps-icon-blue.png'
import buttonImg from 'assets/images/right-arrow-blue.svg'

import { CDN_FOLDER_LINK, Images, Language } from 'root-constants/common'
import { CustomPageId, PageId } from 'root-constants/pages'

import { StyledIntroGoogleVariant5 as S } from './IntroGoogleVariant5.styles'
import { GeolocationBenefitsVariant1 } from './components/GeolocationBenefits'
import { JoinedUsersVariant1 } from './components/JoinedUsers'
import {
  SearchNumberAnimationVariant1,
  SearchNumberAnimationVariant2,
  SearchNumberAnimationVariant3,
} from './components/SearchNumberAnimation'
import { TrustpilotVariant1, TrustpilotVariant2 } from './components/Trustpilot'
import { SECURE_DATA } from './constants'

export const IntroGoogleVariant5: React.FC<TPageProps> = () => {
  const { t } = useTranslation()
  const [phoneNumber, setPhoneNumber] = useState('')
  const scrollToTopElement = useRef<HTMLHeadingElement>(null)
  const scrollToBottomElement = useRef<HTMLParagraphElement>(null)
  const language = useSelector(selectLanguage)

  useViewportValue()

  const isFontOversized = language === Language.PT

  const handleScrollToTop = () => {
    scrollToTopElement.current?.scrollIntoView({
      block: 'start',
      behavior: 'smooth',
    })
  }

  const handleScrollToBottom = () => {
    scrollToBottomElement.current?.scrollIntoView({
      block: 'start',
      behavior: 'smooth',
    })
  }

  return (
    <S.Wrapper>
      <S.BurgerMenu menuIconSrc={burgerMenu} />
      <S.LinksContainer>
        <nav>
          <TermsOfUseLink />
          <PrivacyPolicyLink />
        </nav>
      </S.LinksContainer>
      <S.Content>
        <S.PhoneFormColumn>
          <div>
            <S.Title ref={scrollToTopElement}>
              {t`onboarding.introGoogle5.title`}
            </S.Title>
            <S.Subtitle>{t`onboarding.introGoogle5.subtitle`}</S.Subtitle>
            <S.PhoneForm
              buttonText=""
              buttonImg={buttonImg}
              pageId={CustomPageId.PHONE_NUMBER}
              phoneNumber={phoneNumber}
              setPhoneNumber={setPhoneNumber}
              pathname={PageId.LOCATION_SEARCH_2}
            />
            <S.SecureContainer>
              {SECURE_DATA.map(({ img, title, text }) => (
                <S.SecureWrapper key={text}>
                  <img src={img} alt="icon" />
                  <S.SecureTextWrapper>
                    <S.SecureTitle>{t(title)}</S.SecureTitle>
                    <S.SecureText isFontOversized={isFontOversized}>
                      {t(text)}
                    </S.SecureText>
                  </S.SecureTextWrapper>
                </S.SecureWrapper>
              ))}
            </S.SecureContainer>
          </div>
          <SearchNumberAnimationVariant3 />
        </S.PhoneFormColumn>
        <SearchNumberAnimationVariant1 />
        <S.Column>
          <S.TopButton onClick={handleScrollToTop}>
            <img src={gpsIcon} alt="icon" />
            {t`onboarding.introGoogle5.locateNumber`}
          </S.TopButton>
          <GeolocationBenefitsVariant1 />
        </S.Column>
        <TrustpilotVariant1 handleScroll={handleScrollToTop} />
        <TrustpilotVariant2 handleScroll={handleScrollToTop} />
        <S.BottomFormColumn>
          <SearchNumberAnimationVariant2 />
          <div>
            <S.FormTitle>{t`onboarding.introGoogle5.getStarted`}</S.FormTitle>
            <S.Text ref={scrollToBottomElement}>
              {t`onboarding.introGoogle5.theBestWay`}
            </S.Text>
            <S.PhoneFormV2
              buttonText="onboarding.introGoogle5.locateThem"
              buttonImg={gpsIcon}
              pageId={CustomPageId.PHONE_NUMBER}
              phoneNumber={phoneNumber}
              setPhoneNumber={setPhoneNumber}
              pathname={PageId.LOCATION_SEARCH_2}
            />
          </div>
        </S.BottomFormColumn>
        <S.Column>
          <JoinedUsersVariant1 handleScroll={handleScrollToBottom} />
          <S.Compatible
            src={`${CDN_FOLDER_LINK}${Images.COMPATIBLE_WITH}_${language}.png`}
          />
          <Disclaimer />
          <S.CopyrightContainer>
            <S.CopyrightText>{t`onboarding.introGoogle5.copyright`}</S.CopyrightText>
            <S.CopyrightText>{t`onboarding.introGoogle5.allRights`}</S.CopyrightText>
          </S.CopyrightContainer>
        </S.Column>
      </S.Content>
    </S.Wrapper>
  )
}
