import React, { useLayoutEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router'

import { useNextStep } from 'hooks/common/useNextStep'
import { useDelayedContinue } from 'hooks/ui/useDelayedContinue'
import { useUserData } from 'hooks/user/useUserData'

import { TPageProps } from 'models/common.model'

import { AGE_RANGE_DATA_2 } from 'pages/age-range/constants'

import { OptionType } from 'components/Option'

import { replaceHistory } from 'browser-history'
import { Gender, Language } from 'root-constants/common'
import { CustomPageId, PageId } from 'root-constants/pages'

import { StyledAgeRangeVariant2 as S } from './AgeRangeVariant2.styles'

export const AgeRangeVariant2: React.FC<TPageProps> = ({
  nextPagePath,
  pageId,
}) => {
  const { t } = useTranslation()
  const { userGender } = useUserData()
  const { search } = useLocation()

  const { isAnswersDisabled, handleChange, setIsAnswersDisabled } =
    useDelayedContinue()

  const handleContinue = useNextStep({
    pageId: CustomPageId.AGE_RANGE,
    pageName: pageId,
    question: t('onboarding.ageRange.title2', { lng: Language.EN }),
    nextPagePath,
  })

  const optionProps = {
    name: pageId,
    type: OptionType.RADIO,
    onChange: (value: string) => {
      handleChange(value, handleContinue)
      setIsAnswersDisabled(true)
    },
  }

  const shouldBeRedirect = userGender === Gender.OTHER

  useLayoutEffect(() => {
    if (shouldBeRedirect) {
      replaceHistory(`${PageId.AGE_RANGE_3}${search}`)
    }
  }, [search, shouldBeRedirect])

  return (
    <S.Wrapper>
      <S.Column>
        <S.Title>{t`onboarding.ageRange.title2`}</S.Title>
        <S.Subtitle>{t`onboarding.ageRange.subtitle`}</S.Subtitle>
        <S.OptionsContainer>
          {AGE_RANGE_DATA_2.map(({ value, text, imgSrc }) => (
            <S.Option
              {...optionProps}
              key={value}
              value={value}
              disabled={isAnswersDisabled}
            >
              <S.RangeImage>
                <img src={imgSrc[userGender]} alt="age-range" />
                <S.Range>
                  {t(text)}
                  <S.ArrowIcon />
                </S.Range>
              </S.RangeImage>
            </S.Option>
          ))}
        </S.OptionsContainer>
      </S.Column>
    </S.Wrapper>
  )
}
