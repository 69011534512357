import React, { useEffect, useRef } from 'react'
import { Trans, useTranslation } from 'react-i18next'

import { useGetPrices } from 'hooks/useGetPrices'
import { usePaywall } from 'hooks/usePaywall'
import { usePurchaseStore } from 'hooks/usePurchaseStore'

import { createProductId } from 'helpers/createProductId'

import { TPageProps } from 'models/common.model'

import { ScreenName, eventLogger } from 'services/eventLogger.service'

import { SubheaderWithTimer } from 'modules/subscriptions/components/common/SubheaderWithTimer'
import { Timer } from 'modules/subscriptions/components/common/Timer'
import { PlanItemVariant1 } from 'modules/subscriptions/components/email-subscriptions/PlanItemVariant1'
import { KID_FLOW_BENEFITS } from 'modules/subscriptions/pages/EmailSubscriptions/constants'

import { Guarantee } from 'components/Guarantee'
import { ReviewsSlider } from 'components/ReviewsSlider'
import { Security } from 'components/Security'
import { Spinner } from 'components/Spinner'

import { CDN_FOLDER_LINK, SubscriptionTag } from 'root-constants/common'

import { StyledEmailSubscriptions as S } from './EmailSubscriptions.styles'

export const EmailSubscriptionsVariant2: React.FC<TPageProps> = ({
  nextPagePath,
}) => {
  const { t } = useTranslation()
  const scrollToElement = useRef<HTMLButtonElement>(null)
  const timerTriggerElementRef = useRef(null)

  const { subscriptions, language } = usePurchaseStore()
  const { hasPrices, handleShowPayment } = usePaywall(nextPagePath)
  useGetPrices({ tags: SubscriptionTag.NO_TAX })

  useEffect(() => {
    if (!subscriptions.length) return

    eventLogger.logSalePageShown({
      productIds: subscriptions.map(
        ({ mainPrices: { periodName, periodQuantity, fullPrice } }) =>
          createProductId({
            periodName,
            periodQuantity,
            price: fullPrice,
          }),
      ),
      screenName: ScreenName.ONBOARDING,
    })
  }, [subscriptions])

  const handleTimerButtonClick = () => {
    scrollToElement.current?.scrollIntoView({
      block: 'center',
      behavior: 'smooth',
    })
  }

  return !hasPrices ? (
    <Spinner />
  ) : (
    <>
      <S.BurgerMenu />
      <SubheaderWithTimer
        elemForComparisonRef={timerTriggerElementRef}
        onClick={handleTimerButtonClick}
      />
      <S.Wrapper>
        <S.Column>
          <S.Title>{t`subscriptions.variant2.titleKid`}</S.Title>
          <S.Text>{t`subscriptions.variant2.subtitleKid2`}</S.Text>
          <Timer />
        </S.Column>

        <S.KidImageContainer>
          <img
            ref={timerTriggerElementRef}
            src={`${CDN_FOLDER_LINK}/images/subscription-kid_${language}.webp`}
            alt="kid"
          />
        </S.KidImageContainer>

        <S.Column data-has-bg>
          <S.Subtitle marginBottom={27}>
            {t`subscriptions.variant1.benefitsTitle`}
          </S.Subtitle>
          <S.List>
            {KID_FLOW_BENEFITS.map((text) => (
              <S.ContentItem key={text}>
                <Trans i18nKey={text} />
              </S.ContentItem>
            ))}
          </S.List>

          <S.Subtitle marginBottom={16}>
            {t`subscriptions.email.priceToday`}
          </S.Subtitle>
          <PlanItemVariant1 />
          <S.Button
            onClick={handleShowPayment}
            marginBottom={12}
            ref={scrollToElement}
          >
            {t`actions.start`}
          </S.Button>
          <S.SubscriptionDescription />
        </S.Column>
        <S.Column>
          <Guarantee />
          <ReviewsSlider goal="kidGoalReviews" />
          <S.Button onClick={handleShowPayment} marginBottom={24}>
            {t`actions.start`}
          </S.Button>
          <Security />
        </S.Column>
      </S.Wrapper>
    </>
  )
}
