import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { IInputFieldState, TInputFieldHook } from 'models/inputField.model'

import { EMAIL_REGEXP } from 'root-constants/common'

export const useEmailInputField = (
  initialValue = '',
  errorMessage: string | null = null,
  resetErrorMessage: () => void = () => undefined,
): TInputFieldHook => {
  const { t } = useTranslation()
  const [email, setEmail] = useState<IInputFieldState>({
    value: initialValue,
    isValid: true,
    validationText: t`commonComponents.invalidEmail`,
  })
  const validateEmail = (emailValue: string) => {
    const formattedEmail = emailValue.toLowerCase().trim()
    if (!EMAIL_REGEXP.test(formattedEmail) && formattedEmail !== '') {
      setEmail((prevState) => ({
        ...prevState,
        value: formattedEmail,
        isValid: false,
        validationText: t`commonComponents.invalidEmail`,
      }))
      return
    }

    setEmail((prevState) => ({
      ...prevState,
      value: formattedEmail,
      isValid: true,
      validationText: '',
    }))
  }

  useEffect(() => {
    if (errorMessage) {
      setEmail((prevState) => ({
        ...prevState,
        isValid: false,
        validationText: errorMessage,
      }))

      resetErrorMessage()
    }
  }, [errorMessage, resetErrorMessage])

  return [email, setEmail, validateEmail]
}
