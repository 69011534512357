import styled from 'styled-components'

import { FixedContainer } from 'components/FixedContainer'

import {
  baseAdaptiveColumnStyles,
  baseAdaptiveTitleStyles,
  basePageStyles,
} from 'common-styles'
import { HEADER_HEIGHT, MediaBreakpoint } from 'root-constants/common'

export const StyledReasons = {
  Wrapper: styled.div`
    ${basePageStyles};
    padding-top: ${HEADER_HEIGHT}px;
  `,
  Column: styled.div<{ padding?: string }>`
    ${baseAdaptiveColumnStyles};
    padding: ${({ padding }) => padding || '40px 25px 114px'};

    @media (min-width: ${MediaBreakpoint.LAPTOP}px) {
      padding: 40px 25px 150px;
    }
  `,
  Title: styled.h1<{ marginBottom?: number }>`
    ${baseAdaptiveTitleStyles};
    margin-bottom: ${({ marginBottom }) => marginBottom || 18}px;

    @media (min-width: ${MediaBreakpoint.LAPTOP}px) {
      margin-bottom: 48px;
    }
  `,
  FixedContainer: styled(FixedContainer)`
    @media (min-width: ${MediaBreakpoint.LAPTOP}px) {
      position: fixed;
    }
  `,
}
