import { PageId } from 'root-constants/pages'

export const PAGE_NAMES = {
  [PageId.QUICK_WAY_1]: `quick way to check on your partner's location`,
  [PageId.QUICK_WAY_2]: `Quick way to kid's location`,
  [PageId.QUICK_WAY_3]: `Quick way to partner's location`,
  [PageId.QUICK_WAY_4]: `Quick way to friend's location`,
}
export const TITLE_PATHS = {
  [PageId.QUICK_WAY_2]: 'onboarding.quickWay.title2',
  [PageId.QUICK_WAY_3]: 'onboarding.quickWay.title',
  [PageId.QUICK_WAY_4]: 'onboarding.quickWay.title3',
}
