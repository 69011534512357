import styled from 'styled-components'

import { Button } from 'components/Button'

import bgImage from 'assets/images/intro-7-bg.jpg'

import { baseColumnStyles, basePageStyles } from 'common-styles'
import { Color, MediaBreakpoint } from 'root-constants/common'

type TImageProps = {
  gridArea: string
}
export const StyledIntroVariant7 = {
  Wrapper: styled.div`
    ${basePageStyles};
    max-width: ${MediaBreakpoint.MAX_PHONE}px;
    height: 100dvh;
    margin: 0 auto;
    padding: 40px 0 20px;
    background-image: url(${bgImage});
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;

    @media (min-width: ${MediaBreakpoint.MAX_PHONE}px) {
      max-width: 375px;
    }
  `,
  LogoContainer: styled.div`
    width: 100px;
    height: 100px;
    margin-bottom: 16px;

    img {
      margin: 0 auto;
    }
  `,
  Column: styled.div`
    ${baseColumnStyles};
    height: 100%;
  `,
  Title: styled.h1`
    margin-bottom: 4px;
    font-size: 18px;
    font-weight: 700;
    line-height: 25px;
  `,
  Subtitle: styled.h2`
    margin-bottom: 40px;
    color: #4c8eff;
    text-align: center;
    font-size: 28px;
    font-weight: 800;
    line-height: 32px;
  `,
  Text: styled.div`
    margin-bottom: 72px;
    text-align: center;
    font-size: 28px;
    font-weight: 800;
    line-height: 34px;
  `,
  UserInfoContainer: styled.div`
    max-width: 254px;
    display: grid;
    grid-template-columns: 40px 1fr 40px;
    grid-template-rows: auto;
    grid-template-areas:
      'leftLeaf title rightLeaf'
      'leftLeaf subtitle rightLeaf'
      'leftLeaf rating rightLeaf';
    grid-gap: 4px 11px;
    justify-content: center;
    align-items: center;
    margin: 0 auto auto;
  `,
  UserInfoTitle: styled.span`
    grid-area: title;
    color: ${Color.LIGHT_TEXT};
    text-align: center;
    font-size: 14px;
    font-weight: 600;
    line-height: 19px;
  `,
  UserInfoSubtitle: styled.span`
    grid-area: subtitle;
    text-align: center;
    font-size: 18px;
    font-weight: 800;
    line-height: 24px;
    letter-spacing: 0.5px;
  `,
  UserInfoImage: styled.img<TImageProps>`
    grid-area: ${({ gridArea }) => gridArea};
  `,
  Rating: styled.img`
    grid-area: rating;
  `,
  Button: styled(Button)`
    margin-bottom: 15px;
  `,
  Disclaimer: styled.p`
    color: ${Color.LIGHT_TEXT};
    text-align: center;
    font-size: 11px;
    font-weight: 400;
    line-height: 18px;

    a {
      color: inherit;
      text-decoration: underline;
    }
  `,
}
