import React from 'react'
import { useTranslation } from 'react-i18next'

import { Autoplay } from 'swiper'
import { Swiper as Carousel, SwiperSlide } from 'swiper/react'

import { useNextStep } from 'hooks/useNextStep'

import { TPageProps } from 'models/common.model'

import { Button } from 'components/Button'

import quotesIcon from 'assets/images/quotes.png'

import {
  BackgroundGlobalStyles,
  StyledReviewsVariant1 as S,
} from './ReviewsVariant1.styles'
import { REVIEWS_DATA } from './constants'

export const ReviewsVariant1: React.FC<TPageProps> = ({
  pageId,
  nextPagePath,
}) => {
  const { t } = useTranslation()

  const handleContinue = useNextStep({
    pageId,
    pageName: pageId,
    question: '',
    nextPagePath,
  })

  return (
    <S.Wrapper>
      <S.BurgerMenu />
      <BackgroundGlobalStyles />
      <S.Content>
        <Carousel
          modules={[Autoplay]}
          style={{ maxHeight: '500px', paddingTop: '30px' }}
          slidesPerView="auto"
          loop
          speed={5000}
          direction="vertical"
          spaceBetween={42}
          autoplay={{
            delay: 0,
            pauseOnMouseEnter: false,
            disableOnInteraction: false,
          }}
        >
          {REVIEWS_DATA.map(
            ({
              borderImage,
              avatar,
              labelText,
              mainText,
              additionalText,
              name,
            }) => (
              <SwiperSlide key={name}>
                <S.Card borderImage={borderImage}>
                  <S.Label>{t(labelText)}</S.Label>
                  <S.QuotesContainer>
                    <img src={quotesIcon} alt="quotes" />
                  </S.QuotesContainer>
                  <S.MainText>{t(mainText)}</S.MainText>
                  <S.AdditionalText>{t(additionalText)}</S.AdditionalText>
                  <S.AvatarContainer>
                    <img src={avatar} alt="avatar" />
                  </S.AvatarContainer>
                  <S.Name>{t(name)}</S.Name>
                </S.Card>
              </SwiperSlide>
            ),
          )}
        </Carousel>
      </S.Content>
      <S.Footer>
        <S.Title>{t`onboarding.reviews.title`}</S.Title>
        <Button type="button" onClick={() => handleContinue('')}>
          {t`actions.continue`}
        </Button>
      </S.Footer>
    </S.Wrapper>
  )
}
