import React from 'react'
import { useTranslation } from 'react-i18next'

import { StyledDivider as S } from './Divider.styles'

export const Divider: React.FC<{ className?: string }> = ({ className }) => {
  const { t } = useTranslation()

  return (
    <S.Divider className={className}>
      <S.DividerText>{t`payment.or`}</S.DividerText>
    </S.Divider>
  )
}
