import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { selectUUID } from 'root-redux/selects/user'

import { eventLogger } from 'services/eventLogger.service'

export const useStartSession = (): void => {
  const dispatch = useDispatch()
  const uuid = useSelector(selectUUID)

  useEffect(() => {
    eventLogger.logSessionStarted()
  }, [dispatch])

  useEffect(() => {
    if (uuid) {
      window.fbq('track', 'ViewContent', {}, { eventID: uuid })
    }
  }, [uuid])
}
