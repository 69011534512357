import styled, { css } from 'styled-components'

import { StripePaymentForm } from 'modules/payment/components/StripePaymentForm'

import { Color, MediaBreakpoint } from 'root-constants/common'

type TTextProps = {
  gridArea: string
}

type TPaymentText = {
  isMexicanPeso: boolean
  color?: string
}

const commonPriceTextStyles = css`
  color: ${Color.BLUE};
  text-align: center;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 28px;
`

export const StyledPaymentFlowsGoogleVariant5 = {
  Wrapper: styled.div``,
  PriceContainer: styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto;
    grid-template-areas:
      'priceText priceValue'
      'trialDuration trialDuration';
    margin: 24px 0 16px;
  `,
  PriceText: styled.p<TTextProps>`
    ${commonPriceTextStyles};
    grid-area: ${({ gridArea }) => gridArea};
    justify-self: flex-start;
  `,
  PriceValue: styled.p<TTextProps>`
    ${commonPriceTextStyles};
    grid-area: ${({ gridArea }) => gridArea};
    justify-self: flex-end;
  `,
  TrialDurationText: styled.p`
    grid-area: trialDuration;
    justify-self: flex-end;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
  `,
  Disclaimer: styled.p`
    margin-bottom: 16px;
    font-size: 11px;
    font-weight: 400;
    line-height: 18px;
    color: ${Color.LIGHT_TEXT};
  `,
  StripePaymentForm: styled(StripePaymentForm)`
    @media (min-width: ${MediaBreakpoint.LAPTOP}px) {
      width: initial;
      margin-bottom: 25px;
    }
  `,
  Image: styled.img`
    max-width: 256px;
    margin: 40px auto 16px;
  `,
  Guarantee: styled.p`
    font-size: 13px;
    line-height: 20px;
    text-align: center;
    opacity: 0.7;
    margin-bottom: 48px;
  `,
  BottomDisclaimer: styled.p`
    font-size: 11px;
    line-height: 18px;
    text-align: center;
    color: ${Color.LIGHT_TEXT};
    margin-bottom: 44px;

    a {
      color: inherit;
      text-decoration: underline;
    }
  `,
  PriceContainerV2: styled.div`
    margin: 24px 0 16px;
    display: flex;
    flex-direction: column;
  `,
  PaymentContainer: styled.div`
    display: flex;
    justify-content: space-between;

    &:not(:last-of-type) {
      margin-bottom: 8px;
    }
  `,
  PaymentText: styled.p<TPaymentText>`
    ${commonPriceTextStyles};
    font-size: ${({ isMexicanPeso }) => (isMexicanPeso ? '14px' : '16px')};
    text-align: start;
    color: ${({ color }) => color || Color.BLUE};
  `,
  PaymentDiscountText: styled.p<TPaymentText>`
    ${commonPriceTextStyles};
    color: ${Color.RED};
    font-size: ${({ isMexicanPeso }) => (isMexicanPeso ? '14px' : '16px')};
    text-align: start;
    max-width: 230px;
  `,
  TrialDurationTextV2: styled.p`
    text-align: end;
    justify-self: flex-end;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
  `,
}
