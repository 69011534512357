import { IAppState } from 'models/store.model'
import { IDeliveryInfo, IUserStatus } from 'models/user.model'

import { PaymentMethod, PaymentSystem } from 'modules/payment/constants'

export const selectUUID = (state: IAppState): string => state.user.uuid
export const selectShortenedOneLink = (state: IAppState): string =>
  state.user.shortenedOneLink
export const selectUserStatus = (state: IAppState): IUserStatus | null =>
  state.user.status
export const selectHasUserEmail = (state: IAppState): boolean =>
  state.user.status?.email?.hasEmail || false
export const selectHasUserSubscription = (state: IAppState): boolean | null =>
  state.user.status?.hasSubscription || null
export const selectEmail = (state: IAppState): string =>
  state.user.status?.email?.onboardingEmail || ''
export const selectUserCountryCode = (state: IAppState): string =>
  state.user.status?.countryCode || ''
export const selectUserPaymentMethod = (state: IAppState): PaymentMethod =>
  state.user.status?.config.paymentMethod || PaymentMethod.CREDIT_CARD
export const selectGeneralSubscriptionTrialPeriod = (
  state: IAppState,
): number => state.user.status?.config.trialPeriod || 0
export const selectIsDiscountApplied = (state: IAppState): boolean =>
  state.user.status?.config.isDiscountApplied || false
export const selectCookieConsent = (state: IAppState): string[] | null =>
  state.user.cookieConsent || state.user.status?.config.cookieConsent || null
export const selectIsUpgradePassed = (state: IAppState): boolean =>
  state.user.status?.upgrade.isUpgradePassed || false
export const selectIsUpgradeAvailable = (state: IAppState): boolean =>
  state.user.status?.upgrade.isUpgradeAvailable || false
export const selectUtmTags = (state: IAppState): Record<string, string> =>
  state.user.utmTags || state.user.status?.config?.utmTags || {}
export const selectDeliveryInfo = (state: IAppState): IDeliveryInfo | null =>
  state.user.deliveryInfo
export const selectUserPaymentId = (state: IAppState): string =>
  state.user.status?.config?.paymentId || ''
export const selectUserPaymentSystem = (state: IAppState): PaymentSystem =>
  state.user.status?.config?.paymentSystem || PaymentSystem.STRIPE
