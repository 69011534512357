import styled, { css } from 'styled-components'

import { Color } from 'root-constants/common'

const commonTextStyles = css`
  font-size: 16px;
  line-height: 18px;
`

export const StyledPlanBlockVariant1 = {
  Wrapper: styled.div`
    width: 100%;
  `,
  Title: styled.h2`
    margin-bottom: 36px;
    font-weight: 700;
    font-size: 24px;
    line-height: 33px;
    text-align: center;
  `,
  PriceContainer: styled.div`
    position: relative;
    display: grid;
    grid-template-columns: 85px 80px 1fr;
    grid-template-rows: auto;
    grid-template-areas: 'oldPriceText oldPriceText oldPrice' 'priceText discount price';
    grid-row-gap: 12px;
    align-items: center;
    padding: 36px 20px 26px;
    margin-bottom: 32px;
    background-color: ${Color.WHITE};
    border: 2px solid #4946d2;
    border-radius: 16px;

    ::before {
      content: attr(data-plan-period);
      position: absolute;
      top: 0;
      left: 50%;
      transform: translate(-50%, -50%);
      background-color: #4946d2;
      font-weight: 800;
      font-size: 10px;
      line-height: 18px;
      letter-spacing: 0.4px;
      text-transform: uppercase;
      color: ${Color.WHITE};
      padding: 3px 9px 2px;
      border-radius: 100px;
    }
  `,
  OldPriceText: styled.p`
    ${commonTextStyles};
    grid-area: oldPriceText;
  `,
  PriceText: styled.p`
    ${commonTextStyles};
    grid-area: priceText;
    font-weight: 800;
  `,
  OldPrice: styled.p`
    ${commonTextStyles};
    grid-area: oldPrice;
    justify-self: flex-end;
    text-decoration: line-through;
    color: #fd417f;
  `,
  Price: styled.p`
    ${commonTextStyles};
    grid-area: price;
    justify-self: flex-end;
    font-weight: 800;
  `,
  Discount: styled.p`
    grid-area: discount;
    padding: 5px;
    background-color: #7bc27e;
    color: ${Color.WHITE};
    border-radius: 100px;
    font-size: 10px;
    font-weight: 800;
    line-height: 12px;
    text-align: center;
  `,
}
