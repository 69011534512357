import { createGlobalStyle } from 'styled-components'

export const GlobalStyleForMap = createGlobalStyle`
  .leaflet-container {
    height: 100vh;
    width: 100vw;
    z-index: 1;
  }
  .leaflet-touch .leaflet-control-attribution {
    display: none;
  }
  .leaflet-top {
    display: none;
  }
  .leaflet-left {
    display: none;
  }
`
