import { createBrowserHistory } from 'history'

import { ILocationsState } from 'models/common.model'

// In couple with CustomRouter component
// is needed for using navigate outside of React components
export const browserHistory = createBrowserHistory()
export const goTo = (location: string | ILocationsState): void =>
  browserHistory.push(location)
export const goBack = (): void => browserHistory.back()
export const replaceHistory = (location: string | ILocationsState): void =>
  browserHistory.replace(location)
