import styled, { css } from 'styled-components'

import checkIcon from 'assets/images/check-icon-white-rounded.svg'

import { Color } from 'root-constants/common'

type TProps = {
  isSelected: boolean
}

const commonTextStyles = css`
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
`

export const StyledPlanItemVariant1 = {
  Wrapper: styled.div<TProps>`
    display: grid;
    grid-template-columns: 1fr 190px;
    grid-template-rows: auto;
    grid-row-gap: 9px;
    grid-template-areas: ${({ isSelected }) =>
      isSelected ? `'period checkIcon' 'price text'` : `'period price'`};
    padding: 16px;
    border-radius: 12px;
    box-shadow: ${({ isSelected }) =>
      isSelected ? `0 0 0 2px ${Color.ACCENT_VIOLET}` : `0 0 0 2px #bcc9d0`};
    background: ${({ isSelected }) =>
      isSelected ? '#eee9ff' : `${Color.WHITE}`};
  `,
  Period: styled.p`
    ${commonTextStyles};
    grid-area: period;
    font-weight: 700;
  `,
  Price: styled.p<TProps>`
    ${commonTextStyles};
    grid-area: price;
    color: ${({ isSelected }) =>
      isSelected ? `${Color.LIGHT_TEXT}` : `${Color.DEFAULT_TEXT}`};
    justify-self: ${({ isSelected }) =>
      isSelected ? 'flex-start' : 'flex-end'};
  `,
  Text: styled.p`
    ${commonTextStyles};
    grid-area: text;
  `,
  Checkbox: styled.div`
    grid-area: checkIcon;
    justify-self: flex-end;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    background-color: ${Color.ACCENT_VIOLET};
    background-image: url(${checkIcon});
    background-repeat: no-repeat;
    background-position: center;
    background-size: 16px;
  `,
}
