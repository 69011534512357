import reviewAvatar11 from 'assets/images/review-avatar-11.png'
import reviewAvatar12 from 'assets/images/review-avatar-12.png'
import reviewAvatar13 from 'assets/images/review-avatar-13.png'
import reviewAvatar14 from 'assets/images/review-avatar-14.png'

import { ChildFlow } from 'root-constants/common'

export const REVIEWS_DATA = {
  [ChildFlow.KID]: [
    {
      avatar: reviewAvatar11,
      userName: 'onboarding.parentReviews.firstAuthor',
      reviewText: 'onboarding.parentReviews.firstReview',
      borderColor: '#d9d8f6',
    },
    {
      avatar: reviewAvatar12,
      userName: 'onboarding.parentReviews.secondAuthor',
      reviewText: 'onboarding.parentReviews.secondReview',
      borderColor: '#eed0e4',
    },
  ],
  [ChildFlow.MIXED]: [
    {
      avatar: reviewAvatar12,
      userName: 'onboarding.parentReviews.secondAuthor',
      reviewText: 'onboarding.parentReviews.secondReview',
      borderColor: '#eed0e4',
    },
    {
      avatar: reviewAvatar13,
      userName: 'onboarding.parentReviews.thirdAuthor',
      reviewText: 'onboarding.parentReviews.thirdReview',
      borderColor: '#d9d8f6',
    },
  ],
  [ChildFlow.TEENAGE]: [
    {
      avatar: reviewAvatar14,
      userName: 'onboarding.parentReviews.fourthAuthor',
      reviewText: 'onboarding.parentReviews.fourthReview',
      borderColor: '#eed0e4',
    },
    {
      avatar: reviewAvatar13,
      userName: 'onboarding.parentReviews.thirdAuthor',
      reviewText: 'onboarding.parentReviews.thirdReview',
      borderColor: '#d9d8f6',
    },
  ],
}
