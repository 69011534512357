import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useLocation } from 'react-router-dom'

import { sendUserConfigAction, setUtmTagsAction } from 'root-redux/actions/user'

export const useUtmTags = () => {
  const { search } = useLocation()
  const dispatch = useDispatch()
  const URLParams = new URLSearchParams(search)
  const utmTagsFromUrl = Object.fromEntries(URLParams.entries())

  useEffect(() => {
    if (utmTagsFromUrl.utm_source) {
      dispatch(setUtmTagsAction(utmTagsFromUrl))
      dispatch(
        sendUserConfigAction({
          utm_tags: utmTagsFromUrl,
        }),
      )
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch])
}
