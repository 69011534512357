import { useSelector } from 'react-redux'

import { selectCurrentVariantCohortToUse } from 'root-redux/selects/common'
import { selectUserCountryCode } from 'root-redux/selects/user'

import {
  VAT_INCLUDED_COHORTS,
  VAT_INCLUDED_COUNTRIES,
} from 'root-constants/common'

export const useVatInfo = (): boolean => {
  const countryCode = useSelector(selectUserCountryCode)
  const cohortToUse = useSelector(selectCurrentVariantCohortToUse)

  return (
    VAT_INCLUDED_COHORTS.includes(cohortToUse) &&
    VAT_INCLUDED_COUNTRIES.includes(countryCode.toLowerCase())
  )
}
