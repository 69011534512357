import React, { Dispatch, SetStateAction } from 'react'
import { useTranslation } from 'react-i18next'

import { Option, OptionType } from 'components/Option'

import numberIcon from 'assets/images/circle-with-number.png'
import famioLogo from 'assets/images/famio-logo.png'
import giftIcon from 'assets/images/gift.png'

import { StyledChipoloPlanItems as S } from './ChipoloPlanItems.styles'
import { CHIPOLO_IMAGES, COLORS_DATA } from './constants'

type TProps = {
  chipoloColor: string
  onSetChipoloColor: Dispatch<SetStateAction<string>>
  upgradePriceWithoutTaxes: string
}

export const ChipoloPlanItems: React.FC<TProps> = ({
  chipoloColor,
  onSetChipoloColor,
  upgradePriceWithoutTaxes,
}) => {
  const { t } = useTranslation()

  const handleColorChange = (value: string) => {
    onSetChipoloColor(value)
  }

  return (
    <S.Wrapper>
      <S.Content>
        <S.ImageContainer>
          <img src={famioLogo} alt="famio" />
          <S.Icon src={numberIcon} alt="number" />
        </S.ImageContainer>
        <S.Text>{t`subscriptions.chipolo.checkout.yearly`}</S.Text>
        <S.Text>${upgradePriceWithoutTaxes}</S.Text>
      </S.Content>

      <S.Content>
        <S.ImageContainer>
          <img src={CHIPOLO_IMAGES[chipoloColor]} alt="chipolo" />
          <S.Icon src={giftIcon} alt="gift" />
        </S.ImageContainer>
        <S.Text>{t`subscriptions.chipolo.checkout.chipoloProductName`}</S.Text>
        <S.Text hasGreenColor>
          {t`subscriptions.chipolo.checkout.getForFree`}
        </S.Text>
      </S.Content>

      <S.TextContainer>
        <S.ColorTitle>{t`subscriptions.chipolo.checkout.chooseColor`}</S.ColorTitle>
        <S.ColorValue>
          {t(`subscriptions.chipolo.checkout.${chipoloColor}`)}
        </S.ColorValue>
      </S.TextContainer>

      <S.ColorContainer>
        {COLORS_DATA.map(({ value, backgroundColor, borderColor }) => (
          <Option
            key={value}
            type={OptionType.RADIO}
            value={value}
            checked={value === chipoloColor}
            onChange={handleColorChange}
          >
            <S.ColorOptionContainer isSelected={chipoloColor === value}>
              <S.ColorOption
                borderColor={borderColor}
                backgroundColor={backgroundColor}
              />
            </S.ColorOptionContainer>
          </Option>
        ))}
      </S.ColorContainer>
    </S.Wrapper>
  )
}
