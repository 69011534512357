import styled from 'styled-components'

import { BurgerMenu } from 'components/BurgerMenu'
import { Button } from 'components/Button'
import { Option } from 'components/Option'
import { QuestionButton } from 'components/QuestionButton'

import { baseColumnStyles, basePageStyles } from 'common-styles'
import { Color, HEADER_HEIGHT } from 'root-constants/common'

type TQuestionButtonProps = {
  backgroundImage: string
}
export const StyledAgeRangeVariant1 = {
  Wrapper: styled.div`
    ${basePageStyles};
    padding-top: ${HEADER_HEIGHT}px;
  `,
  Column: styled.div`
    ${baseColumnStyles};
    text-align: center;
    margin: 0 auto;
    padding: 16px 0 10px;
  `,
  Title: styled.h1`
    font-weight: 800;
    font-size: 28px;
    line-height: 36px;
    text-align: center;
  `,
  Subtitle: styled.p`
    font-size: 16px;
    font-weight: 600;
    line-height: 22px;
    margin-top: 16px;
    text-align: center;
    color: ${Color.LIGHT_TEXT};
  `,
  OptionContainer: styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 15px;
    flex-wrap: wrap;
    width: 100%;
    margin-bottom: 22px;
    margin-top: 16px;
  `,
  Option: styled(Option)`
    background-color: ${Color.WHITE};
    max-width: 146px;
    width: 100%;
  `,
  QuestionButton: styled(QuestionButton)<TQuestionButtonProps>`
    background-color: ${Color.WHITE};
    border-radius: 12px;
    box-shadow: 0 3px 13px 0 #d6dcf4;
    padding: 0;
    background-image: url(${({ backgroundImage }) => backgroundImage});
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    display: block;

    &[data-has-check-icon='true'] {
      min-height: 112px;
      border-radius: 12px 12px 0 0;
      padding: 0;
      max-width: 146px;
      width: 100%;
      margin-bottom: 25px;
    }

    &[data-has-check-icon='true']::before {
      left: 10px;
      right: 0;
      top: 20px;
      width: 18px;
      height: 18px;
      border: 2px solid #cbc3e7;
    }

    input:checked + & {
      &[data-has-check-icon='true'] {
        background-color: #5c90ff1a;
      }
    }
  `,
  TextWrapper: styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    width: 146px;
    padding: 6px 0 8px 0;
    border-radius: 0 0 12px 12px;
    background: #7854f8;
    position: absolute;
    bottom: -35px;
    color: ${Color.WHITE};
    font-size: 16px;
    font-weight: 800;
    line-height: 22px;
  `,
  Button: styled(Button)`
    margin-bottom: 16px;
  `,
  BurgerMenu: styled(BurgerMenu)`
    button {
      top: 6px;
      right: 6px;
    }
  `,
}
