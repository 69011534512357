import styled from 'styled-components'

import { Button } from 'components/Button'

import {
  StyledPhoneInput,
  baseAdaptiveColumnStyles,
  baseAdaptiveTitleStyles,
  basePageStyles,
} from 'common-styles'
import { HEADER_HEIGHT, MediaBreakpoint } from 'root-constants/common'

export const StyledPhoneNumberVariant2 = {
  Wrapper: styled.div`
    ${basePageStyles};
    padding-top: ${HEADER_HEIGHT}px;
  `,
  Column: styled.div`
    ${baseAdaptiveColumnStyles};
    padding: 36px 0 32px;
  `,
  Title: styled.h1`
    ${baseAdaptiveTitleStyles};
    margin: 0 0 24px;
  `,
  PhoneForm: styled.form`
    width: 343px;
    border-radius: 16px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 16px;

    @media (min-width: ${MediaBreakpoint.LAPTOP}px) {
      width: 492px;
    }
  `,
  PhoneText: styled.h3`
    color: #0a324d;
    text-align: center;
    font-size: 20px;
    font-weight: 700;
    line-height: 28px;
    width: 296px;
  `,
  PhoneErrorText: styled.p`
    color: #ff0000;
    text-align: center;
    font-size: 18px;
    font-weight: 600;
    line-height: 20px;
  `,
  PhoneNumberInput: styled(StyledPhoneInput)`
    width: 311px;
    border: 1px solid #cbc3e7;
    background: #fff;

    @media (min-width: ${MediaBreakpoint.LAPTOP}px) {
      width: 460px;
    }
  `,
  LaterButton: styled(Button)`
    width: 310px;
    background: #eff5f7;
    color: #0a324d;

    @media (min-width: ${MediaBreakpoint.LAPTOP}px) {
      width: 460px;
    }
  `,
  Button: styled(Button)`
    width: 310px;

    @media (min-width: ${MediaBreakpoint.LAPTOP}px) {
      width: 460px;
    }
  `,
  ImageContainer: styled.div`
    width: 100%;
    margin-bottom: 47px;
    aspect-ratio: 170/170;
    max-width: 170px;
    display: block;
    background-position: center;
    position: relative;

    @media (min-width: ${MediaBreakpoint.LAPTOP}px) {
      display: none;
    }
  `,
  PhoneIcon: styled.img`
    max-width: 90px;
    position: absolute;
    bottom: -25px;
    right: -25px;
  `,
}
