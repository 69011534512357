import styled from 'styled-components'

export const StyledSecurity = {
  SecureImage: styled.img`
    margin: 0 auto 26px;
  `,
  SecureCard: styled.div`
    display: flex;
    flex-direction: column;
    font-size: 13px;
    line-height: 20px;
    margin-bottom: 32px;
    padding: 22px 24px 0;
    background-color: ${({ theme }) => theme.colors.guaranteeBackground};
    border-radius: 16px;

    li {
      margin-bottom: 32px;
    }

    a {
      color: #0091ff;
      font-weight: 700;
    }
  `,
  SecureItemTitle: styled.h4`
    margin-bottom: 8px;
    font-size: 16px;
    line-height: 22px;
    font-weight: 700;
    color: ${({ theme }) => theme.colors.primaryText};
  `,
  SecureItemText: styled.p`
    color: ${({ theme }) => theme.colors.tertiaryText};

    a {
      display: inline-block;

      ::first-letter {
        text-transform: uppercase;
      }
    }
  `,
}
