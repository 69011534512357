import styled from 'styled-components'

type TProps = {
  isFinished: boolean
}
export const StyledLocationVariant2 = {
  BackgroundWrapper: styled.div<TProps>`
    visibility: ${({ isFinished }) => (isFinished ? 'visible' : 'none')};
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: ${({ isFinished }) => (isFinished ? '2' : '-1')};
    opacity: ${({ isFinished }) => (isFinished ? '1' : '0')};
    transition: opacity 0.2s;
    background-color: rgba(0, 0, 0, 0.35);
    backdrop-filter: blur(4px);
  `,
}
