import React from 'react'
import { Trans, useTranslation } from 'react-i18next'

import { useNextStep } from 'hooks/useNextStep'
import { useUserData } from 'hooks/useUserData'

import { TPageProps } from 'models/common.model'

import { Button } from 'components/Button'

import socialProofKid from 'assets/images/social-proof-kid.jpg'
import socialProofTeenage from 'assets/images/social-proof-teenage.jpg'

import { ChildFlow } from 'root-constants/common'

import { StyledSocialProofVariant19 as S } from './SocialProofVariant19.styles'

export const SocialProofVariant19: React.FC<TPageProps> = ({
  pageId,
  nextPagePath,
}) => {
  const { t } = useTranslation()
  const { childFlow } = useUserData()

  const isKidFlow = childFlow === ChildFlow.KID
  const isTeenageFlow = childFlow === ChildFlow.TEENAGE

  const handleContinue = useNextStep({
    pageId,
    pageName: pageId,
    question: '',
    nextPagePath,
  })

  return (
    <S.Wrapper>
      <S.ImageContainer>
        <img
          src={isKidFlow ? socialProofKid : socialProofTeenage}
          alt="socialProof"
        />
      </S.ImageContainer>
      <S.Column>
        <S.Title>{t`onboarding.socialProof.variant19.title`}</S.Title>
        {!isTeenageFlow && (
          <S.Text>
            <Trans i18nKey="onboarding.socialProof.variant19.missPerDay" />
          </S.Text>
        )}
        {!isKidFlow && (
          <S.Text marginTop={16}>
            <Trans i18nKey="onboarding.socialProof.variant19.nonFamilyMembers" />
          </S.Text>
        )}
      </S.Column>
      <S.FixedContainer>
        <S.Note>
          <Trans
            i18nKey={
              isKidFlow
                ? 'onboarding.socialProof.variant19.kidNote'
                : 'onboarding.socialProof.variant19.note'
            }
          />
        </S.Note>
        <Button type="button" onClick={() => handleContinue('')}>
          {t`actions.continue`}
        </Button>
      </S.FixedContainer>
    </S.Wrapper>
  )
}
