import React, { useEffect, useLayoutEffect, useRef, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'
import YouTube from 'react-youtube'

import { useGetPageInfo } from 'hooks/useGetPageInfo'
import { useGetPrices } from 'hooks/useGetPrices'
import { usePaywall } from 'hooks/usePaywall'
import { usePurchaseStore } from 'hooks/usePurchaseStore'
import { useScrollToAnchor } from 'hooks/useScrollToAnchor'
import { useViewportValue } from 'hooks/useViewportValue'

import { createProductId } from 'helpers/createProductId'

import { TPageProps } from 'models/common.model'

import { ScreenName, eventLogger } from 'services/eventLogger.service'

import { TimerVariant2 } from 'modules/subscriptions/components/common/Timer'
import { AppStatistics } from 'modules/subscriptions/components/google/AppStatistics'
import {
  BenefitsVariant1,
  BenefitsVariant2,
  BenefitsVariant3,
} from 'modules/subscriptions/components/google/Benefits'
import { StepsVariant1 } from 'modules/subscriptions/components/google/Steps'
import { SubscriptionsBlockVariant1 } from 'modules/subscriptions/components/google/SubscriptionsBlockVariant1'
import {
  UNIQUE_VIEWS_NUMBER,
  YOUTUBE_VIDEO_ID,
} from 'modules/subscriptions/constants'

import { Security } from 'components/Security'
import { Spinner } from 'components/Spinner'

import { goTo } from 'browser-history'
import {
  CDN_FOLDER_LINK,
  Images,
  Language,
  SubscriptionTag,
} from 'root-constants/common'
import { CustomPageId } from 'root-constants/pages'

import { StyledSubscriptionsGoogleVariant4 as S } from './SubscriptionsGoogleVariant4.styles'

export const SubscriptionsGoogleVariant4: React.FC<TPageProps> = ({
  nextPagePath,
}) => {
  const { t } = useTranslation()
  const { search } = useLocation()
  const scrollToTopElement = useRef<HTMLParagraphElement>(null)
  const scrollToBottomElement = useRef<HTMLHeadingElement>(null)

  const [phoneNumber, setPhoneNumber] = useState('')
  const [videoStartCounter, setVideoStartCounter] = useState(0)

  const { hasUserEmail, language, subscriptions } = usePurchaseStore()
  const { hasPrices } = usePaywall(nextPagePath)
  const { currentPaymentPageId } = useGetPageInfo()
  useGetPrices({ tags: SubscriptionTag.NO_TAX })
  useScrollToAnchor(hasPrices)
  useViewportValue()

  useLayoutEffect(() => {
    if (hasUserEmail) {
      goTo({ pathname: currentPaymentPageId, search })
    }
  }, [search, hasUserEmail, currentPaymentPageId])

  useEffect(() => {
    if (!subscriptions.length) return

    eventLogger.logSalePageShown({
      productIds: subscriptions.map(
        ({ mainPrices: { periodName, periodQuantity, fullPrice } }) =>
          createProductId({
            periodName,
            periodQuantity,
            price: fullPrice,
          }),
      ),
      screenName: ScreenName.ONBOARDING,
    })
  }, [subscriptions])

  const handleButtonScroll = () => {
    scrollToTopElement.current?.scrollIntoView({
      block: 'start',
      behavior: 'smooth',
    })
  }

  const handleVideoClick = () => {
    setVideoStartCounter((prevState) => prevState + 1)
    if (videoStartCounter < UNIQUE_VIEWS_NUMBER) {
      eventLogger.logVideoPlayStarted()
    }
  }

  return !hasPrices ? (
    <Spinner />
  ) : (
    <S.Wrapper id="1">
      <S.BurgerMenu />
      <S.Content>
        <S.IntroMediaContainer
          imageUrl={`${CDN_FOLDER_LINK}${Images.GOOGLE_SUBS_PHONE_PREMIUM}_${Language.EN}.jpg`}
        >
          <TimerVariant2 marginBottom={language === Language.EN ? 24 : 0} />
          <S.SaleTitle fontSize={language === Language.EN ? 30 : 25}>
            <Trans i18nKey="subscriptions.subscriptionsGoogle.variant4.sale" />
          </S.SaleTitle>
          <S.SaleTextContainer>
            <S.SaleText>{t`subscriptions.subscriptionsGoogle.variant4.upTo`}</S.SaleText>
            <S.SaleDiscount>{t`subscriptions.subscriptionsGoogle.variant4.discount`}</S.SaleDiscount>
          </S.SaleTextContainer>
        </S.IntroMediaContainer>

        <S.Column>
          <S.Title>
            <Trans i18nKey="subscriptions.subscriptionsGoogle.variant1.getLocation" />
          </S.Title>
          <S.Text ref={scrollToTopElement}>
            <Trans i18nKey="subscriptions.subscriptionsGoogle.variant1.allYouNeed" />
          </S.Text>
          <S.PhoneForm
            buttonText="subscriptions.subscriptionsGoogle.variant1.locateTheNumber"
            pageId={CustomPageId.PHONE_NUMBER}
            phoneNumber={phoneNumber}
            setPhoneNumber={setPhoneNumber}
            marginBottom={48}
          />
        </S.Column>
        <BenefitsVariant1 marginBottom={48} />

        <S.Column>
          <AppStatistics />

          <S.SaleTitle>{t`subscriptions.subscriptionsGoogle.variant4.onlyToday`}</S.SaleTitle>
          <S.SaleTextContainer>
            <S.SaleText>{t`subscriptions.subscriptionsGoogle.variant4.get`}</S.SaleText>
            <S.SaleDiscount>
              {t`subscriptions.subscriptionsGoogle.variant4.discount`}
            </S.SaleDiscount>
          </S.SaleTextContainer>
        </S.Column>

        <S.PhoneImageContainer>
          <img
            src={
              language === Language.DE
                ? `${CDN_FOLDER_LINK}${Images.GOOGLE_SUBS_PHONE_FAMILY}_${Language.EN}.png`
                : `${CDN_FOLDER_LINK}${Images.GOOGLE_SUBS_PHONE_FAMILY}_${language}.png`
            }
            alt="phone"
          />
        </S.PhoneImageContainer>
        <S.ButtonContainer>
          <S.Button type="button" onClick={handleButtonScroll}>
            {t`subscriptions.subscriptionsGoogle.variant4.getDiscount`}
          </S.Button>
        </S.ButtonContainer>

        <S.Column>
          <BenefitsVariant2 />
          <BenefitsVariant3 />

          <S.SubscribeButton type="button" onClick={handleButtonScroll}>
            {t`subscriptions.subscriptionsGoogle.variant1.subscribe`}
          </S.SubscribeButton>
        </S.Column>

        <S.VideoContainer>
          <YouTube videoId={YOUTUBE_VIDEO_ID} onPlay={handleVideoClick} />
        </S.VideoContainer>

        <S.Column id="3">
          <S.StepsTitle>{t`onboarding.introGoogle4.howItWorks`}</S.StepsTitle>
          <StepsVariant1 marginBottom={48} />
          <S.PhoneFormTitle ref={scrollToBottomElement} id="4">
            {t`onboarding.introGoogle4.steps.enterThePhone`}
          </S.PhoneFormTitle>
        </S.Column>

        <S.PhoneForm
          buttonText="onboarding.introGoogle4.phoneButton"
          pageId={CustomPageId.PHONE_NUMBER}
          phoneNumber={phoneNumber}
          setPhoneNumber={setPhoneNumber}
        />
        <SubscriptionsBlockVariant1 ref={scrollToBottomElement} hasTimer />

        <S.Column>
          <Security />
        </S.Column>
      </S.Content>
    </S.Wrapper>
  )
}
