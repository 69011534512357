import React, { SVGProps } from 'react'

export interface ISvg {
  viewBox: string
  id: string
}

interface IProps extends SVGProps<SVGSVGElement> {
  svg: ISvg
}

export const SvgImage: React.FC<IProps> = ({ svg, ...props }) => (
  <svg viewBox={svg.viewBox} {...props}>
    <use href={`#${svg.id}`} xlinkHref={`#${svg.id}`} />
  </svg>
)
