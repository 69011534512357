import { createProductId } from 'helpers/createProductId'

import { ISubscription } from 'models/subscriptions.model'

export const enum GoogleAnalyticsAction {
  EVENT = 'event',
}

export const enum GoogleAnalyticsEvent {
  BEGIN_CHECKOUT = 'begin_checkout',
  ADD_TO_CART = 'add_to_cart',
  PURCHASE = 'purchase',
  PURCHASE_TEST = 'purchase_test',
  FIRST_PAGE_COMPLETED = 'first_page_completed',
  CONVERSION = 'conversion',
}

export const enum GoogleAnalyticsEventCategory {
  TRANSITION = 'transition',
}

class GoogleAnalyticsService {
  private googleAdsConversions: Record<string, string> = {}

  configure(googleAdsConversions: Record<string, string>): void {
    this.googleAdsConversions = googleAdsConversions
  }

  logCheckoutStarted(subscriptions: ISubscription[]): void {
    if (!window.gtag) return

    window.gtag(
      GoogleAnalyticsAction.EVENT,
      GoogleAnalyticsEvent.BEGIN_CHECKOUT,
      {
        items: subscriptions.map(
          ({ mainPrices: { periodName, periodQuantity, fullPrice } }) => ({
            id: createProductId({
              periodName,
              periodQuantity,
              price: fullPrice,
            }),
            price: fullPrice,
          }),
        ),
      },
    )
  }

  logAddingToCart({
    mainPrices: { periodName, periodQuantity, fullPrice },
  }: ISubscription): void {
    if (!window.gtag) return

    window.gtag(GoogleAnalyticsAction.EVENT, GoogleAnalyticsEvent.ADD_TO_CART, {
      items: [
        {
          id: createProductId({
            periodName,
            periodQuantity,
            price: fullPrice,
          }),
          price: fullPrice,
        },
      ],
    })

    window.gtag(GoogleAnalyticsAction.EVENT, GoogleAnalyticsEvent.CONVERSION, {
      send_to: this.googleAdsConversions[GoogleAnalyticsEvent.ADD_TO_CART],
    })
  }

  logPurchaseCompleted({
    subscriptionId,
    price,
    productId,
    currency = 'USD',
  }: {
    subscriptionId: string
    price: number
    productId: string
    currency?: string
  }): void {
    if (!window.gtag) return

    window.gtag(GoogleAnalyticsAction.EVENT, GoogleAnalyticsEvent.PURCHASE, {
      transaction_id: subscriptionId,
      value: price,
      currency,
      items: [
        {
          id: productId,
          price,
        },
      ],
    })

    window.gtag(GoogleAnalyticsAction.EVENT, GoogleAnalyticsEvent.CONVERSION, {
      send_to: this.googleAdsConversions[GoogleAnalyticsEvent.PURCHASE],
      transaction_id: subscriptionId,
    })

    window.gtag(GoogleAnalyticsAction.EVENT, GoogleAnalyticsEvent.CONVERSION, {
      send_to: this.googleAdsConversions[GoogleAnalyticsEvent.PURCHASE_TEST],
      transaction_id: subscriptionId,
      value: price,
      currency,
    })
  }

  logFirstPageCompleted(): void {
    if (!window.gtag) return

    window.gtag(
      GoogleAnalyticsAction.EVENT,
      GoogleAnalyticsEvent.FIRST_PAGE_COMPLETED,
      {
        event_category: GoogleAnalyticsEventCategory.TRANSITION,
      },
    )

    window.gtag(GoogleAnalyticsAction.EVENT, GoogleAnalyticsEvent.CONVERSION, {
      send_to:
        this.googleAdsConversions[GoogleAnalyticsEvent.FIRST_PAGE_COMPLETED],
    })
  }
}

export const googleAnalyticsLogger = new GoogleAnalyticsService()
