import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import { useNextStep } from 'hooks/common/useNextStep'
import { useCounter } from 'hooks/ui/useCounter'

import { TPageProps } from 'models/common.model'

import { StyledIntroVariant10 as S } from './IntroVariant10.styles'

export const IntroVariant10: React.FC<TPageProps> = ({
  pageId,
  nextPagePath,
}) => {
  const { t } = useTranslation()
  const { counter, isFinished } = useCounter(true, {
    duration: 4000,
  })
  const handleContinue = useNextStep({
    pageId,
    pageName: pageId,
    question: '',
    nextPagePath,
  })

  useEffect(() => {
    if (!isFinished) return
    handleContinue('')
  }, [handleContinue, isFinished])

  return (
    <S.Wrapper>
      <S.BurgerMenu />
      <S.Content>
        <S.Title>{t('onboarding.intro10.title')}</S.Title>
        <S.Subtitle>{t('onboarding.intro10.subtitle')}</S.Subtitle>
        <S.ProgressBlock>
          <S.ProgressBarContainer>
            <S.ProgressBar max={100} value={counter} />
          </S.ProgressBarContainer>
          <S.ProgressBarLabel>
            {t('onboarding.intro10.progressBarLabel')}
          </S.ProgressBarLabel>
        </S.ProgressBlock>
        <S.PoliciesGroup />
      </S.Content>
    </S.Wrapper>
  )
}
