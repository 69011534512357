import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { StyledDisclaimer as S } from './Disclaimer.styles'

export const Disclaimer: React.FC = () => {
  const { t } = useTranslation()
  const [isShown, setIsShown] = useState(false)

  const handleClick = () => {
    setIsShown((prevState) => !prevState)
  }

  return (
    <S.Wrapper>
      <S.Title>{t`onboarding.introGoogle5.geolocationBenefits.disclaimer.disclaimer`}</S.Title>
      <S.DesktopText>{t`onboarding.introGoogle5.geolocationBenefits.disclaimer.fullText`}</S.DesktopText>
      <S.MobileWrapper>
        <S.Text>
          {isShown
            ? t`onboarding.introGoogle5.geolocationBenefits.disclaimer.fullText`
            : t`onboarding.introGoogle5.geolocationBenefits.disclaimer.partialText`}
          <S.SwitcherText isShown={isShown} onClick={handleClick}>
            {isShown
              ? t`onboarding.introGoogle5.geolocationBenefits.disclaimer.showLess`
              : t`onboarding.introGoogle5.geolocationBenefits.disclaimer.showMore`}
          </S.SwitcherText>
        </S.Text>
      </S.MobileWrapper>
    </S.Wrapper>
  )
}
