import styled from 'styled-components'

import { Color } from 'root-constants/common'

type TImageProps = {
  gridArea: string
}

export const StyledAppStatistics = {
  Wrapper: styled.div`
    margin-bottom: 48px;
  `,
  Tracker: styled.div`
    max-width: 231px;
    display: grid;
    grid-template-columns: 46px 1fr 46px;
    grid-template-rows: auto;
    grid-template-areas:
      'leftLeaf title rightLeaf'
      'leftLeaf subtitle rightLeaf';
    grid-gap: 8px;
    justify-content: center;
    align-items: center;
    margin: 0 auto 48px;
  `,
  TrackerTitle: styled.span`
    grid-area: title;
    font-size: 20px;
    font-weight: 800;
    line-height: 22px;
    letter-spacing: 0.5px;
    text-align: center;

    strong {
      font-size: 32px;
      font-weight: 800;
      line-height: 32px;
      letter-spacing: 0.5px;
    }
  `,
  TrackerSubtitle: styled.span`
    grid-area: subtitle;
    text-align: center;
    font-size: 14px;
    font-weight: 500;
    line-height: 19px;
  `,
  TrackerImage: styled.img<TImageProps>`
    grid-area: ${({ gridArea }) => gridArea};
  `,
  StatisticsContainer: styled.div`
    display: flex;
    gap: 9px;
  `,
  StatisticsCard: styled.div`
    min-width: 103px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding: 16px 12px;
    border-radius: 16px;
    background-color: ${Color.GREY};
  `,
  StatisticsImage: styled.img`
    height: 28px;
    margin-bottom: 14px;
  `,
  StatisticsTitle: styled.span`
    margin-bottom: 4px;
    text-align: center;
    font-size: 20px;
    font-weight: 800;
    line-height: 27px;
  `,
  StatisticsSubtitle: styled.span`
    text-align: center;
    font-size: 13px;
    font-weight: 500;
    line-height: 18px;
  `,
}
