import { PageId } from 'root-constants/pages'

export const PAGE_NAME = 'Concerns about safety'

export const OPTION_VALUES = {
  HURT: 'will_be_hurt',
  DANGEROUS_PLACE: 'dangerous_place',
  HIT_BY_CAR: 'hit_by_car',
  KIDNAPPED: 'will_be_kidnapped',
  LOST: 'get_lost',
  DRUGS_ALCOHOL: 'drugs_alcohol',
  BAD_COMPANY: 'bad_company',
  OTHER: 'other',
}

export const PAGE_OPTION_VALUES = {
  [PageId.CONCERNS_2]: [
    OPTION_VALUES.HURT,
    OPTION_VALUES.DANGEROUS_PLACE,
    OPTION_VALUES.HIT_BY_CAR,
    OPTION_VALUES.KIDNAPPED,
    OPTION_VALUES.LOST,
    OPTION_VALUES.DRUGS_ALCOHOL,
    OPTION_VALUES.BAD_COMPANY,
    OPTION_VALUES.OTHER,
  ],
}
