import React, { useEffect, useRef } from 'react'
import { Trans, useTranslation } from 'react-i18next'

import lottie from 'lottie-web/build/player/lottie_light'

import { useNextStep } from 'hooks/common/useNextStep'
import { useCounter } from 'hooks/ui/useCounter'

import { TPageProps } from 'models/common.model'

import personalGiftAnimation from 'assets/animations/personal-gift-animation.json'

import { StyledPersonalGiftVariant1 as S } from './PersonalGiftVariant1.styles'
import { PERSONAL_GIFT_PROGRESS_DURATION } from './constants'

export const PersonalGiftVariant1: React.FC<TPageProps> = ({
  pageId,
  nextPagePath,
}) => {
  const { t } = useTranslation()
  const giftAnimation = useRef<HTMLDivElement>(null)

  const handleContinue = useNextStep({
    pageId,
    pageName: pageId,
    question: '',
    nextPagePath,
  })

  const { linearCounterValue, counter } = useCounter(true, {
    duration: PERSONAL_GIFT_PROGRESS_DURATION,
    callback: handleContinue,
  })

  useEffect(() => {
    if (giftAnimation.current) {
      lottie.loadAnimation({
        name: 'giftAnimation',
        container: giftAnimation.current,
        animationData: personalGiftAnimation,
        loop: false,
      })
    }

    return () => lottie.destroy('giftAnimation')
  }, [])

  return (
    <S.Wrapper>
      <S.Content>
        <S.Title>{t`onboarding.personalGift.title`}</S.Title>
        <S.AnimationContainer ref={giftAnimation} />
        <S.Subtitle>
          <Trans i18nKey="onboarding.personalGift.promo" />
        </S.Subtitle>
        <S.ProgressBar max={100} value={counter}>
          {linearCounterValue}
        </S.ProgressBar>
      </S.Content>
    </S.Wrapper>
  )
}
