import styled, { css } from 'styled-components'

import periodBgImage from 'assets/images/price-bg.svg'
import checkIcon from 'assets/images/success.svg'

import { Color, MediaBreakpoint } from 'root-constants/common'

const activePlanItemStyles = css`
  border-color: #7879f1;
  background-color: transparent;

  ::before {
    background: center url(${checkIcon}) no-repeat;
    background-size: 41px;
  }

  &[data-has-laptop-styles='true'] {
    @media (min-width: ${MediaBreakpoint.LAPTOP}px) {
      background-color: #fff;
    }
  }
`

const defaultPlanItemStyles = css`
  position: relative;

  &::after {
    content: attr(data-text);
    display: block;
    padding: 0 16px;
    position: absolute;
    top: -15px;
    left: 50%;
    transform: translateX(-50%);
    height: 26px;
    font-size: 10px;
    line-height: 26px;
    font-weight: 800;
    text-transform: uppercase;
    color: #ffffff;
    background-color: #4946d2;
    border-radius: 13px;
  }
`

const PlanItem = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 24px 16px 24px 56px;
  border-radius: 16px;
  border: 2px solid transparent;
  background-color: #eff5f7;
  transition: all 0.2s ease-out;

  &[data-is-default='true'] {
    ${defaultPlanItemStyles};
  }

  ::before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 16px;
    width: 24px;
    height: 24px;
    margin: auto;
    border: 2px solid #ccdce5;
    border-radius: 100%;
  }

  &[data-is-selected='true']::before {
    border-color: #7bc27e;
  }

  &[data-has-laptop-styles='true'] {
    @media (min-width: ${MediaBreakpoint.LAPTOP}px) {
      background-color: #fff;
    }
  }
`

export const StyledPlanItemVariant1 = {
  PlanItem: styled(PlanItem)`
    &[data-is-selected='true'] {
      ${activePlanItemStyles};
    }

    &[data-is-es-lang='true'] {
      padding: 24px 10px 24px 48px;
    }
  `,
  PlanTitle: styled.div`
    flex-grow: 1;
    font-size: 16px;
    font-weight: 800;
    line-height: 22px;
    text-transform: capitalize;

    &[data-is-es-lang='true'] {
      text-transform: none;
    }
  `,
  OldPlanPrice: styled.div`
    margin-right: 6px;
    font-size: 16px;
    line-height: 22px;
    font-weight: 700;
    text-decoration: line-through;
    color: ${Color.LIGHT_TEXT};
    opacity: 0.5;

    [data-is-selected='true'] & {
      color: #fe5959;
    }
  `,
  PlanPrice: styled.div`
    position: relative;
    flex-shrink: 0;
    width: 90px;
    height: 40px;
    color: #ffffff;
    background: top right url(${periodBgImage}) no-repeat;

    > strong {
      position: absolute;
      left: 26px;
      bottom: 2px;
      font-size: 32px;
      font-weight: 800;
      line-height: 36px;

      &[data-is-es-lang='true'] {
        left: 18px;
      }

      ::before {
        content: attr(data-currency);
        display: block;
        position: absolute;
        left: -8px;
        top: 6px;
        font-size: 12px;
        font-weight: 800;
        line-height: 12px;
      }

      &[data-is-es-lang='true']::before {
        left: 40px;
      }

      ::after {
        content: attr(data-fractional);
        display: block;
        position: absolute;
        right: -16px;
        top: 6px;
        font-size: 12px;
        font-weight: 800;
        line-height: 12px;
      }
    }

    > sub {
      position: absolute;
      right: 5px;
      bottom: 7px;
      font-size: 9px;
      font-weight: 500;
      line-height: 11px;
    }
  `,
}
