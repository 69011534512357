import styled from 'styled-components'

import { Color } from 'root-constants/common'

type TIndicatorProps = {
  gridArea: string
  backgroundColor: string
  hasShadow: boolean
}

type TColonProps = {
  gridArea: string
}

type TTextProps = TColonProps

type TWrapperProps = {
  marginBottom?: number
}

export const StyledTimerVariant2 = {
  Wrapper: styled.div<TWrapperProps>`
    display: grid;
    grid-template-columns: 48px 6px 48px 6px 48px;
    grid-template-rows: auto;
    grid-template-areas:
      'hours leftColon minutes rightColon seconds'
      'hoursText . minutesText . secondsText';
    align-items: center;
    grid-gap: 4px 8px;
    margin-bottom: ${({ marginBottom }) => marginBottom || 0}px;
  `,
  Indicator: styled.p<TIndicatorProps>`
    grid-area: ${({ gridArea }) => gridArea};
    padding: 12px 10px;
    width: 48px;
    height: 48px;
    color: ${Color.BLUE};
    text-align: center;
    font-size: 24px;
    font-weight: 800;
    line-height: 24px;
    border-radius: 8px;
    background-color: ${({ backgroundColor }) => backgroundColor};
    box-shadow: ${({ hasShadow }) =>
      hasShadow ? '0 3px 10px 0 rgba(178, 176, 202, 0.25)' : 'none'};
  `,
  Colon: styled.p<TColonProps>`
    grid-area: ${({ gridArea }) => gridArea};
    text-align: center;
    font-size: 24px;
    font-weight: 700;
    line-height: 24px;
  `,
  Text: styled.p<TTextProps>`
    grid-area: ${({ gridArea }) => gridArea};
    text-align: center;
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
  `,
}
