import styled from 'styled-components'

import { StripePaymentForm } from 'modules/payment/components/StripePaymentForm'

import { Color, MediaBreakpoint } from 'root-constants/common'

type TTextProps = {
  gridArea: string
  justifySelf?: string
}

type TPriceContainerProps = {
  gridTemplateColumns?: string
}

export const StyledPaymentFlowsGoogleVariant3 = {
  Wrapper: styled.div``,
  PriceContainer: styled.div<TPriceContainerProps>`
    display: grid;
    grid-template-columns: ${({ gridTemplateColumns }) =>
      gridTemplateColumns || '1fr 70px'};
    grid-template-rows: auto;
    grid-template-areas:
      'priceDescription oldPriceValue'
      'discountText discountValue'
      'divider divider'
      'totalText totalValue'
      '. trialText';
    margin-top: 24px;
  `,
  Text: styled.p<TTextProps>`
    grid-area: ${({ gridArea }) => gridArea};
    justify-self: ${({ justifySelf }) => justifySelf || 'flex-start'};
    margin-bottom: 16px;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
  `,
  DiscountText: styled.p<TTextProps>`
    grid-area: ${({ gridArea }) => gridArea};
    justify-self: ${({ justifySelf }) => justifySelf || 'flex-start'};
    margin-bottom: 12px;
    color: #fc5774;
    font-size: 14px;
    font-weight: 700;
    line-height: 20px;
  `,
  Divider: styled.div`
    grid-area: divider;
    width: 100%;
    height: 1px;
    margin-bottom: 16px;
    background-color: #bcc9d0;
  `,
  TotalText: styled.p<TTextProps>`
    grid-area: ${({ gridArea }) => gridArea};
    justify-self: ${({ justifySelf }) => justifySelf || 'flex-start'};
    color: #4c8eff;
    text-align: center;
    font-size: 24px;
    font-weight: 700;
    line-height: 28px;
  `,
  Disclaimer: styled.p`
    margin-bottom: 16px;
    font-size: 11px;
    font-weight: 400;
    line-height: 18px;
    color: ${Color.LIGHT_TEXT};
  `,
  StripePaymentForm: styled(StripePaymentForm)`
    @media (min-width: ${MediaBreakpoint.LAPTOP}px) {
      width: initial;
      margin-bottom: 25px;
    }
  `,
  Image: styled.img`
    max-width: 256px;
    margin: 40px auto 16px;
  `,
  Guarantee: styled.p`
    font-size: 13px;
    line-height: 20px;
    text-align: center;
    opacity: 0.7;
    margin-bottom: 48px;
  `,
  BottomDisclaimer: styled.p`
    font-size: 11px;
    line-height: 18px;
    text-align: center;
    color: ${Color.LIGHT_TEXT};
    margin-bottom: 44px;

    a {
      color: inherit;
      text-decoration: underline;
    }
  `,
}
