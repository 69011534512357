import React from 'react'
import { useTranslation } from 'react-i18next'

import { usePurchaseStore } from 'hooks/usePurchaseStore'

import { PriceValue } from 'components/PriceValue'

import { Language, PERIOD_NAME_TO_MARKUP_MAP } from 'root-constants/common'

import { StyledPlanItemVariant1 as S } from './PlanItem.styles'

export const PlanItemVariant1: React.FC = () => {
  const { t } = useTranslation()

  const {
    currentPrice,
    oldPrice,
    currency,
    periodQuantity,
    periodName,
    language,
  } = usePurchaseStore()

  return (
    <S.PlanItem>
      <S.Column>
        <S.PlanTitle>
          {t('subscriptions.planBlock.planTitle2', {
            periodQuantity,
            periodName: t(PERIOD_NAME_TO_MARKUP_MAP[periodName], {
              count: periodQuantity,
            }),
          })}
        </S.PlanTitle>
        <S.Discount
          maxWidth={language === Language.EN ? 75 : 100}
          lineHeight={language === Language.EN ? 22 : 16}
        >
          {t('payment.variant5.discountBlock.discount', { discount: 50 })}
        </S.Discount>
      </S.Column>
      <S.Column leftAlignment>
        <S.CurrentPrice>
          <PriceValue value={currentPrice} currency={currency} />
        </S.CurrentPrice>
        <S.OldPrice>
          <PriceValue value={oldPrice.fullPrice} currency={currency} />
        </S.OldPrice>
      </S.Column>
    </S.PlanItem>
  )
}
