import styled from 'styled-components'

import { Color } from 'root-constants/common'

export const StyledQRCode = {
  Wrapper: styled.div`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 20px;
    background-color: ${Color.WHITE};
    z-index: 1;
  `,
}
