import styled from 'styled-components'

import { MediaBreakpoint } from 'root-constants/common'

export const StyledVatTotalAmount = {
  Wrapper: styled.div`
    width: 100%;
    margin-bottom: 16px;
    color: ${({ theme }) => theme.colors.secondaryText};
    text-align: center;
    font-size: 13px;
    line-height: 20px;

    @media (min-width: ${MediaBreakpoint.LAPTOP}px) {
      font-size: 18px;
      line-height: 27px;
    }
  `,
}
