import React, { useRef } from 'react'
import { useTranslation } from 'react-i18next'

import { useTimerForTarget } from 'modules/subscriptions/hooks/useTimerForTarget'

import { Color } from 'root-constants/common'

import { StyledTimerVariant2 as S } from './TimerVariant2.styles'

const TEN_HOURS_IN_MILLISECONDS = 36001

type TProps = {
  backgroundColor?: string
  hasShadow?: boolean
  marginBottom?: number
}

export const TimerVariant2: React.FC<TProps> = ({
  backgroundColor = Color.WHITE,
  hasShadow = true,
  marginBottom,
}) => {
  const { t } = useTranslation()
  const timerElementRef = useRef<HTMLDivElement>(null)

  const { hours, minutes, seconds } = useTimerForTarget(
    timerElementRef,
    TEN_HOURS_IN_MILLISECONDS,
  )

  return (
    <S.Wrapper ref={timerElementRef} marginBottom={marginBottom}>
      <S.Indicator
        gridArea="hours"
        backgroundColor={backgroundColor}
        hasShadow={hasShadow}
      >
        {hours}
      </S.Indicator>
      <S.Colon gridArea="leftColon">:</S.Colon>
      <S.Indicator
        gridArea="minutes"
        backgroundColor={backgroundColor}
        hasShadow={hasShadow}
      >
        {minutes}
      </S.Indicator>
      <S.Colon gridArea="rightColon">:</S.Colon>
      <S.Indicator
        gridArea="seconds"
        backgroundColor={backgroundColor}
        hasShadow={hasShadow}
      >
        {seconds}
      </S.Indicator>

      <S.Text gridArea="hoursText">{t`subscriptions.subscriptionsGoogle.variant4.hours`}</S.Text>
      <S.Text gridArea="minutesText">{t`subscriptions.subscriptionsGoogle.variant4.minutes`}</S.Text>
      <S.Text gridArea="secondsText">{t`subscriptions.subscriptionsGoogle.variant4.seconds`}</S.Text>
    </S.Wrapper>
  )
}
