import reviewAvatar1 from 'assets/images/review-avatar-1.jpg'
import reviewAvatar2 from 'assets/images/review-avatar-2.jpg'
import reviewAvatar3 from 'assets/images/review-avatar-3.jpg'
import reviewAvatar4 from 'assets/images/review-avatar-4.webp'
import reviewAvatar6 from 'assets/images/review-avatar-6.webp'
import reviewAvatar7 from 'assets/images/review-avatar-7.webp'
import reviewAvatar8 from 'assets/images/review-avatar-8.webp'
import reviewAvatar9 from 'assets/images/review-avatar-9.webp'
import reviewAvatar10 from 'assets/images/review-avatar-10.jpg'

export const REVIEWS_DATA = {
  default: [
    {
      avatar: reviewAvatar1,
      userName: 'subscriptions.reviewsSlider.lina',
      reviewText: 'subscriptions.reviewsSlider.text1',
      borderColor: '#EED0E4',
    },
    {
      avatar: reviewAvatar2,
      userName: 'subscriptions.reviewsSlider.david',
      reviewText: 'subscriptions.reviewsSlider.text2',
      borderColor: '#D9D8F6',
    },
    {
      avatar: reviewAvatar3,
      userName: 'subscriptions.reviewsSlider.kaung',
      reviewText: 'subscriptions.reviewsSlider.text3',
      borderColor: '#F0DACE',
    },
  ],
  kidGoalReviews: [
    {
      avatar: reviewAvatar4,
      userName: 'subscriptions.reviewsSlider.suzie',
      reviewText: 'subscriptions.reviewsSlider.text4',
      borderColor: '#EED0E4',
    },
    {
      avatar: reviewAvatar8,
      userName: 'subscriptions.reviewsSlider.maria',
      reviewText: 'subscriptions.reviewsSlider.text5',
      borderColor: '#D9D8F6',
    },
    {
      avatar: reviewAvatar6,
      userName: 'subscriptions.reviewsSlider.david',
      reviewText: 'subscriptions.reviewsSlider.text6',
    },
  ],
  friendGoalReviews: [
    {
      avatar: reviewAvatar7,
      userName: 'subscriptions.reviewsSlider.suzie',
      reviewText: 'subscriptions.reviewsSlider.text7',
      borderColor: '#EED0E4',
    },
    {
      avatar: reviewAvatar8,
      userName: 'subscriptions.reviewsSlider.maria',
      reviewText: 'subscriptions.reviewsSlider.text8',
      borderColor: '#D9D8F6',
    },
    {
      avatar: reviewAvatar9,
      userName: 'subscriptions.reviewsSlider.mark',
      reviewText: 'subscriptions.reviewsSlider.text9',
      borderColor: '#F0DACE',
    },
  ],
  severalGoalsReviews: [
    {
      avatar: reviewAvatar7,
      userName: 'subscriptions.reviewsSlider.suzie',
      reviewText: 'subscriptions.reviewsSlider.text4',
      borderColor: '#EED0E4',
    },
    {
      avatar: reviewAvatar8,
      userName: 'subscriptions.reviewsSlider.maria',
      reviewText: 'subscriptions.reviewsSlider.text8',
      borderColor: '#D9D8F6',
    },
    {
      avatar: reviewAvatar9,
      userName: 'subscriptions.reviewsSlider.mark',
      reviewText: 'subscriptions.reviewsSlider.text9',
      borderColor: '#F0DACE',
    },
  ],
  teenGoalReviews: [
    {
      avatar: reviewAvatar4,
      userName: 'subscriptions.reviewsSlider.suzie',
      reviewText: 'subscriptions.reviewsSlider.text4',
      borderColor: '#EED0E4',
    },
    {
      avatar: reviewAvatar8,
      userName: 'subscriptions.reviewsSlider.maria',
      reviewText: 'subscriptions.reviewsSlider.text5',
      borderColor: '#D9D8F6',
    },
    {
      avatar: reviewAvatar6,
      userName: 'subscriptions.reviewsSlider.david',
      reviewText: 'subscriptions.reviewsSlider.text17',
      borderColor: '#F0DACE',
    },
  ],
}

export const REVIEWS_DATA_SLIDER_VARIANT_2 = [
  {
    avatar: reviewAvatar1,
    userName: 'subscriptions.reviewsSlider.lina',
    reviewText: 'subscriptions.reviewsSlider.text10',
    borderColor: '#EED0E4',
  },
  {
    avatar: reviewAvatar2,
    userName: 'subscriptions.reviewsSlider.david',
    reviewText: 'subscriptions.reviewsSlider.text11',
    borderColor: '#D9D8F6',
  },
  {
    avatar: reviewAvatar3,
    userName: 'subscriptions.reviewsSlider.kaung',
    reviewText: 'subscriptions.reviewsSlider.text12',
    borderColor: '#F0DACE',
  },
  {
    avatar: reviewAvatar10,
    userName: 'subscriptions.reviewsSlider.savannah',
    reviewText: 'subscriptions.reviewsSlider.text13',
    borderColor: '#EED0E4',
  },
]

export const REVIEWS_DATA_SLIDER_VARIANT_3 = [
  {
    avatar: reviewAvatar4,
    userName: 'subscriptions.reviewsSlider.anna',
    reviewText: 'subscriptions.reviewsSlider.text20',
    borderColor: '#F0DACE',
  },
  {
    avatar: reviewAvatar1,
    userName: 'subscriptions.reviewsSlider.samantha',
    reviewText: 'subscriptions.reviewsSlider.text18',
    borderColor: '#EED0E4',
  },
  {
    avatar: reviewAvatar2,
    userName: 'subscriptions.reviewsSlider.john',
    reviewText: 'subscriptions.reviewsSlider.text19',
    borderColor: '#D9D8F6',
  },
]

export const REVIEWS_DATA_SLIDER_VARIANT_4 = [
  {
    avatar: reviewAvatar4,
    userName: 'subscriptions.reviewsSlider.anna',
    reviewText: 'subscriptions.reviewsSlider.text21',
    borderColor: '#F0DACE',
  },
  {
    avatar: reviewAvatar1,
    userName: 'subscriptions.reviewsSlider.samantha',
    reviewText: 'subscriptions.reviewsSlider.text22',
    borderColor: '#EED0E4',
  },
  {
    avatar: reviewAvatar2,
    userName: 'subscriptions.reviewsSlider.john',
    reviewText: 'subscriptions.reviewsSlider.text23',
    borderColor: '#D9D8F6',
  },
]

export const BORDER_COLORS = ['#eed0e4', '#d9d8f6', '#f0dace']
