import React from 'react'
import { Trans, useTranslation } from 'react-i18next'

import { usePurchaseStore } from 'hooks/usePurchaseStore'

import { PaymentRequestButton } from 'modules/payment/components/PaymentRequestButton'
import { COHORTS_WITH_DISCLAIMER } from 'modules/payment/pages/PaymentVariant3/constants'

import { PhoneNumberLabel } from 'components/PhoneNumberLabel'
import { PriceValue } from 'components/PriceValue'
import { TermsOfUseLink } from 'components/TermsOfUseLink'

import paymentServicesImage from 'assets/images/payment-services.png'

import {
  CURRENCY_SYMBOLS,
  Cohort,
  TRIAL_IN_HOURS,
  TimeInterval,
  TrialPeriod,
} from 'root-constants/common'

import { StyledPaymentFlowsGoogleVariant2 as S } from './PaymentFlowsGoogleVariant2.styles'

export const PaymentFlowsGoogleVariant2: React.FC = () => {
  const { t } = useTranslation()

  const {
    cohortToUse,
    selectedSubscriptionId,
    currentPrice,
    currency,
    trialPrice,
    trialPeriodDays,
  } = usePurchaseStore()

  const hasDisclaimer = COHORTS_WITH_DISCLAIMER.includes(cohortToUse)

  const textParameters =
    trialPeriodDays >= TrialPeriod.THREE_DAYS
      ? {
          period: trialPeriodDays,
          context: TimeInterval.DAY,
        }
      : {
          period: TRIAL_IN_HOURS[trialPeriodDays],
        }

  const isSixteenCohort = cohortToUse === Cohort.GOOGLE_16

  return (
    <S.Wrapper>
      {!isSixteenCohort && <PhoneNumberLabel />}
      <S.PriceContainer>
        <S.PriceText gridArea="priceText">{t`payment.googlePayment`}</S.PriceText>
        <S.PriceValue gridArea="priceValue">
          <PriceValue value={trialPrice} currency={currency} />
        </S.PriceValue>
        <S.TrialDurationText>
          {t('payment.trial', textParameters)}
        </S.TrialDurationText>
      </S.PriceContainer>

      {hasDisclaimer && (
        <S.Disclaimer>
          {t('payment.googleFlowDisclaimer', {
            period: TRIAL_IN_HOURS[trialPeriodDays],
            trialPeriodDays,
            currencySymbol: CURRENCY_SYMBOLS[currency],
            price: currentPrice,
            minimumFractionDigits: 2,
            context:
              trialPeriodDays > TrialPeriod.THREE_DAYS
                ? TimeInterval.WEEK
                : TimeInterval.DAY,
          })}
        </S.Disclaimer>
      )}

      <S.StripePaymentForm />
      <PaymentRequestButton isDividerVisible key={selectedSubscriptionId} />
      <S.Image
        src={paymentServicesImage}
        decoding="async"
        alt="payments services"
      />
      <S.Guarantee>{t`subscriptions.guarantee.title`}</S.Guarantee>
      <S.BottomDisclaimer>
        <Trans
          i18nKey="payment.descriptionWithLink"
          components={{ a: <TermsOfUseLink /> }}
        />
      </S.BottomDisclaimer>
    </S.Wrapper>
  )
}
