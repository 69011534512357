import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { useDelayedContinue } from 'hooks/useDelayedContinue'
import { useNextStep } from 'hooks/useNextStep'
import { useUserData } from 'hooks/useUserData'

import { TPageProps } from 'models/common.model'

import { DesktopProvider } from 'components/DesktopProvider'
import { Option, OptionType } from 'components/Option'
import { StyledOption } from 'components/Option/Option.styles'
import { QuestionButton } from 'components/QuestionButton'

import daysDisclosureImage2 from 'assets/images/days-disclosure-2.jpg'
import daysDisclosureImage from 'assets/images/days-disclosure.jpg'

import { Frequency, Language } from 'root-constants/common'

import { StyledDaysDisclosure as S } from './DaysDisclosure.styles'
import { PAGE_NAME } from './constants'

export const DaysDisclosureVariant1: React.FC<TPageProps> = ({
  pageId,
  nextPagePath,
}) => {
  const { t } = useTranslation()

  const { hasConcernsAboutPartnerSafety } = useUserData()

  const { isAnswersDisabled, handleChange, setIsAnswersDisabled } =
    useDelayedContinue()

  const handleContinue = useNextStep({
    pageId,
    pageName: PAGE_NAME,
    question: t('onboarding.daysDisclosure.question', { lng: Language.EN }),
    nextPagePath,
  })

  const optionProps = useMemo(
    () => ({
      name: pageId,
      type: OptionType.RADIO,
      onChange: (value: string) => {
        handleChange(value, handleContinue)
        setIsAnswersDisabled(true)
      },
    }),
    [pageId, handleChange, handleContinue, setIsAnswersDisabled],
  )

  return (
    <DesktopProvider
      imageSrc={
        hasConcernsAboutPartnerSafety
          ? daysDisclosureImage
          : daysDisclosureImage2
      }
    >
      <S.Wrapper>
        <S.Column>
          <S.Title>{t`onboarding.daysDisclosure.question`}</S.Title>
          <StyledOption.OptionsContainer>
            <Option
              {...optionProps}
              value={Frequency.QUITE_OFTEN}
              disabled={isAnswersDisabled}
            >
              <QuestionButton>{t`commonComponents.quiteOften`}</QuestionButton>
            </Option>
            <Option
              {...optionProps}
              value={Frequency.SOMETIMES}
              disabled={isAnswersDisabled}
            >
              <QuestionButton>{t`commonComponents.sometimes`}</QuestionButton>
            </Option>
            <Option
              {...optionProps}
              value={Frequency.RARELY}
              disabled={isAnswersDisabled}
            >
              <QuestionButton>{t`commonComponents.rarely`}</QuestionButton>
            </Option>
            <Option
              {...optionProps}
              value={Frequency.NEVER}
              disabled={isAnswersDisabled}
            >
              <QuestionButton>{t`commonComponents.never`}</QuestionButton>
            </Option>
          </StyledOption.OptionsContainer>
        </S.Column>
      </S.Wrapper>
    </DesktopProvider>
  )
}
