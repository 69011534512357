import appStoreLogo from 'assets/images/app-store.svg'
import cnetLogo from 'assets/images/cnet-logo-icon.png'
import confidentialIcon from 'assets/images/confidential-icon.svg'
import googlePlayLogo from 'assets/images/google-play.svg'
import gpsTracking from 'assets/images/gps-tracking-icon.png'
import iotDevices from 'assets/images/iot-devices-icon.png'
import modernMl from 'assets/images/modern-ml-icon.png'
import newYorkLogo from 'assets/images/new-york-logo-icon.png'
import ratingStars from 'assets/images/rating-stars.png'
import secureIcon from 'assets/images/secure-icon.svg'
import theVergeLogo from 'assets/images/the-verge-logo-icon.png'

import { CDN_FOLDER_LINK, Images } from 'root-constants/common'
import { PageId } from 'root-constants/pages'

export const PAGE_NAME = 'First question'
export const QUESTIONS = {
  [PageId.INTRO_1]: 'Do you know where your partner is right now?',
  [PageId.INTRO_2]:
    'What is your relationship to the person you want to track?',
}

export const OPTION_VALUES = {
  PARTNER: 'partner',
  KID: 'kid',
  FRIEND: 'friend',
  OTHER: 'other',
}

export const INTRO_4_OPTION_DATA = [
  {
    optionValue: OPTION_VALUES.PARTNER,
    answerText: 'onboarding.intro.answers.partner',
    nextPagePath: PageId.PARTNERS_SAFETY_1,
  },
  {
    optionValue: OPTION_VALUES.KID,
    answerText: 'onboarding.intro.answers.kid',
    nextPagePath: PageId.CHILD_LOCATION_2,
  },
  {
    optionValue: OPTION_VALUES.FRIEND,
    answerText: 'onboarding.intro.answers.friend',
    nextPagePath: PageId.FRIENDS_SAFETY_1,
  },
  {
    optionValue: OPTION_VALUES.OTHER,
    answerText: 'onboarding.intro.answers.other',
    nextPagePath: PageId.FRIENDS_SAFETY_1,
  },
]

export const SECURE_DATA = [
  { img: confidentialIcon, text: 'onboarding.intro8.confidential' },
  { img: secureIcon, text: 'onboarding.intro8.secure' },
]
export const POSSIBILITIES_DATA = [
  {
    img: `${CDN_FOLDER_LINK}${Images.REAL_TIME_LOCATION}`,
    text: 'onboarding.intro8.possibilities.realTime',
  },
  {
    img: `${CDN_FOLDER_LINK}${Images.LOCATION_HISTORY_ROUNDED}`,
    text: 'onboarding.intro8.possibilities.locationHistory',
  },
  {
    img: `${CDN_FOLDER_LINK}${Images.PLACE_ALERTS}`,
    text: 'onboarding.intro8.possibilities.placeAlerts',
  },
  {
    img: `${CDN_FOLDER_LINK}${Images.AR_SEARCH}`,
    text: 'onboarding.intro8.possibilities.arSearch',
  },
  {
    img: `${CDN_FOLDER_LINK}${Images.SOS_BUTTON}`,
    text: 'onboarding.intro8.possibilities.sosButton',
  },
  {
    img: `${CDN_FOLDER_LINK}${Images.FALL_DETECTION}`,
    text: 'onboarding.intro8.possibilities.fallDetection',
  },
  {
    img: `${CDN_FOLDER_LINK}${Images.CRASH_SPEED_CONTROL}`,
    text: 'onboarding.intro8.possibilities.crashControl',
  },
  {
    img: `${CDN_FOLDER_LINK}${Images.WEARABLES}`,
    text: 'onboarding.intro8.possibilities.wearables',
  },
]
export const TECHNOLOGIES_DATA = [
  { img: gpsTracking, text: 'onboarding.intro8.technologies.gpsTracking' },
  { img: modernMl, text: 'onboarding.intro8.technologies.modernMl' },
  { img: iotDevices, text: 'onboarding.intro8.technologies.iotDevices' },
]
export const WRITE_ABOUT_US_DATA = [cnetLogo, newYorkLogo, theVergeLogo]
export const HOW_IT_WORKS_DATA = [
  {
    title: 'onboarding.intro8.howItWorks.verifyNumber',
    text: 'onboarding.intro8.howItWorks.enterNumber',
  },
  {
    title: 'onboarding.intro8.howItWorks.locationRequest',
    text: 'onboarding.intro8.howItWorks.locationSharing',
  },
  {
    title: 'onboarding.intro8.howItWorks.receiveLocation',
    text: 'onboarding.intro8.howItWorks.keepInTouch',
  },
]
export const RATING_DATA = [
  {
    logo: appStoreLogo,
    name: 'onboarding.intro8.rating.appStore',
    score: 'onboarding.intro8.rating.appStoreScore',
    ratingImg: ratingStars,
    rating: 'onboarding.intro8.rating.appStoreRating',
  },
  {
    logo: googlePlayLogo,
    name: 'onboarding.intro8.rating.googlePlay',
    score: 'onboarding.intro8.rating.googlePlayScore',
    ratingImg: ratingStars,
    rating: 'onboarding.intro8.rating.googlePlayRating',
  },
]
