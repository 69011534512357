import React, { useEffect, useMemo } from 'react'
import { Trans, useTranslation } from 'react-i18next'

import { useFeatureIsOn, useFeatureValue } from '@growthbook/growthbook-react'

import { useCookieConsentAnswer } from 'hooks/useCookieConsentAnswer'
import { useGetPrices } from 'hooks/useGetPrices'
import { usePaywall } from 'hooks/usePaywall'
import { usePurchaseStore } from 'hooks/usePurchaseStore'
import { useVatInfo } from 'hooks/useVatInfo'

import { ScreenName, eventLogger } from 'services/eventLogger.service'
import { googleAnalyticsLogger } from 'services/googleAnalytics.service'

import { DiscountWithTimer } from 'modules/payment/components/DiscountWithTimer'
import { Divider } from 'modules/payment/components/Divider'
import {
  PlanItemVariant2,
  PlanItemVariant3,
  PlanItemVariant4,
  PlanItemVariant5,
} from 'modules/payment/components/PlanItem'
import { SelectPlanBlockVariant1 } from 'modules/payment/components/SelectPlanBlock'
import { StripePaymentFormVariant2 } from 'modules/payment/components/StripePaymentForm'
import { Timer } from 'modules/payment/components/Timer'

import { DynamicDiscountBanner } from 'components/DynamicDiscountBanner'
import { Spinner } from 'components/Spinner'
import { StripePaymentProcessing } from 'components/StripePaymentProcessing'
import { SupportLink } from 'components/SupportLink'
import { TermsOfUseLink } from 'components/TermsOfUseLink'

import paymentServices from 'assets/images/payment-services.png'

import {
  CURRENCY_SYMBOLS,
  Cohort,
  GOOGLE_9_TEST_SEGMENTS,
  INTRO_OFFER_FULL_PERIOD_NAMES,
  SubscriptionTag,
  TRIAL_QUANTITY_TO_MARKUP_MAP,
} from 'root-constants/common'

import { StyledPaymentVariant5 as S } from './PaymentVariant5.styles'

export const PaymentVariant5: React.FC = () => {
  const { t } = useTranslation()

  const {
    email,
    cohort,
    uuid,
    subscriptions,
    selectedSubscriptionId,
    trialPrice,
    currentPrice,
    trialPeriodDays,
    currency,
    isPaymentFlowsShown,
    paymentRequestButtonType,
    stripeAccountName,
    stripeAccountId,
    threeDSecureIframeUrl,
    dynamicDiscount,
  } = usePurchaseStore()

  const { hasPrices, handleSelectSubscription } = usePaywall('')
  const hasIncludedVAT = useVatInfo()
  const { isPersonalDataAllowed } = useCookieConsentAnswer()

  const hasAlternativePrices = useFeatureIsOn('abtest_car_931_month')
  const alternativePlanItem = useFeatureValue(
    'abtest_car_1007_checkout',
    GOOGLE_9_TEST_SEGMENTS.A_SEGMENT,
  )

  const hasAlternativePlanItem =
    alternativePlanItem !== GOOGLE_9_TEST_SEGMENTS.A_SEGMENT

  const subscriptionTag = useMemo(() => {
    if (hasAlternativePrices || hasAlternativePlanItem) {
      return hasIncludedVAT
        ? SubscriptionTag.TAX_NEW_PRICE
        : SubscriptionTag.NO_TAX_NEW_PRICE
    }

    return hasIncludedVAT ? SubscriptionTag.TAX : SubscriptionTag.NO_TAX
  }, [hasAlternativePlanItem, hasAlternativePrices, hasIncludedVAT])

  const PlanItem = useMemo(() => {
    if (cohort === Cohort.GOOGLE_CHECKOUT) return PlanItemVariant5
    if (hasAlternativePlanItem) return PlanItemVariant4
    if (dynamicDiscount) return PlanItemVariant3

    return PlanItemVariant2
  }, [cohort, dynamicDiscount, hasAlternativePlanItem])

  useGetPrices({
    tags: subscriptionTag,
  })

  useEffect(() => {
    if (!subscriptions.length) return

    window.fbq('track', 'InitiateCheckout', {}, { eventID: uuid })
    googleAnalyticsLogger.logCheckoutStarted(subscriptions)
  }, [subscriptions, uuid])

  useEffect(() => {
    eventLogger.logPurchaseShown({
      screenName: ScreenName.ONBOARDING,
      stripeAccountName,
      stripeAccountId,
    })
  }, [stripeAccountId, stripeAccountName])

  const sendEvents = () => {
    window.ttq.identify({ email: isPersonalDataAllowed ? email : '' })
    window.ttq.track('AddToCart')
  }

  return (
    <S.Wrapper>
      {threeDSecureIframeUrl ? (
        <S.ThreeDSecureIframe title="3DSecure" src={threeDSecureIframeUrl} />
      ) : (
        <>
          <DynamicDiscountBanner />

          <S.Content hasLighterBg={hasAlternativePlanItem}>
            <S.Column>
              <S.Title>{t`payment.variant5.title`}</S.Title>

              {dynamicDiscount ? <DiscountWithTimer /> : <Timer />}

              <SelectPlanBlockVariant1
                onSelect={handleSelectSubscription}
                SelectPlanItem={PlanItem}
              />
              <S.BenefitsContainer>
                <S.BenefitsTitle>{t`onboarding.intro8.youGet`}</S.BenefitsTitle>
                {(
                  t('payment.variant5.benefits', {
                    returnObjects: true,
                  }) as string[]
                ).map((text) => (
                  <S.BenefitsText key={text}>{text}</S.BenefitsText>
                ))}
              </S.BenefitsContainer>
              <S.PaymentServiceImage
                width="256px"
                src={paymentServices}
                decoding="async"
                alt="payments services"
              />
              <S.GuaranteeText>{t`payment.guarantee`}</S.GuaranteeText>
              <S.PaymentRequestButton key={selectedSubscriptionId} />
              {!!paymentRequestButtonType && <Divider />}
              <StripePaymentFormVariant2
                onSendEvents={sendEvents}
                inputBorderColor="#cbc3e7"
              />
              <S.Guarantee />

              <S.Disclaimer>
                <Trans
                  i18nKey="payment.variant5.disclaimer"
                  values={{
                    price: trialPrice,
                    minimumFractionDigits: 2,
                    currencySymbol: CURRENCY_SYMBOLS[currency],
                    period: t(TRIAL_QUANTITY_TO_MARKUP_MAP[trialPeriodDays]),
                    periodName: t(
                      INTRO_OFFER_FULL_PERIOD_NAMES[trialPeriodDays],
                    ),
                    vatInfo: hasIncludedVAT
                      ? t('commonComponents.inclVat')
                      : '',
                    currentPrice: currentPrice.toFixed(2),
                    currency: currency.toUpperCase(),
                  }}
                  components={{
                    termsOfUse: <TermsOfUseLink />,
                    supportLink: <SupportLink />,
                  }}
                />
              </S.Disclaimer>
            </S.Column>
          </S.Content>
        </>
      )}
      <StripePaymentProcessing />
      {(!isPaymentFlowsShown || !hasPrices) && <Spinner />}
    </S.Wrapper>
  )
}
