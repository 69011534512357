import { PlatformOS } from 'root-constants/common'

export function getOperatingSystemVersion(): string {
  const { userAgent } = window.navigator

  const [operatingSystemVersion = PlatformOS.UNKNOWN] =
    /[^()]+(?=\))/.exec(userAgent) || []

  return operatingSystemVersion
}
