import drvingMode from 'assets/images/driving-mode.png'
import pathToUser from 'assets/images/path-to-user.png'

import { ProductKey } from 'root-constants/common'

export const PLAN_ITEM_TITLES = {
  [ProductKey.UPSELL_BUNDLE]: 'subscriptions.upsell2.allInBundle',
  [ProductKey.PATH_TO_USER]: 'subscriptions.upsell2.pathToUser',
  [ProductKey.DRIVING_MODE]: 'subscriptions.upsell2.drivingMode',
}
export const DESCRIPTION_DATA = {
  [ProductKey.PATH_TO_USER]: {
    img: pathToUser,
    text: 'subscriptions.upsell2.navigate',
  },
  [ProductKey.DRIVING_MODE]: {
    img: drvingMode,
    text: 'subscriptions.upsell2.detects',
  },
}
