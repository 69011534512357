import React, { useMemo } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { selectLanguage, selectTaxAmount } from 'root-redux/selects/common'

import { getPriceWithoutTaxes } from 'helpers/getPriceWithoutTaxes'

import { ISelectPlanItemProps } from 'models/common.model'

import { selectCurrency } from 'modules/payment/redux/selects'

import {
  CURRENCY_SYMBOLS,
  Language,
  PERIOD_NAME_TO_MARKUP_MAP,
} from 'root-constants/common'

import { StyledPlanItemVariant1 as S } from './PlanItemVariant1.styles'

export const VatPlanItemVariant1: React.FC<ISelectPlanItemProps> = ({
  subscription: {
    isDefault,
    mainPrices: {
      periodQuantity,
      periodName,
      weekly: weeklyCustomPrice,
      oldPrices,
    },
  },
  isSelected,
  hasLaptopStyles = false,
}) => {
  const { t } = useTranslation()
  const taxAmount = useSelector(selectTaxAmount)
  const language = useSelector(selectLanguage)
  const currency = useSelector(selectCurrency)

  const [integerCustomPrice, fractionalCustomPrice = '00'] = useMemo(() => {
    const customPriceWithoutTaxes = getPriceWithoutTaxes({
      price: weeklyCustomPrice,
      taxAmount,
    })
    return customPriceWithoutTaxes.split('.')
  }, [taxAmount, weeklyCustomPrice])

  const oldPriceWithoutTaxes = useMemo(
    () =>
      getPriceWithoutTaxes({
        price: oldPrices.beforeCustomDiscount.weekly,
        taxAmount,
      }),
    [oldPrices.beforeCustomDiscount.weekly, taxAmount],
  )

  return (
    <S.PlanItem
      data-has-laptop-styles={hasLaptopStyles}
      data-is-selected={isSelected}
      data-is-default={isDefault}
      data-text={t`subscriptions.planBlock.mostPopular`}
      data-is-es-lang={language === Language.ES}
      data-currency={CURRENCY_SYMBOLS[currency]}
    >
      <S.PlanTitle data-is-es-lang={language === Language.ES}>
        <Trans
          i18nKey="subscriptions.planBlock.planTitle"
          values={{
            periodQuantity,
            periodName: t(PERIOD_NAME_TO_MARKUP_MAP[periodName], {
              count: periodQuantity,
            }),
          }}
        />
      </S.PlanTitle>

      <S.OldPlanPrice>
        {t('subscriptions.planBlock.price', {
          price: oldPriceWithoutTaxes,
          currencySymbol: CURRENCY_SYMBOLS[currency],
        })}
      </S.OldPlanPrice>
      <S.PlanPrice>
        <strong
          data-fractional={fractionalCustomPrice}
          data-currency={CURRENCY_SYMBOLS[currency]}
          data-is-es-lang={language === Language.ES}
        >
          {integerCustomPrice}
        </strong>
        <sub>
          {t('subscriptions.planBlock.perPeriod', {
            interval: t`subscriptions.planBlock.perWeek`,
          })}
        </sub>
      </S.PlanPrice>
    </S.PlanItem>
  )
}
