import styled, { createGlobalStyle } from 'styled-components'

import { BurgerMenu } from 'components/BurgerMenu'
import { Button } from 'components/Button'

import { basePageStyles } from 'common-styles'
import { HEADER_HEIGHT } from 'root-constants/common'

export const BackgroundGlobalStyles = createGlobalStyle`
  :root {
    --base-background: linear-gradient(340deg, rgba(193, 177, 255, 0.60) 15.19%, rgba(222, 202, 254, 0.60) 46.21%, rgba(219, 241, 253, 0.74) 96.35%), #FFF;;
  }
`

export const StyledBenefitsVariant1 = {
  Wrapper: styled.div`
    ${basePageStyles};
    min-height: calc(100dvh - ${HEADER_HEIGHT}px);
    padding-top: ${HEADER_HEIGHT}px;
  `,
  BurgerMenu: styled(BurgerMenu)`
    button {
      top: 6px;
      right: 16px;
    }
  `,
  Content: styled.div`
    max-width: 375px;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    flex-grow: 1;
    padding: 24px 16px 0;
  `,
  BenefitContainer: styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 294px;
    min-height: 80px;
    margin-bottom: 16px;
    padding: 12px;
    border-radius: 12px;
    background: rgba(255, 255, 255, 0.6);

    &:nth-child(even) {
      align-self: flex-end;
    }
  `,
  Image: styled.img`
    max-width: 56px;
    margin-right: 16px;
  `,
  Text: styled.p`
    font-size: 18px;
    font-weight: 700;
    line-height: 25px;

    strong {
      color: #4c8eff;
    }
  `,
  Button: styled(Button)`
    margin: 15px 0 24px;
  `,
}
