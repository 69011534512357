import styled from 'styled-components'

export const StyledSubscriptionDescription = styled.p`
  font-size: 11px;
  line-height: 18px;
  text-align: center;
  color: ${({ theme }) => theme.colors.disclaimerText};

  a {
    color: inherit;
    font-weight: inherit;
    text-decoration: underline;
    white-space: pre;
  }
`
