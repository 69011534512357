import React, { useEffect, useMemo, useRef } from 'react'
import { Trans, useTranslation } from 'react-i18next'

import { useCookieConsentAnswer } from 'hooks/common/useCookieConsentAnswer'
import { useExperimentalFeatures } from 'hooks/common/useExperimentalFeatures'
import { useGetPrices } from 'hooks/common/useGetPrices'
import { usePaywall } from 'hooks/common/usePaywall'
import { usePurchaseStore } from 'hooks/common/usePurchaseStore'
import { useVatInfo } from 'hooks/common/useVatInfo'
import { useIsVisible } from 'hooks/ui/useIsVisible'

import { ScreenName, eventLogger } from 'services/eventLogger.service'
import { googleAnalyticsLogger } from 'services/googleAnalytics.service'

import { DiscountWithTimer } from 'modules/payment/components/DiscountWithTimer'
import { Divider } from 'modules/payment/components/Divider'
import { GetPlanButton } from 'modules/payment/components/GetPlanButton/GetPlanButton'
import {
  PlanItemVariant2,
  PlanItemVariant3,
  PlanItemVariant4,
  PlanItemVariant5,
} from 'modules/payment/components/PlanItem'
import { SelectPlanBlockVariant1 } from 'modules/payment/components/SelectPlanBlock'
import { StripePaymentFormVariant2 } from 'modules/payment/components/StripePaymentForm'
import { Timer } from 'modules/payment/components/Timer'
import { TIMER_HEIGHT } from 'modules/payment/constants'
import { getAxonItems } from 'modules/payment/helpers/getAxonItem'

import { DynamicDiscountBanner } from 'components/DynamicDiscountBanner'
import { Spinner } from 'components/Spinner'
import { StripePaymentProcessing } from 'components/StripePaymentProcessing'
import { SupportLink } from 'components/SupportLink'
import { TermsOfUseLink } from 'components/TermsOfUseLink'

import paymentServices from 'assets/images/payment-services.png'

import {
  CURRENCY_SYMBOLS,
  Cohort,
  GOOGLE_9_TEST_SEGMENTS,
  INTRO_OFFER_FULL_PERIOD_NAMES,
  MAIN_9_CHECKOUT_TEST_SEGMENTS,
  SubscriptionTag,
  TRIAL_QUANTITY_TO_MARKUP_MAP,
} from 'root-constants/common'

import { StyledPaymentVariant5 as S } from './PaymentVariant5.styles'

export const PaymentVariant5: React.FC = () => {
  const btnRef = useRef<HTMLButtonElement>(null)
  const paymentRef = useRef<HTMLSpanElement>(null)

  const { t } = useTranslation()

  const {
    email,
    cohort,
    uuid,
    subscriptions,
    selectedSubscriptionId,
    selectedSubscription,
    trialPrice,
    currentPrice,
    trialPeriodDays,
    currency,
    isPaymentFlowsShown,
    paymentRequestButtonType,
    stripeAccountName,
    stripeAccountId,
    threeDSecureIframeUrl,
    dynamicDiscount,
  } = usePurchaseStore()

  const { hasPrices } = usePaywall('')
  const hasIncludedVAT = useVatInfo()
  const { isPersonalDataAllowed } = useCookieConsentAnswer()

  const {
    hasAlternativePrices,
    hasBrazilianPrices,
    alternativePlanItem,
    hasCheckoutPinnedButton,
  } = useExperimentalFeatures()

  const isPaymentVisible = useIsVisible(paymentRef)
  const isContinueBtnVisible = useIsVisible(btnRef)

  const isButtonHidden = useMemo(
    () => isPaymentVisible && isContinueBtnVisible,
    [isPaymentVisible, isContinueBtnVisible],
  )

  const hasPinnedButton =
    hasCheckoutPinnedButton === MAIN_9_CHECKOUT_TEST_SEGMENTS.B_SEGMENT

  const hasPinnedBlock =
    hasCheckoutPinnedButton === MAIN_9_CHECKOUT_TEST_SEGMENTS.C_SEGMENT

  const hasAlternativePlanItem =
    alternativePlanItem !== GOOGLE_9_TEST_SEGMENTS.A_SEGMENT

  const hasPinnedTimer = !hasPinnedBlock

  const resultTimer = dynamicDiscount ? <DiscountWithTimer /> : <Timer />

  const subscriptionTag = useMemo(() => {
    if (hasAlternativePrices || hasAlternativePlanItem) {
      return hasIncludedVAT
        ? SubscriptionTag.TAX_NEW_PRICE
        : SubscriptionTag.NO_TAX_NEW_PRICE
    }

    if (hasBrazilianPrices) {
      return SubscriptionTag.NO_TAX_NEW_ROUND_PRICE
    }

    return hasIncludedVAT ? SubscriptionTag.TAX : SubscriptionTag.NO_TAX
  }, [
    hasAlternativePlanItem,
    hasAlternativePrices,
    hasBrazilianPrices,
    hasIncludedVAT,
  ])

  const PlanItem = useMemo(() => {
    if (cohort === Cohort.GOOGLE_CHECKOUT) return PlanItemVariant5
    if (hasAlternativePlanItem) return PlanItemVariant4
    if (dynamicDiscount) return PlanItemVariant3

    return PlanItemVariant2
  }, [cohort, dynamicDiscount, hasAlternativePlanItem])

  useGetPrices({
    tags: subscriptionTag,
  })

  const handleScrollOnPaymentBlock = () => {
    const top = paymentRef.current?.offsetTop

    if (top) {
      const yParam = hasPinnedTimer ? top - TIMER_HEIGHT : top
      document.body.scrollTo(0, yParam)
    }
  }

  const sendEvents = () => {
    window.ttq.identify({ email: isPersonalDataAllowed ? email : '' })
  }

  useEffect(() => {
    if (!subscriptions.length) return

    window.fbq('track', 'InitiateCheckout', {}, { eventID: uuid })
    googleAnalyticsLogger.logCheckoutStarted(subscriptions)
  }, [subscriptions, uuid])

  useEffect(() => {
    eventLogger.logPurchaseShown({
      screenName: ScreenName.ONBOARDING,
      stripeAccountName,
      stripeAccountId,
    })
  }, [stripeAccountId, stripeAccountName])

  useEffect(() => {
    if (!subscriptions.length) return

    const defaultSubscription = subscriptions.find(({ isDefault }) => isDefault)
    const price =
      defaultSubscription?.trialPrices.fullPrice ||
      defaultSubscription?.mainPrices.fullPrice

    window.axon &&
      window.axon('track', 'view_item', {
        value: price,
        currency: currency.toUpperCase(),
        items: getAxonItems(subscriptions),
      })
  }, [currency, subscriptions])

  useEffect(() => {
    if (!selectedSubscription) return

    window.axon &&
      window.axon('track', 'add_to_cart', {
        value: trialPrice || currentPrice,
        currency: currency.toUpperCase(),
        items: getAxonItems([selectedSubscription]),
      })
  }, [currency, currentPrice, selectedSubscription, trialPrice])

  return (
    <S.Wrapper>
      {threeDSecureIframeUrl ? (
        <S.ThreeDSecureIframe title="3DSecure" src={threeDSecureIframeUrl} />
      ) : (
        <>
          <DynamicDiscountBanner />

          <S.Content
            hasLighterBg={hasAlternativePlanItem}
            hasPinnedElement={hasPinnedBlock || hasPinnedButton}
          >
            <S.Column>
              <S.Title hasPinnedTimer={hasPinnedTimer}>
                {t`payment.variant5.title`}
              </S.Title>
              {hasPinnedTimer && resultTimer}
              <SelectPlanBlockVariant1 SelectPlanItem={PlanItem} />
              <S.BenefitsContainer>
                <S.BenefitsTitle>{t`onboarding.intro8.youGet`}</S.BenefitsTitle>
                {(
                  t('payment.variant5.benefits', {
                    returnObjects: true,
                  }) as string[]
                ).map((text) => (
                  <S.BenefitsText key={text}>{text}</S.BenefitsText>
                ))}
              </S.BenefitsContainer>
              <S.PaymentServiceImage
                width="256px"
                src={paymentServices}
                decoding="async"
                alt="payments services"
              />
              <S.GuaranteeText ref={paymentRef}>
                {t`payment.guarantee`}
              </S.GuaranteeText>
              <S.PaymentRequestButton key={selectedSubscriptionId} />
              {!!paymentRequestButtonType && <Divider />}
              <StripePaymentFormVariant2
                onSendEvents={sendEvents}
                inputBorderColor="#cbc3e7"
                submitBtnRef={btnRef}
              />
              <S.Guarantee />
              <S.Disclaimer>
                <Trans
                  i18nKey="payment.variant5.disclaimer"
                  values={{
                    price: trialPrice,
                    minimumFractionDigits: 2,
                    currencySymbol: CURRENCY_SYMBOLS[currency],
                    period: t(TRIAL_QUANTITY_TO_MARKUP_MAP[trialPeriodDays]),
                    periodName: t(
                      INTRO_OFFER_FULL_PERIOD_NAMES[trialPeriodDays],
                    ),
                    vatInfo: hasIncludedVAT
                      ? t('commonComponents.inclVat')
                      : '',
                    currentPrice: currentPrice.toFixed(2),
                    currency: currency.toUpperCase(),
                  }}
                  components={{
                    termsOfUse: <TermsOfUseLink />,
                    supportLink: <SupportLink />,
                  }}
                />
              </S.Disclaimer>
            </S.Column>
          </S.Content>
          {hasPinnedButton && (
            <S.ButtonContainer isVisible={!isButtonHidden}>
              <S.Button onClick={handleScrollOnPaymentBlock}>
                {t('actions.getPlan2')}
              </S.Button>
            </S.ButtonContainer>
          )}
          {hasPinnedBlock && (
            <GetPlanButton
              isVisible={!isButtonHidden}
              onClick={handleScrollOnPaymentBlock}
            />
          )}
        </>
      )}
      <StripePaymentProcessing />
      {(!isPaymentFlowsShown || !hasPrices) && <Spinner />}
    </S.Wrapper>
  )
}
