import React from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import { selectIsFetching } from 'root-redux/selects/common'
import { selectEmail } from 'root-redux/selects/user'

import { useConfirmationPasswordInputField } from 'hooks/useConfirmationPasswordInputField'
import { useEmailInputField } from 'hooks/useEmailInputField'
import { usePasswordInputField } from 'hooks/usePasswordInputField'

import { Button } from 'components/Button'
import { Input } from 'components/Input'
import { PrivacyPolicyLink } from 'components/PrivacyPolicyLink'
import { TermsOfUseLink } from 'components/TermsOfUseLink'

import {
  registerWithEmailFirebaseAction,
  resetEmailErrorMessageAction,
  resetPasswordErrorMessageAction,
} from '../../redux/actions'
import {
  selectEmailErrorMessage,
  selectPasswordErrorMessage,
} from '../../redux/selects'
import { StyledEmailAccount as S } from './EmailAccount.styles'

export const EmailAccount: React.FC = () => {
  const dispatch = useDispatch()
  const isFetching = useSelector(selectIsFetching)
  const emailErrorMessage = useSelector(selectEmailErrorMessage)
  const passwordErrorMessage = useSelector(selectPasswordErrorMessage)
  const userOnboardingEmail = useSelector(selectEmail)

  const [email, setEmail, validateEmail] = useEmailInputField(
    userOnboardingEmail,
    emailErrorMessage,
    () => dispatch(resetEmailErrorMessageAction()),
  )
  const [password, setPassword, validatePassword] = usePasswordInputField(
    passwordErrorMessage,
    () => dispatch(resetPasswordErrorMessageAction()),
  )
  const { t } = useTranslation()
  const [
    confirmationPassword,
    setConfirmationPassword,
    validateConfirmationPassword,
  ] = useConfirmationPasswordInputField(password)

  const isComplete =
    email.isValid &&
    email.value !== '' &&
    password.isValid &&
    password.value !== '' &&
    confirmationPassword.isValid &&
    confirmationPassword.value !== ''

  return (
    <S.Wrapper>
      <S.Title>{t`login.titleWelcome`}</S.Title>
      <S.Text>
        <Trans i18nKey="login.subtitle" />
      </S.Text>

      <Input
        value={email.value}
        isValid={email.isValid}
        validationText={email.validationText}
        labelName={t`login.email`}
        placeholder={t`login.placeholderEmail`}
        onChange={({ target: { value } }) => {
          setEmail((prevState) => ({
            ...prevState,
            value: value.toLowerCase().trim(),
            isValid: true,
            validationText: '',
          }))
        }}
        onBlur={({ target: { value } }) => validateEmail(value)}
      />
      <Input
        value={password.value}
        isValid={password.isValid}
        validationText={password.validationText}
        labelName={t`login.password`}
        placeholder={t`login.placeholderPassword`}
        hasVisibilityControl
        onChange={({ target: { value } }) => {
          setPassword((prevState) => ({
            ...prevState,
            value,
            isValid: true,
            validationText: '',
          }))
        }}
        onBlur={({ target: { value } }) => validatePassword(value)}
      />
      <Input
        value={confirmationPassword.value}
        isValid={confirmationPassword.isValid}
        validationText={confirmationPassword.validationText}
        hasVisibilityControl
        labelName={t`login.repeatPassword`}
        placeholder={t`login.placeholderPassword`}
        onChange={({ target: { value } }) => {
          setConfirmationPassword((prevState) => ({
            ...prevState,
            value,
            isValid: true,
            validationText: '',
          }))
        }}
        onBlur={({ target: { value } }) => validateConfirmationPassword(value)}
      />
      <S.Footer>
        <S.Description>
          {t`login.agreement`} <TermsOfUseLink title={t`login.terms`} />{' '}
          {t`actions.and`} <PrivacyPolicyLink />
        </S.Description>
        <Button
          disabled={!isComplete || isFetching}
          onClick={() => {
            dispatch(
              registerWithEmailFirebaseAction(
                email.value.toLowerCase(),
                password.value,
              ),
            )
          }}
        >
          {t`actions.signUp`}
        </Button>
      </S.Footer>
    </S.Wrapper>
  )
}
