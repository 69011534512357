import { ChildFlow } from 'root-constants/common'

export const TITLES = {
  [ChildFlow.KID]: 'onboarding.kidLost.title',
  [ChildFlow.MIXED]: 'onboarding.kidLost.title',
  [ChildFlow.TEENAGE]: 'onboarding.kidLost.title2',
}

export const OPTION_DATA = [
  { value: 'immediately', text: 'onboarding.kidLost.immediately' },
  { value: 'within_an_hour', text: 'onboarding.kidLost.withinHour' },
  { value: 'within_few_hours', text: 'onboarding.kidLost.withinFewHours' },
  { value: 'other', text: 'onboarding.kidLost.other' },
]
