import React, { useEffect, useLayoutEffect, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import { resetErrorAction } from 'root-redux/actions/common'
import {
  getShortenedOneLinkAction,
  sendUserAnswersAction,
} from 'root-redux/actions/user'
import {
  selectAmplitudeApiKey,
  selectCurrentVariantCohortToUse,
  selectError,
  selectLanguage,
} from 'root-redux/selects/common'
import { selectShortenedOneLink } from 'root-redux/selects/user'

import { eventLogger } from 'services/eventLogger.service'

import { DesktopProvider } from 'components/DesktopProvider/DesktopProvider'
import { Modal } from 'components/Modal'
import { Spinner } from 'components/Spinner'
import { SupportLink } from 'components/SupportLink'

import laptopImage from 'assets/images/download-desktop.jpg'

import { CDN_FOLDER_LINK, Cohort } from 'root-constants/common'

import { StyledDownloadAppPage as S } from './DownloadApp.styles'
import { FOLDER_PATH } from './constants'

export const DownloadApp: React.FC = () => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const amplitudeApiKey = useSelector(selectAmplitudeApiKey)
  const shortenedOneLink = useSelector(selectShortenedOneLink)
  const language = useSelector(selectLanguage)
  const error = useSelector(selectError)
  const cohort = useSelector(selectCurrentVariantCohortToUse)

  const [isErrorModalShown, setIsErrorModalShown] = useState(false)

  const isAdaptedCohort = cohort === Cohort.SPOUSE_1 || cohort === Cohort.MAIN_1

  useLayoutEffect(() => {
    dispatch(getShortenedOneLinkAction(() => setIsErrorModalShown(true)))
  }, [dispatch])

  useEffect(() => {
    eventLogger.logGettingAppShown()
  }, [])

  useEffect(() => {
    dispatch(sendUserAnswersAction(true))
  }, [dispatch])

  const handleContinue = () => {
    window.location.href = shortenedOneLink
  }

  const handleClick = (e: React.SyntheticEvent) => {
    e.preventDefault()

    if (amplitudeApiKey) {
      eventLogger.logDownloadClicked(handleContinue)
      return
    }

    eventLogger.logDownloadClicked()
    handleContinue()
  }

  return (
    <>
      {!shortenedOneLink && <Spinner />}
      {shortenedOneLink && (
        <DesktopProvider imageSrc={laptopImage}>
          <S.MobileWrapper isNotAdaptedCohort={!isAdaptedCohort}>
            <S.Title>
              <Trans i18nKey="download.title" />
            </S.Title>
            <S.Image
              src={`${CDN_FOLDER_LINK}${FOLDER_PATH}_${language}.jpg`}
              alt="App screen"
            />
            <S.Column>
              <S.List>
                <li>
                  <S.ListLabel>1</S.ListLabel>
                  <S.ListText>{t`download.item1`}</S.ListText>
                </li>
                <li>
                  <S.ListLabel>2</S.ListLabel>
                  <S.ListText>{t`download.item2`}</S.ListText>
                </li>
              </S.List>

              <S.StickyButton type="button" onClick={handleClick}>
                {t`download.button`}
              </S.StickyButton>
            </S.Column>
          </S.MobileWrapper>
          {isAdaptedCohort && (
            <S.LaptopWrapper>
              <S.Title>
                <Trans i18nKey="download.title" />
              </S.Title>
              <S.Subtitle>{t`download.subtitle`}</S.Subtitle>
              <S.Advice>
                <Trans i18nKey="download.advice1" />
              </S.Advice>
              <S.Separator>{t`payment.or`}</S.Separator>
              <S.Advice>
                <Trans i18nKey="download.advice2" />
              </S.Advice>
              <S.LoginMethodAdvice>{t`download.item2`}</S.LoginMethodAdvice>
              <S.Button type="button" onClick={handleClick}>
                {t`download.button`}
              </S.Button>
              <S.Support>
                <Trans i18nKey="download.support" />
                <SupportLink />
              </S.Support>
            </S.LaptopWrapper>
          )}
        </DesktopProvider>
      )}
      <Modal
        onClose={() => {
          dispatch(resetErrorAction())
          setIsErrorModalShown(false)
        }}
        isShown={isErrorModalShown}
      >
        {error}
      </Modal>
    </>
  )
}
