import styled from 'styled-components'

import { CloseButton, baseColumnStyles, basePageStyles } from 'common-styles'
import { Color, HEADER_HEIGHT } from 'root-constants/common'

type TTextProps = {
  justifySelf?: string
  fontSize?: number
}

export const StyledChipoloCheckout = {
  Wrapper: styled.div`
    ${basePageStyles};
    padding-top: ${HEADER_HEIGHT}px;
  `,
  Column: styled.div`
    ${baseColumnStyles};
    padding-bottom: 44px;
  `,
  Title: styled.h2`
    padding: 48px 0 24px;
    text-align: center;
    font-size: 24px;
    font-weight: 800;
    line-height: 28px;
  `,
  SubscriptionInfo: styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto;
    grid-row-gap: 12px;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 24px;
  `,
  SubscriptionInfoText: styled.p<TTextProps>`
    justify-self: ${({ justifySelf }) => justifySelf || 'flex-start'};
    font-size: ${({ fontSize }) => fontSize || 14}px;

    span {
      font-size: 14px;
    }
  `,
  Disclaimer: styled.ul`
    margin-bottom: 32px;
    padding-left: 14px;
    color: ${Color.LIGHT_TEXT};
    font-size: 11px;
    font-weight: 400;
    line-height: 18px;
    list-style: outside;
  `,
  CloseButton: styled(CloseButton)`
    position: absolute;
    top: 72px;
    right: 15px;
  `,
}
