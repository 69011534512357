import styled from 'styled-components'

import checkIcon from 'assets/images/check-icon-green.svg'
import priceBgImg from 'assets/images/price-bg-green.svg'
import soldOutPriceBgImg from 'assets/images/price-bg-grey.svg'

import { Color, MediaBreakpoint } from 'root-constants/common'

export const StyledPlanItemCancelOffer = {
  PlanItem: styled.div`
    position: relative;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 16px;
    line-height: 22px;
    padding: 31px 16px 22px 56px;
    margin-bottom: 16px;
    border-radius: 12px;
    border: 1px solid ${Color.ACCENT_VIOLET};
    background-color: #eff5f7;

    &::after {
      content: attr(data-text);
      width: calc(100% + 2px);
      display: block;
      padding: 2px 0;
      position: absolute;
      top: -1px;
      left: -1px;
      font-weight: 900;
      font-size: 10px;
      line-height: 13px;
      text-align: center;
      background-color: ${Color.ACCENT_VIOLET};
      border-radius: 12px 12px 0 0;
      color: #ffffff;

      [data-is-sold-out='true']& {
        background-color: #b2b8cd;
      }
    }

    ::before {
      content: '';
      display: block;
      position: absolute;
      top: 41px;
      left: 16px;
      width: 24px;
      height: 24px;
      border-radius: 50%;
      background-image: url(${checkIcon});
      background-position: center;
      background-repeat: no-repeat;
      background-size: 24px;

      [data-is-sold-out='true']& {
        background-image: none;
        border: 1px solid #dce0ee;
        background-color: #ffffff;
      }
    }

    &[data-is-sold-out='true'] {
      border: 1px solid #fff;
    }

    @media (min-width: ${MediaBreakpoint.LAPTOP}px) {
      &::after {
        padding: 4px 0;
        top: -12px;
        font-size: 13px;
        line-height: 18px;
      }
    }
  `,
  Container: styled.div`
    display: flex;
    align-items: center;
  `,
  PlanTitle: styled.div`
    font-weight: 700;
    text-transform: capitalize;
  `,
  CurrentPrice: styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding-right: 10px;
    width: 81px;
    height: 40px;
    font-weight: 900;
    background-image: url(${priceBgImg});
    background-repeat: no-repeat;
    background-size: contain;
    color: #ffffff;

    &[data-is-sold-out='true'] {
      background-image: url(${soldOutPriceBgImg});
    }
  `,
  OldPrice: styled.span`
    font-weight: 700;
    color: rgba(253, 65, 127, 0.5);
    text-decoration: line-through;
    margin-right: 16px;
  `,
}
