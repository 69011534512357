import styled from 'styled-components'

import { Button } from 'components/Button'

import { baseAdaptiveColumnStyles, basePageStyles } from 'common-styles'
import { HEADER_HEIGHT, MediaBreakpoint } from 'root-constants/common'

type TTextProps = {
  marginBottom: number
  marginBottomDesktop?: number
}

export const StyledSocialProof = {
  Wrapper: styled.div`
    ${basePageStyles};
    padding-top: ${HEADER_HEIGHT}px;
    min-height: var(--full-height, 100vh);

    @media (min-width: ${MediaBreakpoint.LAPTOP}px) {
      justify-content: flex-end;
    }
  `,
  Column: styled.div`
    ${baseAdaptiveColumnStyles};
    padding: 26px 0 40px;

    @media (min-height: 620px) {
      position: fixed;
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);
    }

    @media (min-width: ${MediaBreakpoint.LAPTOP}px) {
      position: relative;
    }
  `,
  ImageContainer: styled.div`
    max-width: ${MediaBreakpoint.MAX_PHONE}px;
    margin: 0 auto;
    aspect-ratio: 375/309;

    @media (min-width: ${MediaBreakpoint.TABLET}px) {
      max-width: 100%;
    }

    @media (min-width: ${MediaBreakpoint.LAPTOP}px) {
      display: none;
    }
  `,
  Text: styled.p<TTextProps>`
    margin: ${({ marginBottom }) => `0 0 ${marginBottom}px`};
    font-size: 16px;
    line-height: 22px;
    text-align: center;

    @media (min-width: ${MediaBreakpoint.LAPTOP}px) {
      margin: ${({ marginBottomDesktop }) =>
        `0 -20px ${marginBottomDesktop}px`};
      font-size: 28px;
      line-height: 38px;
    }
  `,
  Note: styled.span`
    margin-bottom: 16px;
    font-style: italic;
    font-size: 13px;
    line-height: 20px;
    text-align: center;
    color: #bcc9d0;

    @media (min-width: ${MediaBreakpoint.LAPTOP}px) {
      margin-bottom: 200px;
      font-size: 18px;
      line-height: 25px;
    }
  `,
  Button: styled(Button)`
    @media (min-width: ${MediaBreakpoint.TABLET}px) {
      width: 460px;
      height: 76px;
      font-size: 24px;
      border-radius: 100px;
    }
  `,
}
