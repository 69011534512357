import styled, { css } from 'styled-components'

import hintIcon from 'assets/images/hint-icon.svg'

import { Color } from 'root-constants/common'

export type TWrapperProps = {
  marginBottom: number
}

export type TInputProps = {
  hasValue: boolean
  isValid: boolean
}

const commonPlaceholderStyles = css`
  top: 15px;
  font-size: 12px;
  line-height: 16px;
`

export const StyledInputWithFloatPlaceholder = {
  Wrapper: styled.div<TWrapperProps>`
    position: relative;
    margin-bottom: ${({ marginBottom }) => `${marginBottom}px`};
  `,
  Input: styled.input<TInputProps>`
    width: 100%;
    height: 56px;
    padding: 24px 16px 8px;
    color: ${({ isValid }) => (isValid ? Color.DEFAULT_TEXT : Color.ERROR)};
    -webkit-text-fill-color: ${({ isValid }) =>
      isValid ? Color.DEFAULT_TEXT : Color.ERROR};
    opacity: 1;
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
    border-radius: 12px;
    background-color: ${Color.GREY};
    outline: none;
    border: ${({ isValid }) =>
      isValid ? '1px solid transparent' : `1px solid ${Color.ERROR}`};
    transition: all 0.2s ease-out;

    ${({ hasValue }) =>
      hasValue &&
      css`
        & + span {
          ${commonPlaceholderStyles};
        }
      `}

    &:focus {
      & + span {
        ${commonPlaceholderStyles};
      }
    }

    &:focus {
      border: ${({ isValid }) =>
        isValid
          ? `1px solid ${Color.ACCENT_VIOLET}`
          : `1px solid ${Color.ERROR}`};
    }
  `,
  Label: styled.span`
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 16px;
    color: ${Color.LIGHT_TEXT};
    font-size: 16px;
    line-height: 24px;
    transition-property: top, font-size, line-height;
    transition-duration: 0.2s;
    transition-timing-function: ease;
    pointer-events: none;
    cursor: text;
  `,
  ValidationText: styled.p`
    position: absolute;
    top: 57px;
    left: 0;
    color: ${Color.ERROR};
    font-weight: 500;
    font-size: 11px;
    line-height: 20px;
  `,
  HintButton: styled.button`
    padding: 0;
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    width: 52px;
    height: 56px;
    border: none;
    outline: none;
    cursor: pointer;
    background-size: 24px 24px;
    background-color: transparent;
    background-repeat: no-repeat;
    background-position: center;
    background-image: url(${hintIcon});
  `,
  Hint: styled.div`
    position: absolute;
    top: 10px;
    right: -6px;
    padding: 12px 8px;
    border-radius: 6px;
    background-color: #2d3240;
    color: ${Color.WHITE};
    text-align: center;
    font-size: 13px;
    font-weight: 700;
    line-height: 18px;
    transform: translateY(-100%);
    min-width: 140px;
    max-height: 78px;
    width: min-content;

    &::after {
      content: '';
      width: 10px;
      height: 10px;
      display: block;
      position: absolute;
      bottom: -6px;
      right: 27px;
      background-color: #2d3240;
      clip-path: polygon(75% 0, 100% 100%, 0 75%);
      border-radius: 6px;
      transform: rotate(45deg);
    }
  `,
}
