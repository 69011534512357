import React from 'react'

import { useGetFlowInfo } from 'hooks/useGetFlowInfo'
import { useGetPageInfo } from 'hooks/useGetPageInfo'

import { getIsMobileDevice } from 'helpers/getIsMobileDevice'

import { ProgressBar } from 'components/ProgressBar'
import { QrCode } from 'components/QRCode'

import logo from 'assets/images/logo.png'

import { StyledDesktopProvider as S } from './DesktopProvider.styles'

type TProps = {
  imageSrc?: string
  children: React.ReactNode
}

export const DesktopProvider: React.FC<TProps> = ({ imageSrc, children }) => {
  const isMobile = getIsMobileDevice()
  const { hasHeader, isDownloadPage } = useGetPageInfo()
  const { hasDesktopVersion } = useGetFlowInfo()

  return hasDesktopVersion && !isMobile ? (
    <S.Root>
      {!!imageSrc && (
        <>
          <S.LeftContainer>
            <S.Image src={imageSrc} alt="" />
            <S.Gradient />
          </S.LeftContainer>
          <S.MiddleContainer>{isDownloadPage && <QrCode />}</S.MiddleContainer>
        </>
      )}
      <S.RightContainer>
        {hasHeader && (
          <S.HeaderWrapper>
            <S.Header>
              <img height="30" src={logo} alt="logo" />
            </S.Header>
            <ProgressBar />
          </S.HeaderWrapper>
        )}
        {children}
      </S.RightContainer>
    </S.Root>
  ) : (
    <div>{children}</div>
  )
}
