import React, { useEffect, useMemo } from 'react'
import { Trans } from 'react-i18next'

import { useNextStep } from 'hooks/useNextStep'
import { useProgressBarList } from 'hooks/useProgressBarList'

import { CustomPageId } from 'root-constants/pages'

import { StyledProgressBarListModal as S } from './ProgressBarListModal.styles'

type TProps = {
  isShown: boolean
  pageId: CustomPageId
  nextPagePath: string
  phoneNumber?: string
  pageName: string
  isDesktop?: boolean
}

const TWO_SECONDS = 2000

export const ProgressBarListModal: React.FC<TProps> = ({
  isShown,
  pageId,
  nextPagePath,
  phoneNumber = '',
  pageName,
  isDesktop = false,
}) => {
  const {
    firstCounterPercent,
    secondCounterPercent,
    thirdCounterPercent,
    isThirdProcessingFinished,
  } = useProgressBarList(isShown, TWO_SECONDS)

  const handleContinue = useNextStep({
    pageId,
    pageName,
    question: '',
    nextPagePath,
  })

  const barsList = useMemo(
    () => [
      {
        title: 'onboarding.preparingFlow.firstProgressBar2',
        color: '#199CF3',
        counter: firstCounterPercent,
      },
      {
        title: 'onboarding.preparingFlow.secondProgressBar2',
        color: '#7879F1',
        counter: secondCounterPercent,
      },
      {
        title: 'onboarding.preparingFlow.thirdProgressBar2',
        color: '#F24281',
        counter: thirdCounterPercent,
      },
    ],
    [firstCounterPercent, secondCounterPercent, thirdCounterPercent],
  )

  useEffect(() => {
    if (!isThirdProcessingFinished) return
    handleContinue(phoneNumber)
  }, [isThirdProcessingFinished, handleContinue, phoneNumber])

  return isShown ? (
    <S.ModalWrapper>
      <S.ProgressBarWrapper isDesktop={isDesktop}>
        <S.ProgressBarsList>
          {barsList.map(({ color, counter, title }) => (
            <S.ProgressContainer key={title}>
              <S.ProgressHeader>
                <S.ProgressTitle>
                  <Trans i18nKey={title} />
                </S.ProgressTitle>
                <S.ProgressPercent>{counter}%</S.ProgressPercent>
              </S.ProgressHeader>
              <S.ProgressBar max={100} value={counter} color={color} />
            </S.ProgressContainer>
          ))}
        </S.ProgressBarsList>
      </S.ProgressBarWrapper>
    </S.ModalWrapper>
  ) : null
}
