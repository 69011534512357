import React from 'react'

import { TPageProps } from 'models/common.model'

import { RadioOptionPage } from 'pages/options-page/RadioOptionPage'

import { CONFIDENCE_OPTION_DATA } from 'root-constants/common'

import { PAGE_NAME, TITLE_PATH } from './constants'

export const WishVariant2: React.FC<TPageProps> = (props) => (
  <RadioOptionPage
    {...props}
    pageName={PAGE_NAME}
    options={CONFIDENCE_OPTION_DATA}
    titlePath={TITLE_PATH}
  />
)
