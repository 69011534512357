import styled from 'styled-components'

import { Color } from 'root-constants/common'

type TDiscountProps = {
  maxWidth?: number
  lineHeight?: number
}
export const StyledPlanItemVariant1 = {
  PlanItem: styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 16px 0;
    margin-bottom: 18px;
    border-top: 1px solid #eff5f7;
    border-bottom: 1px solid #eff5f7;
  `,
  Column: styled.div<{ leftAlignment?: boolean }>`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: ${({ leftAlignment }) => leftAlignment && 'flex-end'};
  `,
  PlanTitle: styled.div`
    font-weight: 800;
    font-size: 16px;
    line-height: 22px;
    text-transform: capitalize;
    margin-bottom: 4px;
  `,
  Discount: styled.div<TDiscountProps>`
    max-width: ${({ maxWidth }) => maxWidth || 75}px;
    font-weight: 800;
    font-size: 13px;
    line-height: ${({ lineHeight }) => lineHeight || 22}px;
    color: ${Color.WHITE};
    padding: 4px 8px;
    background-color: ${Color.ACCENT_VIOLET};
    border-radius: 10px;
    text-transform: uppercase;
  `,
  CurrentPrice: styled.div`
    font-weight: 800;
    font-size: 15px;
    color: ${Color.ACCENT_VIOLET};
  `,
  OldPrice: styled.div`
    font-size: 13px;
    font-weight: 700;
    text-decoration: line-through;
  `,
}
