import { useEffect, useLayoutEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router'

import { usePageInfo } from 'providers/PageInfoProvider'

import {
  selectAmplitudeApiKey,
  selectCurrentVariantCohort,
  selectLanguage,
  selectStripeAccountId,
  selectStripeAccountName,
} from 'root-redux/selects/common'
import {
  selectUUID,
  selectUserStatus,
  selectUtmTags,
} from 'root-redux/selects/user'

import { useCookieConsentAnswer } from 'hooks/useCookieConsentAnswer'

import { getEnvironment } from 'helpers/getEnvironment'

import { Amplitude } from 'services/amplitude.service'
import { eventLogger } from 'services/eventLogger.service'

import { EventLoggerInstanceName, Language } from 'root-constants/common'

export const useAmplitudeInitialization = () => {
  const { search } = useLocation()
  const cohort = useSelector(selectCurrentVariantCohort)
  const uuid = useSelector(selectUUID)
  const amplitudeApiKey = useSelector(selectAmplitudeApiKey)
  const stripeAccountId = useSelector(selectStripeAccountId)
  const stripeAccountName = useSelector(selectStripeAccountName)
  const utmTags = useSelector(selectUtmTags)
  const userStatus = useSelector(selectUserStatus)
  const language = useSelector(selectLanguage)

  const [amplitudeInstance, setAmplitudeInstance] = useState<Amplitude | null>(
    null,
  )

  const URLParams = new URLSearchParams(search)
  const utmTagsFromUrl = Object.fromEntries(URLParams.entries())
  const { isProdEnvironment, isTestEnvironment } = getEnvironment()

  const { isPaymentPage } = usePageInfo()
  const { hasAnalyticsAndPerformanceCookie, isEUUser } =
    useCookieConsentAnswer()

  const shouldAmplitudeBePaused =
    isEUUser && !hasAnalyticsAndPerformanceCookie && language === Language.EN

  const hasAmplitude = isPaymentPage || userStatus?.hasSubscription

  useLayoutEffect(() => {
    if (eventLogger.isAmplitudeActive || shouldAmplitudeBePaused) return

    if (uuid && amplitudeApiKey && hasAmplitude) {
      const amplitudeService = new Amplitude({
        apiKey: amplitudeApiKey,
        instanceName: EventLoggerInstanceName.AMPLITUDE,
        userId: uuid,
        utmTags: utmTagsFromUrl.utm_source ? utmTagsFromUrl : utmTags,
        isTestEnvironment,
        isDevEnvironment: !isProdEnvironment,
      })

      amplitudeService.configure({ cohort, language })
      eventLogger.init(amplitudeService)
      setAmplitudeInstance(amplitudeService)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    amplitudeApiKey,
    cohort,
    isProdEnvironment,
    isPaymentPage,
    isTestEnvironment,
    search,
    utmTags,
    uuid,
    shouldAmplitudeBePaused,
    userStatus?.hasSubscription,
  ])

  useEffect(() => {
    if (!stripeAccountId || !stripeAccountName) return

    amplitudeInstance?.updateUserProperties(stripeAccountId, stripeAccountName)
  }, [amplitudeInstance, stripeAccountId, stripeAccountName])
}
