/* eslint react-hooks/exhaustive-deps: 0 */
import { useCallback, useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'

import { useFeatureIsOn } from '@growthbook/growthbook-react'
import {
  EventTypes,
  ICardPaymentMethodManager,
  INativePaymentMethodManager,
  PaymentInstrumentType,
  PaymentMethodInfo,
  PaymentMethodType,
  Primer,
  PrimerHeadlessCheckout,
} from '@primer-io/checkout-web'

import {
  setErrorAction,
  startFetching,
  stopFetching,
} from 'root-redux/actions/common'
import { updateUserConfigAction } from 'root-redux/actions/user'
import {
  selectCurrentVariantCohort,
  selectError,
  selectScreenName,
  selectStripeAccountId,
  selectStripeAccountName,
} from 'root-redux/selects/common'
import {
  selectEmail,
  selectUUID,
  selectUserCountryCode,
} from 'root-redux/selects/user'

import { useBulkSelector } from 'hooks/common/useBulkSelector'
import { useCookieConsentAnswer } from 'hooks/common/useCookieConsentAnswer'

import { isPayPalAvailable } from 'helpers/isPayPalAvailable'

import { ISubscription } from 'models/subscriptions.model'

import { Events, eventLogger } from 'services/eventLogger.service'
import { googleAnalyticsLogger } from 'services/googleAnalytics.service'

import {
  INITIAL_PRIMER_CONFIG,
  PRIMER_PAYMENT_ERRORS,
  PRIMER_PAY_PAL_ID,
  PaymentMethod,
  PaymentSystem,
} from 'modules/payment/constants'
import { usePrimerAnalytics } from 'modules/payment/hooks/usePrimerAnalytics'
import { setPaymentMethodAction } from 'modules/payment/redux/actions/common'
import {
  PURCHASE_PRIMER,
  SET_PRIMER_PAYMENT_FORM_IS_LOADING,
  getPrimerClientSessionTokenAction,
  primerPurchaseAction,
  primerResumePurchaseAction,
  setIsFirstPaymentRetryPassedAction,
  setIsPrimerRetryProcessing,
  setPrimerClientSessionTokenAction,
} from 'modules/payment/redux/actions/primer'
import {
  selectCurrency,
  selectIsFirstPaymentRetryPassed,
  selectPrimerClientSessionToken,
  selectProductId,
  selectSubscription,
  selectSubscriptionFullPrice,
  selectSubscriptionLookupKey,
  selectSubscriptionTrialPeriodPrice,
} from 'modules/payment/redux/selects'

import { GROWTH_BOOK_FEATURE_KEYS } from 'root-constants/common'

const useSelectors = () =>
  useBulkSelector({
    accountName: selectStripeAccountName,
    accountId: selectStripeAccountId,
    currency: selectCurrency,
    email: selectEmail,
    error: selectError,
    fullPrice: selectSubscriptionFullPrice,
    isFirstPaymentRetryPassed: selectIsFirstPaymentRetryPassed,
    primerClientSessionToken: selectPrimerClientSessionToken,
    priceLookupKey: selectSubscriptionLookupKey,
    productId: selectProductId,
    screenName: selectScreenName,
    selectedSubscription: selectSubscription,
    trialCurrentPrice: selectSubscriptionTrialPeriodPrice,
    uuid: selectUUID,
    countryCode: selectUserCountryCode,
    cohort: selectCurrentVariantCohort,
  })

export const usePrimer = () => {
  const primerRef = useRef<PrimerHeadlessCheckout | null>(null)
  const primerFormRef = useRef<HTMLFormElement>(null)
  const cardManagerRef = useRef<ICardPaymentMethodManager | null>(null)
  const paypalManagerRef = useRef<INativePaymentMethodManager | null>(null)

  const dispatch = useDispatch()
  const { t } = useTranslation()
  const store = useSelectors()
  const { logPaypalPaymentStarted } = usePrimerAnalytics()
  const { isPersonalDataAllowed } = useCookieConsentAnswer()
  const hasPayPalMethod = isPayPalAvailable(
    store.cohort,
    store.countryCode.toUpperCase(),
  )
  const isPrimerCreditCardAvailable = useFeatureIsOn(
    GROWTH_BOOK_FEATURE_KEYS.car_1134_primer_card,
  )

  const logPaymentStarted = (paymentMethod: PaymentMethod) => {
    eventLogger.logPurchaseStarted({
      email: store.email,
      isPersonalDataAllowed,
      screenName: store.screenName,
      productId: store.productId,
      priceDetails: {
        price: store.fullPrice,
        trial: !!store.trialCurrentPrice,
        currency: store.currency,
      },
      paymentSystem: PaymentSystem.PRIMER,
      stripeAccountName: store.accountName,
      stripeAccountId: store.accountId,
      paymentMethod,
    })
  }

  const retryPaymentCallback = useCallback(() => {
    if (!store.isFirstPaymentRetryPassed) {
      dispatch(setIsFirstPaymentRetryPassedAction(true))

      const submit = new Event('submit', {
        cancelable: true,
        bubbles: true,
      })

      primerFormRef.current?.dispatchEvent(submit)
      return
    }

    dispatch(
      setErrorAction(store.error || t(PRIMER_PAYMENT_ERRORS.COMMON_ERROR)),
    )
    dispatch(setIsPrimerRetryProcessing(false))
    dispatch(stopFetching(PURCHASE_PRIMER))
  }, [dispatch, store.error, store.isFirstPaymentRetryPassed, t])

  const initPayPalButton = async () => {
    if (!paypalManagerRef.current) return

    dispatch(startFetching(SET_PRIMER_PAYMENT_FORM_IS_LOADING))

    const payPalButton = paypalManagerRef.current.createButton()

    await payPalButton.render(PRIMER_PAY_PAL_ID, {
      style: {
        buttonType: 'short',
        buttonColor: 'gold',
        buttonHeight: 55,
      },
    })

    dispatch(stopFetching(SET_PRIMER_PAYMENT_FORM_IS_LOADING))
    payPalButton.addEventListener(EventTypes.CLICK, () => {
      eventLogger.logPaymentMethodSelected({
        paymentMethod: PaymentMethod.PAYPAL,
      })
      window.fbq('track', 'AddToCart', {}, { eventID: store.uuid })
      window.ttq.track('AddToCart')
      window.snaptr &&
        window.snaptr('track', 'ADD_CART', {
          user_email: isPersonalDataAllowed ? store.email : '',
        })
      googleAnalyticsLogger.logAddingToCart(
        store.selectedSubscription as ISubscription,
      )
      logPaypalPaymentStarted()

      dispatch(setPaymentMethodAction(PaymentMethod.PAYPAL))
      dispatch(updateUserConfigAction({ paymentSystem: PaymentSystem.PRIMER }))
    })
  }

  const setError = (err?: any) => {
    dispatch(stopFetching(PURCHASE_PRIMER))

    if (!err) {
      return dispatch(setErrorAction(t(PRIMER_PAYMENT_ERRORS.COMMON_ERROR)))
    }

    const e = PRIMER_PAYMENT_ERRORS[err?.code]
    if (e) return dispatch(setErrorAction(t(e)))

    if (err instanceof Error) {
      return dispatch(setErrorAction(err.message))
    }

    return dispatch(setErrorAction(JSON.stringify(err)))
  }

  const initPrimer = async () => {
    if (!store.primerClientSessionToken || primerRef.current) return

    if (!hasPayPalMethod && !isPrimerCreditCardAvailable) {
      return
    }

    primerRef.current = await Primer.createHeadless(
      store.primerClientSessionToken,
      {
        ...INITIAL_PRIMER_CONFIG,
        async onAvailablePaymentMethodsLoad(
          paymentMethods: PaymentMethodInfo[],
        ) {
          try {
            const hasPayPal = paymentMethods.some(
              ({ type }) => type === PaymentMethodType.PAYPAL,
            )

            const hasCard = paymentMethods.some(
              ({ type }) => type === PaymentMethodType.PAYMENT_CARD,
            )

            if (!primerRef.current) return

            if (hasCard && !cardManagerRef.current) {
              cardManagerRef.current =
                await primerRef.current.createPaymentMethodManager(
                  PaymentMethodType.PAYMENT_CARD,
                )
            }

            if (hasPayPal && !paypalManagerRef.current) {
              paypalManagerRef.current =
                await primerRef.current.createPaymentMethodManager(
                  PaymentMethodType.PAYPAL,
                )
              await initPayPalButton()
            }
          } catch (e) {
            setError(e)
          }
        },
        onTokenizeSuccess({ token, paymentInstrumentType }, handler) {
          const paypalTypes = [
            PaymentInstrumentType.PAYPAL,
            PaymentInstrumentType.PAYPAL_VAULTED,
          ]

          if (paypalTypes.includes(paymentInstrumentType)) {
            logPaypalPaymentStarted(Events.PURCHASE_STARTED_PAYPAL)
          }

          if (paymentInstrumentType === PaymentInstrumentType.CARD) {
            logPaymentStarted(PaymentMethod.CREDIT_CARD)
          }

          dispatch(
            primerPurchaseAction({
              token,
              handler,
              retryPaymentCallback,
            }),
          )
        },
        onTokenizeStart() {
          dispatch(startFetching(PURCHASE_PRIMER))
        },
        onTokenizeError() {
          setError()
        },
        onResumeSuccess({ paymentId, resumeToken }, handler) {
          dispatch(
            primerResumePurchaseAction({
              currentPaymentId: paymentId,
              resumeToken,
              handler,
              retryPaymentCallback,
            }),
          )
        },
      },
    )

    await primerRef.current.start()
  }

  useEffect(() => {
    initPrimer()
  }, [store.primerClientSessionToken])

  useEffect(() => {
    if (
      store.priceLookupKey &&
      (hasPayPalMethod || isPrimerCreditCardAvailable)
    ) {
      dispatch(getPrimerClientSessionTokenAction())
    }

    return () => {
      dispatch(setPrimerClientSessionTokenAction(''))
      dispatch(updateUserConfigAction({ paymentId: '' }))
      dispatch(setIsFirstPaymentRetryPassedAction(false))
    }
  }, [dispatch, store.priceLookupKey])

  return {
    primerRef,
    primerFormRef,
    cardManagerRef,
    paypalManagerRef,
  }
}
