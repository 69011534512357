import { ChildFlow } from 'root-constants/common'

export const PAGE_NAME = 'Ability to create geofences and receive notifications'
export const TITLE_PATH = 'onboarding.specificZones.question'

export const TITLES = {
  [ChildFlow.KID]: 'onboarding.specificZones.question2',
  [ChildFlow.MIXED]: 'onboarding.specificZones.question2',
  [ChildFlow.TEENAGE]: 'onboarding.specificZones.question3',
}
