import styled from 'styled-components'

import { Button } from 'components/Button'

import {
  baseAdaptiveTitleStyles,
  baseColumnStyles,
  basePageStyles,
} from 'common-styles'
import { HEADER_HEIGHT, MediaBreakpoint } from 'root-constants/common'

type TProps = {
  marginBottom?: number
  laptopMarginBottom?: number
}

export const StyledSocialProof = {
  Wrapper: styled.div`
    ${basePageStyles};
    min-height: 100%;
    padding-top: ${HEADER_HEIGHT}px;

    @media (min-width: ${MediaBreakpoint.LAPTOP}px) {
      justify-content: center;
    }
  `,
  Image: styled.img`
    @media (min-width: ${MediaBreakpoint.MAX_PHONE}px) {
      max-width: 375px;
    }

    @media (min-width: ${MediaBreakpoint.LAPTOP}px) {
      display: none;
    }
  `,
  Column: styled.div`
    ${baseColumnStyles};
    position: fixed;
    bottom: 0;

    @media (min-height: 550px) {
      padding-bottom: 35px;
    }

    @media (min-width: ${MediaBreakpoint.LAPTOP}px) {
      position: relative;
      width: 595px;
    }
  `,
  Title: styled.h1<TProps>`
    ${baseAdaptiveTitleStyles};
    margin-bottom: 16px;

    @media (min-width: ${MediaBreakpoint.LAPTOP}px) {
      margin-bottom: ${({ laptopMarginBottom }) => laptopMarginBottom}px;
    }
  `,
  Text: styled.p<TProps>`
    font-size: 16px;
    line-height: 22px;
    text-align: center;
    margin-bottom: ${({ marginBottom }) => marginBottom || 16}px;

    @media (min-width: ${MediaBreakpoint.LAPTOP}px) {
      font-size: 24px;
      line-height: 33px;
      margin-bottom: ${({ laptopMarginBottom }) => laptopMarginBottom}px;

      strong {
        font-weight: 800;
      }
    }
  `,
  Description: styled.p`
    font-weight: 600;
    font-size: 18px;
    line-height: 25px;
    text-align: center;
    margin-bottom: 20px;

    strong {
      display: block;
      font-weight: 800;
      font-size: 52px;
      line-height: 71px;
      margin-bottom: 10px;
    }

    b {
      font-weight: 600;
    }

    @media (min-height: 550px) {
      margin-bottom: 51px;
    }

    @media (min-width: ${MediaBreakpoint.LAPTOP}px) {
      font-weight: 400;
      font-size: 28px;
      line-height: 38px;

      strong {
        font-size: 102px;
        line-height: 139px;
        margin-bottom: 23px;
      }

      b {
        font-weight: 800;
      }
    }
  `,
  Note: styled.span<TProps>`
    font-style: italic;
    font-size: 13px;
    line-height: 20px;
    text-align: center;
    color: #bcc9d0;
    margin-bottom: ${({ marginBottom }) => marginBottom || 21}px;

    @media (min-width: ${MediaBreakpoint.LAPTOP}px) {
      font-size: 18px;
      line-height: 25px;
    }
  `,
  Button: styled(Button)`
    @media (min-width: ${MediaBreakpoint.LAPTOP}px) {
      position: fixed;
      width: 460px;
      bottom: 90px;
      height: 76px;
      font-size: 24px;
      line-height: 76px;
    }
  `,
}
