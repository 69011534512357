import styled from 'styled-components'

import { baseColumnStyles, basePageStyles } from 'common-styles'
import { HEADER_HEIGHT } from 'root-constants/common'

type TImageProps = {
  marginBottom: number
  aspectRatio: number
}

export const StyledFeedback = {
  Wrapper: styled.div`
    ${basePageStyles};
    padding-top: ${HEADER_HEIGHT}px;
  `,
  Column: styled.div`
    ${baseColumnStyles};
    min-height: calc(100svh - ${HEADER_HEIGHT}px);
    padding: 40px 0 0;
    text-align: center;
  `,
  Title: styled.h1`
    font-size: 24px;
    font-weight: 700;
    line-height: 33px;
    margin-bottom: 33px;
  `,
  Image: styled.img<TImageProps>`
    margin-bottom: ${({ marginBottom }) => marginBottom}px;
    aspect-ratio: ${({ aspectRatio }) => aspectRatio};
  `,
  UserName: styled.span`
    font-size: 13px;
    font-weight: 700;
    margin-bottom: 8px;
  `,
  Text: styled.p`
    font-size: 13px;
    font-weight: 400;
    line-height: 18px;
    margin: 16px 0 20px;
  `,
  Note: styled.p`
    font-size: 18px;
    font-weight: 600;
    color: #7854f8;
    flex-grow: ${({ theme }) => theme.dimensions.flexGrow};
    margin-bottom: 10px;

    strong {
      font-weight: 800;
    }
  `,
  StickyButtonContainer: styled.div`
    position: sticky;
    z-index: 1;
    bottom: 0;
    margin: 0 auto;
  `,
}
