import React from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { selectLanguage } from 'root-redux/selects/common'

import { ISelectPlanItemProps } from 'models/common.model'

import { PriceValue } from 'components/PriceValue'

import {
  CURRENCY_SYMBOLS,
  Language,
  PERIOD_NAME_TO_MARKUP_MAP,
} from 'root-constants/common'

import { StyledPlanItemVariant1 as S } from './PlanItemVariant1.styles'

export const PlanItemVariant1: React.FC<ISelectPlanItemProps> = ({
  subscription: {
    isDefault,
    currency,
    mainPrices: {
      periodQuantity,
      periodName,
      weekly: weeklyCustomPrice,
      oldPrices,
    },
  },
  isSelected,
  hasLaptopStyles = false,
}) => {
  const language = useSelector(selectLanguage)
  const { t } = useTranslation()

  const [integerCustomPrice, fractionalCustomPrice = '00'] = weeklyCustomPrice
    .toString()
    .split('.')

  return (
    <S.PlanItem
      data-has-laptop-styles={hasLaptopStyles}
      data-is-selected={isSelected}
      data-is-default={isDefault}
      data-text={t`subscriptions.planBlock.mostPopular`}
      data-is-es-lang={language === Language.ES}
    >
      <S.PlanTitle data-is-es-lang={language === Language.ES}>
        <Trans
          i18nKey="subscriptions.planBlock.planTitle"
          values={{
            periodQuantity,
            periodName: t(PERIOD_NAME_TO_MARKUP_MAP[periodName], {
              count: periodQuantity,
            }),
          }}
        />
      </S.PlanTitle>
      {!!oldPrices && (
        <S.OldPlanPrice>
          <PriceValue
            value={oldPrices.beforeCustomDiscount.weekly}
            currency={currency}
          />
        </S.OldPlanPrice>
      )}
      {!!weeklyCustomPrice && (
        <S.PlanPrice>
          <strong
            data-fractional={fractionalCustomPrice}
            data-currency={CURRENCY_SYMBOLS[currency]}
            data-is-es-lang={language === Language.ES}
          >
            {integerCustomPrice}
          </strong>
          <sub>
            {t('subscriptions.planBlock.perPeriod', {
              interval: t`subscriptions.planBlock.perWeek`,
            })}
          </sub>
        </S.PlanPrice>
      )}
    </S.PlanItem>
  )
}
