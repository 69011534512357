import styled from 'styled-components'

export const StyledTimer = {
  TimerContainer: styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: ${({ theme }) => theme.colors.timerBackground};
    border-radius: 16px;
  `,
  TimerContent: styled.div`
    height: 70px;
    width: 100%;
    padding: 0 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 22px;
    font-weight: 700;
    line-height: 40px;
    color: #fff;

    strong {
      font-size: 38px;
    }

    &[data-is-de-lang='true'] {
      line-height: 30px;
    }
  `,
}
