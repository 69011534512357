import React, { createRef, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import lottie from 'lottie-web/build/player/lottie_light'

import { selectAnswers } from 'root-redux/selects/common'

import { useNextStep } from 'hooks/useNextStep'
import { useUserData } from 'hooks/useUserData'

import { formatPhoneNumber } from 'helpers/phone'

import { TPageProps } from 'models/common.model'

import { SvgImage } from 'components/SvgImage'

import loaderAnimation from 'assets/animations/location-search-loader.json'
import transmitterAnimation from 'assets/animations/location-search-transmitter.json'
import checkIcon from 'assets/images/sprite/check-icon-white.svg'

import { StyledLocationSearchVariant1 as S } from './LocationSearchVariant1.styles'
import { LOCATION_DATA, PAGE_NAME } from './constants'

export const LocationSearchVariant1: React.FC<TPageProps> = ({
  pageId,
  nextPagePath,
}) => {
  const { t } = useTranslation()
  const transmitterAnimationRef = useRef<HTMLDivElement>(null)
  const loaderAnimationsRefs = useRef(
    LOCATION_DATA.map(() => createRef<HTMLDivElement>()),
  )
  const answers = useSelector(selectAnswers)

  const [currentAnimationIndex, setCurrentAnimationIndex] = useState(0)

  const { userPhoneNumber } = useUserData()

  const handleContinue = useNextStep({
    pageId,
    pageName: PAGE_NAME,
    question: '',
    nextPagePath,
  })

  const formattedPhoneNumber = formatPhoneNumber(userPhoneNumber)

  useEffect(() => {
    if (transmitterAnimationRef.current) {
      lottie.loadAnimation({
        name: 'transmitter',
        container: transmitterAnimationRef.current,
        animationData: transmitterAnimation,
        loop: true,
      })
    }

    return () => lottie.destroy('transmitter')
  }, [])

  useEffect(() => {
    if (loaderAnimationsRefs.current) {
      const loaderAnimationInstance = lottie.loadAnimation({
        name: currentAnimationIndex.toString(),
        container: loaderAnimationsRefs.current[currentAnimationIndex]
          ?.current as Element,
        animationData: loaderAnimation,
        loop: false,
      })

      loaderAnimationInstance.addEventListener('complete', () =>
        setCurrentAnimationIndex(currentAnimationIndex + 1),
      )
    }

    return () => lottie.destroy(currentAnimationIndex.toString())
  }, [currentAnimationIndex])

  useEffect(() => {
    if (currentAnimationIndex === LOCATION_DATA.length) {
      setTimeout(() => handleContinue(''), 1000)
    }
  }, [currentAnimationIndex, handleContinue])

  return (
    <S.Wrapper>
      <S.Content>
        <S.Column>
          <S.TransmitterContainer ref={transmitterAnimationRef} />
          <S.SearchTitle>{t`onboarding.locationSearch.searchTitle`}</S.SearchTitle>
          <S.PhoneNumber>{formattedPhoneNumber}</S.PhoneNumber>

          {LOCATION_DATA.map(({ name, mockValue, hasLabel }, index) => (
            <S.SearchItem key={name} isVisible={index <= currentAnimationIndex}>
              <S.SearchItemText>{t(name)}</S.SearchItemText>

              {index < currentAnimationIndex && !hasLabel && (
                <S.SearchItemLocation isBlurred={!!mockValue}>
                  {mockValue || answers?.userPhoneCountry}
                </S.SearchItemLocation>
              )}

              {index < currentAnimationIndex && hasLabel && (
                <S.DefinedLocationLabel>
                  <SvgImage svg={checkIcon} width={24} />
                  {t`onboarding.locationSearch.defined`}
                </S.DefinedLocationLabel>
              )}

              {index === currentAnimationIndex && (
                <S.LoaderAnimationContainer
                  ref={loaderAnimationsRefs.current[index]}
                />
              )}
            </S.SearchItem>
          ))}
        </S.Column>
      </S.Content>
    </S.Wrapper>
  )
}
