import styled from 'styled-components'

import discountBadge from 'assets/images/violet-discount-badge.png'

import { Color } from 'root-constants/common'

type TWrapperProps = {
  isDifferentBG: boolean
}

type TRadioButtonProps = {
  checkIcon: string
}

type TPriceProps = {
  hasSmallerFontSize: boolean
}

type TDiscountBadgeProps = {
  isFamyLocate: boolean
  isESLanguage: boolean
}

type TTitleProps = {
  isESLanguage: boolean
}

export const StyledUpsellPlanItemVariant1 = {
  Wrapper: styled.div<TWrapperProps>`
    border-radius: 16px;
    border: 1px solid #e9e9e9;
    background: ${({ isDifferentBG }) =>
      isDifferentBG ? '#f2f0ff' : Color.WHITE};
    box-shadow: 0 8px 20px 0 #e5e5ec;
    padding: 16px;
    position: relative;

    &[data-is-selected='true'] {
      border: 1px solid #8c74e0;
    }
  `,
  TitleWrapper: styled.div`
    display: flex;
    align-items: center;
  `,
  Title: styled.p<TTitleProps>`
    font-size: ${({ isESLanguage }) => (isESLanguage ? 12 : 16)}px;
    font-weight: 700;
    line-height: 22px;
    letter-spacing: 0.5px;
    text-transform: uppercase;
    margin-left: 12px;
  `,
  PriceWrapper: styled.div`
    display: flex;
    flex-direction: column;
    margin-left: auto;
    align-items: end;
  `,
  Price: styled.p<TPriceProps>`
    font-size: ${({ hasSmallerFontSize }) => (hasSmallerFontSize ? 12 : 16)}px;
    font-weight: 700;
    line-height: 22px;
    margin-left: auto;
  `,
  RadioButton: styled.div<TRadioButtonProps>`
    width: 24px;
    height: 24px;
    background-image: url(${({ checkIcon }) => checkIcon});
    background-repeat: no-repeat;
    border: none;
    background-color: transparent;

    &[data-is-selected='true'] {
      background-image: url(${({ checkIcon }) => checkIcon});
    }
  `,
  DiscountBadge: styled.div<TDiscountBadgeProps>`
    background-image: url(${discountBadge});
    background-size: contain;
    width: 90px;
    height: 90px;
    align-self: center;
    position: absolute;
    bottom: 5px;
    right: 70px;
    background-repeat: no-repeat;
    text-align: center;
    text-shadow: 0 3px 5px rgba(27, 28, 44, 0.15);
    font-size: ${({ isESLanguage }) => (isESLanguage ? 12 : 14)}px;
    font-weight: ${({ isFamyLocate }) => (isFamyLocate ? 700 : 800)};
    line-height: 19px;
    color: ${Color.WHITE};
    padding-top: 19px;
    padding-right: 4px;
  `,
  OldPrice: styled.p<TPriceProps>`
    color: ${Color.LIGHT_TEXT};
    font-size: ${({ hasSmallerFontSize }) => (hasSmallerFontSize ? 12 : 14)}px;
    font-style: normal;
    font-weight: 600;
    line-height: 22px;
    text-decoration: line-through;
    text-decoration-color: ${Color.LIGHT_TEXT};
  `,
  DescriptionWrapper: styled.div`
    display: flex;
    gap: 12px;
    align-items: center;
    margin-top: 16px;

    img {
      width: 64px;
      height: 72px;
    }
  `,
  DescriptionText: styled.p`
    font-size: 13px;
    font-weight: 400;
    line-height: 18px;
    color: #17202a;
  `,
}
