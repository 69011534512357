import styled from 'styled-components'

import { Button } from 'components/Button'

import { baseAdaptiveColumnStyles, basePageStyles } from 'common-styles'
import { HEADER_HEIGHT, MediaBreakpoint } from 'root-constants/common'

export const StyledSocialProofVariant5 = {
  Wrapper: styled.div`
    ${basePageStyles};
    padding-top: ${HEADER_HEIGHT}px;
    min-height: var(--full-height, 100vh);

    @media (min-width: ${MediaBreakpoint.LAPTOP}px) {
      justify-content: flex-end;
    }
  `,
  Column: styled.div`
    ${baseAdaptiveColumnStyles};
    padding: 22px 0 40px;

    @media (min-height: 620px) {
      position: fixed;
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);
    }

    @media (min-width: ${MediaBreakpoint.LAPTOP}px) {
      position: relative;
    }
  `,
  ImageContainer: styled.div`
    max-width: ${MediaBreakpoint.MAX_PHONE}px;
    margin: 0 auto;
    aspect-ratio: 375/309;

    @media (min-width: ${MediaBreakpoint.TABLET}px) {
      max-width: 100%;
    }

    @media (min-width: ${MediaBreakpoint.LAPTOP}px) {
      display: none;
    }
  `,
  Description: styled.p`
    margin-bottom: 29px;
    font-weight: 600;
    font-size: 18px;
    line-height: 25px;
    text-align: center;

    strong {
      display: block;
      font-weight: 800;
      font-size: 52px;
      line-height: 71px;
      margin-bottom: 10px;
    }

    i {
      font-style: normal;
    }

    @media (min-width: ${MediaBreakpoint.LAPTOP}px) {
      margin-bottom: 255px;
      font-weight: 400;
      font-size: 28px;
      line-height: 38px;

      strong {
        margin-bottom: 24px;
        font-size: 102px;
        line-height: 104px;
      }

      i {
        font-weight: 800;
      }
    }
  `,
  Button: styled(Button)`
    @media (min-width: ${MediaBreakpoint.TABLET}px) {
      width: 460px;
      height: 76px;
      font-size: 24px;
      border-radius: 100px;
    }
  `,
}
