import styled from 'styled-components'

import { Color } from 'root-constants/common'

type TTextProps = {
  hasGreenColor?: boolean
}

type TColorOptionContainerProps = {
  isSelected: boolean
}

type TColorOptionProps = {
  borderColor: string
  backgroundColor: string
}
export const StyledChipoloPlanItems = {
  Wrapper: styled.div``,
  Content: styled.div`
    display: grid;
    grid-template-columns: 66px 137px 53px;
    grid-template-rows: auto;
    grid-column-gap: 16px;
    align-items: center;
    padding: 8px 16px 8px 8px;
    margin-bottom: 16px;
    border-radius: 18px;
    border: 1px solid #bcc9d0;
    background-color: ${Color.WHITE};

    &:last-of-type {
      margin-bottom: 24px;
    }
  `,
  ImageContainer: styled.div`
    position: relative;
  `,
  Icon: styled.img`
    position: absolute;
    right: -22px;
    top: -20px;
    max-width: 48px;
  `,
  Text: styled.p<TTextProps>`
    font-size: 16px;
    font-weight: 800;
    line-height: 22px;
    color: ${({ hasGreenColor }) =>
      hasGreenColor ? '#72c675' : Color.DEFAULT_TEXT};
  `,
  TextContainer: styled.div`
    display: flex;
    justify-content: space-between;
    margin-bottom: 12px;
  `,
  ColorTitle: styled.p`
    font-size: 16px;
    font-weight: 800;
    line-height: 22px;
  `,
  ColorValue: styled.p`
    color: ${Color.LIGHT_TEXT};
    font-size: 16px;
    font-weight: 600;
    line-height: 22px;
  `,
  ColorContainer: styled.div`
    display: flex;
    justify-content: space-between;
    margin-bottom: 24px;
  `,
  ColorOptionContainer: styled.div<TColorOptionContainerProps>`
    padding: 6px;
    border-radius: 50%;
    border: ${({ isSelected }) =>
      isSelected
        ? `2px solid ${Color.ACCENT_VIOLET}`
        : `2px solid transparent`};
    transition: border-color 0.1s ease-out;
  `,
  ColorOption: styled.div<TColorOptionProps>`
    width: 32px;
    height: 32px;
    border-radius: 50%;
    background-color: ${({ backgroundColor }) => backgroundColor};
    border: ${({ borderColor }) => `1px solid ${borderColor}`};
  `,
}
