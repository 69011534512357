import styled from 'styled-components'

import {
  baseAdaptiveTitleStyles,
  baseColumnStyles,
  basePageStyles,
} from 'common-styles'
import { HEADER_HEIGHT } from 'root-constants/common'

export const StyledStolenPhone = {
  Wrapper: styled.div`
    ${basePageStyles};
    padding-top: ${HEADER_HEIGHT}px;
  `,
  Column: styled.div<{ isSafetyCohort?: boolean }>`
    ${baseColumnStyles};
    padding: 40px 0 13px;
    height: ${({ theme }) => theme.dimensions.columnHeight};
    justify-content: ${({ theme }) =>
      theme.arrangements.columnVerticalAlignment};
  `,
  Title: styled.h1`
    ${baseAdaptiveTitleStyles};
    margin: 0 0 54px;
  `,
}
