import React from 'react'
import { useTranslation } from 'react-i18next'

import { useNextStep } from 'hooks/common/useNextStep'
import { useDelayedContinue } from 'hooks/ui/useDelayedContinue'

import { TPageProps } from 'models/common.model'

import { OPTIONS } from 'pages/gender/v2/constants'

import { OptionType } from 'components/Option'
import { QuestionButton } from 'components/QuestionButton'

import { Language } from 'root-constants/common'
import { CustomPageId } from 'root-constants/pages'

import { StyledGenderVariant2 as S } from './GenderVariant2.styles'

export const GenderVariant2: React.FC<TPageProps> = ({
  pageId,
  nextPagePath,
}) => {
  const { t } = useTranslation()
  const { isAnswersDisabled, handleChange, setIsAnswersDisabled } =
    useDelayedContinue()

  const handleContinue = useNextStep({
    pageId: CustomPageId.GENDER,
    pageName: pageId,
    question: t('onboarding.gender.question', { lng: Language.EN }),
    nextPagePath,
  })

  const optionProps = {
    name: pageId,
    type: OptionType.RADIO,
    onChange: (value: string) => {
      handleChange(value, handleContinue)
      setIsAnswersDisabled(true)
    },
  }

  return (
    <S.Wrapper>
      <S.Column>
        <S.Title>{t('onboarding.gender.title')}</S.Title>
        <S.Subtitle>{t('onboarding.gender.subtitle')}</S.Subtitle>
        {OPTIONS.map(({ value, text }) => (
          <S.Option
            key={value}
            {...optionProps}
            value={value}
            disabled={isAnswersDisabled}
          >
            <QuestionButton>{t(text)}</QuestionButton>
          </S.Option>
        ))}
      </S.Column>
    </S.Wrapper>
  )
}
