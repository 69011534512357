import React from 'react'

import { useUserData } from 'hooks/useUserData'

import { TPageProps } from 'models/common.model'

import { RadioOptionPage } from 'pages/options-page/RadioOptionPage'

import { ChildFlow, LOW_CONFIDENCE_OPTION_DATA } from 'root-constants/common'

import { TITLES } from './constants'

export const KidsAlertsVariant1: React.FC<TPageProps> = (props) => {
  const { childFlow } = useUserData()

  return (
    <RadioOptionPage
      {...props}
      options={LOW_CONFIDENCE_OPTION_DATA}
      titlePath={TITLES[childFlow]}
      marginBottom={childFlow === ChildFlow.KID ? 21 : 54}
    />
  )
}
