import styled from 'styled-components'

import { Color } from 'root-constants/common'

export const StyledTimer = {
  Wrapper: styled.div`
    position: sticky;
    top: 0;
    width: 100%;
    display: flex;
    place-content: center;
    gap: 8px;
    margin-bottom: 57px;
    padding: 12px;
    border-radius: 12px;
    background-color: #fc5774;
    z-index: 1;
  `,
  Text: styled.p`
    color: ${Color.WHITE};
    text-align: center;
    font-size: 18px;
    font-weight: 700;
    line-height: 25px;
  `,
}
