import styled from 'styled-components'

import { BurgerMenu } from 'components/BurgerMenu'

import { baseColumnStyles, basePageStyles } from 'common-styles'
import { Color, HEADER_HEIGHT, MediaBreakpoint } from 'root-constants/common'

export const StyledIntroGoogleVariant2 = {
  Wrapper: styled.div`
    ${basePageStyles};
    padding-top: ${HEADER_HEIGHT}px;
  `,
  Column: styled.div`
    ${baseColumnStyles};
  `,
  BurgerMenu: styled(BurgerMenu)`
    button {
      top: 6px;
      right: 6px;

      @media (min-width: ${MediaBreakpoint.LAPTOP}px) {
        top: 3px;
        right: 3px;
      }
    }
  `,
  Title: styled.h2`
    margin: 24px -10px 24px;
    font-size: 32px;
    font-weight: 800;
    line-height: 38px;
    text-align: center;

    strong {
      font-weight: 800;
      color: ${Color.BLUE};
    }
  `,
  SocialProofImageContainer: styled.div`
    width: 100%;
    max-width: 220px;
    margin-bottom: 40px;
    aspect-ratio: 220/87;
  `,
  Card: styled.div`
    margin-bottom: 40px;
  `,
  CardImage: styled.img`
    width: 100%;
    max-width: ${MediaBreakpoint.MAX_PHONE}px;
    aspect-ratio: 375/270;

    @media (min-width: ${MediaBreakpoint.MAX_PHONE}px) {
      max-width: 375px;
    }
  `,
  TitleContainer: styled.div`
    max-width: 360px;
    padding: 0 40px;
    margin: 0 auto;
  `,
  CardTitle: styled.h2`
    margin-bottom: 8px;
    text-align: center;
    font-size: 24px;
    font-weight: 800;
    line-height: 28px;
  `,
  CardSubtitle: styled.h3`
    text-align: center;
    font-size: 16px;
    font-weight: 600;
    line-height: normal;
    color: #0a324d;
  `,
  SocialProofContainer: styled.div`
    max-width: 343px;
    margin: 0 auto 40px;
    padding: 24px 12px;
    border-radius: 16px;
    background-color: ${Color.GREY};
  `,
  SocialProofTitle: styled.h3`
    margin-bottom: 12px;
    text-align: center;
    font-size: 20px;
    font-weight: 700;
    line-height: 24px;

    strong {
      color: ${Color.BLUE};
      font-size: 28px;
      font-weight: 800;
      line-height: 28px;
    }
  `,
  SocialProofText: styled.p`
    text-align: center;
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
  `,
  Emoji: styled.img`
    display: inline-block;
    width: 18px;
    vertical-align: top;
  `,
}
