import React from 'react'
import { Trans, useTranslation } from 'react-i18next'

import { useNextStep } from 'hooks/common/useNextStep'
import { useUserData } from 'hooks/user/useUserData'

import { TPageProps } from 'models/common.model'

import { GENDER_TO_MARKUP } from 'pages/social-proof/constants'

import { Button } from 'components/Button'

import img from 'assets/images/social-proof-23.webp'

import { StyledSocialProofVariant23 as S } from './SocialProofVariant23.styles'

export const SocialProofVariant23: React.FC<TPageProps> = ({
  pageId,
  nextPagePath,
}) => {
  const { t } = useTranslation()
  const { userGender, ageRange } = useUserData()

  const handleContinue = useNextStep({
    pageId,
    pageName: pageId,
    question: '',
    nextPagePath,
  })

  return (
    <S.Wrapper>
      <S.ImageContainer>
        <img src={img} alt="social_proof" />
      </S.ImageContainer>
      <S.Column>
        <S.Title>{t`onboarding.socialProof.variant1.title`}</S.Title>
        <S.Text>
          <Trans
            i18nKey="onboarding.socialProof.variant23.partnersFidelity"
            values={{ ageRange }}
          />
        </S.Text>
        <S.Text>
          <Trans
            i18nKey="onboarding.socialProof.variant23.suspectInfidelity"
            values={{ gender: t(GENDER_TO_MARKUP[userGender]) }}
          />
        </S.Text>
      </S.Column>
      <S.FixedContainer>
        <S.Note>{t`onboarding.socialProof.variant23.note`}</S.Note>
        <Button type="button" onClick={() => handleContinue('')}>
          {t`actions.continue`}
        </Button>
      </S.FixedContainer>
    </S.Wrapper>
  )
}
