import styled from 'styled-components'

import { Color, MediaBreakpoint } from 'root-constants/common'

type TBenefitsWrapper = {
  borderColor: string
}
type TTextProps = {
  isFontOversized: boolean
}
export const StyledGeolocationBenefitsVariant1 = {
  Wrapper: styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 40px;

    @media (min-width: ${MediaBreakpoint.LAPTOP}px) {
      margin-top: 200px;
    }
  `,
  Title: styled.h1`
    color: ${Color.WHITE};
    text-align: center;
    font-size: 24px;
    font-weight: 800;
    line-height: 32px;
    margin-bottom: 46px;

    @media (min-width: ${MediaBreakpoint.LAPTOP}px) {
      max-width: 809px;
      font-size: 32px;
      line-height: 40px;
      margin-bottom: 70px;
    }
  `,
  BenefitsContainer: styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 38px;

    @media (min-width: ${MediaBreakpoint.LAPTOP}px) {
      flex-direction: row;
      justify-content: center;
      width: 1080px;
    }
  `,
  BenefitsWrapper: styled.div<TBenefitsWrapper>`
    width: 100%;
    padding: 40px 24px 24px 24px;
    border-radius: 24px;
    background: rgba(255, 255, 255, 0.7);
    border-top: ${({ borderColor }) => `1px solid ${borderColor}`};
    position: relative;

    @media (min-width: ${MediaBreakpoint.LAPTOP}px) {
      width: 344px;
      height: 250px;
    }
  `,
  BenefitsTitle: styled.p`
    font-size: 18px;
    font-weight: 700;
    line-height: 24px;
    color: #17202a;
    margin-bottom: 12px;

    @media (min-width: ${MediaBreakpoint.LAPTOP}px) {
      font-size: 20px;
      line-height: 27px;
    }
  `,
  Text: styled.p<TTextProps>`
    font-size: 15px;
    font-weight: 400;
    line-height: 20px;
    color: #616a77;

    @media (min-width: ${MediaBreakpoint.LAPTOP}px) {
      font-size: ${({ isFontOversized }) => (isFontOversized ? 14 : 17)}px;
      line-height: 24px;
    }
  `,
  Image: styled.img`
    position: absolute;
    top: -30px;
    left: 16px;
    z-index: 1;
    width: 60px;
    height: 60px;
  `,
}
