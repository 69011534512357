import { ZIP_RANGES } from 'modules/subscriptions/constants'
import { IFormattedTime } from 'modules/subscriptions/types'

export const getDecoratedTimerValue = (
  valueInSeconds: number,
): IFormattedTime => {
  const hours = Math.trunc(valueInSeconds / 60 / 60)
  const minutes = Math.trunc(valueInSeconds / 60) - hours * 60
  const seconds = valueInSeconds - hours * 60 * 60 - minutes * 60
  const get2DigitValue = (value: number): string =>
    value.toString().padStart(2, '0')

  return {
    hours: get2DigitValue(hours),
    minutes: get2DigitValue(minutes),
    seconds: get2DigitValue(seconds),
  }
}

export const getStateByCode = (zipString: string): string | null => {
  const zipcode = parseInt(zipString, 10)

  for (let i = 0; i < ZIP_RANGES.length; i++) {
    const [start, end, state] = ZIP_RANGES[i]
    if (zipcode >= start && zipcode <= end) {
      return state
    }
  }

  return null
}
