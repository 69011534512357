import bellIcon from 'assets/images/bell-icon.png'
import carIcon from 'assets/images/car-icon.png'
import geoIcon from 'assets/images/geo-icon.png'
import groupsIcon from 'assets/images/groups-icon.png'
import iosIcon from 'assets/images/ios-icon.png'
import locationIconBlue from 'assets/images/location-icon-blue.png'
import locationIcon from 'assets/images/location-icon.png'
import lockIconBlue from 'assets/images/lock-icon-blue.png'
import lockIcon from 'assets/images/lock-icon.png'
import motionHistoryIcon from 'assets/images/motion-history.png'
import subIcon from 'assets/images/sub-icon.png'

import { CDN_FOLDER_LINK, Images } from 'root-constants/common'

export const GOOGLE_BENEFITS_DATA = [
  {
    image: iosIcon,
    title: 'subscriptions.subscriptionsGoogle.variant1.benefits.compatible',
    subtitle: 'subscriptions.subscriptionsGoogle.variant1.benefits.phoneTypes',
  },
  {
    image: lockIconBlue,
    title: 'subscriptions.subscriptionsGoogle.variant1.benefits.secure',
    subtitle: 'subscriptions.subscriptionsGoogle.variant1.benefits.privacy',
  },
  {
    image: locationIconBlue,
    title:
      'subscriptions.subscriptionsGoogle.variant1.benefits.realTimeLocation',
    subtitle:
      'subscriptions.subscriptionsGoogle.variant1.benefits.stayConnected',
  },
  {
    image: subIcon,
    title: 'subscriptions.subscriptionsGoogle.variant1.benefits.subscription',
    subtitle: 'subscriptions.subscriptionsGoogle.variant1.benefits.trialOffer',
  },
]

export const SAFETY_6_BENEFITS_DATA = [
  {
    image: iosIcon,
    title: 'subscriptions.subscriptionsGoogle.variant1.benefits.compatible',
    subtitle: 'subscriptions.subscriptionsGoogle.variant1.benefits.phoneTypes',
  },
  {
    image: lockIconBlue,
    title: 'subscriptions.subscriptionsGoogle.variant1.benefits.secure',
    subtitle: 'subscriptions.subscriptionsGoogle.variant1.benefits.privacy',
  },
  {
    image: locationIconBlue,
    title:
      'subscriptions.subscriptionsGoogle.variant1.benefits.realTimeLocation',
    subtitle:
      'subscriptions.subscriptionsGoogle.variant1.benefits.stayConnected',
  },
]

export const GEOLOCATION_BENEFITS = [
  'subscriptions.subscriptionsGoogle.variant1.geolocationBenefits.userFriendly',
  'subscriptions.subscriptionsGoogle.variant1.geolocationBenefits.deviceNetwork',
  'subscriptions.subscriptionsGoogle.variant1.geolocationBenefits.reliableAssistant',
  'subscriptions.subscriptionsGoogle.variant1.geolocationBenefits.takeAdvantage',
]

export const APP_BENEFITS = [
  {
    subtitle:
      'subscriptions.subscriptionsGoogle.variant1.appBenefits.trackLocation',
    image: `${CDN_FOLDER_LINK}${Images.LOCATION_LOVED}`,
  },
  {
    subtitle:
      'subscriptions.subscriptionsGoogle.variant1.appBenefits.realTimeLocation',
    image: `${CDN_FOLDER_LINK}${Images.LOCATION_HISTORY}`,
  },
  {
    subtitle:
      'subscriptions.subscriptionsGoogle.variant1.appBenefits.unlimitedGroups',
    image: `${CDN_FOLDER_LINK}${Images.UNLIMITED_GROUPS}`,
  },
  {
    subtitle:
      'subscriptions.subscriptionsGoogle.variant1.appBenefits.batteryNotification',
    image: `${CDN_FOLDER_LINK}${Images.SOS_NOTIFICATION}`,
  },
  {
    subtitle:
      'subscriptions.subscriptionsGoogle.variant1.appBenefits.chatPrivately',
    image: `${CDN_FOLDER_LINK}${Images.PRIVATE_CHAT}`,
  },
]

export const APP_BENEFITS_V2 = [
  {
    subtitle:
      'subscriptions.subscriptionsGoogle.variant1.appBenefits.trackLocation',
    image: `${CDN_FOLDER_LINK}${Images.LOCATION_LOVED}`,
  },
  {
    subtitle:
      'subscriptions.subscriptionsGoogle.variant1.appBenefits.realTimeLocation',
    image: `${CDN_FOLDER_LINK}${Images.LOCATION_HISTORY}`,
  },
  {
    subtitle:
      'subscriptions.subscriptionsGoogle.variant1.appBenefits.unlimitedGroups',
    image: `${CDN_FOLDER_LINK}${Images.UNLIMITED_GROUPS}`,
  },
  {
    subtitle:
      'subscriptions.subscriptionsGoogle.variant1.appBenefits.batteryNotification',
    image: `${CDN_FOLDER_LINK}${Images.SOS_NOTIFICATION}`,
  },
  {
    subtitle:
      'subscriptions.subscriptionsGoogle.variant1.appBenefits.chatPrivately',
    image: `${CDN_FOLDER_LINK}${Images.PRIVATE_CHAT}`,
  },
  {
    subtitle:
      'subscriptions.subscriptionsGoogle.variant1.appBenefits.unsafeDriving',
    image: `${CDN_FOLDER_LINK}${Images.UNSAFE_DRIVING}`,
  },
]

export const CHIPOLO_BENEFITS = [
  {
    subtitle:
      'subscriptions.subscriptionsGoogle.variant1.appBenefits.trackLocation',
    image: `${CDN_FOLDER_LINK}${Images.LOCATION_LOVED}`,
  },
  {
    subtitle:
      'subscriptions.subscriptionsGoogle.variant1.appBenefits.realTimeLocation',
    image: `${CDN_FOLDER_LINK}${Images.LOCATION_HISTORY}`,
  },
  {
    subtitle:
      'subscriptions.subscriptionsGoogle.variant1.appBenefits.unlimitedGroups',
    image: `${CDN_FOLDER_LINK}${Images.UNLIMITED_GROUPS}`,
  },
]

export const POSSIBILITIES_DATA = [
  {
    image: geoIcon,
    title: 'onboarding.introGoogle4.possibilities.sendRequests2',
    text: 'onboarding.introGoogle4.possibilities.trackFriends',
  },
  {
    image: locationIcon,
    title: 'onboarding.introGoogle4.possibilities.getLocation',
    text: 'onboarding.introGoogle4.possibilities.pinpointLocation',
  },
  {
    image: lockIcon,
    title: 'onboarding.introGoogle4.possibilities.securePayments',
    text: 'onboarding.introGoogle4.possibilities.payByCard',
  },
]
export const GOOGLE_6_BENEFITS_DATA = [
  {
    image: locationIconBlue,
    title: 'subscriptions.subscriptionsGoogle.variant6.benefits.realTime',
    subtitle: 'subscriptions.subscriptionsGoogle.variant6.benefits.knowWhere',
  },
  {
    image: motionHistoryIcon,
    title: 'subscriptions.subscriptionsGoogle.variant6.benefits.motionHistory',
    subtitle: 'subscriptions.subscriptionsGoogle.variant6.benefits.fullReport',
  },
  {
    image: bellIcon,
    title:
      'subscriptions.subscriptionsGoogle.variant6.benefits.batteryNotifications',
    subtitle: 'subscriptions.subscriptionsGoogle.variant6.benefits.getMessage',
  },
  {
    image: lockIconBlue,
    title: 'subscriptions.subscriptionsGoogle.variant6.benefits.secureChat',
    subtitle:
      'subscriptions.subscriptionsGoogle.variant6.benefits.chatPrivately',
  },
  {
    image: carIcon,
    title: 'subscriptions.subscriptionsGoogle.variant6.benefits.drivingReports',
    subtitle:
      'subscriptions.subscriptionsGoogle.variant6.benefits.receiveReport',
  },
]
export const GOOGLE_6_BENEFITS_PEACE_DATA = [
  {
    image: iosIcon,
    title: 'subscriptions.subscriptionsGoogle.variant1.benefits.compatible',
    subtitle: 'subscriptions.subscriptionsGoogle.variant1.benefits.phoneTypes',
  },
  {
    image: lockIconBlue,
    title: 'subscriptions.subscriptionsGoogle.variant1.benefits.secure',
    subtitle: 'subscriptions.subscriptionsGoogle.variant1.benefits.privacy',
  },
  {
    image: groupsIcon,
    title:
      'subscriptions.subscriptionsGoogle.variant6.benefits.unlimitedGroups',
    subtitle: 'subscriptions.subscriptionsGoogle.variant6.benefits.stayInTouch',
  },
  {
    image: subIcon,
    title: 'subscriptions.subscriptionsGoogle.variant1.benefits.subscription',
    subtitle: 'subscriptions.subscriptionsGoogle.variant1.benefits.trialOffer',
  },
]
