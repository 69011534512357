import { PlatformOS } from 'root-constants/common'

export function getMobileOperatingSystem(): PlatformOS {
  const { userAgent } = window.navigator

  if (/android/i.test(userAgent)) {
    return PlatformOS.ANDROID
  }

  if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
    return PlatformOS.IOS
  }

  return PlatformOS.UNKNOWN
}
