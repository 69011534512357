import styled from 'styled-components'

import { Button } from 'components/Button'

import circlesImage from 'assets/images/circles.png'

import { basePageStyles } from 'common-styles'
import {
  BASE_COLUMN_WIDTH,
  Color,
  MediaBreakpoint,
} from 'root-constants/common'

export const StyledSocialProofVariant21 = {
  Wrapper: styled.div`
    ${basePageStyles};
    position: relative;
    max-width: ${MediaBreakpoint.MAX_PHONE}px;
    height: 100dvh;
    margin: 0 auto;
    padding: 32px 0 24px;
    background: linear-gradient(
        138deg,
        #c3cfff 36.87%,
        #9fa0ff 65.92%,
        #6336ff 101.39%
      ),
      #fff;
    background-size: cover;

    &::before {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      min-height: 254px;
      background-image: url(${circlesImage});
      background-size: contain;
      background-repeat: no-repeat;
      background-position: bottom center;
      pointer-events: none;
    }

    @media (min-width: ${MediaBreakpoint.MAX_PHONE}px) {
      max-width: 375px;
    }
  `,
  ImageContainer: styled.div`
    width: 100%;
    margin-bottom: 24px;
    aspect-ratio: 375/420;
  `,
  Title: styled.h2`
    max-width: ${BASE_COLUMN_WIDTH}px;
    flex-grow: 1;
    color: ${Color.WHITE};
    text-align: center;
    font-size: 22px;
    font-weight: 800;
    line-height: 28px;
  `,
  Button: styled(Button)`
    background-color: ${Color.WHITE};
    color: ${Color.ACCENT_VIOLET};
  `,
}
