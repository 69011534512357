import styled from 'styled-components'

import { FixedContainer } from 'components/FixedContainer'

import {
  baseAdaptiveColumnStyles,
  baseAdaptiveTitleStyles,
  basePageStyles,
} from 'common-styles'
import { HEADER_HEIGHT, MediaBreakpoint } from 'root-constants/common'

export const StyledData = {
  Wrapper: styled.div`
    ${basePageStyles};
    padding-top: ${HEADER_HEIGHT}px;
  `,
  Column: styled.div`
    ${baseAdaptiveColumnStyles};
    padding: 40px 25px 114px;

    @media (min-width: ${MediaBreakpoint.LAPTOP}px) {
      padding: 40px 25px 150px;
    }
  `,
  Title: styled.h1`
    ${baseAdaptiveTitleStyles};
    margin: 0 0 18px;

    @media (min-width: ${MediaBreakpoint.LAPTOP}px) {
      margin: 0 -25px 48px;
    }
  `,
  FixedContainer: styled(FixedContainer)`
    @media (min-width: ${MediaBreakpoint.LAPTOP}px) {
      position: fixed;
    }
  `,
}
