import React, { useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { selectDynamicDiscount } from 'root-redux/selects/common'

import { useTimerForTarget } from 'modules/subscriptions/hooks/useTimerForTarget'

import {
  DYNAMIC_DISCOUNT_CONTAINER_WITH_TIMER_THEME,
  DYNAMIC_DISCOUNT_THEME,
  DYNAMIC_DISCOUNT_TITLE_COLORS,
} from 'root-constants/common'

import { StyledDiscountWithTimer as S } from './DiscountWithTimer.styles'
import {
  FIVE_MINUTES_IN_SECONDS,
  TIMER_START_MINUTES,
  TIMER_START_SECONDS,
} from './constants'

export const DiscountWithTimer: React.FC = () => {
  const { t } = useTranslation()
  const dynamicDiscount = useSelector(selectDynamicDiscount)

  const timerElementRef = useRef<HTMLDivElement>(null)

  const { minutes, seconds } = useTimerForTarget(
    timerElementRef,
    FIVE_MINUTES_IN_SECONDS,
  )

  return dynamicDiscount ? (
    <S.Wrapper
      backgroundColor={
        DYNAMIC_DISCOUNT_CONTAINER_WITH_TIMER_THEME[dynamicDiscount?.theme] ||
        DYNAMIC_DISCOUNT_CONTAINER_WITH_TIMER_THEME.orange
      }
      ref={timerElementRef}
    >
      <S.Discount
        backgroundColor={
          DYNAMIC_DISCOUNT_THEME[dynamicDiscount?.theme] ||
          DYNAMIC_DISCOUNT_THEME.orange
        }
      >
        {t('payment.variant5.discountBlock.discount', {
          discount: dynamicDiscount?.amount,
        })}
      </S.Discount>
      <S.TextWrapper>
        <S.Title
          color={
            DYNAMIC_DISCOUNT_TITLE_COLORS[dynamicDiscount?.theme] ||
            DYNAMIC_DISCOUNT_TITLE_COLORS.orange
          }
        >
          {t`payment.variant5.discountBlock.discountLabel`}
        </S.Title>
        <S.Text>
          {t('payment.variant5.discountBlock.offerEnds', {
            minutes: minutes || TIMER_START_MINUTES,
            seconds: seconds || TIMER_START_SECONDS,
          })}
        </S.Text>
      </S.TextWrapper>
    </S.Wrapper>
  ) : null
}
