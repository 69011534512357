/* eslint-disable max-lines */
export const COUNTRIES_DATA = [
  {
    country: 'Afghanistan',
    iso2: 'AF',
    timeZone: 'GMT+4:30',
    lat: 34.5553,
    lng: 69.2075,
  },
  {
    country: 'Albania',
    iso2: 'AL',
    timeZone: 'GMT+1',
    lat: 41.3275,
    lng: 19.8189,
  },
  {
    country: 'Algeria',
    iso2: 'DZ',
    timeZone: 'GMT+1',
    lat: 28.0339,
    lng: 1.6596,
  },
  {
    country: 'Andorra',
    iso2: 'AD',
    timeZone: 'GMT+1',
    lat: 42.5078,
    lng: 1.5211,
  },
  {
    country: 'Angola',
    iso2: 'AO',
    timeZone: 'GMT+1',
    lat: -8.839,
    lng: 13.2894,
  },
  {
    country: 'Antigua and Barbuda',
    iso2: 'AG',
    timeZone: 'GMT-4',
    lat: 17.0608,
    lng: -61.7964,
  },
  {
    country: 'Argentina',
    iso2: 'AR',
    timeZone: 'GMT-3',
    lat: -38.4161,
    lng: -63.6167,
  },
  {
    country: 'Armenia',
    iso2: 'AM',
    timeZone: 'GMT+4',
    lat: 40.0692,
    lng: 45.0382,
  },
  {
    country: 'Australia',
    iso2: 'AU',
    timeZone: 'GMT+10',
    lat: -25.2744,
    lng: 133.7751,
  },
  {
    country: 'Austria',
    iso2: 'AT',
    timeZone: 'GMT+1',
    lat: 47.5162,
    lng: 14.5501,
  },
  {
    country: 'Azerbaijan',
    iso2: 'AZ',
    timeZone: 'GMT+4',
    lat: 40.1431,
    lng: 47.5769,
  },
  {
    country: 'Bahamas',
    iso2: 'BS',
    timeZone: 'GMT-5',
    lat: 25.0343,
    lng: -77.3963,
  },
  {
    country: 'Bahrain',
    iso2: 'BH',
    timeZone: 'GMT+3',
    lat: 26.0667,
    lng: 50.5577,
  },
  {
    country: 'Bangladesh',
    iso2: 'BD',
    timeZone: 'GMT+6',
    lat: 23.685,
    lng: 90.3563,
  },
  {
    country: 'Barbados',
    iso2: 'BB',
    timeZone: 'GMT-4',
    lat: 13.1939,
    lng: -59.5432,
  },
  {
    country: 'Belarus',
    iso2: 'BY',
    timeZone: 'GMT+3',
    lat: 53.7098,
    lng: 27.9534,
  },
  {
    country: 'Belgium',
    iso2: 'BE',
    timeZone: 'GMT+1',
    lat: 50.8503,
    lng: 4.3517,
  },
  {
    country: 'Belize',
    iso2: 'BZ',
    timeZone: 'GMT-6',
    lat: 17.1899,
    lng: -88.4976,
  },
  {
    country: 'Benin',
    iso2: 'BJ',
    timeZone: 'GMT+1',
    lat: 9.3084,
    lng: 2.3158,
  },
  {
    country: 'Bhutan',
    iso2: 'BT',
    timeZone: 'GMT+6',
    lat: 27.5142,
    lng: 90.4336,
  },
  {
    country: 'Bolivia',
    iso2: 'BO',
    timeZone: 'GMT-4',
    lat: -16.5,
    lng: -68.1193,
  },
  {
    country: 'Bosnia and Herzegovina',
    iso2: 'BA',
    timeZone: 'GMT+1',
    lat: 43.8486,
    lng: 18.3564,
  },
  {
    country: 'Botswana',
    iso2: 'BW',
    timeZone: 'GMT+2',
    lat: -22.3285,
    lng: 24.6849,
  },
  {
    country: 'Brazil',
    iso2: 'BR',
    timeZone: 'GMT-3',
    lat: -14.235,
    lng: -51.9253,
  },
  {
    country: 'Brunei',
    iso2: 'BN',
    timeZone: 'GMT+8',
    lat: 4.5353,
    lng: 114.7277,
  },
  {
    country: 'Bulgaria',
    iso2: 'BG',
    timeZone: 'GMT+2',
    lat: 42.7339,
    lng: 25.4858,
  },
  {
    country: 'Burkina Faso',
    iso2: 'BF',
    timeZone: 'GMT',
    lat: 12.2383,
    lng: -1.5616,
  },
  {
    country: 'Burundi',
    iso2: 'BI',
    timeZone: 'GMT+2',
    lat: -3.3731,
    lng: 29.9189,
  },
  {
    country: 'Cabo Verde',
    iso2: 'CV',
    timeZone: 'GMT-1',
    lat: 16.0021,
    lng: -24.0132,
  },
  {
    country: 'Cambodia',
    iso2: 'KH',
    timeZone: 'GMT+7',
    lat: 12.5657,
    lng: 104.991,
  },
  {
    country: 'Cameroon',
    iso2: 'CM',
    timeZone: 'GMT+1',
    lat: 3.848,
    lng: 11.5021,
  },
  {
    country: 'Canada',
    iso2: 'CA',
    timeZone: 'GMT-5',
    lat: 56.1304,
    lng: -106.3468,
  },
  {
    country: 'Central African Republic',
    iso2: 'CF',
    timeZone: 'GMT+1',
    lat: 6.6111,
    lng: 20.9394,
  },
  {
    country: 'Chad',
    iso2: 'TD',
    timeZone: 'GMT+1',
    lat: 15.4542,
    lng: 18.7322,
  },
  {
    country: 'Chile',
    iso2: 'CL',
    timeZone: 'GMT-3',
    lat: -35.6751,
    lng: -71.543,
  },
  {
    country: 'China',
    iso2: 'CN',
    timeZone: 'GMT+8',
    lat: 35.8617,
    lng: 104.1954,
  },
  {
    country: 'Colombia',
    iso2: 'CO',
    timeZone: 'GMT-5',
    lat: 4.5709,
    lng: -74.2973,
  },
  {
    country: 'Comoros',
    iso2: 'KM',
    timeZone: 'GMT+3',
    lat: -11.6455,
    lng: 43.3333,
  },
  {
    country: 'Congo (Congo-Brazzaville)',
    iso2: 'CG',
    timeZone: 'GMT+1',
    lat: -4.2666,
    lng: 15.2834,
  },
  {
    country: 'Congo (Congo-Kinshasa)',
    iso2: 'CD',
    timeZone: 'GMT+1',
    lat: -4.0383,
    lng: 21.7587,
  },
  {
    country: 'Costa Rica',
    iso2: 'CR',
    timeZone: 'GMT-6',
    lat: 9.7489,
    lng: -83.7534,
  },
  { country: 'Croatia', iso2: 'HR', timeZone: 'GMT+1', lat: 45.1, lng: 15.2 },
  {
    country: 'Cuba',
    iso2: 'CU',
    timeZone: 'GMT-5',
    lat: 21.5218,
    lng: -77.7812,
  },
  {
    country: 'Cyprus',
    iso2: 'CY',
    timeZone: 'GMT+2',
    lat: 35.1264,
    lng: 33.4299,
  },
  {
    country: 'Czechia (Czech Republic)',
    iso2: 'CZ',
    timeZone: 'GMT+1',
    lat: 49.8175,
    lng: 15.473,
  },
  {
    country: 'Denmark',
    iso2: 'DK',
    timeZone: 'GMT+1',
    lat: 56.2639,
    lng: 9.5018,
  },
  {
    country: 'Djibouti',
    iso2: 'DJ',
    timeZone: 'GMT+3',
    lat: 11.8251,
    lng: 42.5903,
  },
  {
    country: 'Dominica',
    iso2: 'DM',
    timeZone: 'GMT-4',
    lat: 15.4148,
    lng: -61.37,
  },
  {
    country: 'Dominican Republic',
    iso2: 'DO',
    timeZone: 'GMT-4',
    lat: 18.7357,
    lng: -70.1627,
  },
  {
    country: 'Ecuador',
    iso2: 'EC',
    timeZone: 'GMT-5',
    lat: -1.8312,
    lng: -78.1834,
  },
  {
    country: 'Egypt',
    iso2: 'EG',
    timeZone: 'GMT+2',
    lat: 26.8205,
    lng: 30.8025,
  },
  {
    country: 'El Salvador',
    iso2: 'SV',
    timeZone: 'GMT-6',
    lat: 13.7942,
    lng: -88.8965,
  },
  {
    country: 'Equatorial Guinea',
    iso2: 'GQ',
    timeZone: 'GMT+1',
    lat: 1.65,
    lng: 10.2679,
  },
  {
    country: 'Eritrea',
    iso2: 'ER',
    timeZone: 'GMT+3',
    lat: 15.1794,
    lng: 39.7823,
  },
  {
    country: 'Estonia',
    iso2: 'EE',
    timeZone: 'GMT+2',
    lat: 59.437,
    lng: 24.7535,
  },
  {
    country: 'Eswatini',
    iso2: 'SZ',
    timeZone: 'GMT+2',
    lat: -26.5225,
    lng: 31.4659,
  },
  {
    country: 'Ethiopia',
    iso2: 'ET',
    timeZone: 'GMT+3',
    lat: 9.145,
    lng: 40.4897,
  },
  {
    country: 'Fiji',
    iso2: 'FJ',
    timeZone: 'GMT+12',
    lat: -17.7134,
    lng: 178.065,
  },
  {
    country: 'Finland',
    iso2: 'FI',
    timeZone: 'GMT+2',
    lat: 61.9241,
    lng: 25.7482,
  },
  {
    country: 'France',
    iso2: 'FR',
    timeZone: 'GMT+1',
    lat: 46.6034,
    lng: 1.8883,
  },
  {
    country: 'Gabon',
    iso2: 'GA',
    timeZone: 'GMT+1',
    lat: -0.8037,
    lng: 11.6094,
  },
  {
    country: 'Gambia',
    iso2: 'GM',
    timeZone: 'GMT',
    lat: 13.4432,
    lng: -15.3101,
  },
  {
    country: 'Georgia',
    iso2: 'GE',
    timeZone: 'GMT+4',
    lat: 42.3154,
    lng: 43.3569,
  },
  {
    country: 'Germany',
    iso2: 'DE',
    timeZone: 'GMT+1',
    lat: 51.1657,
    lng: 10.4515,
  },
  {
    country: 'Ghana',
    iso2: 'GH',
    timeZone: 'GMT',
    lat: 7.0469,
    lng: -0.6057,
  },
  {
    country: 'Greece',
    iso2: 'GR',
    timeZone: 'GMT+2',
    lat: 39.0742,
    lng: 21.8243,
  },
  {
    country: 'Grenada',
    iso2: 'GD',
    timeZone: 'GMT-4',
    lat: 12.1165,
    lng: -61.679,
  },
  {
    country: 'Guatemala',
    iso2: 'GT',
    timeZone: 'GMT-6',
    lat: 15.7835,
    lng: -90.2308,
  },
  {
    country: 'Guinea',
    iso2: 'GN',
    timeZone: 'GMT',
    lat: 9.9456,
    lng: -9.6966,
  },
  {
    country: 'Guinea-Bissau',
    iso2: 'GW',
    timeZone: 'GMT',
    lat: 11.8037,
    lng: -15.1804,
  },
  {
    country: 'Guyana',
    iso2: 'GY',
    timeZone: 'GMT-4',
    lat: 4.8604,
    lng: -58.9302,
  },
  {
    country: 'Haiti',
    iso2: 'HT',
    timeZone: 'GMT-5',
    lat: 18.9712,
    lng: -72.2852,
  },
  {
    country: 'Honduras',
    iso2: 'HN',
    timeZone: 'GMT-6',
    lat: 13.4974,
    lng: -82.6106,
  },
  {
    country: 'Hungary',
    iso2: 'HU',
    timeZone: 'GMT+1',
    lat: 47.1625,
    lng: 19.5033,
  },
  {
    country: 'Iceland',
    iso2: 'IS',
    timeZone: 'GMT',
    lat: 64.9631,
    lng: -19.0208,
  },
  {
    country: 'India',
    iso2: 'IN',
    timeZone: 'GMT+5:30',
    lat: 20.5937,
    lng: 78.9629,
  },
  {
    country: 'Indonesia',
    iso2: 'ID',
    timeZone: 'GMT+7',
    lat: -0.7893,
    lng: 113.9213,
  },
  {
    country: 'Iran',
    iso2: 'IR',
    timeZone: 'GMT+3:30',
    lat: 32.4279,
    lng: 53.688,
  },
  {
    country: 'Iraq',
    iso2: 'IQ',
    timeZone: 'GMT+3',
    lat: 33.2232,
    lng: 43.6793,
  },
  {
    country: 'Ireland',
    iso2: 'IE',
    timeZone: 'GMT',
    lat: 53.4129,
    lng: -8.2439,
  },
  {
    country: 'Israel',
    iso2: 'IL',
    timeZone: 'GMT+2',
    lat: 31.0461,
    lng: 34.8516,
  },
  {
    country: 'Italy',
    iso2: 'IT',
    timeZone: 'GMT+1',
    lat: 41.8719,
    lng: 12.5674,
  },
  {
    country: 'Jamaica',
    iso2: 'JM',
    timeZone: 'GMT-5',
    lat: 18.1096,
    lng: -77.2975,
  },
  {
    country: 'Japan',
    iso2: 'JP',
    timeZone: 'GMT+9',
    lat: 36.2048,
    lng: 138.2529,
  },
  {
    country: 'Jordan',
    iso2: 'JO',
    timeZone: 'GMT+3',
    lat: 30.5852,
    lng: 36.2384,
  },
  {
    country: 'Kazakhstan',
    iso2: 'KZ',
    timeZone: 'GMT+6',
    lat: 48.0196,
    lng: 66.9237,
  },
  {
    country: 'Kenya',
    iso2: 'KE',
    timeZone: 'GMT+3',
    lat: -0.0236,
    lng: 37.9062,
  },
  {
    country: 'Kiribati',
    iso2: 'KI',
    timeZone: 'GMT+12',
    lat: -3.3702,
    lng: -168.734,
  },
  {
    country: 'Kuwait',
    iso2: 'KW',
    timeZone: 'GMT+3',
    lat: 29.3759,
    lng: 47.9774,
  },
  {
    country: 'Kyrgyzstan',
    iso2: 'KG',
    timeZone: 'GMT+6',
    lat: 41.2044,
    lng: 74.7661,
  },
  {
    country: 'Laos',
    iso2: 'LA',
    timeZone: 'GMT+7',
    lat: 19.8563,
    lng: 102.4955,
  },
  {
    country: 'Latvia',
    iso2: 'LV',
    timeZone: 'GMT+2',
    lat: 56.8796,
    lng: 24.6032,
  },
  {
    country: 'Lebanon',
    iso2: 'LB',
    timeZone: 'GMT+2',
    lat: 33.8547,
    lng: 35.8623,
  },
  {
    country: 'Lesotho',
    iso2: 'LS',
    timeZone: 'GMT+2',
    lat: -29.6094,
    lng: 28.2336,
  },
  {
    country: 'Liberia',
    iso2: 'LR',
    timeZone: 'GMT',
    lat: 6.4281,
    lng: -9.4295,
  },
  {
    country: 'Libya',
    iso2: 'LY',
    timeZone: 'GMT+2',
    lat: 26.3351,
    lng: 17.2283,
  },
  {
    country: 'Liechtenstein',
    iso2: 'LI',
    timeZone: 'GMT+1',
    lat: 47.1415,
    lng: 9.5215,
  },
  {
    country: 'Lithuania',
    iso2: 'LT',
    timeZone: 'GMT+2',
    lat: 55.1694,
    lng: 23.8813,
  },
  {
    country: 'Luxembourg',
    iso2: 'LU',
    timeZone: 'GMT+1',
    lat: 49.8153,
    lng: 6.1296,
  },
  {
    country: 'Madagascar',
    iso2: 'MG',
    timeZone: 'GMT+3',
    lat: -18.7669,
    lng: 46.8691,
  },
  {
    country: 'Malawi',
    iso2: 'MW',
    timeZone: 'GMT+2',
    lat: -13.2543,
    lng: 34.3015,
  },
  {
    country: 'Malaysia',
    iso2: 'MY',
    timeZone: 'GMT+8',
    lat: 4.2105,
    lng: 101.9758,
  },
  {
    country: 'Maldives',
    iso2: 'MV',
    timeZone: 'GMT+5',
    lat: 3.2028,
    lng: 73.2207,
  },
  {
    country: 'Mali',
    iso2: 'ML',
    timeZone: 'GMT',
    lat: 17.5707,
    lng: -3.9962,
  },
  {
    country: 'Malta',
    iso2: 'MT',
    timeZone: 'GMT+1',
    lat: 35.9375,
    lng: 14.3754,
  },
  {
    country: 'Marshall Islands',
    iso2: 'MH',
    timeZone: 'GMT+12',
    lat: 7.1095,
    lng: 171.1885,
  },
  {
    country: 'Mauritania',
    iso2: 'MR',
    timeZone: 'GMT',
    lat: 21.0079,
    lng: -10.9408,
  },
  {
    country: 'Mauritius',
    iso2: 'MU',
    timeZone: 'GMT+4',
    lat: -20.3484,
    lng: 57.5522,
  },
  {
    country: 'Mexico',
    iso2: 'MX',
    timeZone: 'GMT-6',
    lat: 23.6345,
    lng: -102.5528,
  },
  {
    country: 'Micronesia',
    iso2: 'FM',
    timeZone: 'GMT+11',
    lat: 7.4256,
    lng: 150.5508,
  },
  {
    country: 'Moldova',
    iso2: 'MD',
    timeZone: 'GMT+2',
    lat: 47.4116,
    lng: 28.3699,
  },
  {
    country: 'Monaco',
    iso2: 'MC',
    timeZone: 'GMT+1',
    lat: 43.7333,
    lng: 7.4167,
  },
  {
    country: 'Mongolia',
    iso2: 'MN',
    timeZone: 'GMT+8',
    lat: 46.8625,
    lng: 103.8467,
  },
  {
    country: 'Montenegro',
    iso2: 'ME',
    timeZone: 'GMT+1',
    lat: 42.7087,
    lng: 19.3744,
  },
  {
    country: 'Morocco',
    iso2: 'MA',
    timeZone: 'GMT+1',
    lat: 31.7917,
    lng: -7.0926,
  },
  {
    country: 'Mozambique',
    iso2: 'MZ',
    timeZone: 'GMT+2',
    lat: -18.6657,
    lng: 35.5296,
  },
  {
    country: 'Myanmar (formerly Burma)',
    iso2: 'MM',
    timeZone: 'GMT+6:30',
    lat: 21.9162,
    lng: 95.955,
  },
  {
    country: 'Namibia',
    iso2: 'NA',
    timeZone: 'GMT+2',
    lat: -22.5597,
    lng: 17.0832,
  },
  {
    country: 'Nauru',
    iso2: 'NR',
    timeZone: 'GMT+12',
    lat: -0.5228,
    lng: 166.9315,
  },
  {
    country: 'Nepal',
    iso2: 'NP',
    timeZone: 'GMT+5:45',
    lat: 28.3949,
    lng: 84.124,
  },
  {
    country: 'Netherlands',
    iso2: 'NL',
    timeZone: 'GMT+1',
    lat: 52.1326,
    lng: 5.2913,
  },
  {
    country: 'New Zealand',
    iso2: 'NZ',
    timeZone: 'GMT+13',
    lat: -40.9006,
    lng: 174.886,
  },
  {
    country: 'Nicaragua',
    iso2: 'NI',
    timeZone: 'GMT-6',
    lat: 12.8654,
    lng: -85.2072,
  },
  {
    country: 'Niger',
    iso2: 'NE',
    timeZone: 'GMT+1',
    lat: 17.6078,
    lng: 8.0817,
  },
  {
    country: 'Nigeria',
    iso2: 'NG',
    timeZone: 'GMT+1',
    lat: 9.082,
    lng: 8.6753,
  },
  {
    country: 'North Macedonia',
    iso2: 'MK',
    timeZone: 'GMT+1',
    lat: 41.6086,
    lng: 21.7453,
  },
  {
    country: 'Norway',
    iso2: 'NO',
    timeZone: 'GMT+1',
    lat: 60.472,
    lng: 8.4689,
  },
  {
    country: 'Oman',
    iso2: 'OM',
    timeZone: 'GMT+4',
    lat: 21.5126,
    lng: 55.9233,
  },
  {
    country: 'Pakistan',
    iso2: 'PK',
    timeZone: 'GMT+5',
    lat: 30.3753,
    lng: 69.3451,
  },
  {
    country: 'Palau',
    iso2: 'PW',
    timeZone: 'GMT+9',
    lat: 7.5149,
    lng: 134.5825,
  },
  {
    country: 'Panama',
    iso2: 'PA',
    timeZone: 'GMT-5',
    lat: 8.9824,
    lng: -79.519,
  },
  {
    country: 'Papua New Guinea',
    iso2: 'PG',
    timeZone: 'GMT+10',
    lat: -6.3149,
    lng: 143.9555,
  },
  {
    country: 'Paraguay',
    iso2: 'PY',
    timeZone: 'GMT-4',
    lat: -23.442,
    lng: -58.4438,
  },
  {
    country: 'Peru',
    iso2: 'PE',
    timeZone: 'GMT-5',
    lat: -9.19,
    lng: -75.0152,
  },
  {
    country: 'Philippines',
    iso2: 'PH',
    timeZone: 'GMT+8',
    lat: 12.8797,
    lng: 121.774,
  },
  {
    country: 'Poland',
    iso2: 'PL',
    timeZone: 'GMT+1',
    lat: 51.9194,
    lng: 19.1451,
  },
  {
    country: 'Portugal',
    iso2: 'PT',
    timeZone: 'GMT',
    lat: 39.3999,
    lng: -8.2245,
  },
  {
    country: 'Qatar',
    iso2: 'QA',
    timeZone: 'GMT+3',
    lat: 25.2769,
    lng: 51.52,
  },
  {
    country: 'Romania',
    iso2: 'RO',
    timeZone: 'GMT+2',
    lat: 45.9432,
    lng: 24.9668,
  },
  {
    country: 'Russia',
    iso2: 'RU',
    timeZone: 'GMT+3',
    lat: 61.524,
    lng: 105.3188,
  },
  {
    country: 'Rwanda',
    iso2: 'RW',
    timeZone: 'GMT+2',
    lat: -1.9403,
    lng: 29.8739,
  },
  {
    country: 'Saint Kitts and Nevis',
    iso2: 'KN',
    timeZone: 'GMT-4',
    lat: 17.3578,
    lng: -62.7825,
  },
  {
    country: 'Saint Lucia',
    iso2: 'LC',
    timeZone: 'GMT-4',
    lat: 13.9094,
    lng: -60.9789,
  },
  {
    country: 'Saint Vincent and the Grenadines',
    iso2: 'VC',
    timeZone: 'GMT-4',
    lat: 12.9843,
    lng: -61.287,
  },
  {
    country: 'Samoa',
    iso2: 'WS',
    timeZone: 'GMT+13',
    lat: -13.759,
    lng: -172.1046,
  },
  {
    country: 'San Marino',
    iso2: 'SM',
    timeZone: 'GMT+1',
    lat: 43.9333,
    lng: 12.45,
  },
  {
    country: 'Sao Tome and Principe',
    iso2: 'ST',
    timeZone: 'GMT+1',
    lat: 0.1864,
    lng: 6.6131,
  },
  {
    country: 'Saudi Arabia',
    iso2: 'SA',
    timeZone: 'GMT+3',
    lat: 23.8859,
    lng: 45.0792,
  },
  {
    country: 'Senegal',
    iso2: 'SN',
    timeZone: 'GMT',
    lat: 14.6928,
    lng: -14.0078,
  },
  {
    country: 'Serbia',
    iso2: 'RS',
    timeZone: 'GMT+1',
    lat: 44.0165,
    lng: 21.0059,
  },
  {
    country: 'Seychelles',
    iso2: 'SC',
    timeZone: 'GMT+4',
    lat: -4.6796,
    lng: 55.492,
  },
  {
    country: 'Sierra Leone',
    iso2: 'SL',
    timeZone: 'GMT',
    lat: 8.4606,
    lng: -11.7799,
  },
  {
    country: 'Singapore',
    iso2: 'SG',
    timeZone: 'GMT+8',
    lat: 1.3521,
    lng: 103.8198,
  },
  {
    country: 'Slovakia',
    iso2: 'SK',
    timeZone: 'GMT+1',
    lat: 48.669,
    lng: 19.699,
  },
  {
    country: 'Slovenia',
    iso2: 'SI',
    timeZone: 'GMT+1',
    lat: 46.1512,
    lng: 14.9955,
  },
  {
    country: 'Solomon Islands',
    iso2: 'SB',
    timeZone: 'GMT+11',
    lat: -9.428,
    lng: 160.645,
  },
  {
    country: 'Somalia',
    iso2: 'SO',
    timeZone: 'GMT+3',
    lat: 5.1521,
    lng: 46.1996,
  },
  {
    country: 'South Africa',
    iso2: 'ZA',
    timeZone: 'GMT+2',
    lat: -30.5595,
    lng: 22.9375,
  },
  {
    country: 'South Sudan',
    iso2: 'SS',
    timeZone: 'GMT+3',
    lat: 6.877,
    lng: 30.2176,
  },
  {
    country: 'Spain',
    iso2: 'ES',
    timeZone: 'GMT+1',
    lat: 40.4637,
    lng: -3.7492,
  },
  {
    country: 'Sri Lanka',
    iso2: 'LK',
    timeZone: 'GMT+5:30',
    lat: 7.8731,
    lng: 80.7718,
  },
  {
    country: 'Sudan',
    iso2: 'SD',
    timeZone: 'GMT+2',
    lat: 12.8628,
    lng: 30.2176,
  },
  {
    country: 'Suriname',
    iso2: 'SR',
    timeZone: 'GMT-3',
    lat: 3.9193,
    lng: -56.0278,
  },
  {
    country: 'Sweden',
    iso2: 'SE',
    timeZone: 'GMT+1',
    lat: 60.1282,
    lng: 18.6435,
  },
  {
    country: 'Switzerland',
    iso2: 'CH',
    timeZone: 'GMT+1',
    lat: 46.8182,
    lng: 8.2275,
  },
  {
    country: 'Syria',
    iso2: 'SY',
    timeZone: 'GMT+2',
    lat: 34.8021,
    lng: 38.9968,
  },
  {
    country: 'Taiwan',
    iso2: 'TW',
    timeZone: 'GMT+8',
    lat: 23.6978,
    lng: 120.9605,
  },
  {
    country: 'Tajikistan',
    iso2: 'TJ',
    timeZone: 'GMT+5',
    lat: 38.861,
    lng: 71.2761,
  },
  {
    country: 'Tanzania',
    iso2: 'TZ',
    timeZone: 'GMT+3',
    lat: -6.369,
    lng: 34.8888,
  },
  {
    country: 'Thailand',
    iso2: 'TH',
    timeZone: 'GMT+7',
    lat: 15.87,
    lng: 100.9925,
  },
  {
    country: 'Timor-Leste',
    iso2: 'TL',
    timeZone: 'GMT+9',
    lat: -8.8742,
    lng: 125.7275,
  },
  { country: 'Togo', iso2: 'TG', timeZone: 'GMT', lat: 8.6195, lng: 0.8248 },
  {
    country: 'Tonga',
    iso2: 'TO',
    timeZone: 'GMT+13',
    lat: -21.1789,
    lng: -175.1982,
  },
  {
    country: 'Trinidad and Tobago',
    iso2: 'TT',
    timeZone: 'GMT-4',
    lat: 10.6918,
    lng: -61.2225,
  },
  {
    country: 'Tunisia',
    iso2: 'TN',
    timeZone: 'GMT+1',
    lat: 33.8869,
    lng: 9.5375,
  },
  {
    country: 'Turkey',
    iso2: 'TR',
    timeZone: 'GMT+3',
    lat: 38.9637,
    lng: 35.2433,
  },
  {
    country: 'Turkmenistan',
    iso2: 'TM',
    timeZone: 'GMT+5',
    lat: 38.9697,
    lng: 59.5561,
  },
  {
    country: 'Tuvalu',
    iso2: 'TV',
    timeZone: 'GMT+12',
    lat: -7.1095,
    lng: 179.194,
  },
  {
    country: 'Uganda',
    iso2: 'UG',
    timeZone: 'GMT+3',
    lat: 1.3733,
    lng: 32.2903,
  },
  {
    country: 'Ukraine',
    iso2: 'UA',
    timeZone: 'GMT+2',
    lat: 48.3794,
    lng: 31.1656,
  },
  {
    country: 'United Arab Emirates',
    iso2: 'AE',
    timeZone: 'GMT+4',
    lat: 23.4241,
    lng: 53.8478,
  },
  {
    country: 'United Kingdom',
    iso2: 'GB',
    timeZone: 'GMT+1',
    lat: 55.3781,
    lng: -3.436,
  },
  {
    country: 'United States',
    iso2: 'US',
    timeZone: 'GMT-5',
    lat: 37.0902,
    lng: -95.7129,
  },
  {
    country: 'Uruguay',
    iso2: 'UY',
    timeZone: 'GMT-3',
    lat: -32.5228,
    lng: -55.7658,
  },
  {
    country: 'Uzbekistan',
    iso2: 'UZ',
    timeZone: 'GMT+5',
    lat: 41.3775,
    lng: 64.5854,
  },
  {
    country: 'Vanuatu',
    iso2: 'VU',
    timeZone: 'GMT+11',
    lat: -15.3767,
    lng: 166.9592,
  },
  {
    country: 'Vatican City',
    iso2: 'VA',
    timeZone: 'GMT+1',
    lat: 41.9029,
    lng: 12.4534,
  },
  {
    country: 'Venezuela',
    iso2: 'VE',
    timeZone: 'GMT-4',
    lat: 6.4238,
    lng: -66.5897,
  },
  {
    country: 'Vietnam',
    iso2: 'VN',
    timeZone: 'GMT+7',
    lat: 14.0583,
    lng: 108.2772,
  },
  {
    country: 'Yemen',
    iso2: 'YE',
    timeZone: 'GMT+3',
    lat: 15.5528,
    lng: 48.5164,
  },
  {
    country: 'Zambia',
    iso2: 'ZM',
    timeZone: 'GMT+2',
    lat: -13.1339,
    lng: 27.8493,
  },
  {
    country: 'Zimbabwe',
    iso2: 'ZW',
    timeZone: 'GMT+2',
    lat: -19.0154,
    lng: 29.1549,
  },
]
