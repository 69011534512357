import styled, { css } from 'styled-components'

import { BurgerMenu } from 'components/BurgerMenu'
import { Button } from 'components/Button'

import image from 'assets/images/intro-city.webp'

import { baseColumnStyles, basePageStyles } from 'common-styles'
import { Color, MediaBreakpoint } from 'root-constants/common'

type TProps = {
  isCookieConsentAvailable: boolean
}
const absolutePositionedTitleStyles = css`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
`

export const StyledIntroVariant9 = {
  Wrapper: styled.div`
    ${basePageStyles};
    background-image: url(${image});
    background-size: cover;
    background-repeat: no-repeat;
    min-height: 100%;
    max-width: ${MediaBreakpoint.MAX_PHONE}px;
    margin: 0 auto;
    justify-content: flex-end;
    position: relative;
  `,
  Column: styled.div`
    ${baseColumnStyles};
    padding-bottom: 32px;
    max-width: 324px;
    width: 100%;

    @media (min-width: 430px) {
      padding-bottom: 80px;
    }
  `,
  Title: styled.h1<TProps>`
    text-align: center;
    font-size: 34px;
    font-weight: 800;
    line-height: 46px;
    color: ${Color.WHITE};
    ${({ isCookieConsentAvailable }) =>
      isCookieConsentAvailable && absolutePositionedTitleStyles};
  `,
  Subtitle: styled.p`
    font-size: 22px;
    line-height: 30px;
    text-align: center;
    font-weight: 600;
    margin-top: 4px;
    color: ${Color.WHITE};
    margin-bottom: 32px;
  `,
  Button: styled(Button)`
    position: relative;
    width: 324px;

    svg {
      position: absolute;
      top: 19px;
      right: 19px;
    }
  `,
  BurgerMenu: styled(BurgerMenu)`
    button {
      top: 10px;
      right: 20px;
    }

    nav {
      button {
        background-size: 40px;
        top: 15px;
        left: 20px;
      }
    }
  `,
}
