import React from 'react'
import { useTranslation } from 'react-i18next'

import { StyledHowItWorksVariant1 as S } from './HowItWorksVariant1.styles'
import { HOW_IT_WORKS_DATA } from './constants'

export const HowItWorksVariant1: React.FC = () => {
  const { t } = useTranslation()

  return (
    <S.Column>
      {HOW_IT_WORKS_DATA.map(({ step, title, text, image, background }) => (
        <S.Wrapper key={step} background={background}>
          <S.TextWrapper>
            <S.Step>{t(step)}</S.Step>
            <S.Title>{t(title)}</S.Title>
            <S.Text>{t(text)}</S.Text>
          </S.TextWrapper>
          <S.ImageContainer>
            <img src={image} alt="phone" />
          </S.ImageContainer>
        </S.Wrapper>
      ))}
    </S.Column>
  )
}
