export const PARTNER_FLOW_BENEFITS = [
  'subscriptions.variant5.benefits.track',
  'subscriptions.benefits.partnerList.alerts',
  'subscriptions.variant5.benefits.sos',
  'subscriptions.benefits.partnerList.reduce',
]

export const KID_FLOW_BENEFITS = [
  'subscriptions.benefits.kidList.notification',
  'subscriptions.benefits.kidList.track',
  'subscriptions.benefits.kidList.sos',
  'subscriptions.benefits.kidList.driving',
  'subscriptions.benefits.kidList.stress',
]
