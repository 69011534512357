import React, { useEffect } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { useLocation } from 'react-router-dom'

import { useFeatureIsOn } from '@growthbook/growthbook-react'

import { useGetPrices } from 'hooks/useGetPrices'
import { usePaywall } from 'hooks/usePaywall'
import { usePurchaseStore } from 'hooks/usePurchaseStore'

import { ScreenName, eventLogger } from 'services/eventLogger.service'

import { makeUpsellAction } from 'modules/payment/redux/actions/common'
import { UpsellPlanItemVariant1 } from 'modules/subscriptions/components/upsell'
import {
  FAMIO_APP_NAME,
  FAMYLOCATE_APP_NAME,
} from 'modules/subscriptions/pages/Upsell/constants'

import { Spinner } from 'components/Spinner'
import { StripePaymentProcessing } from 'components/StripePaymentProcessing'
import { SupportLink } from 'components/SupportLink'
import { TermsOfUseLink } from 'components/TermsOfUseLink'

import { goTo } from 'browser-history'
import {
  CURRENCY_SYMBOLS,
  Cohort,
  SubscriptionListType,
  SubscriptionTag,
} from 'root-constants/common'
import { PageId } from 'root-constants/pages'

import { StyledUpsellVariant2 as S } from './UpsellVariant2.styles'

export const UpsellVariant2: React.FC = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const { search } = useLocation()

  const hasAlternativePriceTag = useFeatureIsOn('abtest_car_843_newprice')

  useGetPrices({
    screenName: ScreenName.UPSELL_OFFER,
    subscriptionType: SubscriptionListType.UPSELL,
    tags: hasAlternativePriceTag
      ? SubscriptionTag.NO_TAX_NEW_PRICE
      : SubscriptionTag.NO_TAX,
  })

  const { cohortToUse, currency, currentPrice } = usePurchaseStore()
  const { hasPrices, handleSelectSubscription } = usePaywall('')

  useEffect(() => {
    eventLogger.logUpsellPurchaseShown()
  }, [])

  const makeUpsell = () => {
    dispatch(makeUpsellAction())
  }

  const handleSkip = () => {
    eventLogger.logUpsellPurchaseClose()
    goTo({
      pathname: PageId.ACCOUNT,
      search,
    })
  }

  return !hasPrices ? (
    <Spinner />
  ) : (
    <S.Wrapper>
      <S.Column>
        <S.Title isFamyLocate={cohortToUse === Cohort.FAMYLOCATE_V1}>
          {t`subscriptions.upsell2.title`}
        </S.Title>
        <S.SelectPlanBlock
          onSelect={handleSelectSubscription}
          SelectPlanItem={UpsellPlanItemVariant1}
        />
        <S.Button
          type="button"
          onClick={makeUpsell}
          isFamyLocate={cohortToUse === Cohort.FAMYLOCATE_V1}
        >
          {t`actions.continue`}
        </S.Button>
        <S.SkipButton
          type="button"
          onClick={handleSkip}
          isFamyLocate={cohortToUse === Cohort.FAMYLOCATE_V1}
        >
          {t`actions.skipOffer`}
        </S.SkipButton>
        <S.Disclaimer>
          <Trans
            i18nKey="subscriptions.upsell.disclaimer2"
            values={{
              price: currentPrice,
              minimumFractionDigits: 2,
              currencySymbol: CURRENCY_SYMBOLS[currency],
              currency: currency.toUpperCase(),
              appName:
                cohortToUse === Cohort.FAMYLOCATE_V1
                  ? FAMYLOCATE_APP_NAME
                  : FAMIO_APP_NAME,
            }}
            components={{
              termsOfUse: <TermsOfUseLink />,
              supportLink: <SupportLink />,
            }}
          />
        </S.Disclaimer>
      </S.Column>
      <StripePaymentProcessing />
    </S.Wrapper>
  )
}
