import styled from 'styled-components'

type TTextProps = {
  textAlign?: string
}
export const StyledBenefitsVariant3 = {
  Wrapper: styled.div`
    display: flex;
    flex-direction: column;
    gap: 32px;
    margin-bottom: 48px;
  `,
  Title: styled.h2`
    font-size: 24px;
    font-style: normal;
    font-weight: 800;
    line-height: 28px;
    text-align: center;
  `,
  ContentWrapper: styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
  `,
  Subtitle: styled.h3<TTextProps>`
    margin-bottom: 16px;
    font-size: 18px;
    font-weight: 700;
    line-height: 25px;
    text-align: ${({ textAlign }) => textAlign || 'center'};
    width: 100%;
  `,
  Image: styled.img`
    aspect-ratio: 311/100;
    width: 100%;
    max-width: 311px;
  `,
}
