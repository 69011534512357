import React, { HTMLAttributes, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { selectLanguage } from 'root-redux/selects/common'

import { CDN_FOLDER_LINK, Images } from 'root-constants/common'

import { GuaranteeCard } from './Guarantee.styles'

interface IGuarantee extends HTMLAttributes<HTMLDivElement> {}

export const Guarantee: React.FC<IGuarantee> = ({ ...props }) => {
  const language = useSelector(selectLanguage)

  const { t } = useTranslation()

  const guaranteeImageLink = useMemo(
    () => `${CDN_FOLDER_LINK}${Images.GUARANTEE}_${language}.webp`,
    [language],
  )

  return (
    <GuaranteeCard {...props} imageLink={guaranteeImageLink}>
      <h4>{t`subscriptions.guarantee.title`}</h4>
      <span>{t`subscriptions.guarantee.text`}</span>
    </GuaranteeCard>
  )
}
