import styled from 'styled-components'

import checkIcon from 'assets/images/check-icon-white-rounded.svg'

import { Color } from 'root-constants/common'

type TCheckboxProps = {
  isSelected: boolean
}

type TWrapperProps = TCheckboxProps & {
  isDefault: boolean
  labelText: string
}

type TPriceContainerProps = TCheckboxProps

export const StyledPlanItemVariant4 = {
  Wrapper: styled.div<TWrapperProps>`
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    gap: 14px;
    padding: 18px 16px;
    border-radius: ${({ isDefault }) => (isDefault ? '0 0 16px 16px' : '16px')};
    border: ${({ isSelected }) =>
      isSelected ? `1px solid ${Color.ACCENT_VIOLET}` : `1px solid #cbc3e7`};
    background-color: ${Color.WHITE};

    ${({ isDefault, labelText }) =>
      isDefault &&
      `
        &::after {
            content: '${labelText}';
            width: 100%;
            border: 1px solid ${Color.ACCENT_VIOLET};
            border-radius: 16px 16px 0 0;
            position: absolute;
            top: -24px;
            left: 50%;
            transform: translateX(-50%);
            height: 22px;
            background-color: ${Color.ACCENT_VIOLET};
            color: ${Color.WHITE};
            text-align: center;
            font-family: Montserrat;
            font-size: 14px;
            font-weight: 600;
            line-height: 20px;
            letter-spacing: 0.5px;
            box-sizing: content-box;
          }
      `}
  `,
  Checkbox: styled.div<TCheckboxProps>`
    width: 24px;
    height: 24px;
    border: ${({ isSelected }) =>
      isSelected ? `2px solid ${Color.ACCENT_VIOLET}` : `2px solid #cbc3e7`};
    border-radius: 50%;
    background-color: ${({ isSelected }) =>
      isSelected ? Color.ACCENT_VIOLET : 'transparent'};
    background-image: ${({ isSelected }) =>
      isSelected ? `url(${checkIcon})` : 'none'};
    background-repeat: no-repeat;
    background-position: center;
    background-size: 16px;
  `,
  MainPricesContainer: styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    flex: 1;
  `,
  Period: styled.span`
    margin-bottom: 4px;
    width: 100%;
    font-size: 18px;
    font-weight: 700;
    line-height: 22px;
    text-transform: uppercase;
  `,
  CurrentPrice: styled.span`
    margin-bottom: 4px;
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
  `,
  PriceAfter: styled.span`
    display: flex;
    gap: 2px;
    color: ${Color.LIGHT_TEXT};
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
  `,
  TotalPrice: styled.span`
    margin-bottom: 4px;
    font-size: 11px;
    font-weight: 400;
    line-height: 15px;
  `,
  Discount: styled.span`
    padding: 4px;
    border-radius: 6px;
    border: 1px solid ${Color.RED};
    background-color: #fff7f8;
    color: ${Color.RED};
    font-size: 11px;
    font-weight: 700;
    line-height: 15px;
  `,
  CustomPriceContainer: styled.div<TPriceContainerProps>`
    position: relative;
    display: grid;
    grid-template-columns: repeat(2, auto);
    grid-template-rows: 14px auto;
    grid-template-areas:
      'integer fractional'
      'integer period';
    grid-gap: 4px;
    color: ${({ isSelected }) =>
      isSelected ? Color.DEFAULT_TEXT : Color.LIGHT_TEXT};
  `,
  Currency: styled.span`
    position: absolute;
    top: 0;
    left: -4px;
    transform: translateX(-100%);
    text-align: right;
    font-size: 14px;
    font-weight: 700;
    line-height: 14px;
  `,
  CustomPriceInteger: styled.span`
    grid-area: integer;
    font-size: 48px;
    font-weight: 700;
    line-height: 40px;
  `,
  CustomPriceFractional: styled.span`
    grid-area: fractional;
    font-size: 14px;
    line-height: 14px;
    font-weight: 700;
  `,
  CustomPricePeriod: styled.span`
    grid-area: period;
    font-size: 10px;
    font-weight: 600;
    line-height: 10px;
  `,
}
