import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'

import {
  selectApplovinPixelId,
  selectLanguage,
} from 'root-redux/selects/common'

import { useBulkSelector } from 'hooks/common/useBulkSelector'
import { useCookieConsentAnswer } from 'hooks/common/useCookieConsentAnswer'

import { hasAnalyticsByLocale } from 'helpers/hasAnalyticsByLocale'

const useSelectors = () =>
  useBulkSelector({
    applovinPixelId: selectApplovinPixelId,
    language: selectLanguage,
  })

export const useApplovinPixelInitialization = (): void => {
  const { applovinPixelId, language } = useSelectors()
  const { pathname } = useLocation()

  const { isEUUser, hasAdvertisingAndTargetingCookie } =
    useCookieConsentAnswer()

  const shouldApplovinPixelBePaused =
    isEUUser &&
    !hasAdvertisingAndTargetingCookie &&
    !hasAnalyticsByLocale(language)

  useEffect(() => {
    if (!applovinPixelId || shouldApplovinPixelBePaused) return

    const axonScript = document.createElement('script')

    axonScript.type = 'text/javascript'
    axonScript.text = `
    var AXON_EVENT_KEY="${applovinPixelId}";
    !function(e,r){var
    t=["https://s.axon.ai/pixel.js","https://c.albss.com/p/l/loader.iife.js"];if(!e
    .axon){var
    a=e.axon=function(){a.performOperation?a.performOperation.apply(a,arguments):a.
    operationQueue.push(arguments)};a.operationQueue=[],a.ts=Date.now(),a.eventKey=
    AXON_EVENT_KEY;for(var
    n=r.getElementsByTagName("script")[0],o=0;o<t.length;o++){var
    i=r.createElement("script");i.async=!0,i.src=t[o],n.parentNode.insertBefore(i,n
    )}}}(window,document);
    axon("init");
    `
    document.body.appendChild(axonScript)
  }, [applovinPixelId, shouldApplovinPixelBePaused])

  useEffect(() => {
    if (!shouldApplovinPixelBePaused) {
      window.axon && window.axon('track', 'page_view')
    }
  }, [pathname, shouldApplovinPixelBePaused])
}
