import React, { useMemo } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { selectTaxAmount } from 'root-redux/selects/common'

import { getPriceWithoutTaxes } from 'helpers/getPriceWithoutTaxes'

import {
  selectCurrency,
  selectUpgradeAmountToPay,
} from 'modules/payment/redux/selects'

import { Switch } from 'components/Switch'

import { CURRENCY_SYMBOLS } from 'root-constants/common'

import { StyledChipoloOffer as S } from './ChipoloOffer.styles'

type TProps = {
  isUpgradeSelected: boolean
  isChipoloSelected: boolean
  onChange: (value: React.ChangeEvent<HTMLInputElement>) => void
  upgradeValue: string
  chipoloValue: string
}
export const ChipoloOffer: React.FC<TProps> = ({
  isUpgradeSelected,
  isChipoloSelected,
  onChange,
  upgradeValue,
  chipoloValue,
}) => {
  const { t } = useTranslation()
  const upgradePrice = useSelector(selectUpgradeAmountToPay)
  const taxAmount = useSelector(selectTaxAmount)
  const currency = useSelector(selectCurrency)

  const upgradePriceWithoutTaxes = useMemo(
    () =>
      getPriceWithoutTaxes({
        price: upgradePrice,
        taxAmount,
      }),
    [taxAmount, upgradePrice],
  )

  return (
    <S.Wrapper>
      <S.Discount>
        <S.Badge>
          <Trans i18nKey="subscriptions.chipolo.paywall.80%" />
        </S.Badge>
        <S.Offer>
          {t('subscriptions.chipolo.paywall.getAll', {
            price: upgradePriceWithoutTaxes,
            currencySymbol: CURRENCY_SYMBOLS[currency],
          })}
        </S.Offer>
      </S.Discount>
      <S.OfferWrapper isSelected={isUpgradeSelected}>
        <Switch
          value={upgradeValue}
          onChange={onChange}
          isChecked={isUpgradeSelected}
        />
        <S.Text isSelected={isUpgradeSelected}>
          <Trans i18nKey="subscriptions.chipolo.paywall.famioPremium" />
        </S.Text>
        <S.Price isSelected={isUpgradeSelected} color="#0a324d">
          {t('subscriptions.chipolo.paywall.price', {
            price: upgradePriceWithoutTaxes,
            currencySymbol: CURRENCY_SYMBOLS[currency],
          })}
        </S.Price>
      </S.OfferWrapper>
      <S.OfferWrapper isSelected={isChipoloSelected}>
        <Switch
          value={chipoloValue}
          onChange={onChange}
          isChecked={isChipoloSelected}
          disabled={!isUpgradeSelected}
        />
        <S.Text isSelected={isChipoloSelected}>
          <Trans i18nKey="subscriptions.chipolo.paywall.chipoloOne" />
        </S.Text>
        <S.Price isSelected={isChipoloSelected} color="#72c675">
          <Trans i18nKey="subscriptions.chipolo.paywall.getFree" />
        </S.Price>
      </S.OfferWrapper>
    </S.Wrapper>
  )
}
