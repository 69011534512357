import React, { useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'

import { setAnswersAction } from 'root-redux/actions/common'
import { sendUserAnswersAction } from 'root-redux/actions/user'

import { useDelayedContinue } from 'hooks/useDelayedContinue'

import { TPageProps } from 'models/common.model'

import { eventLogger } from 'services/eventLogger.service'

import { Option, OptionType } from 'components/Option'
import { StyledOption } from 'components/Option/Option.styles'
import { QuestionButton } from 'components/QuestionButton'

import { goTo } from 'browser-history'
import { FREQUENCY_OPTION_DATA, Frequency } from 'root-constants/common'

import { StyledSosMessage as S } from './SosMessage.styles'
import { PAGE_NAME } from './constants'

export const SosMessageVariant2: React.FC<TPageProps> = ({
  pageId,
  nextPagePath,
  alternativePagePath,
}) => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const { isAnswersDisabled, handleChange, setIsAnswersDisabled } =
    useDelayedContinue()

  const handleContinue = useCallback(
    (answer) => {
      dispatch(
        setAnswersAction({
          answers: answer,
          pageId,
        }),
      )

      dispatch(sendUserAnswersAction())

      eventLogger.logQuestion({
        question: t(`onboarding.sosMessage.question`, { lng: 'en' }),
        answers: answer,
        pageName: PAGE_NAME,
      })

      answer === Frequency.NEVER
        ? goTo(alternativePagePath)
        : goTo(nextPagePath)
    },
    [alternativePagePath, dispatch, nextPagePath, pageId, t],
  )

  const optionProps = useMemo(
    () => ({
      name: pageId,
      type: OptionType.RADIO,
      onChange: (value: string) => {
        handleChange(value, handleContinue)
        setIsAnswersDisabled(true)
      },
    }),
    [handleChange, handleContinue, pageId, setIsAnswersDisabled],
  )

  return (
    <S.Wrapper>
      <S.Column2>
        <S.Title>{t`onboarding.sosMessage.question`}</S.Title>
        <StyledOption.OptionsContainer>
          {FREQUENCY_OPTION_DATA.map(({ value, text }) => (
            <Option
              {...optionProps}
              key={value}
              value={value}
              disabled={isAnswersDisabled}
            >
              <QuestionButton>{t(text)}</QuestionButton>
            </Option>
          ))}
        </StyledOption.OptionsContainer>
      </S.Column2>
    </S.Wrapper>
  )
}
