import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import { useDynamicOBConfig } from 'hooks/useDynamicOBConfig'
import { useNextStep } from 'hooks/useNextStep'
import { useProgressBarList } from 'hooks/useProgressBarList'

import { TPageProps } from 'models/common.model'

import { StyledPreparingFlow as S } from './PreparingFlow.styles'

export const PreparingFlowVariant2: React.FC<TPageProps> = ({
  pageId,
  nextPagePath,
}) => {
  const { t } = useTranslation()

  const { title } = useDynamicOBConfig()

  const handleContinue = useNextStep({
    pageId,
    pageName: '',
    question: '',
    nextPagePath,
  })

  const {
    firstCounterPercent,
    secondCounterPercent,
    thirdCounterPercent,
    isThirdProcessingFinished,
  } = useProgressBarList(true)

  const barsList = [
    {
      listTitle: 'Setting up SOS',
      color: '#199cf3',
      counter: firstCounterPercent,
    },
    {
      listTitle: 'Adding Place Alerts',
      color: '#7879f1',
      counter: secondCounterPercent,
    },
    {
      listTitle: 'Adding Battery Alerts',
      color: '#f24281',
      counter: thirdCounterPercent,
    },
  ]

  useEffect(() => {
    if (!isThirdProcessingFinished) return
    handleContinue('')
  }, [handleContinue, isThirdProcessingFinished])

  return (
    <S.Wrapper>
      <S.Column>
        <S.Title>{title || t`onboarding.preparingFlow.title`}</S.Title>
        <S.ProgressBarsList>
          {barsList.map(({ listTitle, color, counter }) => (
            <S.ProgressContainer key={listTitle}>
              <S.ProgressHeader>
                <S.ProgressTitle>{listTitle}</S.ProgressTitle>
                <S.ProgressPercent>{counter}%</S.ProgressPercent>
              </S.ProgressHeader>
              <S.ProgressBar max={100} value={counter} color={color} />
            </S.ProgressContainer>
          ))}
        </S.ProgressBarsList>
      </S.Column>
    </S.Wrapper>
  )
}
