import React, { useEffect, useRef } from 'react'

import lottie from 'lottie-web/build/player/lottie_light'

import searchNumberAnimation from 'assets/animations/search-number-animation-v2.json'

import { StyledSearchNumberAnimationVariant2 as S } from './SearchNumberAnimationVariant2.styles'

export const SearchNumberAnimationVariant2: React.FC = () => {
  const phoneAnimationRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (phoneAnimationRef.current) {
      lottie.loadAnimation({
        name: 'phoneAnimation',
        container: phoneAnimationRef.current,
        animationData: searchNumberAnimation,
        loop: true,
      })
    }

    return () => lottie.destroy('phoneAnimation')
  }, [])

  return (
    <S.Wrapper>
      <S.AnimationContainer ref={phoneAnimationRef} />
    </S.Wrapper>
  )
}
