import styled from 'styled-components'

import { Color } from 'root-constants/common'

type TProps = {
  background?: string
}

export const StyledHowItWorksVariant1 = {
  Column: styled.div`
    margin-top: 12px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 24px;
    max-width: 327px;
    margin-bottom: 24px;
  `,
  Wrapper: styled.div<TProps>`
    width: 100%;
    display: flex;
    align-items: start;
    justify-content: center;
    flex-direction: column;
    background-color: ${({ background }) => background};
    padding-top: 24px;
    border-radius: 16px;
    gap: 16px;
  `,
  TextWrapper: styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0 24px;
  `,
  Step: styled.div`
    padding: 4px 12px;
    border-radius: 16px;
    background: ${Color.WHITE};
    font-size: 16px;
    font-weight: 700;
    line-height: 22px;
    letter-spacing: 1px;
    text-transform: uppercase;
    margin-bottom: 12px;
    max-width: 85px;
  `,
  Title: styled.h3`
    font-size: 22px;
    font-weight: 800;
    line-height: 30px;
  `,
  Text: styled.p`
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
  `,
  ImageContainer: styled.div`
    max-width: 327px;
    width: 100%;
    aspect-ratio: 327/230;
  `,
}
