import styled, { createGlobalStyle } from 'styled-components'

import { BurgerMenu } from 'components/BurgerMenu'

import { basePageStyles } from 'common-styles'
import { Color, HEADER_HEIGHT } from 'root-constants/common'

export const BackgroundGlobalStyles = createGlobalStyle`
  :root {
    --base-background: linear-gradient(172deg, rgba(222, 211, 255, 0.43) -2.32%, rgba(246, 235, 254, 0.47) 34.71%, rgba(219, 241, 253, 0.35) 93.76%), #FFF;
  }
`

type TCardProps = {
  borderImage: string
}

export const StyledReviewsVariant1 = {
  Wrapper: styled.div`
    ${basePageStyles};
    min-height: 100dvh;
    padding-top: ${HEADER_HEIGHT}px;

    & .swiper-slide:nth-child(even) {
      display: flex;
      justify-content: flex-end;
    }

    & .swiper-wrapper {
      transition-timing-function: linear;
    }
  `,
  BurgerMenu: styled(BurgerMenu)`
    button {
      top: 6px;
      right: 16px;
    }
  `,
  Content: styled.div`
    width: 100%;
    max-width: 375px;
    padding: 0 16px;
    margin-bottom: auto;
  `,
  Card: styled.div<TCardProps>`
    position: relative;
    max-width: 274px;
    display: grid;
    grid-template-columns: 40% 1fr;
    grid-template-rows: auto;
    grid-template-areas:
      'quotes quotes'
      'mainText mainText'
      'additionalText additionalText'
      'avatar name';
    grid-column-gap: 12px;
    justify-content: center;
    align-items: center;
    padding: 15px 14px 18px;

    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-image: ${({ borderImage }) => `url(${borderImage})`};
      background-repeat: no-repeat;
    }
  `,
  Label: styled.div`
    position: absolute;
    top: -19px;
    left: 50%;
    transform: translateX(-50%);
    width: 88px;
    height: 38px;
    padding: 8px 16px;
    border-radius: 24px;
    background-color: ${Color.ACCENT_VIOLET};
    color: ${Color.WHITE};
    text-align: center;
    font-size: 16px;
    font-weight: 700;
    line-height: 22px;
  `,
  QuotesContainer: styled.div`
    grid-area: quotes;
    width: 20px;
    height: 20px;
    margin-bottom: 3px;
  `,
  MainText: styled.p`
    grid-area: mainText;
    margin-bottom: 6px;
    color: #4c8eff;
    text-align: center;
    font-size: 18px;
    font-weight: 700;
    line-height: 22px;
  `,
  AdditionalText: styled.p`
    grid-area: additionalText;
    margin-bottom: 8px;
    text-align: center;
    font-size: 14px;
    font-weight: 400;
    line-height: 19px;
  `,
  AvatarContainer: styled.div`
    grid-area: avatar;
    width: 40px;
    height: 40px;
    justify-self: flex-end;

    img {
      border-radius: 50%;
    }
  `,
  Name: styled.p`
    grid-area: name;
    text-align: left;
    font-size: 16px;
    font-weight: 700;
    line-height: 20px;
  `,
  Title: styled.h2`
    margin-bottom: 24px;
    text-align: center;
    font-size: 22px;
    font-weight: 800;
    line-height: 28px;
  `,
  Footer: styled.footer`
    position: fixed;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
    max-width: 375px;
    min-height: 182px;
    background-color: #f5f9fe;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding: 16px 0 24px;
    z-index: 1;
  `,
}
