import { TrackingData } from 'root-constants/common'
import { PageId } from 'root-constants/pages'

export const PAGE_NAME = 'Data'

export const PAGE_OPTION_VALUES = {
  [PageId.DATA_1]: [
    TrackingData.MOVEMENT_HISTORY,
    TrackingData.LOCATION_NOTIFICATIONS,
    TrackingData.SOS_NOTIFICATIONS,
    TrackingData.BATTERY_ALERTS,
    TrackingData.DRIVING_MODE,
  ],
  [PageId.DATA_2]: [
    TrackingData.SOS_NOTIFICATIONS,
    TrackingData.BATTERY_ALERTS,
    TrackingData.DRIVING_MODE,
    TrackingData.GET_HURTS,
  ],
  [PageId.DATA_3]: [
    TrackingData.MOVEMENT_HISTORY,
    TrackingData.LOCATION_NOTIFICATIONS,
    TrackingData.SOS_NOTIFICATIONS,
    TrackingData.BATTERY_ALERTS,
    TrackingData.DRIVING_MODE,
    TrackingData.PROXIMITY_ALERTS,
  ],
}
