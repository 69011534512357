import React, { useMemo } from 'react'
import { Trans, useTranslation } from 'react-i18next'

import { useNextStep } from 'hooks/useNextStep'
import { useUserData } from 'hooks/useUserData'

import { TPageProps } from 'models/common.model'

import { DesktopProvider } from 'components/DesktopProvider'

import sadGirlImg from 'assets/images/proof-1.webp'
import coupleImg from 'assets/images/proof-4.webp'
import girlDesktopImg from 'assets/images/social-proof-6-girl.jpg'
import manDesktopImg from 'assets/images/social-proof-6-man.jpg'

import { StyledSocialProof as S } from './SocialProofVariant6.styles'

export const SocialProofVariant6: React.FC<TPageProps> = ({
  pageId,
  nextPagePath,
}) => {
  const { t } = useTranslation()

  const { hasConcernsAboutPartnerSafety } = useUserData()

  const pageName = useMemo(
    () =>
      hasConcernsAboutPartnerSafety
        ? 'Social proof cheating 1'
        : 'Social proof cheating 2',
    [hasConcernsAboutPartnerSafety],
  )

  const handleContinue = useNextStep({
    pageId,
    pageName,
    question: '',
    nextPagePath,
  })

  return (
    <DesktopProvider
      imageSrc={hasConcernsAboutPartnerSafety ? girlDesktopImg : manDesktopImg}
    >
      <S.Wrapper>
        <S.ImageContainer>
          <img
            src={hasConcernsAboutPartnerSafety ? sadGirlImg : coupleImg}
            alt="people"
          />
        </S.ImageContainer>
        <S.Column>
          <S.Title>{t`onboarding.socialProof.variant3.title`}</S.Title>
          {hasConcernsAboutPartnerSafety ? (
            <S.Text marginBottom={16} marginBottomDesktop={77}>
              <Trans i18nKey="onboarding.socialProof.variant6.paragraph" />
            </S.Text>
          ) : (
            <>
              <S.Text marginBottom={14} marginBottomDesktop={24}>
                <Trans i18nKey="onboarding.socialProof.variant3.paragraph1" />
              </S.Text>
              <S.Text marginBottom={8} marginBottomDesktop={85}>
                <Trans i18nKey="onboarding.socialProof.variant3.paragraph2" />
              </S.Text>
            </>
          )}
          <S.Note>{t`onboarding.socialProof.note`}</S.Note>
          <S.Button type="button" onClick={() => handleContinue('')}>
            {t`actions.continue`}
          </S.Button>
        </S.Column>
      </S.Wrapper>
    </DesktopProvider>
  )
}
