import appStore from 'assets/images/app-store.png'
import location from 'assets/images/location.png'
import people from 'assets/images/people.png'

export const STATISTICS_DATA = [
  {
    image: appStore,
    amount: 'subscriptions.subscriptionsGoogle.variant4.downloads',
    text: 'subscriptions.subscriptionsGoogle.variant4.downloadsText',
  },
  {
    image: people,
    amount: 'subscriptions.subscriptionsGoogle.variant4.users',
    text: 'subscriptions.subscriptionsGoogle.variant4.usersText',
  },
  {
    image: location,
    amount: 'subscriptions.subscriptionsGoogle.variant4.geoRequests',
    text: 'subscriptions.subscriptionsGoogle.variant4.geoRequestsText',
  },
]
