import { useState } from 'react'

import { PAGE_TRANSITION_DELAY } from 'root-constants/common'

export const useDelayedContinue = (): {
  isAnswersDisabled: boolean
  handleChange: (
    value: string | string[] | null,
    handleContinue: (value: string | string[]) => void,
  ) => void
  setIsAnswersDisabled: (value: boolean) => void
} => {
  const [isAnswersDisabled, setIsAnswersDisabled] = useState<boolean>(false)

  const handleChange = (value, handleContinue) => {
    setTimeout(() => {
      handleContinue(value)
    }, PAGE_TRANSITION_DELAY)
  }

  return { isAnswersDisabled, handleChange, setIsAnswersDisabled }
}
