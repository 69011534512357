import React, { useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { selectLanguage } from 'root-redux/selects/common'

import { useTargetHiddenOnScroll } from 'hooks/useTargetHiddenOnScroll'

import { useTimerForTarget } from 'modules/subscriptions/hooks/useTimerForTarget'

import logo from 'assets/images/logo.webp'

import { Language } from 'root-constants/common'

import { StyledSubheaderWithTimer as S } from './SubheaderWithTimer.styles'

const FIFTEEN_MINUTES_IN_SECONDS = 900

interface IProps {
  elemForComparisonRef: React.RefObject<HTMLElement>
  onClick: () => void
  timeInSeconds?: number
}

export const SubheaderWithTimer: React.FC<IProps> = ({
  elemForComparisonRef,
  onClick,
  timeInSeconds = FIFTEEN_MINUTES_IN_SECONDS,
  ...props
}) => {
  const { t } = useTranslation()
  const timerElementRef = useRef<HTMLDivElement>(null)
  const language = useSelector(selectLanguage)
  const isTargetHidden = useTargetHiddenOnScroll(elemForComparisonRef)

  const { minutes, seconds } = useTimerForTarget(timerElementRef, timeInSeconds)

  return (
    <S.TimerContainer data-is-visible={isTargetHidden} {...props}>
      <S.Logo src={logo} alt="logo" />
      <S.TimerContent>
        <S.Timer ref={timerElementRef}>
          {minutes && seconds && <strong>{`${minutes}:${seconds}`}</strong>}
          <em>{t`subscriptions.timer.time`}</em>
        </S.Timer>
        <S.Button
          onClick={onClick}
          data-is-de-lang={language === Language.DE}
          data-is-fr-lang={language === Language.FR}
          type="button"
        >
          {t`actions.getPlan`}
        </S.Button>
      </S.TimerContent>
    </S.TimerContainer>
  )
}
