import React, { useEffect, useLayoutEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { useLocation } from 'react-router'

import { usePageInfo } from 'providers/PageInfoProvider'

import {
  getSubscriptionListAction,
  setScreenNameAction,
} from 'root-redux/actions/common'

import { useDynamicPaywallConfig } from 'hooks/useDynamicPaywallConfig'
import { usePaywall } from 'hooks/usePaywall'
import { usePurchaseStore } from 'hooks/usePurchaseStore'
import { useUserData } from 'hooks/useUserData'
import { useVatInfo } from 'hooks/useVatInfo'

import { createProductId } from 'helpers/createProductId'

import { TPageProps } from 'models/common.model'

import { ScreenName, eventLogger } from 'services/eventLogger.service'

import { BenefitsVariant2 } from 'modules/subscriptions/components/benefits'
import { Discount } from 'modules/subscriptions/components/discount'
import { TrialPlanItemVariant4 } from 'modules/subscriptions/components/trial/TrialPlanItemVariant4'
import { PLAN_ITEMS } from 'modules/subscriptions/constants'

import { DynamicDiscountBanner } from 'components/DynamicDiscountBanner'
import { Guarantee } from 'components/Guarantee'
import { ReviewsSlider2, ReviewsSliderDynamic } from 'components/ReviewsSlider'
import { Spinner } from 'components/Spinner'

import coupleImg from 'assets/images/subscriptions-couple.webp'

import { goTo } from 'browser-history'
import { SubscriptionTag } from 'root-constants/common'

import { SubheaderWithTimer } from '../../components/common/SubheaderWithTimer'
import { Timer } from '../../components/common/Timer'
import { StyledSubscriptionsVariant5 as S } from './SubscriptionsVariant5.styles'

export const SubscriptionsVariant5: React.FC<TPageProps> = ({
  nextPagePath,
}) => {
  const { t } = useTranslation()
  const { search } = useLocation()
  const dispatch = useDispatch()
  const scrollToElement = useRef<HTMLDivElement>(null)
  const timerTriggerElementRef = useRef(null)

  const { subscriptions, dynamicDiscount } = usePurchaseStore()
  const { currentPaymentPageId } = usePageInfo()
  const { hasPrices, handleSelectSubscription, handleShowPayment } = usePaywall(
    `${currentPaymentPageId}${search}`,
  )
  const { userGender } = useUserData()
  const {
    title,
    subtitle,
    buttonText,
    subscriptionBlockType,
    subscriptionBlockTitle,
    customerReviews,
  } = useDynamicPaywallConfig()
  const hasIncludedVat = useVatInfo()

  useLayoutEffect(() => {
    if (userGender) {
      goTo(nextPagePath)
      return
    }
    dispatch(setScreenNameAction(ScreenName.ONBOARDING))
    dispatch(
      getSubscriptionListAction({
        tags: hasIncludedVat ? SubscriptionTag.TAX : SubscriptionTag.NO_TAX,
      }),
    )
  }, [dispatch, hasIncludedVat, nextPagePath, userGender])

  useEffect(() => {
    if (!subscriptions.length) return

    eventLogger.logSalePageShown({
      productIds: subscriptions.map(
        ({ mainPrices: { periodName, periodQuantity, fullPrice } }) =>
          createProductId({
            periodName,
            periodQuantity,
            price: fullPrice,
          }),
      ),
      screenName: ScreenName.ONBOARDING,
    })
  }, [subscriptions])

  const handleTimerButtonClick = () => {
    scrollToElement.current?.scrollIntoView({
      block: 'center',
      behavior: 'smooth',
    })
  }

  return !hasPrices || userGender ? (
    <Spinner />
  ) : (
    <>
      <SubheaderWithTimer
        elemForComparisonRef={timerTriggerElementRef}
        onClick={handleTimerButtonClick}
      />
      <S.Wrapper>
        <DynamicDiscountBanner />
        <S.Column>
          <S.Title>{title || t('subscriptions.variant5.title')}</S.Title>
          <S.Subtitle>
            {subtitle || t('subscriptions.variant5.subtitle')}
          </S.Subtitle>
          <Timer />
        </S.Column>
        <S.ImageWrapper>
          <img ref={timerTriggerElementRef} src={coupleImg} alt="couple" />
        </S.ImageWrapper>

        <S.Column>
          <BenefitsVariant2
            title={t`subscriptions.benefits.alternativeTitle`}
          />
          <S.SubscriptionsTitle>
            {subscriptionBlockTitle ||
              t('subscriptions.variant5.subscriptionsTitle')}
          </S.SubscriptionsTitle>
          <Discount />
          <S.SelectPlanBlock
            ref={scrollToElement}
            onSelect={handleSelectSubscription}
            SelectPlanItem={
              dynamicDiscount
                ? TrialPlanItemVariant4
                : PLAN_ITEMS[subscriptionBlockType]
            }
          />
          <S.Button onClick={handleShowPayment}>
            {buttonText || t`actions.start`}
          </S.Button>
          <S.SubscriptionDescriptionTrial />
          <Guarantee />
          {customerReviews?.length ? (
            <ReviewsSliderDynamic />
          ) : (
            <ReviewsSlider2 />
          )}
          <S.Button onClick={handleShowPayment}>
            {buttonText || t`actions.start`}
          </S.Button>
          <S.Security />
        </S.Column>
      </S.Wrapper>
    </>
  )
}
