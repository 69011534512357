import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'

import { selectCurrentVariantSteps } from 'root-redux/selects/common'

import { useGetPageInfo } from 'hooks/useGetPageInfo'

import { StyledProgressBar as S } from './ProgressBar.styles'

export const ProgressBar: React.FC = () => {
  const steps = useSelector(selectCurrentVariantSteps)
  const { currentPageId, hasProgressbar } = useGetPageInfo()

  const pagesWithProgressBar = useMemo(
    () => steps.filter(({ hasProgressBar }) => hasProgressBar),
    [steps],
  )

  const currentPageIndex = useMemo(
    () => pagesWithProgressBar.findIndex(({ id }) => id === currentPageId),
    [currentPageId, pagesWithProgressBar],
  )

  return !hasProgressbar ? null : (
    <S.Wrapper>
      <S.Progress
        max={pagesWithProgressBar.length + 1}
        value={currentPageIndex + 1}
      />
    </S.Wrapper>
  )
}
