import React, {
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'
import YouTube from 'react-youtube'

import { useFeatureIsOn, useFeatureValue } from '@growthbook/growthbook-react'
import { usePageInfo } from 'providers/PageInfoProvider'

import { useDynamicPaywallConfig } from 'hooks/common/useDynamicPaywallConfig'
import { useGetPrices } from 'hooks/common/useGetPrices'
import { usePaywall } from 'hooks/common/usePaywall'
import { usePurchaseStore } from 'hooks/common/usePurchaseStore'
import { useScrollToAnchor } from 'hooks/ui/useScrollToAnchor'
import { useViewportValue } from 'hooks/ui/useViewportValue'

import { createProductId } from 'helpers/createProductId'

import { TPageProps } from 'models/common.model'

import { ScreenName, eventLogger } from 'services/eventLogger.service'

import {
  BenefitsVariant1,
  BenefitsVariant2,
  BenefitsVariant3,
} from 'modules/subscriptions/components/google/Benefits'
import { PhoneForm } from 'modules/subscriptions/components/google/PhoneForm'
import { StepsVariant1 } from 'modules/subscriptions/components/google/Steps'
import { SubscriptionsBlockVariant1 } from 'modules/subscriptions/components/google/SubscriptionsBlockVariant1'
import {
  UNIQUE_VIEWS_NUMBER,
  YOUTUBE_VIDEO_ID,
} from 'modules/subscriptions/constants'

import { DynamicDiscountBanner } from 'components/DynamicDiscountBanner'
import { Security } from 'components/Security'
import { Spinner } from 'components/Spinner'

import { goTo } from 'browser-history'
import {
  CDN_FOLDER_LINK,
  GOOGLE_9_TEST_SEGMENTS,
  Images,
  SubscriptionTag,
} from 'root-constants/common'
import { CustomPageId, PageId } from 'root-constants/pages'

import { StyledSubscriptionsGoogleVariant2 as S } from './SubscriptionsGoogleVariant2.styles'

export const SubscriptionsGoogleVariant2: React.FC<TPageProps> = ({
  nextPagePath,
}) => {
  const { t } = useTranslation()
  const { search } = useLocation()
  const scrollToElement = useRef<HTMLHeadingElement>(null)

  const [phoneNumber, setPhoneNumber] = useState('')
  const [videoStartCounter, setVideoStartCounter] = useState(0)

  const alternativePlanItem = useFeatureValue(
    'abtest_car_1007_checkout',
    GOOGLE_9_TEST_SEGMENTS.A_SEGMENT,
  )

  const hasAlternativeCanadianPrice = useFeatureIsOn('abtest_car_1067_can')

  const hasAlternativeBrazilianPrice = useFeatureIsOn('abtest_car_1067_brl')

  const hasAlternativePlanItem =
    alternativePlanItem !== GOOGLE_9_TEST_SEGMENTS.A_SEGMENT

  const hasAlternativeRoundedPlanItem =
    hasAlternativeCanadianPrice || hasAlternativeBrazilianPrice

  const priceTag = useMemo(() => {
    if (hasAlternativePlanItem) {
      return SubscriptionTag.NO_TAX_NEW_PRICE
    }
    if (hasAlternativeRoundedPlanItem) {
      return SubscriptionTag.NO_TAX_NEW_ROUND_PRICE
    }
    return SubscriptionTag.NO_TAX
  }, [hasAlternativeRoundedPlanItem, hasAlternativePlanItem])

  const { hasUserEmail, language, subscriptions } = usePurchaseStore()
  const { title, subtitle, buttonText } = useDynamicPaywallConfig()
  const { hasPrices } = usePaywall(nextPagePath)
  const { currentPaymentPageId } = usePageInfo()

  useScrollToAnchor(hasPrices)
  useViewportValue()
  useGetPrices({
    tags: priceTag,
  })

  useLayoutEffect(() => {
    if (hasUserEmail) {
      goTo({
        pathname: hasAlternativePlanItem
          ? PageId.PAYMENT_5
          : currentPaymentPageId,
        search,
      })
    }
  }, [currentPaymentPageId, hasAlternativePlanItem, hasUserEmail, search])

  useEffect(() => {
    if (!subscriptions.length) return

    eventLogger.logSalePageShown({
      productIds: subscriptions.map(
        ({ mainPrices: { periodName, periodQuantity, fullPrice } }) =>
          createProductId({
            periodName,
            periodQuantity,
            price: fullPrice,
          }),
      ),
      screenName: ScreenName.ONBOARDING,
    })
  }, [subscriptions])

  const handleButtonScroll = () => {
    scrollToElement.current?.scrollIntoView({
      block: 'start',
      behavior: 'smooth',
    })
  }

  const handleVideoClick = () => {
    setVideoStartCounter((prevState) => prevState + 1)
    if (videoStartCounter < UNIQUE_VIEWS_NUMBER) {
      eventLogger.logVideoPlayStarted()
    }
  }

  return !hasPrices ? (
    <Spinner />
  ) : (
    <S.Wrapper id="1">
      <S.BurgerMenu />
      <DynamicDiscountBanner />
      <S.PhoneNumberWrapper>
        <S.WideColumn>
          <S.Title ref={scrollToElement}>
            {title || (
              <Trans i18nKey="subscriptions.subscriptionsGoogle.variant1.getLocation" />
            )}
          </S.Title>
          <S.Text>
            {subtitle || (
              <Trans i18nKey="subscriptions.subscriptionsGoogle.variant1.allYouNeed" />
            )}
          </S.Text>
          <PhoneForm
            buttonText={
              buttonText ||
              'subscriptions.subscriptionsGoogle.variant1.locateTheNumber'
            }
            pageId={CustomPageId.PHONE_NUMBER}
            phoneNumber={phoneNumber}
            setPhoneNumber={setPhoneNumber}
          />
          <S.PhoneImageContainer>
            <img
              src={`${CDN_FOLDER_LINK}${Images.GOOGLE_SUBS_PHONE}_${language}.jpg`}
              alt="phone"
            />
          </S.PhoneImageContainer>
        </S.WideColumn>
        <S.DesktopImageContainer>
          <img
            src={`${CDN_FOLDER_LINK}${Images.GOOGLE_SUBS_PHONE_DESKTOP}_${language}.jpg`}
            alt="phone"
          />
        </S.DesktopImageContainer>
      </S.PhoneNumberWrapper>
      <BenefitsVariant1 />
      <S.Column>
        <BenefitsVariant2 />
        <BenefitsVariant3 />
        <S.Button type="button" onClick={handleButtonScroll}>
          {t`subscriptions.subscriptionsGoogle.variant1.subscribe`}
        </S.Button>
      </S.Column>
      <S.VideoContainer>
        <YouTube videoId={YOUTUBE_VIDEO_ID} onPlay={handleVideoClick} />
      </S.VideoContainer>
      <S.Column id="3">
        <S.CommonTitle>{t`onboarding.introGoogle4.howItWorks`}</S.CommonTitle>
        <StepsVariant1 />
      </S.Column>
      <S.PhoneFormWrapper>
        <S.EmailSubtitle id="4">{t`onboarding.introGoogle4.steps.enterThePhone`}</S.EmailSubtitle>
        <PhoneForm
          buttonText="onboarding.introGoogle4.phoneButton"
          pageId={CustomPageId.PHONE_NUMBER}
          phoneNumber={phoneNumber}
          setPhoneNumber={setPhoneNumber}
        />
      </S.PhoneFormWrapper>
      <SubscriptionsBlockVariant1 ref={scrollToElement} />
      <S.SecurityColumn>
        <Security />
      </S.SecurityColumn>
    </S.Wrapper>
  )
}
