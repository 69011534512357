import React, { useEffect } from 'react'

import { useFeatureIsOn } from '@growthbook/growthbook-react'

import { useCookieConsentAnswer } from 'hooks/useCookieConsentAnswer'
import { useGetPrices } from 'hooks/useGetPrices'
import { usePaywall } from 'hooks/usePaywall'
import { useProductId } from 'hooks/useProductId'
import { usePurchaseStore } from 'hooks/usePurchaseStore'

import { TPageProps } from 'models/common.model'

import { eventLogger } from 'services/eventLogger.service'
import { googleAnalyticsLogger } from 'services/googleAnalytics.service'

import { PaymentFlowsGoogleVariant4 } from 'modules/payment/components/PaymentFlowsGoogle'

import { Spinner } from 'components/Spinner'

import { PagePath, SubscriptionTag } from 'root-constants/common'

import { StyledPaymentVariant6 as S } from './PaymentVariant6.styles'

export const PaymentVariant6: React.FC<TPageProps> = () => {
  const {
    email,
    cohort,
    uuid,
    hasSubscription,
    subscriptions,
    selectedSubscription,
    isPaymentFlowsShown,
    stripeAccountId,
    stripeAccountName,
    screenName,
    threeDSecureIframeUrl,
  } = usePurchaseStore()

  const productId = useProductId()
  const { hasPrices } = usePaywall('')
  const { isPersonalDataAllowed } = useCookieConsentAnswer()
  const hasAlternativePrice = useFeatureIsOn('abtest_car_843_newprice')

  useGetPrices({
    tags: hasAlternativePrice
      ? SubscriptionTag.NO_TAX_NEW_PRICE
      : SubscriptionTag.NO_TAX,
  })

  useEffect(() => {
    if (!selectedSubscription) return

    window.fbq('track', 'InitiateCheckout', {}, { eventID: uuid })
    googleAnalyticsLogger.logPageView(`${PagePath.SUBSCRIPTIONS}/${cohort}`)
    googleAnalyticsLogger.logCheckoutStarted(subscriptions)
  }, [cohort, selectedSubscription, uuid, subscriptions])

  useEffect(() => {
    if (hasSubscription || !selectedSubscription) return

    eventLogger.logPurchaseShown({
      screenName,
      productId,
      stripeAccountName,
      stripeAccountId,
    })
    window.ttq.identify({ email: isPersonalDataAllowed ? email : '' })
    window.ttq.track('AddToCart')
  }, [
    email,
    hasSubscription,
    isPersonalDataAllowed,
    productId,
    screenName,
    selectedSubscription,
    stripeAccountId,
    stripeAccountName,
  ])

  return (
    <S.Wrapper>
      {threeDSecureIframeUrl ? (
        <S.ThreeDSecureIframe title="3DSecure" src={threeDSecureIframeUrl} />
      ) : (
        <>
          <S.StripePaymentProcessing />
          <S.Content>
            <PaymentFlowsGoogleVariant4 />
          </S.Content>
        </>
      )}
      {(!isPaymentFlowsShown || !hasPrices) && <Spinner />}
    </S.Wrapper>
  )
}
