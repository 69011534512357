import styled, { keyframes } from 'styled-components'

import {
  baseColumnStyles,
  basePageStyles,
  baseTitleStyles,
} from 'common-styles'
import { HEADER_HEIGHT } from 'root-constants/common'

const fadein = keyframes`
  0% { opacity: 0; }
  100% { opacity: 1; }
`

export const StyledPreparingFlowVariant4 = {
  Wrapper: styled.div`
    ${basePageStyles};
    padding-top: ${HEADER_HEIGHT}px;
  `,
  Column: styled.div`
    ${baseColumnStyles};
    padding: 40px 0;
  `,
  Title: styled.h1`
    ${baseTitleStyles};
    margin-bottom: 32px;
  `,
  Text: styled.p`
    ${baseTitleStyles};
    margin-bottom: 32px;
    animation: ${fadein} 0.5s ease-in-out;
  `,
  ProgressBarContainer: styled.div`
    width: 180px;
    height: 180px;
    margin-bottom: 32px;
  `,
  ProgressValue: styled.p`
    padding-bottom: 18px;
    text-align: center;
    font-size: 32px;
    font-weight: 800;
    line-height: 44px;
  `,
}
