import { useEffect } from 'react'
import { useDispatch } from 'react-redux'

import { getVariantAction } from 'root-redux/actions/common'

import { getIsFamylocateDomain } from 'helpers/getIsFamylocateDomain'

import { Cohort } from 'root-constants/common'

export const useSetVariant = (): void => {
  const dispatch = useDispatch()
  const isFamylocateDomain = getIsFamylocateDomain()

  const URLParams = new URLSearchParams(document.location.search)
  const cohortFromUrl = URLParams.get('cohort')
  const defaultCohort = isFamylocateDomain
    ? Cohort.FAMYLOCATE_V1
    : Cohort.MAIN_4

  useEffect(() => {
    dispatch(getVariantAction({ cohort: cohortFromUrl || defaultCohort }))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
}
