import styled, { css } from 'styled-components'

import { Color, MediaBreakpoint } from 'root-constants/common'

type TProps = {
  isDesktop?: boolean
}

const progressValueStyles = css`
  border-radius: 3px;
  transition: all 0.2s ease-out;
`

export const StyledProgressBarListModal = {
  ModalWrapper: styled.div`
    display: flex;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 10;
    background-color: rgba(0, 0, 0, 0.35);
    overflow-y: scroll;
    padding: 40px 0;
  `,
  ProgressBarWrapper: styled.div<TProps>`
    width: 288px;
    display: flex;
    flex-direction: column;
    gap: 32px;
    padding: 24px;
    border-radius: 16px;
    background: #fff;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    @media (min-width: ${MediaBreakpoint.LAPTOP}px) {
      width: ${({ isDesktop }) => (isDesktop ? 517 : 288)}px;
    }
  `,
  ProgressBarsList: styled.ul``,
  ProgressContainer: styled.li`
    width: 100%;
    margin-bottom: 32px;
  `,
  ProgressHeader: styled.header`
    margin-bottom: 12px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  `,
  ProgressTitle: styled.span`
    color: #0a324d;
    font-size: 16px;
    font-weight: 700;
    line-height: 22px;
  `,
  ProgressPercent: styled.span`
    color: ${Color.LIGHT_TEXT};
    font-size: 13px;
    font-weight: 400;
    line-height: 18px;
  `,
  ProgressBar: styled.progress<{ color: string }>`
    width: 100%;
    height: 6px;
    appearance: none;
    border: none;
    display: block;
    border-radius: 10px;
    overflow: hidden;
    background-color: #eff5f7;

    &::-webkit-progress-bar {
      background-color: #eff5f7;
    }
    &::-webkit-progress-value {
      ${progressValueStyles};
      background-color: ${({ color }) => color};
    }
    &::-moz-progress-bar {
      ${progressValueStyles};
      background-color: ${({ color }) => color};
    }
    &::-ms-fill {
      ${progressValueStyles};
      background-color: ${({ color }) => color};
    }
  `,
}
