import { DefaultTheme } from 'styled-components'

import checkVioletIcon from 'assets/images/check-violet.svg'
import checkYellowIcon from 'assets/images/check-yellow.svg'
import checkboxViolet from 'assets/images/checkbox-violet.svg'
import checkboxYellow from 'assets/images/checkbox-yellow.svg'
import closeButtonBlack from 'assets/images/close-button-black.svg'
import closeButtonWhite from 'assets/images/close-button-white.svg'
import closeButtonGray from 'assets/images/close-icon.svg'
import closedEyeIcon from 'assets/images/closed-eye.svg'
import famyLocateHeaderLogo from 'assets/images/famylocate-header-logo.png'
import headerLogo from 'assets/images/logo.png'
import openedEyeIcon from 'assets/images/opened-eye.svg'

import { HEADER_HEIGHT } from 'root-constants/common'

export const COMMON_THEME: DefaultTheme = {
  common: {
    inputBorder: 'transparent',
    headerBorder: '1px solid #ccdce5',
    progressTitleFontWeight: 800,
    priceFontWeight: 800,
    progressTitleFontSize: '14px',
    progressPercentFontSize: '11px',
    subtitleFontSize: '16px',
    autoCompleteButtonBorder: '1px solid #00000019',
    filter: 'drop-shadow(0px 7px 20px #c8d4d8)',
    headerPadding: '13px 0 12px',
    burgerLinksFontSize: '22px',
    burgerLinksFontWeight: '900',
    burgerLinksLineHeight: '30px',
    buttonLineHeight: '62px',
    inputBorderRadius: '12px',
    stripeInputFormMarginBottom: '8px',
  },
  colors: {
    activeBackground: '#6336ff',
    activeTextColor: '#fff',
    defaultTextColor: '#0a324d',
    defaultBackground: '#eff5f7',
    disabledButton: 'rgba(99, 54, 255, 0.3)',
    primaryText: '#0a324d',
    secondaryText: '#7b8898',
    tertiaryText: '#0a324d',
    burgerBackground: '#fff',
    burgerLinksColor: '#153e83',
    progressPercentColor: '#7b8898',
    progressBarBackground: '#eff5f7',
    inputPlaceholder: '#bcc9d0',
    inputBackground: '#eff5f7',
    timerBackground: '#0a324d',
    disclaimerText: '#7b8898',
    guaranteeBackground: '#eff5f7',
    guaranteeText: 'rgba(10, 50, 77, 0.7)',
    paymentModalBackground: '#fff',
    priceColor: '#199cf3',
    emailTipsBackground: '#fff',
    locationPopupBackground: '#fff',
    defaultProgressBarBackground: '#eff1f4',
    errorTextColor: '#ff0000',
    footerLinkColor: '#bcc9d0',
    accountTitleColor: '#0a324d',
    modalTextColor: '#0a324d',
    disabledTextColor: '#fff',
    phoneNumberLabelBackground: '#0a324d',
    headerBackground: '#fff',
    downloadLabelBackground: '#eff5f7',
  },
  arrangements: {
    columnVerticalAlignment: 'flex-start',
    questionTextAlignment: 'start',
  },
  dimensions: {
    columnHeight: 'auto',
    flexGrow: 0,
    headerLogoHeight: 30,
    buttonHeight: '62px',
  },
  images: {
    checkboxIcon: checkboxViolet,
    openedEyeIcon,
    closedEyeIcon,
    checkIcon: checkVioletIcon,
    headerLogo,
    closeBurgerMenuButton: closeButtonGray,
  },
}

export const SAFETY_THEME: DefaultTheme = {
  common: {
    inputBorder: 'transparent',
    headerBorder: '1px solid #ccdce5',
    progressTitleFontWeight: 800,
    priceFontWeight: 800,
    progressTitleFontSize: '14px',
    progressPercentFontSize: '11px',
    subtitleFontSize: '16px',
    autoCompleteButtonBorder: '1px solid #00000019',
    filter: 'drop-shadow(0px 7px 20px #c8d4d8)',
    headerPadding: '13px 0 12px',
    burgerLinksFontSize: '22px',
    burgerLinksFontWeight: '900',
    burgerLinksLineHeight: '30px',
    buttonLineHeight: '62px',
    inputBorderRadius: '12px',
    stripeInputFormMarginBottom: '8px',
  },
  colors: {
    activeBackground: '#6336ff',
    activeTextColor: '#fff',
    defaultTextColor: '#0a324d',
    defaultBackground: '#eff5f7',
    disabledButton: 'rgba(99, 54, 255, 0.3)',
    primaryText: '#0a324d',
    secondaryText: '#7b8898',
    tertiaryText: '#0a324d',
    burgerBackground: '#fff',
    burgerLinksColor: '#153e83',
    progressPercentColor: '#7b8898',
    progressBarBackground: '#eff5f7',
    inputPlaceholder: '#bcc9d0',
    inputBackground: '#eff5f7',
    timerBackground: '#0a324d',
    disclaimerText: '#7b8898',
    guaranteeBackground: '#eff5f7',
    guaranteeText: 'rgba(10, 50, 77, 0.7)',
    paymentModalBackground: '#fff',
    priceColor: '#199cf3',
    emailTipsBackground: '#fff',
    errorTextColor: '#ff0000',
    footerLinkColor: '#bcc9d0',
    accountTitleColor: '#0a324d',
    modalTextColor: '#0a324d',
    disabledTextColor: '#fff',
    phoneNumberLabelBackground: '#0a324d',
    headerBackground: '#fff',
    downloadLabelBackground: '#eff5f7',
  },
  arrangements: {
    columnVerticalAlignment: 'space-between',
    questionTextAlignment: 'center',
  },
  dimensions: {
    columnHeight: `calc(100svh - ${HEADER_HEIGHT}px)`,
    flexGrow: 1,
    headerLogoHeight: 30,
    buttonHeight: '62px',
  },
  images: {
    checkboxIcon: checkboxViolet,
    openedEyeIcon,
    closedEyeIcon,
    headerLogo,
    closeBurgerMenuButton: closeButtonGray,
  },
}

export const FAMYLOCATE_THEME: DefaultTheme = {
  ...COMMON_THEME,
  common: {
    ...COMMON_THEME.common,
    headerBorder: '1px solid rgba(255, 255, 255, 0.10)',
    headerPadding: '8px 0',
    burgerLinksFontSize: '20px',
    burgerLinksFontWeight: '600',
    burgerLinksLineHeight: '24px',
    buttonLineHeight: '56px',
    inputBorderRadius: '30px',
    stripeInputFormMarginBottom: '12px',
  },
  colors: {
    ...COMMON_THEME.colors,
    locationPopupBackground:
      'linear-gradient(180deg, #574FCF 2.34%, #3734A6 100%)',
    activeBackground: '#ffd651',
    disabledButton: 'rgba(255, 214, 81, 0.5)',
    defaultTextColor: '#fff',
    defaultProgressBarBackground: 'rgba(255, 255, 255, 0.10)',
    activeTextColor: '#35319f',
    errorTextColor: '#fc5774',
    burgerBackground: '#e8f1ff',
    burgerLinksColor: '#2d3240',
    tertiaryText: '#fff',
    inputPlaceholder: '#616a77',
    footerLinkColor: '#dfecff',
    secondaryText: '#bcc9d0',
    defaultBackground: 'transparent',
    accountTitleColor: '#fff',
    modalTextColor: '#0a324d',
    headerBackground: 'none',
    downloadLabelBackground: 'transparent',
  },
  dimensions: {
    ...COMMON_THEME.dimensions,
    headerLogoHeight: 36,
    buttonHeight: '56px',
  },
  images: {
    ...COMMON_THEME.images,
    checkboxIcon: checkboxYellow,
    checkIcon: checkYellowIcon,
    headerLogo: famyLocateHeaderLogo,
    closeBurgerMenuButton: closeButtonWhite,
  },
}

export const DARK_THEME: DefaultTheme = {
  ...COMMON_THEME,
  colors: {
    ...COMMON_THEME.colors,
    burgerBackground: '#1c1c20',
    burgerLinksColor: '#fff',
    activeBackground: 'linear-gradient(270deg, #8F42CB 0%, #693DFF 100%)',
    disabledButton: 'linear-gradient(270deg, #3E2854 0%, #332663 100%)',
    disabledTextColor: '#606063',
    phoneNumberLabelBackground: '#010101',
  },
  common: {
    ...COMMON_THEME.common,
    burgerLinksFontSize: '20px',
    burgerLinksFontWeight: '600',
    burgerLinksLineHeight: '24px',
  },
  images: {
    ...COMMON_THEME.images,
    closeBurgerMenuButton: closeButtonBlack,
  },
}
export const MAIN_SAFETY_THEME: DefaultTheme = {
  ...COMMON_THEME,
  colors: {
    ...COMMON_THEME.colors,
    defaultBackground: '#fff',
  },
}
