import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { useNextStep } from 'hooks/useNextStep'

import { TPageProps } from 'models/common.model'

import { Button } from 'components/Button'
import { FixedContainer } from 'components/FixedContainer'
import { Option, OptionType } from 'components/Option'
import { StyledOption } from 'components/Option/Option.styles'
import { QuestionButton } from 'components/QuestionButton'

import { Language } from 'root-constants/common'

import { StyledBiggestFear as S } from './BiggestFear.styles'
import { OPTION_VALUES, PAGE_NAME } from './constants'

export const BiggestFearVariant1: React.FC<TPageProps> = ({
  pageId,
  nextPagePath,
}) => {
  const { t } = useTranslation()
  const [answers, setAnswers] = useState<string[]>([])

  const handleContinue = useNextStep({
    pageId,
    pageName: PAGE_NAME,
    question: t('onboarding.biggestFear.question', { lng: Language.EN }),
    nextPagePath,
  })

  const optionProps = {
    name: pageId,
    type: OptionType.CHECKBOX,
    onChange: (value: string, isChecked: boolean) => {
      if (isChecked) {
        setAnswers([...answers, value])
      } else {
        setAnswers(answers.filter((item) => item !== value))
      }
    },
  }

  return (
    <S.Wrapper>
      <S.Column>
        <S.Title>{t`onboarding.biggestFear.question`}</S.Title>
        <StyledOption.OptionsContainer paddingBottom={4}>
          <Option
            {...optionProps}
            value={OPTION_VALUES.ACCIDENT}
            checked={answers.includes(OPTION_VALUES.ACCIDENT)}
          >
            <QuestionButton hasCheckboxIcon>
              {t`onboarding.biggestFear.answers.accident`}
            </QuestionButton>
          </Option>

          <Option
            {...optionProps}
            value={OPTION_VALUES.UNFAMILIAR_PLACES}
            checked={answers.includes(OPTION_VALUES.UNFAMILIAR_PLACES)}
          >
            <QuestionButton hasCheckboxIcon>
              {t`onboarding.biggestFear.answers.unfamiliarPlaces`}
            </QuestionButton>
          </Option>

          <Option
            {...optionProps}
            value={OPTION_VALUES.DRUGS}
            checked={answers.includes(OPTION_VALUES.DRUGS)}
          >
            <QuestionButton hasCheckboxIcon>
              {t`onboarding.biggestFear.answers.drugs`}
            </QuestionButton>
          </Option>

          <Option
            {...optionProps}
            value={OPTION_VALUES.CRIME}
            checked={answers.includes(OPTION_VALUES.CRIME)}
          >
            <QuestionButton hasCheckboxIcon>
              {t`onboarding.biggestFear.answers.crime`}
            </QuestionButton>
          </Option>
        </StyledOption.OptionsContainer>
        <FixedContainer>
          <Button
            onClick={() => handleContinue(answers)}
            disabled={!answers.length}
          >
            {t`actions.next`}
          </Button>
        </FixedContainer>
      </S.Column>
    </S.Wrapper>
  )
}
