import { useSelector } from 'react-redux'

import { selectActionList } from 'root-redux/selects/common'

import { SET_PRIMER_PAYMENT_FORM_IS_LOADING } from 'modules/payment/redux/actions/primer'

import { selectIsPaymentFlowsShown } from '../redux/selects'

export const useIsCheckoutReady = () => {
  const isPaymentFlowsShown = useSelector(selectIsPaymentFlowsShown)
  const fetchingActionsList = useSelector(selectActionList)

  return (
    isPaymentFlowsShown &&
    !fetchingActionsList.includes(SET_PRIMER_PAYMENT_FORM_IS_LOADING)
  )
}
