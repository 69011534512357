import styled from 'styled-components'

import { SelectPlanBlockVariant1 } from 'modules/subscriptions/components/common/SelectPlanBlockVariant1'
import { SubscriptionDescription } from 'modules/subscriptions/components/common/SubscriptionDescription'
import { TImageWrapperProps } from 'modules/subscriptions/types'

import { Button } from 'components/Button'
import { Guarantee } from 'components/Guarantee'
import { Security } from 'components/Security'

import bgImg from 'assets/images/subscriptions-bg.svg'
import secondaryBgSvg from 'assets/images/subscriptions-multi-bg.svg'

import {
  baseColumnStyles,
  basePageStyles,
  baseTitleStyles,
} from 'common-styles'
import { BASE_COLUMN_WIDTH, MediaBreakpoint } from 'root-constants/common'

import { SubheaderWithTimer } from '../../components/common/SubheaderWithTimer'
import { Timer } from '../../components/common/Timer'

type TProps = {
  marginBottom?: number
  laptopMarginBottom?: number
  bgImgLink?: string
}
export const StyledSubscriptionsVariant2 = {
  Wrapper: styled.div<TProps>`
    ${basePageStyles};
    min-height: 100%;
    padding-top: 96px;
    overflow-x: hidden;

    @media (min-width: ${MediaBreakpoint.LAPTOP}px) {
      padding-top: 505px;
      background-image: url(${({ bgImgLink }) => bgImgLink});
      background-size: 100vw auto;
      background-position: top 56px center;
      background-repeat: no-repeat;
    }
  `,
  Column: styled.div<TProps>`
    ${baseColumnStyles};
    margin-bottom: ${({ marginBottom }) => marginBottom || 0}px;

    &[data-has-bg='true'] {
      position: relative;

      ::before {
        content: '';
        display: block;
        width: 100vw;
        aspect-ratio: 1/1;
        background-image: url(${bgImg});
        background-size: cover;
        background-repeat: no-repeat;
        position: absolute;
        z-index: -1;
      }
    }

    @media (min-width: ${MediaBreakpoint.LAPTOP}px) {
      width: 495px;

      &[data-has-bg='true']:before {
        background-image: none;
      }

      &[data-has-full-width='true'] {
        width: 100vw;
      }
    }
  `,
  ImageWrapper: styled.div<TImageWrapperProps>`
    width: 100%;
    aspect-ratio: ${({ aspectRatio }) => aspectRatio};
    max-height: 302px;

    @media (min-width: ${MediaBreakpoint.MAX_PHONE}px) {
      width: ${BASE_COLUMN_WIDTH}px;
    }

    @media (min-width: ${MediaBreakpoint.LAPTOP}px) {
      width: 1px;
      height: 1px;
      margin: -1px;
      border: 0;
      padding: 0;
      clip: rect(0 0 0 0);
      overflow: hidden;
    }
  `,
  Sharing: styled.p`
    width: 100%;
    font-size: 18px;
    line-height: 24px;
    text-align: center;
    padding: 19px 0;
    margin-bottom: 37px;
    background-image: linear-gradient(
      266deg,
      rgba(225, 204, 252, 0.5) 45%,
      rgba(239, 208, 226, 0.5) 100%
    );
    border-radius: 16px;

    strong {
      font-weight: 800;
    }

    @media (min-width: ${MediaBreakpoint.LAPTOP}px) {
      width: 385px;
      font-size: 22px;
    }
  `,
  Title: styled.h1`
    font-size: 32px;
    line-height: 44px;
    font-weight: 800;
    text-align: center;
    margin-bottom: 12px;

    br {
      display: none;
    }

    @media (min-width: ${MediaBreakpoint.LAPTOP}px) {
      font-size: 72px;
      line-height: 98px;
      padding: 0 50px;

      br {
        display: block;
      }
    }
  `,
  Text: styled.span`
    font-size: 18px;
    font-weight: 600;
    line-height: 24px;
    text-align: center;
    margin-bottom: 28px;

    strong {
      font-weight: 600;
    }

    @media (min-width: ${MediaBreakpoint.LAPTOP}px) {
      font-weight: 500;
      font-size: 24px;
      line-height: 33px;
      margin-bottom: 50px;

      strong {
        font-weight: 800;
      }
    }
  `,
  Subtitle: styled.h2<TProps>`
    ${baseTitleStyles};
    margin: 0 0 40px;

    @media (min-width: ${MediaBreakpoint.LAPTOP}px) {
      font-weight: 800;
      font-size: 38px;
      line-height: 28px;
      margin: 0 0 62px;
    }
  `,
  StyleContainer: styled.div`
    @media (min-width: ${MediaBreakpoint.LAPTOP}px) {
      width: 100vw;
      background-color: #eff5f7;
      background-image: url(${secondaryBgSvg});
      background-size: contain;
      padding: 49px 0 26px;
      margin-bottom: 80px;
    }
  `,
  Timer: styled(Timer)`
    @media (min-width: ${MediaBreakpoint.LAPTOP}px) {
      width: 377px;
      border-radius: 100px;

      div {
        padding: 0 37px;
        font-size: 24px;
      }
    }
  `,
  Button: styled(Button)<TProps>`
    height: 56px;
    line-height: 56px;
    margin-bottom: ${({ marginBottom }) => marginBottom}px;

    @media (min-width: ${MediaBreakpoint.LAPTOP}px) {
      height: 76px;
      width: 391px;
      font-size: 24px;
      line-height: 76px;
      border-radius: 100px;
      margin-bottom: ${({ laptopMarginBottom }) => laptopMarginBottom}px;
    }
  `,
  SubheaderWithTimer: styled(SubheaderWithTimer)`
    @media (min-width: ${MediaBreakpoint.LAPTOP}px) {
      height: 56px;
      padding: 0 197px;
      justify-content: space-between;

      img {
        display: block;
        width: 100px;
      }

      button {
        margin: 0;
      }
    }
  `,
  SelectPlanBlock: styled(SelectPlanBlockVariant1)`
    margin-bottom: 20px;

    @media (min-width: ${MediaBreakpoint.LAPTOP}px) {
      display: flex;
      gap: 20px;
      justify-content: center;
      margin-bottom: 56px;

      label {
        width: 335px;
      }
    }
  `,
  SubscriptionDescription: styled(SubscriptionDescription)<TProps>`
    margin: 0 auto 32px;

    @media (min-width: ${MediaBreakpoint.LAPTOP}px) {
      margin-bottom: 0;
      max-width: 932px;
      font-size: 14px;
    }
  `,
  Guarantee: styled(Guarantee)`
    margin-bottom: 36px;

    @media (min-width: ${MediaBreakpoint.LAPTOP}px) {
      font-size: 14px;
      background-position: calc(100% - 40px) calc(100% - 14px);
      margin-bottom: 54px;
    }
  `,
  Security: styled(Security)`
    @media (min-width: ${MediaBreakpoint.LAPTOP}px) {
      padding: 22px 53px 0;

      li {
        font-size: 14px;
        margin-bottom: 52px;
      }

      h4 {
        font-size: 20px;
      }
    }
  `,
}
