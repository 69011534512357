import styled from 'styled-components'

import { baseColumnStyles, basePageStyles } from 'common-styles'
import { HEADER_HEIGHT, MediaBreakpoint } from 'root-constants/common'

export const StyledStayConnectedVariant2 = {
  Wrapper: styled.div`
    ${basePageStyles};
    padding-top: ${HEADER_HEIGHT}px;
    min-height: var(--full-height, 100vh);
    justify-content: space-between;
  `,
  Column: styled.div`
    ${baseColumnStyles};
    padding-bottom: 35px;
    text-align: center;
  `,
  ImageContainer: styled.div`
    width: 100%;
    max-width: ${MediaBreakpoint.MAX_PHONE}px;
    aspect-ratio: 375/300;

    @media (min-width: ${MediaBreakpoint.MAX_PHONE}px) {
      max-width: 375px;
    }
  `,
  Title: styled.h1`
    font-weight: 700;
    font-size: 24px;
    line-height: 33px;
    margin-bottom: 8px;

    @media (min-width: ${MediaBreakpoint.DEFAULT_PHONE}px) {
      margin: 0 -15px 8px;
    }
  `,
  Note: styled.p`
    font-size: 18px;
    line-height: 25px;
    margin-bottom: 32px;

    strong {
      font-weight: 700;
    }
  `,
}
