import React, { ButtonHTMLAttributes } from 'react'

import { StyledButton } from './Button.styles'

export const Button = React.forwardRef<
  HTMLButtonElement,
  ButtonHTMLAttributes<HTMLButtonElement>
>(({ children, type = 'button', ...props }, ref) => (
  <StyledButton ref={ref} {...props} type={type}>
    {children}
  </StyledButton>
))
