import { useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { setLanguageAction } from 'root-redux/actions/common'
import { selectIsInContextShown } from 'root-redux/selects/common'

import { getEnvironment } from 'helpers/getEnvironment'

import {
  INITIAL_LANGUAGE,
  LANG_QUERY_PARAM,
  Language,
  SUPPORTED_LOCALES,
} from '../constants/common'

export const useLanguage = (isUnsubscribeFlow?: boolean): void => {
  const { pathname, search } = document.location
  const dispatch = useDispatch()
  const isInContextShown = useSelector(selectIsInContextShown)

  const { isDevEnvironment } = getEnvironment()

  const isInContextActive = useMemo(
    () => isDevEnvironment && isInContextShown,
    [isDevEnvironment, isInContextShown],
  )

  useEffect(() => {
    const URLParams = new URLSearchParams(search)
    const langQueryParam = URLParams.get(LANG_QUERY_PARAM) || ''

    if (!SUPPORTED_LOCALES?.includes(langQueryParam)) {
      dispatch(
        setLanguageAction(
          isInContextActive ? Language.IN_CONTEXT : INITIAL_LANGUAGE,
        ),
      )
      return
    }

    dispatch(
      setLanguageAction(
        isInContextActive ? Language.IN_CONTEXT : langQueryParam,
      ),
    )
  }, [dispatch, pathname, search, isUnsubscribeFlow, isInContextActive])
}
