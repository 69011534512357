import React from 'react'
import { useTranslation } from 'react-i18next'

import { useNextStep } from 'hooks/useNextStep'

import { TPageProps } from 'models/common.model'

import { Button } from 'components/Button'
import { FixedContainer } from 'components/FixedContainer'

import pinpointImage from 'assets/images/pinpoint-location.png'

import { StyledPinpointLocationVariant1 as S } from './PinpointLocationVariant1.styles'

export const PinpointLocationVariant1: React.FC<TPageProps> = ({
  pageId,
  nextPagePath,
}) => {
  const { t } = useTranslation()

  const handleContinue = useNextStep({
    pageId,
    pageName: pageId,
    question: '',
    nextPagePath,
  })

  return (
    <S.Wrapper>
      <S.Content>
        <S.ImageContainer>
          <img src={pinpointImage} alt="pinpoint-location" />
        </S.ImageContainer>
        <S.Title>{t`onboarding.pinpointLocation.title`}</S.Title>

        <FixedContainer>
          <Button onClick={() => handleContinue('')}>
            {t`actions.continue`}
          </Button>
        </FixedContainer>
      </S.Content>
    </S.Wrapper>
  )
}
