import styled from 'styled-components'

import checkboxViolet from 'assets/images/checkbox-violet.svg'

export const StyledBenefitsVariant2 = {
  Wrapper: styled.div`
    margin: 32px 0 38px;
  `,
  Title: styled.h2`
    text-align: center;
    font-size: 24px;
    font-weight: 800;
    line-height: 28px;
    margin-bottom: 24px;
  `,
  Text: styled.li`
    position: relative;
    padding-left: 35px;
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
    margin-left: 10px;
    margin-bottom: 16px;
    text-align: start;

    ::before {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      display: block;
      width: 23px;
      height: 23px;
      background-image: url(${checkboxViolet});
      background-size: 23px;
      background-position: top center;
    }

    &:last-of-type p {
      margin-bottom: 0;
    }
  `,
}
