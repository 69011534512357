import React from 'react'
import { useTranslation } from 'react-i18next'

import { useNextStep } from 'hooks/useNextStep'

import { TPageProps } from 'models/common.model'

import phonesImage from 'assets/images/social-proof-21.png'

import { StyledSocialProofVariant21 as S } from './SocialProofVariant21.styles'

export const SocialProofVariant21: React.FC<TPageProps> = ({
  pageId,
  nextPagePath,
}) => {
  const { t } = useTranslation()

  const handleContinue = useNextStep({
    pageId,
    pageName: pageId,
    question: '',
    nextPagePath,
  })

  return (
    <S.Wrapper>
      <S.ImageContainer>
        <img src={phonesImage} alt="phones" />
      </S.ImageContainer>
      <S.Title>{t`onboarding.socialProof.variant21.title`}</S.Title>
      <S.Button type="button" onClick={() => handleContinue('')}>
        {t`actions.continue`}
      </S.Button>
    </S.Wrapper>
  )
}
