import { Cohort, Language } from 'root-constants/common'

export const LIVE_CHAT_PUBLIC_KEY = '10826272'
export const enum LiveChatStatus {
  MAXIMIZED = 'maximized',
  MINIMIZED = 'minimized',
  HIDDEN = 'hidden',
}

export const LIVECHAT_COHORTS: Record<string, string[]> = {
  [Language.EN]: [
    Cohort.MAIN_1,
    Cohort.SPOUSE_1,
    Cohort.MAIN_4,
    Cohort.SAFETY_2,
  ],
  [Language.ES]: [Cohort.MAIN_4, Cohort.SAFETY_2],
}
