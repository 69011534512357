import {
  CardCvcElement,
  CardExpiryElement,
  CardNumberElement,
} from '@stripe/react-stripe-js'
import { StripeElementStyle } from '@stripe/stripe-js'
import styled, { css } from 'styled-components'

import { Button } from 'components/Button'

import cardIcon from 'assets/images/card.svg'
import warningIcon from 'assets/images/warning.svg'

import { Color, MediaBreakpoint } from 'root-constants/common'

type TLabelProps = {
  gridArea: string
}
type TButtonProps = {
  isChinese?: boolean
}

export const stripeElementStyle: StripeElementStyle = {
  base: {
    color: Color.DEFAULT_TEXT,
    fontFamily: 'Nunito, sans-serif',
    fontSize: '16px',
    lineHeight: '56px',
    fontWeight: 700,
    fontSmoothing: 'antialiased',
    '::placeholder': {
      color: '#bcc9d0',
      fontWeight: 400,
    },
  },
  invalid: {
    color: Color.ERROR,
  },
}

export const stripeElementStyleFamyLocateCohort: StripeElementStyle = {
  base: {
    ...stripeElementStyle.base,
    fontFamily: 'Open Sans, sans-serif',
    color: '#616A77',
    fontSize: '17px',
    fontWeight: '700',
    '::placeholder': {
      color: '#616A77',
      fontWeight: 400,
    },
  },
  invalid: {
    ...stripeElementStyle.invalid,
    color: '#ff7089',
  },
}

const commonInputStyles = css`
  position: relative;
  margin-bottom: ${({ theme }) => theme.common.stripeInputFormMarginBottom};
  padding-left: 16px;
  height: 56px;
  background-color: ${({ theme }) => theme.colors.inputBackground};
  color: ${({ theme }) => theme.colors.primaryText};
  font-size: 16px;
  font-weight: 700;
  border-radius: ${({ theme }) => theme.common.inputBorderRadius};
  border: 1px solid transparent;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);

  &[data-invalid='true'],
  &.StripeElement--invalid {
    border: 1px solid ${Color.ERROR};

    &::before {
      content: url(${warningIcon});
      position: absolute;
      top: 50%;
      right: 16px;
      transform: translateY(-50%);
      width: 18px;
      height: 18px;
    }
  }
`

export const StyledStripePaymentForm = {
  Form: styled.form`
    position: relative;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto;
    grid-template-areas:
      'cardNumberLabel cardNumberLabel'
      'cardNumber cardNumber'
      'cardExpiryLabel cardCvcLabel'
      'cardExpiry cardCvc'
      'cardholderLabel cardholderLabel'
      'cardholder cardholder'
      'phoneNumber phoneNumber'
      'button button';
    grid-gap: 0 16px;
    width: 100%;
    margin-bottom: 25px;

    @media (min-width: ${MediaBreakpoint.LAPTOP}px) {
      width: 460px;
      margin-bottom: 45px;
    }
  `,
  Label: styled.p<TLabelProps>`
    grid-area: ${({ gridArea }) => gridArea};
    padding-bottom: 8px;
    font-size: 13px;
    line-height: 20px;
    color: ${({ theme }) => theme.colors.tertiaryText};

    @media (min-width: ${MediaBreakpoint.LAPTOP}px) {
      font-size: 14px;
    }
  `,
  CardNumberElement: styled(CardNumberElement)`
    ${commonInputStyles};
    grid-area: cardNumber;
  `,
  CardExpiryElement: styled(CardExpiryElement)`
    ${commonInputStyles};
    grid-area: cardExpiry;
  `,
  CardCvcElement: styled(CardCvcElement)`
    ${commonInputStyles};
    grid-area: cardCvc;

    &.StripeElement--invalid {
      &::before {
        right: 48px;
      }
    }

    ::after {
      content: url(${cardIcon});
      position: absolute;
      right: 16px;
      top: 18px;
    }
  `,
  CardholderInput: styled.input`
    ${commonInputStyles};
    grid-area: cardholder;
    width: 100%;
    margin-bottom: 16px;
    outline: none;
    box-shadow: none;
    direction: ltr;

    ::placeholder {
      color: ${({ theme }) => theme.colors.inputPlaceholder};
      font-weight: 400;
    }
  `,
  SubmitButton: styled(Button)<TButtonProps>`
    grid-area: button;
    height: 56px;
    line-height: 56px;
    font-size: ${({ isChinese }) => (isChinese ? 25 : 20)}px;
    font-weight: ${({ isChinese }) => (isChinese ? 500 : 700)};

    &[data-has-laptop-styles='true'] {
      @media (min-width: ${MediaBreakpoint.LAPTOP}px) {
        height: 76px;
        line-height: 76px;
        width: 460px;
      }
    }

    @media (min-width: ${MediaBreakpoint.LAPTOP}px) {
      width: 460px;
      font-size: 24px;
      line-height: 33px;
    }
  `,
  PhoneNumberContainer: styled.div`
    grid-area: phoneNumber;
    max-width: fit-content;
    width: 100%;
    padding: 8px 16px;
    border-radius: 8px;
    background-color: #f2f3f5;
    font-size: 15px;
    font-weight: 600;
    line-height: 20px;
    color: #000;
    text-align: center;
    margin: 6px auto 12px;
  `,
}
