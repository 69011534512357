import React, { useRef } from 'react'
import { Trans, useTranslation } from 'react-i18next'

import { useFeatureValue } from '@growthbook/growthbook-react'

import { usePurchaseStore } from 'hooks/common/usePurchaseStore'

import { getPriceBeforeDiscount } from 'helpers/getPriceBeforeDiscount'

import {
  FIVE_MINUTES_IN_SECONDS,
  TIMER_START_MINUTES,
  TIMER_START_SECONDS,
} from 'modules/payment/components/DiscountWithTimer/constants'
import { OVERSIZE_CURRENCIES } from 'modules/payment/components/PlanItem/constants'
import { PERSONAL_DISCOUNT_AMOUNT } from 'modules/payment/constants'
import { useTimerForTarget } from 'modules/subscriptions/hooks/useTimerForTarget'

import { PriceValue } from 'components/PriceValue'

import paymentServicesImage from 'assets/images/payment-services-grey-bg.png'
import personalGift from 'assets/images/personal-gift.png'

import {
  CURRENCY_SYMBOLS,
  FAMYLOCATE_V1_TEST_SEGMENTS,
  GROWTH_BOOK_FEATURE_KEYS,
  TRIAL_IN_HOURS,
  TimeInterval,
} from 'root-constants/common'

import { StyledPaymentFlowsGoogleVariant4 as S } from './PaymentFlowsGoogleVariant4.styles'

export const PaymentFlowsGoogleVariant4: React.FC = () => {
  const { t } = useTranslation()
  const timerElementRef = useRef<HTMLDivElement>(null)

  const {
    selectedSubscriptionId,
    currentPrice,
    currency,
    trialPrice,
    trialPeriodDays,
  } = usePurchaseStore()

  const { minutes, seconds } = useTimerForTarget(
    timerElementRef,
    FIVE_MINUTES_IN_SECONDS,
  )

  const hasTestDiscount = useFeatureValue(
    GROWTH_BOOK_FEATURE_KEYS.car_1212_promo_code,
    FAMYLOCATE_V1_TEST_SEGMENTS.A_SEGMENT,
  )

  const oldPrice = getPriceBeforeDiscount(PERSONAL_DISCOUNT_AMOUNT, trialPrice)

  const hasPersonalDiscount =
    hasTestDiscount === FAMYLOCATE_V1_TEST_SEGMENTS.B_SEGMENT ||
    hasTestDiscount === FAMYLOCATE_V1_TEST_SEGMENTS.C_SEGMENT

  const isFontOversized = OVERSIZE_CURRENCIES.includes(currency)

  return (
    <S.Wrapper>
      <S.PhoneNumberLabel hasPersonalDiscount={hasPersonalDiscount} />
      {hasPersonalDiscount && (
        <S.DiscountWrapper ref={timerElementRef}>
          <img src={personalGift} alt="gift" />
          <S.DiscountText>
            <Trans i18nKey="payment.variant5.variant6.discountText" />
          </S.DiscountText>
          <S.TimerContainer>
            {minutes || TIMER_START_MINUTES}:{seconds || TIMER_START_SECONDS}
          </S.TimerContainer>
        </S.DiscountWrapper>
      )}
      {hasPersonalDiscount ? (
        <>
          <S.Separator />
          <S.PriceContainerV2>
            <S.PriceBlock>
              <S.Payment>{t`payment.googlePayment`}</S.Payment>
              <S.PricesWrapper>
                <S.OldPrice isFontOversized={isFontOversized}>
                  <PriceValue value={oldPrice} currency={currency} />
                </S.OldPrice>
                <S.CurrentPrice isFontOversized={isFontOversized}>
                  <PriceValue value={trialPrice} currency={currency} />
                </S.CurrentPrice>
              </S.PricesWrapper>
            </S.PriceBlock>
            <S.TextBlock>
              <S.Gift>{t`payment.variant5.variant6.personalGift`}</S.Gift>
              <S.Duration>
                {t('payment.trial', {
                  period: TRIAL_IN_HOURS[trialPeriodDays],
                })}
              </S.Duration>
            </S.TextBlock>
          </S.PriceContainerV2>
        </>
      ) : (
        <S.PriceContainer>
          <S.PriceText gridArea="priceText">{t`payment.googlePayment`}</S.PriceText>
          <S.PriceValue gridArea="priceValue">
            <PriceValue value={trialPrice} currency={currency} />
          </S.PriceValue>
          <S.TrialDurationText>
            {t('payment.trial', {
              period: TRIAL_IN_HOURS[trialPeriodDays],
            })}
          </S.TrialDurationText>
        </S.PriceContainer>
      )}
      <S.PaymentRequestButton key={selectedSubscriptionId} />

      <S.StripePaymentForm />

      <S.Disclaimer>
        {t('payment.googleFlowDisclaimer', {
          period: TRIAL_IN_HOURS[trialPeriodDays],
          trialPeriodDays,
          currencySymbol: CURRENCY_SYMBOLS[currency],
          price: currentPrice,
          context: TimeInterval.DAY,
        })}
      </S.Disclaimer>
      <S.Image
        src={paymentServicesImage}
        decoding="async"
        alt="payments services"
      />
      <S.Guarantee>{t`payment.guarantee`}</S.Guarantee>
    </S.Wrapper>
  )
}
