import { useEffect } from 'react'
import { useLocation } from 'react-router'

import { NO_SCROLL_TO_TOP_PAGES } from 'root-constants/common'

export const useScrollToTop = (): void => {
  const { pathname } = useLocation()

  useEffect(() => {
    const pageId = pathname.slice(1)
    if (NO_SCROLL_TO_TOP_PAGES.includes(pageId)) return

    document.body.scrollTo({ top: 0, behavior: 'instant' })
  }, [pathname])
}
