import styled from 'styled-components'

import { Button } from 'components/Button'
import { StyledOption } from 'components/Option/Option.styles'
import { QuestionButton } from 'components/QuestionButton'

import appImg from 'assets/images/intro-2.webp'

import { baseColumnStyles, basePageStyles } from 'common-styles'
import { Color, MediaBreakpoint } from 'root-constants/common'

export const StyledIntroVariant2 = {
  Wrapper: styled.div`
    ${basePageStyles};
    width: 100%;
  `,
  ImageContainer: styled.div`
    width: 100%;
    min-height: 700px;
    padding-top: 15px;
    background-image: url(${appImg});
    background-size: 100% auto;
    background-repeat: no-repeat;

    &[data-is-es-lang='true'] {
      padding-top: 0;

      @media (min-width: 360px) {
        padding-top: 15px;
      }
    }

    @media (min-width: ${MediaBreakpoint.LAPTOP}px) {
      min-height: 110px;
      background-image: none;
    }
  `,
  FixedContainer: styled.div`
    position: fixed;
    bottom: 0;
    width: 100%;
    padding: 60px 0 0;
    background-image: linear-gradient(
      180deg,
      rgba(255, 255, 255, 0.1) 10%,
      #ffffff 16%
    );
    background-size: cover;
    background-repeat: no-repeat;
    background-position: bottom center;

    @media (min-height: 550px) {
      padding: 80px 0 18px;
    }

    @media (min-width: ${MediaBreakpoint.LAPTOP}px) {
      background-image: none;
      position: static;
      padding: 24px 0;
    }
  `,
  Column: styled.div`
    ${baseColumnStyles};
    text-align: center;
    margin: 0 auto;

    @media (min-width: ${MediaBreakpoint.LAPTOP}px) {
      width: 540px;
    }
  `,
  Title: styled.h1`
    margin: 0 auto;
    font-weight: 800;
    font-size: 28px;
    line-height: 38px;
    text-align: center;
    max-width: 350px;

    &[data-is-es-lang='true'] {
      @media (max-width: 360px) {
        font-size: 20px;
        line-height: 24px;
      }

      @media (min-width: ${MediaBreakpoint.LAPTOP}px) {
        font-weight: 800;
        font-size: 42px;
        line-height: 42px;
      }
    }

    @media (min-width: ${MediaBreakpoint.LAPTOP}px) {
      font-weight: 800;
      font-size: 48px;
      line-height: 48px;
    }
  `,
  Question: styled.h2`
    font-weight: 700;
    font-size: 20px;
    line-height: 27px;
    margin-bottom: 8px;

    @media (min-width: ${MediaBreakpoint.LAPTOP}px) {
      margin-bottom: 24px;
      font-size: 24px;
      line-height: 24px;
    }
  `,
  Note: styled.span`
    font-size: 13px;
    line-height: 18px;
    margin-bottom: 7px;
    color: #bcc9d0;

    @media (min-height: 670px) {
      margin-bottom: 23px;
    }

    @media (min-width: ${MediaBreakpoint.LAPTOP}px) {
      font-size: 18px;
      line-height: 25px;
    }
  `,
  OptionsContainer: styled(StyledOption.OptionsContainer)`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    gap: 0 16px;
    padding-bottom: 0;

    div {
      width: 147px;
      flex-shrink: 0;
    }

    @media (min-height: 670px) {
      gap: 11px 16px;
      padding-bottom: 16px;
    }

    @media (min-width: ${MediaBreakpoint.LAPTOP}px) {
      margin-bottom: 30px;
      gap: 0 12px;

      div {
        width: 460px;
      }
    }
  `,
  QuestionButton: styled(QuestionButton)`
    height: 54px;
    justify-content: center;
    border-radius: 30px;
    border: 3px solid transparent;

    input:checked + & {
      border: 3px solid ${Color.ACCENT_VIOLET};
      background-color: ${Color.GREY};
      color: ${Color.DEFAULT_TEXT};
    }

    @media (min-width: ${MediaBreakpoint.LAPTOP}px) {
      height: 76px;
      border-radius: 32px;
      border: 4px solid transparent;
      font-size: 18px;
      line-height: 25px;

      input:checked + & {
        border: 4px solid ${Color.ACCENT_VIOLET};
      }
    }
  `,
  Button: styled(Button)`
    margin-bottom: 17px;

    @media (min-width: ${MediaBreakpoint.LAPTOP}px) {
      width: 460px;
      height: 76px;
      border-radius: 100px;
      margin-bottom: 24px;
      font-size: 24px;
      line-height: 33px;
    }
  `,
}
