import { Images } from 'root-constants/common'

export const BENEFITS_DATA = [
  {
    img: `${Images.TRACK_LOCATION}`,
    text: 'onboarding.introGoogle5.trustpilot.trackLocation',
  },
  {
    img: `${Images.REAL_TIME_LOCATION_WITHOUT_BG}`,
    text: 'onboarding.introGoogle5.trustpilot.realTime',
  },
  {
    img: `${Images.UNLIMITED_GROUPS_WITHOUT_BG}`,
    text: 'onboarding.introGoogle5.trustpilot.ultimateGroups',
  },
  {
    img: `${Images.SOS_NOTIFICATION_WITHOUT_BG}`,
    text: 'onboarding.introGoogle5.trustpilot.sos',
  },
  {
    img: `${Images.PRIVATE_CHAT_WITHOUT_BG}`,
    text: 'onboarding.introGoogle5.trustpilot.chatPrivately',
  },
]
