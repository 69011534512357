import React from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { selectLanguage } from 'root-redux/selects/common'

import { OVERSIZE_LANGUAGES } from 'modules/subscriptions/constants'

import { StyledBenefits as S } from './Benefits.styles'
import { CANCEL_OFFER_BENEFITS_DATA } from './constants'

type TProps = {
  onButtonClick: () => void
}

export const Benefits: React.FC<TProps> = ({ onButtonClick }) => {
  const { t } = useTranslation()
  const language = useSelector(selectLanguage)
  const hasSmallerFontSize = OVERSIZE_LANGUAGES.includes(language)

  return (
    <S.Wrapper>
      <S.Title>{t`subscriptions.subscriptionsGoogle.variant1.ultimatePeace`}</S.Title>
      {CANCEL_OFFER_BENEFITS_DATA.map(({ image, title, subtitle }) => (
        <S.Content key={title}>
          <img src={image} alt="icon" />
          <S.TextWrapper>
            <S.BenefitsTitle>{t(title)}</S.BenefitsTitle>
            <S.Subtitle>
              <Trans i18nKey={subtitle} />
            </S.Subtitle>
          </S.TextWrapper>
        </S.Content>
      ))}
      <S.Button
        type="button"
        onClick={onButtonClick}
        hasSmallerFontSize={hasSmallerFontSize}
      >
        {t('subscriptions.cancelOffer.button')}
      </S.Button>
    </S.Wrapper>
  )
}
