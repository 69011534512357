import React, { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { useLocation } from 'react-router-dom'

import { setAnswersAction } from 'root-redux/actions/common'
import { sendUserAnswersAction } from 'root-redux/actions/user'

import { useDelayedContinue } from 'hooks/useDelayedContinue'

import { TPageProps } from 'models/common.model'

import { eventLogger } from 'services/eventLogger.service'

import { Option, OptionType } from 'components/Option'
import { StyledOption } from 'components/Option/Option.styles'
import { QuestionButton } from 'components/QuestionButton'

import { goTo } from 'browser-history'
import { CheckboxCustomValue, Language } from 'root-constants/common'
import { PageId } from 'root-constants/pages'

import { StyledReasons as S } from './Reasons.styles'
import { OPTION_VALUES, PAGE_NAME } from './constants'

export const ReasonsVariant3: React.FC<TPageProps> = ({
  pageId,
  nextPagePath,
}) => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const { search } = useLocation()
  const { isAnswersDisabled, handleChange, setIsAnswersDisabled } =
    useDelayedContinue()

  const handleContinue = useCallback(
    (answer) => {
      dispatch(
        setAnswersAction({
          answers: answer,
          pageId,
        }),
      )

      dispatch(sendUserAnswersAction())

      eventLogger.logQuestion({
        question: t('onboarding.reasons.question', { lng: Language.EN }),
        answers: answer,
        pageName: PAGE_NAME,
      })

      answer === OPTION_VALUES.SAFE || answer === OPTION_VALUES.EMERGENCY
        ? goTo(nextPagePath)
        : goTo(`${PageId.CATCH_ON_A_LIE_1}${search}`)
    },
    [dispatch, nextPagePath, pageId, search, t],
  )

  const optionProps = {
    name: pageId,
    type: OptionType.RADIO,
    onChange: (value: string) => {
      handleChange(value, handleContinue)
      setIsAnswersDisabled(true)
    },
  }

  return (
    <S.Wrapper>
      <S.Column padding="56px 25px 114px">
        <S.Title marginBottom={71}>{t`onboarding.reasons.question`}</S.Title>
        <StyledOption.OptionsContainer paddingBottom={4}>
          <Option
            {...optionProps}
            value={OPTION_VALUES.PEACE}
            disabled={isAnswersDisabled}
          >
            <QuestionButton>
              {t`onboarding.reasons.answers.peace`}
            </QuestionButton>
          </Option>
          <Option
            {...optionProps}
            value={OPTION_VALUES.SAFE}
            disabled={isAnswersDisabled}
          >
            <QuestionButton>
              {t`onboarding.reasons.answers.safe`}
            </QuestionButton>
          </Option>
          <Option
            {...optionProps}
            value={OPTION_VALUES.HONEST}
            disabled={isAnswersDisabled}
          >
            <QuestionButton>
              {t`onboarding.reasons.answers.honest`}
            </QuestionButton>
          </Option>
          <Option
            {...optionProps}
            value={OPTION_VALUES.EMERGENCY}
            disabled={isAnswersDisabled}
          >
            <QuestionButton>
              {t`onboarding.reasons.answers.emergency`}
            </QuestionButton>
          </Option>
          <Option
            {...optionProps}
            value={CheckboxCustomValue.ALL_OF_THE_ABOVE}
            disabled={isAnswersDisabled}
          >
            <QuestionButton>{t`commonComponents.allOfTheAbove`}</QuestionButton>
          </Option>
        </StyledOption.OptionsContainer>
      </S.Column>
    </S.Wrapper>
  )
}
