import React from 'react'
import { Trans, useTranslation } from 'react-i18next'

import { Swiper, SwiperSlide } from 'swiper/react'

import { REVIEWS_DATA_SLIDER_VARIANT_4 } from 'components/ReviewsSlider/constants'

import stars from 'assets/images/stars-2.png'

import { StyledReviewsSlider as S } from './ReviewsSlider.styles'

type TProps = {
  marginBottom?: number
}
export const ReviewsSlider3: React.FC<TProps> = ({ marginBottom = 48 }) => {
  const { t } = useTranslation()

  return (
    <S.Wrapper marginBottom={marginBottom}>
      <S.Title>
        <Trans i18nKey="onboarding.introGoogle4.joinMillions" />
      </S.Title>
      <Swiper
        slidesPerView="auto"
        spaceBetween={20}
        centeredSlides
        breakpoints={{
          1200: {
            spaceBetween: 12,
            initialSlide: 1,
          },
        }}
      >
        {REVIEWS_DATA_SLIDER_VARIANT_4.map(
          ({ userName, reviewText, avatar, borderColor }) => (
            <SwiperSlide key={userName}>
              <S.Card borderColor={borderColor}>
                <S.Avatar width="66" height="66" src={avatar} loading="lazy" />
                <S.CardTitle>
                  <Trans i18nKey={userName} />
                </S.CardTitle>
                <S.Recommendation
                  text={t`subscriptions.reviewsSlider.recommendation`}
                />
                <S.Rating src={stars} alt="stars" />
                <S.CardText fontStyle="normal">
                  <Trans i18nKey={reviewText} />
                </S.CardText>
              </S.Card>
            </SwiperSlide>
          ),
        )}
      </Swiper>
    </S.Wrapper>
  )
}
