import React, { useEffect, useLayoutEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'

import { useFeatureIsOn } from '@growthbook/growthbook-react'
import { usePageInfo } from 'providers/PageInfoProvider'

import {
  sendFacebookParamsAction,
  sendGoogleAdsParamsAction,
} from 'root-redux/actions/user'
import {
  selectCurrentVariantCohortToUse,
  selectLanguage,
} from 'root-redux/selects/common'
import { selectUUID, selectUserCountryCode } from 'root-redux/selects/user'

import { useAmplitudeInitialization } from 'hooks/analytics/useAmplitudeInitialization'
import { useGAPageTracker } from 'hooks/analytics/useGAPageTracker'
import { useGiaFlowTrackerInitialization } from 'hooks/analytics/useGiaFlowTrackerInitialization'
import { useCookieConsentAnswer } from 'hooks/common/useCookieConsentAnswer'
import { useSetPlanAdditions } from 'hooks/common/useSetPlanAdditions'
import { useStartSession } from 'hooks/common/useStartSession'
import { useUtmTags } from 'hooks/common/useUtmTags'
import { growthbook } from 'hooks/services/useGrowthBook'
import { useDynamicDiscount } from 'hooks/ui/useDynamicDiscount'
import { useScrollToTop } from 'hooks/ui/useScrollToTop'

import { getEnvironment } from 'helpers/getEnvironment'

import { googleAnalyticsLogger } from 'services/googleAnalytics.service'

import { CookieConsent } from 'components/CookieConsent'
import { Header } from 'components/Header'
import { InContextPopup } from 'components/InContextPopup'
import { LanguageSelector } from 'components/LanguageSelector'
import { FamylocateGlobalStyles } from 'components/LayoutProvider/LayoutProvider.styles'
import { Spinner } from 'components/Spinner'
import { Theme } from 'components/Theme'

import famyLocateLogoV2 from 'assets/images/famylocate-header-logo-v2.png'

import {
  COOKIE_CONSENT_LANGUAGES,
  Cohort,
  DEFAULT_HEADER_BORDER,
  FAMYLOCATE_COHORTS,
  Language,
} from 'root-constants/common'

import { RouteList } from '../../RouteList'

export const LayoutProvider: React.FC = () => {
  const dispatch = useDispatch()
  const { search, pathname } = useLocation()
  const { i18n } = useTranslation()
  const cohortToUse = useSelector(selectCurrentVariantCohortToUse)
  const language = useSelector(selectLanguage)
  const uuid = useSelector(selectUUID)
  const userCountryCode = useSelector(selectUserCountryCode)

  const { isStageEnvironment, isTestEnvironment } = getEnvironment()
  const isLanguageSelectorShown = isStageEnvironment || isTestEnvironment

  const { isSecondPage, isSecondUpsellPage } = usePageInfo()

  useUtmTags()
  useGAPageTracker()
  useGiaFlowTrackerInitialization()
  useAmplitudeInitialization()
  useStartSession()
  useSetPlanAdditions()
  useScrollToTop()
  useDynamicDiscount()

  const shouldBeRedirected = useFeatureIsOn('abtest_car_1067_mxn')
  const { isEUUser, cookieConsent } = useCookieConsentAnswer()

  const hasAlternativeStyles = FAMYLOCATE_COHORTS.includes(cohortToUse)
  const hasAlternativeFamyLocateHeader =
    FAMYLOCATE_COHORTS.includes(cohortToUse) && isSecondUpsellPage

  const isCookieConsentAvailable =
    !cookieConsent && isEUUser && COOKIE_CONSENT_LANGUAGES.includes(language)

  useLayoutEffect(() => {
    if (!shouldBeRedirected) return

    const originUrl = window.location.origin
    const searchParams = new URLSearchParams(search)
    searchParams.set('cohort', Cohort.GOOGLE_9_MXN)
    searchParams.set('uuid', uuid)
    window.location.href = `${originUrl}?${searchParams.toString()}`
  }, [search, shouldBeRedirected, uuid])

  useEffect(() => {
    i18n.changeLanguage(language)
    document.documentElement.dir = i18n.language === Language.AR ? 'rtl' : 'ltr'
  }, [i18n, language])

  useEffect(() => {
    if (isSecondPage) {
      dispatch(sendFacebookParamsAction())
      dispatch(sendGoogleAdsParamsAction())
      window.fbq('track', 'CustomizeProduct', {}, { eventID: uuid })
      googleAnalyticsLogger.logFirstPageCompleted()
    }
  }, [dispatch, isSecondPage, uuid])

  useEffect(() => {
    growthbook.setAttributes({
      ...growthbook.getAttributes(),
      url: `${pathname}${search}`,
      country: userCountryCode,
      language,
    })
  }, [language, pathname, search, userCountryCode])

  return shouldBeRedirected ? (
    <Spinner />
  ) : (
    <Theme>
      {hasAlternativeStyles && (
        <FamylocateGlobalStyles isSecondUpsellPage={isSecondUpsellPage} />
      )}

      {isStageEnvironment && <InContextPopup />}
      {isLanguageSelectorShown && <LanguageSelector />}

      <Header
        headerImg={hasAlternativeFamyLocateHeader ? famyLocateLogoV2 : ''}
        headerBorder={
          hasAlternativeFamyLocateHeader ? DEFAULT_HEADER_BORDER : ''
        }
      />
      {isCookieConsentAvailable && <CookieConsent />}
      <RouteList />
    </Theme>
  )
}
