import styled from 'styled-components'

import warningIcon from 'assets/images/warning.svg'

import { baseInputStyles } from 'common-styles'
import { Color } from 'root-constants/common'

export const StyledInput = {
  Wrapper: styled.div`
    position: relative;
    display: block;
    width: 100%;
    padding-bottom: 20px;

    ::after {
      display: block;
      position: absolute;
      left: 0;
      bottom: 2px;
      color: ${Color.ERROR};
      font-size: 11px;
      font-weight: 500;
      line-height: 13px;
    }

    &:has(.no-validation-icon) {
      input {
        border-radius: 30px;
        color: #17202a;
        font-size: 17px;
        font-weight: 400;
        line-height: 24px;
      }

      ::after {
        left: 16px;
        color: #ff93a6;
        font-size: 13px;
        font-weight: 400;
        line-height: 16px;
      }

      &[data-valid='false'] {
        input {
          border-color: #ff93a6;
        }

        ::before {
          display: none;
        }
      }
    }

    &[data-valid='false'] {
      ::before {
        content: url(${warningIcon});
        position: absolute;
        right: 16px;
        top: 19px;
      }

      &[data-has-label='true']::before {
        top: 50px;
      }

      &[data-has-visibility-control='true']::before {
        right: 50px;
      }

      ::after {
        content: attr(data-validation-text);
      }
    }
  `,
  Input: styled.input`
    ${baseInputStyles};

    [data-valid='false'] & {
      border-color: ${Color.ERROR};
      padding-right: 45px;
    }

    [data-has-visibility-control='true'] & {
      padding-right: 45px;
    }

    [data-valid='false'][data-has-visibility-control='true'] & {
      padding-right: 75px;
    }

    /* to hide arrows */
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    &[type='number'] {
      -moz-appearance: textfield;
    }
  `,
  Label: styled.span`
    display: block;
    font-size: 13px;
    line-height: 16px;
    padding-top: 5px;
    margin-bottom: 10px;
    color: ${({ theme }) => theme.colors.tertiaryText};
  `,
  VisibilityButton: styled.button`
    padding: 0;
    display: block;
    position: absolute;
    bottom: 20px;
    right: 0;
    width: 52px;
    height: 56px;
    border: none;
    outline: none;
    cursor: pointer;
    background-size: 20px 20px;
    background-color: transparent;
    background-repeat: no-repeat;
    background-position: center;
    &[data-visible='true'] {
      background-image: ${({ theme }) => `url(${theme.images.openedEyeIcon})`};
    }
    &[data-visible='false'] {
      background-image: ${({ theme }) => `url(${theme.images.closedEyeIcon})`};
    }
  `,
}
