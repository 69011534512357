import styled from 'styled-components'

import { Button } from 'components/Button'

import { baseColumnStyles, basePageStyles } from 'common-styles'
import { Color, HEADER_HEIGHT } from 'root-constants/common'

type TButtonProps = {
  hasDisclaimer: boolean
}

export const StyledChipoloUpgradeVariant1 = {
  Wrapper: styled.div`
    ${basePageStyles};
    min-height: 100%;
    padding-top: ${HEADER_HEIGHT}px;
    overflow-x: hidden;
    padding-bottom: 16px;
  `,
  Column: styled.div`
    ${baseColumnStyles};
  `,
  Title: styled.h1`
    padding: 24px 0 12px;
    font-size: 32px;
    line-height: 38px;
    font-weight: 800;
    text-align: center;
  `,
  Offer: styled.p`
    margin-bottom: 12px;
    font-weight: 800;
    font-size: 20px;
    line-height: 27px;
    text-align: center;
    color: ${Color.RED};
  `,
  Text: styled.p`
    margin-bottom: 24px;
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
    text-align: center;

    strong {
      font-size: 16px;
      font-weight: 800;
      line-height: 22px;
    }
  `,
  Button: styled(Button)<TButtonProps>`
    margin-bottom: ${({ hasDisclaimer }) => (hasDisclaimer ? '16px' : '48px')};
    line-height: 22px;
    background-color: ${Color.ACCENT_VIOLET};
    max-height: 56px;
  `,
  Subtitle: styled.h2`
    font-size: 24px;
    font-weight: 800;
    line-height: 30px;
  `,
  Disclaimer: styled.p`
    margin-bottom: 48px;
    color: ${Color.LIGHT_TEXT};
    font-size: 11px;
    font-weight: 400;
    line-height: 18px;
  `,
}
