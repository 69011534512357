import { PhoneNumberFormat, PhoneNumberUtil } from 'google-libphonenumber'

import { PHONE_REGEX } from 'root-constants/common'

const phoneUtil = PhoneNumberUtil.getInstance()

export const checkIsPhoneValid = (phoneNumber: string) => {
  try {
    return phoneUtil.isValidNumber(phoneUtil.parseAndKeepRawInput(phoneNumber))
  } catch (error) {
    return false
  }
}

export const checkIsUSPhoneValid = (phoneNumber: string) => {
  try {
    return (
      PHONE_REGEX.test(phoneNumber) &&
      phoneUtil.isValidNumberForRegion(
        phoneUtil.parseAndKeepRawInput(phoneNumber, 'US'),
        'US',
      )
    )
  } catch (error) {
    return false
  }
}

export const formatPhoneNumber = (phoneNumber: string) => {
  if (!checkIsPhoneValid(phoneNumber)) return phoneNumber

  return phoneUtil.format(
    phoneUtil.parse(phoneNumber),
    PhoneNumberFormat.INTERNATIONAL,
  )
}
