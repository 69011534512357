import React, { useMemo, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'

import { TPageProps } from 'models/common.model'

import { Option, OptionType } from 'components/Option'
import { PoliciesGroup } from 'components/PoliciesGroup'

import { goTo } from 'browser-history'

import { StyledIntroVariant3 as S } from './IntroVariant3.styles'
import { OPTION_VALUES } from './constants'

export const IntroVariant3: React.FC<TPageProps> = ({
  pageId,
  nextPagePath,
}) => {
  const { t } = useTranslation()
  const [answers, setAnswers] = useState<string[]>([])

  const optionProps = useMemo(
    () => ({
      name: pageId,
      type: OptionType.CHECKBOX,
      onChange: (value, isChecked) => {
        if (isChecked) {
          setAnswers([...answers, value])
        } else {
          const newValues = answers.filter((item) => item !== value)
          setAnswers(newValues)
        }
      },
    }),
    [answers, pageId],
  )
  return (
    <S.Wrapper>
      <S.Title>
        <Trans i18nKey="onboarding.intro3.title" />
      </S.Title>
      <S.FixedContainer>
        <S.Column>
          <S.Question>{t`onboarding.intro3.question`}</S.Question>
          <S.Note>{t`onboarding.intro.note`}</S.Note>
          <S.OptionsContainer>
            <Option {...optionProps} value={OPTION_VALUES.PARTNER}>
              <S.QuestionButton>{t`onboarding.intro3.wifeOrHusband`}</S.QuestionButton>
            </Option>
            <Option {...optionProps} value={OPTION_VALUES.KID}>
              <S.QuestionButton>{t`onboarding.intro.answers.kid`}</S.QuestionButton>
            </Option>
            <Option {...optionProps} value={OPTION_VALUES.OTHER}>
              <S.QuestionButton>{t`commonComponents.other`}</S.QuestionButton>
            </Option>
          </S.OptionsContainer>
          <S.Button
            onClick={() => goTo(nextPagePath)}
            disabled={!answers.length}
          >
            {t`actions.continue`}
          </S.Button>
          <PoliciesGroup />
        </S.Column>
      </S.FixedContainer>
    </S.Wrapper>
  )
}
