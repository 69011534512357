import React from 'react'

import { TPageProps } from 'models/common.model'

import { RadioOptionPage } from 'pages/options-page/RadioOptionPage'

import { SIMPLE_OPTION_DATA } from 'root-constants/common'
import { PageId } from 'root-constants/pages'

import { PAGE_NAMES, TITLE_PATHS } from './constants'

export const LowBatteryVariant3: React.FC<TPageProps> = (props) => (
  <RadioOptionPage
    {...props}
    pageName={PAGE_NAMES[PageId.LOW_BATTERY_3]}
    options={SIMPLE_OPTION_DATA}
    titlePath={TITLE_PATHS[PageId.LOW_BATTERY_3]}
  />
)
