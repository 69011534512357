import React, { useEffect } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'

import { usePageInfo } from 'providers/PageInfoProvider'

import { useDynamicPaywallConfig } from 'hooks/useDynamicPaywallConfig'
import { useGetPrices } from 'hooks/useGetPrices'
import { usePaywall } from 'hooks/usePaywall'
import { usePurchaseStore } from 'hooks/usePurchaseStore'
import { useVatInfo } from 'hooks/useVatInfo'

import { createProductId } from 'helpers/createProductId'

import { TPageProps } from 'models/common.model'

import { ScreenName, eventLogger } from 'services/eventLogger.service'

import { Benefits } from 'modules/subscriptions/components/cancel-offer/Benefits'
import {
  CANCEL_OFFER_TRIAL_COHORTS,
  OVERSIZE_LANGUAGES,
  PLAN_ITEMS,
} from 'modules/subscriptions/constants'

import { DesktopProvider } from 'components/DesktopProvider/DesktopProvider'
import { PrivacyPolicyLink } from 'components/PrivacyPolicyLink'
import { Spinner } from 'components/Spinner'
import { TermsOfUseLink } from 'components/TermsOfUseLink'

import icon from 'assets/images/check-icon-violet.svg'
import logo from 'assets/images/logo.png'

import { CDN_FOLDER_LINK, SubscriptionTag } from 'root-constants/common'

import { PlanBlockVariant1 } from '../../components/cancel-offer/PlanBlockVariant1'
import { StyledCancelOfferSubscriptions as S } from './CancelOfferSubscription.styles'

export const CancelOfferSubscriptionVariant1: React.FC<TPageProps> = () => {
  const { search } = useLocation()
  const { t } = useTranslation()

  const { cohortToUse, subscriptions, language } = usePurchaseStore()
  const { subscriptionBlockType } = useDynamicPaywallConfig()
  const { currentPaymentPageId } = usePageInfo()
  const { hasPrices, handleSelectSubscription, handleShowPayment } = usePaywall(
    `${currentPaymentPageId}${search}`,
  )
  const hasIncludedVAT = useVatInfo()

  const priceTags = hasIncludedVAT
    ? `${SubscriptionTag.CANCEL_OFFER},${SubscriptionTag.TAX}`
    : `${SubscriptionTag.CANCEL_OFFER},${SubscriptionTag.NO_TAX}`

  const isTrialCohort = CANCEL_OFFER_TRIAL_COHORTS.includes(cohortToUse)
  const hasSmallerFontSize = OVERSIZE_LANGUAGES.includes(language)

  useGetPrices({ screenName: ScreenName.CANCEL_OFFER, tags: priceTags })

  useEffect(() => {
    if (!subscriptions.length) return

    eventLogger.logSalePageShown({
      productIds: subscriptions.map(
        ({ mainPrices: { periodName, periodQuantity, fullPrice } }) =>
          createProductId({
            periodName,
            periodQuantity,
            price: fullPrice,
          }),
      ),
      screenName: ScreenName.CANCEL_OFFER,
    })
  }, [subscriptions])

  return !hasPrices ? (
    <Spinner />
  ) : (
    <DesktopProvider>
      <S.Wrapper data-testid="cancel-offer-container">
        <S.Content>
          <S.Title>
            <Trans i18nKey="subscriptions.cancelOffer.title" />
          </S.Title>
          <S.Text>
            <Trans i18nKey="subscriptions.cancelOffer.text" />
          </S.Text>
          <S.PromoBlock>
            <Trans
              i18nKey="subscriptions.cancelOffer.promoText"
              components={{
                strong: <strong />,
                b: <b />,
                br: <br />,
                span: <span />,
              }}
            />
          </S.PromoBlock>

          <S.Subtitle marginBottom={27} laptopMarginBottom={80}>
            {t('subscriptions.benefits.title')}
          </S.Subtitle>
          <S.BenefitsList>
            <S.InnerWrapper>
              <li>
                <img src={icon} alt="" />
                <p>
                  <Trans i18nKey="subscriptions.cancelOffer.benefits.realTimeLocation" />
                </p>
              </li>
              <li>
                <img src={icon} alt="" />
                <p>
                  <Trans i18nKey="subscriptions.cancelOffer.benefits.connection" />
                </p>
              </li>
            </S.InnerWrapper>
            <S.InnerWrapper>
              <li>
                <img src={icon} alt="" />
                <p>
                  <Trans i18nKey="subscriptions.cancelOffer.benefits.history" />
                </p>
              </li>
              <li>
                <img src={icon} alt="" />
                <p>
                  <Trans i18nKey="subscriptions.cancelOffer.benefits.sos" />
                </p>
              </li>
            </S.InnerWrapper>
          </S.BenefitsList>

          <S.Subtitle marginBottom={24} laptopMarginBottom={56}>
            {t('subscriptions.cancelOffer.planTitle')}
          </S.Subtitle>
          {isTrialCohort ? (
            <S.SelectPlanBlock
              onSelect={handleSelectSubscription}
              SelectPlanItem={PLAN_ITEMS[subscriptionBlockType]}
            />
          ) : (
            <PlanBlockVariant1 />
          )}
          <S.Button
            laptopMarginBottom={70}
            onClick={handleShowPayment}
            hasSmallerFontSize={hasSmallerFontSize}
          >
            {t('subscriptions.cancelOffer.button')}
          </S.Button>
        </S.Content>

        <Benefits onButtonClick={handleShowPayment} />

        <S.Content>
          <S.Subtitle marginBottom={30} laptopMarginBottom={55}>
            {t('subscriptions.cancelOffer.reviewsTitle')}
          </S.Subtitle>
          <S.Slider />
          <S.Button
            laptopMarginBottom={54}
            onClick={handleShowPayment}
            hasSmallerFontSize={hasSmallerFontSize}
          >
            {t('subscriptions.cancelOffer.button')}
          </S.Button>
          <S.GuaranteeCard>
            <S.GuaranteeImage
              src={`${CDN_FOLDER_LINK}/images/guarantee-stamp_${language}.png`}
              alt="guarantee stamp"
            />
            <S.Logo src={logo} alt="logo" height={30} />
            <S.LinksWrapper>
              <TermsOfUseLink />
              <PrivacyPolicyLink />
            </S.LinksWrapper>
          </S.GuaranteeCard>
        </S.Content>
      </S.Wrapper>
    </DesktopProvider>
  )
}
