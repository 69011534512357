import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { useLocation } from 'react-router'

import {
  resetErrorAction,
  setSubscriptionListAction,
} from 'root-redux/actions/common'

import { useDynamicPaywallConfig } from 'hooks/common/useDynamicPaywallConfig'
import { useProductId } from 'hooks/common/useProductId'
import { usePurchaseStore } from 'hooks/common/usePurchaseStore'
import { useVatInfo } from 'hooks/common/useVatInfo'

import { isPayPalAvailable } from 'helpers/isPayPalAvailable'

import { ScreenName, eventLogger } from 'services/eventLogger.service'

import { Divider } from 'modules/payment/components/Divider'
import { PaymentRequestButton } from 'modules/payment/components/PaymentRequestButton'
import { PrimerPayPalButton } from 'modules/payment/components/PrimerPayPalButton'
import { TotalAmountWithDiscount } from 'modules/payment/components/TotalAmountWithDiscount'

import paymentServices from 'assets/images/payment-services.png'

import { goBack, replaceHistory } from 'browser-history'
import { Language } from 'root-constants/common'
import { PageId } from 'root-constants/pages'

import { StripePaymentForm } from '../StripePaymentForm'
import { TotalAmount } from '../TotalAmount'
import { VatTotalAmount } from '../VatTotalAmount'
import { StyledPaymentFlows as S } from './PaymentFlows.styles'

export const PaymentFlows: React.FC = () => {
  const { t } = useTranslation()
  const { search } = useLocation()
  const dispatch = useDispatch()

  const {
    cohort,
    screenName,
    hasCancelOffer,
    language,
    dynamicDiscount,
    countryCode,
  } = usePurchaseStore()

  const productId = useProductId()
  const hasIncludedVAT = useVatInfo()
  const { title } = useDynamicPaywallConfig()

  const hasPaypal = isPayPalAvailable(cohort, countryCode.toLowerCase())

  const handleBack = () => {
    dispatch(resetErrorAction())
    eventLogger.logPurchaseScreenClosed({
      productId,
      screenName,
    })

    if (hasCancelOffer && language !== Language.DE) {
      screenName !== ScreenName.CANCEL_OFFER &&
        dispatch(setSubscriptionListAction([]))
      replaceHistory({ pathname: PageId.CANCEL_OFFER_SUBSCRIPTIONS_1, search })
      return
    }
    goBack()
  }

  const totalAmountDescription = useMemo(() => {
    if (dynamicDiscount && screenName !== ScreenName.CANCEL_OFFER) {
      return <TotalAmountWithDiscount />
    }
    if (hasIncludedVAT) {
      return <VatTotalAmount />
    }
    return <TotalAmount />
  }, [dynamicDiscount, hasIncludedVAT, screenName])

  return (
    <>
      <S.TitleWrapper>
        <S.CloseButton
          data-testid="close-btn"
          type="button"
          onClick={handleBack}
        />
        <S.Title>{title || t`payment.checkout`}</S.Title>
      </S.TitleWrapper>
      {totalAmountDescription}

      <StripePaymentForm hasLaptopStyles />
      {hasPaypal && <Divider />}

      <S.PaymentButtonsContainer>
        {hasPaypal && <PrimerPayPalButton />}
        <PaymentRequestButton hasLaptopStyles isDividerVisible={!hasPaypal} />
      </S.PaymentButtonsContainer>

      <S.PaymentServiceImage
        width="256px"
        src={paymentServices}
        decoding="async"
        alt="payments services"
      />
      <S.Guarantee>{t`payment.guarantee`}</S.Guarantee>
      <S.Description>{t`payment.description`}</S.Description>
    </>
  )
}
