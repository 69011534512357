import reasonsFriend from 'assets/images/reasons-friend.jpg'
import reasonsKid from 'assets/images/reasons-kid.jpg'
import reasonsPartner from 'assets/images/reasons-partner.jpg'

import { UserGoal } from 'root-constants/common'
import { PageId } from 'root-constants/pages'

export const QUESTION = 'Why do you want to keep an eye on them?'
export const QUESTION_MULTIPLE =
  'Why do you need to keep an eye on these people?'
export const PAGE_NAME_MULTIPLE = 'Goal multiple'

export const OPTION_VALUES = {
  PEACE: 'peace_of_mind',
  SAFE: 'ensure_they_are_safe',
  LOST: 'to_find_them',
  EMERGENCY: 'emergency',
  LOCATION: 'to_keep_track',
  NOTIFICATION: 'be_notified',
  HONEST: 'make_sure_they_are_honest',
  NEED_HELP: 'they_need_help',
}

export const PAGE_OPTION_VALUES = {
  [PageId.REASONS_1]: [
    OPTION_VALUES.PEACE,
    OPTION_VALUES.SAFE,
    OPTION_VALUES.HONEST,
    OPTION_VALUES.LOST,
    OPTION_VALUES.NOTIFICATION,
    OPTION_VALUES.EMERGENCY,
    OPTION_VALUES.LOCATION,
  ],
  [PageId.REASONS_2]: [
    OPTION_VALUES.PEACE,
    OPTION_VALUES.SAFE,
    OPTION_VALUES.NEED_HELP,
  ],
}

export const REASON_IMAGES = {
  [UserGoal.KID]: reasonsKid,
  [UserGoal.PARTNER]: reasonsPartner,
  [UserGoal.FRIEND]: reasonsFriend,
}

export const REASON_PAGE_NAMES = {
  [UserGoal.KID]: 'Goal kid',
  [UserGoal.PARTNER]: 'Goal partner',
  [UserGoal.FRIEND]: 'Goal friend',
}
export const PAGE_NAME = 'Reason'
