import styled from 'styled-components'

import { Option } from 'components/Option'

import { baseColumnStyles, basePageStyles } from 'common-styles'
import { HEADER_HEIGHT } from 'root-constants/common'

export const StyledGenderVariant2 = {
  Wrapper: styled.div`
    ${basePageStyles};
    min-height: 100%;
    padding-top: ${HEADER_HEIGHT}px;
  `,
  Column: styled.div`
    ${baseColumnStyles};
    text-align: center;
    margin: 0 auto;
    padding: 24px 0 10px;
    width: 327px;
  `,
  Title: styled.h1`
    font-weight: 700;
    font-size: 24px;
    line-height: 33px;
    text-align: center;
    margin: 0 0 12px 0;
  `,
  Subtitle: styled.p`
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
    text-align: center;
    margin: 0 0 36px 0;
  `,
  Option: styled(Option)`
    width: 100%;
  `,
}
