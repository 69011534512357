import React from 'react'
import { useTranslation } from 'react-i18next'

import { GOOGLE_6_BENEFITS_DATA } from 'modules/subscriptions/components/google/Benefits/constants'

import { StyledBenefitsVariant5 as S } from './BenefitsVariant5.styles'

type TProps = {
  benefits?: {
    image: string
    title: string
    subtitle: string
  }[]
}
export const BenefitsVariant5: React.FC<TProps> = ({
  benefits = GOOGLE_6_BENEFITS_DATA,
}) => {
  const { t } = useTranslation()

  return (
    <S.Wrapper>
      {benefits.map(({ image, title, subtitle }) => (
        <S.Content key={title}>
          <img src={image} alt="icon" />
          <S.TextWrapper>
            <S.Title>{t(title)}</S.Title>
            <S.Subtitle>{t(subtitle)}</S.Subtitle>
          </S.TextWrapper>
        </S.Content>
      ))}
    </S.Wrapper>
  )
}
