import styled from 'styled-components'

import {
  baseAdaptiveColumnStyles,
  baseAdaptiveTitleStyles,
  basePageStyles,
} from 'common-styles'
import { HEADER_HEIGHT, MediaBreakpoint } from 'root-constants/common'

export const StyledProtectiveOfPhone = {
  Wrapper: styled.div`
    ${basePageStyles};
    padding-top: ${HEADER_HEIGHT}px;
  `,
  Column: styled.div`
    ${baseAdaptiveColumnStyles};
    padding: 40px 25px;
  `,
  Title: styled.h1`
    ${baseAdaptiveTitleStyles};
    margin: 0 0 54px;

    @media (min-width: ${MediaBreakpoint.LAPTOP}px) {
      margin: 0 -40px 48px;
    }
  `,
}
