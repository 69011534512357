import styled, { css } from 'styled-components'

import {
  baseAdaptiveColumnStyles,
  baseAdaptiveTitleStyles,
  basePageStyles,
} from 'common-styles'
import { HEADER_HEIGHT, MediaBreakpoint } from 'root-constants/common'

const progressValueStyles = css`
  border-radius: 3px;
  transition: all 0.2s ease-out;
`

export const StyledPreparingFlow = {
  Wrapper: styled.div`
    ${basePageStyles};
    padding-top: ${HEADER_HEIGHT}px;
  `,
  Column: styled.div`
    ${baseAdaptiveColumnStyles};
    padding: 40px 0;
  `,
  Title: styled.h1`
    ${baseAdaptiveTitleStyles};
    margin-bottom: 112px;
    color: ${({ theme }) => theme.colors.primaryText};

    @media (min-width: ${MediaBreakpoint.LAPTOP}px) {
      margin-bottom: 96px;
    }
  `,
  ProgressBarsList: styled.ul`
    min-width: 311px;

    @media (min-width: ${MediaBreakpoint.LAPTOP}px) {
      min-width: 510px;
    }
  `,
  ProgressContainer: styled.li`
    width: 100%;
    margin-bottom: 32px;

    @media (min-width: ${MediaBreakpoint.LAPTOP}px) {
      margin-bottom: 48px;
    }
  `,
  ProgressHeader: styled.header`
    margin-bottom: 12px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  `,
  ProgressTitle: styled.span`
    font-weight: ${({ theme }) => theme.common.progressTitleFontWeight};
    font-size: ${({ theme }) => theme.common.progressTitleFontSize};
    line-height: 22px;
    color: ${({ theme }) => theme.colors.primaryText};

    @media (min-width: ${MediaBreakpoint.LAPTOP}px) {
      font-weight: 700;
      font-size: 20px;
      line-height: 27px;
    }
  `,

  ProgressPercent: styled.span`
    font-size: ${({ theme }) => theme.common.progressPercentFontSize};
    line-height: 20px;
    color: ${({ theme }) => theme.colors.progressPercentColor};

    @media (min-width: ${MediaBreakpoint.LAPTOP}px) {
      font-size: 15px;
    }
  `,
  ProgressBar: styled.progress<{ color: string }>`
    width: 100%;
    height: 6px;
    appearance: none;
    border: none;
    display: block;
    border-radius: 3px;
    overflow: hidden;
    background-color: ${({ theme }) => theme.colors.progressBarBackground};

    &::-webkit-progress-bar {
      background-color: ${({ theme }) => theme.colors.progressBarBackground};
    }
    &::-webkit-progress-value {
      ${progressValueStyles};
      background-color: ${({ color }) => color};
    }
    &::-moz-progress-bar {
      ${progressValueStyles};
      background-color: ${({ color }) => color};
    }
    &::-ms-fill {
      ${progressValueStyles};
      background-color: ${({ color }) => color};
    }
  `,
}
