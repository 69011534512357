export const PAGE_NAME = 'Location search'
export const LOCATION_DATA = [
  {
    name: 'onboarding.locationSearch.carrier',
    mockValue: 'Verizon',
  },
  {
    name: 'onboarding.locationSearch.country',
  },
  {
    name: 'onboarding.locationSearch.city',
    mockValue: 'Chicago',
  },
  {
    name: 'onboarding.locationSearch.location',
    hasLabel: true,
  },
]
