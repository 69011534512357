import React, { useLayoutEffect } from 'react'

import { Spinner } from 'components/Spinner'

export const ProxyToStart: React.FC = () => {
  useLayoutEffect(() => {
    window.location.href = window.location.origin
  }, [])

  return <Spinner />
}
