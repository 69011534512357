import React from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router'

import { useDelayedContinue } from 'hooks/useDelayedContinue'
import { useNextStep } from 'hooks/useNextStep'
import { useUserData } from 'hooks/useUserData'

import { TPageProps } from 'models/common.model'

import { DesktopProvider } from 'components/DesktopProvider'
import { Option, OptionType } from 'components/Option'
import { StyledOption } from 'components/Option/Option.styles'
import { QuestionButton } from 'components/QuestionButton'

import childInTroubleImage from 'assets/images/child-in-trouble.jpg'

import { Confidence, Language } from 'root-constants/common'
import { PageId } from 'root-constants/pages'

import { StyledChildInTrouble as S } from './ChildInTrouble.styles'
import { PAGE_NAME } from './constants'

export const ChildInTroubleVariant1: React.FC<TPageProps> = ({
  pageId,
  nextPagePath,
}) => {
  const { t } = useTranslation()
  const { search } = useLocation()

  const { isAnswersDisabled, handleChange, setIsAnswersDisabled } =
    useDelayedContinue()
  const { isTeenPath } = useUserData()

  const handleContinue = useNextStep({
    pageId,
    pageName: PAGE_NAME,
    question: t('onboarding.childInTrouble.question', { lng: Language.EN }),
    nextPagePath: isTeenPath
      ? `${PageId.LACK_OF_CONTACT_1}${search}`
      : nextPagePath,
  })

  const optionProps = {
    name: pageId,
    type: OptionType.RADIO,
    onChange: (value: string) => {
      handleChange(value, handleContinue)
      setIsAnswersDisabled(true)
    },
  }

  return (
    <DesktopProvider imageSrc={childInTroubleImage}>
      <S.Wrapper>
        <S.Column>
          <S.Title>{t`onboarding.childInTrouble.question`}</S.Title>
          <StyledOption.OptionsContainer>
            <Option
              {...optionProps}
              value={Confidence.YES}
              disabled={isAnswersDisabled}
            >
              <QuestionButton>{t`commonComponents.yes`}</QuestionButton>
            </Option>
            <Option
              {...optionProps}
              value={Confidence.NO}
              disabled={isAnswersDisabled}
            >
              <QuestionButton>{t`commonComponents.no`}</QuestionButton>
            </Option>
            <Option
              {...optionProps}
              value={Confidence.NOT_SURE}
              disabled={isAnswersDisabled}
            >
              <QuestionButton>{t`commonComponents.notSure`}</QuestionButton>
            </Option>
          </StyledOption.OptionsContainer>
        </S.Column>
      </S.Wrapper>
    </DesktopProvider>
  )
}
