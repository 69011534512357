import { useSelector } from 'react-redux'

import { createProductId } from 'helpers/createProductId'

import {
  selectSubscriptionFullPrice,
  selectSubscriptionPeriodName,
  selectSubscriptionPeriodQuantity,
  selectSubscriptionTrialPeriodPrice,
} from 'modules/payment/redux/selects'

export const useProductId = () => {
  const periodName = useSelector(selectSubscriptionPeriodName)
  const periodQuantity = useSelector(selectSubscriptionPeriodQuantity)
  const price = useSelector(selectSubscriptionFullPrice)
  const trialPrice = useSelector(selectSubscriptionTrialPeriodPrice)

  return createProductId({
    periodName,
    periodQuantity,
    price: trialPrice || price,
  })
}
