import styled from 'styled-components'

import { Button } from 'components/Button'

import checkboxViolet from 'assets/images/checkbox-violet.svg'
import bgImg from 'assets/images/subscriptions-bg.svg'

import {
  baseColumnStyles,
  basePageStyles,
  baseTitleStyles,
} from 'common-styles'
import { Color, MediaBreakpoint } from 'root-constants/common'

type TButtonProps = {
  marginBottom?: number
}

export const StyledPurchaseUpgrade = {
  Wrapper: styled.div`
    ${basePageStyles};
    padding-top: 85px;
  `,
  Column: styled.div`
    ${baseColumnStyles};
    z-index: 2;

    &[data-has-bg] {
      position: relative;

      ::before {
        content: '';
        display: block;
        width: 100vw;
        aspect-ratio: 1/1;
        background-image: url(${bgImg});
        background-size: contain;
        background-repeat: no-repeat;
        position: absolute;
        top: 165px;
        z-index: -1;
      }
    }
  `,
  Title: styled.h1`
    font-size: 32px;
    line-height: 44px;
    font-weight: 800;
    text-align: center;
    margin-bottom: 12px;
  `,
  Text: styled.span`
    font-size: 18px;
    font-weight: 600;
    line-height: 24px;
    text-align: center;
    margin-bottom: 32px;
  `,
  Subtitle: styled.h2<{ marginBottom: number }>`
    ${baseTitleStyles};
    margin-bottom: ${({ marginBottom }) => marginBottom}px;
  `,
  ImageContainer: styled.div`
    max-width: ${MediaBreakpoint.MAX_PHONE}px;
    aspect-ratio: 375/302;

    @media (min-width: ${MediaBreakpoint.MAX_PHONE}px) {
      max-width: 375px;
    }
  `,
  Review: styled.div`
    max-width: 315px;
    display: grid;
    grid-template-columns: 46px 1fr;
    grid-template-rows: auto;
    grid-template-areas: 'name stars' 'review review';
    grid-gap: 12px 6px;
    align-items: center;
    padding: 16px;
    margin-bottom: 30px;
  `,
  Name: styled.span`
    grid-area: name;
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
  `,
  Stars: styled.img`
    grid-area: stars;
    max-width: 86px;
  `,
  ReviewText: styled.p`
    grid-area: review;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
  `,
  BenefitsList: styled.ul`
    margin-bottom: 26px;
  `,
  Benefit: styled.li`
    position: relative;
    font-weight: 700;
    margin-bottom: 20px;
    padding-left: 35px;
    font-size: 16px;
    line-height: 24px;

    &:last-of-type {
      margin-bottom: 0;
    }

    ::before {
      content: url(${checkboxViolet});
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
      display: block;
    }
  `,
  Button: styled(Button)<TButtonProps>`
    height: 56px;
    line-height: 56px;
    margin-bottom: ${({ marginBottom }) => `${marginBottom || 32}px`};
  `,
  SubscriptionDescription: styled.p`
    margin-bottom: 45px;
    font-weight: 400;
    font-size: 11px;
    line-height: 18px;
    text-align: center;
    color: ${Color.LIGHT_TEXT};

    & > a {
      color: inherit;

      &[href^='mailto:'] {
        font-weight: 700;
      }

      &:not([href^='mailto:']) {
        font-weight: inherit;
        text-decoration: underline;
      }
    }
  `,
  Disclaimer: styled.p`
    margin-bottom: 40px;
    color: ${Color.LIGHT_TEXT};
    text-align: center;
    font-size: 11px;
    font-weight: 400;
    line-height: 18px;
  `,
}
