import styled from 'styled-components'

import arrowDown from 'assets/images/arrow-violet-down.svg'
import arrowUp from 'assets/images/arrow-violet-up.svg'

import { Color, MediaBreakpoint } from 'root-constants/common'

type TProps = {
  isShown: boolean
}
export const StyledDisclaimer = {
  Wrapper: styled.div`
    display: flex;
    flex-direction: column;
    margin-bottom: 40px;
    max-width: 327px;
    width: 100%;

    @media (min-width: ${MediaBreakpoint.LAPTOP}px) {
      max-width: 1080px;
      margin-top: 16px;
      margin-bottom: 56px;
    }
  `,
  Title: styled.p`
    font-size: 14px;
    font-weight: 700;
    line-height: 16px;
    letter-spacing: 0.5px;
    color: #616a77;
    margin-bottom: 8px;
  `,
  DesktopText: styled.p`
    color: #616a77;
    font-size: 13px;
    font-weight: 400;
    line-height: 16px;
    display: none;

    @media (min-width: ${MediaBreakpoint.LAPTOP}px) {
      display: block;
    }
  `,
  MobileWrapper: styled.div`
    display: flex;
    flex-direction: column;
    position: relative;

    @media (min-width: ${MediaBreakpoint.LAPTOP}px) {
      display: none;
    }
  `,
  Text: styled.p`
    color: #616a77;
    font-size: 13px;
    font-weight: 400;
    line-height: 16px;
  `,
  SwitcherText: styled.span<TProps>`
    display: ${({ isShown }) => (isShown ? 'inline' : 'block')};
    font-size: 13px;
    font-weight: 600;
    line-height: 16px;
    color: ${Color.ACCENT_VIOLET};
    max-width: fit-content;
    width: 100%;
    cursor: pointer;
    position: relative;
    -webkit-tap-highlight-color: transparent;
    margin-left: ${({ isShown }) => (isShown ? 5 : 0)}px;
    padding-right: 2px;

    &:after {
      display: block;
      content: '';
      position: absolute;
      right: -15px;
      top: 0;
      background-image: ${({ isShown }) =>
        isShown ? `url(${arrowUp})` : `url(${arrowDown})`};
      width: 16px;
      height: 16px;
      background-repeat: no-repeat;
      background-position: center;
    }
  `,
}
