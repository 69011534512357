import styled from 'styled-components'

import { Color } from 'root-constants/common'

export const StyledSelectPaymentMethod = {
  Wrapper: styled.div`
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    z-index: 3;
    backdrop-filter: blur(2px);
    transition: opacity 0.25s;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
  `,
  PaymentMethodPanel: styled.div`
    border-radius: 12px 12px 0 0;
    background-color: ${Color.WHITE};
    padding: 16px;
  `,
  Header: styled.header`
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 16px;
  `,
  Title: styled.h4`
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
  `,
  PaymentMethodControls: styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 9px;
  `,
  Label: styled.label`
    cursor: pointer;
  `,
  Checkbox: styled.input`
    position: absolute;
    z-index: -1;
    opacity: 0;
    visibility: hidden;

    &:checked ~ div {
      box-shadow: 0 0 0 2px ${Color.ACCENT_VIOLET};
      background-color: #f5f3ff;

      & span {
        color: ${Color.DEFAULT_TEXT};
      }
    }
  `,
  PaymentMethodControl: styled.div`
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    align-content: center;
    justify-content: center;
    border-radius: 8px;
    box-shadow: 0 0 0 1px #bcc9d0;
    background: ${Color.WHITE};
    height: 66px;

    & svg {
      margin-bottom: 6px;
    }

    & span {
      flex-basis: 100%;
      text-align: center;
      font-size: 14px;
      font-style: normal;
      font-weight: 700;
      line-height: 16px;
      letter-spacing: 0.5px;
      color: ${Color.LIGHT_TEXT};
    }
  `,
  CloseButton: styled.button`
    all: unset;
    display: flex;
    cursor: pointer;
    align-items: center;
  `,
}
