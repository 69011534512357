import React, { useCallback } from 'react'

import lottie from 'lottie-web/build/player/lottie_light'

import spinnerAnimation from 'assets/animations/spinner-animation.json'

import { StyledSpinner as S } from './Spinner.styles'

type TProps = {
  isFullScreen?: boolean
}

export const Spinner: React.FC<TProps> = ({ isFullScreen = true }) => {
  const beforeAfterAnimationRef = useCallback((node: HTMLDivElement) => {
    if (node) {
      lottie.loadAnimation({
        container: node,
        animationData: spinnerAnimation,
        loop: true,
      })
    }
  }, [])

  return isFullScreen ? (
    <S.Wrapper>
      <div ref={beforeAfterAnimationRef} />
    </S.Wrapper>
  ) : (
    <div ref={beforeAfterAnimationRef} />
  )
}
