import React from 'react'
import { useTranslation } from 'react-i18next'

import { POSSIBILITIES_DATA } from 'modules/subscriptions/components/google/Benefits/constants'

import { StyledBenefitsVariant4 as S } from './BenefitsVariant4.styles'

export const BenefitsVariant4: React.FC = () => {
  const { t } = useTranslation()

  return (
    <S.Wrapper id="2">
      <S.Title>{t`subscriptions.subscriptionsGoogle.variant1.appBenefits.title`}</S.Title>

      {POSSIBILITIES_DATA.map(({ image, title, text }) => (
        <S.BenefitContainer key={title}>
          <S.Image src={image} alt="benefit-icon" />
          <S.BenefitTitle>{t(title)}</S.BenefitTitle>
          <S.Text>{t(text)}</S.Text>
        </S.BenefitContainer>
      ))}
    </S.Wrapper>
  )
}
