import React, { useEffect, useMemo, useRef } from 'react'
import { CircularProgressbarWithChildren } from 'react-circular-progressbar'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { selectAnswers } from 'root-redux/selects/common'

import { useCounter } from 'hooks/useCounter'
import { useNextStep } from 'hooks/useNextStep'

import { TPageProps } from 'models/common.model'

import { Color } from 'root-constants/common'
import { PageId } from 'root-constants/pages'

import { StyledPreparingFlowVariant4 as S } from './PreparingFlowVariant4.styles'
import {
  CHILD_FLOW_PROGRESS_DURATION,
  CHILD_FLOW_PROGRESS_TEXTS,
} from './constants'

export const PreparingFlowVariant4: React.FC<TPageProps> = ({
  pageId,
  nextPagePath,
}) => {
  const { t } = useTranslation()
  const counterRef = useRef<number>(0)
  const answers = useSelector(selectAnswers)

  const handleContinue = useNextStep({
    pageId,
    pageName: pageId,
    question: '',
    nextPagePath,
  })

  const { linearCounterValue } = useCounter(true, {
    duration: CHILD_FLOW_PROGRESS_DURATION,
    callback: handleContinue,
  })

  const { textVisibilityTime, trackingPointTexts } = useMemo(() => {
    const trackingPoints = (answers?.[PageId.DATA_3] as string[]) || []
    const textTime = Math.ceil(100 / trackingPoints.length)
    const trackingTexts = trackingPoints.map((key) =>
      t(CHILD_FLOW_PROGRESS_TEXTS[key]).toLowerCase(),
    )

    return {
      textVisibilityTime: textTime,
      trackingPointTexts: trackingTexts,
    }
  }, [answers, t])

  useEffect(() => {
    if (
      linearCounterValue &&
      !(linearCounterValue % textVisibilityTime) &&
      counterRef.current < trackingPointTexts.length
    ) {
      counterRef.current += 1
    }
  }, [
    linearCounterValue,
    textVisibilityTime,
    trackingPointTexts,
    trackingPointTexts.length,
  ])

  return (
    <S.Wrapper>
      <S.Column>
        <S.Title>{t`onboarding.preparingFlow.title`}</S.Title>
        <S.ProgressBarContainer>
          <svg style={{ height: 0, width: 0, position: 'absolute' }}>
            <defs>
              <linearGradient id="progress">
                <stop offset="0" stopColor="#eae4ff" />
                <stop offset="1" stopColor="#7854f8" />
              </linearGradient>
            </defs>
          </svg>
          <CircularProgressbarWithChildren
            value={linearCounterValue}
            styles={{
              path: {
                stroke: 'url(#progress)',
                strokeLinecap: 'round',
                strokeWidth: '8px',
                transition: 'all 0.5s ease-out',
              },
              trail: {
                stroke: Color.GREY,
                strokeWidth: '8px',
              },
            }}
          >
            <S.ProgressValue>{linearCounterValue}%</S.ProgressValue>
          </CircularProgressbarWithChildren>
        </S.ProgressBarContainer>
        <S.Text key={trackingPointTexts[counterRef.current]}>
          {t('onboarding.preparingFlow.subtitle', {
            value: trackingPointTexts[counterRef.current],
          })}
        </S.Text>
      </S.Column>
    </S.Wrapper>
  )
}
