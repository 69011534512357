import React from 'react'
import { useTranslation } from 'react-i18next'

import { usePurchaseStore } from 'hooks/usePurchaseStore'

import { DISCOUNT_UPSELL } from 'modules/subscriptions/constants'

import {
  CURRENCY_SYMBOLS,
  PERIOD_NAME_TO_MARKUP_MAP,
} from 'root-constants/common'

import { StyledPlanBlockVariant1 as S } from './PlanBlockVariant1.styles'

export const PlanBlockVariant1: React.FC = () => {
  const { t } = useTranslation()

  const { periodQuantity, periodName, currency, amountToPay } =
    usePurchaseStore()

  const oldPrice = amountToPay * 2

  return (
    <S.Wrapper>
      <S.Title>{t('subscriptions.purchaseUpgrade.plan.title')}</S.Title>
      <S.PriceContainer
        data-plan-period={t('subscriptions.purchaseUpgrade.plan.period', {
          quantity: periodQuantity,
          period: t(PERIOD_NAME_TO_MARKUP_MAP[periodName], {
            count: periodQuantity,
          }),
        })}
      >
        <S.OldPriceText>
          {t('subscriptions.purchaseUpgrade.plan.oldPrice')}
        </S.OldPriceText>
        <S.OldPrice>
          {t('subscriptions.planBlock.price', {
            price: oldPrice,
            currencySymbol: CURRENCY_SYMBOLS[currency],
            minimumFractionDigits: 2,
          })}
        </S.OldPrice>

        <S.Discount>
          {t('subscriptions.purchaseUpgrade.plan.discount', {
            discount: DISCOUNT_UPSELL,
          })}
        </S.Discount>

        <S.PriceText>
          {t('subscriptions.purchaseUpgrade.plan.newPrice')}
        </S.PriceText>
        <S.Price>
          {t('subscriptions.planBlock.price', {
            price: amountToPay,
            currencySymbol: CURRENCY_SYMBOLS[currency],
            minimumFractionDigits: 2,
          })}
        </S.Price>
      </S.PriceContainer>
    </S.Wrapper>
  )
}
