import { useMemo } from 'react'
import { useSelector } from 'react-redux'

import { usePageInfo } from 'providers/PageInfoProvider'

import { selectAnswers, selectLanguage } from 'root-redux/selects/common'

import { getDynamicPersonalization } from 'helpers/getDynamicPersonalization'

export const useDynamicOBConfig = () => {
  const language = useSelector(selectLanguage)
  const userAnswers = useSelector(selectAnswers)

  const { currentPageIndex, steps } = usePageInfo()

  return useMemo(() => {
    const dynamicOBConfig = steps[currentPageIndex].onboardingDynamicConfig

    return {
      title: getDynamicPersonalization(
        dynamicOBConfig?.[language]?.title,
        userAnswers,
        dynamicOBConfig?.[language]?.personalization,
      ),
      subtitle: getDynamicPersonalization(
        dynamicOBConfig?.[language]?.subtitle,
        userAnswers,
        dynamicOBConfig?.[language]?.personalization,
      ),
      buttonText: dynamicOBConfig?.[language]?.buttonText,
      imageUrls: dynamicOBConfig?.[language]?.imageUrls,
      pageTexts: (dynamicOBConfig?.[language]?.pageTexts || []).map((text) =>
        getDynamicPersonalization(
          text,
          userAnswers,
          dynamicOBConfig?.[language]?.personalization,
        ),
      ),
    }
  }, [currentPageIndex, language, steps, userAnswers])
}
