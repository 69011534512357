import styled from 'styled-components'

import { Button } from 'components/Button'

import blueCheck from 'assets/images/check-icon-blue.svg'
import badge from 'assets/images/violet-badge.svg'

import { Color, MediaBreakpoint } from 'root-constants/common'

type TContentProps = {
  hasOldPrice: boolean
}

type TPriceProps = {
  gridArea: string
}

type TDurationBadgeProps = {
  isFrenchLocale: boolean
}

export const StyledSubscriptionsBlockVariant1 = {
  Wrapper: styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 48px;
  `,
  Title: styled.h3`
    max-width: 284px;
    margin: 48px auto 24px;
    text-align: center;
    font-size: 24px;
    font-weight: 800;
    line-height: 28px;
  `,
  Content: styled.div<TContentProps>`
    position: relative;
    display: grid;
    grid-template-columns: 1fr 75px 1fr;
    grid-template-rows: auto;
    grid-template-areas:
      'title title title'
      ${({ hasOldPrice }) => hasOldPrice && `'oldTrialPrice . oldPrice'`}
      'familyGift familyGift familyGift'
      'trialPrice durationBadge fullPrice'
      'trialText durationBadge fullPriceText'
      'disclaimer disclaimer disclaimer'
      'benefits benefits benefits'
      'button button button';
    grid-column-gap: 8px;
    max-width: ${MediaBreakpoint.MAX_PHONE}px;
    padding: 32px;
    border-radius: 20px;
    background: linear-gradient(179deg, #f0f5ff 26.53%, #f0e4ff 99.42%);

    @media (min-width: ${MediaBreakpoint.MAX_PHONE}px) {
      max-width: 375px;
    }
  `,
  ContentTitle: styled.span`
    grid-area: title;
    max-width: 140px;
    justify-self: center;
    margin-bottom: 16px;
    padding-bottom: 16px;
    border-bottom: 1px solid ${Color.BLUE};
    font-size: 24px;
    font-weight: 700;
    line-height: 28px;
  `,
  OfferBadge: styled.img`
    max-width: 100px;
    position: absolute;
    top: -30px;
    right: 16px;
  `,
  DiscountBadge: styled.img`
    max-width: 112px;
    position: absolute;
    top: -35px;
    right: 5px;
  `,
  FamilyPlanWrapper: styled.div`
    grid-area: familyGift;
    border-radius: 16px;
    background: rgba(92, 144, 255, 0.1);
    display: flex;
    max-width: 311px;
    width: 100%;
    margin-bottom: 16px;
    max-height: 80px;

    img {
      width: 64px;
      height: 96px;
      position: relative;
      top: -20px;
    }
  `,
  FamilyTextWrapper: styled.div`
    padding: 8px 12px 8px 0;
  `,
  FamilyTextTitle: styled.p`
    color: #5c90ff;
    font-size: 14px;
    font-weight: 700;
    line-height: 20px;
    margin-bottom: 4px;
  `,
  FamilyText: styled.p`
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
  `,
  OldPriceValue: styled.p<TPriceProps>`
    grid-area: ${({ gridArea }) => gridArea};
    color: ${Color.LIGHT_TEXT};
    text-align: center;
    font-size: 14px;
    font-weight: 500;
    line-height: 18px;
    text-decoration: line-through;
    text-decoration-color: #fc5774;
  `,
  PriceValue: styled.p<TPriceProps>`
    grid-area: ${({ gridArea }) => gridArea};
    margin-bottom: 4px;
    color: ${Color.BLUE};
    text-align: center;
    font-size: 24px;
    font-weight: 800;
    line-height: 28px;
  `,
  PriceText: styled.p<TPriceProps>`
    grid-area: ${({ gridArea }) => gridArea};
    margin-bottom: 4px;
    text-align: center;
    font-size: 14px;
    font-weight: 500;
    line-height: 18px;
  `,
  DurationBadge: styled.div<TDurationBadgeProps>`
    grid-area: durationBadge;
    align-self: center;
    margin-bottom: 10px;
    padding: 6px 13px 6px 6px;
    background-image: url(${badge});
    background-repeat: no-repeat;
    background-size: 100%;
    color: ${Color.WHITE};
    text-align: center;
    font-size: ${({ isFrenchLocale }) => (isFrenchLocale ? '12px' : '13px')};
    font-weight: 700;
    line-height: 20px;
  `,
  Disclaimer: styled.p`
    grid-area: disclaimer;
    margin: 24px 0;
    padding: 12px;
    border-radius: 16px;
    background-color: ${Color.WHITE};
    color: ${Color.BLUE};
    text-align: center;
    font-size: 14px;
    font-style: italic;
    font-weight: 600;
    line-height: 20px;
  `,
  Benefits: styled.div`
    grid-area: benefits;
    margin-bottom: 24px;
  `,
  BenefitItem: styled.p`
    position: relative;
    margin-bottom: 14px;
    padding-left: 40px;
    font-size: 16px;
    font-weight: 600;
    line-height: 22px;

    &::before {
      content: '';
      position: absolute;
      top: -5px;
      left: 0;
      width: 32px;
      height: 32px;
      background: url(${blueCheck}) center no-repeat;
      background-size: contain;
    }
  `,
  Button: styled(Button)`
    grid-area: button;
  `,
}
