import React, { useEffect, useLayoutEffect } from 'react'
import { useLocation } from 'react-router'

import { usePageInfo } from 'providers/PageInfoProvider'

import { useCookieConsentAnswer } from 'hooks/useCookieConsentAnswer'
import { useProductId } from 'hooks/useProductId'
import { usePurchaseStore } from 'hooks/usePurchaseStore'

import { TPageProps } from 'models/common.model'

import { eventLogger } from 'services/eventLogger.service'

import { useIsCheckoutReady } from 'modules/payment/hooks/useIsCheckoutReady'

import { DesktopProvider } from 'components/DesktopProvider/DesktopProvider'
import { Spinner } from 'components/Spinner'
import { StripePaymentProcessing } from 'components/StripePaymentProcessing'

import { replaceHistory } from 'browser-history'

import { PaymentFlows } from '../../components/PaymentFlows'
import { StyledPaymentVariant1 as S } from './PaymentVariant1.styles'

export const PaymentVariant1: React.FC<TPageProps> = () => {
  const { search } = useLocation()

  const {
    email,
    hasSubscription,
    selectedSubscriptionId,
    stripeAccountId,
    stripeAccountName,
    screenName,
    threeDSecureIframeUrl,
  } = usePurchaseStore()

  const productId = useProductId()
  const { currentSubscriptionPageId } = usePageInfo()
  const { isPersonalDataAllowed } = useCookieConsentAnswer()
  const isCheckoutReady = useIsCheckoutReady()

  useLayoutEffect(() => {
    if (!selectedSubscriptionId) {
      replaceHistory({ pathname: currentSubscriptionPageId, search })
    }
  }, [currentSubscriptionPageId, search, selectedSubscriptionId])

  useEffect(() => {
    if (hasSubscription || !selectedSubscriptionId) return

    eventLogger.logPurchaseShown({
      screenName,
      productId,
      stripeAccountName,
      stripeAccountId,
    })
    window.ttq.identify({ email: isPersonalDataAllowed ? email : '' })
    window.ttq.track('AddToCart')
  }, [
    email,
    hasSubscription,
    isPersonalDataAllowed,
    productId,
    screenName,
    selectedSubscriptionId,
    stripeAccountId,
    stripeAccountName,
  ])

  return (
    <DesktopProvider>
      <S.Wrapper>
        {threeDSecureIframeUrl ? (
          <S.ThreeDSecureIframe title="3DSecure" src={threeDSecureIframeUrl} />
        ) : (
          <>
            <StripePaymentProcessing />
            <S.Content>
              <PaymentFlows />
            </S.Content>
          </>
        )}
        {!isCheckoutReady && <Spinner />}
      </S.Wrapper>
    </DesktopProvider>
  )
}
