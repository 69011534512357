import React, { useEffect, useRef } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { selectSubscriptionList } from 'root-redux/selects/common'

import { useGetPrices } from 'hooks/useGetPrices'
import { usePaywall } from 'hooks/usePaywall'

import { compareSubscriptionList } from 'helpers/compareSubscriptionList'
import { createProductId } from 'helpers/createProductId'

import { TPageProps } from 'models/common.model'

import { ScreenName, eventLogger } from 'services/eventLogger.service'

import { SubheaderWithTimer } from 'modules/subscriptions/components/common/SubheaderWithTimer'
import { Timer } from 'modules/subscriptions/components/common/Timer'
import { PlanItemVariant1 } from 'modules/subscriptions/components/email-subscriptions/PlanItemVariant1'
import { PARTNER_FLOW_BENEFITS } from 'modules/subscriptions/pages/EmailSubscriptions/constants'

import { Guarantee } from 'components/Guarantee'
import { ReviewsSlider } from 'components/ReviewsSlider'
import { Security } from 'components/Security'
import { Spinner } from 'components/Spinner'

import partnersImg from 'assets/images/email-subscriptions.jpg'

import { SubscriptionTag } from 'root-constants/common'

import { StyledEmailSubscriptions as S } from './EmailSubscriptions.styles'

export const EmailSubscriptionsVariant1: React.FC<TPageProps> = ({
  nextPagePath,
}) => {
  const { t } = useTranslation()
  const scrollToElement = useRef<HTMLButtonElement>(null)
  const timerTriggerElementRef = useRef(null)
  const subscriptions = useSelector(
    selectSubscriptionList,
    compareSubscriptionList,
  )

  const { hasPrices, handleShowPayment } = usePaywall(nextPagePath)

  useGetPrices({ tags: SubscriptionTag.NO_TAX })

  useEffect(() => {
    if (!subscriptions.length) return

    eventLogger.logSalePageShown({
      productIds: subscriptions.map(
        ({ mainPrices: { periodName, periodQuantity, fullPrice } }) =>
          createProductId({
            periodName,
            periodQuantity,
            price: fullPrice,
          }),
      ),
      screenName: ScreenName.ONBOARDING,
    })
  }, [subscriptions])

  const handleTimerButtonClick = () => {
    scrollToElement.current?.scrollIntoView({
      block: 'center',
      behavior: 'smooth',
    })
  }

  return !hasPrices ? (
    <Spinner />
  ) : (
    <>
      <S.BurgerMenu />
      <SubheaderWithTimer
        elemForComparisonRef={timerTriggerElementRef}
        onClick={handleTimerButtonClick}
      />
      <S.Wrapper>
        <S.Column>
          <S.Title>{t`subscriptions.email.titlePartner`}</S.Title>

          <S.Text>{t`subscriptions.variant9.subtitlePartner`}</S.Text>
          <Timer />
        </S.Column>

        <S.PartnerImageContainer>
          <img ref={timerTriggerElementRef} src={partnersImg} alt="partners" />
        </S.PartnerImageContainer>

        <S.Column data-has-bg>
          <S.Subtitle marginBottom={27}>
            {t`subscriptions.benefits.alternativeTitle`}
          </S.Subtitle>
          <S.List>
            {PARTNER_FLOW_BENEFITS.map((text) => (
              <S.ContentItem key={text}>
                <Trans i18nKey={text} />
              </S.ContentItem>
            ))}
          </S.List>

          <S.Subtitle marginBottom={16}>
            {t`subscriptions.email.priceToday`}
          </S.Subtitle>
          <PlanItemVariant1 />
          <S.Button
            onClick={handleShowPayment}
            marginBottom={12}
            ref={scrollToElement}
          >
            {t`actions.start`}
          </S.Button>
          <S.SubscriptionDescription />
        </S.Column>
        <S.Column>
          <Guarantee />
          <ReviewsSlider />
          <S.Button onClick={handleShowPayment} marginBottom={24}>
            {t`actions.start`}
          </S.Button>
          <Security />
        </S.Column>
      </S.Wrapper>
    </>
  )
}
