import { LANG_QUERY_PARAM } from 'root-constants/common'

export const getPathFromPageId = ({
  pageId,
  cohort,
  uuid,
  language,
  currentSearch,
  hash,
}: {
  pageId: string
  cohort: string
  uuid: string
  language: string
  currentSearch: string
  hash: string
}): string => {
  const searchParams = new URLSearchParams(currentSearch)

  searchParams.set('cohort', cohort)
  searchParams.set('uuid', uuid)
  searchParams.set(LANG_QUERY_PARAM, language)

  return `${pageId}?${searchParams.toString()}${hash}`
}
