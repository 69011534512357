import { IPaymentConfig, IPaymentConfigRaw } from 'models/config.model'

export const getPaymentConfigFromConfigRaw = (
  paymentConfig: IPaymentConfigRaw,
): IPaymentConfig => ({
  stripe: {
    accountId: paymentConfig.stripe.account_id,
    accountName: paymentConfig.stripe.account_name,
    publicKey: paymentConfig.stripe.public_key,
  },
  paypal: {
    clientId: paymentConfig.paypal.client_id,
  },
})
