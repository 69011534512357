import React, { useRef } from 'react'
import { Trans, useTranslation } from 'react-i18next'

import { useBulkSelector } from 'hooks/common/useBulkSelector'

import { FIVE_MINUTES_IN_SECONDS } from 'modules/payment/components/DiscountWithTimer/constants'
import {
  selectCurrency,
  selectSubscriptionTrialPeriodDailyPrice,
} from 'modules/payment/redux/selects'
import { useTimerForTarget } from 'modules/subscriptions/hooks/useTimerForTarget'

import { CURRENCY_SYMBOLS } from 'root-constants/common'

import { StyledGetPlanButton as S } from './GetPlanButton.styles'

type TProps = {
  isVisible: boolean
  onClick: () => void
}

export const TIMER_START_MINUTES = '00'
export const TIMER_START_SECONDS = '00'

const useSelectors = () =>
  useBulkSelector({
    currency: selectCurrency,
    dailyPrice: selectSubscriptionTrialPeriodDailyPrice,
  })

export const GetPlanButton: React.FC<TProps> = ({ isVisible, onClick }) => {
  const timerElementRef = useRef<HTMLDivElement>(null)

  const { currency, dailyPrice } = useSelectors()
  const { t } = useTranslation()
  const { minutes, seconds } = useTimerForTarget(
    timerElementRef,
    FIVE_MINUTES_IN_SECONDS,
  )

  return (
    <S.Button isVisible={isVisible} onClick={onClick}>
      <S.ButtonContent>
        <S.TimerBlock ref={timerElementRef}>
          <S.TimerValue>
            <S.Value>{minutes || TIMER_START_MINUTES}</S.Value>
            <S.Text>{t('commonComponents.min')}</S.Text>
          </S.TimerValue>
          <S.Divider>:</S.Divider>
          <S.TimerValue>
            <S.Value>{seconds || TIMER_START_SECONDS}</S.Value>
            <S.Text>{t('commonComponents.sec')}</S.Text>
          </S.TimerValue>
        </S.TimerBlock>
        <S.PromoSlogan>
          <Trans
            i18nKey="payment.dailyPromoSlogan"
            values={{
              currency: CURRENCY_SYMBOLS[currency],
              dailyPrice: dailyPrice.toFixed(2),
            }}
          />
        </S.PromoSlogan>
        <S.Label>{t('actions.get')}</S.Label>
      </S.ButtonContent>
    </S.Button>
  )
}
