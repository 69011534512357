import styled from 'styled-components'

import { Color } from 'root-constants/common'

type TWrapperProps = {
  backgroundColor: string
}
type TTitleProps = {
  color: string
}
export const StyledDiscountWithTimer = {
  Wrapper: styled.div<TWrapperProps>`
    display: flex;
    padding: 12px;
    align-items: center;
    gap: 16px;
    align-self: stretch;
    border-radius: 16px;
    background: ${({ backgroundColor }) => backgroundColor};
    margin-bottom: 54px;
    position: sticky;
    top: 0;
    z-index: 1;
  `,
  Discount: styled.div<TWrapperProps>`
    display: flex;
    padding: 4px 12px;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    background: ${({ backgroundColor }) => backgroundColor};
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: 0.5px;
    text-transform: uppercase;
    text-align: center;
    color: ${Color.WHITE};
    max-width: 130px;
  `,
  TextWrapper: styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    max-width: 150px;
  `,
  Title: styled.p<TTitleProps>`
    font-size: 17px;
    font-weight: 700;
    line-height: 24px;
    color: ${({ color }) => color};
  `,
  Text: styled.p`
    font-size: 15px;
    font-weight: 600;
    line-height: 20px;
  `,
}
