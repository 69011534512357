import styled from 'styled-components'

import { basePageStyles } from 'common-styles'
import { HEADER_HEIGHT, MediaBreakpoint } from 'root-constants/common'

export const StyledPinpointLocationVariant1 = {
  Wrapper: styled.div`
    ${basePageStyles};
    padding-top: ${HEADER_HEIGHT}px;
  `,
  Content: styled.div`
    width: 100%;
    max-width: ${MediaBreakpoint.MAX_PHONE}px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding-bottom: 130px;

    @media (min-width: ${MediaBreakpoint.MAX_PHONE}px) {
      max-width: 375px;
    }
  `,
  ImageContainer: styled.div`
    width: 100%;
    aspect-ratio: 375/321;
  `,
  Title: styled.h1`
    text-align: center;
    font-size: 32px;
    font-weight: 800;
    line-height: 44px;
  `,
}
