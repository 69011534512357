import styled from 'styled-components'

export const StyledPoliciesGroup = {
  Wrapper: styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 6px;
    font-size: 13px;
    font-weight: 400;
    line-height: 20px;
    color: ${({ theme }) => theme.colors.footerLinkColor};
  `,
  Links: styled.div`
    display: flex;
    gap: 14px;
    align-items: center;
    margin: 0 -10px;

    a {
      color: inherit;
      text-decoration: underline;
    }
  `,
}
