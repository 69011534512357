import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { useDelayedContinue } from 'hooks/useDelayedContinue'
import { useNextStep } from 'hooks/useNextStep'

import { TPageProps } from 'models/common.model'

import { Option, OptionType } from 'components/Option'
import { StyledOption } from 'components/Option/Option.styles'
import { QuestionButton } from 'components/QuestionButton'

import { HEADER_HEIGHT } from 'root-constants/common'

import { StyledRadioOptionPage as S } from './RadioOptionPage.styles'

type TRadioPageProps = {
  pageName?: string
  options: { value: string; text: string }[]
  titlePath: string
  titleFontSize?: number
  titleLineHeight?: number
  marginBottom?: number
  wrapperPaddingTop?: number
  columnPaddingTop?: number
} & TPageProps

export const RadioOptionPage: React.FC<TRadioPageProps> = ({
  pageName,
  options,
  titlePath,
  titleFontSize,
  titleLineHeight,
  pageId,
  nextPagePath,
  marginBottom,
  wrapperPaddingTop = HEADER_HEIGHT,
  columnPaddingTop,
}) => {
  const { t } = useTranslation()
  const { isAnswersDisabled, handleChange, setIsAnswersDisabled } =
    useDelayedContinue()

  const handleContinue = useNextStep({
    pageId,
    pageName: pageName || pageId,
    question: t(titlePath, { lng: 'en' }),
    nextPagePath,
  })

  const optionProps = useMemo(
    () => ({
      name: pageId,
      type: OptionType.RADIO,
      onChange: (value: string) => {
        handleChange(value, handleContinue)
        setIsAnswersDisabled(true)
      },
    }),
    [pageId, handleChange, handleContinue, setIsAnswersDisabled],
  )

  return (
    <S.Wrapper wrapperPaddingTop={wrapperPaddingTop}>
      <S.Column columnPaddingTop={columnPaddingTop}>
        <S.Title
          titleFontSize={titleFontSize}
          titleLineHeight={titleLineHeight}
          marginBottom={marginBottom}
        >
          {t(titlePath)}
        </S.Title>
        <StyledOption.OptionsContainer>
          {options.map(({ value, text }) => (
            <Option
              {...optionProps}
              key={value}
              value={value}
              disabled={isAnswersDisabled}
            >
              <QuestionButton>{t(text)}</QuestionButton>
            </Option>
          ))}
        </StyledOption.OptionsContainer>
      </S.Column>
    </S.Wrapper>
  )
}
