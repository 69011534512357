import styled from 'styled-components'

import checkbox from 'assets/images/checkbox-blue.svg'

import { MediaBreakpoint } from 'root-constants/common'

import { ISliderProps } from './ReviewsSlider'

type TRecommendationProps = {
  text: string
}

type TCardProps = {
  borderColor: string
}

type TTextProps = {
  fontStyle?: string
}

export const StyledReviewsSlider = {
  Wrapper: styled.div<ISliderProps>`
    padding-top: 33px;
    margin-bottom: ${({ marginBottom }) => marginBottom}px;

    .swiper {
      overflow: visible;
    }

    .swiper-wrapper {
      display: flex;
    }

    .swiper-slide {
      position: relative;
      width: 265px;
    }

    &[data-has-laptop-styles='true'] {
      @media (min-width: ${MediaBreakpoint.LAPTOP}px) {
        .swiper-slide {
          width: 392px;
        }
      }
    }
  `,
  Title: styled.h3`
    margin-bottom: 57px;
    text-align: center;
    font-size: 24px;
    font-weight: 800;
    line-height: 30px;

    strong {
      color: #4c8eff;
      font-weight: 800;
    }
  `,
  Card: styled.div<TCardProps>`
    position: relative;
    padding: 40px 10px 16px;
    min-height: 228px;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 9px;
    border: 4px solid #eed0e4;
    border: ${({ borderColor }) => `4px solid ${borderColor}`};
    color: ${({ theme }) => theme.colors.primaryText};

    &[data-has-laptop-styles='true'] {
      @media (min-width: ${MediaBreakpoint.LAPTOP}px) {
        padding: 40px 18px 44px;
        height: 175px;
        border-radius: 16px;

        p {
          font-style: italic;
        }
      }
    }
  `,
  Avatar: styled.img`
    position: absolute;
    top: -33px;
    left: 50%;
    transform: translateX(-50%);
    border-radius: 50%;
  `,
  CardTitle: styled.h3`
    font-size: 16px;
    font-weight: 700;
    line-height: 20px;
  `,
  Recommendation: styled.div<TRecommendationProps>`
    margin-bottom: 8px;
    padding-left: 20px;
    position: relative;
    display: flex;

    ::before {
      content: url(${checkbox});
      width: 14px;
      height: 14px;
      display: block;
      position: absolute;
      left: 0;
      top: -2px;
    }

    ::after {
      content: '${({ text }) => text}';
      font-size: 12px;
      line-height: 16px;
    }
  `,
  CardText: styled.p<TTextProps>`
    font-size: 12px;
    font-weight: 500;
    line-height: 16px;
    text-align: center;
    font-style: ${({ fontStyle }) => fontStyle || 'italic'};
  `,
  Rating: styled.img`
    max-width: 88px;
    margin-bottom: 8px;
  `,
}
