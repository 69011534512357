import { PaymentMethod } from 'modules/payment/constants'

import applePayIcon from 'assets/images/sprite/apple-pay.svg'
import creditCardIcon from 'assets/images/sprite/credit-card-black.svg'
import googlePayIcon from 'assets/images/sprite/google-pay.svg'

import { BillingCycle, TimeInterval } from 'root-constants/common'

export const PAYMENT_METHOD_INFO = {
  [PaymentMethod.CREDIT_CARD]: {
    name: 'payment.variant4.creditCard',
    icon: creditCardIcon,
    iconWidth: 20,
  },
  [PaymentMethod.APPLE_PAY]: {
    name: 'payment.variant4.applePay',
    icon: applePayIcon,
    iconWidth: 36,
  },
  [PaymentMethod.GOOGLE_PAY]: {
    name: 'payment.variant4.googlePay',
    icon: googlePayIcon,
    iconWidth: 36,
  },
}

export const BILLING_CYCLE_CONTEXTS = {
  [BillingCycle.ONE_MONTH]: TimeInterval.MONTH,
  [BillingCycle.YEAR]: TimeInterval.YEAR,
}
