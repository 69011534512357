import React, { useEffect, useLayoutEffect, useMemo } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'

import { useFeatureIsOn, useFeatureValue } from '@growthbook/growthbook-react'

import {
  getSubscriptionListAction,
  setScreenNameAction,
  setSubscriptionListAction,
} from 'root-redux/actions/common'
import { selectGeneralSubscriptionTrialPeriod } from 'root-redux/selects/user'

import { useIsFamylocateCohort } from 'hooks/common/useIsFamylocateCohort'
import { usePaywall } from 'hooks/common/usePaywall'
import { usePurchaseStore } from 'hooks/common/usePurchaseStore'
import { useVatInfo } from 'hooks/common/useVatInfo'

import { ScreenName, eventLogger } from 'services/eventLogger.service'

import { makeUpsellAction } from 'modules/payment/redux/actions/common'
import { UpsellPlanItemVariant1 } from 'modules/subscriptions/components/upsell'
import {
  FAMIO_APP_NAME,
  FAMYLOCATE_APP_NAME,
} from 'modules/subscriptions/pages/Upsell/constants'

import { Spinner } from 'components/Spinner'
import { StripePaymentProcessing } from 'components/StripePaymentProcessing'
import { SupportLink } from 'components/SupportLink'
import { TermsOfUseLink } from 'components/TermsOfUseLink'

import { goTo } from 'browser-history'
import {
  CURRENCY_SYMBOLS,
  GOOGLE_9_TEST_SEGMENTS,
  GROWTH_BOOK_FEATURE_KEYS,
  SubscriptionListType,
  SubscriptionTag,
  TimeInterval,
  TrialPeriod,
} from 'root-constants/common'
import { PageId } from 'root-constants/pages'

import { StyledUpsellVariant2 as S } from './UpsellVariant2.styles'

export const UpsellVariant2: React.FC = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const { search } = useLocation()

  const hasAlternativePriceTag = useFeatureIsOn(
    GROWTH_BOOK_FEATURE_KEYS.car_843_newprice,
  )
  const alternativePlanItem = useFeatureValue(
    GROWTH_BOOK_FEATURE_KEYS.car_1007_checkout,
    GOOGLE_9_TEST_SEGMENTS.A_SEGMENT,
  )

  const hasIncludedVAT = useVatInfo()
  const { cohortToUse, currency, currentPrice } = usePurchaseStore()
  const isFamylocateCohort = useIsFamylocateCohort(cohortToUse)
  const { hasPrices } = usePaywall('')
  const userTrialPeriod = useSelector(selectGeneralSubscriptionTrialPeriod)

  const priceTag = useMemo(() => {
    if (
      hasAlternativePriceTag ||
      alternativePlanItem !== GOOGLE_9_TEST_SEGMENTS.A_SEGMENT
    ) {
      return SubscriptionTag.NO_TAX_NEW_PRICE
    }

    return hasIncludedVAT ? SubscriptionTag.TAX : SubscriptionTag.NO_TAX
  }, [alternativePlanItem, hasAlternativePriceTag, hasIncludedVAT])

  useLayoutEffect(() => {
    dispatch(setSubscriptionListAction([]))

    dispatch(setScreenNameAction(ScreenName.UPSELL_OFFER))
    dispatch(
      getSubscriptionListAction({
        tags: priceTag,
        subscriptionType: SubscriptionListType.UPSELL,
      }),
    )
  }, [dispatch, priceTag])

  useEffect(() => {
    eventLogger.logUpsellPurchaseShown()
  }, [])

  const makeUpsell = () => {
    dispatch(makeUpsellAction())
  }

  const handleSkip = () => {
    eventLogger.logUpsellPurchaseClose()
    goTo({
      pathname: PageId.ACCOUNT,
      search,
    })
  }

  return !hasPrices ? (
    <Spinner />
  ) : (
    <S.Wrapper>
      <S.Column>
        <S.Title isFamyLocate={isFamylocateCohort}>
          {t`subscriptions.upsell2.title`}
        </S.Title>
        <S.SelectPlanBlock SelectPlanItem={UpsellPlanItemVariant1} />
        <S.Button
          type="button"
          onClick={makeUpsell}
          isFamyLocate={isFamylocateCohort}
        >
          {t`actions.continue`}
        </S.Button>
        <S.SkipButton
          type="button"
          onClick={handleSkip}
          isFamyLocate={isFamylocateCohort}
        >
          {t`actions.skipOffer`}
        </S.SkipButton>
        <S.Disclaimer>
          <Trans
            i18nKey="subscriptions.upsell.disclaimer2"
            values={{
              price: currentPrice,
              minimumFractionDigits: 2,
              currencySymbol: CURRENCY_SYMBOLS[currency],
              currency: currency.toUpperCase(),
              vatInfo: hasIncludedVAT ? t('commonComponents.inclVat') : '',
              context:
                userTrialPeriod && userTrialPeriod <= TrialPeriod.ONE_WEEK
                  ? TimeInterval.DAY
                  : TimeInterval.MONTH,
              appName: isFamylocateCohort
                ? FAMYLOCATE_APP_NAME
                : FAMIO_APP_NAME,
            }}
            components={{
              termsOfUse: <TermsOfUseLink />,
              supportLink: <SupportLink />,
            }}
          />
        </S.Disclaimer>
      </S.Column>
      <StripePaymentProcessing />
    </S.Wrapper>
  )
}
