import React, { HTMLAttributes } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import {
  selectCurrentVariantCohort,
  selectLanguage,
} from 'root-redux/selects/common'

import { PrivacyPolicyLink } from 'components/PrivacyPolicyLink'
import { GOOGLE_COHORT_SUBSTRING } from 'components/Security/constants'
import { SupportLink } from 'components/SupportLink'

import { CDN_FOLDER_LINK, Language } from 'root-constants/common'

import { StyledSecurity as S } from './Security.styles'

export const Security: React.FC<HTMLAttributes<HTMLDivElement>> = ({
  ...props
}) => {
  const language = useSelector(selectLanguage)
  const cohort = useSelector(selectCurrentVariantCohort)
  const { t } = useTranslation()

  const securityImageLink = `${CDN_FOLDER_LINK}/images/secure_${language}.webp`

  const isFontOversized = language === Language.FR

  const hasUserAgreements = cohort.includes(GOOGLE_COHORT_SUBSTRING)

  return (
    <S.SecureCard {...props}>
      <S.SecureImage src={securityImageLink} height="60" alt="secure" />
      <ul>
        <li>
          <S.SecureItemTitle>
            {t`subscriptions.security.informationTitle`}
          </S.SecureItemTitle>
          <S.SecureItemText isFontOversized={isFontOversized}>
            {t`subscriptions.security.informationText`}
          </S.SecureItemText>
        </li>
        <li>
          <S.SecureItemTitle>{t`subscriptions.security.secureTitle`}</S.SecureItemTitle>
          <S.SecureItemText isFontOversized={isFontOversized}>
            {t`subscriptions.security.secureText`}
          </S.SecureItemText>
        </li>
        {hasUserAgreements && (
          <li>
            <S.SecureItemTitle>{t`subscriptions.security.userAgreements`}</S.SecureItemTitle>
            <S.SecureItemText isFontOversized={isFontOversized}>
              <Trans
                i18nKey="subscriptions.security.currentLocation"
                components={{ privacyLink: <PrivacyPolicyLink /> }}
              />
            </S.SecureItemText>
          </li>
        )}
        <li>
          <S.SecureItemTitle>{t`subscriptions.security.helpTitle`}</S.SecureItemTitle>
          <S.SecureItemText isFontOversized={isFontOversized}>
            <SupportLink />
          </S.SecureItemText>
        </li>
      </ul>
    </S.SecureCard>
  )
}
