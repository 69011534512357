import styled from 'styled-components'

import { Button } from 'components/Button'

import { baseColumnStyles, basePageStyles } from 'common-styles'
import { Color, HEADER_HEIGHT } from 'root-constants/common'

export const StyledUpsellVariant1 = {
  Wrapper: styled.div`
    ${basePageStyles};
    padding-top: ${HEADER_HEIGHT}px;
  `,
  Column: styled.div`
    ${baseColumnStyles};
    padding-bottom: 24px;
  `,
  Title: styled.h1`
    font-size: 32px;
    font-weight: 800;
    line-height: 44px;
    text-align: center;
    margin: 29px 0 24px;

    strong {
      font-size: 32px;
      font-weight: 800;
      line-height: 44px;
      color: ${Color.BLUE};
    }
  `,
  BenefitsContainer: styled.div`
    max-width: 311px;
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 24px;
    border-radius: 16px;
    background: linear-gradient(174deg, #F0F5FF13.11%, #F9F4FF96.5%);
    margin-bottom: 24px;
  `,
  BenefitsData: styled.div`
    display: flex;
    align-items: center;
    gap: 16px;
    margin-bottom: 16px;

    img {
      width: 56px;
      height: 56px;
    }
  `,
  BenefitsText: styled.p`
    font-size: 18px;
    font-weight: 700;
    line-height: 25px;
    color: ${Color.BLUE};
  `,
  BenefitsPrice: styled.p`
    font-size: 16px;
    font-weight: 600;
    line-height: 22px;
    margin-top: 8px;
    text-align: center;
    color: #b6acda;
  `,
  Button: styled(Button)`
    line-height: 22px;
  `,
  SkipButton: styled(Button)`
    color: ${Color.LIGHT_TEXT};
    text-align: center;
    font-size: 16px;
    font-weight: 500;
    line-height: 22px;
    text-decoration: underline;
    margin-top: 16px;
    background-color: transparent;
    cursor: pointer;
    max-height: 22px;
  `,
  Disclaimer: styled.div`
    font-size: 13px;
    font-weight: 500;
    line-height: 18px;
    color: ${Color.LIGHT_TEXT};
    margin-top: 16px;
    text-align: center;

    strong {
      font-size: 13px;
      font-weight: 800;
      line-height: 18px;
    }

    a {
      font-size: 13px;
      font-weight: 500;
      line-height: 18px;
      color: ${Color.LIGHT_TEXT};
      text-decoration: underline;
    }
  `,
  Subtitle: styled.h2`
    font-size: 24px;
    font-weight: 700;
    line-height: 33px;
    margin: 24px 0 16px;
    text-align: center;
  `,
  Text: styled.p`
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
    text-align: center;
    margin-bottom: 16px;
    color: ${Color.LIGHT_TEXT};
  `,
  ProductsContainer: styled.div`
    display: flex;
    flex-direction: column;
    gap: 12px;
    margin-bottom: 24px;
  `,
  ProductDescriptionWrapper: styled.div`
    display: flex;
    padding: 12px;
    align-items: center;
    gap: 13px;
    border-radius: 16px;
    background-color: ${Color.WHITE};
    box-shadow: 0 8px 20px 0 #dfe3f3;
  `,
  ProductImage: styled.img`
    max-width: 106px;
    width: 100%;
    height: 138px;
    aspect-ratio: 106/138;
  `,
  ProductDescription: styled.div`
    display: flex;
    flex-direction: column;
    gap: 4px;
  `,
  ProductName: styled.p`
    font-size: 16px;
    font-weight: 700;
    line-height: 22px;
  `,
  Description: styled.p`
    font-size: 13px;
    font-weight: 400;
    line-height: 18px;
    color: #565976;
  `,
}
