import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import {
  selectDynamicDiscount,
  selectLanguage,
} from 'root-redux/selects/common'

import { getPriceBeforeDiscount } from 'helpers/getPriceBeforeDiscount'

import { ISubscription } from 'models/subscriptions.model'

import { selectCurrency } from 'modules/payment/redux/selects'

import { PriceValue } from 'components/PriceValue'

import {
  CURRENCY_SYMBOLS,
  Currency,
  Language,
  ProductKey,
} from 'root-constants/common'

import { StyledPlanItemVariant2 as S } from './PlanItemVariant2.styles'
import { INTRO_OFFER_PERIODS, OVERSIZE_CURRENCIES } from './constants'

type TProps = {
  subscription: ISubscription
  isSelected: boolean
}

export const PlanItemVariant3: React.FC<TProps> = ({
  subscription: {
    isDefault,
    product,
    trialPrices: { fullPrice: trialPrice, durationDays, daily },
  },
  isSelected,
}) => {
  const { t } = useTranslation()
  const currency = useSelector(selectCurrency)
  const language = useSelector(selectLanguage)
  const dynamicDiscount = useSelector(selectDynamicDiscount)

  const [integerCustomDailyPrice, fractionalCustomDailyPrice = '00'] = daily
    .toFixed(2)
    .split('.')

  const oldPrice = Number(
    getPriceBeforeDiscount(Number(dynamicDiscount?.amount), trialPrice),
  )

  const oldCustomPrice = Number(
    getPriceBeforeDiscount(Number(dynamicDiscount?.amount), daily),
  )

  const hasSmallerFontSize = OVERSIZE_CURRENCIES.includes(currency)
  const isMexicanPeso = currency === Currency.MXN

  return (
    <S.Wrapper
      isDefault={isDefault}
      isSelected={isSelected}
      isMexicanPeso={isMexicanPeso}
      labelText={t`subscriptions.planBlock.mostPopular`}
    >
      <S.Checkbox isSelected={isSelected} />
      <S.MainPricesContainer>
        <S.Period>
          {t('payment.variant5.planPeriodName', {
            periodQuantity: INTRO_OFFER_PERIODS[durationDays],
            periodName:
              product === ProductKey.ONE_WEEK
                ? t('payment.variant5.planPeriods.week')
                : t('payment.variant5.planPeriods.month', {
                    count: INTRO_OFFER_PERIODS[durationDays],
                  }),
          })}
        </S.Period>
        <S.OldPrice hasSmallerFontSize={hasSmallerFontSize}>
          <PriceValue value={oldPrice} currency={currency} />
        </S.OldPrice>
        <S.CurrentPrice hasSmallerFontSize={hasSmallerFontSize}>
          <PriceValue value={trialPrice} currency={currency} />
        </S.CurrentPrice>
        <S.OldCustomPrice hasSmallerFontSize={hasSmallerFontSize}>
          <PriceValue value={oldCustomPrice} currency={currency} />
        </S.OldCustomPrice>
      </S.MainPricesContainer>

      <S.CustomPriceContainer
        isSelected={isSelected}
        isMexicanPeso={isMexicanPeso}
      >
        {language === Language.EN && (
          <S.Currency>{CURRENCY_SYMBOLS[currency]}</S.Currency>
        )}
        <S.CustomPriceInteger>{integerCustomDailyPrice}</S.CustomPriceInteger>
        <S.CustomPriceFractional isMexicanPeso={isMexicanPeso}>
          {fractionalCustomDailyPrice}{' '}
          {language === Language.ES && CURRENCY_SYMBOLS[currency]}
        </S.CustomPriceFractional>
        <S.CustomPricePeriod>
          {t('subscriptions.planBlock.perPeriod', {
            interval: t('subscriptions.planBlock.perDay'),
          })}
        </S.CustomPricePeriod>
      </S.CustomPriceContainer>
    </S.Wrapper>
  )
}
