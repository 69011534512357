import styled from 'styled-components'

export const StyledSearchNumberAnimationVariant2 = {
  Wrapper: styled.div`
    margin-top: 40px;
  `,
  AnimationContainer: styled.div`
    width: 100%;
    max-height: 280px;
  `,
}
