import styled from 'styled-components'

import { BASE_COLUMN_WIDTH, Color } from 'root-constants/common'

export const StyledGoodHands = {
  Wrapper: styled.div`
    max-width: ${BASE_COLUMN_WIDTH}px;
    display: grid;
    grid-template-columns: 116px 1fr;
    grid-template-rows: auto;
    grid-template-areas: 'title title' 'usersInfo statement';
    grid-gap: 24px 12px;
    align-items: center;
    margin-bottom: 48px;
  `,
  Title: styled.h2`
    grid-area: title;
    text-align: center;
    font-size: 24px;
    font-weight: 800;
    line-height: 28px;
  `,
  UsersInfoContainer: styled.div`
    grid-area: usersInfo;
    display: flex;
    flex-direction: column;
    place-content: center;
    border-radius: 12px;
    padding: 12px;
    background: ${Color.GREY};
  `,
  Percents: styled.p`
    color: ${Color.ACCENT_VIOLET};
    text-align: center;
    font-size: 28px;
    font-weight: 800;
    line-height: 38px;
  `,
  Users: styled.p`
    text-align: center;
    font-size: 16px;
    font-weight: 700;
    line-height: 22px;
  `,
  Statement: styled.p`
    grid-area: statement;
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
  `,
}
