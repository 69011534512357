import React from 'react'
import { Trans, useTranslation } from 'react-i18next'

import { useNextStep } from 'hooks/useNextStep'

import { TPageProps } from 'models/common.model'

import { Button } from 'components/Button'

import reasonsToJoin from 'assets/images/reasons-to-join-2.jpg'

import { StyledReasonToJoin as S } from './ReasonToJoin.styles'
import { PAGE_NAME } from './constants'

export const ReasonToJoinVariant2: React.FC<TPageProps> = ({
  pageId,
  nextPagePath,
}) => {
  const { t } = useTranslation()

  const handleContinue = useNextStep({
    pageId,
    pageName: PAGE_NAME,
    question: '',
    nextPagePath,
  })

  return (
    <S.Wrapper>
      <S.Image src={reasonsToJoin} alt="join_reasons" />
      <S.FixedContainer>
        <S.Text>
          <Trans i18nKey="onboarding.reasonToJoin.text" />
        </S.Text>
        <S.Note>{t`onboarding.reasonToJoin.note`}</S.Note>
        <Button onClick={() => handleContinue('')}>
          {t`actions.continue`}
        </Button>
      </S.FixedContainer>
    </S.Wrapper>
  )
}
