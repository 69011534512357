import { useEffect, useState } from 'react'

import { Events, ScreenName, eventLogger } from 'services/eventLogger.service'

export const enum ViewportScrollPercentage {
  V_0 = 0,
  V_16 = 16,
  V_33 = 33,
  V_50 = 50,
  V_66 = 66,
  V_83 = 83,
  V_100 = 100,
}

export const useViewportValue = (
  event: Events = Events.PAGE_SCROLLED,
  screenName: ScreenName = ScreenName.ONBOARDING,
) => {
  const [viewportValue, setViewportValue] = useState(
    ViewportScrollPercentage.V_0,
  )

  useEffect(() => {
    let currentViewportValue = ViewportScrollPercentage.V_0

    const setIntermediateViewportValue = (
      value: ViewportScrollPercentage,
      removeEventListener: () => void,
    ) => {
      if (currentViewportValue < value) {
        currentViewportValue = value
        setViewportValue(value)
      }
      removeEventListener()
    }

    const setViewportScrollValue = (e: Event) => {
      e.stopPropagation()

      const {
        offsetHeight: oneScreenHeight,
        scrollHeight: fullScrollHeight,
        scrollTop,
      } = e.target as HTMLBodyElement

      const currentViewportScrollPercentage = Math.round(
        ((scrollTop + oneScreenHeight) / fullScrollHeight) * 100 + 0.1,
      )
      const removeEventListener = () => {
        if (currentViewportValue === ViewportScrollPercentage.V_100) {
          document.body.removeEventListener('scroll', setViewportScrollValue)
        }
      }

      switch (currentViewportScrollPercentage) {
        case ViewportScrollPercentage.V_33:
          setIntermediateViewportValue(
            ViewportScrollPercentage.V_33,
            removeEventListener,
          )
          break
        case ViewportScrollPercentage.V_50:
          setIntermediateViewportValue(
            ViewportScrollPercentage.V_50,
            removeEventListener,
          )
          break
        case ViewportScrollPercentage.V_66:
          setIntermediateViewportValue(
            ViewportScrollPercentage.V_66,
            removeEventListener,
          )
          break
        case ViewportScrollPercentage.V_83:
          setIntermediateViewportValue(
            ViewportScrollPercentage.V_83,
            removeEventListener,
          )
          break
        case ViewportScrollPercentage.V_100:
          setIntermediateViewportValue(
            ViewportScrollPercentage.V_100,
            removeEventListener,
          )
          break
        default:
          setIntermediateViewportValue(
            ViewportScrollPercentage.V_16,
            removeEventListener,
          )
          break
      }
    }

    document.body.addEventListener('scroll', setViewportScrollValue)

    return () => {
      document.body.removeEventListener('scroll', setViewportScrollValue)
    }
  }, [])

  useEffect(() => {
    if (viewportValue > ViewportScrollPercentage.V_0) {
      eventLogger.logPageScrolled({
        event,
        value: viewportValue,
        screenName,
      })
    }
  }, [event, screenName, viewportValue])
}
