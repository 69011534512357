import styled from 'styled-components'

import badgeBG from 'assets/images/violet-chipolo-badge.svg'

import { Color } from 'root-constants/common'

type TProps = {
  isSelected: boolean
  color?: string
}

export const StyledChipoloOffer = {
  Wrapper: styled.div`
    width: 100%;
  `,
  Discount: styled.div`
    max-width: 311px;
    width: 100%;
    border-radius: 16px;
    border: 1px solid #6336ff;
    background-color: #f7f5ff;
    display: flex;
    align-items: center;
    padding: 0 16px;
    height: 72px;
    gap: 20px;
    margin-bottom: 16px;
  `,
  Badge: styled.div`
    background-image: url(${badgeBG});
    height: 70px;
    width: 56px;
    color: ${Color.WHITE};
    font-size: 20px;
    line-height: 22px;
    font-weight: 800;
    text-shadow: 0 3px 5px rgba(27, 28, 44, 0.15);
    text-align: center;
    padding-top: 10px;
    margin-bottom: 20px;
  `,
  Offer: styled.p`
    color: #6336ff;
    font-size: 18px;
    font-weight: 800;
    line-height: 22px;
  `,
  OfferWrapper: styled.div<TProps>`
    display: flex;
    align-items: center;
    border: ${({ isSelected }) =>
      isSelected ? `1px solid ${Color.ACCENT_VIOLET}` : '1px solid #bcc9d0'};
    background: ${Color.WHITE};
    border-radius: 20px;
    padding: 0 16px;
    margin-bottom: 16px;
    min-height: 72px;
    gap: 10px;
    max-width: 311px;
    width: 100%;
  `,
  Text: styled.p<TProps>`
    font-size: 16px;
    font-weight: 800;
    line-height: 22px;
    color: ${({ isSelected }) => (isSelected ? `#0a324d` : '#bcc9d0')};
    max-width: 146px;
    width: 100%;

    strong {
      font-size: 14px;
      font-weight: 400;
      line-height: 22px;
    }
  `,
  Price: styled.p<TProps>`
    font-size: 16px;
    font-weight: 800;
    line-height: 22px;
    color: ${({ isSelected, color }) => (isSelected ? color : '#bcc9d0')};
    max-width: 53px;
    width: 100%;

    strong {
      font-size: 16px;
      font-weight: 800;
      line-height: 22px;
      margin-left: 5px;
    }
  `,
}
