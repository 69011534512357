import styled from 'styled-components'

import phoneImg from 'assets/images/search-number-phone.png'

import { Color, MediaBreakpoint } from 'root-constants/common'

export const StyledSearchNumberAnimationVariant1 = {
  Wrapper: styled.div`
    display: flex;
    justify-content: center;
    gap: 4px;
    width: 100%;
    max-width: ${MediaBreakpoint.MAX_PHONE}px;
    margin-bottom: 24px;

    @media (min-width: ${MediaBreakpoint.MAX_PHONE}px) {
      max-width: 375px;
    }
  `,
  BenefitsColumn: styled.div`
    padding-top: 41px;
    display: flex;
    flex-direction: column;
    gap: 16px;
    max-width: 154px;
    width: 100%;
    position: relative;
    left: 24px;
  `,
  Benefits: styled.div`
    display: flex;
    align-items: center;
    gap: 8px;

    img {
      width: 16px;
      height: 16px;
    }
  `,
  Text: styled.p`
    font-size: 12px;
    font-weight: 600;
    line-height: 14px;
    letter-spacing: 1px;
    text-transform: uppercase;
    color: ${Color.WHITE};
  `,
  AnimationContainer: styled.div`
    width: 100%;
    min-height: 333px;
    background-image: url(${phoneImg});
    background-repeat: no-repeat;
    background-size: 164px auto;
    background-position: center;
  `,
}
