import styled from 'styled-components'

import { Option } from 'components/Option'

type TWrapperProps = {
  hasAlternativePrices?: boolean
}
export const StyledSelectPlanBlockVariant1 = {
  Wrapper: styled.div<TWrapperProps>`
    width: 100%;
    margin-bottom: 12px;

    & label:first-of-type {
      margin-bottom: ${({ hasAlternativePrices }) =>
        hasAlternativePrices ? 30 : 12}px;
    }
  `,
  Option: styled(Option)`
    width: 100%;
  `,
}
