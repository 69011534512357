import styled from 'styled-components'

import { BurgerMenu } from 'components/BurgerMenu'
import { Button } from 'components/Button'

import { baseColumnStyles, basePageStyles } from 'common-styles'
import { HEADER_HEIGHT, MediaBreakpoint } from 'root-constants/common'

type TButtonProps = {
  marginBottom?: number
}
type TSubtitleProps = {
  lineHeight?: number
}

export const StyledSubscriptionsGoogleVariant6 = {
  Wrapper: styled.div`
    ${basePageStyles};
    padding-top: ${HEADER_HEIGHT}px;
    padding-bottom: 25px;
  `,
  BurgerMenu: styled(BurgerMenu)`
    button {
      top: 6px;
      right: 6px;

      @media (min-width: ${MediaBreakpoint.LAPTOP}px) {
        top: 3px;
        right: 3px;
      }
    }
  `,
  Content: styled.div`
    width: 100%;
    max-width: ${MediaBreakpoint.MAX_PHONE}px;
    height: calc(100dvh - 56px);
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    background: radial-gradient(
        77.02% 49.98% at 123.2% 68.28%,
        rgba(255, 255, 255, 0.2) 0%,
        rgba(232, 214, 255, 0.2) 42.71%,
        rgba(255, 255, 255, 0.2) 83.25%
      ),
      radial-gradient(
        72.83% 45.25% at -34.27% 25.76%,
        rgba(255, 255, 255, 0.2) 0%,
        rgba(232, 214, 255, 0.2) 62.55%,
        rgba(255, 255, 255, 0.2) 96.24%
      ),
      #fff;

    @media (min-width: ${MediaBreakpoint.MAX_PHONE}px) {
      max-width: 375px;
    }
  `,
  Column: styled.div`
    ${baseColumnStyles};
  `,
  Title: styled.h1`
    font-size: 32px;
    font-weight: 800;
    line-height: 38px;
    text-align: center;
    margin-top: 24px;
  `,
  Tracker: styled.div`
    display: flex;
    text-align: center;
    margin-top: 32px;

    p {
      margin: 0 10px;
      font-size: 20px;
      font-weight: 800;
      line-height: 22px;
      letter-spacing: 0.5px;
    }

    strong {
      font-size: 32px;
    }

    img {
      width: 46px;
      height: 87px;
    }
  `,
  Subtitle: styled.h3<TSubtitleProps>`
    font-size: 24px;
    font-weight: 800;
    line-height: ${({ lineHeight }) => lineHeight || 28}px;
    text-align: center;
    margin-bottom: 24px;
  `,
  PhoneImageContainer: styled.div`
    width: 100%;
    aspect-ratio: 375/318;
    max-width: ${MediaBreakpoint.MAX_PHONE}px;
    margin-bottom: 24px;

    @media (min-width: ${MediaBreakpoint.MAX_PHONE}px) {
      max-width: 375px;
    }
  `,
  Button: styled(Button)<TButtonProps>`
    margin-bottom: ${({ marginBottom }) => marginBottom || 32}px;
  `,
}
