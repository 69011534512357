import { useMemo } from 'react'
import { useSelector } from 'react-redux'

import { selectCurrentVariantCohortToUse } from 'root-redux/selects/common'

import { Cohort, DESKTOP_COHORTS } from 'root-constants/common'

export const useGetFlowInfo = () => {
  const cohortToUse = useSelector(selectCurrentVariantCohortToUse)

  const hasDesktopVersion = useMemo(
    () => DESKTOP_COHORTS.includes(cohortToUse),
    [cohortToUse],
  )

  const isSafetyFlow = useMemo(
    () => cohortToUse === Cohort.SAFETY_4,
    [cohortToUse],
  )

  return {
    hasDesktopVersion,
    isSafetyFlow,
  }
}
