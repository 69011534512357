import styled from 'styled-components'

import { MediaBreakpoint } from 'root-constants/common'

type TProps = {
  marginBottom?: number
}
export const StyledBenefitsVariant1 = {
  Wrapper: styled.div<TProps>`
    display: flex;
    flex-direction: column;
    gap: 24px;
    padding: 24px 32px;
    align-items: center;
    background: linear-gradient(174deg, #f0f5ff 13.11%, #f9f4ff 96.5%);
    max-width: ${MediaBreakpoint.MAX_PHONE}px;
    margin-bottom: ${({ marginBottom }) => marginBottom || 0}px;

    @media (min-width: ${MediaBreakpoint.MAX_PHONE}px) {
      max-width: 375px;
    }
  `,
  Title: styled.h2`
    font-size: 24px;
    font-style: normal;
    font-weight: 800;
    line-height: 28px;
    text-align: center;
  `,
  Content: styled.div`
    display: flex;
    justify-content: center;
    gap: 16px;

    img {
      width: 56px;
      height: 56px;
    }
  `,
  TextWrapper: styled.div``,
  BenefitsTitle: styled.p`
    color: #5c90ff;
    font-size: 18px;
    font-weight: 700;
    line-height: 25px;
    margin-bottom: 4px;
  `,
  Subtitle: styled.p`
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
  `,
}
