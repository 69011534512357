import i18n from 'i18next'

export const numberToWords = (value: number): string => {
  const zeroToNineteenNames = i18n.t('commonComponents.zeroToNineteenNames', {
    returnObjects: true,
  })
  const tensNames = i18n.t('commonComponents.tensNames', {
    returnObjects: true,
  })
  const hundredNames = i18n.t('commonComponents.hundredNames', {
    returnObjects: true,
  })

  if (value < 20) return zeroToNineteenNames[value]
  if (value < 100) {
    const tens = Math.floor(value / 10)
    const ones = value % 10 ? `-${zeroToNineteenNames[value % 10]}` : ``
    return tensNames[tens] + ones
  }

  const hundreds = Math.floor(value / 100)
  const tensAndOnes =
    value % 100 ? ` ${i18n.t('actions.and')} ${numberToWords(value % 100)}` : ``

  return hundredNames[hundreds] + tensAndOnes
}
