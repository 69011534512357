import React, { useEffect, useMemo } from 'react'

import { useCookieConsentAnswer } from 'hooks/useCookieConsentAnswer'
import { useGetPrices } from 'hooks/useGetPrices'
import { usePaywall } from 'hooks/usePaywall'
import { useProductId } from 'hooks/useProductId'
import { usePurchaseStore } from 'hooks/usePurchaseStore'

import { TPageProps } from 'models/common.model'

import { eventLogger } from 'services/eventLogger.service'
import { googleAnalyticsLogger } from 'services/googleAnalytics.service'

import {
  PaymentFlowsGoogleVariant2,
  PaymentFlowsGoogleVariant3,
  PaymentFlowsGoogleVariant5,
} from 'modules/payment/components/PaymentFlowsGoogle'

import { Spinner } from 'components/Spinner'
import { StripePaymentProcessing } from 'components/StripePaymentProcessing'

import { Cohort, SubscriptionTag } from 'root-constants/common'

import { StyledPaymentVariant3 as S } from './PaymentVariant3.styles'

export const PaymentVariant3: React.FC<TPageProps> = () => {
  const {
    email,
    cohort,
    uuid,
    hasSubscription,
    subscriptions,
    selectedSubscription,
    isPaymentFlowsShown,
    stripeAccountId,
    stripeAccountName,
    screenName,
    threeDSecureIframeUrl,
    dynamicDiscount,
  } = usePurchaseStore()

  const productId = useProductId()
  const { hasPrices } = usePaywall('')
  const { isPersonalDataAllowed } = useCookieConsentAnswer()

  useGetPrices({ tags: SubscriptionTag.NO_TAX })

  const isThirteenOrFourteenCohort =
    cohort === Cohort.GOOGLE_13 || cohort === Cohort.GOOGLE_14

  const paymentFlowVariant = useMemo(() => {
    if (dynamicDiscount) {
      return <PaymentFlowsGoogleVariant5 />
    }
    if (isThirteenOrFourteenCohort) {
      return <PaymentFlowsGoogleVariant3 />
    }
    return <PaymentFlowsGoogleVariant2 />
  }, [isThirteenOrFourteenCohort, dynamicDiscount])

  useEffect(() => {
    if (!selectedSubscription) return

    window.fbq('track', 'InitiateCheckout', {}, { eventID: uuid })
    googleAnalyticsLogger.logCheckoutStarted(subscriptions)
  }, [selectedSubscription, uuid, subscriptions])

  useEffect(() => {
    if (hasSubscription || !selectedSubscription) return

    eventLogger.logPurchaseShown({
      screenName,
      productId,
      stripeAccountName,
      stripeAccountId,
    })
    window.ttq.identify({ email: isPersonalDataAllowed ? email : '' })
    window.ttq.track('AddToCart')
  }, [
    email,
    hasSubscription,
    isPersonalDataAllowed,
    productId,
    screenName,
    selectedSubscription,
    stripeAccountId,
    stripeAccountName,
  ])

  return (
    <S.Wrapper>
      {threeDSecureIframeUrl ? (
        <S.ThreeDSecureIframe title="3DSecure" src={threeDSecureIframeUrl} />
      ) : (
        <>
          <StripePaymentProcessing />
          <S.Content>{paymentFlowVariant}</S.Content>
        </>
      )}
      {(!isPaymentFlowsShown || !hasPrices) && <Spinner />}
    </S.Wrapper>
  )
}
