import styled, { css } from 'styled-components'

import { BurgerMenu } from 'components/BurgerMenu'
import { PoliciesGroup } from 'components/PoliciesGroup'

import bg from 'assets/images/intro-10.webp'

import { basePageStyles } from 'common-styles'
import { Color, HEADER_HEIGHT, MediaBreakpoint } from 'root-constants/common'

const progressValueStyles = css`
  border-radius: 20px;
  transition: all 0.2s ease-out;
  background: #7388bf;
`

const commonWidthStyles = css`
  width: 100%;
  max-width: ${MediaBreakpoint.MAX_PHONE}px;

  @media (min-width: ${MediaBreakpoint.MAX_PHONE}px) {
    max-width: 375px;
  }
`

export const StyledIntroVariant10 = {
  Wrapper: styled.div`
    ${basePageStyles};
    padding-top: ${HEADER_HEIGHT}px;
  `,
  BurgerMenu: styled(BurgerMenu)`
    button {
      top: 6px;
      right: 6px;

      @media (min-width: ${MediaBreakpoint.LAPTOP}px) {
        top: 3px;
        right: 3px;
      }
    }
  `,
  Content: styled.div`
    ${commonWidthStyles};
    padding: 24px;
    min-height: calc(100dvh - ${HEADER_HEIGHT}px);

    background-image: url(${bg});
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
  `,
  Title: styled.h2`
    font-size: 24px;
    font-weight: 800;
    text-align: center;
    margin-bottom: 4px;
  `,
  Subtitle: styled.h3`
    font-size: 18px;
    font-weight: 500;
    text-align: center;
    max-width: 285px;
    margin: 0 auto;
  `,
  ProgressBlock: styled.div`
    ${commonWidthStyles};
    display: flex;
    flex-direction: column;
    align-items: center;
    position: absolute;
    bottom: 82px;
    left: 50%;
    transform: translateX(-50%);
    padding: 0 32px;
  `,
  ProgressBarContainer: styled.div`
    width: 100%;
    min-height: 10px;
    margin: 0 0 8px;
    padding: 0 2px;
    border-radius: 20px;
    background: ${Color.WHITE};
    display: flex;
    align-items: center;
  `,
  ProgressBar: styled.progress`
    width: 100%;
    height: 6px;
    border-radius: 16px;
    appearance: none;
    border: none;
    display: block;
    overflow: hidden;
    position: relative;

    &::-webkit-progress-bar {
      background: ${Color.WHITE};
    }
    &::-webkit-progress-value {
      ${progressValueStyles};
    }
    &::-moz-progress-bar {
      ${progressValueStyles};
    }
    &::-ms-fill {
      ${progressValueStyles};
    }
  `,
  ProgressBarLabel: styled.h4`
    font-size: 15px;
    font-weight: 800;
    text-align: center;
    text-transform: capitalize;
    color: ${({ theme }) => theme.colors.defaultTextColor};
  `,
  PoliciesGroup: styled(PoliciesGroup)`
    position: absolute;
    bottom: 12px;
    left: 50%;
    transform: translateX(-50%);
    color: ${Color.LIGHT_TEXT};
    width: 100%;
  `,
}
