import React, { useMemo, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { selectLanguage } from 'root-redux/selects/common'

import { useNextStep } from 'hooks/useNextStep'

import { TPageProps } from 'models/common.model'

import { BurgerMenu } from 'components/BurgerMenu'
import { DesktopProvider } from 'components/DesktopProvider'
import { Option, OptionType } from 'components/Option'
import { PoliciesGroup } from 'components/PoliciesGroup'

import mapImage from 'assets/images/intro-map.jpg'

import { Language } from 'root-constants/common'

import { StyledIntroVariant2 as S } from './IntroVariant2.styles'
import { OPTION_VALUES, PAGE_NAME, QUESTIONS } from './constants'

export const IntroVariant2: React.FC<TPageProps> = ({
  pageId,
  nextPagePath,
  alternativePagePath,
}) => {
  const { t } = useTranslation()
  const language = useSelector(selectLanguage)
  const [answers, setAnswers] = useState<string[]>([])

  const handleContinue = useNextStep({
    pageId,
    pageName: PAGE_NAME,
    question: QUESTIONS[pageId],
    nextPagePath:
      !!answers.length &&
      answers.every((answer) => answer === OPTION_VALUES.KID)
        ? nextPagePath
        : alternativePagePath,
  })

  const optionProps = useMemo(
    () => ({
      name: pageId,
      type: OptionType.CHECKBOX,
      onChange: (value: string, isChecked: boolean) => {
        if (isChecked) {
          setAnswers([...answers, value])
        } else {
          setAnswers(answers.filter((item) => item !== value))
        }
      },
    }),
    [pageId, answers],
  )

  return (
    <DesktopProvider imageSrc={mapImage}>
      <BurgerMenu />
      <S.Wrapper>
        <S.ImageContainer data-is-es-lang={language === Language.ES}>
          <S.Title data-is-es-lang={language === Language.ES}>
            <Trans i18nKey="onboarding.intro.title" />
          </S.Title>
        </S.ImageContainer>
        <S.FixedContainer>
          <S.Column>
            <S.Question>{t`onboarding.intro.question`}</S.Question>
            <S.Note>{t`onboarding.intro.note`}</S.Note>
            <S.OptionsContainer>
              <Option {...optionProps} value={OPTION_VALUES.PARTNER}>
                <S.QuestionButton>{t`onboarding.intro.answers.partner`}</S.QuestionButton>
              </Option>
              <Option {...optionProps} value={OPTION_VALUES.KID}>
                <S.QuestionButton>{t`onboarding.intro.answers.kid`}</S.QuestionButton>
              </Option>
              <Option {...optionProps} value={OPTION_VALUES.FRIEND}>
                <S.QuestionButton>{t`onboarding.intro.answers.friend`}</S.QuestionButton>
              </Option>
              <Option {...optionProps} value={OPTION_VALUES.OTHER}>
                <S.QuestionButton>{t`onboarding.intro.answers.other`}</S.QuestionButton>
              </Option>
            </S.OptionsContainer>
            <S.Button
              onClick={() => handleContinue(answers)}
              disabled={!answers.length}
            >
              {t`actions.continue`}
            </S.Button>
            <PoliciesGroup />
          </S.Column>
        </S.FixedContainer>
      </S.Wrapper>
    </DesktopProvider>
  )
}
