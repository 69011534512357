import React from 'react'
import { useSelector } from 'react-redux'

import { selectSubscriptionList } from 'root-redux/selects/common'

import { selectCurrency } from 'modules/payment/redux/selects'

import { PlanItemCancelOffer } from '../PlanItemCancelOffer'
import { StyledPlanBlockVariant1 as S } from './PlanBlock.styles'

export const PlanBlockVariant1: React.FC = () => {
  const subscriptions = useSelector(selectSubscriptionList)
  const currency = useSelector(selectCurrency)

  return (
    <S.Wrapper>
      {subscriptions.map(
        ({
          id,
          isDefault,
          mainPrices: {
            fullPrice,
            periodQuantity,
            periodName,
            oldPrices: { beforeCustomDiscount },
          },
        }) => (
          <PlanItemCancelOffer
            key={id}
            currentPrice={fullPrice}
            periodQuantity={periodQuantity}
            periodName={periodName}
            currency={currency}
            oldPrice={beforeCustomDiscount?.fullPrice}
            isSoldOut={!isDefault}
          />
        ),
      )}
    </S.Wrapper>
  )
}
